// src/router/redirects.js

export const redirects = [
  {
    path: "/",
    redirect: () => {
      const storedActiveUrl = localStorage.getItem("activeUrl");
      return storedActiveUrl;
    },
  },
  {
    path: "/cricket",
    redirect: () => {
      const storedActiveUrl = localStorage.getItem("activeUrl");
      if (storedActiveUrl && storedActiveUrl !== "/cricket") {
        return storedActiveUrl;
      }
      return "/en/live/cricket";
    },
  },
  {
    path: "/en/live/table-tennis",
    redirect: () => {
      return "/en/live/table_tennis";
    },
  },
  {
    path: "/contact-us",
    redirect: () => {
      return "/en/contact-us";
    },
  },
  {
    path: "/privacy-policy",
    redirect: () => {
      return "/en/privacy-policy";
    },
  },


  {
    path: "/disclaimer",
    redirect: () => {
      return "/en/terms-and-conditions";
    },
  },
  {
    path: "/terms-of-service",
    redirect: () => {
      return "/en/terms-and-conditions";
    },
  },
  {
    path: "/about-us",
    redirect: () => {
      return "/en/about-us";
    },
  },
  {
    path: "/cricket/teams/list",
    redirect: () => {
      return "/en/live/cricket";
    },
  },
  {
    path: "/cricket/news/details/199",
    redirect: () => {
      return "/en/cricket/news/list";
    },
  },
];
