<template>
  <div
    class="el-dialog__wrapper settingsBox"
    style="z-index: 2020"
    data-v-698032b0=""
  >
    <div
      role="dialog"
      aria-modal="true"
      aria-label="Setting"
      class="el-dialog"
      style="margin-top: 15vh; width: 520px"
    >
      <div class="el-dialog__header">
        <span class="el-dialog__title">{{ $t("common.menu.setting") }}</span
        ><button
          @click="close"
          type="button"
          aria-label="Close"
          class="el-dialog__headerbtn"
        >
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </div>
      <div class="el-dialog__body">
        <span data-v-698032b0="" class="topSolid"></span>
        <div data-v-698032b0="" class="settingsItemBox">
          <p data-v-698032b0="" class="settingsItemTitle">
            {{ $t("common.menu.language") }}
          </p>
          <select v-model="lang" @change="onSwitchLang" class="languageListBox">
            <option value="en">English</option>
            <option value="cn">Chinese</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div
    @click="testClickOverlay"
    class="v-modal"
    tabindex="0"
    style="z-index: 2000"
  ></div>
</template>

<script>
export default {
  name: "SettingsDialog",
  beforeMount() {
    this.lang = this.$i18n.locale;
  },
  data() {
    return {
      lang: "en",
    };
  },

  methods: {
    close() {
      this.$emit("closeDialog");
    },
    onSwitchLang() {
      console.log("Selected language:", this.lang);

      // Update Vue I18n locale
      this.$i18n.locale = this.lang;

      // Close the dialog
      this.close();

      // Update URL based on the selected language
      let currentPath = window.location.pathname;
      let newPath = currentPath.replace(/^\/(en|cn)\//, "/" + this.lang + "/");
      localStorage.setItem("activeUrl", newPath);

      window.history.replaceState({}, "", newPath);
      // You can perform additional actions here if needed
    },
  },
};
</script>

<style></style>
