<template>
  <div
    class="ft_players view border-box"
    data-v-a8612f26=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="playerheader" data-v-a8612f26="">
      <!-- begin::header -->
      <div class="backFil" data-v-a8612f26="">
        <div class="header" data-v-54d9a5ac="" data-v-a8612f26="">
          <div class="van-sticky">
            <header
              class="first-header"
              style="background: #033e80"
              data-v-54d9a5ac=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-54d9a5ac=""
              >
                <div class="left van-col van-col--4" data-v-54d9a5ac="">
                  <span
                    class="icon iconfont myIcon iconback"
                    style="opacity: 0.6"
                    data-v-54d9a5ac=""
                  ></span>
                </div>
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="font-size: 0.4rem"
                  data-v-54d9a5ac=""
                >
                  <span
                    class="formatClass"
                    style="opacity: 1"
                    data-v-54d9a5ac=""
                    ><span data-v-54d9a5ac=""
                      ><span data-v-54d9a5ac=""></span>
                      <span
                        itemprop="startDate"
                        data-v-54d9a5ac=""
                      ></span></span
                  ></span>
                </div>
                <div class="right van-col van-col--4" data-v-54d9a5ac=""></div>
              </div>
            </header>
          </div>
        </div>
      </div>

      <!-- end::header -->
      <!-- begin::player Info -->
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-a8612f26=""
      >
        <div
          class="player_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
          data-v-a8612f26=""
        >
          <img
            :src="
              playerDetails && playerDetails.logo
                ? playerDetails.logo + '!w100'
                : bannerImagePath('default-player.png')
            "
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div class="player_name" data-v-a8612f26="">
          {{
            this.$i18n.locale == "cn" && playerDetails && playerDetails.cn_name
              ? playerDetails.cn_name
              : playerDetails.name
          }}
        </div>
        <div class="des flex align-center justify-center" data-v-a8612f26="">
          <div
            class="country_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-a8612f26=""
          >
            <img
              :src="
                playerDetails &&
                playerDetails.countryDetails &&
                playerDetails.countryDetails.logo
                  ? playerDetails.countryDetails.logo
                 : bannerImagePath('default-nation.png')
              "
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span class="country" data-v-a8612f26="">
            <span v-if="this.$i18n.locale == 'cn'">
              {{
                playerDetails && playerDetails.countryDetails
                  ? playerDetails.countryDetails.cn_name + "/"
                  : ""
              }}
            </span>
            <span v-else>
              {{
                playerDetails && playerDetails.countryDetails
                  ? playerDetails.countryDetails.name + "/"
                  : ""
              }}
            </span>
          </span>
          <a
            class="division"
            data-v-a8612f26=""
            :href="
              '/' +
              this.$i18n.locale +
              '/football/team/details/' +
              playerDetails.teamDetails.name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              playerDetails.teamDetails.id
            "
          >
            {{
              this.$i18n.locale == "cn" &&
              playerDetails.teamDetails &&
              playerDetails.teamDetails.cn_name
                ? playerDetails.teamDetails.cn_name
                : playerDetails.teamDetails.name
            }}
          </a>
          <span class="sj" style="line-height: 0.26rem" data-v-a8612f26="">
            /
            {{
              playerDetails && playerDetails.position
                ? playerDetails.position
                : "-"
            }}
          </span>
        </div>
      </div>
      <!-- end::player Info -->
    </div>
    <div class="content" data-v-a8612f26="">
      <div class="player_overview" data-v-010cfb63="" data-v-a8612f26="">
        <div class="w100" data-v-010cfb63="">
          <!-- begin::player info -->
          <div class="title" data-v-010cfb63="">
            {{ $t("football.league-details.info") }}
          </div>
          <div class="infoContent" data-v-010cfb63="">
            <div class="detail" data-v-010cfb63="">
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.team") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <div
                    class="clubLogo van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                    data-v-010cfb63=""
                  >
                    <img
                      :src="
                        playerDetails &&
                        playerDetails.teamDetails &&
                        playerDetails.teamDetails.logo
                          ? playerDetails.teamDetails.logo
                        : bannerImagePath('default-team.png')
                      "
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      playerDetails.teamDetails.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      playerDetails.teamDetails.id
                    "
                    class="value"
                    data-v-010cfb63=""
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      playerDetails.teamDetails &&
                      playerDetails.teamDetails.cn_name
                        ? playerDetails.teamDetails.cn_name
                        : playerDetails.teamDetails.name
                    }}
                  </a>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.country") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span
                    class="value"
                    data-v-010cfb63=""
                    v-if="this.$i18n.locale == 'cn'"
                  >
                    {{
                      playerDetails &&
                      playerDetails.countryDetails &&
                      playerDetails.countryDetails.cn_name
                        ? playerDetails.countryDetails.cn_name
                        : "-"
                    }}
                  </span>

                  <span class="value" data-v-010cfb63="" v-else>
                    {{
                      playerDetails &&
                      playerDetails.countryDetails &&
                      playerDetails.countryDetails.name
                        ? playerDetails.countryDetails.name
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.contract-until") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails &&
                      playerDetails.contract_until &&
                      playerDetails.contract_until != 0
                        ? getFormattedDate(playerDetails.contract_until)
                        : "-"
                    }}</span
                  >
                </div>
              </div>

              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.height") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails && playerDetails.height
                        ? playerDetails.height
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.weight") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails && playerDetails.weight
                        ? playerDetails.weight
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.age") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails && playerDetails.age
                        ? playerDetails.age
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.player-value") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails && playerDetails.market_value
                        ? playerDetails.market_value + "€"
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.position") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails && playerDetails.position
                        ? playerDetails.position
                        : "-"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::player info -->
          <!-- begin::player profile -->
          <div class="title" data-v-010cfb63="">
            {{ $t("football.league-details.player-info") }}
          </div>
          <div class="dec-box" data-v-010cfb63="">
            <div class="text" data-v-010cfb63="">
              <p v-if="this.$i18n.locale == 'cn'">
                {{ playerDetails.cn_name }}
                的个人资料页面显示了所有比赛和比赛的统计数据
                他参加的比赛。您可以获取
                {{ playerDetails.cn_name }}
                最重要的数据，包括平均球员评分、进球、助攻、红牌和
                其他相关数据也显示在AiScore上。

                <br /><br />

                <br />
              </p>
              <p v-else>
                The profile page of
                {{ playerDetails.name }}
                displays all matches and competitions with statistics for all
                the matches he played in. You can get most important stats of
                {{ playerDetails.name }}
                , including average player rating, goals, assists, cards and
                other relevant data are also displayed on AiScore.<br /><br />

                <br />
              </p>
            </div>
          </div>
          <!-- end::player player  -->
        </div>
      </div>
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  data() {
    return {
      playerDetails: null,
      loading: true,
      playerId: null,
      metaData: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.playerId;
    this.playerId = itemId;
    this.getPlayerDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    getMetaData(playerId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/player/meta/` + playerId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getPlayerDetails(playerId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/player/details/${playerId}`)
        .then((response) => {
          this.playerDetails = response.data.playerDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormattedDate(timeStamp) {
      if (timeStamp != "") {
        const date = new Date(timeStamp);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      }
      return "-";
    },
  },
};
</script>
