<template>
  <div class="FooterLink2" data-v-92aacecc="" data-v-250f1619="">
    <div class="link flex" data-v-92aacecc="">
      <div class="flex-1 item" data-v-92aacecc="">
        <a
          :href="'/' + this.$i18n.locale + '/about-us'"
          target="_blank"
          rel="sponsored"
          class="text-item"
          data-v-92aacecc=""
        >
          {{ $t("football.league-details.about-us") }}</a
        >
      </div>
      <div class="line" data-v-92aacecc=""></div>
      <div class="flex-1 item" data-v-92aacecc="">
        <a
          :href="'/' + this.$i18n.locale + '/terms-and-conditions'"
          target="_blank"
          rel="nofollow"
          class="text-item"
          data-v-92aacecc=""
          >{{ $t("football.league-details.terms-and-conditions") }}</a
        >
      </div>
      <div class="line" data-v-92aacecc=""></div>
      <div class="flex-1 item" data-v-92aacecc="">
        <a
          :href="'/' + this.$i18n.locale + '/privacy-policy'"
          target="_blank"
          rel="nofollow"
          class="text-item"
          data-v-92aacecc=""
          >{{ $t("football.league-details.privacy-policy") }}</a
        >
      </div>
      <div class="line" data-v-92aacecc=""></div>
      <div class="flex-1 item" data-v-92aacecc="">
        <a
          :href="'/' + this.$i18n.locale + '/contact-us'"
          target="_blank"
          rel="nofollow"
          class="text-item"
          data-v-92aacecc=""
          >{{ $t("football.league-details.contact-us") }}</a
        >
      </div>
    </div>
    <div class="Copyright" data-v-92aacecc="">
      <span v-if="this.$i18n.locale == 'cn'">
        版权所有@2024 Sportyclub Live
      </span>

      <span v-else> Copyright @ 2024 Sportyclub Live </span>
    </div>

    <div
      style="width: 100%; height: 1.228rem; display: none"
      data-v-92aacecc=""
    ></div>
  </div>
</template>