<template>
  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
  >
    <div data-v-46436e01="" class="user">
      <div data-v-46436e01="" class="tag_commingMatches">
        <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
        <span data-v-46436e01="" class="text">
          {{ $t("football.live-score.search-results") }}</span
        >
      </div>
    </div>
  </div>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
    style="transform: translateY(0px); min-height: 200px"
  >
    <div class="flex justify-center align-center flex-col contentBox">
      <img :src="bannerImagePath('default-container.png')" class="nodata" />

      <div class="color-999 noDataText fs-12">
        {{ $t("football.live-score.nothing-found") }}
      </div>
    </div>
  </div>

  <!-- live search result -->
  <template v-else>

    <div
      data-v-46436e01=""
      class="vue-recycle-scroller scroller ready page-mode direction-vertical"
    >
      <div class="vue-recycle-scroller__item-wrapper">
        <div class="vue-recycle-scroller__item-view">
          <div data-v-46436e01="" class="user">
            <div
              data-v-46436e01=""
              class="flex align-center"
              v-for="(league, leagueName) in searchMatchListLive"
              :key="leagueName"
            >
              <div data-v-89734b7c="" data-v-46436e01="" style="width: 100%">
                <div data-v-89734b7c="" class="list_title">
                  <div data-v-89734b7c="" class="flex align-center topLeftBox">
                    <i
                      class="iconfont collectImg"
                      style="visibility: visible"
                    ></i>

                    <!-- Render the image if it loads successfully -->
                    <img
                      style="height: 33px !important; width: 38px !important"
                      data-v-89734b7c=""
                      class="countryLogo"
                      :src="
                        'https://sportyclub.online//images/cricket/leagues/' +
                        league[0].tournament.uniqueTournament.id +
                        '/' +
                        league[0].tournament.uniqueTournament.id +
                        '.png'
                      "
                    />
                    <!-- Render the default image if the image fails to load -->
                    <img
                      :src="bannerImagePath('default-country.png')"
                      v-if="imageError"
                    />

                    <a
                      data-v-2eea2747=""
                      :href="
                                    '/' +
                                    this.$i18n.locale +
                                    '/cricket/league/details/' +
                                    league[0].tournament.uniqueTournament.name.toLowerCase().replace(/\s/g, '-') +
                                    '/' +
                                    league[0].tournament.uniqueTournament.id
                                  "
                      target="_blank"
                      class="compText"
                    >
                      {{ leagueName }}
                    </a>
                  </div>
                </div>
                <div data-v-89734b7c="" v-for="match in league" :key="match.id">
                  <div
                    data-v-89734b7c=""
                    class="list newList"
                    style="height: 64px"
                  >
                    <div data-v-89734b7c="" class="list">
                      <div
                        data-v-89734b7c=""
                        class="flex align-center justify-center collectImgBox"
                      ></div>

                      <a
                        data-v-89734b7c=""
                        target="_blank"
                        class="flex flex-1"
                        style="height: 100%"
                      >
                        <div
                          data-v-89734b7c=""
                          class="flex align-center listBox justify-around"
                        >
                          <span
                            data-v-89734b7c=""
                            class="fs-13 matchTime"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{ formatUnixTimestamp(match.startTimestamp) }}
                          </span>
                          <div
                            data-v-89734b7c=""
                            class="flex flex-col fs-13 align-center color-r text-center barItem"
                          >
                            {{ match.status.description }}
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          class="team flex flex-col padding5"
                        >
                          <!-- begin::home team -->
                          <div
                            data-v-89734b7c=""
                            class="flex-1 align-center flex"
                          >
                            <span data-v-89734b7c="" class="teamLogoBox">
                              <img
                                :src="
                                  'https://sportyclub.online//images/cricket/teams/' +
                                  match.homeTeam.id +
                                  '/' +
                                  match.homeTeam.id +
                                  '.png'
                                "
                              />
                            </span>
                            <div data-v-89734b7c="" class="w-o-h elseTeamName">
                              {{ match.homeTeam.name }} ({{
                                match.homeTeam.shortName
                              }})
                            </div>

                            <span> </span>
                          </div>
                          <!-- end::home team -->
                          <!-- begin::away team -->
                          <div
                            data-v-89734b7c=""
                            class="flex-1 align-center flex"
                          >
                            <span data-v-89734b7c="" class="teamLogoBox">
                              <img
                                :src="
                                  'https://sportyclub.online//images/cricket/teams/' +
                                  match.awayTeam.id +
                                  '/' +
                                  match.awayTeam.id +
                                  '.png'
                                "
                                data-v-89734b7c=""
                                alt=""
                              />
                            </span>
                            <div data-v-89734b7c="" class="w-o-h elseTeamName">
                              {{ match.awayTeam.name }} ({{
                                match.awayTeam.shortName
                              }})
                            </div>
                          </div>
                          <!-- end::away team -->
                        </div>

                        <!-- begin::score area -->
                        <div data-v-89734b7c="" class="teamScore padding5">
                          <div
                            data-v-89734b7c=""
                            class="flex flex-col align-center"
                            style="height: 100%"
                          >
                            <div
                              data-v-89734b7c=""
                              class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                position: relative;
                              "
                            >
                              <div data-v-89734b7c="" class="bigScore bh">
                                <div
                                  v-if="
                                    match &&
                                    match.homeScore &&
                                    match.homeScore.innings
                                  "
                                  data-v-89734b7c=""
                                  class=""
                                >
                                  <span
                                    v-for="inn in match.homeScore.innings"
                                    :key="inn"
                                    data-v-89734b7c=""
                                    :class="
                                      match.changes.changes.includes(
                                        'homeScore.innings'
                                      )
                                        ? 'scoreRed'
                                        : ''
                                    "
                                  >
                                    {{ inn.score + "/" }}
                                    {{ inn.wickets }} ({{ inn.overs }}) &nbsp;
                                    <div
                                      v-if="
                                        match.changes.changes.includes(
                                          'homeScore.innings'
                                        )
                                      "
                                      data-v-89734b7c=""
                                      class="svgBallIconBox"
                                    >
                                      <img
                                        data-v-89734b7c=""
                                        src="/images/banqiu-banzi.png"
                                        alt="#"
                                        class="svgBallIcon"
                                      />
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              data-v-89734b7c=""
                              class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                position: relative;
                              "
                            >
                              <div data-v-89734b7c="" class="bigScore bh">
                                <div
                                  v-if="
                                    match &&
                                    match.awayScore &&
                                    match.awayScore.innings
                                  "
                                  data-v-89734b7c=""
                                  class=""
                                >
                                  <span
                                    v-for="inn in match.awayScore.innings"
                                    :key="inn"
                                    data-v-89734b7c=""
                                    :class="
                                      match.changes.changes.includes(
                                        'awayScore.innings'
                                      )
                                        ? 'scoreRed'
                                        : ''
                                    "
                                  >
                                    {{ inn.score + "/" }}
                                    {{ inn.wickets }} ({{ inn.overs }}) &nbsp;
                                    <div
                                      v-if="
                                        match.changes.changes.includes(
                                          'awayScore.innings'
                                        )
                                      "
                                      data-v-89734b7c=""
                                      class="svgBallIconBox"
                                    >
                                      <img
                                        data-v-89734b7c=""
                                        src="/images/banqiu-banzi.png"
                                        alt="#"
                                        class="svgBallIcon"
                                      />
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end::score area -->
                        <!-- begin::status area -->
                        <div
                          data-v-89734b7c=""
                          class="flex-1 icon-setting"
                          style="border-right: 1px solid rgb(234, 234, 234)"
                        ></div>
                        <div
                          data-v-89734b7c=""
                          class="flex Moneyline moneyLineContent"
                          style="position: relative"
                        >
                          <div data-v-89734b7c="" class="flex flex-1 oddsItem">
                            <div
                              data-v-89734b7c=""
                              class="flex-1 text-center flex le oddsItemBox noOddsBox oddsWidth3"
                              style="margin-left: 15px !important;margin-right:10px !important"
                            >
                              <span> {{ match.note }} </span>
                            </div>
                          </div>
                        </div>
                        <!-- end::status area -->
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upcoming search result -->

    <!-- <div
      v-if="Object.entries(searchMatchListUpcoming).length !== 0"
      class="vue-recycle-scroller__item-view"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="tag_commingMatches">
          <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
          <span data-v-46436e01="" class="text">
            {{ $t("football.live-score.today-upcoming-matches") }}</span
          >
        </div>
      </div>
    </div> -->

    <div
      data-v-46436e01=""
      class="vue-recycle-scroller scroller ready page-mode direction-vertical"
    >
      <div class="vue-recycle-scroller__item-wrapper">
        <div>
          <div class="vue-recycle-scroller__item-view">
            <div data-v-46436e01="" class="user">
              <div
                data-v-46436e01=""
                class="flex align-center"
                v-for="(league, leagueName) in searchMatchListUpcoming"
                :key="leagueName"
              >
                <div data-v-89734b7c="" data-v-46436e01="" style="width: 100%">
                  <div data-v-89734b7c="" class="list_title">
                    <div
                      data-v-89734b7c=""
                      class="flex align-center topLeftBox"
                    >
                      <i
                        class="iconfont collectImg"
                        style="visibility: visible"
                      ></i>

                      <!-- Render the image if it loads successfully -->
                      <img
                        style="height: 33px !important; width: 38px"
                        data-v-89734b7c=""
                        class="countryLogo"
                        :src="
                          'https://sportyclub.online//images/cricket/leagues/' +
                          league[0].tournament.uniqueTournament.id +
                          '/' +
                          league[0].tournament.uniqueTournament.id +
                          '.png'
                        "
                      />
                      <!-- Render the default image if the image fails to load -->
                      <img
                        :src="bannerImagePath('default-country.png')"
                        v-if="imageError"
                      />

                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/cricket/league/details/' +
                          league[0].tournament.uniqueTournament.name.toLowerCase().replace(/\s/g, '-') +
                          '/' +
                          league[0].tournament.uniqueTournament.id
                        "
                        data-v-2eea2747=""
                        target="_blank"
                        class="compText"
                      >
                        {{ leagueName }}
                      </a>
                    </div>
                  </div>
                  <div
                    data-v-89734b7c=""
                    v-for="match in league"
                    :key="match.id"
                  >
                    <div
                      data-v-89734b7c=""
                      class="list newList"
                      style="height: 64px"
                    >
                      <div data-v-89734b7c="" class="list">
                        <div
                          data-v-89734b7c=""
                          class="flex align-center justify-center collectImgBox"
                        ></div>

                        <a
                          data-v-89734b7c=""
                          target="_blank"
                          class="flex flex-1"
                          style="height: 100%"
                        >
                          <div
                            data-v-89734b7c=""
                            class="flex align-center listBox justify-around"
                          >
                            <span
                              data-v-89734b7c=""
                              class="fs-13 matchTime"
                              style="color: rgb(153, 153, 153)"
                            >
                              {{ formatUnixTimestamp(match.startTimestamp) }}
                            </span>
                            <div
                              data-v-89734b7c=""
                              class="flex flex-col fs-13 align-center color-r text-center barItem"
                            >
                              {{ match.status.description }}
                            </div>
                          </div>

                          <div
                            data-v-89734b7c=""
                            class="team flex flex-col padding5"
                          >
                            <!-- begin::home team -->
                            <div
                              data-v-89734b7c=""
                              class="flex-1 align-center flex"
                            >
                              <span data-v-89734b7c="" class="teamLogoBox">
                                <img
                                  :src="
                                    'https://sportyclub.online//images/cricket/teams/' +
                                    match.homeTeam.id +
                                    '/' +
                                    match.homeTeam.id +
                                    '.png'
                                  "
                                />
                              </span>
                              <div
                                data-v-89734b7c=""
                                class="w-o-h elseTeamName"
                              >
                                {{ match.homeTeam.name }} ({{
                                  match.homeTeam.shortName
                                }})
                              </div>
                            </div>
                            <!-- end::home team -->
                            <!-- begin::away team -->
                            <div
                              data-v-89734b7c=""
                              class="flex-1 align-center flex"
                            >
                              <span data-v-89734b7c="" class="teamLogoBox">
                                <img
                                  :src="
                                    'https://sportyclub.online//images/cricket/teams/' +
                                    match.awayTeam.id +
                                    '/' +
                                    match.awayTeam.id +
                                    '.png'
                                  "
                                  data-v-89734b7c=""
                                  alt=""
                                />
                              </span>
                              <div
                                data-v-89734b7c=""
                                class="w-o-h elseTeamName"
                              >
                                {{ match.awayTeam.name }} ({{
                                  match.awayTeam.shortName
                                }})
                              </div>
                            </div>
                            <!-- end::away team -->
                          </div>
                          <!-- begin::score area -->
                          <div data-v-89734b7c="" class="teamScore padding5">
                            <div
                              data-v-89734b7c=""
                              class="flex flex-col align-center"
                              style="height: 100%"
                            >
                              <div
                                data-v-89734b7c=""
                                class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  position: relative;
                                "
                              >
                                <div data-v-89734b7c="" class="bigScore bh">
                                  <div
                                    v-if="
                                      match &&
                                      match.homeScore &&
                                      match.homeScore.innings
                                    "
                                    data-v-89734b7c=""
                                    class=""
                                  >
                                    <span
                                      v-for="inn in match.homeScore.innings"
                                      :key="inn"
                                      data-v-89734b7c=""
                                      :class="
                                        match.changes.changes.includes(
                                          'homeScore.innings'
                                        )
                                          ? 'scoreRed'
                                          : ''
                                      "
                                    >
                                      {{ inn.score + "/" }}
                                      {{ inn.wickets }} ({{ inn.overs }}) &nbsp;
                                      <div
                                        v-if="
                                          match.changes.changes.includes(
                                            'homeScore.innings'
                                          )
                                        "
                                        data-v-89734b7c=""
                                        class="svgBallIconBox"
                                      >
                                        <img
                                          data-v-89734b7c=""
                                          src="/images/banqiu-banzi.png"
                                          alt="#"
                                          class="svgBallIcon"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                data-v-89734b7c=""
                                class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  position: relative;
                                "
                              >
                                <div data-v-89734b7c="" class="bigScore bh">
                                  <div
                                    v-if="
                                      match &&
                                      match.awayScore &&
                                      match.awayScore.innings
                                    "
                                    data-v-89734b7c=""
                                    class=""
                                  >
                                    <span
                                      v-for="inn in match.awayScore.innings"
                                      :key="inn"
                                      data-v-89734b7c=""
                                      :class="
                                        match.changes.changes.includes(
                                          'awayScore.innings'
                                        )
                                          ? 'scoreRed'
                                          : ''
                                      "
                                    >
                                      {{ inn.score + "/" }}
                                      {{ inn.wickets }} ({{ inn.overs }}) &nbsp;
                                      <div
                                        v-if="
                                          match.changes.changes.includes(
                                            'awayScore.innings'
                                          )
                                        "
                                        data-v-89734b7c=""
                                        class="svgBallIconBox"
                                      >
                                        <img
                                          data-v-89734b7c=""
                                          src="/images/banqiu-banzi.png"
                                          alt="#"
                                          class="svgBallIcon"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end::score area -->
                          <!-- begin::status area -->
                          <div
                            data-v-89734b7c=""
                            class="flex-1 icon-setting"
                            style="border-right: 1px solid rgb(234, 234, 234)"
                          ></div>
                          <div
                            data-v-89734b7c=""
                            class="flex Moneyline moneyLineContent"
                            style="position: relative"
                          >
                            <div
                              data-v-89734b7c=""
                              class="flex flex-1 oddsItem"
                            >
                              <div
                                data-v-89734b7c=""
                                class="flex-1 text-center flex le oddsItemBox noOddsBox oddsWidth3"
                                style="margin-left: 15px !important;margin-right:10px !important"
                              >
                                <span>{{ match.note }}</span>
                              </div>
                            </div>
                          </div>
                          <!-- end::status area -->
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",

      // topTeamInningsStatuses: [
      //   432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      // ],
      // bottomTeamInningsStatuses: [
      //   433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      // ],
      // TOP AWAY
      // BOTTOM HOME
      match_status: {
        FIRST_INNINGS_HOME_TEAM: 532,
        FIRST_INNINGS_AWAY_TEAM: 533,
        SECOND_INNINGS_HOME_TEAM: 534,
        SECOND_INNINGS_AWAY_TEAM: 535,
      },
    };
  },

  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to convert unix timestamp
    formatUnixTimestamp(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
      const hours = ("0" + date.getHours()).slice(-2); // Add leading zero if needed
      const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero if needed
      return `${hours}:${minutes}`;
    },
  },
};
</script>
