<template>
  <div class="clearfix-row view border-box" data-v-27524a2e="">
    <div class="team" data-v-27524a2e="">
      <div class="wrapper" data-v-27524a2e="">
        <div class="flex" data-v-771adb1a="" data-v-27524a2e="">
          <div
            class="p-l flex-1"
            data-v-771adb1a=""
            v-if="loading || loadingLineups"
          >
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span
                  class="color-p font-500 w-o-h active"
                  data-v-7c947e55=""
                  style="cursor: pointer"
                >
                  <span>
                    {{ $t("football.league-details.match-lineups") }}</span
                  >
                </span>
              </div>
              <!-- begin::home squad -->
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <!-- begin::header -->
                  <div
                    class="head flex justify-between align-center color-999 border-box fs-12"
                    data-v-0939e9fc=""
                  >
                    <div class="col" style="width: 200px" data-v-0939e9fc="">
                      {{ $t("football.league-details.players") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.position") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.jersey-no") }}
                    </div>

                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.birthday") }}
                    </div>
                  </div>
                  <!-- end::header -->
                  <div class="body" data-v-0939e9fc="">
                    <div
                      class="row flex fs-12 color-333 cur-pointer"
                      data-v-0939e9fc=""
                    >
                      <a
                        class="col justify-start border-box"
                        style="width: 200px; padding-left: 12px"
                        data-v-0939e9fc=""
                      >
                        <img
                          src="/images/default-loader.gif"
                          style="
                            width: 120px !important;
                            height: 60px !important;
                          "
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end home squad -->
            </div>
          </div>

          <div
            class="p-l flex-1"
            data-v-771adb1a=""
            v-else-if="
              matchLineups && matchLineups != '' && matchLineups.home != ''
            "
          >
            <!-- <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                Full Screen
              </div>
              <iframe
                gesture="media"
                allow="encrypted-media"
                :src="'https://player002.vip/embed2.php?id=ptvsp'"
                height="400"
                width="800"
                style="margin-bottom: 20px !important"
              ></iframe>
              <br />
            </div> -->
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span
                  class="color-p font-500 w-o-h active"
                  data-v-7c947e55=""
                  style="cursor: pointer"
                >
                  <span v-on:click="toggleSquad('home')">
                    {{ matchInfo.homeTeam.name }}
                    {{ $t("football.league-details.squad") }}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span v-on:click="toggleSquad('away')">
                    {{ matchInfo.awayTeam.name }}
                    {{ $t("football.league-details.squad") }}
                  </span>
                </span>
              </div>
              <!-- begin::home squad -->
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <!-- begin::header -->
                  <div
                    class="head flex justify-between align-center color-999 border-box fs-12"
                    data-v-0939e9fc=""
                  >
                    <div class="col" style="width: 200px" data-v-0939e9fc="">
                      {{ $t("football.league-details.players") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.position") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.jersey-no") }}
                    </div>

                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.birthday") }}
                    </div>
                  </div>
                  <!-- end::header -->
                  <div class="body" data-v-0939e9fc="">
                    <div
                      class="row flex fs-12 color-333 cur-pointer"
                      data-v-0939e9fc=""
                      v-for="home in matchLineups[activeSquad].players"
                      :key="home"
                    >
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/cricket/player/details/' +
                          home.player.name.toLowerCase().replace(/\s/g, '-') +
                          '/' +
                          home.player.id
                        "
                        class="col justify-start border-box"
                        style="width: 200px; padding-left: 12px"
                        data-v-0939e9fc=""
                        ><img
                          style="
                            width: 35px !important;
                            height: 35px !important;
                          "
                          :src="
                            home.player.id
                              ? 'https://sportyclub.online//images/cricket/players/' +
                                home.player.id +
                                '/' +
                                home.player.id +
                                '.png'
                              : bannerImagePath('default-team.png')
                          "
                          @error="
                            $event.target.src =
                              bannerImagePath('default-player.png')
                          "
                          class="player-logo mr-xs"
                          data-v-0939e9fc=""
                        />
                        <span class="flex flex-col fs-12" data-v-0939e9fc=""
                          ><span class="color-p" data-v-0939e9fc=""
                            ><span
                              itemprop="name"
                              data-v-0939e9fc=""
                              style="color: black; font-weight: bold"
                              >{{ home.player.name }}</span
                            ></span
                          >
                          <span
                            class="flex align-center color-999 s-0-85"
                            style="transform-origin: left"
                            data-v-0939e9fc=""
                          >
                            <span
                              data-v-0939e9fc=""
                              style="
                                background-color: rgb(175, 39, 39);
                                color: white;
                                padding: 2px;
                                border-radius: 7px;
                                font-size: 13px;
                                /* font-weight: bold; */
                              "
                              >{{ home.player.country.name }}</span
                            ></span
                          ></span
                        >
                      </a>

                      <div class="col flex-1" data-v-0939e9fc="">
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/cricket/player/details/' +
                            home.player.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            home.player.id
                          "
                          style="color: black; font-weight: bold"
                        >
                          {{
                            home.position == "BM"
                              ? "Batsman"
                              : home.position == "B"
                              ? "Bowler"
                              : ""
                              ? home.position == "AR"
                                ? "Al-Rounder"
                                : home.position == "WK"
                              : "Wicket Keeper"
                          }}
                        </a>
                      </div>
                      <div class="col flex-1" data-v-0939e9fc="">
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/cricket/player/details/' +
                            home.player.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            home.player.id
                          "
                          style="color: black; font-weight: bold"
                        >
                          {{
                            home && home.player.jerseyNumber
                              ? home.player.jerseyNumber
                              : "-"
                          }}
                        </a>
                      </div>

                      <div
                        itemprop="endDate"
                        class="col flex-1 color-999"
                        data-v-0939e9fc=""
                      >
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/cricket/player/details/' +
                            home.player.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            home.player.id
                          "
                          style="color: black; font-weight: bold"
                        >
                          {{
                            timestampToDate(home.player.dateOfBirthTimestamp)
                          }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end home squad -->
            </div>
          </div>

          <!-- begin::if lineups is empty -->
          <div class="p-l flex-1" data-v-771adb1a="" v-else>
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span
                  class="color-p font-500 w-o-h active"
                  data-v-7c947e55=""
                  style="cursor: pointer"
                >
                  <span>
                    {{ $t("football.league-details.match-lineups") }}
                  </span>
                </span>
              </div>
              <!-- begin::home squad -->
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <!-- begin::header -->
                  <div
                    class="head flex justify-between align-center color-999 border-box fs-12"
                    data-v-0939e9fc=""
                  >
                    <div class="col" style="width: 200px" data-v-0939e9fc="">
                      {{ $t("football.league-details.players") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.position") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.jersey-no") }}
                    </div>

                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.birthday") }}
                    </div>
                  </div>
                  <!-- end::header -->
                  <div class="body" data-v-0939e9fc="">
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 300px"
                      data-v-975a1620=""
                    >
                      <img :src="bannerImagePath('no-data.png')" />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end home squad -->
            </div>
          </div>
          <!-- begin::rightsidebar -->
          <div class="p-r" data-v-29b39e42="">
            <div data-v-cf600afc="" v-if="!loadingH2H && !loading">
              <div
                id="Highlights"
                class="detail-box match-info flex flex-col"
                style="margin-top: 0; background-color: white"
                data-v-cf600afc=""
              >
                <div
                  id="matchInfoRef"
                  class="ml-16 mr-16 mb-16 matchInfoRef"
                  data-v-cf600afc=""
                >
                  <div
                    class="f-s-12 color-666 line-h-16 mt-8 m-b-8"
                    data-v-cf600afc=""
                  >
                    <br />

                    <p v-if="this.$i18n.locale === 'cn'">
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >
                      的比赛是 Twenty20
                      {{
                        matchInfo.tournament.uniqueTournament.name
                      }}。比赛将于2024年6月24日上午8:30开始。在Sri
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >的直播比分页面上，您可以找到所有
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >过去的交锋比赛结果。
                    </p>

                    <p v-else>
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >
                      match is part of
                      {{ matchInfo.tournament.uniqueTournament.name }}. The
                      match starts on
                      {{ timestampToDate(matchInfo.startTimestamp) }},
                      {{ timestampToTime(matchInfo.startTimestamp) }}
                      . Here on
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >
                      live score you can find all
                      <b>
                        {{ matchInfo.homeTeam.name }} vs
                        {{ matchInfo.awayTeam.name }}</b
                      >
                      previous results sorted by their H2H matches.
                    </p>

                    <br />
                    <b style="color: brown"
                      >{{ $t("football.league-details.facts") }}:</b
                    >
                    <br />
                    <ul>
                      <li>
                        <b>{{ $t("football.league-details.match-status") }}</b
                        >:
                        {{
                          matchInfo &&
                          matchInfo.status &&
                          matchInfo.status.description &&
                          matchInfo.status.description
                            ? matchInfo.status.description
                            : "-"
                        }}
                      </li>
                      <li>
                        <b>{{ $t("football.league-details.toss-win") }}</b
                        >:
                        {{
                          matchInfo && matchInfo.tossWin
                            ? matchInfo.tossWin
                            : "-"
                        }}
                      </li>
                      <li>
                        <b>{{ $t("football.league-details.toss-decision") }}</b
                        >:
                        {{
                          matchInfo && matchInfo.tossDecision
                            ? matchInfo.tossDecision
                            : "-"
                        }}
                      </li>

                      <li>
                        <b>{{ $t("football.league-details.league") }}</b
                        >:
                        {{
                          matchInfo &&
                          matchInfo.tournament &&
                          matchInfo.tournament.uniqueTournament &&
                          matchInfo.tournament.uniqueTournament.name
                            ? matchInfo.tournament.uniqueTournament.name
                            : "-"
                        }}
                      </li>

                      <li>
                        <b>{{ $t("football.league-details.season") }}</b
                        >:
                        {{
                          matchInfo && matchInfo.season && matchInfo.season.name
                            ? matchInfo.season.name
                            : "-"
                        }}
                      </li>

                      <li>
                        <b>{{ $t("football.league-details.venue") }}</b
                        >:
                        {{
                          matchInfo &&
                          matchInfo.venue &&
                          matchInfo.venue.city &&
                          matchInfo.venue.city.name
                            ? matchInfo.venue.city.name
                            : "-"
                        }}
                      </li>
                      <li>
                        <b>{{ $t("football.league-details.stadium") }}</b
                        >:
                        {{
                          matchInfo &&
                          matchInfo.venue &&
                          matchInfo.venue.stadium &&
                          matchInfo.venue.stadium.name
                            ? matchInfo.venue.stadium.name
                            : "-"
                        }}
                      </li>
                      <li>
                        <b> {{ $t("football.league-details.country") }}</b
                        >:
                        {{
                          matchInfo &&
                          matchInfo.venue &&
                          matchInfo.venue.country &&
                          matchInfo.venue.country.name
                            ? matchInfo.venue.country.name
                            : "-"
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div
              v-if="!loadingH2H && !loading && matchH2H"
              class="container-box mb-12 pb-24"
              data-v-7c947e55=""
              data-v-29b39e42=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span
                  class="color-p font-500 w-o-h text-dark"
                  data-v-7c947e55=""
                  style="color: black !important"
                >
                  {{ $t("football.league-details.head-to-head") }}
                </span>
                <span class="color-999 fs-12" data-v-7c947e55=""></span>
              </div>
              <div
                class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
                data-v-bb874c34=""
                data-v-29b39e42=""
                data-v-7c947e55=""
              >
                <div class="o-h team-box" data-v-bb874c34="">
                  <a
                    target="_blank"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/team/details/' +
                      matchInfo.homeTeam.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      matchInfo.homeTeam.id
                    "
                    class="item flex align-center justify-center flex-col cur-pointer"
                    data-v-bb874c34=""
                    ><img
                      :src="
                        matchInfo.homeTeam.id
                          ? 'https://sportyclub.online//images/cricket/teams/' +
                            matchInfo.homeTeam.id +
                            '/' +
                            matchInfo.homeTeam.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="logo"
                      data-v-bb874c34=""
                    />
                    <span
                      class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                      style="line-height: 24px"
                      data-v-bb874c34=""
                      >{{ matchInfo.homeTeam.name }}</span
                    >
                    <span
                      class="color-999"
                      data-v-bb874c34=""
                      style="
                        background-color: #5dbb60;
                        color: white;
                        padding: 6px;
                        border-radius: 7px;
                        font-size: 13px;
                        font-weight: bold;
                        margin-top: 1px;
                      "
                    >
                      {{ $t("football.league-details.total-wins") }}
                      <span data-v-bb874c34=""
                        >({{ matchH2H.homeWins }})</span
                      ></span
                    ></a
                  >
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/team/details/' +
                      matchInfo.awayTeam.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      matchInfo.awayTeam.id
                    "
                    class="item flex align-center justify-center flex-col cur-pointer"
                    data-v-bb874c34=""
                    ><img
                      :src="
                        matchInfo.awayTeam.id
                          ? 'https://sportyclub.online//images/cricket/teams/' +
                            matchInfo.awayTeam.id +
                            '/' +
                            matchInfo.awayTeam.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="logo"
                      data-v-bb874c34=""
                    />
                    <span
                      class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                      style="line-height: 24px"
                      data-v-bb874c34=""
                      >{{ matchInfo.awayTeam.name }}</span
                    >
                    <span
                      class="color-999"
                      data-v-bb874c34=""
                      style="
                        background-color: #5dbb60;
                        color: white;
                        padding: 6px;
                        border-radius: 7px;
                        font-size: 13px;
                        font-weight: bold;
                        margin-top: 1px;
                      "
                    >
                      {{ $t("football.league-details.total-wins") }}
                      <span data-v-bb874c34=""
                        >({{ matchH2H.awayWins }})</span
                      ></span
                    ></a
                  >
                </div>
                <div class="container-box mt-15" data-v-bb874c34="">
                  <div class="player flex mt-15" data-v-bb874c34="">
                    <a
                      class="player-logo"
                      :style="{
                        'background-image': 'url(' + bannerImagePath() + ')',
                        'background-size': 'cover',
                      }"
                      data-v-bb874c34=""
                    ></a>
                    <div
                      class="flex flex-col h-bar-100 ml-xs justify-around flex-1"
                      data-v-bb874c34=""
                    >
                      <a style="color: #000" data-v-bb874c34="">
                        {{ $t("football.league-details.total-draws") }}</a
                      >
                    </div>
                    <div
                      class="market-value font-500 color-p flex align-center justify-end"
                      data-v-bb874c34=""
                    >
                      {{ matchH2H.draws }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- end::rightsidebar -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      activeSquad: "home",
      matchInfo: null,
      loading: true,
      matchH2H: null,
      loadingH2H: true,
      loadingLineups: true,
    };
  },

  created() {
    this.getMatchInfo(this.matchId);
    this.getMatchH2H(this.matchId);
    this.getMatchLineups(this.matchId);
  },
  methods: {
    // method to get match Info
    getMatchInfo(matchId) {
      console.log(matchId);
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/single/match/details/${matchId}`
        )
        .then((response) => {
          this.matchInfo = response.data.event;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to return h2h of match
    getMatchH2H(matchId) {
      console.log(matchId);
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/single/match/h2h/${matchId}`)
        .then((response) => {
          this.matchH2H = response.data.teamDuel;
          this.loadingH2H = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get home team matches
    getMatchLineups(matchId) {
      console.log(matchId);
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/single/match/lineups/${matchId}`
        )
        .then((response) => {
          this.matchLineups = response.data;
          this.loadingLineups = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to convert timeStamp
    timestampToDate(timestamp) {
      // Check if timestamp is defined and numeric
      if (typeof timestamp !== "undefined" && !isNaN(timestamp)) {
        // Create a new Date object from the timestamp (in milliseconds)
        var date = new Date(timestamp * 1000);

        // Array of month names
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Extract day, month, and year
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        // Return formatted date
        return day + " " + months[monthIndex] + " " + year;
      } else {
        // If timestamp is undefined or not numeric, return a dash
        return "-";
      }
    },

    // method to toggle squad
    toggleSquad(team) {
      this.activeSquad = team;
    },

    // method to convert unix timestamp
    timestampToTime(timestamp) {
      const gmtOffset = 4 * 60 * 60 * 1000; // GMT+4 offset in milliseconds
      const adjustedTimestamp = timestamp * 1000 + gmtOffset; // Convert seconds to milliseconds and add GMT+4 offset
      const date = new Date(adjustedTimestamp);
      let hours = date.getUTCHours(); // Get UTC hours
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert hours to 12-hour format
      const formattedHours = hours < 10 ? "0" + hours : hours; // Add leading zero if hours < 10
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    },
  },
};
</script>
