<template>
  <div id="__layout">
    <div data-v-250f1619="" id="app" class="font-en">
      <div
        data-v-6efd1af3=""
        data-v-250f1619=""
        class="privacy-view view border-box"
      >
        <p
          data-v-6efd1af3=""
          class="t1 text-center"
          v-if="this.$i18n.locale == 'cn'"
        >
          隐私政策
        </p>
        <p data-v-6efd1af3="" class="t1 text-center" v-else>Privacy Policy</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          欢迎来到 Sportyclub Live 的隐私政策！
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          Welcome to the privacy policy of Sportyclub Live !
        </div>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          重要信息和我们是谁
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>
          IMPORTANT INFORMATION AND WHO WE ARE
        </p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们是 Sportyclub Live，总部位于 Concord Tower 12 楼
          阿联酋迪拜媒体城。本页中提及的“Sportyclub
          Live”、“我们”或“我们的”均属于 Sportyclub Live 作为实体
          负责处理您的数据。本隐私政策仅适用于
          我们的在线活动在您满足以下条件时有效：
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          We are Sportyclub Live, with our headquarters located at Concord
          Tower, Floor 12, Media City, Dubai UAE. References to "Sportyclub
          Live," "we," "us," or "our" in this page pertain to Sportyclub Live as
          the entity responsible for handling your data. This Privacy Policy
          applies only to our online activities and is valid when you :
        </div>

        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">在我们的网站上注册个人资料。</li>
          <li data-v-6efd1af3="">访问和/或使用我们的网站。</li>
        </ul>
        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">Register a profile on our website.</li>
          <li data-v-6efd1af3="">Access and/or utilize our website.</li>
        </ul>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          本政策不适用于线下或线下收集的任何信息。通过本网站以外的渠道。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          This policy is not applicable to any information collected offline or
          via channels other than this website.
        </div>

        <p data-v-6efd1af3="" class="t1" v-if="this.$i18n.locale == 'cn'">
          本隐私政策的目的
        </p>

        <p data-v-6efd1af3="" class="t1" v-else>
          PURPOSE OF THIS PRIVACY POLICY
        </p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          本隐私政策旨在告知您Sportyclub Live如何
          当您使用/访问我们的时收集、使用和处理您的信息
          网站。阅读本隐私政策以及其他任何政策非常重要
          我们在特定场合提供的隐私政策或通知 收集或处理有关您的信息。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          This privacy policy aims to inform you about how Sportyclub Live
          collects, uses, and processes your information when you use/access our
          Website. It's essential to read this privacy policy along with any
          other privacy policies or notices we provide on specific occasions
          when collecting or processing information about you.
        </div>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          第三方链接
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>THIRD-PARTY LINKS</p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们的平台可能包含第三方网站、插件和
          应用程序。单击这些链接或启用这些连接可能会
          允许第三方收集或共享有关您的数据。我们不
          控制这些第三方网站，并且不对其负责
          隐私声明。当您离开我们的平台时，我们强烈建议
          您阅读您访问的每个网站的隐私政策。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          Our Platforms may include links to third-party websites, plug-ins, and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our Platforms, we strongly
          recommend you to read the privacy policy of every website you visit.
        </div>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          您的信息是如何收集的？
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>
          HOW IS YOUR INFORMATION COLLECTED?
        </p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          当您与我们的服务互动时，我们可能会自动收集技术信息
          有关您的设备、浏览操作和模式的数据。我们 通过使用 cookie
          和其他类似方式收集此信息 技术。
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          As you interact with our Services, we may automatically collect
          Technical Data about your device, browsing actions and patterns. We
          collect this information by using cookies and other similar
          technologies.
        </div>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          我们如何使用您的信息？
        </p>

        <p data-v-6efd1af3="" class="t2" v-else>
          HOW IS YOUR INFORMATION COLLECTED?
        </p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们只会根据适用的规定使用您的信息
          法律。我们主要会在以下情况下使用您的个人数据：
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          We will utilize your information only in accordance with applicable
          laws. Primarily, we'll use your Personal Data in these situations:
        </div>
        <ul
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
          v-if="this.$i18n.locale == 'cn'"
        >
          <li data-v-6efd1af3="">我们需要遵守法律或监管义务。</li>
          <li data-v-6efd1af3="">
            我们向您发送电子邮件，提供我们产品的更新以及
            服务，以及任何条款和条件的更改，这 隐私政策或我们的 cookie 政策。
          </li>
          <li data-v-6efd1af3="">
            您出于以下目的向我们发送信息 参加促销或竞赛或者您有
            以其他方式获得您同意处理您的个人数据
          </li>
          <li data-v-6efd1af3="">
            凡需要我们为您提供各种配套客户服务的地方
            （例如您联系我们询问有关我们的问题 平台和/或向我们请求某些信息）。
          </li>
          <li data-v-6efd1af3="">
            直接或通过我们的合作伙伴之一与您沟通（包括客户服务），以便为您提供
            与网站相关的更新和其他信息，以及用于营销和促销目的a
          </li>
        </ul>

        <ul
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
          v-else
        >
          <li data-v-6efd1af3="">
            Where we need to comply with a legal or regulatory obligation.
          </li>
          <li data-v-6efd1af3="">
            Where we send you emails providing updates to our products and
            services, along with changes to any terms and conditions, this
            privacy policy or our cookie policy.
          </li>
          <li data-v-6efd1af3="">
            Where you have sent your information to us for the purpose of
            participating in a promotion or competition or where you have
            otherwise provided your consent to process your Personal Data
          </li>

          <li data-v-6efd1af3="">
            Where we need to provide various supporting customer services to you
            (such as where you contact us with a question in connection with our
            Platforms and/or request certain information from us).
          </li>

          <li data-v-6efd1af3="">
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposesa
          </li>
        </ul>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          饼干
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>COOKIES</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          与任何其他网站类似，Sportyclub Live 使用“cookies”。这些
          “cookies”用于保留数据，例如访问者的偏好
          以及他们在网站上访问或访问的页面。这些数据根据访问者的浏览器类型和/或其他相关信息定制我们的网页内容，有助于增强用户体验。
          <br />
          有关我们使用的 Cookie 以及如何管理您的偏好的更多信息，请参阅我们的<a
            href=""
            >什么是 Cookie</a
          >
          Cookie 同意。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          Similar to any other website, Sportyclub Live use 'cookies'. These
          'cookies' are employed to retain data, such as visitors’ preferences
          and the pages they accessed or visited on the website. This data helps
          enhance user experience by tailoring our web page content based on
          visitors’ browser type and/or other relevant information.
          <br />
          For more information about the cookies we use and how to manage your
          preferences, please see our <a href="">What Are Cookies</a> from
          Cookie Consent.
        </div>
        <p data-v-6efd1af3="" class="t1" v-if="this.$i18n.locale == 'cn'">
          您的权利
        </p>
        <p data-v-6efd1af3="" class="t1" v-else>YOUR'S RIGHTS</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          在某些情况下，您享有数据保护法规定的相关权利
          您的个人数据，包括以下权利：
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          Under certain circumstances, you have rights under Data Protection
          Laws in relation to your Personal Data, including the right to:
        </div>

        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">请求访问您的个人数据。</li>
          <li data-v-6efd1af3="">请求更正您的个人数据。</li>
          <li data-v-6efd1af3="">请求删除您的个人数据。</li>
          <li data-v-6efd1af3="">请求限制处理您的个人数据。</li>
          <li data-v-6efd1af3="">请求转移您的个人数据。</li>
        </ul>

        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">Request access to your Personal Data.</li>
          <li data-v-6efd1af3="">Request correction of your Personal Data.</li>
          <li data-v-6efd1af3="">Request erasure of your Personal Data.</li>
          <li data-v-6efd1af3="">
            Request restriction of processing your Personal Data.
          </li>
          <li data-v-6efd1af3="">Request transfer your Personal Data.</li>
        </ul>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          问题
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>QUESTIONS</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          如果您对本隐私政策有任何疑问或希望以其他方式
          行使您的权利（例如撤回您对接收信息的同意）
          营销传播），请通过邮件联系我们：info@sportyclub.live
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          If you have any questions about this privacy policy or wish to
          otherwise exercise your rights (such as to withdraw your consent from
          receiving marketing communications), please contact us by mail at:
          info@sportyclub.live
        </div>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          本隐私政策的变更
        </p>

        <p data-v-6efd1af3="" class="t2" v-else>
          CHANGES TO THIS PRIVACY POLICY
        </p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们可能会不时更改或更新本隐私政策。所有变更
          本隐私政策将发布在我们的此页面上 网站。每项更改均将在发布后生效。我们
          建议您定期重新访问并阅读本隐私政策，以确保 您了解最新的当前条款。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          We may, from time to time, change or update this privacy policy. All
          changes to this privacy policy will be published on this page of our
          website. Each change will become effective upon publication. We
          recommend that you revisit and read this privacy policy regularly to
          ensure that you are up-to-date with the current terms.
        </div>
      </div>
    </div>
  </div>
  <span>
    <MobileFooter />
  </span>
</template>

<script>
export default {};
</script>

<style>
</style>