<template>
  <span
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
  >
    <div data-v-d4c6fef0="" class="match-box">
      <div data-v-d4c6fef0="" class="status-tip-page">
        <i data-v-d4c6fef0="" class="iconfont icon-shaozi"></i>

        {{ $t("football.live-score.search-results") }}
      </div>
    </div>
  </span>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
    style="transform: translateY(0px); min-height: 200px"
  >
    <div class="flex justify-center align-center flex-col contentBox">
      <img :src="bannerImagePath('default-container.png')" class="nodata" />

      <div class="color-999 noDataText fs-12">
        {{ $t("football.live-score.nothing-found") }}
      </div>
    </div>
  </div>

  <!-- live search result -->
  <template v-else>

    <div
      data-v-46436e01=""
      class="vue-recycle-scroller scroller ready page-mode direction-vertical"
    >
      <div class="vue-recycle-scroller__item-wrapper">
        <div
          class="vue-recycle-scroller__item-view"
          style="transform: translateY(0px)"
          v-for="(league, key) in searchMatchListLive"
          :key="key"
        >
          <div data-v-46436e01="" class="user">
            <div data-v-46436e01="" class="flex align-center">
              <div data-v-a06a59d6="" data-v-46436e01="" style="width: 100%">
                <div
                  data-v-a06a59d6=""
                  class="list_title"
                  :class="
                    leagueFavoriteStatus[league[0].unique_tournament_id]
                      ? 'collectCom'
                      : ''
                  "
                >
                  <div data-v-a06a59d6="" class="topt flex">
                    <div
                      data-v-a06a59d6=""
                      style="z-index: -1; width: 13.03%; min-width: 124px"
                    ></div>
                    <div
                      data-v-a06a59d6=""
                      style="
                        z-index: -1;
                        margin-left: 10px;
                        width: 21%;
                        min-width: 200px;
                      "
                    ></div>
                    <div data-v-a06a59d6="" class="flex-1 headBoxRight">
                      <span
                        data-v-a06a59d6=""
                        class="watchNum"
                        style="margin-right: 1px"
                        ><i
                          data-v-a06a59d6=""
                          class="iconfont icon-renshu1"
                        ></i>
                        <span data-v-a06a59d6=""></span
                      ></span>
                    </div>
                    <div data-v-a06a59d6="" class="Moneyline">
                      <div
                        data-v-a06a59d6=""
                        class="text-center oddsTypeText oddsWidth1 bh"
                      >
                        {{ $t("football.league-details.to-win") }}
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="text-center oddsTypeText oddsWidth2 bh"
                      >
                        {{ $t("football.league-details.handicap") }}
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                      >
                        {{ $t("football.league-details.total") }}
                      </div>
                    </div>
                  </div>
                  <div data-v-2eea2747="" class="flex align-center topLeftBox">
                    <i
                      class="iconfont"
                      :class="
                        leagueFavoriteStatus[league[0].unique_tournament_id]
                          ? 'icon-yishoucang'
                          : 'icon-weishoucang'
                      "
                      @click="toggleFavorite(league[0].unique_tournament_id)"
                      style="visibility: hidden"
                    ></i>
                    <img
                      data-v-2eea2747=""
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo
                      "
                      :src="
                        league[0].leagueInfo.countryDetails.logo
                          ? league[0].leagueInfo.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      alt="China"
                      class="countryLogo"
                    />
                    <span data-v-2eea2747="" class="countryText">
                      <span
                        v-if="this.$i18n.locale == 'cn'"
                        data-v-f1b1cb6e=""
                        class="country-name minitext"
                      >
                        <span
                          v-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.cn_name }}:
                        </span>
                        <span
                          v-else-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                        </span>
                      </span>

                      <span
                        v-else
                        data-v-f1b1cb6e=""
                        class="country-name minitext"
                      >
                        <span
                          v-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}:
                        </span>
                        <span
                          v-else-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}:
                        </span>
                      </span>
                      :</span
                    >
                    <span
                      v-if="
                        league &&
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo
                      "
                    >
                      <a
                        v-if="league[0].hasLeagueDetails"
                        data-v-2eea2747=""
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/tennis/league/details/' +
                          league[0].leagueInfo.en_name
                            .toLowerCase()
                            .replace(/[\s/]+/g, '-') +
                          '/' +
                          league[0].unique_tournament_id
                        "
                        target="_blank"
                        class="compText"
                      >
                        {{
                          this.$i18n.locale == "cn" &&
                          league[0].leagueInfo.cn_name
                            ? league[0].leagueInfo.cn_name
                            : league[0].leagueInfo.en_name
                            ? league[0].leagueInfo.en_name
                            : ""
                        }}
                      </a>
                      <a
                        v-else
                        target="_blank"
                        class="compText"
                        style="cursor: not-allowed"
                      >
                        {{
                          this.$i18n.locale == "cn" &&
                          league[0].leagueInfo.cn_name
                            ? league[0].leagueInfo.cn_name
                            : league[0].leagueInfo.en_name
                            ? league[0].leagueInfo.en_name
                            : ""
                        }}
                      </a>
                    </span>
                  </div>
                </div>
                <div data-v-a06a59d6="">
                  <div
                    data-v-a06a59d6=""
                    class="list"
                    v-for="match in league"
                    :key="match.id"
                  >
                    <div
                      data-v-a06a59d6=""
                      class="flex align-center justify-center collectImgBox"
                    ></div>
                    <a
                      data-v-a06a59d6=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/tennis/match/details/' +
                        match.homeInfo.en_name
                          .toLowerCase()
                          .replace(/[\s/]+/g, '-') +
                        +'-vs-' +
                        match.awayInfo.en_name
                          .toLowerCase()
                          .replace(/[\s/]+/g, '-') +
                        +'/' +
                        match.id
                      "
                      target="_blank"
                      class="flex flex-1"
                      style="height: 100%"
                      ><div
                        data-v-a06a59d6=""
                        class="flex align-center listBox justify-around"
                      >
                        <span
                          data-v-a06a59d6=""
                          class="fs-13 matchTime"
                          style="color: rgb(153, 153, 153)"
                        >
                          {{
                            readableTime(this.$i18n.locale, match.match_time)
                          }}
                        </span>
                        <div
                          data-v-a06a59d6=""
                          class="flex flex-col color-999 fs-13 align-center text-center barItem color-r"
                        >
                          <span data-v-2eea2747=""
                            ><b>{{
                              match.status_id &&
                              this.FinishedMatches.includes(match.status_id)
                                ? $t("football.league-details.full-time")
                                : match?.statusDescription
                            }}</b></span
                          >
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="team flex flex-col padding5"
                      >
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 align-center flex"
                        >
                          <span data-v-2eea2747="" class="teamLogoBox"
                            ><img
                              data-v-2eea2747=""
                              v-if="match.homeInfo"
                              :src="
                                match.homeInfo.logo
                                  ? match.homeInfo.logo
                                  : bannerImagePath('default-player.png')
                              "
                          /></span>
                          <div
                            data-v-a06a59d6=""
                            itemprop="homeTeam"
                            class="w-o-h elseTeamName"
                          >
                            <span v-if="this.$i18n.locale == 'cn'">
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.cn_name
                                  ? match.homeInfo.cn_name
                                  : ""
                              }}
                            </span>
                            <span v-else>
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.en_name
                                  ? match.homeInfo.en_name
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 align-center flex"
                        >
                          <span data-v-2eea2747="" class="teamLogoBox"
                            ><img
                              data-v-2eea2747=""
                              v-if="match.awayInfo"
                              :src="
                                match.awayInfo.logo
                                  ? match.awayInfo.logo
                                  : bannerImagePath('default-player.png')
                              "
                          /></span>
                          <div
                            data-v-a06a59d6=""
                            itemprop="awayTeam"
                            class="w-o-h elseTeamName"
                          >
                            <span v-if="this.$i18n.locale == 'cn'">
                              {{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.cn_name
                                  ? match.awayInfo.cn_name
                                  : ""
                              }}
                            </span>
                            <span v-else>
                              {{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.en_name
                                  ? match.awayInfo.en_name
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="teamScore padding5"
                        v-if="
                          liveMatches.includes(match.status_id) ||
                          FinishedMatches.includes(match.status_id)
                        "
                      >
                        <div
                          data-v-a06a59d6=""
                          class="flex flex-col align-center"
                          style="height: 100%"
                        >
                          <div
                            data-v-a06a59d6=""
                            class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div data-v-a06a59d6="" class="bigScore">
                              <span data-v-a06a59d6="" class="scoreRed">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  (match.homeInfo.totalScore ||
                                    match.homeInfo.totalScore == 0)
                                    ? match.homeInfo.totalScore
                                    : "-"
                                }}</span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox">
                                  <div
                                    data-v-a06a59d6=""
                                    class="flex-1 text-center isVisible showTieBreak"
                                  >
                                    <span data-v-a06a59d6="" class=""
                                      ><span
                                        data-v-a06a59d6=""
                                        class="scoresBox"
                                        ><span
                                          data-v-a06a59d6=""
                                          class="tieBreak"
                                        >
                                          {{
                                            match &&
                                            match.homeInfo &&
                                            (match.homeInfo.x1 ||
                                              match.homeInfo.x1 == 0)
                                              ? match.homeInfo.x1
                                              : ""
                                          }}</span
                                        >
                                        {{
                                          match &&
                                          match.homeInfo &&
                                          (match.homeInfo.p1 ||
                                            match.homeInfo.p1 == 0)
                                            ? match.homeInfo.p1
                                            : "-"
                                        }}
                                      </span></span
                                    >
                                  </div>
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible showTieBreak"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      (match.homeInfo.x2 ||
                                        match.homeInfo.x2 == 0)
                                        ? match.homeInfo.x2
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    (match.homeInfo.p2 ||
                                      match.homeInfo.p2 == 0)
                                      ? match.homeInfo.p2
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible showTieBreak"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      (match.homeInfo.x3 ||
                                        match.homeInfo.x3 == 0)
                                        ? match.homeInfo.x3
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    (match.homeInfo.p3 ||
                                      match.homeInfo.p3 == 0)
                                      ? match.homeInfo.p3
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox">
                                  <span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      (match.homeInfo.x4 ||
                                        match.homeInfo.x4 == 0)
                                        ? match.homeInfo.x4
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    (match.homeInfo.p4 ||
                                      match.homeInfo.p4 == 0)
                                      ? match.homeInfo.p4
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                          </div>
                          <div
                            data-v-a06a59d6=""
                            class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div data-v-a06a59d6="" class="bigScore scoreRed">
                              {{
                                match &&
                                match.awayInfo &&
                                (match.awayInfo.totalScore ||
                                  match.awayInfo.totalScore == 0)
                                  ? match.awayInfo.totalScore
                                  : "-"
                              }}
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      (match.awayInfo.x1 ||
                                        match.awayInfo.x1 == 0)
                                        ? match.awayInfo.x1
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    (match.awayInfo.p1 ||
                                      match.awayInfo.p1 == 0)
                                      ? match.awayInfo.p1
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      (match.awayInfo.x2 ||
                                        match.awayInfo.x2 == 0)
                                        ? match.awayInfo.x2
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    (match.awayInfo.p2 ||
                                      match.awayInfo.p2 == 0)
                                      ? match.awayInfo.p2
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      (match.awayInfo.x3 ||
                                        match.awayInfo.x3 == 0)
                                        ? match.awayInfo.x3
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    (match.awayInfo.p3 ||
                                      match.awayInfo.p3 == 0)
                                      ? match.awayInfo.p3
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-a06a59d6="" class=""
                                ><span data-v-a06a59d6="" class="scoresBox"
                                  ><span data-v-a06a59d6="" class="tieBreak">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      (match.awayInfo.x4 ||
                                        match.awayInfo.x4 == 0)
                                        ? match.awayInfo.x4
                                        : ""
                                    }}</span
                                  >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    (match.awayInfo.p4 ||
                                      match.awayInfo.p4 == 0)
                                      ? match.awayInfo.p4
                                      : "-"
                                  }}
                                </span></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="flex-1 icon-setting"
                        style="border-right: 1px solid rgb(234, 234, 234)"
                      ></div>
                      <div
                        data-v-a06a59d6=""
                        class="flex Moneyline moneyLineContent"
                        style="position: relative"
                      >
                        <div data-v-a06a59d6="" class="flex flex-1 oddsItem">
                          <div
                            data-v-a06a59d6=""
                            class="text-center le padding5 lh oddsItemBox oddsWidth1"
                          >
                            <div data-v-a06a59d6="">
                              <div data-v-a06a59d6="">
                                <div
                                  data-v-a06a59d6=""
                                  class="oddhoverGreen oddColorGreen"
                                >
                                  {{
                                    match &&
                                    match.odds &&
                                    match.odds != "" &&
                                    match.odds.euro &&
                                    match.odds.euro != ""
                                      ? match.odds.euro[1].toFixed(2)
                                      : "-"
                                  }}
                                </div>
                                <div
                                  data-v-a06a59d6=""
                                  class="oddhoverGreen oddTop oddColorRed"
                                >
                                  {{
                                    match &&
                                    match.odds &&
                                    match.odds != "" &&
                                    match.odds.euro &&
                                    match.odds.euro != ""
                                      ? match.odds.euro[3].toFixed(2)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            data-v-a06a59d6=""
                            class="text-center flex le padding5 oddsItemBox oddsWidth2"
                          >
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 lh handicapColor"
                              style=""
                            >
                              <div data-v-a06a59d6="">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds != "" &&
                                  match.odds.asia &&
                                  match.odds.asia != ""
                                    ? match.odds.asia[2] > 0
                                      ? "-" + match.odds.asia[2].toFixed(2)
                                      : "+" +
                                        Math.abs(match.odds.asia[2]).toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div data-v-a06a59d6="" class="oddTop">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds !== "" &&
                                  match.odds.asia &&
                                  match.odds.asia !== "" &&
                                  match.odds.asia[2] !== undefined
                                    ? Math.abs(match.odds.asia[2]).toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 lh oddTextColor"
                              style=""
                            >
                              <div data-v-a06a59d6="" class="oddhoverGreen">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  match.odds.asia.length > 1
                                    ? match.odds.asia[1].toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="oddhoverGreen oddTop"
                              >
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  match.odds.asia.length > 1
                                    ? match.odds.asia[3].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            data-v-a06a59d6=""
                            class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                          >
                            <div
                              data-v-a06a59d6=""
                              class="flex-1 lh handicapColor"
                              style=""
                            >
                              <div
                                data-v-a06a59d6=""
                                style="text-align: center"
                              >
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  match.odds.bigSmall &&
                                  match.odds.bigSmall.length > 1
                                    ? match.odds.bigSmall[1].toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="oddTop"
                                style="text-align: center"
                              >
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  match.odds.bigSmall &&
                                  match.odds.bigSmall.length > 3
                                    ? match.odds.bigSmall[3].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px)"
      v-for="(league, key) in searchMatchListUpcoming"
      :key="key"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="flex align-center">
          <div data-v-a06a59d6="" data-v-46436e01="" style="width: 100%">
            <div
              data-v-a06a59d6=""
              class="list_title"
              :class="
                leagueFavoriteStatus[league[0].unique_tournament_id]
                  ? 'collectCom'
                  : ''
              "
            >
              <div data-v-a06a59d6="" class="topt flex">
                <div
                  data-v-a06a59d6=""
                  style="z-index: -1; width: 13.03%; min-width: 124px"
                ></div>
                <div
                  data-v-a06a59d6=""
                  style="
                    z-index: -1;
                    margin-left: 10px;
                    width: 21%;
                    min-width: 200px;
                  "
                ></div>
                <div data-v-a06a59d6="" class="flex-1 headBoxRight">
                  <span
                    data-v-a06a59d6=""
                    class="watchNum"
                    style="margin-right: 1px"
                    ><i data-v-a06a59d6="" class="iconfont icon-renshu1"></i>
                    <span data-v-a06a59d6=""></span
                  ></span>
                </div>
                <div data-v-a06a59d6="" class="Moneyline">
                  <div
                    data-v-a06a59d6=""
                    class="text-center oddsTypeText oddsWidth1 bh"
                  >
                    {{ $t("football.league-details.to-win") }}
                  </div>
                  <div
                    data-v-a06a59d6=""
                    class="text-center oddsTypeText oddsWidth2 bh"
                  >
                    {{ $t("football.league-details.handicap") }}
                  </div>
                  <div
                    data-v-a06a59d6=""
                    class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                  >
                    {{ $t("football.league-details.total") }}
                  </div>
                </div>
              </div>
              <div data-v-2eea2747="" class="flex align-center topLeftBox">
                <i
                  class="iconfont"
                  :class="
                    leagueFavoriteStatus[league[0].unique_tournament_id]
                      ? 'icon-yishoucang'
                      : 'icon-weishoucang'
                  "
                  @click="toggleFavorite(league[0].unique_tournament_id)"
                  style="visibility: hidden"
                ></i>
                <img
                  data-v-2eea2747=""
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo) ||
                      (league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name) ||
                      (league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name))
                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt="China"
                  class="countryLogo"
                />
                <span data-v-2eea2747="" class="countryText">
                  <span
                    v-if="this.$i18n.locale == 'cn'"
                    data-v-f1b1cb6e=""
                    class="country-name minitext"
                  >
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.cn_name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                    </span>
                  </span>

                  <span v-else data-v-f1b1cb6e="" class="country-name minitext">
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.name }}:
                    </span>
                  </span>
                  :</span
                >
                <span
                  v-if="
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo
                  "
                >
                  <a
                    v-if="league[0].hasLeagueDetails"
                    data-v-2eea2747=""
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/tennis/league/details/' +
                      league[0].leagueInfo.en_name
                        .toLowerCase()
                        .replace(/[\s/]+/g, '-') +
                      '/' +
                      league[0].unique_tournament_id
                    "
                    target="_blank"
                    class="compText"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                  <a
                    v-else
                    target="_blank"
                    class="compText"
                    style="cursor: not-allowed"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                </span>
              </div>
            </div>
            <div data-v-a06a59d6="">
              <div
                data-v-a06a59d6=""
                class="list"
                v-for="match in league"
                :key="match.id"
              >
                <div
                  data-v-a06a59d6=""
                  class="flex align-center justify-center collectImgBox"
                ></div>
                <a
                  data-v-a06a59d6=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/tennis/match/details/' +
                    match.homeInfo.en_name
                      .toLowerCase()
                      .replace(/[\s/]+/g, '-') +
                    +'-vs-' +
                    match.awayInfo.en_name
                      .toLowerCase()
                      .replace(/[\s/]+/g, '-') +
                    +'/' +
                    match.id
                  "
                  target="_blank"
                  class="flex flex-1"
                  style="height: 100%"
                  ><div
                    data-v-a06a59d6=""
                    class="flex align-center listBox justify-around"
                  >
                    <span
                      data-v-a06a59d6=""
                      class="fs-13 matchTime"
                      style="color: rgb(153, 153, 153)"
                    >
                      {{ readableTime(this.$i18n.locale, match.match_time) }}
                    </span>
                    <div
                      data-v-a06a59d6=""
                      class="flex flex-col color-999 fs-13 align-center text-center barItem color-r"
                    >
                      <span data-v-2eea2747=""
                        ><b>{{
                          match && match.statusDescription
                            ? match.statusDescription
                            : ""
                        }}</b></span
                      >
                    </div>
                  </div>
                  <div data-v-a06a59d6="" class="team flex flex-col padding5">
                    <div data-v-a06a59d6="" class="flex-1 align-center flex">
                      <span data-v-2eea2747="" class="teamLogoBox"
                        ><img
                          data-v-2eea2747=""
                          v-if="match.homeInfo"
                          :src="
                            match.homeInfo.logo
                              ? match.homeInfo.logo
                              : bannerImagePath('default-player.png')
                          "
                      /></span>
                      <div
                        data-v-a06a59d6=""
                        itemprop="homeTeam"
                        class="w-o-h elseTeamName"
                      >
                        <template v-if="match && match.homeInfo">
                          {{
                            this.$i18n.locale == "cn" && match.homeInfo.cn_name
                              ? match.homeInfo.cn_name
                              : match.homeInfo.en_name
                              ? match.homeInfo.en_name
                              : ""
                          }}
                        </template>
                      </div>
                    </div>
                    <div data-v-a06a59d6="" class="flex-1 align-center flex">
                      <span data-v-2eea2747="" class="teamLogoBox"
                        ><img
                          data-v-2eea2747=""
                          v-if="match.awayInfo"
                          :src="
                            match.awayInfo.logo
                              ? match.awayInfo.logo
                              : bannerImagePath('default-player.png')
                          "
                      /></span>
                      <div
                        data-v-a06a59d6=""
                        itemprop="awayTeam"
                        class="w-o-h elseTeamName"
                      >
                        <template v-if="match && match.awayInfo">
                          {{
                            this.$i18n.locale == "cn" && match.awayInfo.cn_name
                              ? match.awayInfo.cn_name
                              : match.awayInfo.en_name
                              ? match.awayInfo.en_name
                              : ""
                          }}
                        </template>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-a06a59d6=""
                    class="teamScore padding5"
                    v-if="
                      liveMatches.includes(match.status_id) ||
                      FinishedMatches.includes(match.status_id)
                    "
                  >
                    <div
                      data-v-a06a59d6=""
                      class="flex flex-col align-center"
                      style="height: 100%"
                    >
                      <div
                        data-v-a06a59d6=""
                        class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                        style="font-family: Roboto-Regular, Roboto"
                      >
                        <div data-v-a06a59d6="" class="bigScore">
                          <span data-v-a06a59d6="" class="scoreRed">
                            {{
                              match &&
                              match.homeInfo &&
                              (match.homeInfo.totalScore ||
                                match.homeInfo.totalScore == 0)
                                ? match.homeInfo.totalScore
                                : "-"
                            }}</span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox">
                              <div
                                data-v-a06a59d6=""
                                class="flex-1 text-center isVisible showTieBreak"
                              >
                                <span data-v-a06a59d6="" class=""
                                  ><span data-v-a06a59d6="" class="scoresBox"
                                    ><span data-v-a06a59d6="" class="tieBreak">
                                      {{
                                        match &&
                                        match.homeInfo &&
                                        (match.homeInfo.x1 ||
                                          match.homeInfo.x1 == 0)
                                          ? match.homeInfo.x1
                                          : ""
                                      }}</span
                                    >
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      (match.homeInfo.p1 ||
                                        match.homeInfo.p1 == 0)
                                        ? match.homeInfo.p1
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible showTieBreak"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  (match.homeInfo.x2 || match.homeInfo.x2 == 0)
                                    ? match.homeInfo.x2
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.homeInfo &&
                                (match.homeInfo.p2 || match.homeInfo.p2 == 0)
                                  ? match.homeInfo.p2
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible showTieBreak"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  (match.homeInfo.x3 || match.homeInfo.x3 == 0)
                                    ? match.homeInfo.x3
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.homeInfo &&
                                (match.homeInfo.p3 || match.homeInfo.p3 == 0)
                                  ? match.homeInfo.p3
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox">
                              <span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  (match.homeInfo.x4 || match.homeInfo.x4 == 0)
                                    ? match.homeInfo.x4
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.homeInfo &&
                                (match.homeInfo.p4 || match.homeInfo.p4 == 0)
                                  ? match.homeInfo.p4
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                        style="font-family: Roboto-Regular, Roboto"
                      >
                        <div data-v-a06a59d6="" class="bigScore scoreRed">
                          {{
                            match &&
                            match.awayInfo &&
                            (match.awayInfo.totalScore ||
                              match.awayInfo.totalScore == 0)
                              ? match.awayInfo.totalScore
                              : "-"
                          }}
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  (match.awayInfo.x1 || match.awayInfo.x1 == 0)
                                    ? match.awayInfo.x1
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.awayInfo &&
                                (match.awayInfo.p1 || match.awayInfo.p1 == 0)
                                  ? match.awayInfo.p1
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  (match.awayInfo.x2 || match.awayInfo.x2 == 0)
                                    ? match.awayInfo.x2
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.awayInfo &&
                                (match.awayInfo.p2 || match.awayInfo.p2 == 0)
                                  ? match.awayInfo.p2
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  (match.awayInfo.x3 || match.awayInfo.x3 == 0)
                                    ? match.awayInfo.x3
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.awayInfo &&
                                (match.awayInfo.p3 || match.awayInfo.p3 == 0)
                                  ? match.awayInfo.p3
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-a06a59d6="" class=""
                            ><span data-v-a06a59d6="" class="scoresBox"
                              ><span data-v-a06a59d6="" class="tieBreak">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  (match.awayInfo.x4 || match.awayInfo.x4 == 0)
                                    ? match.awayInfo.x4
                                    : ""
                                }}</span
                              >
                              {{
                                match &&
                                match.awayInfo &&
                                (match.awayInfo.p4 || match.awayInfo.p4 == 0)
                                  ? match.awayInfo.p4
                                  : "-"
                              }}
                            </span></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-a06a59d6=""
                    class="flex-1 icon-setting"
                    style="border-right: 1px solid rgb(234, 234, 234)"
                  ></div>

                  <div
                    data-v-a06a59d6=""
                    class="flex Moneyline moneyLineContent"
                    style="position: relative"
                  >
                    <div data-v-a06a59d6="" class="flex flex-1 oddsItem">
                      <div
                        data-v-a06a59d6=""
                        class="text-center le padding5 lh oddsItemBox oddsWidth1"
                      >
                        <div data-v-a06a59d6="">
                          <div data-v-a06a59d6="">
                            <div
                              data-v-a06a59d6=""
                              class="oddhoverGreen oddColorGreen"
                            >
                              {{
                                match &&
                                match.odds &&
                                match.odds != "" &&
                                match.odds.euro &&
                                match.odds.euro != ""
                                  ? match.odds.euro[1].toFixed(2)
                                  : "-"
                              }}
                            </div>
                            <div
                              data-v-a06a59d6=""
                              class="oddhoverGreen oddTop oddColorRed"
                            >
                              {{
                                match &&
                                match.odds &&
                                match.odds != "" &&
                                match.odds.euro &&
                                match.odds.euro != ""
                                  ? match.odds.euro[3].toFixed(2)
                                  : "-"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="text-center le padding5 lh oddsItemBox oddsWidth1"
                        style="display: none"
                      ></div>
                      <div
                        data-v-a06a59d6=""
                        class="text-center flex le padding5 oddsItemBox oddsWidth2"
                      >
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 lh handicapColor"
                          style=""
                        >
                          <div data-v-a06a59d6="" style="text-align: center">
                            {{
                              match &&
                              match.odds &&
                              match.odds != "" &&
                              match.odds.asia &&
                              match.odds.asia != ""
                                ? match.odds.asia[2].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div
                            data-v-a06a59d6=""
                            class="oddTop"
                            style="text-align: center"
                          >
                            {{
                              match &&
                              match.odds &&
                              match.odds != "" &&
                              match.odds.asia &&
                              match.odds.asia != ""
                                ? match.odds.asia[2].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-a06a59d6=""
                        class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                      >
                        <div
                          data-v-a06a59d6=""
                          class="flex-1 lh handicapColor"
                          style=""
                        >
                          <div data-v-a06a59d6="" style="text-align: center">
                            {{
                              match &&
                              match.odds &&
                              match.odds != "" &&
                              match.odds.asia &&
                              match.odds.asia != ""
                                ? match.odds.asia[2] > 0
                                  ? "-" + match.odds.asia[2].toFixed(2)
                                  : "+" +
                                    Math.abs(match.odds.asia[2]).toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div
                            data-v-a06a59d6=""
                            class="oddTop"
                            style="text-align: center"
                          >
                            {{
                              match &&
                              match.odds &&
                              match.odds != "" &&
                              match.odds.bigSmall &&
                              match.odds.bigSmall != ""
                                ? match.odds.bigSmall[3].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],

  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [51, 52, 53, 54, 55],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",
    };
  },

  computed: {
    thereIsUpcomingMatches() {
      return true;
    },
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `baseball_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`baseball_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`baseball_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);

      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }

      if (status_code == 3) {
        return "HT";
      }

      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }

      if (this.liveMatches.includes(status_code)) {
        return "90+";
      }
    },
  },
};
</script>
