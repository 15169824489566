<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-59710436="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-59710436="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-59710436="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.players-stats") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>

          <!-- begin::display data -->
          <div
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
          >
            <div class="mt-8 flex justify-between" data-v-37d31aab="">
              <div
                class="el-select select-transfers-year el-select--mini"
                style="width: 120px"
                data-v-37d31aab=""
              ></div>
            </div>
            <div class="table mt-8" data-v-37d31aab="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12"
                data-v-37d31aab=""
              >
                <div class="col" style="width: 116px" data-v-37d31aab="">
                  &nbsp;
                </div>
                <div
                  class="col flex-1 justify-start pl-15 border-box"
                  data-v-37d31aab=""
                >
                  #
                  <span style="margin-left: 48px" data-v-37d31aab="">{{
                    $t("football.league-details.players")
                  }}</span>
                </div>
                <div data-v-37d31aab="" class="col flex-1"> {{ $t("football.league-details.value") }}</div>
                <div data-v-37d31aab="" class="col flex-1"> {{ $t("football.league-details.matches") }}</div>
                <div data-v-37d31aab="" class="col flex-1"> {{ $t("football.league-details.innings") }}</div>
              </div>
              <div
                class="body flex fs-12 color-333 align-start"
                data-v-37d31aab=""
              >
                <div
                  class="flex flex-wrap"
                  style="width: 116px"
                  data-v-37d31aab=""
                >
                  <div
                    v-on:click="toggleTopPlayers('runs_Scored')"
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'runs_Scored'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                  >
                  {{ $t("football.league-details.runs-scored") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    v-on:click="toggleTopPlayers('wickets')"
                    :style="
                      activeTab == 'wickets'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                  >
                  {{ $t("football.league-details.wickets") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    v-on:click="toggleTopPlayers('hundreds')"
                    :style="
                      activeTab == 'hundreds'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                  >
                  {{ $t("football.league-details.most-centuries") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('fifties')"
                    :style="
                      activeTab == 'fifties'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                  >
                  {{ $t("football.league-details.most-fifties") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('fours')"
                    :style="
                      activeTab == 'fours'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                  >
                  {{ $t("football.league-details.most-fours") }}
                  </div>

                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('sixes')"
                    :style="
                      activeTab == 'sixes'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                  >
                  {{ $t("football.league-details.most-sixes") }}
                  </div>
                </div>

                <!-- loading -->
                <div
                  class="flex-1 align-start"
                  data-v-37d31aab=""
                  v-if="loading"
                >
                  <img
                    src="/images/default-loader.gif"
                    style="width: 20%; margin-left: -50px"
                  />
                </div>

                <!-- begin::player stats -->
                <div
                  class="flex-1 align-start"
                  data-v-37d31aab=""
                  v-if="!loading && playersStats"
                >
                  <div
                    class="flex flex-wrap align-start"
                    data-v-37d31aab=""
                    v-for="(property, index) in playersStats[activeTab]"
                    :key="property.id"
                  >
                    <div
                      data-v-37d31aab=""
                      class="row flex w-bar-100"
                      v-if="index < 10"
                    >
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/cricket/player/details' +
                          property.player.name.toLowerCase().replace(/\s/g, '-') +
                          '/' +
                          property.player.id
                        "
                        data-v-37d31aab=""
                        class="col cur-pointer justify-start flex-1 pl-15 border-box"
                        ><span data-v-37d31aab="" class="number color-999">{{
                          ++index
                        }}</span>
                        <span
                          data-v-37d31aab=""
                          class="player-logo mr-xs ml-15"
                        >
                          <img
                            data-v-37d31aab=""
                            :src="
                              property && property.player && property.player.id
                                ? 'https://sportyclub.online//images/cricket/players/' +
                                  property.player.id +
                                  '/' +
                                  property.player.id +
                                  '.png'
                                : bannerImagePath('default-player.png')
                            "
                                @error="
                              $event.target.src = bannerImagePath('default-player.png')
                            "
                          />
                        </span>
                        <span data-v-37d31aab="" class="flex flex-col fs-12">
                          <span data-v-37d31aab="" class="color-333">
                            {{ property.player.name }}
                          </span>
                        </span></a
                      >
                      <div
                        data-v-37d31aab=""
                        class="col flex-1 color-p font-500"
                      >
                        {{ property.statistics[activeTab] }}
                      </div>
                      <div
                        data-v-37d31aab=""
                        class="col flex-1 color-p font-500"
                      >
                        {{
                          property.statistics.matches
                            ? property.statistics.matches
                            : ""
                        }}
                      </div>
                      <div
                        data-v-37d31aab=""
                        class="col flex-1 color-p font-500"
                      >
                        {{
                          property.statistics.innings
                            ? property.statistics.innings
                            : "-"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end::display data -->
          <!-- begin::data is not available -->
          <div
            v-if="!loading && !playersStats"
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
          >
            <div class="border-box" data-v-51bacf47="">
              <br />
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <div
                  data-v-1eddfbef=""
                  class="flex justify-center align-center flex-col"
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px; margin-top: -400px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end::if data is not available -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Number,
      default: null,
    },
    seasonId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      activeTab: "runs_Scored",
      playersStats: null,
      loading: true,
    };
  },
  updated() {
    this.getPlayerStats(this.leagueId, this.seasonId);
  },
  created() {
    this.getPlayerStats(this.leagueId, this.seasonId);
  },

  methods: {
    // method to toggle dropdowns
    toggleTopPlayers(status) {
      this.activeTab = status;
    },
    getPlayerStats(leagueId, seasonId) {
      this.$axios
        .get(  this.BASE_SERVER_URI +
          `/api/cricket/league/season/players/stats/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.playersStats = response.data.topPlayers;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
