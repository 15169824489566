<template>
  <div class="w100" v-if="!loading">
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.league-details.featured-match") }}</span
      >
    </div>
    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px); min-height: 100px"
    >
      <div class="w100" v-for="(match, key) in todayFeaturedMatch" :key="key">
        <div class="list_active" style="width: 100%">
          <div
            class="list_title"
            bis_skin_checked="1"
            style="background-color: var(--main-theme-fav-color)"
          >
            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
              bis_skin_checked="1"
            >
              <div
                class="listImg van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
                bis_skin_checked="1"
              >
                <img
                  class="van-image__img"
                  :src="
                    match &&
                    match.tournament &&
                    match.tournament.uniqueTournament &&
                    match.tournament.uniqueTournament.id
                      ? `https://sportyclub.online//images/cricket/leagues/${match.tournament.uniqueTournament.id}/${match.tournament.uniqueTournament.id}.png`
                      : bannerImagePath('default-country.png')
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-country.png')
                  "
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>
              <span class="countryText" style="color: white">
                <span
                  data-v-89734b7c=""
                  class="countryText"
                  style="color: white"
                >
                  <span
                    data-v-f1b1cb6e=""
                    class="country-name minitext"
                    style="color: white"
                  >
                    <template v-if="match && match.tournament">
                      <template
                        v-if="
                          match.tournament.category &&
                          match.tournament.category.country &&
                          match.tournament.category.country.name
                        "
                      >
                        <span style="color: white">
                          {{ match.tournament.category.country.name }}
                        </span>
                      </template>
                      <template
                        v-else-if="
                          match.tournament.category &&
                          match.tournament.category.name
                        "
                      >
                        <span style="color: white">
                          {{ match.tournament.category.name }}
                        </span>
                      </template>
                      <template v-else></template>
                    </template>
                  </span>
                </span>
                :</span
              >
              <a
                v-if="
                  match &&
                  match.tournament &&
                  match.tournament.uniqueTournament &&
                  match.tournament.uniqueTournament.id
                "
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/league/details/' +
                  match.tournament.uniqueTournament.name +
                  '/' +
                  match.tournament.uniqueTournament.id
                "
                class="compText"
                style="font-size: 0.32rem; color: white"
              >
                {{
                  this.$i18n.locale == "cn" && match.tournament.cn_name
                    ? match.tournament.cn_name
                    : match.tournament.name
                    ? match.tournament.name
                    : ""
                }}
              </a>
              <a v-else class="compText">
                {{
                  this.$i18n.locale == "cn" &&
                  match.tournament &&
                  match.tournament.cn_name
                    ? match.tournament.cn_name
                    : match.tournament && match.tournament.name
                    ? match.tournament.name
                    : ""
                }}
              </a>
            </div>
          </div>

          <div class="w100 list_active">
            <div class="list" style="height: 1.38rem; position: relative">
              <div class="w100 flex h100 contentListBox">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/match/details/' +
                    match.homeTeam.name.toLowerCase() +
                    '-vs-' +
                    match.awayTeam.name.toLowerCase() +
                    '/' +
                    match.id
                  "
                  class="flex flex-1 h100 allBox"
                >
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ formatUnixTimestamp(match.startTimestamp) }}
                      </span>

                      <div class="flex fs-12 align-center color-r text-center">
                        <span class="fs-12" style="white-space: nowrap">{{
                          match?.status?.description
                        }}</span>
                      </div>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.homeTeam && match.homeTeam.id"
                                  :src="
                                    'https://sportyclub.online//images/cricket/teams/' +
                                    match.homeTeam.id +
                                    '/' +
                                    match.homeTeam.id +
                                    '.png'
                                  "
                                  lazy="loaded"
                                  alt="Punjab Royals"
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="homeTeam"
                              class="w-o-h fs-14 pl2"
                              style="max-width: 3.6rem"
                            >
                              &nbsp;
                              <template v-if="match && match.homeTeam"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.homeTeam?.cn_name
                                    ? match.homeTeam?.cn_name
                                    : match.homeTeam?.name
                                    ? match.homeTeam?.name
                                    : ""
                                }}({{ match.homeTeam?.shortName }})
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.awayTeam && match.awayTeam.id"
                                  :src="
                                    'https://sportyclub.online//images/cricket/teams/' +
                                    match.awayTeam.id +
                                    '/' +
                                    match.awayTeam.id +
                                    '.png'
                                  "
                                  lazy="loaded"
                                  alt="Punjab Royals"
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="homeTeam"
                              class="w-o-h fs-14 pl2"
                              style="max-width: 3.6rem"
                            >
                              &nbsp;
                              <template v-if="match && match.awayTeam"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.awayTeam?.cn_name
                                    ? match.awayTeam?.cn_name
                                    : match.awayTeam?.name
                                    ? match.awayTeam?.name
                                    : ""
                                }}({{ match.awayTeam?.shortName }})
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
export default {
  name: "FeaturedMatches",
  data() {
    return {
      loading: true,

      todayFeaturedMatch: null,
    };
  },
  mixins: [dateUtils],
  created() {
    this.getTodayFeaturedMatches();
  },
  methods: {
    getTodayFeaturedMatches() {
      this.$axios
        .get(`${this.BASE_SERVER_URI}/api/cricket/today/featured/match`)
        .then((response) => {
          console.log("featured match:" + response);
          this.todayFeaturedMatch = response.data.featuredEvents;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>