<template>
  <span
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
  >
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.live-score.search-results") }}
      </span>
    </div>
  </span>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    data-v-abef72c2=""
    class="flex justify-center align-center flex-col contentBox"
    style="height: 400px"
  >
    <img data-v-abef72c2="" src="/images/no-data-search.png" class="nodata" />
    <div data-v-abef72c2="" class="color-999 noDataText">
      {{ $t("football.live-score.nothing-found") }}
    </div>
  </div>

  <template v-else>
    <div style="min-height: 500px">
      <!-- live search result -->

      <div class="w100" v-for="(league, key) in searchMatchListLive" :key="key">
        <div style="width: 100%">
          <!-- begin::show league & country -->
          <div class="list_title" :class="false ? 'collectComp' : ''">
            <i
              v-if="false"
              class="iconfont collectImg"
              :class="false ? 'icon-yishoucang' : 'icon-weishoucang'"
              @click="toggleFavorite(league[0].unique_tournament_id)"
            ></i>
            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
            >
              <div
                class="listImg van-image van-image--round"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  class="van-image__img"
                  :src="
                    league[0] &&
                    league[0].tournament &&
                    league[0].tournament.uniqueTournament &&
                    league[0].tournament.uniqueTournament.id
                      ? `https://sportyclub.online//images/cricket/leagues/${league[0].tournament.uniqueTournament.id}/${league[0].tournament.uniqueTournament.id}.png`
                     : bannerImagePath('default-country.png')
                  "
                  @error="$event.target.src = bannerImagePath('default-country.png')"
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>
              <span class="countryText">
                <span data-v-f1b1cb6e="" class="country-name minitext">
                  <template v-if="league[0] && league[0].tournament">
                    <template
                      v-if="
                        league[0].tournament.category &&
                        league[0].tournament.category.country &&
                        league[0].tournament.category.country.name
                      "
                    >
                      {{ league[0].tournament.category.country.name }}
                    </template>
                    <template
                      v-else-if="
                        league[0].tournament.category &&
                        league[0].tournament.category.name
                      "
                    >
                      {{ league[0].tournament.category.name }}
                    </template>
                    <template v-else></template>
                  </template>
                </span>
                :</span
              >

              <a
                v-if="
                  league &&
                  league[0] &&
                  league[0].tournament &&
                  league[0].tournament.uniqueTournament &&
                  league[0].tournament.uniqueTournament.id
                "
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/league/details/' +
                  league[0].tournament.uniqueTournament.id +
                  '/' +
                  league[0].tournament.uniqueTournament.id
                "
                class="compText"
                style="font-size: 0.32rem"
              >
                {{
                  this.$i18n.locale == "cn" && league[0].tournament.cn_name
                    ? league[0].tournament.cn_name
                    : league[0].tournament.name
                    ? league[0].tournament.name
                    : ""
                }}
              </a>
              <a v-else class="compText">
                {{
                  this.$i18n.locale == "cn" &&
                  league[0].tournament &&
                  league[0].tournament.cn_name
                    ? league[0].tournament.cn_name
                    : league[0].tournament && league[0].tournament.name
                    ? league[0].tournament.name
                    : ""
                }}
              </a>
            </div>
            <div class="flex userNumBox">
              <!-- <span class="iconfont icon-renshu"></span> -->
              <span class="userNum"> </span>
            </div>
          </div>
          <!-- end::show league & country -->
          <!-- begin::match info -->
          <div class="w100 list_active">
            <div
              class="list"
              v-for="match in league"
              :key="match.id"
              style="height: 1.38rem; position: relative"
            >
              <div class="w100 flex h100 contentListBox">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/match/details/' +
                    match.homeTeam.name.toLowerCase() +
                    '-vs-' +
                    match.awayTeam.name.toLowerCase() +
                    '/' +
                    match.id
                  "
                  class="flex flex-1 h100 allBox"
                >
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ formatUnixTimestamp(match.startTimestamp) }}
                      </span>

                      <div class="flex fs-12 align-center color-r text-center">
                        <span class="fs-12" style="white-space: nowrap">{{
                          match?.status?.description
                        }}</span>
                      </div>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    match.homeTeam && match.homeTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        match.homeTeam.id +
                                        '/' +
                                        match.homeTeam.id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt=""
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h fs-14 pl2"
                              style="max-width: 3.6rem"
                            >
                              &nbsp;
                              <template v-if="match && match.homeTeam"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.homeTeam?.cn_name
                                    ? match.homeTeam?.cn_name
                                    : match.homeTeam?.name
                                    ? match.homeTeam?.name
                                    : ""
                                }}({{ match.homeTeam?.shortName }})
                              </template>
                            </div>
                            <img
                              v-if="
                                match.changes && match.changes.changes
                                  ? match.changes.changes.includes(
                                      'homeScore.innings'
                                    )
                                  : false
                              "
                              src="/images/banqiu-banzi.png"
                              alt="#"
                              class="svgPostop ballIcon ballIconLeft"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    match.awayTeam && match.awayTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        match.awayTeam.id +
                                        '/' +
                                        match.awayTeam.id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt=""
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h fs-14 pl2"
                              style="max-width: 3.6rem"
                            >
                              &nbsp;
                              <template v-if="match && match.awayTeam"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.awayTeam?.cn_name
                                    ? match.awayTeam?.cn_name
                                    : match.awayTeam?.name
                                    ? match.awayTeam?.name
                                    : ""
                                }}({{ match.awayTeam?.shortName }})
                              </template>
                            </div>
                            <img
                              v-if="
                                match.changes && match.changes.changes
                                  ? match.changes.changes.includes(
                                      'awayScore.innings'
                                    )
                                  : false
                              "
                              src="/images/banqiu-banzi.png"
                              alt="#"
                              class="svgPostop ballIcon ballIconLeft"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="icon-setting">
                      <span class="iconfont icon-shipinzhibo"></span>
                    </div>

                    <div class="teamScore">
                      <div class="teamScore">
                        <div class="w100 flex h100">
                          <div class="flex-1 h100 padding5">
                            <div
                              class="flex flex-col align-center w100"
                              style="height: 100%; line-height: 12px"
                            >
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center scoreWeight"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="scoreRed"
                                    style="font-size: 0.32rem; padding: 0px 2px"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      :class="{
                                        scoreRed: false,
                                      }"
                                    >
                                      <span
                                        v-for="inn in match.homeScore.innings"
                                        :key="inn"
                                        data-v-89734b7c=""
                                        class=""
                                      >
                                        {{ inn.score + "/" }}
                                        {{ inn.wickets }} ({{ inn.overs }})
                                        &nbsp;
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="scoreRed"
                                    style="font-size: 0.32rem; padding: 0px 2px"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      :class="{
                                        scoreRed: false,
                                      }"
                                    >
                                      <span
                                        v-for="inn in match.awayScore.innings"
                                        :key="inn"
                                        data-v-89734b7c=""
                                        class=""
                                      >
                                        {{ inn.score + "/" }}
                                        {{ inn.wickets }} ({{ inn.overs }})
                                        &nbsp;
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- end::match info -->
        </div>
      </div>

      <!-- upcoming search result -->
      <div class="w100">
        <div
          class="vue-recycle-scroller__item-view"
          style="transform: translateY(0px); min-height: 400px"
        >
          <div
            class="w100"
            v-for="(league, key) in searchMatchListUpcoming"
            :key="key"
          >
            <div class="list_active" style="width: 100%">
              <div
                class="list_title"
                bis_skin_checked="1"
                :class="false ? 'collectComp' : ''"
              >
                <i
                  v-if="false"
                  class="iconfont collectImg"
                  :class="false ? 'icon-yishoucang' : 'icon-weishoucang'"
                  @click="toggleFavorite(league[0].unique_tournament_id)"
                ></i>
                <div
                  class="flex align-center"
                  style="overflow: hidden; width: 8.4rem"
                  bis_skin_checked="1"
                >
                  <div
                    class="listImg van-image van-image--round"
                    itemprop="logo"
                    style="overflow: hidden; border-radius: 50%"
                    bis_skin_checked="1"
                  >
                    <img
                      class="van-image__img"
                      :src="
                        league[0] &&
                        league[0].tournament &&
                        league[0].tournament.uniqueTournament &&
                        league[0].tournament.uniqueTournament.id
                          ? `https://sportyclub.online//images/cricket/leagues/${league[0].tournament.uniqueTournament.id}/${league[0].tournament.uniqueTournament.id}.png`
                         : bannerImagePath('default-country.png')
                      "
                      @error="$event.target.src = bannerImagePath('default-country.png')"
                      alt=""
                      lazy="loaded"
                      style="object-fit: contain"
                    />
                  </div>
                  <span class="countryText">
                    <span data-v-89734b7c="" class="countryText">
                      <span data-v-f1b1cb6e="" class="country-name minitext">
                        <template v-if="league[0] && league[0].tournament">
                          <template
                            v-if="
                              league[0].tournament.category &&
                              league[0].tournament.category.country &&
                              league[0].tournament.category.country.name
                            "
                          >
                            {{ league[0].tournament.category.country.name }}
                          </template>
                          <template
                            v-else-if="
                              league[0].tournament.category &&
                              league[0].tournament.category.name
                            "
                          >
                            {{ league[0].tournament.category.name }}
                          </template>
                          <template v-else></template>
                        </template>
                      </span>
                    </span>
                    :</span
                  >
                  <a
                    v-if="
                      league[0] &&
                      league[0].tournament &&
                      league[0].tournament.uniqueTournament &&
                      league[0].tournament.uniqueTournament.id
                    "
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/league/details/' +
                      +league[0].tournament.uniqueTournament.name +
                      '/' +
                      league[0].tournament.uniqueTournament.id
                    "
                    class="compText"
                    style="font-size: 0.32rem"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].tournament.cn_name
                        ? league[0].tournament.cn_name
                        : league[0].tournament.name
                        ? league[0].tournament.name
                        : ""
                    }}
                  </a>
                  <a v-else class="compText">
                    {{
                      this.$i18n.locale == "cn" &&
                      league[0].tournament &&
                      league[0].tournament.cn_name
                        ? league[0].tournament.cn_name
                        : league[0].tournament && league[0].tournament.name
                        ? league[0].tournament.name
                        : ""
                    }}
                  </a>
                </div>
              </div>

              <div class="w100 list_active">
                <div
                  v-for="match in league"
                  :key="match.id"
                  class="list"
                  style="height: 1.38rem; position: relative"
                >
                  <div class="w100 flex h100 contentListBox">
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase() +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase() +
                        '/' +
                        match.id
                      "
                      class="flex flex-1 h100 allBox"
                    >
                      <div class="w100 flex h100 align-center">
                        <div
                          class="flex align-center justify-center listBox flex-column"
                        >
                          <span
                            class="fs-12 text-center"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{ formatUnixTimestamp(match.startTimestamp) }}
                          </span>

                          <div
                            class="flex fs-12 align-center color-r text-center"
                          >
                            <span class="fs-12" style="white-space: nowrap">{{
                              match?.status?.description
                            }}</span>
                          </div>
                        </div>
                        <div class="team flex flex-col padding5">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div class="flex-1 align-center flex">
                                <div
                                  class="flex align-center"
                                  style="width: 0.4rem"
                                >
                                  <div
                                    class="imgw4 van-image van-image--round"
                                    itemprop="logo"
                                    style="overflow: hidden; border-radius: 50%"
                                  >
                                    <img
                                      v-if="match.homeTeam && match.homeTeam.id"
                                      :src="
                                        'https://sportyclub.online//images/cricket/teams/' +
                                        match.homeTeam.id +
                                        '/' +
                                        match.homeTeam.id +
                                        '.png'
                                      "
                                      lazy="loaded"
                                      alt="Punjab Royals"
                                      class="van-image__img"
                                      style="object-fit: contain"
                                    />
                                  </div>
                                </div>
                                <div
                                  itemprop="homeTeam"
                                  class="w-o-h fs-14 pl2"
                                  style="max-width: 3.6rem"
                                >
                                  &nbsp;
                                  <template v-if="match && match.homeTeam"
                                    >{{
                                      this.$i18n.locale == "cn" &&
                                      match.homeTeam?.cn_name
                                        ? match.homeTeam?.cn_name
                                        : match.homeTeam?.name
                                        ? match.homeTeam?.name
                                        : ""
                                    }}({{ match.homeTeam?.shortName }})
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div class="flex-1 align-center flex">
                                <div
                                  class="flex align-center"
                                  style="width: 0.4rem"
                                >
                                  <div
                                    class="imgw4 van-image van-image--round"
                                    itemprop="logo"
                                    style="overflow: hidden; border-radius: 50%"
                                  >
                                    <img
                                      v-if="match.awayTeam && match.awayTeam.id"
                                      :src="
                                        'https://sportyclub.online//images/cricket/teams/' +
                                        match.awayTeam.id +
                                        '/' +
                                        match.awayTeam.id +
                                        '.png'
                                      "
                                      lazy="loaded"
                                      alt="Punjab Royals"
                                      class="van-image__img"
                                      style="object-fit: contain"
                                    />
                                  </div>
                                </div>
                                <div
                                  itemprop="homeTeam"
                                  class="w-o-h fs-14 pl2"
                                  style="max-width: 3.6rem"
                                >
                                  &nbsp;
                                  <template v-if="match && match.awayTeam"
                                    >{{
                                      this.$i18n.locale == "cn" &&
                                      match.awayTeam?.cn_name
                                        ? match.awayTeam?.cn_name
                                        : match.awayTeam?.name
                                        ? match.awayTeam?.name
                                        : ""
                                    }}({{ match.awayTeam?.shortName }})
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="icon-setting">
                          <span
                            v-if="liveMatches.includes(match.status_id)"
                            class="iconfont icon-shipinzhibo"
                          ></span>
                          <span
                            v-else
                            class="iconfont icon-donghuazhibo"
                          ></span>
                        </div>

                 
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import dateUtils from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";

export default {
  mixins: [dateUtils,DynamicImagePath],
  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },


  data() {
    return {
      liveMatches: [],
      FinishedMatches: [100],
      match_status: {
        FIRST_INNINGS_HOME_TEAM: 532,
        FIRST_INNINGS_AWAY_TEAM: 533,
        SECOND_INNINGS_HOME_TEAM: 534,
        SECOND_INNINGS_AWAY_TEAM: 535,
      },
    };
  },

  methods: {
    addAllScoreTennis(val1, val2, val3, val4) {
      val1 = val1 || 0;
      val2 = val2 || 0;
      val3 = val3 || 0;
      val4 = val4 || 0;
      return val1 + val2 + val3 + val4;
    },
  },
};
</script>
