<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <div v-if="!loading" class="w100">
    <div data-v-40b57f9e="" class="cricket_overview">
      <div
        data-v-826fe974=""
        data-v-40b57f9e=""
        class="odds-box"
        type="overview"
      >
        <div data-v-826fe974="" class="list">
          <div data-v-0e07873a="" data-v-826fe974="" class="oddsBox">
            <div data-v-0e07873a="" class="oddsContent">
              <p data-v-0e07873a="" class="oddsType flex">
                <span data-v-0e07873a="" class="placeholder"></span>

                <span
                  data-v-0e07873a=""
                  class="oddsItem flex-1 justify-center align-center"
                >
                  {{ $t("football.league-details.to-win") }}
                </span>
              </p>
              <div data-v-0e07873a="" class="oddsBox flex">
                <div
                  data-v-0e07873a=""
                  class="oddsBoxLeft flex justify-center align-center"
                >
                  <img
                    data-v-0e07873a=""
                    src="/images/odds/bet365.png"
                    alt="#"
                    class="companyLogo"
                  />
                </div>
                <div data-v-0e07873a="" class="oddsBoxContent flex-1">
                  <div data-v-0e07873a="" class="oddsItem flex flex-1 border2">
                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center borderR"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          liveMatchDetails.matchOdds &&
                          liveMatchDetails.matchOdds.bet365 &&
                          liveMatchDetails.matchOdds.bet365.euro &&
                          liveMatchDetails.matchOdds.bet365.euro[0] &&
                          liveMatchDetails.matchOdds.bet365.euro[0][1]
                            ? liveMatchDetails.matchOdds.bet365.euro[0][1].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >

                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          liveMatchDetails.matchOdds &&
                          liveMatchDetails.matchOdds.bet365 &&
                          liveMatchDetails.matchOdds.bet365.euro &&
                          liveMatchDetails.matchOdds.bet365.euro[0] &&
                          liveMatchDetails.matchOdds.bet365.euro[0][3]
                            ? liveMatchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
              <p data-v-0e07873a="" class="hint">
                {{ $t("football.league-details.gamble-responsibly") }}
              </p>
              <p data-v-0e07873a="" class="oddsTypeText mt-12">
                <span data-v-0e07873a="" class="openingBox"
                  ><span data-v-0e07873a="" class="chunk opening"></span>
                  <span data-v-0e07873a="">
                    {{ $t("football.league-details.opening-odds") }}
                  </span></span
                >
                <span data-v-0e07873a="" class="openingBox mt-8"
                  ><span data-v-0e07873a="" class="chunk pre-match"></span>
                  <span data-v-0e07873a="">{{
                    $t("football.league-details.pre-match-odds")
                  }}</span></span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-v-40b57f9e="" class="info"></div>
    </div>

    <div class="prefetch"></div>
  </div>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
    };
  },
  created() {
    this.getliveMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getliveMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/match/details/` + matchId)
        .then((response) => {
          this.liveMatchDetails = response.data.matchDetails;
          console.log(this.liveMatchDetails.matchOdds.bet365.euro[0].length);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
