<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-6ad89ef3="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-6ad89ef3="">
      <div class="content-box" data-v-6ad89ef3="">
        <ul class="breadcrumb" data-v-6ad89ef3="">
          <li data-v-6ad89ef3="">
            <a
              :href="'/' + this.$i18n.locale + '/live/basketball'"
              class="link"
              data-v-6ad89ef3=""
            >
              {{ $t("football.league-details.basketball-live-score") }}
            </a>
          </li>

          <li data-v-6ad89ef3="">
            <h1 class="text" data-v-6ad89ef3="">
              {{
                this.$i18n.locale == "cn" && teamDetails
                  ? teamDetails.cn_name
                  : teamDetails.name
              }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="team" data-v-6ad89ef3="">
      <div class="team-header" data-v-2f158089="" data-v-6ad89ef3="">
        <div class="content border-box flex align-center" data-v-2f158089="">
          <div class="p-l flex-1 flex align-center" data-v-2f158089="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-2f158089=""
            >
              <img
                :src="
                  teamDetails && teamDetails.logo
                    ? teamDetails.logo
                   : bannerImagePath('default-nation.png')
                "
                class="team-logo"
                data-v-2f158089=""
              />
              <div class="pl-15" data-v-2f158089="">
                <div class="fs-24 font-500 color-333" data-v-2f158089="">
                  <span data-v-2f158089="">
                    {{
                      this.$i18n.locale == "cn" && teamDetails
                        ? teamDetails.cn_name
                        : teamDetails.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- begin::team stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-2f158089=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ $t("football.league-details.stats") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.country") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  <span v-if="this.$i18n.locale == 'cn'">
                    <small>
                      {{
                        teamDetails &&
                        teamDetails.countryDetails &&
                        teamDetails.countryDetails.cn_name
                          ? teamDetails.countryDetails.cn_name
                          : "-"
                      }}
                    </small>
                  </span>

                  <span v-if="this.$i18n.locale == 'en'">
                    <small>
                      {{
                        teamDetails &&
                        teamDetails.countryDetails &&
                        teamDetails.countryDetails.en_name
                          ? teamDetails.countryDetails.en_name
                          : "-"
                      }}
                    </small>
                  </span>
                </div>
              </div>

              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.league") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  <span v-if="teamDetails && teamDetails.leagueDetails">
                    <small>
                      {{
                        this.$i18n.locale == "cn"
                          ? teamDetails.leagueDetails.cn_name
                          : teamDetails.leagueDetails.short_name
                      }}
                    </small>
                  </span>
                  <span v-else> - </span>
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.is-national") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    teamDetails && teamDetails.national
                      ? $t("football.league-details.yes")
                      : $t("football.league-details.no")
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- end::team stats -->
        </div>
      </div>

      <!-- begin::team squad -->
      <div class="wrapper" data-v-6ad89ef3="">
        <div class="flex" data-v-771adb1a="" data-v-6ad89ef3="">
          <div class="p-l flex-1" data-v-771adb1a="">
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ $t("football.league-details.team-squad") }}
                </span>
              </div>
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <div
                    class="head flex justify-between align-center color-999 border-box fs-12"
                    data-v-0939e9fc=""
                  >
                    <div class="col" style="width: 200px" data-v-0939e9fc="">
                      {{ $t("football.league-details.players") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.age") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.height") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.weight") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.shirt-number") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.position") }}
                    </div>
                  </div>
                  <div class="body" data-v-0939e9fc="">
                    <div class="border-box" data-v-51bacf47="">
                      <br />
                    </div>
                    <div v-if="teamSquad && teamSquad != ''">
                      <div
                        class="row flex fs-12 color-333 cur-pointer"
                        data-v-0939e9fc=""
                        v-for="team in teamSquad"
                        :key="team.id"
                      >
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/basketball/player/details/' +
                            team.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            team.id
                          "
                          class="col justify-start border-box"
                          style="width: 200px; padding-left: 12px"
                          data-v-0939e9fc=""
                        >
                          <span class="flex flex-col fs-12" data-v-0939e9fc=""
                            ><span class="color-p" data-v-0939e9fc=""
                              ><span data-v-0939e9fc="">
                                {{
                                  this.$i18n.locale == "cn"
                                    ? team.cn_name
                                    : team.name
                                }}
                              </span></span
                            >
                            <span
                              class="flex align-center color-999 s-0-85"
                              style="transform-origin: left"
                              data-v-0939e9fc=""
                            >
                              <span data-v-0939e9fc="">
                                {{ team.nationality }}
                              </span></span
                            ></span
                          ></a
                        >
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team.age }}
                        </div>
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team.height }}
                          <span data-v-0939e9fc="">cm</span>
                        </div>
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team.weight }}
                          <span data-v-0939e9fc="">kg</span>
                        </div>
                        <div class="col flex-1 color-p" data-v-0939e9fc="">
                          {{ team.shirt_number }}
                        </div>
                        <div
                          itemprop="endDate"
                          class="col flex-1 color-999"
                          data-v-0939e9fc=""
                        >
                          {{ team.position }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 500px"
                      v-else
                    >
                      <img :src="bannerImagePath('default-container.png')" />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-r" data-v-771adb1a="">
            <div>
              <div class="right" data-v-5021e98f="">
                <div class="tpBox" data-v-4291ef99="" data-v-5021e98f="">
                  <div class="fs-12 color-999 pl-15 pr-15" data-v-4291ef99="">
                    <div class="padTop">
                      {{ $t("football.league-details.team-info") }}
                    </div>
                  </div>
                  <div class="table TopPlayer" data-v-4291ef99="">
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 400px"
                      data-v-4291ef99=""
                    >
                      <img
                        :src="bannerImagePath('no-data.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::team squad -->
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>

<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
    };
  },

  created() {
    // Accessing the ID parameter from the routes
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getTeamSquad(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team squad
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/squad/${teamId}`)
        .then((response) => {
          this.teamSquad = response.data.teamPlayers;
          this.totlePlayers = response.data.teamPlayers.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
