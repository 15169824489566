<template>
  <div
    class="ft_teams view border-box"
    data-v-3aaba216=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div
      class="teamheader"
      data-v-3aaba216=""
      style="background-color: #8b2231"
    >
      <!-- backfill -->
      <div class="backFil" style="background-color: #8b2231" data-v-3aaba216="">
        <div class="header" data-v-48b34d9b="" data-v-3aaba216="" style="">
          <div class="van-sticky">
            <header
              class="first-header"
              style="background: #8b2231"
              data-v-48b34d9b=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-48b34d9b=""
              >
                <div
                  class="left van-col van-col--4"
                  data-v-48b34d9b=""
                  @click="goBack"
                >
                  <span
                    class="icon iconfont myIcon iconback"
                    style="opacity: 0.6"
                    data-v-48b34d9b=""
                  ></span>
                </div>
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="font-size: 0.4rem"
                  data-v-48b34d9b=""
                >
                  <span
                    data-v-48b34d9b=""
                    class="formatClass"
                    style="opacity: 1"
                    ><span data-v-48b34d9b="">
                      <span data-v-48b34d9b=""></span>
                      <span data-v-48b34d9b=""></span>
                    </span>
                  </span>
                </div>
                <div class="right van-col van-col--4" data-v-48b34d9b=""></div>
              </div>
            </header>
          </div>
        </div>
      </div>
      <!-- end backfill -->
      <!-- <i class="iconfont iconback" data-v-db02cb7a="" @click="goBack"></i> -->
      <!-- start commonHeadInfo -->
      <div
        data-v-3aaba216=""
        class="commonHeadInfo flex align-center justify-center flex-column"
        style="background-color: rgb(139, 34, 49)"
      >
        <div
          data-v-3aaba216=""
          class="team_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
        >
          <img
            :src="
              teamDetails && teamDetails.logo
                ? teamDetails.logo + '!w60'
               : bannerImagePath('default-nation.png')
            "
            :alt="
              this.$i18n.locale == 'cn' && teamDetails && teamDetails.cn_name
                ? teamDetails.cn_name
                : teamDetails.name
                ? teamDetails.name
                : ''
            "
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div data-v-3aaba216="" class="team_name">
          {{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
              ? teamDetails.name
              : ""
          }}
        </div>
        <div data-v-3aaba216="" class="des flex align-center justify-center">
          <div
            data-v-3aaba216=""
            class="country_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
          >
            <img
              :src="
                teamDetails &&
                teamDetails.countryDetails &&
                teamDetails.countryDetails.logo
                  ? teamDetails.countryDetails.logo + '!w60'
                 : bannerImagePath('default-country.png')
              "
              alt=""
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span data-v-3aaba216="" class="country">
            <template v-if="teamDetails">
              <template v-if="this.$i18n.locale == 'cn'">
                <template
                  v-if="
                    teamDetails.countryDetails &&
                    teamDetails.countryDetails.cn_name
                  "
                >
                  {{ teamDetails.countryDetails.cn_name }}
                </template>
                <template
                  v-else-if="
                    teamDetails.categoryDetails &&
                    teamDetails.categoryDetails.cn_name
                  "
                >
                  {{ teamDetails.categoryDetails.cn_name }}
                </template>
                <template
                  v-else-if="
                    teamDetails.countryDetails &&
                    teamDetails.countryDetails.name
                  "
                >
                  {{ teamDetails.countryDetails.name }}
                </template>
                <template
                  v-else-if="
                    teamDetails.categoryDetails &&
                    teamDetails.categoryDetails.name
                  "
                >
                  {{ teamDetails.categoryDetails.name }}
                </template>
              </template>
              <template v-else>
                <template
                  v-if="
                    teamDetails.countryDetails &&
                    teamDetails.countryDetails.name
                  "
                >
                  {{ teamDetails.countryDetails.name }}
                </template>
                <template
                  v-else-if="
                    teamDetails.categoryDetails &&
                    teamDetails.categoryDetails.name
                  "
                >
                  {{ teamDetails.categoryDetails.name }}
                </template>
                <template v-else></template>
              </template>
            </template>
          </span>
        </div>
      </div>
      <!-- end common header -->
    </div>
    <!-- begin tabs -->
    <div data-v-3aaba216="" style="">
      <div class="van-sticky" style="">
        <div data-v-3aaba216="" class="van-tabs van-tabs--line">
          <div
            class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
          >
            <div
              role="tablist"
              class="van-tabs__nav van-tabs__nav--line"
              style="
                border-color: rgb(255, 255, 255);
                background: rgb(139, 34, 49);
              "
            >
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--active van-tab--complete"
                style="color: rgba(255, 255, 255, 0.6)"
                :style="
                  activeTab === 'overview' ? 'color: rgba(255, 255, 255)' : ''
                "
                v-on:click="toggleTab('overview')"
              >
                <span class="van-tab__text">Overview</span>
              </div>
              <div
                role="tab"
                class="van-tab van-tab--complete"
                style="color: rgba(255, 255, 255, 0.6)"
                :style="
                  activeTab === 'squad' ? 'color: rgba(255, 255, 255)' : ''
                "
                v-on:click="toggleTab('squad')"
              >
                <span class="van-tab__text">{{
                  $t("football.league-details.squad")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end tabs -->
    <!-- begin of breadcrumb -->

    <div data-v-e49cc106="" data-v-3aaba216="" class="breadcrumb-box iconfont">
      <div data-v-e49cc106="" class="display-i-l">
        <a
          data-v-e49cc106=""
          :href="'/' + this.$i18n.locale + '/live/basketball'"
          target=""
          class="ele link"
        >
          <span data-v-e49cc106="">
            {{ $t("basketball.league-details.basketball-live-score") }}
          </span>
        </a>
      </div>

      <span data-v-e49cc106="" class="jt"></span>

      <h1 data-v-e49cc106="" class="ele text lastText" style="display: inline">
        <span data-v-e49cc106="">
          {{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
              ? teamDetails.name
              : ""
          }}
          {{ $t("football.league-details.schedule") }},
          {{ $t("football.league-details.standings") }}
        </span>
      </h1>
    </div>

    <!-- end of breadcrumb -->

    <div v-if="activeTab === 'overview'" class="scheduleBox" data-v-db02cb7a="">
      <div
        class="flex justify-center align-center flex-col"
        style="height: 400px"
        data-v-4291ef99=""
      >
        <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
        <div class="color-999 fs-12 mt-12">
          {{ $t("football.live-score.temporarily-no-data") }}
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'squad'" class="content" data-v-3aaba216="">
      <div data-v-582c2586="" data-v-3aaba216="" class="team_squad">
        <div data-v-582c2586="" class="w100">
          <div data-v-582c2586="" class="title">
            <div data-v-582c2586="" class="flex align-center">
              <div
                data-v-582c2586=""
                class="shirtNumber"
                style="margin-right: 0.27rem"
              >
                #
              </div>
              {{ $t("football.league-details.players") }}
            </div>
            <span data-v-582c2586="">
              {{ $t("football.league-details.position") }}
            </span>
          </div>
          <div
            v-if="teamSquad && teamSquad != ''"
            data-v-582c2586=""
            class="content list_active"
          >
            <a
              v-for="player in teamSquad"
              :key="player"
              data-v-582c2586=""
              :href="
                    '/' +
                    this.$i18n.locale +
                    '/basketball/player/details/' +
                    player.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    player.id
                  "
              class="w100 itemContent"
              style="display: block"
              ><div
                data-v-582c2586=""
                class="w100 h100 flex align-center justify-between"
              >
                <div data-v-582c2586="" class="flex-1 contentBox">
                  <div data-v-582c2586="" class="shirtNumber">
                    {{ player?.shirt_number }}
                  </div>
                  <div
                    data-v-582c2586=""
                    class="playerLogo van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="player?.logo + '!w60'"
                      alt="Jimmy Butler"
                      class="van-image__img"
                      style="object-fit: cover"
                    />
                  </div>
                  <div data-v-582c2586="" class="playerInfo">
                    <span data-v-582c2586="" class="name">{{
                      this.$i18n.locale == "cn" && player && player.cn_name
                        ? player.cn_name
                        : player.name
                        ? player.name
                        : ""
                    }}</span>
                  </div>
                </div>
                <span data-v-582c2586="" class="marketValue">{{
                  player?.position
                }}</span>
              </div>
            </a>
          </div>
          <div v-else data-v-582c2586="" class="content list_active">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 500px"
            >
              <img :src="bannerImagePath('default-container.png')" />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],

  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
      activeTab: "overview",
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getTeamSquad(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          //   this.getTeamSquad(teamId);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team squad
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/team/squad/${teamId}`)
        .then((response) => {
          this.teamSquad = response.data.teamPlayers;
          this.totlePlayers = response.data.teamPlayers.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
