<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-8948368c="" v-if="!loading">
    <!-- begin::breadcrumb -->
    <div class="breadcrumb-box" data-v-8948368c="">
      <div class="content-box" data-v-8948368c="">
        <ul class="breadcrumb" data-v-8948368c="">
          <li data-v-8948368c="">
            <a
              :href="'/' + this.$i18n.locale + '/live/basketball'"
              class="link"
              data-v-8948368c=""
            >
              {{ $t("football.league-details.basketball-live-score") }}
            </a>
          </li>
          <li data-v-8948368c="">
            {{
              leagueDetails && this.$i18n.locale == "cn"
                ? leagueDetails.cn_name
                : leagueDetails.name
            }}
          </li>
          <li data-v-8948368c="">
            <h1 class="text" data-v-8948368c="">
              {{ $t("football.league-details.winners") }},
              {{ $t("football.league-details.champions") }},
              {{ $t("football.league-details.standings") }} &nbsp;
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::breadcrumb -->
    <div class="team" data-v-8948368c="">
      <!-- begin::title holder info -->
      <div class="competition-header" data-v-26a596f7="" data-v-8948368c="">
        <div class="content border-box flex align-center" data-v-26a596f7="">
          <div class="p-l flex-1 flex align-center" data-v-26a596f7="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-26a596f7=""
            >
              <img
                :src="leagueDetails.logo + '!w100'"
                :alt="
                  leagueDetails && leagueDetails.name ? leagueDetails.name : ''
                "
                :title="
                  leagueDetails && leagueDetails.name ? leagueDetails.name : ''
                "
                class="team-logo"
                data-v-26a596f7=""
              />
              <!-- begin::title -->
              <div class="pl-15 pr-15" data-v-26a596f7="">
                <div class="fs-24 font-500 flex" data-v-26a596f7="">
                  <span
                    style="
                      max-width: 330px;
                      font-weight: 500;
                      font-family: Rubik-Medium, MicrosoftYaHei,
                        MicrosoftYaHei-Bold, PingFang SC;
                    "
                    data-v-26a596f7=""
                  >
                    {{
                      leagueDetails &&
                      this.$i18n.locale == "cn" &&
                      leagueDetails.name
                        ? leagueDetails.cn_name
                        : leagueDetails.name
                    }}
                  </span>
                </div>
                <div class="mt-8 flex" data-v-26a596f7="">
                  <div class="country-logo" data-v-26a596f7="">
                    <img
                      :src="
                        leagueDetails &&
                        leagueDetails.countryDetails &&
                        leagueDetails.countryDetails.logo
                          ? leagueDetails.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      data-v-26a596f7=""
                    />
                  </div>

                  <span
                    v-if="leagueDetails && leagueDetails.countryDetails"
                    class="ml-xs fs-14 color-666"
                    style="line-height: 24px"
                    data-v-26a596f7=""
                  >
                    <span v-if="this.$i18n.locale == 'cn'">
                      {{
                        this.$i18n.locale == "cn"
                          ? leagueDetails.countryDetails.cn_name
                          : leagueDetails.countryDetails.name
                      }}
                    </span>

                    <span v-else>
                      {{ leagueDetails.countryDetails.name }}
                    </span>
                  </span>

                  <span
                    v-else-if="leagueDetails && leagueDetails.categoryDetails"
                    class="ml-xs fs-14 color-666"
                    style="line-height: 24px"
                    data-v-26a596f7=""
                  >
                    <span v-if="this.$i18n.locale == 'cn'">
                      {{
                        this.$i18n.locale == "cn"
                          ? leagueDetails.categoryDetails.cn_name
                          : leagueDetails.categoryDetails.name
                      }}
                    </span>
                    <span v-else>
                      {{ leagueDetails.categoryDetails.name }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- display: none; -->
              <!-- end::title -->
              <div
                class="flex flex-col fs-12 pl-15 ml-l"
                style="border-left: 1px dashed #dcdddf"
                data-v-26a596f7=""
              >
                <div class="flex mb-xxs" data-v-26a596f7="">
                  <div
                    class="color-999 font-300"
                    style="width: 140px"
                    data-v-26a596f7=""
                  >
                    {{ $t("football.league-details.category") }} :
                  </div>
                  <a
                    v-if="this.$i18n.locale == 'cn'"
                    class="color-333 w-o-h cur-pointer"
                    style="max-width: 240px"
                    data-v-26a596f7=""
                    >{{
                      leagueDetails &&
                      leagueDetails.categoryDetails &&
                      leagueDetails.categoryDetails.cn_name
                        ? leagueDetails.categoryDetails.cn_name
                        : leagueDetails.categoryDetails &&
                          leagueDetails.categoryDetails.name
                        ? leagueDetails.categoryDetails.name
                        : "--"
                    }}
                  </a>

                  <a
                    v-else
                    class="color-333 w-o-h cur-pointer"
                    style="max-width: 240px"
                    data-v-26a596f7=""
                  >
                    {{
                      leagueDetails &&
                      leagueDetails.categoryDetails &&
                      leagueDetails.categoryDetails.name
                        ? leagueDetails.categoryDetails.name
                        : "--"
                    }}
                  </a>
                </div>
                <div class="flex mb-xxs" data-v-26a596f7="">
                  <div
                    class="color-999 font-300"
                    style="width: 140px"
                    data-v-26a596f7=""
                  >
                    {{ $t("football.league-details.country") }} :
                  </div>
                  <a
                    v-if="this.$i18n.locale == 'cn'"
                    class="color-333 w-o-h cur-pointer"
                    style="max-width: 240px"
                    data-v-26a596f7=""
                    >{{
                      leagueDetails &&
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.cn_name
                        ? leagueDetails.countryDetails.cn_name
                        : leagueDetails.countryDetails &&
                          leagueDetails.countryDetails.name
                        ? leagueDetails.countryDetails.name
                        : "--"
                    }}
                  </a>

                  <a
                    v-else
                    class="color-333 w-o-h cur-pointer"
                    style="max-width: 240px"
                    data-v-26a596f7=""
                  >
                    {{
                      leagueDetails &&
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.name
                        ? leagueDetails.countryDetails.name
                        : "--"
                    }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- begin::current seasom stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-26a596f7=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{
                  leagueDetails &&
                  leagueDetails.curr_season_details &&
                  leagueDetails.curr_season_details.year
                    ? leagueDetails.curr_season_details.year
                    : ""
                }}
                {{ $t("football.league-details.stats") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.no-of-players") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{ leagueDetails.totalPlayers }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div
                  class="color-666 font-300 mb-xxs"
                  style="transform-origin: left"
                  data-v-5ea21537=""
                >
                  {{ $t("football.league-details.no-of-teams") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{ leagueDetails.totalTeams }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.total-points") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{ leagueDetails.points }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.rebounds") }}
                </div>
                <div
                  class="value font-500"
                  data-v-5ea21537=""
                  v-if="leagueDetails && leagueDetails.type"
                >
                  {{ leagueDetails.rebounds }}
                </div>
                <div class="value font-500" data-v-5ea21537="" v-else>-</div>
              </div>
            </div>
          </div>
          <!-- end::current season stats -->
        </div>
      </div>
      <!-- end::title holder info -->
      <!-- begin::tabs -->
      <div class="tab-bar" data-v-274dfa8a="" data-v-8948368c="">
        <div class="content-box" data-v-274dfa8a="">
          <div class="child" data-v-274dfa8a="">
            <a
              class="tab"
              :class="activeTab == 'standings' ? 'active' : ''"
              data-v-274dfa8a=""
              v-on:click="toggleTab('standings')"
            >
              {{ $t("football.league-details.standings") }} </a
            ><a
              class="tab"
              style="cursor: pointer"
              data-v-274dfa8a=""
              v-on:click="toggleTab('playersStats')"
              :class="activeTab == 'playersStats' ? 'active' : ''"
            >
              {{ $t("football.league-details.players-stats") }} </a
            ><a
              class="tab"
              style="cursor: pointer"
              data-v-274dfa8a=""
              v-on:click="toggleTab('teamsStats')"
              :class="activeTab == 'teamsStats' ? 'active' : ''"
            >
              {{ $t("football.league-details.teams-stats") }}
            </a>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
      <!-- begin::overview -->
      <span v-if="activeTab == 'standings'">
        <LeagueStandings :leagueDetail="leagueDetails" :leagueId="leagueId" />
      </span>
      <!-- end::overview -->
      <!-- begin::players stats -->
      <span v-if="activeTab == 'playersStats'">
        <PlayersStats :leagueDetail="leagueDetails" :leagueId="leagueId" />
      </span>
      <!-- end::players stats -->
      <!-- begin::teams stats -->
      <span v-if="activeTab == 'teamsStats'">
        <TeamsStats :leagueDetail="leagueDetails" :leagueId="leagueId" />
      </span>

      <!-- end::teams stats -->
    </div>
  </div>
  <DesktopFooter />
</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";
import PlayersStats from "./tabs/players-stats.vue";
import TeamsStats from "./tabs/teams-stats.vue";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],

  components: {
    LeagueStandings,
    PlayersStats,
    TeamsStats,

  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "standings",
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/basketball/league/details/${leagueId}`
        )
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
