export default {
  methods: {
    formatUnixTimestamp(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
      const hours = ("0" + date.getHours()).slice(-2); // Add leading zero if needed
      const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero if needed
      return `${hours}:${minutes}`;
    },
    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      return date.toLocaleDateString("en-US", options);
    },

    getBirthdayFromTimestamp(timestamp) {
      // Convert the timestamp to milliseconds by multiplying it by 1000
      const milliseconds = timestamp * 1000;

      // Create a new Date object using the timestamp
      const date = new Date(milliseconds);

      // Extract the year, month, and day from the date object
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Month is zero-based, so add 1
      const day = date.getDate();

      // Format the date
      const formattedDate = `${year}/${month < 10 ? "0" + month : month}/${
        day < 10 ? "0" + day : day
      }`;

      return formattedDate;
    },
    getH2hDateTimeFormat(inputDate) {
      // Array of month names
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Array of day names
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      // Convert inputDate to a Date object
      var dateObj = new Date(inputDate * 1000);

      // Extracting components of the input date
      var dayOfWeek = days[dateObj.getDay()];
      var month = months[dateObj.getMonth()];
      var day = dateObj.getDate();
      var year = dateObj.getFullYear();

      // Formatting the output date string
      var formattedDate = dayOfWeek + ", " + month + " " + day + ", " + year;

      // Return the formatted date
      return formattedDate;
    },
    // method to convert timestamp to readable date
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();

      // Format the date
      return month + " " + day + ", " + year;
    },

    readableTime(lang, matchTime) {
      const moment = require("moment-timezone");
      var date = new Date(matchTime * 1000);
      if (lang == "cn") {
        date = moment(date).subtract(24, "hours");
        return date.tz("Asia/Shanghai").format("hh:mm A");
      } else {
        date = moment(date).subtract(4, "hours");
        return date.tz("Asia/Shanghai").format("hh:mm A");
      }
    },
  },
};
