<template>
  <div
    class="ft_teams view border-box"
    data-v-9a805c60=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="teamheader" data-v-9a805c60="">
      <div class="backFil" data-v-9a805c60="">
        <div class="header" data-v-48b34d9b="" data-v-9a805c60="" style="">
          <div class="van-sticky"></div>
        </div>
      </div>
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-9a805c60=""
      >
        <div
          itemprop="logo"
          class="team_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
          data-v-9a805c60=""
        >
          <img
            :src="
              teamDetails && teamDetails.logo
                ? teamDetails.logo + '!w60'
               : bannerImagePath('default-nation.png')
            "
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div itemprop="name" class="team_name" data-v-9a805c60="">
          {{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
          }}
        </div>
      </div>
      <div data-v-9a805c60="">
        <div class="van-sticky">
          <div class="van-tabs van-tabs--line" data-v-9a805c60="">
            <div
              class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
            >
              <div
                role="tablist"
                class="van-tabs__nav van-tabs__nav--line"
                style="border-color: #ffffff; background: #285898"
              >
                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgb(255, 255, 255)"
                  :style="
                    activeTab === 'overview'
                      ? 'color:white'
                      : 'color: rgba(255, 255, 255, 0.6)'
                  "
                  v-on:click="toggleTab('overview')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.overview") }}</span
                  >
                </div>
                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgba(255, 255, 255, 0.6)"
                  :style="activeTab === 'squad' ? 'color:white' : ''"
                  v-on:click="toggleTab('squad')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.squad") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb-box iconfont" data-v-e49cc106="" data-v-9a805c60="">
      <div class="display-i-l" data-v-e49cc106="">
        <a
          :href="'/' + this.$i18n.locale + '/live/football'"
          class="ele link"
          data-v-e49cc106=""
          ><span itemprop="name" data-v-e49cc106="">
            {{ $t("football.league-details.football-live-score") }}</span
          >
        </a>
      </div>
      <span class="jt" data-v-e49cc106=""></span>

      <div class="display-i-l" data-v-e49cc106="">
        <a target="" class="ele link" data-v-e49cc106=""
          ><span itemprop="name" data-v-e49cc106="">
            {{
              this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                ? teamDetails.cn_name
                : teamDetails.name
                ? teamDetails.name
                : ""
            }}
            {{ $t("football.league-details.schedule") }},
            {{ $t("football.league-details.standings") }}</span
          >
        </a>
      </div>
    </div>
    <div class="content" data-v-9a805c60="" v-if="activeTab === 'overview'">
      <div class="team_overview" data-v-7a9b3945="" data-v-9a805c60="">
        <div class="info" data-v-7a9b3945="">
          <div class="info_title" data-v-7a9b3945="">
            {{ $t("football.league-details.team-info") }}
          </div>
          <div class="info_content" data-v-7a9b3945="">
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.total-players") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.total_players
                    ? teamDetails.total_players
                    : "-"
                }}
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.foreign-players") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.foreign_players
                    ? teamDetails.foreign_players
                    : "-"
                }}
              </div>
            </div>
            <div
              itemprop="location"
              class="infoItem"
              style="border-top: 1px solid #f1f1f1"
              data-v-7a9b3945=""
            >
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.national-players") }}
              </div>
              <div itemprop="name" class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.national_players
                    ? teamDetails.national_players
                    : "-"
                }}
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.website") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.website ? teamDetails.website : "-"
                }}
              </div>
            </div>
            <div itemprop="location" class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.total-market-value") }}
              </div>
              <div itemprop="address" class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.market_value
                    ? teamDetails.market_value / 1000000 + " €"
                    : "-"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="title-box" data-v-7a9b3945="">
          <span class="text" data-v-7a9b3945=""
            >About
            {{
              this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                ? teamDetails.cn_name
                : teamDetails.name
                ? teamDetails.name
                : ""
            }}</span
          >
        </div>
        <div class="dec-box" data-v-7a9b3945="">
          <div class="text" data-v-7a9b3945="">
            <p>
              <a>
                {{
                  this.$i18n.locale == "cn" &&
                  teamDetails &&
                  teamDetails.cn_name
                    ? teamDetails.cn_name
                    : teamDetails.name
                    ? teamDetails.name
                    : ""
                }}
                live score</a
              >, schedule, match results, standings, minute by minute updated
              live results and match statistics.
            </p>
          </div>
          <div class="text mt-20" data-v-7a9b3945="">
            <p>
              You can click on any player from the roster on the squad page and
              see his personal information such as nationality, date of birth,
              height, preferred foot, position, player value, transfer history
              etc. There are also statistics for each player in all competitions
              with all total played and started matches, minutes played, number
              of goals scored, number of cards and much more. AiScore
              <a style="color: var(--main-text-color)" href="/">football live score</a> is
              available as iPhone and iPad app, Android app on Google Play and
              Windows phone app. You can find us in all stores on different
              languages searching for "AiScore". Install AiScore app and follow
              all
              {{
                this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                  ? teamDetails.cn_name
                  : teamDetails.name
                  ? teamDetails.name
                  : ""
              }}
              matches live on your mobile!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'squad'" class="content" data-v-3aaba216="">
      <div data-v-582c2586="" data-v-3aaba216="" class="team_squad">
        <div data-v-582c2586="" class="w100">
          <div data-v-582c2586="" class="title">
            <div data-v-582c2586="" class="flex align-center">
              <div
                data-v-582c2586=""
                class="shirtNumber"
                style="margin-right: 0.27rem"
              >
                #
              </div>
              {{ $t("football.league-details.players") }}
            </div>
            <span data-v-582c2586="">
              {{ $t("football.league-details.position") }}
            </span>
          </div>
          <div
            v-if="teamSquad && teamSquad != ''"
            data-v-582c2586=""
            class="content list_active"
          >
            <a
              v-for="player in teamSquad"
              :key="player"
              data-v-582c2586=""
              :href="
                '/' +
                this.$i18n.locale +
                '/football/player/details/' +
                player.name.toLowerCase().replace(/\s/g, '-') +
                '/' +
                player.id
              "
              class="w100 itemContent"
              style="display: block"
              ><div
                data-v-582c2586=""
                class="w100 h100 flex align-center justify-between"
              >
                <div data-v-582c2586="" class="flex-1 contentBox">
                  <div data-v-582c2586="" class="shirtNumber">
                    {{ player?.shirt_number }}
                  </div>
                  <div
                    data-v-582c2586=""
                    class="playerLogo van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        player && player.logo
                          ? player.logo + '!w60'
                          : bannerImagePath('default-player.png')
                      "
                      alt="Jimmy Butler"
                      class="van-image__img"
                      style="object-fit: cover"
                    />
                  </div>
                  <div data-v-582c2586="" class="playerInfo">
                    <span data-v-582c2586="" class="name">{{
                      this.$i18n.locale == "cn" && player && player.cn_name
                        ? player.cn_name
                        : player.name
                        ? player.name
                        : ""
                    }}</span>
                  </div>
                </div>
                <span data-v-582c2586="" class="marketValue">{{
                  player && player.position ? player.position : "-"
                }}</span>
              </div>
            </a>
          </div>
          <div v-else data-v-582c2586="" class="content list_active">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 500px"
            >
              <img :src="bannerImagePath('default-container.png')" />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],


  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
      activeTab: "overview",
      metaData: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getTeamSquad(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          //   this.getTeamSquad(teamId);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team squad
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/squad/${teamId}`)
        .then((response) => {
          this.teamSquad = response.data.teamPlayers;
          this.totlePlayers = response.data.teamPlayers.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
