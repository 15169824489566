<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div id="__nuxt" v-if="!loading">
    <div id="__layout">
      <div id="app" class="font-en" data-v-250f1619="">
        <div
          class="bk_competition view border-box"
          data-v-06d4278f=""
          data-v-250f1619
        >
          <div class="compheader" data-v-06d4278f="">
            <div class="backFil" data-v-06d4278f="">
              <div
                class="header"
                data-v-54d9a5ac=""
                data-v-06d4278f=""
                style=""
              >
                <div class="van-sticky">
                  <header
                    class="first-header"
                    style="background: #16418c"
                    data-v-54d9a5ac=""
                  >
                    <div
                      class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                      data-v-54d9a5ac=""
                    >
                      <div
                        class="left van-col van-col--5"
                        data-v-54d9a5ac=""
                        @click="() => this.$router.back()"
                      >
                        <span
                          class="icon iconfont myIcon iconback"
                          style="opacity: 0.6"
                          data-v-54d9a5ac=""
                        ></span>
                      </div>
                      <div
                        class="proMod2 flex align-center justify-center van-col"
                        style="font-size: 0.4rem"
                        data-v-54d9a5ac=""
                      ></div>
                      <div class="right van-col van-col--5" data-v-54d9a5ac="">
                        <div
                          class="right_slot flex align-center justify-around"
                          data-v-06d4278f=""
                          @click="toggleSeasonDialog"
                        >
                          <span class="text" data-v-06d4278f="">{{
                            selectedSeason?.year
                          }}</span>
                          <i
                            class="seasonIcon van-icon van-icon-play"
                            data-v-06d4278f=""
                          ></i>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
            </div>
            <div
              class="commonHeadInfo flex align-center justify-center flex-column"
              data-v-06d4278f=""
            >
              <div
                itemprop="logo"
                class="comp_logo van-image van-image--round"
                style="overflow: hidden; border-radius: 50%"
                data-v-06d4278f=""
              >
                <img
                  v-if="leagueId"
                  :src="
                    'https://sportyclub.online//images/cricket/leagues/' +
                    leagueId +
                    '/' +
                    leagueId +
                    '.png'
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-country.png')
                  "
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div class="comp_name" data-v-06d4278f="">
                {{
                  this.$i18n.locale == "cn" && leagueDetails.cn_name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                    ? leagueDetails.name
                    : ""
                }}
              </div>
              <div
                class="des flex align-center justify-center"
                data-v-06d4278f=""
              >
                <div
                  itemprop="logo"
                  class="country_logo van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                  data-v-06d4278f=""
                >
                  <img
                    :src="
                      leagueDetails &&
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.logo
                        ? leagueDetails.countryDetails.logo
                        : bannerImagePath('default-country.png')
                    "
                    alt=""
                    class="van-image__img"
                    style="object-fit: contain"
                  />
                </div>
                <span class="country" data-v-06d4278f="">
                  <template v-if="leagueDetails">
                    <template
                      v-if="
                        leagueDetails.category &&
                        leagueDetails.category.country &&
                        leagueDetails.category.country.name
                      "
                    >
                      {{ leagueDetails.category.country.name }}
                    </template>
                    <template
                      v-else-if="
                        leagueDetails.category && leagueDetails.category.name
                      "
                    >
                      {{ leagueDetails.category.name }}
                    </template>
                    <template v-else></template>
                  </template>
                </span>
              </div>
            </div>
            <div data-v-06d4278f="" style="">
              <div class="van-sticky" style="">
                <div class="van-tabs van-tabs--line" data-v-06d4278f="">
                  <div
                    class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
                  >
                    <div
                      role="tablist"
                      class="van-tabs__nav van-tabs__nav--line"
                      style="border-color: #ffffff; background: #16418c"
                    >
                      <div
                        v-on:click="toggleTab('overview')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'overview'
                            ? 'color:white'
                            : 'color: rgba(255, 255, 255, 0.6)'
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.overview")
                        }}</span>
                        <span
                          v-if="activeTab === 'overview'"
                          data-v-06d4278f=""
                        ></span>
                      </div>
                      <div
                        v-on:click="toggleTab('standings')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'standings'
                            ? 'color:white'
                            : 'color: rgba(255, 255, 255, 0.6)'
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.standings")
                        }}</span>
                        <span
                          v-if="activeTab === 'standings'"
                          data-v-06d4278f=""
                        ></span>
                      </div>

                      <div
                        v-on:click="toggleTab('player-stats')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'player-stats'
                            ? 'color:white'
                            : 'color: rgba(255, 255, 255, 0.6)'
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.player-stats")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- begin::breadcrumb -->
          <div
            class="breadcrumb-box iconfont"
            style="background-color: "
            data-v-14e526a2=""
            data-v-1ebdf8d4=""
          >
            <div class="display-i-l" data-v-14e526a2="">
              <a
                :href="'/' + this.$i18n.locale + '/live/cricket'"
                class="ele link"
                data-v-14e526a2=""
                ><span data-v-14e526a2="">{{
                  $t("football.league-details.football-live-score")
                }}</span>
              </a>
            </div>
            <span class="jt" data-v-14e526a2=""></span>

            <h1
              itemprop="itemListElement"
              class="ele text lastText"
              style="display: inline"
              data-v-14e526a2=""
            >
              <span itemprop="name" data-v-14e526a2="">
                {{
                  this.$i18n.locale == "cn" &&
                  leagueDetails &&
                  leagueDetails.name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                }}
              </span>
            </h1>
          </div>
          <!-- end::breadcrumb -->

          <div class="content" data-v-1ebdf8d4="" v-if="!loading">
            <div class="ft_tour_overview" data-v-61a7efa4="" data-v-1ebdf8d4="">
              <div class="w100" data-v-61a7efa4="">
                <LeagueOverview
                  v-if="activeTab == 'overview'"
                  :leagueId="leagueId"
                  :leagueDetails="leagueDetails"
                  :seasonId="selectedSeason.id"
                />
                <LeagueStandings
                  v-if="activeTab == 'standings'"
                  :leagueId="leagueId"
                  :seasonId="selectedSeason.id"
                  :leagueDetails="leagueDetails"
                />

                <PlayerStats
                  v-if="activeTab == 'player-stats'"
                  :leagueId="leagueId"
                  :seasonId="selectedSeason.id"
                />
              </div>
            </div>
          </div>
        </div>
        <template v-if="seasonDialogStatus">
          <div
            @click="toggleSeasonDialog"
            class="van-overlay"
            style="z-index: 2003"
          ></div>
          <div
            data-v-0e623cd2=""
            data-v-3cc6ec0a=""
            class="van-popup van-popup--bottom van-popup--safe-area-inset-bottom"
            style="max-height: 70%; z-index: 2004"
          >
            <div data-v-0e623cd2="" class="popcontent">
              <div data-v-0e623cd2="" class="w100 myOptions">
                <div
                  v-for="season in seasonsList"
                  :key="season"
                  data-v-0e623cd2=""
                  class="item"
                  :class="{ active: season.id === selectedSeason.id }"
                  @click="changeSeason(season.id)"
                >
                  {{ season.year }}
                </div>
              </div>
            </div>
            <div data-v-0e623cd2="" class="item cancel">Cancel</div>
          </div>
        </template>
        <MobileFooter />
      </div>
    </div>
  </div>
</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";
import PlayerStats from "./tabs/players-stats.vue";
import LeagueOverview from "./tabs/league-overview.vue";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  name: "LeagueDetailsMobileVersion",
  components: {
    LeagueStandings,
    PlayerStats,
    LeagueOverview,
  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "overview",
      selectedSeason: null,
      seasonDialogStatus: false,
    };
  },
  created() {
    this.callApis();
  },
  methods: {
    async callApis() {
      const itemId = this.$route.params.leagueId;
      const leagueName = this.$route.params.slug;
      this.leagueId = itemId;
      let [result, result2] = [null, null];
      try {
        result = await this.getLeagueDetails(leagueName, itemId);
        this.leagueDetails = result.data.uniqueTournament;

        result2 = await this.getSeasonList(itemId);
        this.seasonsList = result2.data.seasons;
        this.selectedSeason = this.seasonsList[0];
      } catch (e) {
        console.error(e);
      }
      console.log(result, result2);
      this.loading = false;
      console.log("loading false");
    },
    // method to get hot leagues list
    async getLeagueDetails(leagueName, leagueId) {
      return this.$axios.get(
        this.BASE_SERVER_URI +
          `/api/cricket/league/details/${leagueName}/${leagueId}`
      );
    },
    // method to get seasons of league
    async getSeasonList(leagueId) {
      return this.$axios.get(
        this.BASE_SERVER_URI +
          `/api/cricket/league/season/standings/list/${leagueId}`
      );
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    toggleSeasonDialog() {
      this.seasonDialogStatus = !this.seasonDialogStatus;
    },
    changeSeason(seasonId) {
      // console.log(seasonId);
      // console.log(this.seasonsList);
      const result = this.seasonsList.filter(
        (season) => season.id === seasonId
      );
      if (result.length !== 0) {
        this.selectedSeason = result[0];
        this.toggleSeasonDialog();
      }
    },
  },
};
</script>
