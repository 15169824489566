<template>
  <div style="padding: 0.5rem 0.2rem 0.2rem 0.2rem">
    <h4 style="font-weight: 800">Latest News:</h4>
  </div>
  <div
    v-if="loading"
    class="cricket view border-box"
    data-v-250f1619=""
    style="min-height: 0px !important"
  >
    <div>
      <div class="detail view border-box" data-v-250f1619="">
        <div
          class="loading van-loading van-loading--spinner van-loading--vertical"
        >
          <span class="van-loading__spinner van-loading__spinner--spinner"
            ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
            ><i></i><i></i><i></i
          ></span>
        </div>
      </div>
    </div>
  </div>
  <template v-if="!loading && this.newsList">
    <nav
      aria-label="Page navigation example"
      style="
        display: flex;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        justify-content: center;
      "
    >
      <ul class="pagination" style="justify-content: space-around; width: 80%">
        <li class="page-item" :class="{ disabled: currentPage == 1 }">
          <a class="page-link" tabindex="-1" @click="goPreviousPage">&lt;</a>
        </li>
        <li
          @click="goToPage(1)"
          class="page-item"
          :class="{ active: currentPage == 1 }"
        >
          <a class="page-link">1</a>
        </li>
        <li
          class="page-item"
          @click.prevent="goToPage(2)"
          :class="{ active: currentPage == 2 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 2
          }}</a>
        </li>
        <li
          v-if="!pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          @click.prevent="goToPage(3)"
          :class="{ active: currentPage == 3 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 3
          }}</a>
        </li>
        <li
          v-if="!pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          @click.prevent="goToPage(4)"
          :class="{ active: currentPage == 4 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 4
          }}</a>
        </li>
        <li
          v-if="pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          :class="{ active: pageAboveLastNumberAndBelowTotalPages }"
        >
          <a class="page-link">{{ pageNumberAboveTwoAndBelowTotalPage }}</a>
        </li>
        <li class="page-item"><a class="page-link">...</a></li>
        <li
          class="page-item"
          @click.prevent="goToPage(totalPages)"
          :class="{ active: currentPage == totalPages }"
        >
          <a class="page-link">{{ totalPages }}</a>
        </li>

        <li
          class="page-item"
          :class="{ disabled: currentPage == totalPages }"
          @click.prevent="goNextPage"
        >
          <a class="page-link">&gt;</a>
        </li>
      </ul>
    </nav>
    <div
      translate="translate"
      class="bd-example vue-example vue-example-b-card"
    >
      <div
        v-for="newsItem in newsList"
        :key="newsItem"
        style="margin-bottom: 1rem"
      >
        <a
          :href="
            '/' + this.$i18n.locale + '/cricket/news/details/' + newsItem.id
          "
          target="_blank"
        >
          <article class="card mb-2" style="max-width: 20rem">
            <img
              :src="'http://sportyclub.online/' + newsItem.image"
              alt="Image"
              class="card-img-top"
              loading="lazy"
            />
            <div class="card-body">
              <h4 class="card-title">{{ newsItem.title }}</h4>
            </div>
          </article>
        </a>
      </div>
    </div>
    <nav
      aria-label="Page navigation example"
      style="
        display: flex;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        justify-content: center;
      "
    >
      <ul class="pagination" style="justify-content: space-around; width: 80%">
        <li class="page-item" :class="{ disabled: currentPage == 1 }">
          <a class="page-link" tabindex="-1" @click="goPreviousPage">&lt;</a>
        </li>
        <li
          @click="goToPage(1)"
          class="page-item"
          :class="{ active: currentPage == 1 }"
        >
          <a class="page-link">1</a>
        </li>
        <li
          class="page-item"
          @click.prevent="goToPage(2)"
          :class="{ active: currentPage == 2 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 2
          }}</a>
        </li>
        <li
          v-if="!pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          @click.prevent="goToPage(3)"
          :class="{ active: currentPage == 3 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 3
          }}</a>
        </li>
        <li
          v-if="!pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          @click.prevent="goToPage(4)"
          :class="{ active: currentPage == 4 }"
        >
          <a class="page-link">{{
            pageAboveLastNumberAndBelowTotalPages ? "..." : 4
          }}</a>
        </li>
        <li
          v-if="pageAboveLastNumberAndBelowTotalPages"
          class="page-item"
          :class="{ active: pageAboveLastNumberAndBelowTotalPages }"
        >
          <a class="page-link">{{ pageNumberAboveTwoAndBelowTotalPage }}</a>
        </li>
        <li class="page-item"><a class="page-link">...</a></li>
        <li
          class="page-item"
          @click.prevent="goToPage(totalPages)"
          :class="{ active: currentPage == totalPages }"
        >
          <a class="page-link">{{ totalPages }}</a>
        </li>

        <li
          class="page-item"
          :class="{ disabled: currentPage == totalPages }"
          @click.prevent="goNextPage"
        >
          <a class="page-link">&gt;</a>
        </li>
      </ul>
    </nav>
  </template>

  <!--begin::footer -->
  <MobileFooter />
  <!-- end::footter -->
</template>
<!-- begin::scripts -->
<script>
import DateMixin from "@/utils/dateUtils";

export default {
  components: {},
  mixins: [DateMixin],
  data() {
    return {
      loading: true,
      newsList: null,
      currentPage: 1,
      totalPages: 1,
      lastNumber: 4,
      pageAboveLastNumberAndBelowTotalPages: false,
      pageNumberAboveTwoAndBelowTotalPage: null,
    };
  },
  created() {
    this.getNewsList(this.currentPage);
  },
  mounted() {},
  methods: {
    goToPage(pageNum) {
      if (pageNum != this.currentPage) this.getNewsList(pageNum);
    },
    goNextPage() {
      if (this.currentPage != this.totalPages) {
        this.currentPage = this.currentPage + 1;
        this.getNewsList(this.currentPage);
      }
    },
    goPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.getNewsList(this.currentPage);
      }
    },
    getNewsList(pageNum) {
      this.loading = true;
      this.$axios
        .get(
          `https://sportyclub.online/api/cricket/news/list?pageNumber=${pageNum}`
        )
        .then((response) => {
          this.newsList = response.data.cricketNewsList;
          this.currentPage = parseInt(response.data.currentPage);
          this.totalPages = parseInt(response.data.totalPages) - 1;
          this.checkIfAboveTwoOrLowerThanTotalPages(
            this.currentPage,
            this.totalPages
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfAboveTwoOrLowerThanTotalPages(currentPage, totalPages) {
      if (currentPage > this.lastNumber && currentPage < totalPages) {
        this.pageAboveLastNumberAndBelowTotalPages = true;
        this.pageNumberAboveTwoAndBelowTotalPage = currentPage;
      } else {
        this.pageAboveLastNumberAndBelowTotalPages = false;
        this.pageNumberAboveTwoAndBelowTotalPage = null;
      }
    },
  },
};
</script>

<style scoped>
.card {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}
.card-title {
  color: black;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
.bd-example {
  position: relative;
  padding: 0.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
}

.bd-example .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.bd-example ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  font-size: 0.5rem;

  position: relative;
  display: block;
  padding: 0.1rem 0.25rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000000;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.bd-example a {
  color: var(--main-theme-color);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.page-item.disabled .page-link {
  color: #b5b6b8;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--main-theme-color);
  border-color: var(--main-theme-color);
}
</style>
