<template>
  <span mode="out-in" data-v-4a160e70=""
    >
        <!-- begin::loader -->
        <div class="detail view border-box" data-v-250f1619="" v-if="loading">
        <div class="loading van-loading van-loading--spinner van-loading--vertical"><span
                class="van-loading__spinner van-loading__spinner--spinner"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></span>
        </div>
        <div class="prefetch"></div>
    </div>
    <!-- end::leader -->
    
    <div activetab="odds" class="w100" data-v-07c8a370="" data-v-4a160e70="">
      <div class="newOdds" data-v-07c8a370="">
        <p class="oddsType" data-v-07c8a370="">
          <span class="openingBox openingBox1" data-v-07c8a370=""
            ><span class="chunk opening" data-v-07c8a370=""></span>
            <span data-v-07c8a370="">
              {{ $t("football.league-details.opening-odds") }}</span
            ></span
          >
          <span class="openingBox" data-v-07c8a370=""
            ><span class="chunk pre-match" data-v-07c8a370=""></span>
            <span data-v-07c8a370="">
              {{ $t("football.league-details.pre-match-odds") }}</span
            ></span
          >
        </p>
        <div class="content flex" data-v-07c8a370="">
          <div class="comp" data-v-07c8a370="">
            <div class="header" data-v-07c8a370=""></div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex" data-v-07c8a370="">
                <div
                  class="flex-1 text notRightColor"
                  style="border-bottom: none"
                  data-v-07c8a370=""
                >
                  1
                </div>
                <div
                  class="flex-1 text notRightColor"
                  style="border-bottom: none"
                  data-v-07c8a370=""
                >
                  X
                </div>
                <div
                  class="flex-1 text"
                  style="border-bottom: none"
                  data-v-07c8a370=""
                >
                  2
                </div>
              </div>
            </div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex" data-v-07c8a370="">
                <div class="flex-1 text notRightColor" data-v-07c8a370=""></div>
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.asian-handicap") }}
                </div>
                <div class="flex-1 text" data-v-07c8a370=""></div>
              </div>
            </div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex brr" data-v-07c8a370="">
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.goals") }}
                </div>
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.over") }}
                </div>
                <div
                  class="flex-1 text notRightBorder"
                  style="border-right: none"
                  data-v-07c8a370=""
                >
                  {{ $t("football.league-details.under") }}
                </div>
              </div>
            </div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex brr" data-v-07c8a370="">
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.corners") }}
                </div>
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.over") }}
                </div>
                <div
                  class="flex-1 text notRightBorder"
                  style="border-right: none"
                  data-v-07c8a370=""
                >
                  {{ $t("football.league-details.under") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- begin bet365 ::company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet365
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/bet365.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}
                            
                            
                            </span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end bet365::company -->
        <!-- begin:: crown company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.Crown
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/crownBet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Crown.euro[
                              liveMatchDetails.matchDetails.matchOdds.Crown.euro
                                .length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown.euro[
                              liveMatchDetails.matchDetails.matchOdds.Crown.euro
                                .length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Crown.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Crown.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Crown.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Crown.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Crown.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Crown.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Crown.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Crown
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Crown.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Crown.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.Crown.asia[
                              liveMatchDetails.matchDetails.matchOdds.Crown.asia
                                .length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Crown.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Crown
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Crown
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Crown.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Crown
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::crown company -->
        <!-- begin:: Ladbrokes company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/Ladbrokes.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .euro[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Ladbrokes.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Ladbrokes.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Ladbrokes.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds
                                  .Ladbrokes.asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Ladbrokes.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Ladbrokes.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .asia[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .asia.length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Ladbrokes.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.corner[
                                  liveMatchDetails.matchDetails.matchOdds
                                    .Ladbrokes.corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Ladbrokes company -->
        <!-- begin::10bet company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet10
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/10bet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet10.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet10.euro
                                .length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet10.euro
                                .length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet10.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet10.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet10.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet10.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet10.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet10.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet10.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet10
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet10.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet10.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet10.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet10.asia
                                .length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet10.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet10
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet10
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet10.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet10
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::10bet company -->
        <!-- begin::12bet company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet12
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/12bet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet12.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet12.euro
                                .length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet12.euro
                                .length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet12.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet12.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet12.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet12.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet12.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet12.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet12.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet12
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet12.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet12.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet12.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet12.asia
                                .length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet12.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet12
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet12
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet12.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet12
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::12bet company -->
        <!-- begin::wewbet company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.Wewbet
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/wewbet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Wewbet.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Wewbet.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Wewbet.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.Wewbet
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Wewbet.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .Wewbet.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet.asia[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .asia.length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .Wewbet.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.Wewbet
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.Wewbet
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.Wewbet.corner[
                                  liveMatchDetails.matchDetails.matchOdds.Wewbet
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::wewbet company-->
        <!-- begin::bet18 company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet18
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/18bet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet18.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet18.euro
                                .length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet18.euro
                                .length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet18.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet18.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet18.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet18.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet18.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet18.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet18.asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet18
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet18.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet18.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet18.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet18.asia
                                .length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet18.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet18
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet18
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet18.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet18
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::bet18  company-->
        <!-- begin::bet188  company-->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet188
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/188bet.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[0][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][2].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .euro[0] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .euro[0][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[0][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][4].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .euro.length - 1
                              ][2]
                                ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.euro.length - 1
                                  ][2].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .euro &&
                            liveMatchDetails.matchDetails.matchOdds.bet188.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188.euro[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .euro.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .euro.length - 1
                              ][4]
                                ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.euro.length - 1
                                  ][4].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[0][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[0][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[0][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[0][4] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .asia.length - 1
                              ][3] < 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet188.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia.length - 1
                                  ][3] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet188.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet188.asia.length - 1
                                    ][2] + 1
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet188
                                  .asia.length - 1
                              ][3] < 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet188.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia.length - 1
                                  ][3] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet188.asia.length - 1
                                    ][3]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia.length - 1
                                  ][3] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet188.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .asia.length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet188.asia.length - 1
                                  ][4] + 1
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .bigSmall.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .bigSmall.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[0][4].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .corner.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .corner.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet188
                              .corner[
                              liveMatchDetails.matchDetails.matchOdds.bet188
                                .corner.length - 1
                            ][4]
                              ? liveMatchDetails.matchDetails.matchOdds.bet188.corner[
                                  liveMatchDetails.matchDetails.matchOdds.bet188
                                    .corner.length - 1
                                ][4].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::bet188 company-->
        <p class="oddText" data-v-07c8a370="">
          {{ $t('football.league-details.gamble-responsibly') }}
        </p>
        <div class="w100 propUp" data-v-07c8a370=""></div>
      </div></div
  ></span>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
      liveMatches: [2, 3, 4, 5, 6, 7],
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/details/` + matchId)
        .then((response) => {
          this.liveMatchDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to check decimal value
    processNumber(value) {
      if (value == 0) {
        return value;
      }

      const stringValue = value.toString();
      const decimalIndex = stringValue.indexOf(".");

      if (decimalIndex !== -1 && decimalIndex < stringValue.length - 2) {
        const decimalPart = stringValue.slice(
          decimalIndex + 1,
          decimalIndex + 3
        );

        if (decimalPart === "25") {
          return `${Math.floor(value)}/${Math.floor(value) + 0.5}`;
        } else if (decimalPart === "75") {
          return `${Math.floor(value) + 0.5}/${Math.floor(value)}`;
        }
      }

      if (stringValue.endsWith(".5")) {
        return stringValue;
      }

      // Default return when none of the conditions are met
      return stringValue;
    },
  },
};
</script>
