<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-if="!loading && matchLineups != ''">
    <div class="content" data-v-c36567d6="">
      <div data-v-02ab54fc="" data-v-c36567d6="" class="team_squad">
        <div data-v-02ab54fc="" class="w100">
          <div data-v-02ab54fc="" class="title">
            {{ $t("football.league-details.home-team-squad") }}
          </div>
          <div data-v-02ab54fc="" class="content list_active">
            <span v-if="matchLineups && matchLineups.home">
              <span v-for="(home, index) in matchLineups.home" :key="home.id">
                <a
                  data-v-02ab54fc=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/player/details/' +
                    home.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    home.id
                  "
                  class="w100 itemContent"
                  style="display: block"
                >
                  <div
                    data-v-02ab54fc=""
                    class="w100 h100 flex align-center justify-between"
                  >
                    <div data-v-02ab54fc="" class="flex-1 contentBox">
                      <div data-v-02ab54fc="" class="shirtNumber">
                        {{ ++index }}
                      </div>
                      <div
                        data-v-02ab54fc=""
                        class="playerLogo van-image van-image--round"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            home && home.logo
                              ? home.logo
                              : bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <div data-v-02ab54fc="" class="playerInfo">
                        <span data-v-02ab54fc="" class="name">
                          {{
                            this.$i18n.locale == "cn" ? home.cn_name : home.name
                          }}
                        </span>
                      </div>
                    </div>
                    <span data-v-02ab54fc="" class="marketValue">{{
                      home.position
                    }}</span>
                  </div>
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div data-v-02ab54fc="" data-v-c36567d6="" class="team_squad">
        <div data-v-02ab54fc="" class="w100">
          <div data-v-02ab54fc="" class="title">
            {{ $t("football.league-details.away-team-squad") }}
          </div>
          <div data-v-02ab54fc="" class="content list_active">
            <span v-if="matchLineups && matchLineups.away">
              <span v-for="(home, index) in matchLineups.away" :key="home.id">
                <a
                  data-v-02ab54fc=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/player/details/' +
                    home.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    home.id
                  "
                  class="w100 itemContent"
                  style="display: block"
                >
                  <div
                    data-v-02ab54fc=""
                    class="w100 h100 flex align-center justify-between"
                  >
                    <div data-v-02ab54fc="" class="flex-1 contentBox">
                      <div data-v-02ab54fc="" class="shirtNumber">
                        {{ ++index }}
                      </div>
                      <div
                        data-v-02ab54fc=""
                        class="playerLogo van-image van-image--round"
                        itemprop="logo"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            home && home.logo
                              ? home.logo
                              : bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <div data-v-02ab54fc="" class="playerInfo">
                        <span data-v-02ab54fc="" class="name">
                          {{
                            this.$i18n.locale == "cn" ? home.cn_name : home.name
                          }}
                        </span>
                      </div>
                    </div>
                    <span data-v-02ab54fc="" class="marketValue">{{
                      home.position
                    }}</span>
                  </div>
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </span>
  <span v-else>
    <div class="scheduleBox" data-v-db02cb7a="">
      <div
        class="flex justify-center align-center flex-col"
        data-v-4291ef99=""
        style="height: 400px"
      >
        <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
        <div class="color-999 fs-12 mt-12">
          {{ $t("football.live-score.temporarily-no-data") }}
        </div>
      </div>
    </div>
  </span>
  <br />
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
      matchDetail: null,
    };
  },
  created() {
    this.getLiveMatchLineups(this.matchId);
    this.matchDetail = this.matchDetails;
  },
  methods: {
    // method to get today matchlist
    getLiveMatchLineups(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/lineups/` + matchId)
        .then((response) => {
          this.matchLineups = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>