<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <div class="football-index-page view border-box theme-init" v-if="!loading">
    <!-- begin::broadcumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: #7a7d8a; padding-left: 0"
    >
      <span class="el-breadcrumb__item" aria-current="page"
        ><span role="link" class="el-breadcrumb__inner"
          ><a href="/" target="_blank" style="cursor: pointer">
            <h1 class="liveScoreh1" v-if="this.$i18n.locale == 'cn'">
              {{ $t("football.live-score.aiScore-football-live-score") }}
            </h1>
          </a></span
        ><i class="el-breadcrumb__separator el-icon-arrow-right"></i
      ></span>
    </div>
    <!-- end::broudcumb -->
    <div class="page-content-box" style="width: ">
      <div class="flex" data-v-d4c6fef0="">
        <div data-v-417eeb1a="" data-v-d4c6fef0="" class="index-left fs-13">
          <!-- begin::hot league list -->
          <HotLeagues />
          <!-- end::league list -->
          <!-- begin::languages -->
          <CountryList />
          <!-- end::languages -->
        </div>
        <!-- begin::table -->

        <span>
          <div
            class="right-box rightBox"
            data-v-d4c6fef0=""
            :style="
              toggleBannerState
                ? this.$i18n.locale === 'cn'
                  ? 'width: 130%;'
                  : 'width: 117%;'
                : ''
            "
          >
            <!-- begin::banner -->
            <div
              class="bannerBox"
              data-v-d4c6fef0=""
              :style="toggleBannerState ? 'display:none' : 'display:block'"
            >
              <img
                :src="bannerImagePath('general-banner.png')"
                alt="#"
                class="banner"
                data-v-d4c6fef0=""
              />
              <i
                class="iconfont icon-guanbi close"
                data-v-d4c6fef0=""
                v-on:click="toggleBanner(state)"
              ></i>
            </div>
            <!-- end::banner -->
            <div class="right-box" data-v-d4c6fef0="">
              <!-- begin::tabs -->
              <div class="screen-header" style="z-index: 2" data-v-d4c6fef0="">
                <div class="changTabBox" data-v-d4c6fef0="">
                  <span
                    :class="
                      liveActivebtn == 'all' ? 'activeElsTab' : 'notActiveTab'
                    "
                    class="changeItem bh"
                    data-v-d4c6fef0=""
                    v-on:click="getTodayMatchList('all', isTimeActive)"
                  >
                    {{ $t("football.live-score.all") }}
                    <div data-v-89734b7c="" class="svgBallIconBox">
                      <svg
                        data-v-89734b7c=""
                        aria-hidden="true"
                        class="svgBallIcon"
                      >
                        <use
                          data-v-89734b7c=""
                          xlink:href="#icon-banqiu-banzi"
                        ></use>
                      </svg>
                    </div>
                  </span>
                  <span
                    :class="
                      liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
                    "
                    class="changeItem bh ml-12"
                    data-v-d4c6fef0=""
                    v-on:click="getTodayMatchList('live', isTimeActive)"
                    ><span class="liveContentBox" data-v-d4c6fef0=""
                      ><i
                        class="iconfont icon-jinhangzhong liveImg"
                        data-v-d4c6fef0=""
                      ></i>
                      {{ $t("football.live-score.live") }}
                      <span data-v-d4c6fef0="" class="total">{{
                        countLiveMatches != 0 ? countLiveMatches : ""
                      }}</span></span
                    ></span
                  >
                  <span
                    class="changeItem bh ml-12 notActiveTab"
                    data-v-d4c6fef0=""
                    v-on:click="getTodayMatchList('finished', isTimeActive)"
                    :class="
                      liveActivebtn == 'finished'
                        ? 'activeElsTab'
                        : 'notActiveTab'
                    "
                  >
                    {{ $t("football.live-score.finished") }}
                  </span>
                  <span
                    class="changeItem bh ml-12 notActiveTab"
                    data-v-d4c6fef0=""
                    v-on:click="getTodayMatchList('upcoming', isTimeActive)"
                    :class="
                      liveActivebtn == 'upcoming'
                        ? 'activeElsTab'
                        : 'notActiveTab'
                    "
                  >
                    {{ $t("football.live-score.soon") }}
                  </span>

                  <a
                    :href="'/' + this.$i18n.locale + '/football/news/list'"
                    class="changeItem bh ml-12 notActiveTab"
                    data-v-d4c6fef0=""
                  >
                    {{ $t("football.league-details.football-news") }}
                  </a>
                </div>
              </div>
              <SearchMatches
                v-if="searchListUpcoming || searchListLive"
                :searchMatchListUpcoming="searchListUpcoming"
                :searchMatchListLive="searchListLive"
              />

              <template v-if="!searchListUpcoming && !searchListLive">
                <div
                  data-v-d4c6fef0=""
                  class="match-box"
                  style="min-height: 288px"
                  v-if="countLiveMatches == 0"
                >
                  <div
                    data-v-d4c6fef0=""
                    class="vue-recycle-scroller scroller ready page-mode direction-vertical"
                  >
                    <div class="vue-recycle-scroller__item-wrapper">
                      <div
                        class="vue-recycle-scroller__item-view hover"
                        style="transform: translateY(0px)"
                      >
                        <div data-v-d4c6fef0="" class="no-data">
                          <div data-v-d4c6fef0="" class="flex-1">
                            <img
                              data-v-d4c6fef0=""
                              :src="bannerImagePath('default-container.png')"
                            />
                            <div data-v-d4c6fef0="" class="text">
                              {{ $t("football.league-details.no-live-match") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::tabs -->
                <div
                  class="vue-recycle-scroller__item-view"
                  style="transform: translateY(0px)"
                  v-for="(league, key) in todayMatchList"
                  :key="key"
                >
                  <div class="comp-container">
                    <!-- begin::league title -->

                    <div
                      data-v-f1b1cb6e=""
                      data-id="2j374oirla4qo6d"
                      class="title"
                      :class="
                        leagueFavoriteStatus[league[0].competition_id]
                          ? 'collectName'
                          : ''
                      "
                    >
                      <!-- add  collectName to title-->

                      <i
                        class="iconfont"
                        :class="
                          leagueFavoriteStatus[league[0].competition_id]
                            ? 'icon-yishoucang'
                            : 'icon-weishoucang'
                        "
                        @click="toggleFavorite(league[0].competition_id)"
                        style="visibility: visible"
                      ></i>

                      <i
                        data-v-f1b1cb6e=""
                        class="country-logo squareLogo"
                        style="height: -12px !important"
                      >
                        <img
                          v-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            ((league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.logo) ||
                              (league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name) ||
                              (league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name))
                          "
                          :src="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.logo
                              ? league[0].leagueInfo.countryDetails.logo
                              : bannerImagePath('default-country.png')
                          "
                          alt=""
                          style="width: 100%"
                        />
                      </i>
                      <!-- display country, category -->
                      <span
                        v-if="this.$i18n.locale == 'cn'"
                        data-v-f1b1cb6e=""
                        class="country-name minitext"
                      >
                        <span
                          v-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.cn_name }}: :
                          &nbsp;
                        </span>
                        <span
                          v-else-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.cn_name }}: :
                          &nbsp;
                        </span>
                      </span>

                      <span
                        v-else
                        data-v-f1b1cb6e=""
                        class="country-name minitext"
                      >
                        <span
                          v-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}: :
                        </span>
                        <span
                          v-else-if="
                            league[0] &&
                            league[0].leagueInfo &&
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}: :
                          &nbsp;
                        </span>
                      </span>

                      <span
                        v-if="
                          league &&
                          league[0] &&
                          league[0].leagueInfo &&
                          league[0].leagueInfo
                        "
                      >
                        <a
                          v-if="league[0].hasLeagueDetails"
                          data-v-2eea2747=""
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/league/details/' +
                            league[0].leagueInfo.en_name
                              .toLowerCase()
                              .replace(/\s/g, '-') +
                            '/' +
                            league[0].competition_id
                          "
                          target="_blank"
                          class="compText"
                          style="color: black"
                        >
                          <span>
                            <b>
                              {{
                                this.$i18n.locale == "cn" &&
                                league[0].leagueInfo.cn_name
                                  ? league[0].leagueInfo.cn_name
                                  : league[0].leagueInfo.en_name
                                  ? league[0].leagueInfo.en_name
                                  : ""
                              }}</b
                            >
                          </span>
                        </a>
                        <a
                          v-else
                          target="_blank"
                          class="compText"
                          style="cursor: not-allowed"
                        >
                          {{
                            this.$i18n.locale == "cn" &&
                            league[0].leagueInfo.cn_name
                              ? league[0].leagueInfo.cn_name
                              : league[0].leagueInfo.en_name
                              ? league[0].leagueInfo.en_name
                              : ""
                          }}
                        </a>
                      </span>

                      <div data-v-f1b1cb6e="" class="flex-1">
                        <span
                          data-v-f1b1cb6e=""
                          class="watchNum"
                          style="margin-right: 1px"
                        >
                        </span>
                      </div>
                      <ul data-v-f1b1cb6e="" class="odds">
                        <li data-v-f1b1cb6e="">
                          <span data-v-f1b1cb6e="">1</span>
                        </li>
                        <li data-v-f1b1cb6e="">
                          <span data-v-f1b1cb6e="">X</span>
                        </li>
                        <li data-v-f1b1cb6e="">
                          <span data-v-f1b1cb6e="">2</span>
                        </li>
                      </ul>
                    </div>

                    <!-- end::league title -->
                    <!-- begin::teams -->
                    <div
                      class="comp-list"
                      v-for="match in league"
                      :key="match.id"
                    >
                      <a
                        v-if="match && match.homeInfo && match.awayInfo"
                        data-v-0d00ff82=""
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/football/match/details/' +
                          match.homeInfo.en_name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '-vs-' +
                          match.awayInfo.en_name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '/' +
                          match.id
                        "
                        target="_blank"
                        class="match-container"
                        ><span data-v-0d00ff82="" class="left-box"
                          ><span data-v-0d00ff82="" class="v-st-box">
                            <span data-v-0d00ff82="" class="time minitext">
                              {{
                                readableTime(
                                  this.$i18n.locale,
                                  match.match_time
                                )
                              }}
                            </span>
                            <span
                              data-v-0d00ff82=""
                              class="status minitext on twinkle"
                              v-if="liveMatches.includes(match.status_id)"
                            >
                              {{
                                onGoingMatchTime(
                                  match.counter_timing,
                                  match.status_id
                                )
                              }}
                            </span></span
                          >

                          <span data-v-0d00ff82="" class="v-tt-box">
                            <!-- begin::home info -->
                            <span data-v-0d00ff82="" class="team home"
                              ><span
                                data-v-0d00ff82=""
                                class="flex-1 teamBox teamHomeBox"
                              >
                                <!-- yellow cards -->
                                <span
                                  data-v-0d00ff82=""
                                  class="card yellow"
                                  v-if="match.homeInfo.yellow_cards"
                                  ><span data-v-0d00ff82="">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      match.homeInfo.yellow_cards
                                        ? match.homeInfo.yellow_cards
                                        : ""
                                    }}
                                  </span>
                                </span>
                                <!-- red cards -->
                                <span
                                  data-v-0d00ff82=""
                                  class="card yellow"
                                  v-if="match.homeInfo.red_cards"
                                  ><span data-v-0d00ff82="">
                                    {{
                                      match.homeInfo.red_cards
                                        ? match.homeInfo.red_cards
                                        : ""
                                    }}
                                  </span>
                                </span>
                                <!-- begin::teams info -->
                                <span data-v-0d00ff82="" class="nameBox"
                                  ><a
                                    data-v-0d00ff82=""
                                    itemprop="homeTeam"
                                    class="name minitext maxWidth160"
                                  >
                                    {{
                                      match.homeInfo.cn_name &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.en_name
                                        ? match.homeInfo.en_name
                                        : ""
                                    }}
                                  </a>
                                </span>
                                <span
                                  data-v-0d00ff82=""
                                  class="teamLogoBox teamLogoHomeBox"
                                >
                                  <img
                                    :src="
                                      match.homeInfo.logo
                                        ? match.homeInfo.logo
                                        : '/images/default-football.jpg'
                                    "
                                  />
                                </span> </span
                            ></span>
                            <!-- end::home info -->
                            <!-- begin::away info -->
                            <!-- begin::score section -->
                            <span
                              data-v-0d00ff82=""
                              class="scores on"
                              v-if="
                                liveMatches.includes(match.status_id) ||
                                FinishedMatches.includes(match.status_id)
                              "
                            >
                              <span
                                data-v-0d00ff82=""
                                class="score-home minitext"
                              >
                                {{
                                  match && match.homeInfo
                                    ? match.homeInfo.home_score
                                    : "0"
                                }}
                              </span>
                              <span
                                data-v-0d00ff82=""
                                class="or"
                                style="background-color: transparent !important"
                                >-</span
                              >
                              <span
                                data-v-0d00ff82=""
                                class="score-away minitext"
                              >
                                {{
                                  match && match.awayInfo
                                    ? match.awayInfo.away_score
                                    : "0"
                                }}
                              </span>
                            </span>

                            <span
                              data-v-0d00ff82=""
                              class="scores on"
                              v-if="match.status_id == 1"
                            >
                              <span
                                data-v-0d00ff82=""
                                class="or"
                                style="margin-left: 25px !important"
                                >-</span
                              >
                            </span>

                            <!-- end::score section -->
                            <span data-v-0d00ff82="" class="team away"
                              ><span
                                data-v-0d00ff82=""
                                class="flex-1 teamBox teamAwayBox"
                                ><span
                                  data-v-0d00ff82=""
                                  class="teamLogoBox teamLogoAwayBox"
                                >
                                  <img
                                    v-if="match.awayInfo"
                                    :src="
                                      match.awayInfo.logo
                                        ? match.awayInfo.logo
                                        : '/images/default-football.jpg'
                                    "
                                  />
                                </span>
                                <span data-v-0d00ff82="" class="nameBox">
                                  <span
                                    data-v-0d00ff82=""
                                    itemprop="awayTeam"
                                    class="name minitext maxWidth160"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.cn_name &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.en_name
                                        ? match.awayInfo.en_name
                                        : ""
                                    }}
                                  </span>
                                </span>
                                <!-- yellow cards -->
                                <span
                                  data-v-0d00ff82=""
                                  class="card yellow"
                                  v-if="
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.yellow_cards
                                  "
                                  ><span data-v-0d00ff82="">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.yellow_cards
                                        ? match.awayInfo.yellow_cards
                                        : ""
                                    }}
                                  </span>
                                </span>
                                <!-- red cards -->
                                <span
                                  data-v-0d00ff82=""
                                  class="card yellow"
                                  v-if="
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.red_cards
                                  "
                                  ><span data-v-0d00ff82="">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.red_cards
                                        ? match.awayInfo.red_cards
                                        : ""
                                    }}
                                  </span>
                                </span>
                              </span></span
                            >
                            <!-- end::away info -->
                          </span>
                          <!-- end::teams info -->
                          <!-- begin::half & full score -->
                          <span
                            data-v-0d00ff82=""
                            class="v-ft-box"
                            v-if="
                              (match &&
                                match.homeInfo &&
                                match.homeInfo.half_time_score) ||
                              (match &&
                                match.awayInfo &&
                                match.awayInfo.half_time_score)
                            "
                            ><span data-v-0d00ff82="" class="half-over">
                              HT
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.half_time_score !== undefined
                                  ? match.homeInfo.half_time_score
                                  : "0"
                              }}
                              -
                              {{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.half_time_score !== undefined
                                  ? match.awayInfo.half_time_score
                                  : "0"
                              }}
                            </span>
                          </span>
                          <!-- else section -->
                          <span data-v-0d00ff82="" class="v-ft-box" v-else
                            ><span data-v-0d00ff82="" class="half-over">
                              <span style="display: none"> HT 0 - 0 </span>
                            </span>
                          </span>
                          <!-- end else section -->
                          <!-- end::half & full score -->
                        </span>
                        <!-- begin::odds area -->
                        <ul
                          data-v-0d00ff82=""
                          class="odds oddhoverGreen newOddsBox"
                          v-if="
                            match &&
                            match.odds &&
                            match.odds != '' &&
                            match.odds.euro &&
                            match.odds.euro != ''
                          "
                        >
                          <li data-v-0d00ff82="">
                            <span data-v-0d00ff82="" class="text">
                              {{
                                match.odds.euro[2] ? match.odds.euro[2] : "-"
                              }}
                            </span>
                          </li>
                          <li data-v-0d00ff82="">
                            <span
                              data-v-0d00ff82=""
                              class="text text1X2 dogFallTop"
                            >
                              {{
                                match.odds.euro[3] ? match.odds.euro[3] : "-"
                              }}
                            </span>
                          </li>
                          <li data-v-0d00ff82="">
                            <span data-v-0d00ff82="" class="text loseBottom">
                              {{
                                match.odds.euro[4] ? match.odds.euro[4] : "-"
                              }}
                            </span>
                          </li>
                        </ul>
                        <ul
                          data-v-0d00ff82=""
                          class="odds oddhoverGreen newOddsBox"
                          v-else
                        >
                          <li data-v-0d00ff82="">
                            <span data-v-0d00ff82="" class="text">
                              <b>-</b>
                            </span>
                          </li>
                          <li data-v-0d00ff82="">
                            <span
                              data-v-0d00ff82=""
                              class="text text1X2 dogFallTop"
                            >
                              <b>-</b>
                            </span>
                          </li>
                          <li data-v-0d00ff82="">
                            <span data-v-0d00ff82="" class="text loseBottom">
                              <b>-</b>
                            </span>
                          </li>
                        </ul>
                        <!-- end::odds area -->
                      </a>
                    </div>

                    <!-- end::teams -->
                  </div>
                </div>
                <span v-if="liveActivebtn != 'upcoming'">
                  <div
                    v-if="thereIsUpcomingMatches"
                    data-v-d4c6fef0=""
                    class="match-box"
                  >
                    <div data-v-d4c6fef0="" class="status-tip-page">
                      <i data-v-d4c6fef0="" class="iconfont icon-shaozi"></i>

                      {{ $t("football.live-score.today-upcoming-matches") }}
                    </div>
                  </div>
                  <span>
                    <UpcomingMatchesList v-if="thereIsUpcomingMatches" />
                  </span>
                </span>
              </template>
            </div>
          </div>
        </span>
        <!-- end::table -->
      </div>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<!-- begin::scripts -->
<script>
import UpcomingMatchesList from "./tabs/upcoming-matches-list.vue";
import DateMixin from "@/utils/dateUtils";
import CountryList from "./tabs/countries-list.vue";
import HotLeagues from "./tabs/hot-leagues.vue";
import SearchMatches from "./tabs/search-matches-list.vue";
import LiveScoringMixin from "@/utils/liveScoreComputedDataMixin";

import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  components: {
    UpcomingMatchesList,
    CountryList,
    HotLeagues,
    SearchMatches,
  },

  mixins: [LiveScoringMixin, DateMixin, DynamicImagePath],
  data() {
    return {
      todayUpcomingMatchList: null,
      searchListUpcoming: null,
      searchListLive: null,
      todayMatchList: null,
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
      countryList: null,
      countryLeagueActive: "",
      isTimeActive: false,
      toggleBannerState: false,
      isCollected: false,
      isLeagueSelected: {},
      storgeaKey: "todayMatchListState",
      leagueFavoriteStatus: {}, // Store favorite status for each league
    };
  },
  created() {
    this.gethotLeaguesList();
    this.getTodayMatchList(this.liveActivebtn, this.isTimeActive);
    this.getCountryLeagues();
    this.getUpcomingMatchList();

    //  refresh frontend every 30 seconds
    setInterval(() => {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${this.liveActivebtn}`
        )
        .then((response) => {
          this.todayMatchList = response.data.footballMatchList;
          this.countLiveMatches = response.data.liveMatches;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100000);
  },
  methods: {
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        console.log("Today's Upcoming Match List:", this.todayMatchList);
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const competitionId = match.competition_id;
              const favoriteStatus = localStorage.getItem(
                `football_${competitionId}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`football_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].competition_id;
          const favoriteStatus = localStorage.getItem(`football_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.footballHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to toggle checkBox
    toggoleCheckBox() {
      this.isTimeActive = !this.isTimeActive;
      this.getTodayMatchList(this.liveActivebtn, this.isTimeActive);
    },

    // method to get today matchlist
    getTodayMatchList(status, time) {
      this.isTimeActive = time;
      this.liveActivebtn = status;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.footballMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loading = false;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);

      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }

      if (status_code == 3) {
        return "HT";
      }

      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }

      if (this.liveMatches.includes(status_code)) {
        return "90+";
      }
    },

    // method to get country list
    getCountryLeagues() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/country/list`)
        .then((response) => {
          this.countryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get League Details
    checkCountryActiveLeague(countryId) {
      if (this.countryLeagueActive == countryId) {
        this.countryLeagueActive = "";
      } else {
        this.countryLeagueActive = countryId;
      }
    },
    // method to toggle banner
    toggleBanner(state) {
      this.toggleBannerState = !state;
    },

    getUpcomingMatchList() {
      console.log("upcoming match list");
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=upcoming`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.footballMatchList;
          console.log(this.todayUpcomingMatchList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });

    for (const key in this.todayMatchList) {
      if (Object.prototype.hasOwnProperty.call(this.todayMatchList, key)) {
        this.$set(this.isLeagueSelected, key, false);
      }
    }
  },
};
</script>
<style>
@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}
</style>
