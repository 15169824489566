<template>
  <div data-v-8502b08a="" class="page-box view border-box">
    <div data-v-8502b08a="" class="text-box container">
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        联系我们
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>Contact Us</p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        如果您对运动俱乐部服务有任何疑问，请通过以下方式联系我们：
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        If you have any enquiry concerning sportyclub service, please contact us
        via:
      </p>

      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li><b>电子邮件 : </b>sportyclub.live@gmail.com</li>
        <li><b>电话 : </b>+971542984926</li>
      </ul>

      <ul class="list" v-else>
        <li><b>Email : </b>sportyclub.live@gmail.com</li>
        <li><b>Phone : </b>+971542984926</li>
      </ul>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<style scoped>
.par {
  color: black;
  font-family: "Arial";
  font-size: 17px !important;
  text-align: justify;
}
.heading {
  color: black;
  margin-bottom: 15px;
  font-family: "Arial";
  font-weight: bold;
  font-size: 18px !important;
}

.list {
  color: black;
  list-style-type: disc;
  font-family: "Arial";
  font-size: 17px !important;
  margin-bottom: 15px;
  margin-left: 40px;
}
</style>