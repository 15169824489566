<template>
  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
  >
    <div data-v-46436e01="" class="user">
      <div data-v-46436e01="" class="tag_commingMatches">
        <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
        <span data-v-46436e01="" class="text">
          {{ $t("football.live-score.search-results") }}</span
        >
      </div>
    </div>
  </div>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
    style="transform: translateY(0px); min-height: 200px"
  >
    <div class="flex justify-center align-center flex-col contentBox">
      <img :src="bannerImagePath('default-container.png')" class="nodata" />

      <div class="color-999 noDataText fs-12">
        {{ $t("football.live-score.nothing-found") }}
      </div>
    </div>
  </div>

  <!-- live search result -->
  <template v-else>
 
    <div
      data-v-46436e01=""
      class="vue-recycle-scroller scroller ready page-mode direction-vertical"
    >
      <div class="vue-recycle-scroller__item-wrapper">
   
        <div
          class="vue-recycle-scroller__item-view"
          v-for="(league, key) in searchMatchListLive"
          :key="key"
        >
          <div data-v-46436e01="" class="user">
            <div data-v-46436e01="" class="flex align-center">
              <div data-v-89734b7c="" data-v-46436e01="" style="width: 100%">
                <div
                  data-v-89734b7c=""
                  class="list_title"
                  :class="
                    leagueFavoriteStatus[league[0].unique_tournament_id]
                      ? 'collectCom'
                      : ''
                  "
                >
                  <div data-v-89734b7c="" class="topt flex">
                    <div
                      data-v-89734b7c=""
                      style="z-index: -1; width: 13.03%; min-width: 124px"
                    ></div>
                    <div
                      data-v-89734b7c=""
                      style="
                        z-index: -1;
                        margin-left: 10px;
                        width: 21%;
                        min-width: 200px;
                      "
                    ></div>
                    <div data-v-89734b7c="" class="scoreBox" style="width: 30%">
                      <div data-v-89734b7c="" style="width: 150px"></div>
                      <div data-v-89734b7c="" style="width: 150px"></div>
                      <div data-v-89734b7c="" class="flex-1"></div>
                    </div>
                    <div data-v-89734b7c="" class="flex-1 headBoxRight"></div>
                    <div data-v-89734b7c="" class="Moneyline">
                      <div
                        data-v-89734b7c=""
                        class="text-center oddsTypeText oddsWidth1 bh"
                      >
                        {{ $t("football.league-details.to-win") }}
                      </div>
                      <div class="text-center oddsTypeText oddsWidth2 bh">
                        {{ $t("football.league-details.runs") }}
                      </div>
                      <div
                        data-v-89734b7c=""
                        class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                      >
                        {{ $t("football.league-details.total-runs") }}
                      </div>
                    </div>
                  </div>
                  <div data-v-89734b7c="" class="flex align-center topLeftBox">
                    <i
                      class="iconfont"
                      :class="
                        leagueFavoriteStatus[league[0].unique_tournament_id]
                          ? 'icon-yishoucang'
                          : 'icon-weishoucang'
                      "
                      @click="toggleFavorite(league[0].unique_tournament_id)"
                      style="visibility: hidden"
                    ></i>

                    <img
                      data-v-89734b7c=""
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo
                      "
                      :src="
                        league[0].leagueInfo.countryDetails.logo
                          ? league[0].leagueInfo.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      alt="China"
                      title="China"
                      class="countryLogo"
                    />

                    <span data-v-89734b7c="" class="countryText">
                      <span data-v-f1b1cb6e="" class="country-name minitext">
                        <template v-if="league[0] && league[0].leagueInfo">
                          <template v-if="this.$i18n.locale == 'cn'">
                            <template
                              v-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.cn_name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.cn_name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.cn_name
                              "
                            >
                              {{
                                league[0].leagueInfo.categoryDetails.cn_name
                              }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.categoryDetails.name }}:
                            </template>
                          </template>
                          <template v-else>
                            <template
                              v-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.categoryDetails.name }}:
                            </template>
                            <template v-else> </template>
                          </template>
                        </template>
                      </span>
                    </span>

                    <a
                      v-if="
                        league &&
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo &&
                        league[0].hasLeagueDetails
                      "
                      data-v-2eea2747=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/baseball/league/details/' +
                        league[0].leagueInfo.en_name
                          .toLowerCase()
                          .replace(/\s/g, '-') +
                        '/' +
                        league[0].unique_tournament_id
                      "
                      target="_blank"
                      class="compText"
                    >
                      {{
                        this.$i18n.locale == "cn" &&
                        league[0].leagueInfo.cn_name
                          ? league[0].leagueInfo.cn_name
                          : league[0].leagueInfo.en_name
                          ? league[0].leagueInfo.en_name
                          : ""
                      }}
                    </a>
                    <a
                      v-else
                      target="_blank"
                      class="compText"
                      style="cursor: not-allowed"
                    >
                      {{
                        this.$i18n.locale == "cn" &&
                        league[0].leagueInfo.cn_name
                          ? league[0].leagueInfo.cn_name
                          : league[0].leagueInfo.en_name
                          ? league[0].leagueInfo.en_name
                          : ""
                      }}
                    </a>
                  </div>
                </div>
                <div data-v-89734b7c="">
                  <div
                    data-v-89734b7c=""
                    class="list newList"
                    style="height: 64px"
                    v-for="match in league"
                    :key="match.id"
                  >
                    <div data-v-89734b7c="" class="list">
                      <div
                        data-v-89734b7c=""
                        class="flex align-center justify-center collectImgBox"
                      >
                        <!-- <i
                                  data-v-89734b7c=""
                                  class="iconfont icon-weishoucang collectImg"
                                  style="visibility: visible"
                                ></i> -->
                      </div>
                      <a
                        data-v-89734b7c=""
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/baseball/match/details/' +
                          match.homeInfo.en_name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '-vs-' +
                          match.awayInfo.en_name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '/' +
                          match.id
                        "
                        target="_blank"
                        itemscope="itemscope"
                        itemtype="http://schema.org/SportsEvent"
                        class="flex flex-1"
                        style="height: 100%"
                      >
                        <div
                          data-v-89734b7c=""
                          class="flex align-center listBox justify-around"
                        >
                          <span
                            data-v-89734b7c=""
                            class="fs-13 matchTime"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{
                              readableTime(this.$i18n.locale, match.match_time)
                            }}
                          </span>
                          <div
                            data-v-89734b7c=""
                            class="flex flex-col fs-13 align-center color-r text-center barItem"
                          >
                            <span data-v-89734b7c="">{{
                              match.status_id &&
                              this.FinishedMatches.includes(match.status_id)
                                ? $t("football.league-details.full-time")
                                : match?.statusDescription
                            }}</span>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          class="team flex flex-col padding5"
                        >
                          <div
                            data-v-89734b7c=""
                            class="flex-1 align-center flex"
                          >
                            <span data-v-89734b7c="" class="teamLogoBox"
                              ><img
                                data-v-89734b7c=""
                                v-if="match.homeInfo"
                                :src="
                                  match.homeInfo.logo
                                    ? match.homeInfo.logo
                                    : bannerImagePath('default-team.png')
                                "
                                alt=""
                            /></span>
                            <div data-v-89734b7c="" class="w-o-h elseTeamName">
                              <template v-if="match && match.homeInfo">
                                {{
                                  this.$i18n.locale == "cn" &&
                                  match.homeInfo.cn_name
                                    ? match.homeInfo.cn_name
                                    : match.homeInfo.en_name
                                    ? match.homeInfo.en_name
                                    : ""
                                }}
                              </template>
                            </div>
                            <span
                              data-v-89734b7c=""
                              style="margin-left: 5px; display: none"
                              >[]</span
                            >
                          </div>
                          <div
                            data-v-89734b7c=""
                            class="flex-1 align-center flex"
                          >
                            <span data-v-89734b7c="" class="teamLogoBox">
                              <img
                                data-v-89734b7c=""
                                v-if="match.awayInfo"
                                :src="
                                  match.awayInfo.logo
                                    ? match.awayInfo.logo
                                    : bannerImagePath('default-team.png')
                                "
                                alt=""
                              />
                            </span>
                            <div data-v-89734b7c="" class="w-o-h elseTeamName">
                              <template v-if="match && match.awayInfo"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.awayInfo.cn_name
                                    ? match.awayInfo.cn_name
                                    : match.awayInfo.en_name
                                    ? match.awayInfo.en_name
                                    : ""
                                }}
                              </template>
                            </div>
                            <span
                              data-v-89734b7c=""
                              style="margin-left: 5px; display: none"
                              >[]</span
                            >
                          </div>
                        </div>
                        <div data-v-89734b7c="" class="teamScore padding5">
                          <div
                            data-v-89734b7c=""
                            class="flex flex-col align-center"
                            style="height: 100%"
                          >
                            <div
                              data-v-89734b7c=""
                              class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                position: relative;
                              "
                            >
                              <div data-v-89734b7c="" class="svgBallIconBox">
                                <img
                                  v-if="
                                    topTeamInningsStatuses.findIndex(
                                      (status) => status == match.status_id
                                    ) != -1
                                  "
                                  data-v-89734b7c=""
                                  src="/images/baseball-bat.png"
                                  alt="#"
                                  class="svgBallIcon"
                                />
                              </div>
                              <div data-v-89734b7c="" class="bigScore bh">
                                <span data-v-89734b7c="" class="underway">
                                  <span
                                    data-v-89734b7c=""
                                    style="font-family: Roboto-Regular, Roboto"
                                  >
                                    <span
                                      v-if="
                                        match.status_id ==
                                        match_status.NOT_STARTED
                                      "
                                      data-v-89734b7c=""
                                    >
                                    </span>
                                    <span
                                      v-else
                                      data-v-89734b7c=""
                                      :class="{
                                        scoreRed: true,
                                      }"
                                      ><span data-v-89734b7c="" class=""
                                        ><span data-v-89734b7c="" class="">
                                          {{
                                            match.scores && match.scores.ft
                                              ? match.scores.ft[0]
                                              : ""
                                          }}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div data-v-89734b7c="" class="bigScore bh">
                                <span data-v-89734b7c="" class="underway"
                                  ><span
                                    data-v-89734b7c=""
                                    style="font-family: Roboto-Regular, Roboto"
                                  ></span
                                ></span>
                              </div>
                            </div>
                            <div
                              data-v-89734b7c=""
                              class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                position: relative;
                              "
                            >
                              <div data-v-89734b7c="" class="svgBallIconBox">
                                <img
                                  v-if="
                                    bottomTeamInningsStatuses.findIndex(
                                      (status) => status == match.status_id
                                    ) != -1
                                  "
                                  data-v-89734b7c=""
                                  src="/images/baseball-bat.png"
                                  alt="#"
                                  class="svgBallIcon"
                                />
                              </div>
                              <div data-v-89734b7c="" class="bigScore bh">
                                <span
                                  data-v-89734b7c=""
                                  class="scoreWeight underway"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    style="font-family: Roboto-Regular, Roboto"
                                  >
                                    <span
                                      v-if="
                                        match.status_id ==
                                        match_status.NOT_STARTED
                                      "
                                      data-v-89734b7c=""
                                    >
                                    </span>
                                    <span
                                      v-else
                                      data-v-89734b7c=""
                                      :class="{
                                        scoreRed: true,
                                      }"
                                      ><span data-v-89734b7c="" class=""
                                        ><span data-v-89734b7c="" class="">
                                          {{
                                            match.scores && match.scores.ft
                                              ? match.scores.ft[1]
                                              : ""
                                          }}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div data-v-89734b7c="" class="bigScore bh">
                                <span data-v-89734b7c="" class="underway"
                                  ><span
                                    data-v-89734b7c=""
                                    style="font-family: Roboto-Regular, Roboto"
                                  ></span
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          class="flex-1 icon-setting"
                          style="border-right: 1px solid rgb(234, 234, 234)"
                        ></div>
                        <div
                          data-v-89734b7c=""
                          class="flex Moneyline moneyLineContent"
                          style="position: relative"
                        >
                          <div data-v-89734b7c="" class="flex flex-1 oddsItem">
                            <div
                              data-v-89734b7c=""
                              class="text-center le padding5 lh oddsItemBox oddsWidth1"
                            >
                              <div data-v-89734b7c="">
                                <div data-v-89734b7c="">
                                  <div data-v-89734b7c="" class="oddhoverGreen">
                                    {{
                                      match.odds && match.odds.euro
                                        ? parseFloat(
                                            match?.odds?.euro[1]
                                          ).toFixed(2)
                                        : "-"
                                    }}
                                  </div>
                                  <div
                                    data-v-89734b7c=""
                                    class="oddhoverGreen oddTop"
                                  >
                                    {{
                                      match.odds && match.odds.euro
                                        ? parseFloat(
                                            match?.odds?.euro[3]
                                          ).toFixed(2)
                                        : "-"
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="flex le padding5 oddsItemBox oddsWidth2"
                            >
                              <div class="flex-1 lh handicapColor">
                                <div>
                                  {{
                                    match.odds && match.odds.asia
                                      ? match.odds.asia[2] * -1 > 0
                                        ? "+" +
                                          parseFloat(
                                            match.odds.asia[2] * -1
                                          ).toFixed(1)
                                        : match.odds.asia[2] * -1
                                      : "-"
                                  }}
                                </div>
                                <div class="oddTop">
                                  {{
                                    match.odds && match.odds.asia
                                      ? match.odds.asia[2] > 0
                                        ? "+" +
                                          parseFloat(
                                            match.odds.asia[2]
                                          ).toFixed(1)
                                        : match.odds.asia[2]
                                      : "-"
                                  }}
                                </div>
                              </div>
                              <div class="flex-1 lh oddTextColor">
                                <div
                                  class="oddhoverGreen"
                                  style="text-align: center"
                                >
                                  {{
                                    match.odds && match.odds.asia
                                      ? parseFloat(match.odds.asia[1]).toFixed(
                                          2
                                        )
                                      : "-"
                                  }}
                                </div>
                                <div
                                  class="oddhoverGreen oddTop"
                                  style="text-align: center"
                                >
                                  {{
                                    match.odds && match.odds.asia
                                      ? parseFloat(
                                          match?.odds?.asia[3]
                                        ).toFixed(2)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              data-v-89734b7c=""
                              class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                            >
                              <div
                                data-v-89734b7c=""
                                class="flex-1 lh handicapColor"
                              >
                                <div data-v-89734b7c="">
                                  {{
                                    match.odds && match.odds.bigSmall
                                      ? "O " +
                                        parseFloat(
                                          match.odds.bigSmall[2]
                                        ).toFixed(2)
                                      : "-"
                                  }}
                                </div>
                                <div data-v-89734b7c="" class="oddTop">
                                  {{
                                    match.odds && match.odds.bigSmall
                                      ? "U " +
                                        parseFloat(
                                          match.odds.bigSmall[2]
                                        ).toFixed(2)
                                      : "-"
                                  }}
                                </div>
                              </div>
                              <div
                                data-v-89734b7c=""
                                class="flex-1 lh oddTextColor"
                              >
                                <div data-v-89734b7c="" class="oddhoverGreen">
                                  {{
                                    match.odds && match.odds.bigSmall
                                      ? parseFloat(
                                          match.odds.bigSmall[1] + 1
                                        ).toFixed(2)
                                      : "-"
                                  }}
                                </div>
                                <div
                                  data-v-89734b7c=""
                                  class="oddhoverGreen oddTop"
                                >
                                  {{
                                    match.odds && match.odds.bigSmall
                                      ? parseFloat(
                                          match.odds.bigSmall[3] + 1
                                        ).toFixed(2)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upcoming search result -->

    <!-- <div
      v-if="Object.entries(searchMatchListUpcoming).length !== 0"
      class="vue-recycle-scroller__item-view"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="tag_commingMatches">
          <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
          <span data-v-46436e01="" class="text">
            {{ $t("football.live-score.today-upcoming-matches") }}</span
          >
        </div>
      </div>
    </div> -->

    <div
      class="vue-recycle-scroller__item-view"
      v-for="(league, key) in searchMatchListUpcoming"
      :key="key"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="flex align-center">
          <div data-v-89734b7c="" data-v-46436e01="" style="width: 100%">
            <div
              data-v-89734b7c=""
              class="list_title"
              :class="
                leagueFavoriteStatus[league[0].unique_tournament_id]
                  ? 'collectCom'
                  : ''
              "
            >
              <div data-v-89734b7c="" class="topt flex">
                <div
                  data-v-89734b7c=""
                  style="z-index: -1; width: 13.03%; min-width: 124px"
                ></div>
                <div
                  data-v-89734b7c=""
                  style="
                    z-index: -1;
                    margin-left: 10px;
                    width: 21%;
                    min-width: 200px;
                  "
                ></div>
                <div data-v-89734b7c="" class="scoreBox" style="width: 30%">
                  <div data-v-89734b7c="" style="width: 150px"></div>
                  <div data-v-89734b7c="" style="width: 150px"></div>
                  <div data-v-89734b7c="" class="flex-1"></div>
                </div>
                <div data-v-89734b7c="" class="flex-1 headBoxRight"></div>
                <div data-v-89734b7c="" class="Moneyline">
                  <div
                    data-v-89734b7c=""
                    class="text-center oddsTypeText oddsWidth1 bh"
                  >
                    {{ $t("football.league-details.to-win") }}
                  </div>
                  <div class="text-center oddsTypeText oddsWidth2 bh">
                    {{ $t("football.league-details.runs") }}
                  </div>
                  <div
                    data-v-89734b7c=""
                    class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                  >
                    {{ $t("football.league-details.total-runs") }}
                  </div>
                </div>
              </div>
              <div data-v-89734b7c="" class="flex align-center topLeftBox">
                <i
                  class="iconfont"
                  :class="
                    leagueFavoriteStatus[league[0].unique_tournament_id]
                      ? 'icon-yishoucang'
                      : 'icon-weishoucang'
                  "
                  @click="toggleFavorite(league[0].unique_tournament_id)"
                  style="visibility: hidden"
                ></i>

                <img
                  data-v-89734b7c=""
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo) ||
                      (league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name) ||
                      (league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name))
                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt="China"
                  title="China"
                  class="countryLogo"
                />

                <span data-v-89734b7c="" class="countryText">
                  <span data-v-f1b1cb6e="" class="country-name minitext">
                    <template v-if="league[0] && league[0].leagueInfo">
                      <template v-if="this.$i18n.locale == 'cn'">
                        <template
                          v-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.cn_name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}:
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}:
                        </template>
                        <template v-else> </template>
                      </template>
                    </template>
                  </span>
                </span>

                <a
                  v-if="
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].hasLeagueDetails
                  "
                  data-v-2eea2747=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/baseball/league/details/' +
                    league[0].leagueInfo.en_name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    league[0].unique_tournament_id
                  "
                  target="_blank"
                  class="compText"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
                <a
                  v-else
                  target="_blank"
                  class="compText"
                  style="cursor: not-allowed"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
              </div>
            </div>
            <div data-v-89734b7c="">
              <div
                data-v-89734b7c=""
                class="list newList"
                style="height: 64px"
                v-for="match in league"
                :key="match.id"
              >
                <div data-v-89734b7c="" class="list">
                  <div
                    data-v-89734b7c=""
                    class="flex align-center justify-center collectImgBox"
                  >
                    <!-- <i
                    data-v-89734b7c=""
                    class="iconfont icon-weishoucang collectImg"
                    style="visibility: visible"
                  ></i> -->
                  </div>
                  <a
                    data-v-89734b7c=""
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/baseball/match/details/' +
                      match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                      '-vs-' +
                      match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                      '/' +
                      match.id
                    "
                    target="_blank"
                    itemscope="itemscope"
                    itemtype="http://schema.org/SportsEvent"
                    class="flex flex-1"
                    style="height: 100%"
                  >
                    <div
                      data-v-89734b7c=""
                      class="flex align-center listBox justify-around"
                    >
                      <span
                        data-v-89734b7c=""
                        class="fs-13 matchTime"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ readableTime(this.$i18n.locale, match.match_time) }}
                      </span>
                      <div
                        data-v-89734b7c=""
                        class="flex flex-col fs-13 align-center color-r text-center barItem"
                      >
                        <span data-v-89734b7c="">{{
                          match.status_id &&
                          this.FinishedMatches.includes(match.status_id)
                            ? $t("football.league-details.full-time")
                            : match?.statusDescription
                        }}</span>
                      </div>
                    </div>
                    <div data-v-89734b7c="" class="team flex flex-col padding5">
                      <div data-v-89734b7c="" class="flex-1 align-center flex">
                        <span data-v-89734b7c="" class="teamLogoBox"
                          ><img
                            data-v-89734b7c=""
                            v-if="match.homeInfo"
                            :src="
                              match.homeInfo.logo
                                ? match.homeInfo.logo
                                : bannerImagePath('default-team.png')
                            "
                            alt=""
                            itemprop="logo"
                        /></span>
                        <div
                          data-v-89734b7c=""
                          itemprop="homeTeam"
                          class="w-o-h elseTeamName"
                        >
                          <template v-if="match && match.homeInfo">{{
                            this.$i18n.locale == "cn" && match.homeInfo.cn_name
                              ? match.homeInfo.cn_name
                              : match.homeInfo.en_name
                              ? match.homeInfo.en_name
                              : ""
                          }}</template>
                        </div>
                        <span
                          data-v-89734b7c=""
                          style="margin-left: 5px; display: none"
                          >[]</span
                        >
                      </div>
                      <div data-v-89734b7c="" class="flex-1 align-center flex">
                        <span data-v-89734b7c="" class="teamLogoBox"
                          ><img
                            data-v-89734b7c=""
                            v-if="match.awayInfo"
                            :src="
                              match.awayInfo.logo
                                ? match.awayInfo.logo
                                : bannerImagePath('default-team.png')
                            "
                            alt=""
                            itemprop="logo"
                        /></span>
                        <div
                          data-v-89734b7c=""
                          itemprop="awayTeam"
                          class="w-o-h elseTeamName"
                        >
                          <template v-if="match && match.awayInfo">{{
                            this.$i18n.locale == "cn" && match.awayInfo.cn_name
                              ? match.awayInfo.cn_name
                              : match.awayInfo.en_name
                              ? match.awayInfo.en_name
                              : ""
                          }}</template>
                        </div>
                        <span
                          data-v-89734b7c=""
                          style="margin-left: 5px; display: none"
                          >[]</span
                        >
                      </div>
                    </div>
                    <div data-v-89734b7c="" class="teamScore padding5">
                      <div
                        data-v-89734b7c=""
                        class="flex flex-col align-center"
                        style="height: 100%"
                      >
                        <div
                          data-v-89734b7c=""
                          class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                          style="
                            font-family: Roboto-Regular, Roboto;
                            position: relative;
                          "
                        >
                          <div data-v-89734b7c="" class="svgBallIconBox">
                            <svg
                              data-v-89734b7c=""
                              aria-hidden="true"
                              class="svgBallIcon"
                            >
                              <use
                                data-v-89734b7c=""
                                xlink:href="#icon-banqiu-banzi"
                              ></use>
                            </svg>
                          </div>
                          <div data-v-89734b7c="" class="bigScore bh">
                            <span data-v-89734b7c="" class="underway"
                              ><span
                                data-v-89734b7c=""
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <span
                                  v-if="
                                    match.status_id == match_status.NOT_STARTED
                                  "
                                  data-v-89734b7c=""
                                >
                                </span>
                                <span
                                  v-else
                                  data-v-89734b7c=""
                                  :class="{
                                    scoreRed:
                                      homeTeamInningsStatuses.findIndex(
                                        (status) => status == match.status_id
                                      ) != -1,
                                  }"
                                  ><span data-v-89734b7c="" class=""
                                    ><span data-v-89734b7c="" class="">
                                      {{
                                        match.scores && match.scores.ft
                                          ? match.scores.ft[0]
                                          : ""
                                      }}
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div data-v-89734b7c="" class="bigScore bh">
                            <span data-v-89734b7c="" class="underway"
                              ><span
                                data-v-89734b7c=""
                                style="font-family: Roboto-Regular, Roboto"
                              ></span
                            ></span>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                          style="
                            font-family: Roboto-Regular, Roboto;
                            position: relative;
                          "
                        >
                          <div data-v-89734b7c="" class="svgBallIconBox"></div>
                          <div data-v-89734b7c="" class="bigScore bh">
                            <span
                              data-v-89734b7c=""
                              class="scoreWeight underway"
                            >
                              <span
                                data-v-89734b7c=""
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <span data-v-89734b7c=""> </span>
                              </span>
                            </span>
                          </div>
                          <div data-v-89734b7c="" class="bigScore bh">
                            <span data-v-89734b7c="" class="underway"
                              ><span
                                data-v-89734b7c=""
                                style="font-family: Roboto-Regular, Roboto"
                              ></span
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-v-89734b7c=""
                      class="flex-1 icon-setting"
                      style="border-right: 1px solid rgb(234, 234, 234)"
                    >
                      <i class="iconfont icon-donghuazhibo videoAn"></i>
                    </div>
                    <div
                      data-v-89734b7c=""
                      class="flex Moneyline moneyLineContent"
                      style="position: relative"
                    >
                      <div data-v-89734b7c="" class="flex flex-1 oddsItem">
                        <div
                          data-v-89734b7c=""
                          class="text-center le padding5 lh oddsItemBox oddsWidth1"
                        >
                          <div data-v-89734b7c="">
                            <div data-v-89734b7c="">
                              <div data-v-89734b7c="" class="oddhoverGreen">
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match.odds.euro[1]).toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div
                                data-v-89734b7c=""
                                class="oddhoverGreen oddTop"
                              >
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match?.odds?.euro[3]).toFixed(
                                        2
                                      )
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex le padding5 oddsItemBox oddsWidth2">
                          <div class="flex-1 lh handicapColor">
                            <div>
                              {{
                                match.odds && match.odds.asia
                                  ? match.odds.asia[2] * -1 > 0
                                    ? "+" +
                                      parseFloat(
                                        match.odds.asia[2] * -1
                                      ).toFixed(1)
                                    : match.odds.asia[2] * -1
                                  : "-"
                              }}
                            </div>
                            <div class="oddTop">
                              {{
                                match.odds && match.odds.asia
                                  ? match.odds.asia[2] > 0
                                    ? "+" +
                                      parseFloat(match.odds.asia[2]).toFixed(1)
                                    : match.odds.asia[2]
                                  : "-"
                              }}
                            </div>
                          </div>
                          <div class="flex-1 lh oddTextColor">
                            <div
                              class="oddhoverGreen"
                              style="text-align: center"
                            >
                              {{
                                match.odds && match.odds.asia
                                  ? parseFloat(match.odds.asia[1]).toFixed(1)
                                  : "-"
                              }}
                            </div>
                            <div
                              class="oddhoverGreen oddTop"
                              style="text-align: center"
                            >
                              {{
                                match.odds && match.odds.asia
                                  ? parseFloat(match?.odds?.asia[3]).toFixed(1)
                                  : "-"
                              }}
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                        >
                          <div
                            data-v-89734b7c=""
                            class="flex-1 lh handicapColor"
                          >
                            <div data-v-89734b7c="">
                              {{
                                match.odds && match.odds.bigSmall
                                  ? "O " +
                                    parseFloat(match.odds.bigSmall[2]).toFixed(
                                      1
                                    )
                                  : "-"
                              }}
                            </div>
                            <div data-v-89734b7c="" class="oddTop">
                              {{
                                match.odds && match.odds.bigSmall
                                  ? "U " +
                                    parseFloat(match.odds.bigSmall[2]).toFixed(
                                      1
                                    )
                                  : "-"
                              }}
                            </div>
                          </div>
                          <div
                            data-v-89734b7c=""
                            class="flex-1 lh oddTextColor"
                          >
                            <div data-v-89734b7c="" class="oddhoverGreen">
                              {{
                                match.odds && match.odds.bigSmall
                                  ? parseFloat(
                                      match.odds.bigSmall[1] + 1
                                    ).toFixed(2)
                                  : "-"
                              }}
                            </div>
                            <div
                              data-v-89734b7c=""
                              class="oddhoverGreen oddTop"
                            >
                              {{
                                match.odds && match.odds.bigSmall
                                  ? parseFloat(
                                      match.odds.bigSmall[3] + 1
                                    ).toFixed(2)
                                  : "-"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, DateMixin],

  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",

      topTeamInningsStatuses: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      ],
      bottomTeamInningsStatuses: [
        433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      ],
      // TOP AWAY
      // BOTTOM HOME
      match_status: {
        NOT_STARTED: 1,
        FIRST_INNINGS_TOP_TEAM: 432,
        FIRST_INNINGS_BOTTOM_TEAM: 433,
        SECOND_INNINGS_TOP_TEAM: 434,
        SECOND_INNINGS_BOTTOM_TEAM: 435,
        THIRD_INNINGS_TOP_TEAM: 436,
        THIRD_INNINGS_BOTTOM_TEAM: 437,
        FOURTH_INNINGS_TOP_TEAM: 438,
        FOURTH_INNINGS_BOTTOM_TEAM: 439,
        FIFTH_INNINGS_TOP_TEAM: 440,
        FIFTH_INNINGS_BOTTOM_TEAM: 411,
        SIXTH_INNINGS_TOP_TEAM: 412,
        SIXTH_INNINGS_BOTTOM_TEAM: 413,
        SEVENTH_INNINGS_TOP_TEAM: 414,
        SEVENTH_INNINGS_BOTTOM_TEAM: 415,
        EIGHTH_INNINGS_TOP_TEAM: 416,
        EIGHTH_INNINGS_BOTTOM_TEAM: 417,
        NINTH_INNINGS_TOP_TEAM: 418,
        NINTH_INNINGS_BOTTOM_TEAM: 419,
        EXTRA_INNINGS_TOP_TEAM: 420,
        EXTRA_INNINGS_BOTTOM_TEAM: 421,
      },
    };
  },

  computed: {
    thereIsUpcomingMatches() {
      return true;
    },
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `baseball_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`baseball_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`baseball_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },

    // method to get upcoming matchlist
    // getUpcomingMatchList(status) {
    //   this.liveActivebtn = status;
    //   this.loading = true;
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/baseball/match/list/today?matchStatus=${status}`
    //     )
    //     .then((response) => {
    //       this.todayMatchList = response.data.baseballMatchList;
    //       this.loadFavoriteStatus();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>
