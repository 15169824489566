<template>
  <div class="w100">
    <div data-v-40b57f9e="" class="cricket_overview">
      <div
        data-v-826fe974=""
        data-v-40b57f9e=""
        class="odds-box"
        type="overview"
      >
        <div data-v-826fe974="" class="list">
          <div data-v-0e07873a="" data-v-826fe974="" class="oddsBox">
            <div data-v-0e07873a="" class="oddsContent">
              <p data-v-0e07873a="" class="oddsType flex">
                <span data-v-0e07873a="" class="placeholder"></span>

                <span
                  data-v-0e07873a=""
                  class="oddsItem flex-1 justify-center align-center"
                >
                  {{ $t("football.league-details.to-win") }}
                </span>
              </p>
              <div data-v-0e07873a="" class="oddsBox flex">
                <div
                  data-v-0e07873a=""
                  class="oddsBoxLeft flex justify-center align-center"
                >
                  <img
                    data-v-0e07873a=""
                    src="/images/odds/bet365.png"
                    alt="#"
                    class="companyLogo"
                  />
                </div>
                <div data-v-0e07873a="" class="oddsBoxContent flex-1">
                  <div data-v-0e07873a="" class="oddsItem flex flex-1 border2">
                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center borderR"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][1]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][1].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >

                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][3]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
              <p data-v-0e07873a="" class="hint">
                {{ $t("football.league-details.gamble-responsibly") }}
              </p>
              <p data-v-0e07873a="" class="oddsTypeText mt-12">
                <span data-v-0e07873a="" class="openingBox"
                  ><span data-v-0e07873a="" class="chunk opening"></span>
                  <span data-v-0e07873a="">
                    {{ $t("football.league-details.opening-odds") }}
                  </span></span
                >
                <span data-v-0e07873a="" class="openingBox mt-8"
                  ><span data-v-0e07873a="" class="chunk pre-match"></span>
                  <span data-v-0e07873a="">{{
                    $t("football.league-details.pre-match-odds")
                  }}</span></span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-v-40b57f9e="" class="info"></div>
    </div>

    <div class="prefetch"></div>
  </div>

  <div
    class="content"
    v-if="todayMatchDetails.status_id != match_status.NOT_STARTED"
  >
    <div class="baseball_overview">
      <div class="left">
        <div class="topScore">
          <div
            style="
              width: 100%;
              padding: 0.1rem 0 0.05rem 0;
              box-sizing: border-box;
            "
          >
            <div class="w100 js flex">
              <div class="border-bottom" style="width: 4rem"></div>
              <div class="border-bottom flex-1">R</div>
              <div class="border-bottom flex-1">1</div>
              <div class="border-bottom flex-1">2</div>
              <div class="border-bottom flex-1">3</div>
              <div class="border-bottom flex-1">4</div>
              <div class="border-bottom flex-1">5</div>
              <div class="border-bottom flex-1">6</div>
              <div class="border-bottom flex-1">7</div>
              <div class="border-bottom flex-1">8</div>
              <div class="border-bottom flex-1">9</div>
              <div class="border-bottom flex-1">EI</div>
              <div class="border-bottom flex-1">H</div>
              <div class="border-bottom flex-1">E</div>
            </div>
            <div class="w100 detailScore flex">
              <div
                class="col"
                style="
                  width: 4rem;
                  padding-left: 0.32rem;
                  border-right: 1px solid #eeeeee;
                "
              >
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        todayMatchDetails.homeInfo.logo
                          ? todayMatchDetails.homeInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      todayMatchDetails.homeInfo.cn_name
                        ? todayMatchDetails.homeInfo.cn_name
                        : todayMatchDetails.homeInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_1"></use>
                  </svg>
                  <!---->
                </div>
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        todayMatchDetails.awayInfo.logo
                          ? todayMatchDetails.awayInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      todayMatchDetails.awayInfo.cn_name
                        ? todayMatchDetails.awayInfo.cn_name
                        : todayMatchDetails.awayInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_2"></use>
                  </svg>
                  <!---->
                </div>
              </div>
              <div class="col flex-1" style="border-right: 1px solid #eeeeee">
                <div class="scoreText active">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ft"] &&
                    todayMatchDetails.scores["ft"][0]
                      ? todayMatchDetails.scores["ft"][0]
                      : 0
                  }}
                </div>
                <div class="scoreText active">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ft"] &&
                    todayMatchDetails.scores["ft"][1]
                      ? todayMatchDetails.scores["ft"][1]
                      : 0
                  }}
                </div>
              </div>
              <div
                v-for="index in 9"
                :key="index"
                class="col flex-1"
                style="border-right: 1px solid #eeeeee"
              >
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["p" + index] &&
                    todayMatchDetails.scores["p" + index][0]
                      ? todayMatchDetails.scores["p" + index][0]
                      : 0
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["p" + index] &&
                    todayMatchDetails.scores["p" + index][1]
                      ? todayMatchDetails.scores["p" + index][1]
                      : 0
                  }}
                </div>
              </div>

              <div class="col flex-1" style="border-right: 1px solid #eeeeee">
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ei"] &&
                    todayMatchDetails.scores["ei"][0]
                      ? todayMatchDetails.scores["ei"][0]
                      : 0
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ei"] &&
                    todayMatchDetails.scores["ei"][1]
                      ? todayMatchDetails.scores["ei"][1]
                      : 0
                  }}
                </div>
              </div>
              <div class="col flex-1" style="border-right: 1px solid #eeeeee">
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["h"] &&
                    todayMatchDetails.scores["h"][0]
                      ? todayMatchDetails.scores["h"][0]
                      : 0
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["h"] &&
                    todayMatchDetails.scores["h"][1]
                      ? todayMatchDetails.scores["h"][1]
                      : 0
                  }}
                </div>
              </div>
              <div class="col flex-1" style="border-right: 1px solid #eeeeee">
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["e"] &&
                    todayMatchDetails.scores["e"][0]
                      ? todayMatchDetails.scores["e"][0]
                      : 0
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["e"] &&
                    todayMatchDetails.scores["e"][1]
                      ? todayMatchDetails.scores["e"][1]
                      : 0
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    todayMatchDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      match_status: {
        NOT_STARTED: 1,
        FIRST_INNINGS_TOP_TEAM: 432,
        FIRST_INNINGS_BOTTOM_TEAM: 433,
        SECOND_INNINGS_TOP_TEAM: 434,
        SECOND_INNINGS_BOTTOM_TEAM: 435,
        THIRD_INNINGS_TOP_TEAM: 436,
        THIRD_INNINGS_BOTTOM_TEAM: 437,
        FOURTH_INNINGS_TOP_TEAM: 438,
        FOURTH_INNINGS_BOTTOM_TEAM: 439,
        FIFTH_INNINGS_TOP_TEAM: 440,
        FIFTH_INNINGS_BOTTOM_TEAM: 411,
        SIXTH_INNINGS_TOP_TEAM: 412,
        SIXTH_INNINGS_BOTTOM_TEAM: 413,
        SEVENTH_INNINGS_TOP_TEAM: 414,
        SEVENTH_INNINGS_BOTTOM_TEAM: 415,
        EIGHTH_INNINGS_TOP_TEAM: 416,
        EIGHTH_INNINGS_BOTTOM_TEAM: 417,
        NINTH_INNINGS_TOP_TEAM: 418,
        NINTH_INNINGS_BOTTOM_TEAM: 419,
        EXTRA_INNINGS_TOP_TEAM: 420,
        EXTRA_INNINGS_BOTTOM_TEAM: 421,
      },
      matchLineups: null,
      loading: true,
      matchDetails: null,
    };
  },
  created() {
    this.matchDetails = this.todayMatchDetails;
  },
};
</script>