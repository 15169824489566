<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-00737b1a="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.latest-matches-of-this-league") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>

          <!-- if data is available -->

          <div
            v-if="loading"
            class="table Matches pl-15 pr-15 pb-24"
            data-v-e39f9930=""
            data-v-54d3d41a=""
            data-v-7c947e55=""
          >
            <div class="body" data-v-0939e9fc="">
              <div
                class="row flex fs-12 color-333 cur-pointer"
                data-v-0939e9fc=""
              >
                <a
                  class="col justify-start border-box"
                  style="width: 200px; padding-left: 12px"
                  data-v-0939e9fc=""
                >
                  <img
                    src="/images/default-loader.gif"
                    style="width: 120px !important; height: 60px !important"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            class="table Matches pl-15 pr-15 pb-24"
            data-v-e39f9930=""
            data-v-54d3d41a=""
            data-v-7c947e55=""
            v-else-if="teamMatches && teamMatches != ''"
          >
            <div
              class="body"
              style="min-height: 0px"
              data-v-e39f9930=""
              v-for="match in paginatedMatches"
              :key="match.id"
            >
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/match/details/' +
                  match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '-vs-' +
                  match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '/' +
                  match.id
                "
                target="_blank"
              >
                <div class="row flex fs-12 color-333" data-v-e39f9930="">
                  <div
                    itemprop="startDate"
                    class="col col-15 border-box"
                    style="justify-content: flex-start; padding-left: 12px"
                    data-v-e39f9930=""
                  >
                    <b style="font-size: 14px">
                      {{ getTimeStampDate(match.startTimestamp) }}</b
                    >
                  </div>
                  <span
                    class="col col-30 color-p color-p"
                    style="
                      justify-content: flex-end;
                      border-radius: 10px !important;
                    "
                    data-v-e39f9930=""
                    :style="
                      match.winnerCode == 1
                        ? 'background-color: #5dbb60'
                        : match.winnerCode == 2
                        ? 'background-color: rgb(175,39,39)'
                        : 'background-color: rgb(241,241,241)'
                    "
                    ><span
                      class="w-o-h"
                      data-v-e39f9930=""
                      :style="
                        match.winnerCode == 1
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : match.winnerCode == 2
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : 'font-size: 14px !important;font-weight: bold;   color: black;'
                      "
                    >
                      {{ match.homeTeam.name }}
                    </span>
                    <img
                      style="
                        width: 30px !important;
                        height: 30px !important;
                        margin-right: 15px;
                      "
                      :src="
                        'https://sportyclub.online//images/cricket/teams/' +
                        match.homeTeam.id +
                        '/' +
                        match.homeTeam.id +
                        '.png'
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="team-logo ml-12"
                      data-v-e39f9930=""
                    />
                  </span>

                  <span class="col col-30" data-v-e39f9930="">
                    <h3
                      style="
                        font-weight: bold;
                        padding: 6px;
                        border-radius: 7px;
                        font-size: 13px;
                        /* font-weight: bold; */
                      "
                    >
                      VS
                    </h3>
                  </span>
                  <span
                    style="border-radius: 10px !important"
                    class="col col-30 color-p color-p justify-start"
                    data-v-e39f9930=""
                    :style="
                      match.winnerCode == 2
                        ? 'background-color: #5dbb60'
                        : match.winnerCode == 1
                        ? 'background-color: rgb(175,39,39)'
                        : 'background-color: rgb(241,241,241)'
                    "
                  >
                    <img
                      style="
                        width: 30px !important;
                        height: 30px !important;
                        margin-left: 15px !important;
                      "
                      :src="
                        'https://sportyclub.online//images/cricket/teams/' +
                        match.awayTeam.id +
                        '/' +
                        match.awayTeam.id +
                        '.png'
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="team-logo mr-12"
                      data-v-e39f9930=""
                    />
                    <span
                      itemprop="awayTeam"
                      class="w-o-h"
                      data-v-e39f9930=""
                      style="font-size: 14px !important"
                      :style="
                        match.winnerCode == 2
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : match.winnerCode == 1
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : 'font-size: 14px !important;font-weight: bold;   color: black;'
                      "
                    >
                      {{ match.awayTeam.name }}
                    </span></span
                  >
                </div>
                <br />
              </a>
            </div>
            <!-- begin::pagination -->
            <div class="text-center mt-24">
              <div class="el-pagination">
                <button
                  type="button"
                  class="btn-prev"
                  @click="prevPage"
                  :disabled="currentPage === 1"
                >
                  <i class="el-icon el-icon-arrow-left"></i>
                </button>
                <ul class="el-pager">
                  <li
                    class="number"
                    v-for="page in totalPages"
                    :key="page"
                    :class="{ active: page === currentPage }"
                    @click="changePage(page)"
                  >
                    {{ page }}
                  </li>
                </ul>
                <button
                  type="button"
                  class="btn-next"
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  <i class="el-icon el-icon-arrow-right"></i>
                </button>
              </div>
            </div>
            <!-- end::pagination -->
          </div>
        </div>
      </div>

      <!-- begin::rightsidebar -->
      <div class="p-r" data-v-29b39e42="">
        <div
          class="container-box mb-12 pb-24"
          data-v-7c947e55=""
          data-v-29b39e42=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                !matchLoading &&
                featuredMatch &&
                featuredMatch.tournament &&
                featuredMatch.tournament &&
                featuredMatch.tournament.name
                  ? featuredMatch.tournament.name
                  :  $t("football.league-details.featured-match")
              }}
            </span>
          </div>

          <div
            class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
            data-v-bb874c34=""
            data-v-29b39e42=""
            data-v-7c947e55=""
            v-if="matchLoading"
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <img
                src="/images/default-loader.gif"
                style="
                  width: 120px !important;
                  height: 60px !important;
                  margin-left: -50px;
                "
              />
            </div>
          </div>
          <div
            class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
            data-v-bb874c34=""
            data-v-29b39e42=""
            data-v-7c947e55=""
            v-if="!matchLoading"
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/team/details/' +
                  featuredMatch.homeTeam.name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  featuredMatch.homeTeam.id
                "
                target="_blank"
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
              >
                <img
                  :src="
                    featuredMatch.homeTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        featuredMatch.homeTeam.id +
                        '/' +
                        featuredMatch.homeTeam.id +
                        '.png'
                      : bannerImagePath('default-team.png')
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                >
                  {{ featuredMatch.homeTeam.name }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  <span>
                    {{ featuredMatchH2H ? featuredMatchH2H.homeWins : "" }}
                  </span>
                </span>
              </a>
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/team/details/' +
                  featuredMatch.awayTeam.name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  featuredMatch.awayTeam.id
                "
                target="_blank"
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
              >
                <img
                  :src="
                    featuredMatch.awayTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        featuredMatch.awayTeam.id +
                        '/' +
                        featuredMatch.awayTeam.id +
                        '.png'
                      : bannerImagePath('default-team.png')
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                  >{{ featuredMatch.awayTeam.name }}</span
                >
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  <span>
                    {{ featuredMatchH2H ? featuredMatchH2H.awayWins : "" }}
                  </span>
                </span>
              </a>
            </div>
            <div class="container-box" data-v-bb874c34="">
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Date</div>
                <div data-v-bb874c34="">
                  {{ timestampToDate(featuredMatch.startTimestamp) }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Time</div>
                <div data-v-bb874c34="">
                  {{ timestampToTime(featuredMatch.startTimestamp) }}
                </div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Toss Win</div>
                <div data-v-bb874c34="">
                  {{
                    featuredMatch && featuredMatch.tossWin
                      ? featuredMatch.tossWin
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Toss Decision</div>
                <div data-v-bb874c34="">
                  {{
                    featuredMatch && featuredMatch.tossDecision
                      ? featuredMatch.tossDecision
                      : "-"
                  }}
                </div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Venue</div>
                <div data-v-bb874c34="">
                  {{
                    featuredMatch &&
                    featuredMatch.venue &&
                    featuredMatch.venue.city &&
                    featuredMatch.venue.city.name
                      ? featuredMatch.venue.city.name
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">Country</div>
                <div data-v-bb874c34="">
                  {{
                    featuredMatch &&
                    featuredMatch.venue &&
                    featuredMatch.venue.country &&
                    featuredMatch.venue.country.name
                      ? featuredMatch.venue.country.name
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::rightsidebar -->
    </div>
  </div>
</template>
  <script>
import DynamicImagePath from "@/utils/dynamicImagePath";
import metaDataUtils from "@/utils/metaDataUtils";
export default {
  mixins: [DynamicImagePath, metaDataUtils],
  props: {
    teamId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      teamMatches: null,
      featuredMatch: null,
      featuredMatchH2H: true,
      matchLoading: true,
      currentPage: 1,
      matchesPerPage: 10,
    };
  },
  computed: {
    paginatedMatches() {
      const startIndex = (this.currentPage - 1) * this.matchesPerPage;
      const endIndex = startIndex + this.matchesPerPage;
      return this.teamMatches.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.teamMatches.length / this.matchesPerPage);
    },
  },
  created() {
    this.getteamMatches(this.teamId);
    this.getFeaturedMatch(this.teamId);
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getteamMatches(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/teams/next-matches/${teamId}`
        )
        .then((response) => {
          this.teamMatches = response.data.events;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get featured match
    getFeaturedMatch(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/teams/next-matches/${teamId}`
        )
        .then((response) => {
          this.getFeaturedMatchDetails(response.data.events[0].id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get featured match detailss
    getFeaturedMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/details/${matchId}`
        )
        .then((response) => {
          this.featuredMatch = response.data.event;
          this.getMatchH2H(matchId);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to toggle dropdown
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();
      return month + " " + day + ", " + year;
    },
    // method to convert timeStamp
    timestampToDate(timestamp) {
      // Create a new Date object from the timestamp (in milliseconds)
      var date = new Date(timestamp * 1000);

      // Array of month names
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Extract day, month, and year
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return day + " " + months[monthIndex] + " " + year;
    },
    // method to convert timestamp to time
    timestampToTime(timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    // method to get h2h
    getMatchH2H(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/single/match/h2h/${matchId}`
        )
        .then((response) => {
          if (response.data.teamDuel !== null) {
            this.featuredMatchH2H = response.data.teamDuel;
          } else {
            // Handle the case when teamDuel is null
            // For example, display a message or set a default value
            console.log("teamDuel is null");
          }
          this.matchLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  