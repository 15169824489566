<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div id="__nuxt" v-if="!loading">
    <div id="__layout">
      <div id="app" class="font-en" data-v-250f1619="">
        <div
          class="bk_competition view border-box"
          data-v-06d4278f=""
          data-v-250f1619
        >
          <div class="compheader" data-v-06d4278f="">
            <div class="backFil" data-v-06d4278f="">
              <div
                class="header"
                data-v-54d9a5ac=""
                data-v-06d4278f=""
                style=""
              >
                <div class="van-sticky">
                  <header
                    class="first-header"
                    style="background: #16418c"
                    data-v-54d9a5ac=""
                  >
                    <div
                      class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                      data-v-54d9a5ac=""
                    >
                      <div class="left van-col van-col--5" data-v-54d9a5ac="">
                        <!--                         
                        <span
                          class="icon iconfont myIcon iconback"
                          style="opacity: 0.6"
                          data-v-54d9a5ac=""
                        ></span>
                        -->
                      </div>
                      <div
                        class="proMod2 flex align-center justify-center van-col"
                        style="font-size: 0.4rem"
                        data-v-54d9a5ac=""
                      >
                        <!-- <span
                          data-v-54d9a5ac=""
                          class="formatClass"
                          style="opacity: 1"
                          ><span data-v-54d9a5ac=""
                            ><span data-v-54d9a5ac=""></span>
                            <span
                              data-v-54d9a5ac=""
                              itemprop="startDate"
                            ></span></span
                        ></span> -->
                      </div>
                      <div class="right van-col van-col--5" data-v-54d9a5ac="">
                        <!-- <div
                          class="right_slot flex align-center justify-around"
                          data-v-06d4278f=""
                        >
                          <span class="text" data-v-06d4278f="">23/24</span>
                          <i
                            class="seasonIcon van-icon van-icon-play"
                            data-v-06d4278f=""
                            ></i
                          >
                        </div> -->
                      </div>
                    </div>
                  </header>
                </div>
              </div>
            </div>
            <div
              class="commonHeadInfo flex align-center justify-center flex-column"
              data-v-06d4278f=""
            >
              <div
                itemprop="logo"
                class="comp_logo van-image van-image--round"
                style="overflow: hidden; border-radius: 50%"
                data-v-06d4278f=""
              >
                <img
                  :src="
                    leagueDetails && leagueDetails.logo
                      ? leagueDetails.logo + '!w100'
                     : bannerImagePath('default-nation.png')
                  "
                  :alt="
                    leagueDetails && leagueDetails.name
                      ? leagueDetails.name
                      : ''
                  "
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div class="comp_name" data-v-06d4278f="">
                {{
                  this.$i18n.locale == "cn" && leagueDetails.cn_name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                    ? leagueDetails.name
                    : ""
                }}
              </div>
              <div
                class="des flex align-center justify-center"
                data-v-06d4278f=""
              >
                <div
                  itemprop="logo"
                  class="country_logo van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                  data-v-06d4278f=""
                >
                  <img
                    :src="
                      leagueDetails &&
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.logo
                        ? leagueDetails.countryDetails.logo
                       : bannerImagePath('default-country.png')
                    "
                    alt=""
                    class="van-image__img"
                    style="object-fit: contain"
                  />
                </div>
                <span class="country" data-v-06d4278f=""
                  ><template v-if="leagueDetails">
                    <template v-if="this.$i18n.locale == 'cn'">
                      <template
                        v-if="
                          leagueDetails.countryDetails &&
                          leagueDetails.countryDetails.cn_name
                        "
                      >
                        {{ leagueDetails.countryDetails.cn_name }}
                      </template>
                      <template
                        v-else-if="
                          leagueDetails.categoryDetails &&
                          leagueDetails.categoryDetails.cn_name
                        "
                      >
                        {{ leagueDetails.categoryDetails.cn_name }}
                      </template>
                      <template
                        v-else-if="
                          leagueDetails.countryDetails &&
                          leagueDetails.countryDetails.name
                        "
                      >
                        {{ leagueDetails.countryDetails.name }}
                      </template>
                      <template
                        v-else-if="
                          leagueDetails.categoryDetails &&
                          leagueDetails.categoryDetails.name
                        "
                      >
                        {{ leagueDetails.categoryDetails.name }}
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          leagueDetails.countryDetails &&
                          leagueDetails.countryDetails.name
                        "
                      >
                        {{ leagueDetails.countryDetails.name }}
                      </template>
                      <template
                        v-else-if="
                          leagueDetails.categoryDetails &&
                          leagueDetails.categoryDetails.name
                        "
                      >
                        {{ leagueDetails.categoryDetails.name }}
                      </template>
                      <template v-else> </template>
                    </template>
                  </template>
                </span>
              </div>
            </div>
            <div data-v-06d4278f="" style="">
              <div class="van-sticky" style="">
                <div class="van-tabs van-tabs--line" data-v-06d4278f="">
                  <div
                    class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
                  >
                    <div
                      role="tablist"
                      class="van-tabs__nav van-tabs__nav--line"
                      style="border-color: #ffffff; background: #16418c"
                    >
                      <!-- <a
                        v-on:click="toggleTab('overview')"
                        :class="
                          'flex flex-1 justify-center align-center active' +
                          (activeTab == 'overview' ? ' active' : '')
                        "
                        target="_self"
                        class="flex flex-1 justify-center align-center"
                        data-v-06d4278f=""
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.overview")
                        }}</span>
                        <span
                          v-if="activeTab === 'overview'"
                          data-v-06d4278f=""
                        ></span>
                      </a> -->
                      <div
                        v-on:click="toggleTab('overview')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'overview'
                            ? 'color:rgba(255, 255, 255);'
                            : ''
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.overview")
                        }}</span>
                        <span
                          v-if="activeTab === 'overview'"
                          data-v-06d4278f=""
                        ></span>
                      </div>
                      <div
                        v-on:click="toggleTab('standings')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'standings'
                            ? 'color:rgba(255, 255, 255);'
                            : ''
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.standings")
                        }}</span>
                        <span
                          v-if="activeTab === 'standings'"
                          data-v-06d4278f=""
                        ></span>
                      </div>

                      <div
                        v-on:click="toggleTab('teams-stats')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'teams-stats'
                            ? 'color:rgba(255, 255, 255);'
                            : ''
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.teams-stats")
                        }}</span>
                      </div>

                      <div
                        v-on:click="toggleTab('player-stats')"
                        role="tab"
                        class="van-tab van-tab--complete"
                        style="color: rgba(255, 255, 255, 0.6)"
                        :style="
                          activeTab === 'player-stats'
                            ? 'color:rgba(255, 255, 255);'
                            : ''
                        "
                      >
                        <span class="van-tab__text">{{
                          $t("football.league-details.player-stats")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- begin::breadcrumb -->
          <div
            class="breadcrumb-box iconfont"
            style="background-color: "
            data-v-14e526a2=""
            data-v-1ebdf8d4=""
          >
            <div class="display-i-l" data-v-14e526a2="">
              <a
                :href="'/' + this.$i18n.locale + '/live/basketball'"
                target=""
                class="ele link"
                data-v-14e526a2=""
                ><span data-v-14e526a2="">{{
                  $t("basketball.league-details.basketball-live-score")
                }}</span>
              </a>
            </div>
            <span class="jt" data-v-14e526a2=""></span>

            <h1
              itemprop="itemListElement"
              class="ele text lastText"
              style="display: inline"
              data-v-14e526a2=""
            >
              <span itemprop="name" data-v-14e526a2="">
                {{
                  this.$i18n.locale == "cn" &&
                  leagueDetails &&
                  leagueDetails.name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                }}
              </span>
            </h1>
          </div>
          <!-- end::breadcrumb -->

          <div class="content" data-v-1ebdf8d4="" v-if="!loading">
            <div class="ft_tour_overview" data-v-61a7efa4="" data-v-1ebdf8d4="">
              <div class="w100" data-v-61a7efa4="">
                <LeagueOverview
                  v-if="activeTab == 'overview'"
                  :leagueId="leagueId"
                  :leagueDetails="leagueDetails"
                />
                <LeagueStandings
                  v-if="activeTab == 'standings'"
                  :leagueId="leagueId"
                  :leagueDetails="leagueDetails"
                />
                <TeamStats
                  v-if="activeTab == 'teams-stats'"
                  :leagueId="leagueId"
                  :leagueDetails="leagueDetails"
                />
                <PlayerStats
                  v-if="activeTab == 'player-stats'"
                  :leagueId="leagueId"
                  :leagueDetails="leagueDetails"
                />
              </div>
            </div>
          </div>
        </div>
        <MobileFooter />
      </div>
    </div>
  </div>
</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";
import TeamStats from "./tabs/teams-stats.vue";
import PlayerStats from "./tabs/players-stats.vue";
import LeagueOverview from "./tabs/league-overview.vue";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],

  components: {
    LeagueStandings,
    TeamStats,
    PlayerStats,
    LeagueOverview,
    
  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "overview",
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/basketball/league/details/${leagueId}`
        )
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
