<template>
  <div data-v-8502b08a="" class="page-box view border-box">
    <div data-v-8502b08a="" class="text-box container">
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        隐私政策
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        PRIVACY POLICY
      </p>
      <br />
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        重要信息和我们是谁
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        IMPORTANT INFORMATION AND WHO WE ARE
      </p>
      <p class="pb-24 color-666 par" v-if="this.$i18n.locale == 'cn'">
        我们是 Sportyclub Live，总部位于 Concord Tower 12 楼
        阿联酋迪拜媒体城。本页中提及的“Sportyclub Live”、“我们”或“我们的”均属于
        Sportyclub Live 作为实体 负责处理您的数据。本隐私政策仅适用于
        我们的在线活动在您满足以下条件时有效：
      </p>

      <p class="pb-24 color-666 par" v-else>
        We are Sportyclub Live, with our headquarters located at Concord Tower,
        Floor 12, Media City, Dubai UAE. References to "Sportyclub Live," "we,"
        "us," or "our" in this page pertain to Sportyclub Live as the entity
        responsible for handling your data. This Privacy Policy applies only to
        our online activities and is valid when you :
      </p>
      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li>在我们的网站上注册个人资料。</li>
        <li>访问和/或使用我们的网站。</li>
      </ul>

      <ul class="list" v-else>
        <li>Register a profile on our website.</li>
        <li>Access and/or utilize our website.</li>
      </ul>

      <br />
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        本政策不适用于线下或线下收集的任何信息。通过本网站以外的渠道。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        This policy is not applicable to any information collected offline or
        via channels other than this website.
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        本隐私政策的目的
      </p>

      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        PURPOSE OF THIS PRIVACY POLICY
      </p>

      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        本隐私政策旨在告知您Sportyclub Live如何
        当您使用/访问我们的时收集、使用和处理您的信息
        网站。阅读本隐私政策以及其他任何政策非常重要
        我们在特定场合提供的隐私政策或通知 收集或处理有关您的信息。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        This privacy policy aims to inform you about how Sportyclub Live
        collects, uses, and processes your information when you use/access our
        Website. It's essential to read this privacy policy along with any other
        privacy policies or notices we provide on specific occasions when
        collecting or processing information about you.
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        第三方链接
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        THIRD-PARTY LINKS
      </p>

      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们的平台可能包含第三方网站、插件和
        应用程序。单击这些链接或启用这些连接可能会
        允许第三方收集或共享有关您的数据。我们不
        控制这些第三方网站，并且不对其负责
        隐私声明。当您离开我们的平台时，我们强烈建议
        您阅读您访问的每个网站的隐私政策。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        Our Platforms may include links to third-party websites, plug-ins, and
        applications. Clicking on those links or enabling those connections may
        allow third parties to collect or share data about you. We do not
        control these third-party websites and are not responsible for their
        privacy statements. When you leave our Platforms, we strongly recommend
        you to read the privacy policy of every website you visit.
      </p>

      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        您的信息是如何收集的？
      </p>

      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        HOW IS YOUR INFORMATION COLLECTED?
      </p>

      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        当您与我们的服务互动时，我们可能会自动收集技术信息
        有关您的设备、浏览操作和模式的数据。我们 通过使用 cookie
        和其他类似方式收集此信息 技术。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        As you interact with our Services, we may automatically collect
        Technical Data about your device, browsing actions and patterns. We
        collect this information by using cookies and other similar
        technologies.
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们如何使用您的信息？
      </p>

      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        HOW DO WE USE YOUR INFORMATION?
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们只会根据适用的规定使用您的信息
        法律。我们主要会在以下情况下使用您的个人数据：
      </p>
      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        We will utilize your information only in accordance with applicable
        laws. Primarily, we'll use your Personal Data in these situations:
      </p>

      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li>我们需要遵守法律或监管义务的情况。</li>
        <li>
          我们向您发送电子邮件以提供我们产品的更新信息以及
          服务，以及任何条款和条件、本隐私政策或我们的 cookie 政策的更改。
        </li>
        <li>
          您出于以下目的向我们发送信息 参加促销或竞赛或者您有
          以其他方式获得您同意处理您的个人数据
        </li>
        <li>
          凡需要我们为您提供各种配套客户服务的地方
          （例如您联系我们询问有关我们的问题 平台和/或向我们请求某些信息）。
        </li>
        <li>
          直接或通过我们的合作伙伴之一与您沟通（包括客户服务），为您提供更新和其他信息
          与网站相关的信息以及用于营销和促销的信息 目的
        </li>
      </ul>

      <ul class="list" v-else>
        <li>Where we need to comply with a legal or regulatory obligation.</li>
        <li>
          Where we send you emails providing updates to our products and
          services, along with changes to any terms and conditions, this privacy
          policy or our cookie policy.
        </li>
        <li>
          Where you have sent your information to us for the purpose of
          participating in a promotion or competition or where you have
          otherwise provided your consent to process your Personal Data
        </li>
        <li>
          Where we need to provide various supporting customer services to you
          (such as where you contact us with a question in connection with our
          Platforms and/or request certain information from us).
        </li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
      </ul>
      <br />
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        饼干
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>COOKIES</p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        与任何其他网站类似，Sportyclub Live 使用“cookies”。这些
        “cookie”用于保留数据，例如访问者的偏好以及他们在网站上访问或访问的页面。这些数据有帮助
        根据访问者的浏览器类型和/或其他相关信息定制我们的网页内容，从而增强用户体验。
        <br />
        有关我们使用的 Cookie 以及如何管理您的偏好的更多信息，请参阅 Cookie
        Consent 中的<a href="">什么是 Cookie</a>。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        Similar to any other website, Sportyclub Live use 'cookies'. These
        'cookies' are employed to retain data, such as visitors’ preferences and
        the pages they accessed or visited on the website. This data helps
        enhance user experience by tailoring our web page content based on
        visitors’ browser type and/or other relevant information.
        <br />
        For more information about the cookies we use and how to manage your
        preferences, please see our <a href="">What Are Cookies</a> from Cookie
        Consent.
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        您的权利
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        YOUR'S RIGHTS
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        在某些情况下，您享有数据保护法规定的相关权利
        您的个人数据，包括以下权利：
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        Under certain circumstances, you have rights under Data Protection Laws
        in relation to your Personal Data, including the right to:
      </p>
      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li>请求访问您的个人数据。</li>
        <li>请求更正您的个人数据。</li>
        <li>请求删除您的个人数据。</li>
        <li>请求限制处理您的个人数据。</li>
        <li>请求转移您的个人数据。</li>
      </ul>
      <ul class="list" v-else>
        <li>Request access to your Personal Data.</li>
        <li>Request correction of your Personal Data.</li>
        <li>Request erasure of your Personal Data.</li>
        <li>Request restriction of processing your Personal Data.</li>
        <li>Request transfer your Personal Data.</li>
      </ul>
      <br />

      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        问题
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>QUESTIONS</p>

      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        如果您对本隐私政策有任何疑问或希望以其他方式
        行使您的权利（例如撤回您对接收信息的同意）
        营销传播），请通过邮件联系我们：info@sportyclub.live
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        If you have any questions about this privacy policy or wish to otherwise
        exercise your rights (such as to withdraw your consent from receiving
        marketing communications), please contact us by mail at:
        info@sportyclub.live
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        本隐私政策的变更
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        CHANGES TO THIS PRIVACY POLICY
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们可能会不时更改或更新本隐私政策。所有变更
        本隐私政策将发布在我们的此页面上 网站。每项更改均将在发布后生效。我们
        建议您定期重新访问并阅读本隐私政策，以确保 您了解最新的当前条款。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        We may, from time to time, change or update this privacy policy. All
        changes to this privacy policy will be published on this page of our
        website. Each change will become effective upon publication. We
        recommend that you revisit and read this privacy policy regularly to
        ensure that you are up-to-date with the current terms.
      </p>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<style scoped>
.par {
  color: black;
  font-family: "arial";
  font-size: 17px !important;
  text-align: justify;
}
.heading {
  color: black;
  margin-bottom: 15px;
  font-family: "arial";
  font-weight: bold;
  font-size: 18px !important;
}

.list {
  color: black;
  list-style-type: disc;
  font-family: "arial";
  font-size: 17px !important;
  margin-bottom: 15px;
  margin-left: 40px;
}
</style>