<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-00737b1a="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.latest-matches-of-this-league") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>

          <!-- if data is empty -->

          <div
            v-if="loading"
            class="table Matches pl-15 pr-15 pb-24"
            data-v-e39f9930=""
            data-v-54d3d41a=""
            data-v-7c947e55=""
          >
            <div class="body" data-v-0939e9fc="">
              <div
                class="row flex fs-12 color-333 cur-pointer"
                data-v-0939e9fc=""
              >
                <a
                  class="col justify-start border-box"
                  style="width: 200px; padding-left: 12px"
                  data-v-0939e9fc=""
                >
                  <img
                    src="/images/default-loader.gif"
                    style="width: 120px !important; height: 60px !important"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- if data is available -->
          <div
            :style="standingsLoader ? 'opacity:0.5' : ''"
            class="table Matches pl-15 pr-15 pb-24"
            data-v-e39f9930=""
            data-v-54d3d41a=""
            data-v-7c947e55=""
            v-else-if="!laoding && leagueMatches && leagueMatches != ''"
          >
            <div
              class="body"
              style="min-height: 0px"
              data-v-e39f9930=""
              v-for="match in paginatedMatches"
              :key="match.id"
            >
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/match/details/' +
                  match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '-vs-' +
                  match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '/' +
                  match.id
                "
                target="_blank"
              >
                <div class="row flex fs-12 color-333" data-v-e39f9930="">
                  <div
                    class="col col-15 border-box"
                    style="justify-content: flex-start; padding-left: 12px"
                    data-v-e39f9930=""
                  >
                    <b
                      style="font-size: 14px; font-weight: bold; color: brown"
                      v-if="match.status.code == 21"
                    >
                    {{ $t("football.live-score.live") }}
                      <span class="blink">'</span>
                    </b>
                    <b style="font-size: 14px" v-else>
                      {{ getTimeStampDate(match.startTimestamp) }}
                    </b>
                  </div>
                  <span
                    class="col col-30 color-p color-p"
                    style="
                      justify-content: flex-end;
                      border-radius: 10px !important;
                    "
                    data-v-e39f9930=""
                    :style="
                      match.winnerCode == 1
                        ? 'background-color: #5dbb60'
                        : match.winnerCode == 2
                        ? 'background-color: rgb(175,39,39)'
                        : 'background-color: rgb(241,241,241)'
                    "
                    ><span
                      class="w-o-h"
                      data-v-e39f9930=""
                      :style="
                        match.winnerCode == 1
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : match.winnerCode == 2
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : 'font-size: 14px !important;font-weight: bold;   color: black;'
                      "
                    >
                      {{ match.homeTeam.name }}
                    </span>
                    <img
                      style="
                        width: 30px !important;
                        height: 30px !important;
                        margin-right: 15px;
                      "
                      :src="
                        'https://sportyclub.online//images/cricket/teams/' +
                        match.homeTeam.id +
                        '/' +
                        match.homeTeam.id +
                        '.png'
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="team-logo ml-12"
                      data-v-e39f9930=""
                    />
                  </span>

                  <span class="col col-30" data-v-e39f9930="">
                    <h3 v-if="match.winnerCode == 1 || match.winnerCode == 2">
                      <span
                        v-if="Object.keys(match.homeScore.innings).length > 1"
                      >
                        <span v-for="inn in match.homeScore.innings" :key="inn">
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ") "
                          }}
                        </span>
                        <br />
                      </span>
                      <span v-else>
                        <span v-for="inn in match.homeScore.innings" :key="inn">
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ") - "
                          }}
                        </span>
                      </span>
                      <span v-for="inn in match.awayScore.innings" :key="inn">
                        {{
                          inn.score +
                          "/" +
                          inn.wickets +
                          " (" +
                          inn.overs +
                          ") "
                        }}
                      </span>
                    </h3>

                    <span v-else>
                      <h3
                        style="
                          background-color: rgb(175, 39, 39);
                          color: white;
                          padding: 6px;
                          border-radius: 7px;
                          font-size: 13px;
                          /* font-weight: bold; */
                        "
                      >
                        {{ match.status.description }}
                      </h3>
                    </span>
                    &nbsp;
                  </span>
                  <span
                    style="border-radius: 10px !important"
                    class="col col-30 color-p color-p justify-start"
                    data-v-e39f9930=""
                    :style="
                      match.winnerCode == 2
                        ? 'background-color: #5dbb60'
                        : match.winnerCode == 1
                        ? 'background-color: rgb(175,39,39)'
                        : 'background-color: rgb(241,241,241)'
                    "
                  >
                    <img
                      style="
                        width: 30px !important;
                        height: 30px !important;
                        margin-left: 15px !important;
                      "
                      :src="
                        'https://sportyclub.online//images/cricket/teams/' +
                        match.awayTeam.id +
                        '/' +
                        match.awayTeam.id +
                        '.png'
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="team-logo mr-12"
                      data-v-e39f9930=""
                    />
                    <span
                      itemprop="awayTeam"
                      class="w-o-h"
                      data-v-e39f9930=""
                      style="font-size: 14px !important"
                      :style="
                        match.winnerCode == 2
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : match.winnerCode == 1
                          ? 'font-size: 14px !important;font-weight: bold;   color: white;'
                          : 'font-size: 14px !important;font-weight: bold;   color: black;'
                      "
                    >
                      {{ match.awayTeam.name }}
                    </span></span
                  >
                </div>
              </a>
              <br />
            </div>
            <!-- begin::pagination -->
            <div class="text-center mt-24">
              <div class="el-pagination">
                <button
                  type="button"
                  class="btn-prev"
                  @click="prevPage"
                  :disabled="currentPage === 1"
                >
                  <i class="el-icon el-icon-arrow-left"></i>
                </button>
                <ul class="el-pager">
                  <li
                    class="number"
                    v-for="page in totalPages"
                    :key="page"
                    :class="{ active: page === currentPage }"
                    @click="changePage(page)"
                  >
                    {{ page }}
                  </li>
                </ul>
                <button
                  type="button"
                  class="btn-next"
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  <i class="el-icon el-icon-arrow-right"></i>
                </button>
              </div>
            </div>
            <!-- end::pagination -->
          </div>

          <!-- if no data -->
          <div
            class="table Matches pl-15 pr-15 pb-24"
            data-v-e39f9930=""
            data-v-54d3d41a=""
            data-v-7c947e55=""
            v-else
          >
            <div class="border-box" data-v-51bacf47="">
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <div
                  data-v-1eddfbef=""
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      <!-- begin::rightsidebar -->
      <div class="p-r" data-v-29b39e42="">
        <div
          class="container-box mb-12 pb-24"
          data-v-7c947e55=""
          data-v-29b39e42=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span
              class="font-500 w-o-h"
              data-v-7c947e55=""
              style="font-weight: 1000"
            >
              {{
                !featureLoading &&
                matchInfo &&
                matchInfo.tournament &&
                matchInfo.tournament &&
                matchInfo.tournament.name
                  ? matchInfo.tournament.name
                  : "Featured Match"
              }}
            </span>
          </div>

          <div
            class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
            data-v-bb874c34=""
            data-v-29b39e42=""
            data-v-7c947e55=""
            v-if="featureLoading"
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <img
                src="/images/default-loader.gif"
                style="
                  width: 120px !important;
                  height: 60px !important;
                  margin-left: -50px;
                "
              />
            </div>
          </div>
          <div
            class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
            data-v-bb874c34=""
            data-v-29b39e42=""
            data-v-7c947e55=""
            v-if="!featureLoading"
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/team/details/' +
                  matchInfo.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '/' +
                  matchInfo.homeTeam.id
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
              >
                <img
                  :src="
                    matchInfo.homeTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        matchInfo.homeTeam.id +
                        '/' +
                        matchInfo.homeTeam.id +
                        '.png'
                      : bannerImagePath('default-team.png')
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                >
                  {{ matchInfo.homeTeam.name }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                  v-if="
                    matchInfo &&
                    matchInfo.homeScore &&
                    matchInfo.homeScore.innings
                  "
                >
                  <span v-for="inn in matchInfo.homeScore.innings" :key="inn">
                    {{ inn.score + "/" + inn.wickets + "(" + inn.overs + ")" }}
                  </span>
                </span>
              </a>
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/cricket/team/details/' +
                  matchInfo.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                  '/' +
                  matchInfo.awayTeam.id
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
              >
                <img
                  :src="
                    matchInfo.awayTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        matchInfo.awayTeam.id +
                        '/' +
                        matchInfo.awayTeam.id +
                        '.png'
                      : bannerImagePath('default-team.png')
                  "
                  @error="
                    $event.target.src = bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                  >{{ matchInfo.awayTeam.name }}</span
                >
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                  v-if="
                    matchInfo &&
                    matchInfo.awayScore &&
                    matchInfo.awayScore.innings
                  "
                >
                  <span v-for="inn in matchInfo.awayScore.innings" :key="inn">
                    {{ inn.score + "/" + inn.wickets + "(" + inn.overs + ")" }}
                  </span>
                </span>
              </a>
            </div>

            <div class="container-box" data-v-bb874c34="">
              <div
                class="font-500 mb-xs"
                data-v-bb874c34=""
                v-if="matchInfo && matchInfo.note"
              >
                <br />
                <center>
                  <span
                    style="
                      background-color: rgb(175, 39, 39);
                      color: white;
                      padding: 6px;
                      border-radius: 7px;
                      font-size: 12px;
                      font-weight: bold;
                      display: inline-block;
                      width: 100%;
                      overflow: hidden;
                    "
                  >
                    <b
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        width: 100%;
                      "
                      >{{ matchInfo.note }}</b
                    >
                  </span>
                </center>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.date") }}</div>
                <div data-v-bb874c34="">
                  {{ getTimeStampDate(matchInfo.startTimestamp) }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.time") }}</div>
                <div data-v-bb874c34="">
                  {{ formatTimestampWithAMPM(matchInfo.startTimestamp) }}
                </div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.toss-win") }}</div>
                <div data-v-bb874c34="">
                  {{ matchInfo && matchInfo.tossWin ? matchInfo.tossWin : "-" }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.toss-decision") }}</div>
                <div data-v-bb874c34="">
                  {{
                    matchInfo && matchInfo.tossDecision
                      ? matchInfo.tossDecision
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.stadium") }}</div>
                <div data-v-bb874c34="">
                  {{
                    matchInfo &&
                    matchInfo.venue &&
                    matchInfo.venue.stadium &&
                    matchInfo.venue.stadium.name
                      ? matchInfo.venue.stadium.name
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.venue") }}</div>
                <div data-v-bb874c34="">
                  {{
                    matchInfo &&
                    matchInfo.venue &&
                    matchInfo.venue.city &&
                    matchInfo.venue.city.name
                      ? matchInfo.venue.city.name
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34=""> {{ $t("football.league-details.country") }}</div>
                <div data-v-bb874c34="">
                  {{
                    matchInfo &&
                    matchInfo.venue &&
                    matchInfo.venue.country &&
                    matchInfo.venue.country.name
                      ? matchInfo.venue.country.name
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::rightsidebar -->
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Number,
      default: null,
    },
    seasonId: {
      type: Number,
      default: null,
    },
    leagueInfo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      leagueMatches: [], // Your array to store fetched matches
      matchInfo: null,
      featureLoading: true,
      previousLeagueId: null,
      previousSeasonId: null,
      standingsLoader: true,
      currentPage: 1,
      matchesPerPage: 10,
    };
  },
  computed: {
    paginatedMatches() {
      const startIndex = (this.currentPage - 1) * this.matchesPerPage;
      const endIndex = startIndex + this.matchesPerPage;
      return this.leagueMatches.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.leagueMatches.length / this.matchesPerPage);
    },
  },
  updated() {
    // Check if leagueId and seasonId have changed before calling getLeagueStandings
    if (
      this.leagueId !== this.previousLeagueId ||
      this.seasonId !== this.previousSeasonId
    ) {
      this.getleagueMatches(this.leagueId, this.seasonId);

      this.previousLeagueId = this.leagueId;
      this.previousSeasonId = this.seasonId;
    }
  },

  created() {
    this.getleagueMatches(this.leagueId, this.seasonId);
    this.getFeaturedMatch(this.leagueId);
  },

  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    // method to get previous matches of season
    getleagueMatches(leagueId, seasonId) {
      // Set loading to true before making the API call
      this.standingsLoader = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/previous-matches/${leagueId}/${seasonId}`
        )
        .then((response) => {
          if (response.data.events && response.data.events.length > 0) {
            this.leagueMatches = response.data.events.reverse();
            // Set loading to false only if leagueMatches have data
          }
          this.loading = false;
          this.standingsLoader = false;
        })
        .catch((error) => {
          console.log(error);
          // Ensure loading is set to false in case of error
          this.loading = false;
        });
    },

    // method to get featured match
    getFeaturedMatch(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/featured/match/${leagueId}`
        )
        .then((response) => {
          this.matchInfo = response.data.featuredEvents[0];
          this.featureLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to convert timestamp to readable time
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();

      // Format the date
      return month + " " + day + ", " + year;
    },

    // method to convert timeStamp
    formatTimestampWithAMPM(timestamp) {
      const gmtOffset = 4 * 60 * 60 * 1000; // GMT+4 offset in milliseconds
      const adjustedTimestamp = timestamp * 1000 + gmtOffset; // Convert seconds to milliseconds and add GMT+4 offset
      const date = new Date(adjustedTimestamp);
      let hours = date.getUTCHours(); // Get UTC hours
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert hours to 12-hour format
      const formattedHours = hours < 10 ? "0" + hours : hours; // Add leading zero if hours < 10
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    },
  },
};
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}
.ellipsis-container {
  display: flex;
}

.ellipsis-content {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.ellipsis-container:not(:only-child) .ellipsis-content {
  margin-top: 15px;
}
</style>
