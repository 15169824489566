<template>
  <div
    class="moreOption"
    style="bottom: 2.45rem; height: calc(-2.45rem + 100vh); z-index: 2020"
    data-v-736e8afd=""
  >
    <i class="iconfont icon-guanbi" data-v-736e8afd="" @click="close"></i>
    <p class="title flex align-center" data-v-736e8afd="">
      <i
        class="iconfont icon-zuojiantou"
        :style="{ display: languageLevelTwoStatus ? 'block' : 'none' }"
        data-v-736e8afd=""
      ></i>
      {{
        languageLevelTwoStatus
          ? $t("common.menu.language")
          : $t("common.menu.setting")
      }}
    </p>
    <ul class="optionBox" data-v-736e8afd="">
      <li
        v-if="!languageLevelTwoStatus"
        class="flex align-center"
        data-v-736e8afd=""
        @click="toggleLanguageLevelTwo"
      >
        <i
          class="iconfont icon-Mzhan-shezhi-qiehuanyuyan"
          data-v-736e8afd=""
        ></i>
        <span class="name" data-v-736e8afd="">
          {{ $t("common.menu.language") }}
        </span>

        <i class="iconfont icon-youjiantou" data-v-736e8afd=""></i>
      </li>
    </ul>
    <ul
      v-if="languageLevelTwoStatus"
      class="optionBox level2Box"
      data-v-736e8afd=""
    >
      <li
        class="flex align-center"
        data-v-736e8afd=""
        @click="onSwitchLang('en')"
      >
        <span data-v-736e8afd="">
          English
          <i
            v-if="this.$i18n.locale === 'en'"
            data-v-736e8afd=""
            class="iconfont icon-xuanzhong1"
          ></i
        ></span>
      </li>
      <li
        class="flex align-center"
        data-v-736e8afd=""
        @click="onSwitchLang('cn')"
      >
        <span data-v-736e8afd="">
          中国人
          <i
            v-if="this.$i18n.locale === 'cn'"
            data-v-736e8afd=""
            class="iconfont icon-xuanzhong1"
          ></i
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SettingsDialogMobile",
  beforeMount() {
    // this.lang = this.$i18n.locale;
  },
  data() {
    return {
      // lang: "en",
      languageLevelTwoStatus: false,
    };
  },

  methods: {
    toggleLanguageLevelTwo() {
      this.languageLevelTwoStatus = !this.languageLevelTwoStatus;
    },
    close() {
      this.$emit("closeDialog");
    },
    onSwitchLang(lang) {
      // console.log("Selected language:", lang);

      // Update Vue I18n locale
      this.$i18n.locale = lang;

      // Close the dialog
      this.toggleLanguageLevelTwo();

      // Update URL based on the selected language
      let currentPath = window.location.pathname;
      let newPath = currentPath.replace(/^\/(en|cn)\//, "/" + lang + "/");
      window.history.replaceState({}, "", newPath);

      // You can perform additional actions here if needed
    },
  },
};
</script>


