<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-else>
    <div class="innerMatchInfo detail view border-box" data-v-250f1619="">
      <div class="header" data-v-54d9a5ac="">
        <div class="van-sticky">
          <header
            class="first-header"
            style="background: #825020"
            data-v-54d9a5ac=""
          >
            <div
              class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
              data-v-54d9a5ac=""
            >
              <div
                @click="() => this.$router.back()"
                class="left van-col van-col--4"
                data-v-54d9a5ac=""
              >
                <span
                  class="icon iconfont myIcon iconback"
                  style="opacity: 1"
                  data-v-54d9a5ac=""
                ></span>
              </div>
              <div
                class="proMod2 flex align-center justify-center van-col"
                style="
                  font-size: 0.27rem;
                  opacity: 0.6;
                  white-space: normal;
                  line-height: unset;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                data-v-54d9a5ac=""
              >
                <span class="formatClass" style="opacity: 1" data-v-54d9a5ac=""
                  ><span data-v-54d9a5ac=""
                    ><span data-v-54d9a5ac="">
                      {{
                        this.$i18n.locale == "cn" &&
                        todayMatchDetails.leagueInfo &&
                        todayMatchDetails.leagueInfo.cn_name
                          ? todayMatchDetails.leagueInfo.cn_name +
                            " " +
                            $t("football.league-details.league") +
                            " "
                          : todayMatchDetails.leagueInfo.en_name
                          ? todayMatchDetails.leagueInfo.en_name +
                            " " +
                            $t("football.league-details.league") +
                            " "
                          : ""
                      }}
                    </span>
                    <span data-v-54d9a5ac="">
                      {{ getFormattedDate(todayMatchDetails.match_time) }}</span
                    ></span
                  ></span
                >
              </div>
              <div class="right van-col van-col--4" data-v-54d9a5ac=""></div>
            </div>
          </header>
        </div>
      </div>
      <div class="matchTop" style="height: 3.92rem; background: #825020">
        <div class="matchInfo" data-v-ef85c778="">
          <!-- begin::home Info -->
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/baseball/team/details/' +
              todayMatchDetails.homeInfo.en_name.replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            class="teamLeft"
            data-v-ef85c778=""
          >
            <div
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.logo
                    ? todayMatchDetails.homeInfo.logo + '!w100'
                    : bannerImagePath('default-team.png')
                "
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.cn_name
                  ? todayMatchDetails.homeInfo.cn_name
                  : todayMatchDetails.homeInfo.en_name
                  ? todayMatchDetails.homeInfo.en_name
                  : ""
              }}
            </div>
            <img
              v-if="
                bottomTeamInningsStatuses.findIndex(
                  (status) => status == todayMatchDetails.status_id
                ) != -1
              "
              src="/images/baseball-bat.png"
              alt="#"
              class="svgPostop ballIconSize2"
            />
          </a>
          <!-- end::home Info -->

          <!-- begin::score -->
          <div
            v-if="this.notStartedStatus.includes(todayMatchDetails.status_id)"
            class="matchScore1"
          >
            <div class="status1Box"><div class="vs">vs</div></div>
          </div>

          <div v-else class="matchScore">
            <span class="color-f timeScore">{{
              this.FinishedMatches.includes(todayMatchDetails.status_id)
                ? this.$i18n.t("football.league-details.full-time")
                : todayMatchDetails?.statusDescriptionShort
            }}</span>

            <div class="score flex justify-center">
              <div class="flex justify-center">
                <span class="margin01">{{
                  todayMatchDetails.scores &&
                  todayMatchDetails.scores.ft &&
                  todayMatchDetails.scores.ft[0]
                    ? todayMatchDetails.scores.ft[0]
                    : ""
                }}</span>
                <span>-</span>
                <span class="margin01">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores.ft &&
                    todayMatchDetails.scores.ft[1]
                      ? todayMatchDetails.scores.ft[1]
                      : ""
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::score -->
          <!-- begin::away info-->
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/baseball/team/details/' +
              todayMatchDetails.awayInfo.en_name.replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            class="teamRight"
          >
            <div
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.logo
                    ? todayMatchDetails.awayInfo.logo + '!w100'
                    : bannerImagePath('default-team.png')
                "
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.cn_name
                  ? todayMatchDetails.awayInfo.cn_name
                  : todayMatchDetails.awayInfo.en_name
                  ? todayMatchDetails.awayInfo.en_name
                  : ""
              }}
            </div>
            <img
              v-if="
                topTeamInningsStatuses.findIndex(
                  (status) => status == todayMatchDetails.status_id
                ) != -1
              "
              src="/images/baseball-bat.png"
              alt="#"
              class="svgPostop ballIconSize2"
            />
          </a>
          <!-- end::away info -->
        </div>
      </div>
      <!-- begin::tabs -->
      <div class="van-sticky">
        <div class="van-tabs van-tabs--line" data-v-ef85c778="">
          <div
            class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
          >
            <div
              role="tablist"
              class="van-tabs__nav van-tabs__nav--line"
              style="
                border-color: rgb(15, 128, 218);
                background: rgb(255, 255, 255);
              "
            >
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('overview')"
                :style="status == 'overview' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.overview") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('odds')"
                :style="status == 'odds' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.odds") }}
                </span>
              </div>
              <!-- <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('lineups')"
                :style="status == 'lineups' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.lineups") }}
                </span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
      <div
        class="breadcrumb-box iconfont"
        style="background-color: #fff"
        data-v-e49cc106=""
      >
        <div class="display-i-l" data-v-e49cc106="">
          <a target="" class="ele link" data-v-e49cc106=""
            ><span data-v-e49cc106="">
              {{ $t("baseball.league-details.baseball-live-score") }}
            </span>
          </a>
        </div>
        <span class="jt" data-v-e49cc106=""></span>
        <span class="ele text" style="display: inline" data-v-e49cc106="">
          <span data-v-e49cc106="">
            <a
              target="_blank"
              style="color: var(--main-text-color)"
              :href="
                '/' +
                this.$i18n.locale +
                '/baseball/league/details/' +
                todayMatchDetails.leagueInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.unique_tournament_id
              "
            >
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.leagueInfo &&
                todayMatchDetails.leagueInfo.cn_name
                  ? todayMatchDetails.leagueInfo.cn_name
                  : todayMatchDetails.leagueInfo.en_name
                  ? todayMatchDetails.leagueInfo.en_name
                  : ""
              }}
            </a>
          </span>
        </span>
        <span class="jt" data-v-e49cc106=""></span>

        <h1
          class="ele text lastText"
          style="display: inline"
          data-v-e49cc106=""
        >
          <span data-v-e49cc106="">
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/baseball/team/details/' +
                todayMatchDetails.homeInfo.en_name.replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.home_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.cn_name
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                    ? todayMatchDetails.homeInfo.en_name
                    : ""
                }}
              </b>
            </a>

            vs
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/baseball/team/details/' +
                todayMatchDetails.awayInfo.en_name.replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.away_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.cn_name
                    ? todayMatchDetails.awayInfo.cn_name
                    : todayMatchDetails.awayInfo.en_name
                    ? todayMatchDetails.awayInfo.en_name
                    : ""
                }}
              </b>
            </a>
          </span>
        </h1>
      </div>

      <MatchOverview
        v-if="status == 'overview'"
        :matchId="matchId"
        :todayMatchDetails="todayMatchDetails"
      />
      <!-- begin::squad -->
      <!-- <span v-if="status == 'lineups'">
      <MatchLineups :matchId="matchId" />
    </span> -->
      <!-- end::squad -->
      <!-- begin::squad -->
      <!-- <span v-if="status == 'h2h'">
      <MatchH2H :matchId="matchId" />
    </span> -->
      <!-- end::squad -->
      <!-- begin::squad -->
      <MatchOdds :matchId="matchId" v-if="status == 'odds'" />
      <!-- end::squad -->
    </div>
  </span>
  <MobileFooter />
</template>
<script>
import MatchOverview from "./tabs/match-overview.vue";
import MatchOdds from "./tabs/match-odds.vue";
import DateMixin from "@/utils/dateUtils";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DateMixin, metaDataUtils, DynamicImagePath],
  components: {
    MatchOverview,
    MatchOdds,
  },

  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [],

      topTeamInningsStatuses: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      ],
      bottomTeamInningsStatuses: [
        433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      ],
      FinishedMatches: [100],
      notStartedStatus: [1],
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.matchId = itemId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleTabs(status) {
      return (this.status = status);
    },
    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      if (status_code == 3) {
        return "HT";
      }
      return minutes;
    },
  },
};
</script>
