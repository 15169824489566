<template>
  <div
    class="teamsBox view border-box"
    data-v-db02cb7a=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div
      class="tourHeader flex justify-center align-center flex-col"
      data-v-db02cb7a=""
    >
      <i class="iconfont iconback" data-v-db02cb7a="" @click="goBack"></i>
      <img
        :src="
          teamDetails && teamDetails.logo
            ? teamDetails.logo
            : bannerImagePath('default-nation.png')
        "
        :alt="
          this.$i18n.locale == 'cn' && teamDetails && teamDetails.cn_name
            ? teamDetails.cn_name
            : teamDetails.name
            ? teamDetails.name
            : ''
        "
        class="teamImg"
        data-v-db02cb7a=""
      />
      <p class="teamName" data-v-db02cb7a="">
        {{
          this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
            ? teamDetails.cn_name
            : teamDetails.name
            ? teamDetails.name
            : ""
        }}
      </p>
    </div>
    <div class="tab flex" data-v-db02cb7a="">
      <a
        target="_self"
        class="flex justify-center align-center active"
        data-v-db02cb7a=""
        @click="toggleTab('overview')"
      >
        {{ $t("football.league-details.overview") }}
        <span v-if="activeTab === 'overview'" data-v-db02cb7a=""></span
      ></a>

      <!-- <a
        target="_self"
        class="flex justify-center align-center active"
        data-v-db02cb7a=""
        @click="toggleTab('squad')"
      >
        {{ $t("football.league-details.squad") }}
        <span v-if="activeTab === 'squad'" data-v-db02cb7a=""></span
      ></a> -->
    </div>
    <div
      itemscope="itemscope"
      itemtype="http://schema.org/BreadcrumbList"
      class="breadcrumb-box iconfont"
      style="background-color: transparent"
      data-v-e49cc106=""
      data-v-db02cb7a=""
    >
      <div
        itemprop="itemListElement"
        itemscope="itemscope"
        itemtype="http://schema.org/ListItem"
        class="display-i-l"
        data-v-e49cc106=""
      >
        <a
          itemprop="item"
          :href="'/' + this.$i18n.locale + '/live/table_tennis'"
          target=""
          class="ele link"
          data-v-e49cc106=""
          ><span itemprop="name" data-v-e49cc106="">
            {{ $t("table_tennis.league-details.table_tennis-live-score") }}
          </span>
        </a>
      </div>
      <span class="jt" data-v-e49cc106=""></span>

      <h1
        itemprop="itemListElement"
        itemscope="itemscope"
        itemtype="http://schema.org/ListItem"
        class="ele text lastText"
        style="display: inline"
        data-v-e49cc106=""
      >
        <span itemprop="name" data-v-e49cc106=""
          >{{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
              ? teamDetails.name
              : ""
          }}
          {{ $t("football.league-details.schedule") }},
          {{ $t("football.league-details.standings") }}
        </span>
      </h1>
    </div>
    <!-- end of breadcrumb -->

    <div v-if="activeTab === 'overview'" class="scheduleBox" data-v-db02cb7a="">
      <div
        class="flex justify-center align-center flex-col"
        style="height: 400px"
        data-v-4291ef99=""
      >
        <img
          :src="bannerImagePath('no-data.png')"
          style="width: 123px; height: 74px"
        />
        <div class="color-999 fs-12 mt-12">
          {{ $t("football.live-score.temporarily-no-data") }}
        </div>
      </div>
    </div>

    <!-- end standings -->

    <MobileFooter />
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],

  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
      activeTab: "overview",
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getMetaData(itemId);
  },

  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/table_tennis/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/table_tennis/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          //   this.getTeamSquad(teamId);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
