<template>
  <div v-if="loading" class="detail view border-box" data-v-250f1619="">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div v-else-if="!loading && !leagueStandings">
    <div class="standingBox" data-v-6d73ae82="">
      <div class="title flex align-center" data-v-6d73ae82="">
        <span data-v-6d73ae82="">
          {{
            leagueDetails && this.$i18n.locale == "cn" && leagueDetails.cn_name
              ? leagueDetails.cn_name +
                " " +
                $t("football.league-details.standings")
              : leagueDetails.name
              ? leagueDetails.name +
                " " +
                $t("football.league-details.standings")
              : ""
          }}
        </span>
      </div>
      <div data-v-6d73ae82="">
        <div style="display: " data-v-6d73ae82="">
   
          <div class="tableHeader flex" data-v-6d73ae82="">
            <span
              class="flex justify-center align-center w-52"
              data-v-6d73ae82=""
              >#
            </span>
            <span class="flex align-center w-120" data-v-6d73ae82=""
              >{{ $t("football.league-details.team") }}
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              P
            </span>
            <span
              class="flex justify-center align-center flex-1 w"
              data-v-6d73ae82=""
            >
              W
            </span>

            <span
              class="flex justify-center align-center flex-1 l"
              data-v-6d73ae82=""
            >
              L
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              {{ $t("football.league-details.goals") }}
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              OT
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              PTS
            </span>
          </div>
          <ul class="tableContent" data-v-6d73ae82="">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 400px"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="standingBox" data-v-6d73ae82="">
      <div class="title flex align-center" data-v-6d73ae82="">
        <span data-v-6d73ae82="">
          {{
            leagueDetails && this.$i18n.locale == "cn" && leagueDetails.cn_name
              ? leagueDetails.cn_name +
                " " +
                $t("football.league-details.standings")
              : leagueDetails.name
              ? leagueDetails.name +
                " " +
                $t("football.league-details.standings")
              : ""
          }}
        </span>
      </div>
      <div
        data-v-6d73ae82=""
        v-for="(standingDetail, standingName) in leagueStandings"
        :key="standingDetail.id"
      >
        <div style="display: " data-v-6d73ae82="">
          <div class="title title2 flex align-center" data-v-6d73ae82="">
            {{ standingName + " " + $t("football.league-details.standings") }}
          </div>
          <div class="tableHeader flex" data-v-6d73ae82="">
            <span
              class="flex justify-center align-center w-52"
              data-v-6d73ae82=""
              >#
            </span>
            <span class="flex align-center w-120" data-v-6d73ae82=""
              >{{ $t("football.league-details.team") }}
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              P
            </span>
            <span
              class="flex justify-center align-center flex-1 w"
              data-v-6d73ae82=""
            >
              W
            </span>

            <span
              class="flex justify-center align-center flex-1 l"
              data-v-6d73ae82=""
            >
              L
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              OT
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              {{ $t("football.league-details.goals") }}
            </span>
            <span
              class="flex justify-center align-center flex-1"
              data-v-6d73ae82=""
            >
              PTS
            </span>
          </div>
          <ul
            v-for="(rowDetails, index) in standingDetail.rows"
            :key="index"
            class="tableContent"
            data-v-6d73ae82=""
          >
            <template v-if="rowDetails.teamInfo">
              <li class="flex" data-v-6d73ae82="">
                <div
                  class="flex justify-center align-center w-52"
                  data-v-6d73ae82=""
                >
                  {{ index + 1 }}
                </div>
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/hockey/team/details/' +
                    rowDetails.teamInfo.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    rowDetails.team_id
                  "
                  target="_self"
                  class="flex align-center w-120 teamData"
                  data-v-6d73ae82=""
                  ><img
                    :src="
                      rowDetails.teamInfo && rowDetails.teamInfo.logo
                        ? rowDetails.teamInfo.logo + '!w30'
                      : bannerImagePath('default-team.png')
                    "
                    alt=""
                    data-v-6d73ae82=""
                  />
                  <span class="bh" data-v-6d73ae82="">{{
                    rowDetails?.teamInfo?.name
                  }}</span></a
                >
                <div
                  class="flex justify-center align-center flex-1"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.total }}
                </div>
                <div
                  class="flex justify-center align-center flex-1 w"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.win }}
                </div>

                <div
                  class="flex justify-center align-center flex-1 l"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.loss }}
                </div>
                <!-- <div
                class="flex justify-center align-center flex-1"
                data-v-6d73ae82=""
              >
                -
              </div> -->
                <div
                  class="flex justify-center align-center flex-1"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.overtime_win }}:{{ rowDetails?.overtime_loss }}
                </div>
                <div
                  class="flex justify-center align-center flex-1"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.goals }}:{{ rowDetails?.goals_against }}
                </div>
                <div
                  class="flex justify-center align-center flex-1"
                  data-v-6d73ae82=""
                >
                  {{ rowDetails?.points }}
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
    leagueDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      topPlayers: "goals",
      leagueStandings: null,
      loading: true,
      // leagueTeamsStats: null,
      // playersPropertyStats: null,
    };
  },
  created() {
    this.getLeagueStandings(this.leagueId);

    // this.getPlayerStats(this.leagueId, this.topPlayers);
    // this.getLeagueTeamStats(this.leagueId);
  },
  methods: {
    // method to toggle dropdown
    // toggleTopPlayers(status) {
    //   this.topPlayers = status;
    //   this.getPlayerStats(this.leagueId, this.topPlayers);
    // },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/hockey/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data.standings;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getPlayerStats(leagueId, property) {
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/football/league/season/playersStats/${leagueId}?playerProperty=${property}`
    //     )
    //     .then((response) => {
    //       this.playersPropertyStats = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // // method to get team stats
    // getLeagueTeamStats(leagueId) {
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/football/league/season/teamsStats/${leagueId}`
    //     )
    //     .then((response) => {
    //       this.leagueTeamsStats = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>
