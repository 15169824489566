<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="view border-box" data-v-46436e01="" v-if="!loading">
    <!-- begin::broadcumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: black; padding-left: 0"
    >
      <span class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">
          <a href="/" target="_blank" style="cursor: pointer">
            <h1 class="liveScoreh1" v-if="this.$i18n.locale == 'cn'">
              {{ $t("cricket.live-score.aiScore-cricket-live-score") }}
            </h1>
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </span>
    </div>
    <!-- end::broudcumb -->
    <div
      class="index-view flex justify-between basketball theme_init"
      data-v-46436e01=""
    >
      <div data-v-46436e01="">
        <div
          class="index-r"
          style="width: 892px; margin-left: -30px"
          data-v-46436e01=""
        >
          <div class="match-score" data-v-46436e01=""></div>
          <div
            data-v-46436e01=""
            class="index-tab flex text-center justify-between"
            style="box-sizing: content-box"
          >
            <!-- begin::tabs -->
            <div class="changTabBox" data-v-d4c6fef0="">
              <span
                :class="
                  liveActivebtn == 'live' ? 'activeElsTab' : 'notActiveTab'
                "
                class="changeItem bh ml-12"
                data-v-d4c6fef0=""
                ><span
                  class="liveContentBox"
                  data-v-d4c6fef0=""
                  v-on:click="getTodayMatchList('live')"
                  ><i
                    class="iconfont icon-jinhangzhong liveImg"
                    data-v-d4c6fef0=""
                  ></i>
                  {{ $t("football.live-score.live") }}

                  <span data-v-d4c6fef0="" class="total"
                    >{{ countLiveMatches != 0 ? countLiveMatches : "" }}
                  </span>
                </span></span
              >
              <span
                v-on:click="getTodayMatchList('finished')"
                class="changeItem bh ml-12 notActiveTab"
                data-v-d4c6fef0=""
                :class="
                  liveActivebtn == 'finished' ? 'activeElsTab' : 'notActiveTab'
                "
              >
                {{ $t("football.live-score.finished") }}
              </span>
              <span
                v-on:click="getTodayMatchList('soon')"
                class="changeItem bh ml-12 notActiveTab"
                data-v-d4c6fef0=""
                :class="
                  liveActivebtn == 'soon' ? 'activeElsTab' : 'notActiveTab'
                "
              >
                {{ $t("football.live-score.soon") }}
              </span>
              <a
                :href="'/' + this.$i18n.locale + '/cricket/news/list'"
                class="changeItem bh ml-12 notActiveTab"
                data-v-d4c6fef0=""
              >
                {{ $t("football.league-details.cricket-news") }}
              </a>
            </div>

            <!-- end::tabs -->
          </div>
          <SearchMatches
            v-if="searchListUpcoming || searchListLive"
            :searchMatchListUpcoming="searchListUpcoming"
            :searchMatchListLive="searchListLive"
          />
          <template v-if="!searchListUpcoming && !searchListLive">
            <!-- begin::live matches -->
            <div
              data-v-46436e01=""
              class="vue-recycle-scroller scroller ready page-mode direction-vertical"
            >
              <div class="vue-recycle-scroller__item-wrapper">
                <!-- if no live match -->
                <div
                  class="flex justify-center align-center flex-col"
                  v-if="countLiveMatches == 0 || countLiveMatches == ''"
                >
                  <img :src="bannerImagePath('default-container.png')" />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.league-details.no-live-match") }}
                  </div>
                  <br />
                </div>
                <div
                  class="vue-recycle-scroller__item-view"
                  v-if="
                    liveMatchList && (matchList === '' || matchList == null)
                  "
                >
                  <div data-v-46436e01="" class="user">
                    <div
                      data-v-46436e01=""
                      class="flex align-center"
                      v-for="(league, leagueName) in liveMatchList"
                      :key="leagueName"
                    >
                      <div
                        data-v-89734b7c=""
                        data-v-46436e01=""
                        style="width: 100%"
                      >
                        <div data-v-89734b7c="" class="list_title">
                          <div
                            data-v-89734b7c=""
                            class="flex align-center topLeftBox"
                          >
                            <i
                              class="iconfont collectImg"
                              style="visibility: visible"
                            ></i>

                            <!-- Render the image if it loads successfully -->
                            <img
                              style="
                                height: 33px !important;
                                width: 38px !important;
                              "
                              data-v-89734b7c=""
                              class="countryLogo"
                              :src="
                                'https://sportyclub.online//images/cricket/leagues/' +
                                league[0].tournament.uniqueTournament.id +
                                '/' +
                                league[0].tournament.uniqueTournament.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                            />

                            <a
                              :href="
                                '/' +
                                this.$i18n.locale +
                                '/cricket/league/details/' +
                                league[0].tournament.uniqueTournament.name
                                  .toLowerCase()
                                  .replace(/\s/g, '-') +
                                '/' +
                                league[0].tournament.uniqueTournament.id
                              "
                              data-v-2eea2747=""
                              target="_blank"
                              class="compText"
                            >
                              {{ leagueName }}
                            </a>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          v-for="match in league"
                          :key="match.id"
                        >
                          <div
                            data-v-89734b7c=""
                            class="list newList"
                            style="height: 64px"
                          >
                            <div data-v-89734b7c="" class="list">
                              <div
                                data-v-89734b7c=""
                                class="flex align-center justify-center collectImgBox"
                              ></div>

                              <a
                                :href="
                                  '/' +
                                  this.$i18n.locale +
                                  '/cricket/match/details/' +
                                  match.homeTeam.name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '-vs-' +
                                  match.awayTeam.name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '/' +
                                  match.id
                                "
                                data-v-89734b7c=""
                                target="_blank"
                                class="flex flex-1"
                                style="height: 100%"
                              >
                                <div
                                  data-v-89734b7c=""
                                  class="flex align-center listBox justify-around"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    class="fs-13 matchTime"
                                    style="color: rgb(153, 153, 153)"
                                  >
                                    {{
                                      formatUnixTimestamp(match.startTimestamp)
                                    }}
                                  </span>
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-col fs-13 align-center color-r text-center barItem"
                                  >
                                    {{ match.status.description }}
                                  </div>
                                </div>
                                <div
                                  data-v-89734b7c=""
                                  class="team flex flex-col padding5"
                                >
                                  <!-- begin::home team -->
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      class="teamLogoBox"
                                    >
                                      <img
                                        :src="
                                          'https://sportyclub.online//images/cricket/teams/' +
                                          match.homeTeam.id +
                                          '/' +
                                          match.homeTeam.id +
                                          '.png'
                                        "
                                        @error="
                                          $event.target.src =
                                            bannerImagePath('default-team.png')
                                        "
                                      />
                                    </span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      {{ match.homeTeam.name }} ({{
                                        match.homeTeam.shortName
                                      }})
                                    </div>

                                    <span> </span>
                                  </div>
                                  <!-- end::home team -->
                                  <!-- begin::away team -->
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      class="teamLogoBox"
                                    >
                                      <img
                                        :src="
                                          'https://sportyclub.online//images/cricket/teams/' +
                                          match.awayTeam.id +
                                          '/' +
                                          match.awayTeam.id +
                                          '.png'
                                        "
                                        @error="
                                          $event.target.src =
                                            bannerImagePath('default-team.png')
                                        "
                                        data-v-89734b7c=""
                                        alt=""
                                      />
                                    </span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      {{ match.awayTeam.name }} ({{
                                        match.awayTeam.shortName
                                      }})
                                    </div>
                                  </div>
                                  <!-- end::away team -->
                                </div>

                                <!-- begin::score area -->
                                <div
                                  data-v-89734b7c=""
                                  class="teamScore padding5"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-col align-center"
                                    style="height: 100%"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                      style="
                                        font-family: Roboto-Regular, Roboto;
                                        position: relative;
                                      "
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="bigScore bh"
                                      >
                                        <div
                                          v-if="
                                            match &&
                                            match.homeScore &&
                                            match.homeScore.innings
                                          "
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          <span
                                            v-for="inn in match.homeScore
                                              .innings"
                                            :key="inn"
                                            data-v-89734b7c=""
                                            :class="
                                              match.changes.changes.includes(
                                                'homeScore.innings'
                                              )
                                                ? 'scoreRed'
                                                : ''
                                            "
                                          >
                                            {{ inn.score + "/" }}
                                            {{ inn.wickets }} ({{ inn.overs }})
                                            &nbsp;
                                            <div
                                              v-if="
                                                match.changes.changes.includes(
                                                  'homeScore.innings'
                                                )
                                              "
                                              data-v-89734b7c=""
                                              class="svgBallIconBox"
                                            >
                                              <img
                                                data-v-89734b7c=""
                                                src="/images/banqiu-banzi.png"
                                                alt="#"
                                                class="svgBallIcon"
                                              />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      data-v-89734b7c=""
                                      class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                      style="
                                        font-family: Roboto-Regular, Roboto;
                                        position: relative;
                                      "
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="bigScore bh"
                                      >
                                        <div
                                          v-if="
                                            match &&
                                            match.awayScore &&
                                            match.awayScore.innings
                                          "
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          <span
                                            v-for="inn in match.awayScore
                                              .innings"
                                            :key="inn"
                                            data-v-89734b7c=""
                                            :class="
                                              match.changes.changes.includes(
                                                'awayScore.innings'
                                              )
                                                ? 'scoreRed'
                                                : ''
                                            "
                                          >
                                            {{ inn.score + "/" }}
                                            {{ inn.wickets }} ({{ inn.overs }})
                                            &nbsp;
                                            <div
                                              v-if="
                                                match.changes.changes.includes(
                                                  'awayScore.innings'
                                                )
                                              "
                                              data-v-89734b7c=""
                                              class="svgBallIconBox"
                                            >
                                              <img
                                                data-v-89734b7c=""
                                                src="/images/banqiu-banzi.png"
                                                alt="#"
                                                class="svgBallIcon"
                                              />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end::score area -->
                                <!-- begin::status area -->
                                <div
                                  data-v-89734b7c=""
                                  class="flex-1 icon-setting"
                                  style="
                                    border-right: 1px solid rgb(234, 234, 234);
                                  "
                                ></div>
                                <div
                                  data-v-89734b7c=""
                                  class="flex Moneyline moneyLineContent"
                                  style="position: relative"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-1 oddsItem"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="flex-1 text-center flex le oddsItemBox noOddsBox oddsWidth3"
                                      style="
                                        margin-left: 15px !important;
                                        margin-right: 10px !important;
                                      "
                                    >
                                      <span> {{ match.note }} </span>
                                    </div>
                                  </div>
                                </div>
                                <!-- end::status area -->
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::live matches -->
            <!-- begin::display upcoming &n finsihed matches -->
            <div
              data-v-46436e01=""
              class="vue-recycle-scroller scroller ready page-mode direction-vertical"
            >
              <div class="vue-recycle-scroller__item-wrapper">
                <div
                  class="vue-recycle-scroller__item-view"
                  v-if="
                    finishedMatchList && (matchList == '' || matchList == null)
                  "
                >
                  <div data-v-46436e01="" class="user">
                    <div
                      data-v-46436e01=""
                      class="flex align-center"
                      v-for="(league, leagueName) in finishedMatchList"
                      :key="leagueName"
                    >
                      <divs
                        data-v-89734b7c=""
                        data-v-46436e01=""
                        style="width: 100%"
                      >
                        <div data-v-89734b7c="" class="list_title">
                          <div
                            data-v-89734b7c=""
                            class="flex align-center topLeftBox"
                          >
                            <i
                              class="iconfont collectImg"
                              style="visibility: visible"
                            ></i>

                            <!-- Render the image if it loads successfully -->
                            <img
                              data-v-89734b7c=""
                              style="
                                height: 33px !important;
                                width: 38px !important;
                              "
                              class="countryLogo"
                              :src="
                                'https://sportyclub.online//images/cricket/leagues/' +
                                league[0].tournament.uniqueTournament.id +
                                '/' +
                                league[0].tournament.uniqueTournament.id +
                                '.png'
                              "
                              @error="
                                $event.target.src =
                                  bannerImagePath('default-nation.png')
                              "
                            />

                            <a
                              data-v-2eea2747=""
                              :href="
                                '/' +
                                this.$i18n.locale +
                                '/cricket/league/details/' +
                                league[0].tournament.uniqueTournament.name
                                  .toLowerCase()
                                  .replace(/\s/g, '-') +
                                '/' +
                                league[0].tournament.uniqueTournament.id
                              "
                              target="_blank"
                              class="compText"
                            >
                              {{ leagueName }}
                            </a>
                          </div>
                        </div>
                        <div
                          data-v-89734b7c=""
                          v-for="match in league"
                          :key="match.id"
                        >
                          <div
                            data-v-89734b7c=""
                            class="list newList"
                            style="height: 64px"
                          >
                            <div data-v-89734b7c="" class="list">
                              <div
                                data-v-89734b7c=""
                                class="flex align-center justify-center collectImgBox"
                              ></div>

                              <a
                                :href="
                                  '/' +
                                  this.$i18n.locale +
                                  '/cricket/match/details/' +
                                  match.homeTeam.name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '-vs-' +
                                  match.awayTeam.name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '/' +
                                  match.id
                                "
                                data-v-89734b7c=""
                                target="_blank"
                                class="flex flex-1"
                                style="height: 100%"
                              >
                                <div
                                  data-v-89734b7c=""
                                  class="flex align-center listBox justify-around"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    class="fs-13 matchTime"
                                    style="color: rgb(153, 153, 153)"
                                  >
                                    {{
                                      formatUnixTimestamp(match.startTimestamp)
                                    }}
                                  </span>
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-col fs-13 align-center color-r text-center barItem"
                                  >
                                    {{ match.status.description }}
                                  </div>
                                </div>
                                <div
                                  data-v-89734b7c=""
                                  class="team flex flex-col padding5"
                                >
                                  <!-- begin::home team -->
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      class="teamLogoBox"
                                    >
                                      <img
                                        :src="
                                          'https://sportyclub.online//images/cricket/teams/' +
                                          match.homeTeam.id +
                                          '/' +
                                          match.homeTeam.id +
                                          '.png'
                                        "
                                        @error="
                                          $event.target.src =
                                            bannerImagePath('default-team.png')
                                        "
                                      />
                                    </span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      {{ match.homeTeam.name }} ({{
                                        match.homeTeam.shortName
                                      }})
                                    </div>

                                    <span> </span>
                                  </div>
                                  <!-- end::home team -->
                                  <!-- begin::away team -->
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      class="teamLogoBox"
                                    >
                                      <img
                                        :src="
                                          'https://sportyclub.online//images/cricket/teams/' +
                                          match.awayTeam.id +
                                          '/' +
                                          match.awayTeam.id +
                                          '.png'
                                        "
                                        data-v-89734b7c=""
                                        @error="
                                          $event.target.src =
                                            bannerImagePath('default-team.png')
                                        "
                                      />
                                    </span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      {{ match.awayTeam.name }} ({{
                                        match.awayTeam.shortName
                                      }})
                                    </div>
                                  </div>
                                  <!-- end::away team -->
                                </div>

                                <!-- begin::score area -->
                                <div
                                  data-v-89734b7c=""
                                  class="teamScore padding5"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-col align-center"
                                    style="height: 100%"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                      style="
                                        font-family: Roboto-Regular, Roboto;
                                        position: relative;
                                      "
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="bigScore bh"
                                      >
                                        <div
                                          v-if="
                                            match &&
                                            match.homeScore &&
                                            match.homeScore.innings
                                          "
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          <span
                                            v-for="inn in match.homeScore
                                              .innings"
                                            :key="inn"
                                            data-v-89734b7c=""
                                            :class="
                                              match.changes.changes.includes(
                                                'homeScore.innings'
                                              )
                                                ? 'scoreRed'
                                                : ''
                                            "
                                          >
                                            {{ inn.score + "/" }}
                                            {{ inn.wickets }} ({{ inn.overs }})
                                            &nbsp;
                                            <div
                                              v-if="
                                                match.changes.changes.includes(
                                                  'homeScore.innings'
                                                )
                                              "
                                              data-v-89734b7c=""
                                              class="svgBallIconBox"
                                            >
                                              <img
                                                data-v-89734b7c=""
                                                src="/images/banqiu-banzi.png"
                                                alt="#"
                                                class="svgBallIcon"
                                              />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      data-v-89734b7c=""
                                      class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                      style="
                                        font-family: Roboto-Regular, Roboto;
                                        position: relative;
                                      "
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="bigScore bh"
                                      >
                                        <div
                                          v-if="
                                            match &&
                                            match.awayScore &&
                                            match.awayScore.innings
                                          "
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          <span
                                            v-for="inn in match.awayScore
                                              .innings"
                                            :key="inn"
                                            data-v-89734b7c=""
                                            :class="
                                              match.changes.changes.includes(
                                                'awayScore.innings'
                                              )
                                                ? 'scoreRed'
                                                : ''
                                            "
                                          >
                                            {{ inn.score + "/" }}
                                            {{ inn.wickets }} ({{ inn.overs }})
                                            &nbsp;
                                            <div
                                              v-if="
                                                match.changes.changes.includes(
                                                  'awayScore.innings'
                                                )
                                              "
                                              data-v-89734b7c=""
                                              class="svgBallIconBox"
                                            >
                                              <img
                                                data-v-89734b7c=""
                                                src="/images/banqiu-banzi.png"
                                                alt="#"
                                                class="svgBallIcon"
                                              />
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end::score area -->
                                <!-- begin::status area -->
                                <div
                                  data-v-89734b7c=""
                                  class="flex-1 icon-setting"
                                  style="
                                    border-right: 1px solid rgb(234, 234, 234);
                                  "
                                ></div>
                                <div
                                  data-v-89734b7c=""
                                  class="flex Moneyline moneyLineContent"
                                  style="position: relative"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-1 oddsItem"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="flex-1 text-center flex le oddsItemBox noOddsBox oddsWidth3"
                                      style="
                                        margin-left: 15px !important;
                                        margin-right: 10px !important;
                                      "
                                    >
                                      <span> {{ match.note }} </span>
                                    </div>
                                  </div>
                                </div>
                                <!-- end::status area -->
                              </a>
                            </div>
                          </div>
                        </div>
                      </divs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end:::display upcoming &n finsihed matche -->

            <!-- begin::display previous and next matches -->
            <span v-if="matchList && !loading">
              <div
                data-v-46436e01=""
                class="vue-recycle-scroller scroller ready page-mode direction-vertical"
              >
                <div class="vue-recycle-scroller__item-wrapper">
                  <div>
                    <div class="vue-recycle-scroller__item-view">
                      <div data-v-46436e01="" class="tag_commingMatches">
                        <i data-v-46436e01="" class="iconfont icon-shaozi"></i
                        ><span data-v-46436e01="" class="text">{{
                          activeDate
                        }}</span>
                      </div>
                      <div data-v-46436e01="" class="user">
                        <div
                          data-v-46436e01=""
                          class="flex align-center"
                          v-for="(league, leagueName) in matchList"
                          :key="leagueName"
                        >
                          <div
                            data-v-89734b7c=""
                            data-v-46436e01=""
                            style="width: 100%"
                          >
                            <div data-v-89734b7c="" class="list_title">
                              <div
                                data-v-89734b7c=""
                                class="flex align-center topLeftBox"
                              >
                                <i
                                  class="iconfont collectImg"
                                  style="visibility: visible"
                                ></i>

                                <!-- Render the image if it loads successfully -->
                                <img
                                  style="height: 33px !important; width: 38px"
                                  data-v-89734b7c=""
                                  class="countryLogo"
                                  :src="
                                    'https://sportyclub.online//images/cricket/leagues/' +
                                    league[0].tournament.uniqueTournament.id +
                                    '/' +
                                    league[0].tournament.uniqueTournament.id +
                                    '.png'
                                  "
                                  alt="Team Image"
                                  @error="
                                    $event.target.src = bannerImagePath(
                                      'default-country.png'
                                    )
                                  "
                                />

                                <a
                                  data-v-2eea2747=""
                                  :href="
                                    '/' +
                                    this.$i18n.locale +
                                    '/cricket/league/details/' +
                                    league[0].tournament.uniqueTournament.name
                                      .toLowerCase()
                                      .replace(/\s/g, '-') +
                                    '/' +
                                    league[0].tournament.uniqueTournament.id
                                  "
                                  target="_blank"
                                  class="compText"
                                >
                                  {{ leagueName }}
                                </a>
                              </div>
                            </div>
                            <div
                              data-v-89734b7c=""
                              v-for="match in league"
                              :key="match.id"
                            >
                              <div
                                data-v-89734b7c=""
                                class="list newList"
                                style="height: 64px"
                              >
                                <div data-v-89734b7c="" class="list">
                                  <div
                                    data-v-89734b7c=""
                                    class="flex align-center justify-center collectImgBox"
                                  ></div>

                                  <a
                                    :href="
                                      '/' +
                                      this.$i18n.locale +
                                      '/cricket/match/details/' +
                                      match.homeTeam.name
                                        .toLowerCase()
                                        .replace(/\s/g, '-') +
                                      '-vs-' +
                                      match.awayTeam.name
                                        .toLowerCase()
                                        .replace(/\s/g, '-') +
                                      '/' +
                                      match.id
                                    "
                                    data-v-89734b7c=""
                                    target="_blank"
                                    class="flex flex-1"
                                    style="height: 100%"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="flex align-center listBox justify-around"
                                    >
                                      <span
                                        data-v-89734b7c=""
                                        class="fs-13 matchTime"
                                        style="color: rgb(153, 153, 153)"
                                      >
                                        {{
                                          formatUnixTimestamp(
                                            match.startTimestamp
                                          )
                                        }}
                                      </span>
                                      <div
                                        data-v-89734b7c=""
                                        class="flex flex-col fs-13 align-center color-r text-center barItem"
                                      >
                                        {{ match.status.description }}
                                      </div>
                                    </div>

                                    <div
                                      data-v-89734b7c=""
                                      class="team flex flex-col padding5"
                                    >
                                      <!-- begin::home team -->
                                      <div
                                        data-v-89734b7c=""
                                        class="flex-1 align-center flex"
                                      >
                                        <span
                                          data-v-89734b7c=""
                                          class="teamLogoBox"
                                        >
                                          <img
                                            :src="
                                              'https://sportyclub.online//images/cricket/teams/' +
                                              match.homeTeam.id +
                                              '/' +
                                              match.homeTeam.id +
                                              '.png'
                                            "
                                            @error="
                                              $event.target.src =
                                                bannerImagePath(
                                                  'default-team.png'
                                                )
                                            "
                                          />
                                        </span>
                                        <div
                                          data-v-89734b7c=""
                                          class="w-o-h elseTeamName"
                                        >
                                          {{ match.homeTeam.name }} ({{
                                            match.homeTeam.shortName
                                          }})
                                        </div>
                                      </div>
                                      <!-- end::home team -->
                                      <!-- begin::away team -->
                                      <div
                                        data-v-89734b7c=""
                                        class="flex-1 align-center flex"
                                      >
                                        <span
                                          data-v-89734b7c=""
                                          class="teamLogoBox"
                                        >
                                          <img
                                            :src="
                                              'https://sportyclub.online//images/cricket/teams/' +
                                              match.awayTeam.id +
                                              '/' +
                                              match.awayTeam.id +
                                              '.png'
                                            "
                                            @error="
                                              $event.target.src =
                                                bannerImagePath(
                                                  'default-team.png'
                                                )
                                            "
                                            data-v-89734b7c=""
                                            alt=""
                                          />
                                        </span>
                                        <div
                                          data-v-89734b7c=""
                                          class="w-o-h elseTeamName"
                                        >
                                          {{ match.awayTeam.name }} ({{
                                            match.awayTeam.shortName
                                          }})
                                        </div>
                                      </div>
                                      <!-- end::away team -->
                                    </div>
                                    <!-- begin::score area -->
                                    <div
                                      data-v-89734b7c=""
                                      class="teamScore padding5"
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="flex flex-col align-center"
                                        style="height: 100%"
                                      >
                                        <div
                                          data-v-89734b7c=""
                                          class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                          style="
                                            font-family: Roboto-Regular, Roboto;
                                            position: relative;
                                          "
                                        >
                                          <div
                                            data-v-89734b7c=""
                                            class="bigScore bh"
                                          >
                                            <div
                                              v-if="
                                                match &&
                                                match.homeScore &&
                                                match.homeScore.innings
                                              "
                                              data-v-89734b7c=""
                                              class=""
                                            >
                                              <span
                                                v-for="inn in match.homeScore
                                                  .innings"
                                                :key="inn"
                                                data-v-89734b7c=""
                                              >
                                                {{ inn.score + "/" }}
                                                {{ inn.wickets }} ({{
                                                  inn.overs
                                                }}) &nbsp;
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          data-v-89734b7c=""
                                          class="flex align-center fs-13 color-999 w-bar-100 flex-1"
                                          style="
                                            font-family: Roboto-Regular, Roboto;
                                            position: relative;
                                          "
                                        >
                                          <div
                                            data-v-89734b7c=""
                                            class="bigScore bh"
                                          >
                                            <div
                                              v-if="
                                                match &&
                                                match.awayScore &&
                                                match.awayScore.innings
                                              "
                                              data-v-89734b7c=""
                                              class=""
                                            >
                                              <span
                                                v-for="inn in match.awayScore
                                                  .innings"
                                                :key="inn"
                                                data-v-89734b7c=""
                                              >
                                                {{ inn.score + "/" }}
                                                {{ inn.wickets }} ({{
                                                  inn.overs
                                                }}) &nbsp;
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- end::score area -->
                                    <!-- begin::status area -->
                                    <div
                                      data-v-89734b7c=""
                                      class="flex-1 icon-setting"
                                      style="
                                        border-right: 1px solid
                                          rgb(234, 234, 234);
                                      "
                                    ></div>
                                    <div
                                      data-v-89734b7c=""
                                      class="flex Moneyline moneyLineContent"
                                      style="position: relative"
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="flex flex-1 oddsItem"
                                      >
                                        <div
                                          data-v-89734b7c=""
                                          class="flex-1 text-center flex le oddsItemBox noOddsBox oddsWidth3"
                                          style="
                                            margin-left: 15px !important;
                                            margin-right: 10px !important;
                                          "
                                        >
                                          <span>{{ match.note }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- end::status area -->
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <!-- end::display previous and next matches -->

            <!-- begin::featured match -->

            <br />
            <TopStories />

            <br />

            <!-- <ActiveLeaguesNews /> -->
            <!-- end::featured match -->
          </template>
        </div>
      </div>

      <div class="wrapper" data-v-6c1525da="">
        <div class="flex" data-v-29b39e42="" data-v-6c1525da="">
          <div class="p-r" data-v-29b39e42="">
            <div class="calendar-container">
              <div class="calendar">
                <div
                  class="week-days"
                  style="background-color: var(--main-theme-fav-color)"
                >
                  <span
                    v-for="day in weekDays"
                    :key="day"
                    style="color: white"
                    >{{ day }}</span
                  >
                </div>
                <br />
                <div class="days">
                  <div
                    v-for="day in daysInMonth"
                    :key="day"
                    class="day"
                    :class="{ 'current-day': isToday(day) }"
                    @click="selectDate(day)"
                  >
                    {{ day }}
                  </div>
                </div>
              </div>
            </div>
            <FeaturedMatch />
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>

  <DesktopFooter />
</template>
<!-- begin::scripts -->
<script>
import axios from "axios";
import FeaturedMatch from "./tabs/featured-match.vue";
import TopStories from "./tabs/top-stories.vue";
import SearchMatches from "./tabs/search-matches-list.vue";

import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  components: {
    FeaturedMatch,
    SearchMatches,
    TopStories,
  },

  data() {
    return {
      loading: true,
      liveActivebtn: "live",
      countryLeagueActive: "",
      toggleBannerState: false,
      todayMatchList: null,
      liveMatchList: null,
      finishedMatchList: null,

      upcomingDates: [],
      previousDates: [],
      matchList: null,
      activeDate: null,
      countLiveMatches: null,

      // search functionality
      searchListUpcoming: null,
      searchListLive: null,
      todayUpcomingMatchList: null,
      currentYear: new Date().getFullYear(), // Initialize with the current year
      currentMonth: new Date().getMonth() + 1, // Initialize with the current month (1-based index)
      currentDate: new Date(), // Current date
      weekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // Days of the week
    };
  },
  created() {
    this.displayUpcomingDates();
    this.displayPreviousDates();
    this.getTodayMatchList("live");
    this.getSearchResult();
    //  refresh frontend every 30 seconds
    setInterval(() => {
      this.getTodayMatchList("live");
    }, 100000);
  },
  computed: {
    currentMonthYear() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return `${
        monthNames[this.currentDate.getMonth()]
      } ${this.currentDate.getFullYear()}`;
    },
    daysInMonth() {
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth();
      const days = new Date(year, month + 1, 0).getDate(); // Get number of days in the month
      const firstDay = new Date(year, month, 1).getDay(); // Get day of the week for the 1st of the month

      // Fill the days array with empty slots for the first day and actual days of the month
      return [
        ...Array(firstDay).fill(""), // Empty slots for the first day of the month
        ...Array.from({ length: days }, (_, i) => i + 1), // Days of the month
      ];
    },
  },

  mounted() {
    // search functionality
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.liveMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.liveMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayTeam.cn_name &&
                  matchObject.homeTeam.cn_name &&
                  matchObject.tournament.cn_name
                ) {
                  return (
                    matchObject.awayTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayTeam.cn_name &&
                    matchObject.homeTeam.cn_name &&
                    matchObject.tournament.cn_name
                  ) {
                    return (
                      matchObject.awayTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayTeam.cn_name &&
                  matchObject.homeTeam.cn_name &&
                  matchObject.tournament.cn_name
                ) {
                  return (
                    matchObject.awayTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayTeam.cn_name &&
                    matchObject.homeTeam.cn_name &&
                    matchObject.tournament.cn_name
                  ) {
                    return (
                      matchObject.awayTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },

  methods: {
    prevMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    },
    nextMonth() {
      this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    },
    isToday(day) {
      const today = new Date();
      return (
        today.getFullYear() === this.currentDate.getFullYear() &&
        today.getMonth() === this.currentDate.getMonth() &&
        today.getDate() === day
      );
    },
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },

    displayUpcomingDates() {
      const today = new Date();
      // Loop through the next 7 days
      for (let i = 0; i < 7; i++) {
        // Calculate the date for the current iteration
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because months are zero-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        this.upcomingDates.push(formattedDate);
      }
    },
    selectDate(day) {
      if (this.currentYear && this.currentMonth) {
        const selectedDate = new Date(
          this.currentYear,
          this.currentMonth - 1,
          day
        );
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
        const date = String(selectedDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${date}`;

        this.getMatchList(formattedDate);
      } else {
        window.alert("Current year or month is not defined"); // Error message in alert
      }
    },
    displayPreviousDates() {
      const today = new Date();
      // Loop through the previous 7 days
      for (let i = 1; i <= 7; i++) {
        // Calculate the date for the current iteration
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because months are zero-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        this.previousDates.push(formattedDate);
      }
    },

    // method to convert unix timestamp
    formatUnixTimestamp(timestamp) {
      const gmtOffset = 4 * 60 * 60 * 1000; // GMT+4 offset in milliseconds
      const adjustedTimestamp = timestamp * 1000 + gmtOffset; // Convert seconds to milliseconds and add GMT+4 offset
      const date = new Date(adjustedTimestamp);
      let hours = date.getUTCHours(); // Get UTC hours
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert hours to 12-hour format
      const formattedHours = hours < 10 ? "0" + hours : hours; // Add leading zero if hours < 10
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    },

    // method to get today matchList
    getTodayMatchList(status) {
      var stat;
      if (status == "live") {
        this.liveActivebtn = "live";
        axios
          .get(`${this.BASE_SERVER_URI}/api/cricket/today/live/matches`)
          .then((response) => {
            const leagues = Object.keys(response.data); // Get all league keys
            let liveMatchesCount = 0;

            // Iterate through leagues
            leagues.forEach((leagueKey) => {
              const matches = response.data[leagueKey];

              // Iterate through matches of the current league to count live matches
              matches.forEach((match) => {
                if (match.status.type === "inprogress") {
                  liveMatchesCount++;
                }
              });
            });

            // Store the count in countLiveMatches
            this.countLiveMatches = liveMatchesCount;

            // Proceed with other operations
            this.liveMatchList = response.data;
          })
          .catch((error) => {
            console.error("Error fetching live matches:", error);
          });
      } else if (status == "finished") {
        this.liveActivebtn = "finished";
        stat = 100;
      } else if (status == "soon") {
        this.liveActivebtn = "soon";
        stat = 0;
      }

      // get yeasterday date
      axios
        .get(`${this.BASE_SERVER_URI}/api/cricket/today/matches/${stat}`)
        .then((response) => {
          this.liveMatchList = "";
          this.matchList = "";
          this.finishedMatchList = response.data;
          this.loading = false;
        });
    },

    // method to get previous/upcoming matchList
    getMatchList(date) {
      this.activeDate = date;
      this.loading = true;
      axios
        .get(`${this.BASE_SERVER_URI}/api/cricket/today/all/matches/${date}`)
        .then((response) => {
          this.matchList = response.data;
          this.loading = false;
        });
    },

    // search functionality
    getSearchResult() {
      const currentDate = new Date().toISOString().split("T")[0];
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api/cricket/today/all/matches/${currentDate}`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data;
          console.log(this.todayUpcomingMatchList);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.dateHover:hover {
  color: #ff0000; /* Change this to the desired hover color */
}

@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}
.calendar {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
}

.calendar-body {
  width: 100%;
}

.week-days {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px; /* Padding to cover all corners */
  margin-top: -10px; /* Negative margin to cover corners */
  margin-left: -10px; /* Negative margin to cover corners */
  margin-right: -10px; /* Negative margin to cover corners */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f0f0f0; /* Background color for the header */
}

.days {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 0 5px 5px; /* Round bottom corners */
  overflow: hidden; /* Prevent overflow to ensure corners are covered */
}

.day {
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer; /* Pointer cursor on hover */
}

.day:nth-child(7n + 1) {
  color: red; /* Highlight Sundays, optional */
}
</style>
