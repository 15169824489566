<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div id="__nuxt" v-if="!loading">
    <!---->
    <div id="__layout">
      <div id="app" class="font-en" data-v-250f1619="">
        <div
          class="competition view border-box"
          data-v-6d73ae82=""
          data-v-250f1619
        >
          <div
            class="tourHeader flex justify-center align-center flex-col"
            data-v-6d73ae82=""
          >
            <!-- <i class="iconfont iconback" data-v-6d73ae82=""></i> -->
            <img
              :src="
                leagueDetails && leagueDetails.logo
                  ? leagueDetails.logo + '!w100'
                  : bannerImagePath('default-nation.png')
              "
              :alt="
                leagueDetails && leagueDetails.name ? leagueDetails.name : ''
              "
              class="compImg"
              data-v-6d73ae82=""
            />
            <p class="compName" data-v-6d73ae82="">
              {{
                leagueDetails &&
                this.$i18n.locale == "cn" &&
                leagueDetails.cn_name
                  ? leagueDetails.cn_name
                  : leagueDetails.name
                  ? leagueDetails.name
                  : ""
              }}
            </p>
          
          </div>
          <div class="tab flex" data-v-6d73ae82="">
            <a
              v-on:click="toggleTab('standings')"
              :class="
                'flex flex-1 justify-center align-center active' +
                (activeTab == 'standings' ? ' active' : '')
              "
              target="_self"
              class="flex flex-1 justify-center align-center"
              data-v-6d73ae82=""
            >
              <span class="van-tab__text">{{
                $t("football.league-details.standings")
              }}</span>
              <span v-if="activeTab === 'standings'" data-v-6d73ae82=""></span>
            </a>
          </div>
          <LeagueStandings
            v-if="activeTab == 'standings'"
            :leagueId="leagueId"
            :leagueDetails="leagueDetails"
          />
        </div>
        <MobileFooter />
      </div>
    </div>
  </div>
</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";

import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    LeagueStandings,
  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "standings",
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/badminton/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/badminton/league/details/${leagueId}`)
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
