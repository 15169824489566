<template>
  <span
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
  >
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.live-score.search-results") }}
      </span>
    </div>
  </span>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    data-v-abef72c2=""
    class="flex justify-center align-center flex-col contentBox"
    style="height: 400px"
  >
    <img data-v-abef72c2="" src="/images/no-data-search.png" class="nodata" />
    <div data-v-abef72c2="" class="color-999 noDataText">
      {{ $t("football.live-score.nothing-found") }}
    </div>
  </div>

  <template v-else>
    <div style="min-height: 500px">
      <!-- live search result -->

      <div class="w100" v-for="(league, key) in searchMatchListLive" :key="key">
        <div style="width: 100%">
          <!-- begin::show league & country -->
          <div class="list_title">
            <i class="iconfont collectImg"></i>
            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
            >
              <div
                class="listImg van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  class="van-image__img"
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo) ||
                      (league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name) ||
                      (league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name))
                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>

              <span data-v-89734b7c="" class="countryText">
                <span data-v-f1b1cb6e="" class="country-name minitext">
                  <template v-if="league[0] && league[0].leagueInfo">
                    <template v-if="this.$i18n.locale == 'cn'">
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                      <template v-else> </template>
                    </template>
                  </template>
                </span>
              </span>

              <a
                v-if="
                  league &&
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo &&
                  league[0].hasLeagueDetails
                "
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/baseball/league/details/' +
                  league[0].leagueInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  league[0].unique_tournament_id
                "
                class="compText"
                style="font-size: 0.32rem"
              >
                {{
                  this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                    ? league[0].leagueInfo.cn_name
                    : league[0].leagueInfo.en_name
                    ? league[0].leagueInfo.en_name
                    : ""
                }}
              </a>
              <a v-else class="compText" style="font-size: 0.32rem">
                {{
                  this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                    ? league[0].leagueInfo.cn_name
                    : league[0].leagueInfo.en_name
                    ? league[0].leagueInfo.en_name
                    : ""
                }}
              </a>
            </div>
            <div class="flex userNumBox">
              <!-- <span class="iconfont icon-renshu"></span> -->
              <span class="userNum"> </span>
            </div>
          </div>
          <!-- end::show league & country -->
          <!-- begin::match info -->
          <div class="w100 list_active">
            <div
              class="list"
              v-for="match in league"
              :key="match.id"
              style="height: 1.38rem; position: relative"
            >
              <div class="w100 flex h100 contentListBox">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/baseball/match/details/' +
                    match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                    '-vs-' +
                    match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    match.id
                  "
                  class="flex flex-1 h100 allBox"
                >
                  <!-- <i class="iconfont collectImg icon-weishoucang"></i> -->
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ readableTime(this.$i18n.locale, match.match_time) }}
                      </span>

                      <div class="flex fs-12 align-center color-r text-center">
                        <span class="fs-12" style="white-space: nowrap">{{
                          match.status_id &&
                          this.FinishedMatches.includes(match.status_id)
                            ? $t("football.league-details.full-time")
                            : match?.statusDescriptionShort
                        }}</span>
                      </div>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.homeInfo"
                                  :src="
                                    match.homeInfo.logo
                                      ? match.homeInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt=""
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="homeTeam"
                              class="w-o-h fs-14"
                              style="padding-left: 0.2rem; max-width: 4.8rem"
                            >
                              <template v-if="match && match.homeInfo"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.homeInfo.cn_name
                                    ? match.homeInfo.cn_name
                                    : match.homeInfo.en_name
                                    ? match.homeInfo.en_name
                                    : ""
                                }}
                              </template>
                            </div>
                            <img
                              src="/images/baseball-bat.png"
                              alt="#"
                              class="svgPostop ballIcon ballIconLeft"
                              v-if="
                                bottomTeamInningsStatuses.findIndex(
                                  (status) => status == match.status_id
                                ) != -1
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.awayInfo"
                                  :src="
                                    match.awayInfo.logo
                                      ? match.awayInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt="Punjab Royals"
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="awayTeam"
                              class="w-o-h fs-14 pl2"
                              style="padding-left: 0.2rem; max-width: 4.8rem"
                            >
                              <template v-if="match && match.awayInfo"
                                >{{
                                  this.$i18n.locale == "cn" &&
                                  match.awayInfo.cn_name
                                    ? match.awayInfo.cn_name
                                    : match.awayInfo.en_name
                                    ? match.awayInfo.en_name
                                    : ""
                                }}
                              </template>
                            </div>
                            <img
                              v-if="
                                topTeamInningsStatuses.findIndex(
                                  (status) => status == match.status_id
                                ) != -1
                              "
                              src="/images/baseball-bat.png"
                              alt="#"
                              class="svgPostop ballIcon ballIconLeft"
                            />
                            <!---->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="icon-setting">
                      <span
                        v-if="liveMatches.includes(match.status_id)"
                        class="iconfont icon-shipinzhibo"
                      ></span>
                    </div>

                    <div class="teamScore padding5" style="width: 1.17rem">
                      <div class="w100 flex h100">
                        <div class="flex-1 h100 padding5">
                          <div
                            class="flex flex-col align-center w100"
                            style="height: 100%; line-height: 12px"
                          >
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center scoreWeight"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span
                                  class="scoreRed"
                                  style="font-size: 0.32rem; padding: 0px 2px"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    :class="{
                                      scoreRed:
                                        topTeamInningsStatuses.findIndex(
                                          (status) => status == match.status_id
                                        ) != -1,
                                    }"
                                  >
                                    <span data-v-89734b7c="" class="">
                                      <span data-v-89734b7c="" class="">
                                        {{
                                          match.scores &&
                                          match.scores.ft &&
                                          match.scores.ft[0]
                                            ? match.scores.ft[0]
                                            : ""
                                        }}
                                      </span>
                                    </span>
                                    <!---->
                                  </span></span
                                >
                              </div>
                            </div>
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span
                                  class="scoreRed"
                                  style="font-size: 0.32rem; padding: 0px 2px"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    :class="{
                                      scoreRed:
                                        bottomTeamInningsStatuses.findIndex(
                                          (status) => status == match.status_id
                                        ) != -1,
                                    }"
                                  >
                                    <span data-v-89734b7c="" class="">
                                      <span data-v-89734b7c="" class="">
                                        <!-- get the first team run number -->
                                        {{
                                          match.scores &&
                                          match.scores.ft &&
                                          match.scores.ft[1]
                                            ? match.scores.ft[1]
                                            : ""
                                        }}
                                      </span>
                                    </span>
                                    <!---->
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- end::match info -->
        </div>
      </div>

      <!-- upcoming search result -->

      <div class="w100">
        <div
          class="vue-recycle-scroller__item-view"
          style="transform: translateY(0px); min-height: 400px"
        >
          <div
            class="w100"
            v-for="(league, key) in searchMatchListUpcoming"
            :key="key"
          >
            <div class="list_active" style="width: 100%">
              <div class="list_title">
                <i class="iconfont collectImg"></i>
                <div
                  class="flex align-center"
                  style="overflow: hidden; width: 8.4rem"
                >
                  <div
                    class="listImg van-image van-image--round"
                    itemprop="logo"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      class="van-image__img"
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo
                      "
                      :src="
                        league[0].leagueInfo.countryDetails.logo
                          ? league[0].leagueInfo.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      alt=""
                      lazy="loaded"
                      style="object-fit: contain"
                    />
                  </div>
                  <span data-v-89734b7c="" class="countryText">
                    <span data-v-f1b1cb6e="" class="country-name minitext">
                      <template v-if="league[0] && league[0].leagueInfo">
                        <template v-if="this.$i18n.locale == 'cn'">
                          <template
                            v-if="
                              league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.cn_name
                            "
                          >
                            {{ league[0].leagueInfo.countryDetails.cn_name }}:
                          </template>
                          <template
                            v-else-if="
                              league[0].leagueInfo.categoryDetails &&
                              league[0].leagueInfo.categoryDetails.cn_name
                            "
                          >
                            {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                          </template>
                          <template
                            v-else-if="
                              league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.name
                            "
                          >
                            {{ league[0].leagueInfo.countryDetails.name }}:
                          </template>
                          <template
                            v-else-if="
                              league[0].leagueInfo.categoryDetails &&
                              league[0].leagueInfo.categoryDetails.name
                            "
                          >
                            {{ league[0].leagueInfo.categoryDetails.name }}:
                          </template>
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.name
                            "
                          >
                            {{ league[0].leagueInfo.countryDetails.name }}:
                          </template>
                          <template
                            v-else-if="
                              league[0].leagueInfo.categoryDetails &&
                              league[0].leagueInfo.categoryDetails.name
                            "
                          >
                            {{ league[0].leagueInfo.categoryDetails.name }}:
                          </template>
                          <template v-else> </template>
                        </template>
                      </template>
                    </span>
                  </span>
                  <a
                    v-if="league[0].hasLeagueDetails"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/baseball/league/details/' +
                      league[0].leagueInfo.en_name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      league[0].unique_tournament_id
                    "
                    class="compText"
                    style="font-size: 0.32rem"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                  <a v-else class="compText" style="font-size: 0.32rem">
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                </div>
              </div>
              <div class="w100 list_active">
                <div
                  v-for="match in league"
                  :key="match.id"
                  class="list"
                  style="height: 1.38rem; position: relative"
                >
                  <div class="w100 flex h100 contentListBox">
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/baseball/match/details/' +
                        match.homeInfo.en_name
                          .toLowerCase()
                          .replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayInfo.en_name
                          .toLowerCase()
                          .replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      class="flex flex-1 h100 allBox"
                    >
                      <!-- <i class="iconfont collectImg icon-weishoucang"></i> -->
                      <div class="w100 flex h100 align-center">
                        <div
                          class="flex align-center justify-center listBox flex-column"
                        >
                          <span
                            class="fs-12 text-center"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{
                              readableTime(this.$i18n.locale, match.match_time)
                            }}
                          </span>
                          <!-- <span
                        class="fs-12 color-999 van-ellipsis color-r"
                        style="max-width: 1.6rem"
                        v-if="liveMatches.includes(match.status_id)"
                      >
                        {{
                          onGoingMatchTime(
                            match.counter_timing,
                            match.status_id
                          )
                        }}<span class="Twinkle">'</span></span
                      > -->

                          <div
                            class="flex fs-12 align-center color-r text-center"
                          >
                            <span class="fs-12" style="white-space: nowrap">{{
                              match.status_id &&
                              this.FinishedMatches.includes(match.status_id)
                                ? $t("football.league-details.full-time")
                                : match?.statusDescription
                            }}</span>
                          </div>
                        </div>
                        <div class="team flex flex-col padding5">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div class="flex-1 align-center flex">
                                <div
                                  class="flex align-center"
                                  style="width: 0.4rem"
                                >
                                  <div
                                    class="imgw4 van-image van-image--round"
                                    itemprop="logo"
                                    style="overflow: hidden; border-radius: 50%"
                                  >
                                    <img
                                      v-if="match.homeInfo"
                                      :src="
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo
                                        : bannerImagePath('default-team.png')
                                      "
                                      lazy="loaded"
                                      alt="Punjab Royals"
                                      class="van-image__img"
                                      style="object-fit: contain"
                                    />
                                  </div>
                                </div>
                                <div
                                  itemprop="homeTeam"
                                  class="w-o-h fs-14 pl2"
                                  style="
                                    padding-left: 0.2rem;
                                    max-width: 4.8rem;
                                  "
                                >
                                  <template v-if="match && match.homeInfo">{{
                                    this.$i18n.locale == "cn" &&
                                    match.homeInfo.cn_name
                                      ? match.homeInfo.cn_name
                                      : match.homeInfo.en_name
                                      ? match.homeInfo.en_name
                                      : ""
                                  }}</template>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </div>

                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div class="flex-1 align-center flex">
                                <div
                                  class="flex align-center"
                                  style="width: 0.4rem"
                                >
                                  <div
                                    class="imgw4 van-image van-image--round"
                                    itemprop="logo"
                                    style="overflow: hidden; border-radius: 50%"
                                  >
                                    <img
                                      v-if="match.awayInfo"
                                      :src="
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo
                                        : bannerImagePath('default-team.png')
                                      "
                                      lazy="loaded"
                                      alt="Punjab Royals"
                                      class="van-image__img"
                                      style="object-fit: contain"
                                    />
                                  </div>
                                </div>
                                <div
                                  itemprop="homeTeam"
                                  class="w-o-h fs-14 pl2"
                                  style="
                                    padding-left: 0.2rem;
                                    max-width: 4.8rem;
                                  "
                                >
                                  <template v-if="match && match.awayInfo">{{
                                    this.$i18n.locale == "cn" &&
                                    match.awayInfo.cn_name
                                      ? match.awayInfo.cn_name
                                      : match.awayInfo.en_name
                                      ? match.awayInfo.en_name
                                      : ""
                                  }}</template>
                                </div>
                                <!---->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="icon-setting">
                          <span
                            v-if="liveMatches.includes(match.status_id)"
                            class="iconfont icon-shipinzhibo"
                          ></span>
                          <span
                            v-else
                            class="iconfont icon-donghuazhibo"
                          ></span>
                        </div>

                        <div class="teamScore padding5" style="width: 1.17rem">
                          <div
                            class="flex flex-col align-center"
                            style="
                              height: 100%;
                              color: rgb(153, 153, 153);
                              line-height: 12px;
                            "
                          >
                            <div class="flex oddDataBox">
                              <div
                                class="flex align-center"
                                style="flex: 1 1 0%; font-size: 0.32rem"
                              >
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match?.odds?.euro[1]).toFixed(
                                        2
                                      )
                                    : "-"
                                }}
                              </div>
                              <div
                                class="flex align-center"
                                style="flex: 1 1 0%; font-size: 0.32rem"
                              >
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match?.odds?.euro[3]).toFixed(
                                        2
                                      )
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {

  mixins: [DateMixin,DynamicImagePath],
  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      liveMatches: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420, 433, 435, 437, 439,
        411, 413, 415, 417, 419, 421,
      ],

      FinishedMatches: [100],
      topTeamInningsStatuses: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      ],
      bottomTeamInningsStatuses: [
        433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      ],
    };
  },
  created() {},
  computed: {},
  methods: {
    addAllScoreTennis(val1, val2, val3, val4) {
      val1 = val1 || 0;
      val2 = val2 || 0;
      val3 = val3 || 0;
      val4 = val4 || 0;
      return val1 + val2 + val3 + val4;
    },

  },
};
</script>
