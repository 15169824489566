<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-6ad89ef3="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-6ad89ef3="">
      <div class="content-box" data-v-6ad89ef3="">
        <ul class="breadcrumb" data-v-6ad89ef3="">
          <li data-v-6ad89ef3="">
            <a
              :href="'/' + this.$i18n.locale + '/live/tennis'"
              class="link"
              data-v-6ad89ef3=""
            >
              {{ $t("football.league-details.tennis-live-score") }}
            </a>
          </li>

          <li data-v-6ad89ef3="">
            <h1 class="text" data-v-6ad89ef3="">
              {{
                this.$i18n.locale == "cn" && teamDetails
                  ? teamDetails.cn_name
                  : teamDetails.name
              }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="team" data-v-6ad89ef3="">
      <div class="team-header" data-v-2f158089="" data-v-6ad89ef3="">
        <div class="content border-box flex align-center" data-v-2f158089="">
          <div class="p-l flex-1 flex align-center" data-v-2f158089="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-2f158089=""
            >
              <img
                :src="
                  teamDetails && teamDetails.logo
                    ? teamDetails.logo
                   : bannerImagePath('default-nation.png')
                "
                class="team-logo"
                data-v-2f158089=""
              />
              <div class="pl-15" data-v-2f158089="">
                <div class="fs-24 font-500 color-333" data-v-2f158089="">
                  <span data-v-2f158089="">
                    {{
                      this.$i18n.locale == "cn" && teamDetails
                        ? teamDetails.cn_name
                        : teamDetails.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- begin::team squad -->
      <div class="wrapper" data-v-6ad89ef3="">
        <div class="flex" data-v-771adb1a="" data-v-6ad89ef3="">
          <div class="p-l flex-1" data-v-771adb1a="">
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <div class="body" data-v-0939e9fc="">
                    <div class="border-box" data-v-51bacf47="">
                      <br />
                    </div>
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 500px"
                    >
                      <img :src="bannerImagePath('default-container.png')" />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-r" data-v-08c9d60b="">
            <!-- begin::league Info -->
            <div
              class="container-box mb-12 pb-15"
              data-v-7c947e55=""
              data-v-59710436=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ $t("football.league-details.profile-info") }}
                </span>
                <span class="color-999 fs-12" data-v-7c947e55=""></span>
              </div>
              <div
                class="LeagueInfo fs-12 pl-15 pr-15"
                data-v-bb874c34=""
                data-v-59710436=""
                data-v-7c947e55=""
              >
                <!-- begin::league info -->
                <div class="container-box" data-v-bb874c34="">
                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.name") }}
                    </div>
                    <div data-v-bb874c34="">
                      {{
                        this.$i18n.locale == "cn" && teamDetails
                          ? teamDetails.cn_name
                          : teamDetails.name
                      }}
                    </div>
                  </div>
                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.type") }}
                    </div>
                    <div data-v-bb874c34="">
                      {{
                        teamDetails && teamDetails.type == 1
                          ? $t("football.league-details.single")
                          : $t("football.league-details.double")
                      }}
                    </div>
                  </div>

                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.gender") }}
                    </div>
                    <div data-v-bb874c34="">
                      {{
                        teamDetails && teamDetails.type == 1
                          ? $t("football.league-details.male")
                          : $t("football.league-details.female")
                      }}
                    </div>
                  </div>
                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.birthday") }}
                    </div>
                    <div
                      data-v-bb874c34=""
                      v-if="
                        teamDetails &&
                        teamDetails.extra &&
                        teamDetails.extra.birthday &&
                        teamDetails.extra.birthday != ''
                      "
                    >
                      {{ formatDate(teamDetails.extra.birthday) }}
                    </div>
                    <div data-v-bb874c34="" v-else>-</div>
                  </div>
                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.is-national") }}
                    </div>
                    <div data-v-bb874c34="">
                      {{
                        teamDetails && teamDetails.national == 0
                          ? $t("football.league-details.no")
                          : $t("football.league-details.yes")
                      }}
                    </div>
                  </div>
                  <div
                    class="flex justify-between Stadium-item"
                    data-v-bb874c34=""
                  >
                    <div class="color-999" data-v-bb874c34="">
                      {{ $t("football.league-details.country") }}
                    </div>
                    <div data-v-bb874c34="">
                      {{
                        teamDetails &&
                        teamDetails.countryDetails &&
                        this.$i18n.locale == "cn"
                          ? teamDetails.countryDetails.cn_name
                          : teamDetails.countryDetails.name
                      }}
                    </div>
                  </div>
                  <div
                    v-if="teamDetails == ''"
                    class="flex justify-center align-center flex-col"
                    style="height: 400px"
                    data-v-4291ef99=""
                  >
                    <img
                      :src="bannerImagePath('no-data.png')"
                      style="width: 123px; height: 74px"
                    />
                    <div class="color-999 fs-12 mt-12">
                      {{ $t("football.live-score.temporarily-no-data") }}
                    </div>
                  </div>
                </div>
                <!-- end::league Info  -->
              </div>
            </div>
            <!-- end::league Info -->
          </div>
        </div>
      </div>
      <!-- end::team squad -->
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  data() {
    return {
      teamDetails: null,
      loading: true,
      teamId: null,
    };
  },
  created() {
    // Accessing the ID parameter from the routes
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getMetaData(itemId);
  },

  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(timestamp) {
      if (timestamp) {
        // Convert Unix timestamp to milliseconds
        var date = new Date(timestamp * 1000);
        var day = date.getDate();
        var month = date.getMonth() + 1; // Months are zero indexed
        var year = date.getFullYear();

        // Pad day and month with leading zeros if necessary
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        return day + "/" + month + "/" + year;
      }
      return "";
    },
  },
};
</script>
