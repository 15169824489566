<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div
    class="ft_players view border-box"
    data-v-a8612f26=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="playerheader" data-v-a8612f26="">
      <!-- begin::header -->
      <div class="backFil" data-v-a8612f26="">
        <div class="header" data-v-54d9a5ac="" data-v-a8612f26="">
          <div class="van-sticky">
            <header
              class="first-header"
              style="background: #033e80"
              data-v-54d9a5ac=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-54d9a5ac=""
              >
                <div
                  @click="goBack"
                  class="left van-col van-col--4"
                  data-v-54d9a5ac=""
                >
                  <span
                    class="icon iconfont myIcon iconback"
                    style="opacity: 0.6"
                    data-v-54d9a5ac=""
                  ></span>
                </div>
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="font-size: 0.4rem"
                  data-v-54d9a5ac=""
                >
                  <span
                    class="formatClass"
                    style="opacity: 1"
                    data-v-54d9a5ac=""
                    ><span data-v-54d9a5ac=""
                      ><span data-v-54d9a5ac=""></span>
                      <span
                        itemprop="startDate"
                        data-v-54d9a5ac=""
                      ></span></span
                  ></span>
                </div>
                <div class="right van-col van-col--4" data-v-54d9a5ac=""></div>
              </div>
            </header>
          </div>
        </div>
      </div>

      <!-- end::header -->
      <!-- begin::player Info -->
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-a8612f26=""
      >
        <div
          class="player_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
          data-v-a8612f26=""
        >
          <img
            :src="
              playerDetails.player && playerDetails.player.id
                ? 'https://sportyclub.online//images/cricket/players/' +
                  playerDetails.player.id +
                  '/' +
                  playerDetails.player.id +
                  '.png'
              : bannerImagePath('default-team.png')
            "
            @error="$event.target.src = bannerImagePath('default-player.png')"
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div class="player_name" data-v-a8612f26="">
          {{
            this.$i18n.locale == "cn" &&
            playerDetails.player &&
            playerDetails.player.cn_name
              ? playerDetails.player.cn_name
              : playerDetails.player.name
          }}
        </div>
        <div class="des flex align-center justify-center" data-v-a8612f26="">
          <div
            class="country_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-a8612f26=""
          >
            <img
              :src="
                playerDetails.player.team.id
                  ? 'https://sportyclub.online//images/cricket/teams/' +
                    playerDetails.player.team.id +
                    '/' +
                    playerDetails.player.team.id +
                    '.png'
                 : bannerImagePath('default-nation.png')
              "
              @error="$event.target.src = bannerImagePath('default-nation.png')"
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span class="country" data-v-a8612f26="">
            {{
              playerDetails &&
              playerDetails.player.team &&
              this.$i18n.locale == "cn" &&
              playerDetails.player.team.cn_name
                ? playerDetails.player.team.cn_name
                : playerDetails.player.team.name
                ? playerDetails.player.team.name
                : ""
            }}
          </span>
        </div>
      </div>
      <!-- end::player Info -->
    </div>
    <div class="content" data-v-a8612f26="">
      <div
        class="player_overview"
        data-v-010cfb63=""
        data-v-a8612f26=""
        style="min-height: 400px"
      >
        <div class="w100" data-v-010cfb63="">
          <!-- begin::player info -->
          <div class="title" data-v-010cfb63="">
            {{ $t("football.league-details.info") }}
          </div>
          <div class="infoContent" data-v-010cfb63="">
            <div class="detail" data-v-010cfb63="">
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.country") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails &&
                      playerDetails.player.country &&
                      playerDetails.player.country.cn_name
                        ? playerDetails.player.country.cn_name
                        : playerDetails.player.country.name
                        ? playerDetails.player.country.name
                        : "-"
                    }}
                  </span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-between dInfo"
                data-v-010cfb63=""
              >
                <div class="key" data-v-010cfb63="">
                  {{ $t("football.league-details.position") }}
                </div>
                <div class="flex align-center" data-v-010cfb63="">
                  <span class="value" data-v-010cfb63="">
                    {{
                      playerDetails &&
                      playerDetails.player &&
                      playerDetails.player.position
                        ? playerDetails.player.position
                        : "-"
                    }}
                  </span>
                </div>
              </div>
    
            </div>
          </div>
          <!-- end::player info -->
          <!-- begin::player profile -->
          <div class="title" data-v-010cfb63="">
            {{ $t("football.league-details.player-info") }}
          </div>
          <div
            class="dec-box"
            data-v-010cfb63=""
            style="padding-bottom: 0.28rem"
          >
            <div
              class="text"
              data-v-010cfb63=""
              style="max-height: fit-content"
              v-html="playerContents.about"
            ></div>
          </div>
          <!-- end::player player -->
        </div>
      </div>
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],


  data() {
    return {
      playerDetails: null,
      loading: true,
      playerId: null,
      playerContents: {
        about: "",
        description: "",
        heading: "",
        title: "",
      },
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.playerId;
    this.playerId = itemId;
    this.getPlayerDetails(itemId);
    this.getPlayerContents(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to get hot leagues list
    getPlayerDetails(playerId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/player/details/${playerId}`
        )
        .then((response) => {
          this.playerDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get player contents
    getPlayerContents(playerId) {
      console.log(playerId);
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/player/contents/${playerId}`
        )
        .then((response) => {
          this.playerContents = response.data.content;
          if (this.playerContents.about) {
            // Replace <a> tags with <b> tags
            this.playerContents.about = this.playerContents.about
              // Replace <a> tags with <b> tags
              .replace(/<a\b[^>]*>(.*?)<\/a>/gi, "<b>$1</b>")
              // Replace "Sofascore" with "Sportyclub Live" (case-insensitive)
              .replace(/sofascore/gi, "Sportyclub Live");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormattedDate(timeStamp) {
      if (timeStamp != "") {
        const date = new Date(timeStamp);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      }
      return "-";
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
