<template>
  <span v-if="!loading">
    <div data-v-417eeb1a="" class="hot-comps">
      <div data-v-417eeb1a="" class="title">
        {{ $t("football.live-score.popular") }}
      </div>
      <a
        v-for="hotLeague in hotLeaguesList"
        :key="hotLeague.id"
        data-v-417eeb1a=""
        :href="
          '/' +
          this.$i18n.locale +
          '/football/league/details/' +
          hotLeague.nameEn.toLowerCase().replace(/\s/g, '-') +
          '/' +
          hotLeague.id
        "
        target="_blank"
        class="comps-item"
        ><span data-v-417eeb1a="" class="country-logo"
          ><img data-v-417eeb1a="" :src="hotLeague.logo + '!w30'"
        /></span>
        <span
          data-v-417eeb1a=""
          title="UEFA Champions League"
          class="ml-xs w-o-h"
          >
          
          
          {{
            this.$i18n.locale == "cn" ? hotLeague.nameCn : hotLeague.nameEn
          }}
          
          
          </span
        ></a
      >
    </div>
  </span>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      hotLeaguesList: null,
      leagueLoading: true,
    };
  },
  created() {
    this.gethotLeaguesList();
  },
  methods: {
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.footballHotLeaguesList;
          this.leagueLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
