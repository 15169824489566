<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-59710436="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-59710436="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-59710436="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name
                  : leagueDetails.name
              }}
              {{ $t("football.league-details.teams-stats") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <!-- begin::loading area -->
          <div
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
            v-if="loading"
          >
            <img src="/images/default-loader.gif" alt="" style="width: 20%" />
          </div>
          <!-- end::loading area -->
          <span v-if="!loading">
            <!-- begin::display loading area -->
            <div
              class="SquadStats pl-15 pr-15 pb-24"
              data-v-37d31aab=""
              data-v-59710436=""
              data-v-7c947e55=""
              v-if="
                teamsPropertyStats &&
                teamsPropertyStats.teamsStats &&
                teamsPropertyStats.teamsStats != ''
              "
            >
              <div class="mt-8 flex justify-between" data-v-37d31aab="">
                <div
                  class="el-select select-transfers-year el-select--mini"
                  style="width: 120px"
                  data-v-37d31aab=""
                ></div>
              </div>
              <div class="table mt-8" data-v-37d31aab="">
                <div
                  class="head flex justify-between align-center color-999 border-box fs-12"
                  data-v-37d31aab=""
                >
                  <div class="col" style="width: 116px" data-v-37d31aab="">
                    &nbsp;
                  </div>
                  <div
                    class="col flex-1 justify-start pl-15 border-box"
                    data-v-37d31aab=""
                  >
                    #
                    <span style="margin-left: 48px" data-v-37d31aab="">{{
                      $t("football.league-details.players")
                    }}</span>
                  </div>
                  <div data-v-37d31aab="" class="col flex-1">
                    {{ $t("football.league-details.goals") }}
                  </div>
                </div>
                <div
                  class="body flex fs-12 color-333 align-start"
                  data-v-37d31aab=""
                >
                  <div
                    class="flex flex-wrap"
                    style="width: 116px"
                    data-v-37d31aab=""
                  >
                    <!-- toggleTopPlayers -->
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'points'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('points')"
                    >
                      {{ $t("football.league-details.total-points") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer active"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'assists'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('assists')"
                    >
                      {{ $t("football.league-details.assists") }}
                    </div>

                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'minutes_played'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('minutes_played')"
                    >
                      {{ $t("football.league-details.minutes_played") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'red_cards'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('rebounds')"
                    >
                      {{ $t("football.league-details.rebounds") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'turnovers'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('turnovers')"
                    >
                      {{ $t("football.league-details.turnovers") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'steals'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('steals')"
                    >
                      {{ $t("football.league-details.steals") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'blocks'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('blocks')"
                    >
                      {{ $t("football.league-details.blocks") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'personal_fouls'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('personal_fouls')"
                    >
                      {{ $t("football.league-details.fouls") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'scope'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('scope')"
                    >
                      {{ $t("football.league-details.scope") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'court'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('court')"
                    >
                      {{ $t("football.league-details.court") }}
                    </div>
                    <div
                      class="col color-p w-bar-100 row cur-pointer"
                      style="height: 48px; background: rgb(242, 248, 252)"
                      :style="
                        activeTab == 'first'
                          ? 'background:rgb(225, 243, 255)'
                          : 'background: rgb(242, 248, 252)'
                      "
                      data-v-37d31aab=""
                      v-on:click="toggleTopPlayers('first')"
                    >
                      {{ $t("football.league-details.first") }}
                    </div>
                  </div>
                  <div class="flex-1 align-start" data-v-37d31aab="">
                    <div
                      class="flex flex-wrap align-start"
                      data-v-37d31aab=""
                      v-for="(property, index) in teamsPropertyStats.teamsStats"
                      :key="property.id"
                    >
                      <div
                        data-v-37d31aab=""
                        class="row flex w-bar-100"
                        v-if="index < 10"
                      >
                        <a
                          target="_blank"
                          data-v-37d31aab=""


                          :href="
                              '/' +
                              this.$i18n.locale +
                              '/basketball/team/details/' +
                              property.team.name
                                .toLowerCase()
                                .replace(/\s/g, '-') +
                              '/' +
                              property.team.id
                            "



                   
                          class="col cur-pointer justify-start flex-1 pl-15 border-box"
                          ><span data-v-37d31aab="" class="number color-999">{{
                            ++index
                          }}</span>
                          <span
                            data-v-37d31aab=""
                            class="player-logo mr-xs ml-15"
                          >
                            <img
                              data-v-37d31aab=""
                              :src="
                                property && property.team && property.team.logo
                                  ? property.team.logo + '!w40'
                                  : bannerImagePath('default-player.png')
                              "
                            />
                          </span>
                          <span data-v-37d31aab="" class="flex flex-col fs-12"
                            ><span data-v-37d31aab="" class="color-333">
                              {{
                                this.$i18n.locale == "cn" &&
                                property &&
                                property.team &&
                                property.team.cn_name
                                  ? property.team.cn_name
                                  : property.team.name
                              }}
                            </span>
                          </span></a
                        >
                        <div
                          data-v-37d31aab=""
                          class="col flex-1 color-p font-500"
                        >
                          {{ property[activeTab] }}

                          {{
                            activeTab == "goals" && property.penalty != 0
                              ? "(" + property.penalty + ")"
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end::display loading area -->
            <!--begin:: if data is  not available -->
            <div
              class="flex justify-center align-center flex-col"
              style="height: 500px"
              v-else
            >
              <img :src="bannerImagePath('default-container.png')" />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
            <!-- end::if data is  not available -->
          </span>
        </div>
      </div>
      <div class="p-r" data-v-59710436="">
        <div class="infoBox" data-v-df2a923e="" data-v-5021e98f="">
          <div class="fs-12 color-999 pl-15 pr-15" data-v-df2a923e="">
            <div class="padTop">
              {{ $t("football.league-details.league-info") }}
            </div>
          </div>
          <div
            style="width: 100%; padding: 0 16px; box-sizing: border-box"
            data-v-df2a923e=""
            v-if="
              leagueDetails &&
              leagueDetails.curr_season_details &&
              leagueDetails.curr_season_details.has_player_stats == 1
            "
          >
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.players") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.totalPlayers }}
              </div>
            </div>
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.teams") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.totalTeams }}
              </div>
            </div>
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.court") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.court }}
              </div>
            </div>
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.points") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.points }}
              </div>
            </div>
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.rebounds") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.rebounds }}
              </div>
            </div>
            <div class="factItem" style="display: " data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.assists") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.assists }}
              </div>
            </div>
            <div class="factItem" data-v-df2a923e="">
              <div class="fact_text" data-v-df2a923e="">
                {{ $t("football.league-details.blocks") }}
              </div>
              <div class="fact_value" data-v-df2a923e="">
                {{ leagueDetails.blocks }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="flex justify-center align-center flex-col"
            style="height: 400px"
            data-v-4291ef99=""
          >
            <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      activeTab: "points",
      teamsPropertyStats: null,

      loading: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;

    this.geTeamStats(this.leagueId, this.activeTab);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.activeTab = status;
      this.geTeamStats(this.leagueId, this.activeTab);
    },
    geTeamStats(leagueId, property) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/league/season/teamsStats/${leagueId}?teamProperty=${property}`
        )
        .then((response) => {
          this.teamsPropertyStats = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
