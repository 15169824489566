<template>
  <div
    class="inner-temp"
    style="border-radius: 0px"
    data-v-d9dc0c56=""
    data-v-6ad89ef3=""
    v-if="!loading"
  >
    <div class="flex" data-v-d9dc0c56="">
      <div class="border-lt active" data-v-d9dc0c56="">
        {{ $t("football.live-score.today-live-matches") }}
      </div>

      <div
        class="flex-1"
        style="border-left: 1px solid #eeeeee; border-bottom: 1px solid #eee"
        data-v-d9dc0c56=""
      ></div>
    </div>
    <ul gutter="20" class="data-box" data-v-d9dc0c56="">
      <template v-for="league in todayMatchList" :key="league.id">
        <li
          style="width: 20%"
          data-v-d9dc0c56=""
          v-for="match in league"
          :key="match.id"
        >
          <div class="mr-20" data-v-d9dc0c56="">
            <a
            :href="
              '/' +
              this.$i18n.locale +
              '/football/match/details/' +
              match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') + '-vs-' +
              match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') + '/' +
              match.id
            "
              target="_blank"
              class="link"
              data-v-d9dc0c56=""
            >
              <template v-if="match && match.homeInfo">
                {{
                  this.$i18n.locale == "cn" && match.homeInfo.cn_name
                    ? match.homeInfo.cn_name
                    : match.homeInfo.en_name
                    ? match.homeInfo.en_name
                    : ""
                }}
              </template>

              VS
              <template v-if="match && match.awayInfo">
                {{
                  this.$i18n.locale == "cn" && match.awayInfo.cn_name
                    ? match.awayInfo.cn_name
                    : match.awayInfo.en_name
                    ? match.awayInfo.en_name
                    : ""
                }}
              </template>
            </a>
          </div>
        </li>
      </template>
    </ul>
    <!---->
  </div>
</template>
<script>
export default {
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      todayMatchList: null,
      loading: false,
      liveActivebtn: "live",
    };
  },
  created() {
    this.getTodayLiveMatches("live");
  },
  methods: {
    // method to toggle dropdown

    getTodayLiveMatches(status) {
      this.loading = true;
      this.liveActivebtn = status;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.footballMatchList;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
