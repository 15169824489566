<template>
  <div
    class="football-index-page view border-box theme-init"
    data-v-d4c6fef0=""
  >
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: #7a7d8a; padding-left: 0"
      data-v-d4c6fef0=""
    >
      <span class="el-breadcrumb__item" data-v-d4c6fef0=""
        ><span role="link" class="el-breadcrumb__inner"
          ><a
            href=""
            target="_blank"
            style="cursor: pointer"
            data-v-d4c6fef0=""
          >
          </a></span
        ><span role="presentation" class="el-breadcrumb__separator"></span
      ></span>
    </div>
    <div class="page-content-box" style="width: " data-v-d4c6fef0="">
      <div class="flex" data-v-d4c6fef0="">
        <div
          class="leftSkeleton"
          data-v-2821657e=""
          data-v-417eeb1a=""
          data-v-d4c6fef0=""
        >
          <div class="skeleton skeleton1" data-v-2821657e="">
            <div class="el-skeleton is-animated">
              <div class="el-skeleton__item el-skeleton__p is-first"></div>
            </div>
          </div>
          <div class="skeleton skeleton2" data-v-2821657e="">
            <div class="el-skeleton is-animated">
              <div class="el-skeleton__item el-skeleton__p is-first"></div>
            </div>
          </div>
          <div class="skeleton skeleton3" data-v-2821657e="">
            <div class="el-skeleton is-animated">
              <div class="el-skeleton__item el-skeleton__p is-first"></div>
            </div>
          </div>
          <div class="skeleton skeleton2" data-v-2821657e="">
            <div class="el-skeleton is-animated">
              <div class="el-skeleton__item el-skeleton__p is-first"></div>
            </div>
          </div>
          <div class="skeleton skeleton4" data-v-2821657e="">
            <div class="el-skeleton is-animated">
              <div class="el-skeleton__item el-skeleton__p is-first"></div>
            </div>
          </div>
        </div>
        <div
          class="right-box rightBox"
          data-v-d4c6fef0=""
          :style="
            toggleBannerState
              ? this.$i18n.locale === 'cn'
                ? 'width: 130%;'
                : 'width: 117%;'
              : ''
          "
        >
          <!-- begin::banner -->
          <div
            class="bannerBox"
            data-v-d4c6fef0=""
            :style="toggleBannerState ? 'display:none' : 'display:block'"
          >
            <img
              :src="bannerImagePath('general-banner.png')"
              alt="#"
              class="banner"
              data-v-d4c6fef0=""
            />
            <i
              class="iconfont icon-guanbi close"
              data-v-d4c6fef0=""
              v-on:click="toggleBanner(state)"
            ></i>
          </div>
          <!-- end::banner -->
          <div class="right-box" data-v-d4c6fef0="">
            <div class="screen-header" style="z-index: 2" data-v-d4c6fef0="">
              <div class="changTabBox" data-v-d4c6fef0="">
                <span class="changeItem bh notActiveTab" data-v-d4c6fef0="">
                  {{ $t("football.live-score.all") }}
                </span>
                <span
                  class="changeItem bh ml-12 activeElsTab"
                  data-v-d4c6fef0=""
                  ><span class="liveContentBox" data-v-d4c6fef0=""
                    ><i
                      class="iconfont icon-jinhangzhong liveImg activeLive"
                      data-v-d4c6fef0=""
                    ></i>
                    {{ $t("football.live-score.live") }}
                  </span></span
                >
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                >
                  {{ $t("football.live-score.finished") }}
                </span>
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                >
                  {{ $t("football.live-score.soon") }}
                </span>
              </div>
            </div>
            <div class="listSkeleton" data-v-05a0b0c9="" data-v-d4c6fef0="">
              <div data-v-05a0b0c9="">
                <div class="el-skeleton1 el-skeleton3" data-v-05a0b0c9="">
                  <div class="el-skeleton is-animated">
                    <div
                      class="el-skeleton__item el-skeleton__p is-first"
                    ></div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-05a0b0c9="">
                <div class="el-skeleton1 el-skeleton3" data-v-05a0b0c9="">
                  <div class="el-skeleton is-animated">
                    <div
                      class="el-skeleton__item el-skeleton__p is-first"
                    ></div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-05a0b0c9="">
                <div class="el-skeleton1 el-skeleton3" data-v-05a0b0c9="">
                  <div class="el-skeleton is-animated">
                    <div
                      class="el-skeleton__item el-skeleton__p is-first"
                    ></div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div
                        class="el-skeleton__item el-skeleton__p is-first"
                      ></div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-v-05a0b0c9="">
                <div class="el-skeleton1 el-skeleton3" data-v-05a0b0c9="">
                  <div class="el-skeleton is-animated">
                    <div class="el-skeleton__item el-skeleton__p is-first">
                      <!---->
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="el-skeletonItem" data-v-05a0b0c9="">
                  <div class="el-skeletonType el-skeleton4" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton5" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton6" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton7" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton8" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton9" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                  <div class="el-skeletonType el-skeleton10" data-v-05a0b0c9="">
                    <div class="el-skeleton is-animated">
                      <div class="el-skeleton__item el-skeleton__p is-first">
                        <!---->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  data() {
    return {
      toggleBannerState: false,
    };
  },
};
</script>
