<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <div class="clearfix-row view border-box" data-v-e88f578c="">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-e88f578c="">
      <div class="content-box" data-v-e88f578c="">
        <ul class="breadcrumb" data-v-e88f578c="">
          <li data-v-e88f578c="">
            <a
              class="link"
              data-v-e88f578c=""
              :href="'/' + this.$i18n.locale + '/live/cricket'"
            >
              标题
            </a>
          </li>

          <li>
            <h1 class="text" data-v-e88f578c="">
              {{
                !loading
                  ? playerDetails &&
                    playerDetails.player &&
                    playerDetails.player.name
                    ? playerDetails.player.name
                    : "player"
                  : ""
              }}
              stats and transfer history
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="player" data-v-e88f578c="" v-if="!loading">
      <div class="player-header" data-v-04359244="" data-v-e88f578c="">
        <div class="content border-box flex align-center" data-v-04359244="">
          <div class="p-l flex-1 flex align-center" data-v-04359244="">
            <div
              class="flex align-center"
              style="height: 88px; width: 430px"
              data-v-04359244=""
            >
              <img
                :src="
                  playerDetails.player.id
                    ? 'https://sportyclub.online//images/cricket/players/' +
                      playerDetails.player.id +
                      '/' +
                      playerDetails.player.id +
                      '.png'
                    : bannerImagePath('default-team.png')
                "
                @error="
                  $event.target.src = bannerImagePath('default-player.png')
                "
                class="player-logo"
                data-v-04359244=""
              />
              <div class="pl-15" data-v-04359244="">
                <div class="fs-24 font-500 flex" data-v-04359244="">
                  <span data-v-04359244="">
                    {{ playerDetails.player.name }}
                  </span>
                  <span
                    class="flex justify-center align-center mr-12"
                    data-v-04359244=""
                  >
                  </span>
                </div>
                <div class="mt-8 flex align-center" data-v-04359244="">
                  <div class="country-logo" data-v-04359244="">
                    <img
                      :src="
                        playerDetails.player.team.id
                          ? 'https://sportyclub.online//images/cricket/teams/' +
                            playerDetails.player.team.id +
                            '/' +
                            playerDetails.player.team.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src =
                          bannerImagePath('default-player.png')
                      "
                      data-v-04359244=""
                    />
                  </div>
                  <span class="ml-xs fs-14 color-666" data-v-04359244="">
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/team/details/' +
                        playerDetails.player.team.name
                          .toLowerCase()
                          .replace(/\s/g, '-') +
                        '/' +
                        playerDetails.player.team.id
                      "
                    >
                      {{ playerDetails.player.team.name }}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-04359244=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ $t("football.league-details.player-info") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.height") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails &&
                    playerDetails.player &&
                    playerDetails.player.height
                      ? playerDetails.player.height
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div
                  class="color-666 font-300 mb-xxs"
                  style="transform-origin: left"
                  data-v-5ea21537=""
                >
                  {{ $t("football.league-details.jersey-no") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails &&
                    playerDetails.player &&
                    playerDetails.player.jerseyNumber
                      ? playerDetails.player.jerseyNumber
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.retired") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{ playerDetails.player.retired ? "Yes" : "No" }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.position") }}
                </div>
                <div class="value font-500 color-p" data-v-5ea21537="">
                  {{
                    playerDetails &&
                    playerDetails.player &&
                    playerDetails.player.position
                      ? playerDetails.player.position
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-bar" data-v-440f15d6="" data-v-e88f578c="">
        <div class="content-box" data-v-440f15d6="">
          <div class="child" data-v-440f15d6="">
            <a class="tab active" style="display: " data-v-440f15d6="">
              {{ $t("football.league-details.overview") }}
            </a>
          </div>
        </div>
      </div>
      <div class="wrapper" data-v-e88f578c="">
        <div class="flex" data-v-771033de="" data-v-e88f578c="">
          <div class="p-l flex-1" data-v-771adb1a="">
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <!-- begin::home squad -->
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <div class="body" data-v-0939e9fc="">
                    <div
                      class="flex justify-center align-center flex-col"
                      data-v-975a1620=""
                      style="height: 300px"
                    >
                      <img :src="bannerImagePath('no-data.png')" />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end home squad -->
            </div>
          </div>
          <div class="p-r" data-v-771033de="">
            <div class="container-box" data-v-7c947e55="" data-v-771033de="">
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ playerDetails.player.name }}
                </span>
                <span class="color-999 fs-12" data-v-7c947e55=""></span>
              </div>

              <div
                class="f-s-12 color-666 line-h-16 ml-15 mr-15 mt-15 pb-20 mt-20"
                data-v-7c947e55=""
                data-v-771033de=""
              >
                <span v-if="this.$i18n.locale === 'cn'">
                  {{ playerDetails.player.name }} 统计数据可在
                  现场比赛以及每场比赛结束后。
                  {{ playerDetails.player.name }} 统计信息可用于 Sportyclub
                  直播部分比赛。
                </span>

                <span v-else>
                  {{ playerDetails.player.name }} statistics are available
                  during live games and after every game played.
                  {{ playerDetails.player.name }} statistics may be available on
                  Sportyclub Live for some of the games.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.playerId;
    this.playerInfo(itemId);
    this.getMetaData(itemId);
    this.getPlayerContentss(itemId);
  },

  data() {
    return {
      playerDetails: null,
      loading: true,
      metaData: null,

      playerContents: {
        about: "",
        description: "",
        heading: "",
        title: "",
      },
    };
  },
  methods: {
    // method to get league h2h
    playerInfo(playerId) {
      console.log(playerId);
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/player/details/${playerId}`)
        .then((response) => {
          this.playerDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get player contents
    getPlayerContentss(playerId) {
      console.log(playerId);
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/player/contents/${playerId}`)
        .then((response) => {
          this.playerContents = response.data.content;
          if (this.playerContents.about) {
            // Replace <a> tags with <b> tags
            this.playerContents.about = this.playerContents.about
              // Replace <a> tags with <b> tags
              .replace(/<a\b[^>]*>(.*?)<\/a>/gi, "<b>$1</b>")
              // Replace "Sofascore" with "Sportyclub Live" (case-insensitive)
              .replace(/sofascore/gi, "Sportyclub Live");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMetaData(playerId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/player/meta/` + playerId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
