<template>
  <div data-v-94567e5a="" class="hotComps pr-12 mb-12 pb-15">
    <div data-v-94567e5a="" class="title font-500">
      {{ $t("football.live-score.popular") }}
    </div>
    <div data-v-94567e5a="" style="width: 100%">
      <a
        v-for="hotLeague in hotLeaguesList"
        :key="hotLeague.id"
        data-v-94567e5a=""
        target="_blank"
        :href="
          '/' +
          this.$i18n.locale +
          '/baseball/league/details/' +
          hotLeague.nameEn.toLowerCase().replace(/\s/g, '-') +
          '/' +
          hotLeague.id
        "
        class="comps-item"
        ><span data-v-94567e5a="" class="country-logo"
          ><img
            data-v-94567e5a=""
            :src="
              hotLeague.logo
                ? hotLeague.logo + '!w30'
                : bannerImagePath('default-nation.png')
            "
            alt="baseball Tournament"
            title="baseball Tournament"
        /></span>
        <span
          data-v-94567e5a=""
          title="baseball Tournament"
          class="ml-xs w-o-h"
          >{{
            this.$i18n.locale == "cn" && hotLeague.nameCn
              ? hotLeague.nameCn
              : hotLeague.nameEn
              ? hotLeague.nameEn
              : ""
          }}</span
        ></a
      >
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],

  data() {
    return {
      hotLeaguesList: null,
    };
  },
  created() {
    this.gethotLeaguesList();
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.baseballHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
