<template>
  <div class="content" data-v-59f3daaa="">
    <div
      v-if="loading || playerStatsloading || teamStatsloading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="top: 10rem"
      data-v-59f3daaa=""
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-59f3daaa=""
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
      ></span>
    </div>
    <div
      v-else-if="!loading && !playerStatsloading && !teamStatsloading"
      class="bk_tour_overview"
      data-v-812788c4=""
      data-v-59f3daaa=""
    >
      <div class="w100" data-v-812788c4="">
        <div class="Schedule" data-v-812788c4="">
          <div
            class="title flex align-center justify-between"
            data-v-812788c4=""
          >
            <a class="text" data-v-812788c4="">{{
              $t("football.league-details.standings")
            }}</a>
          </div>

          <div class="w100" data-v-812788c4="">
            <div
              v-if="!leagueStandings"
              class="basketball_standing"
              style="padding-bottom: 0"
              data-v-8808ea54=""
              data-v-812788c4=""
            >
              <div data-v-8808ea54="" style="width: 100%">
                <div data-v-8808ea54="" class="w100">
                  <div data-v-8808ea54="" class="dataName">
                    <span data-v-8808ea54="" class="name flex align-center"
                      ><div
                        data-v-8808ea54=""
                        class="comp_logo van-image"
                        itemprop="logo"
                        style="overflow: hidden; border-radius: 0px"
                      >
                        <img
                          :src="
                            leagueDetails && leagueDetails.logo
                              ? leagueDetails.logo + '!w100'
                             : bannerImagePath('default-nation.png')
                          "
                          :alt="
                            leagueDetails && leagueDetails.name
                              ? leagueDetails.name
                              : ''
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                    </span>
                  </div>
                  <div data-v-8808ea54="" class="tableTitle">
                    <div
                      data-v-8808ea54=""
                      class="t1 font-400"
                      style="box-sizing: border-box; width: 5rem"
                    >
                      <span data-v-8808ea54="" class="index">#</span>
                      <span data-v-8808ea54="" style="margin-left: 0.21rem">
                        {{ $t("football.league-details.team") }}
                      </span>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        P
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        W
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        L
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        D
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        {{ $t("football.league-details.points") }}
                      </div>
                    </div>
                  </div>
                  <div data-v-8808ea54="" class="contentBox">
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 400px"
                      data-v-4291ef99=""
                    >
                      <img
                        :src="bannerImagePath('no-data.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              data-v-8808ea54=""
              data-v-06d4278f=""
              class="basketball_standing"
              style=""
            >
              <div data-v-8808ea54="" style="width: 100%">
                <div
                  data-v-8808ea54=""
                  class="w100"
                  v-for="standingDetail in leagueStandings"
                  :key="standingDetail.id"
                >
                  <div data-v-8808ea54="" class="dataName">
                    <span data-v-8808ea54="" class="name flex align-center"
                      ><div data-v-8808ea54="">
                        {{ $t("football.league-details.group") }}
                        {{ standingDetail.group }}
                      </div>
                    </span>
                  </div>

                  <div data-v-8808ea54="" class="tableTitle">
                    <div
                      data-v-8808ea54=""
                      class="t1 font-400"
                      style="box-sizing: border-box; width: 5rem"
                    >
                      <span data-v-8808ea54="" class="index">#</span>
                      <span data-v-8808ea54="" style="margin-left: 0.21rem">
                        {{ $t("football.league-details.team") }}
                      </span>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        T
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        W
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        L
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        D
                      </div>
                    </div>
                    <div
                      data-v-8808ea54=""
                      class="font-400"
                      style="position: relative; flex: 1 1 0%"
                    >
                      <div
                        data-v-8808ea54=""
                        class="text-center"
                        style="width: 100%"
                      >
                        {{ $t("football.league-details.points") }}
                      </div>
                    </div>
                  </div>
                  <div data-v-8808ea54="" class="contentBox">
                    <div
                      v-for="(rowDetails, index) in standingDetail.rows"
                      :key="index"
                      data-v-8808ea54=""
                      class="w100 flex align-center cbox list_active2"
                    >
                      <div
                        data-v-8808ea54=""
                        class="flex align-center contentTeamBox"
                      >
                        <div data-v-8808ea54="" class="teamIndex">
                          {{ rowDetails?.position }}
                        </div>
                        <div
                          data-v-8808ea54=""
                          class="teamImg van-image van-image--round"
                          style="overflow: hidden; border-radius: 50%"
                        >
                          <img
                            :src="
                              rowDetails.teamInfo && rowDetails.teamInfo.logo
                                ? rowDetails.teamInfo.logo + '!w30'
                              : bannerImagePath('default-team.png')
                            "
                            :alt="rowDetails.teamInfo.short_name"
                            class="van-image__img"
                            style="object-fit: contain"
                          />
                        </div>
                        <a
                          data-v-8808ea54=""
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            rowDetails.teamInfo.name
                              .toLowerCase()
                              .replace(/\s/g, '-') +
                            '/' +
                            rowDetails.team_id
                          "
                          class="teamName van-ellipsis"
                          style="margin-left: 0.21rem"
                        >
                          {{ rowDetails.teamInfo.name }}
                        </a>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 flex">
                        <div data-v-8808ea54="" class="flex-1 text-center">
                          <div data-v-8808ea54="" class="detailData">
                            {{ rowDetails?.total }}
                          </div>
                        </div>
                        <div data-v-8808ea54="" class="flex-1 text-center">
                          <div data-v-8808ea54="" class="detailData">
                            {{ rowDetails?.won }}
                          </div>
                        </div>
                        <div data-v-8808ea54="" class="flex-1 text-center">
                          <div data-v-8808ea54="" class="detailData">
                            {{ rowDetails?.loss }}
                          </div>
                        </div>
                        <div data-v-8808ea54="" class="flex-1 text-center">
                          <div data-v-8808ea54="" class="detailData">
                            {{ rowDetails?.draw }}
                          </div>
                        </div>
                        <div data-v-8808ea54="" class="flex-1 text-center">
                          <div data-v-8808ea54="" class="detailData">
                            {{ rowDetails?.points }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Schedule" data-v-812788c4="">
          <div
            class="title flex align-center justify-between"
            data-v-812788c4=""
          >
            <a class="text" data-v-812788c4="">{{
              $t("football.league-details.top-players")
            }}</a>
          </div>
          <div class="w100" data-v-812788c4="">
            <div
              class="bk_stats w100"
              style="padding-bottom: 0"
              data-v-44fa5b68=""
              data-v-812788c4=""
            >
              <div class="bk_statscomp" data-v-042072c0="" data-v-460a6726="">
                <div
                  v-for="(propertyObject, index) in playerStatsTeamProperties"
                  :key="index"
                  class="w100 items"
                  style=""
                  data-v-042072c0=""
                >
                  <div class="w100 text-center title" data-v-042072c0="">
                    {{ $t("football.league-details." + propertyObject.name) }}
                  </div>
                  <template v-if="!leaguePlayersPropertiesStats[index].data">
                    <div
                      class="flex justify-center align-center flex-col"
                      data-v-4291ef99=""
                    >
                      <img
                        :src="bannerImagePath('no-data.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="!propertyObject.viewAll">
                    <div
                      v-for="teamIndex in 3"
                      :key="teamIndex"
                      class="w100 flex align-center justify-between list_active2"
                      data-v-042072c0=""
                    >
                      <div class="des" data-v-042072c0="">
                        <div
                          class="ind"
                          :class="
                            teamIndex == 1
                              ? 'first'
                              : teamIndex == 2
                              ? 'second'
                              : 'third'
                          "
                          data-v-042072c0=""
                        >
                          {{ teamIndex }}
                        </div>
                        <div
                          itemprop="logo"
                          class="myImg van-image van-image--round"
                          style="overflow: hidden; border-radius: 50%"
                          data-v-042072c0=""
                        >
                          <img
                            :src="
                              leaguePlayersPropertiesStats[index].data
                                .playersStats[teamIndex - 1]?.player?.logo
                            "
                            alt=""
                            class="van-image__img"
                            style="object-fit: cover"
                          />
                        </div>
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            leaguePlayersPropertiesStats[index].data
                              .playersStats[teamIndex - 1]?.player?.name +
                            '/' +
                            leaguePlayersPropertiesStats[index].data
                              .playersStats[teamIndex - 1]?.player?.id
                          "
                          class="flex"
                          data-v-042072c0=""
                          ><div class="playerName" data-v-042072c0="">
                            {{
                              this.$i18n.locale == "cn" &&
                              leaguePlayersPropertiesStats[index].data
                                .playersStats[teamIndex - 1].player.cn_name
                                ? leaguePlayersPropertiesStats[index].data
                                    .playersStats[teamIndex - 1].player.cn_name
                                : leaguePlayersPropertiesStats[index].data
                                    .playersStats[teamIndex - 1].player.name
                            }}
                          </div>
                        </a>
                      </div>
                      <div class="num" data-v-042072c0="">
                        {{
                          leaguePlayersPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ][propertyObject.property]
                        }}
                      </div>
                    </div>

                    <div class="w100 viewall" data-v-042072c0="">
                      <div
                        @click="toggleViewAll(index)"
                        class="viewallBtn"
                        data-v-042072c0=""
                      >
                        {{ $t("football.league-details.show-more") }} &gt;
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-for="teamIndex in 15"
                      :key="teamIndex"
                      class="w100 flex align-center justify-between list_active2"
                      data-v-042072c0=""
                    >
                      <div class="des" data-v-042072c0="">
                        <div
                          class="ind"
                          :class="
                            teamIndex == 1
                              ? 'first'
                              : teamIndex == 2
                              ? 'second'
                              : 'third'
                          "
                          data-v-042072c0=""
                        >
                          {{ teamIndex }}
                        </div>
                        <div
                          itemprop="logo"
                          class="myImg van-image van-image--round"
                          style="overflow: hidden; border-radius: 50%"
                          data-v-042072c0=""
                        >
                          <img
                            :src="
                              leaguePlayersPropertiesStats[index].data
                                .playersStats[teamIndex - 1].player.logo
                            "
                            alt=""
                            class="van-image__img"
                            style="object-fit: cover"
                          />
                        </div>
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            leaguePlayersPropertiesStats[index].data
                              .playersStats[teamIndex - 1].player.name +
                            '/' +
                            leaguePlayersPropertiesStats[index].data
                              .playersStats[teamIndex - 1].player.id
                          "
                          class="flex"
                          data-v-042072c0=""
                          ><div class="playerName" data-v-042072c0="">
                            {{
                              this.$i18n.locale == "cn" &&
                              leaguePlayersPropertiesStats[index].data
                                .playersStats[teamIndex - 1].player.cn_name
                                ? leaguePlayersPropertiesStats[index].data
                                    .playersStats[teamIndex - 1].player.cn_name
                                : leaguePlayersPropertiesStats[index].data
                                    .playersStats[teamIndex - 1].player.name
                            }}
                          </div>
                        </a>
                      </div>
                      <div class="num" data-v-042072c0="">
                        {{
                          leaguePlayersPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ][propertyObject.property]
                        }}
                      </div>
                    </div>
                    <div class="w100 viewall" data-v-042072c0="">
                      <div
                        @click="toggleViewAllPlayer(index)"
                        class="viewallBtn"
                        data-v-042072c0=""
                      >
                        {{ $t("football.league-details.show-less") }} &lt;
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Schedule" data-v-812788c4="">
          <div
            class="title flex align-center justify-between"
            data-v-812788c4=""
          >
            <a class="text" data-v-812788c4="">{{
              $t("football.league-details.team-stats")
            }}</a>
          </div>
          <div class="w100" data-v-812788c4="">
            <div class="bk_stats w100" data-v-460a6726="" data-v-59f3daaa="">
              <div class="bk_statscomp" data-v-042072c0="" data-v-460a6726="">
                <div
                  v-for="(propertyObject, index) in teamProperties"
                  :key="index"
                  class="w100 items"
                  style=""
                  data-v-042072c0=""
                >
                  <div class="w100 text-center title" data-v-042072c0="">
                    {{ $t("football.league-details." + propertyObject.name) }}
                  </div>
                  <template
                    v-if="
                      (leagueTeamsPropertiesStats[index].data
                        .teamsStats instanceof Array &&
                        leagueTeamsPropertiesStats[index].data.teamsStats
                          .length == 0) ||
                      !leagueTeamsPropertiesStats[index].data
                    "
                  >
                    <div
                      class="flex justify-center align-center flex-col"
                      data-v-4291ef99=""
                    >
                      <img
                        :src="bannerImagePath('no-data.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </template>
                  <template v-else-if="!propertyObject.viewAll">
                    <div
                      v-for="teamIndex in 3"
                      :key="teamIndex"
                      class="w100 flex align-center justify-between list_active2"
                      data-v-042072c0=""
                    >
                      <div
                        v-if="
                          leagueTeamsPropertiesStats &&
                          leagueTeamsPropertiesStats[index] &&
                          leagueTeamsPropertiesStats[index].data &&
                          leagueTeamsPropertiesStats[index].data.teamsStats
                        "
                        class="des"
                        data-v-042072c0=""
                      >
                        <div
                          class="ind"
                          :class="
                            teamIndex == 1
                              ? 'first'
                              : teamIndex == 2
                              ? 'second'
                              : 'third'
                          "
                          data-v-042072c0=""
                        >
                          {{ teamIndex }}
                        </div>
                        <div
                          itemprop="logo"
                          class="myImg van-image van-image--round"
                          style="overflow: hidden; border-radius: 50%"
                          data-v-042072c0=""
                        >
                          <img
                            :src="
                              leagueTeamsPropertiesStats[index].data.teamsStats[
                                teamIndex - 1
                              ].team.logo
                            "
                            alt="Hamamatsu Higashimikawa"
                            class="van-image__img"
                            style="object-fit: cover"
                          />
                        </div>
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            leagueTeamsPropertiesStats[index].data.teamsStats[
                              teamIndex - 1
                            ].team.name
                              .toLowerCase()
                              .replace(/\s/g, '-') +
                            '/' +
                            leagueTeamsPropertiesStats[index].data.teamsStats[
                              teamIndex - 1
                            ].team.id
                          "
                          class="flex"
                          data-v-042072c0=""
                          ><div class="playerName" data-v-042072c0="">
                            {{
                              this.$i18n.locale == "cn" &&
                              leagueTeamsPropertiesStats[index].data.teamsStats[
                                teamIndex - 1
                              ].team.cn_name
                                ? leagueTeamsPropertiesStats[index].data
                                    .teamsStats[teamIndex - 1].team.cn_name
                                : leagueTeamsPropertiesStats[index].data
                                    .teamsStats[teamIndex - 1].team.name
                            }}
                          </div></a
                        >
                      </div>
                      <div class="num" data-v-042072c0="">
                        {{
                          leagueTeamsPropertiesStats[index].data.teamsStats[
                            teamIndex - 1
                          ][propertyObject.property]
                        }}
                      </div>
                    </div>
                    <div class="w100 viewall" data-v-042072c0="">
                      <div
                        @click="toggleViewAll(index)"
                        class="viewallBtn"
                        data-v-042072c0=""
                      >
                        {{ $t("football.league-details.show-more") }} &gt;
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-for="teamIndex in 10"
                      :key="teamIndex"
                      class="w100 flex align-center justify-between list_active2"
                      data-v-042072c0=""
                    >
                      <div
                        v-if="
                          leagueTeamsPropertiesStats &&
                          leagueTeamsPropertiesStats[index] &&
                          leagueTeamsPropertiesStats[index].data &&
                          leagueTeamsPropertiesStats[index].data.teamsStats
                        "
                        class="des"
                        data-v-042072c0=""
                      >
                        <div
                          class="ind"
                          :class="
                            teamIndex == 1
                              ? 'first'
                              : teamIndex == 2
                              ? 'second'
                              : 'third'
                          "
                          data-v-042072c0=""
                        >
                          {{ teamIndex }}
                        </div>
                        <div
                          itemprop="logo"
                          class="myImg van-image van-image--round"
                          style="overflow: hidden; border-radius: 50%"
                          data-v-042072c0=""
                        >
                          <img
                            :src="
                              leagueTeamsPropertiesStats[index].data.teamsStats[
                                teamIndex - 1
                              ].team.logo
                            "
                            alt="Hamamatsu Higashimikawa"
                            class="van-image__img"
                            style="object-fit: cover"
                          />
                        </div>
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            leagueTeamsPropertiesStats[index].data.teamsStats[
                              teamIndex - 1
                            ].team.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            leagueTeamsPropertiesStats[index].data.teamsStats[
                              teamIndex - 1
                            ].team.id
                          "
                          class="flex"
                          data-v-042072c0=""
                          ><div class="playerName" data-v-042072c0="">
                            {{
                              this.$i18n.locale == "cn" &&
                              leagueTeamsPropertiesStats[index].data.teamsStats[
                                teamIndex - 1
                              ].team.cn_name
                                ? leagueTeamsPropertiesStats[index].data
                                    .teamsStats[teamIndex - 1].team.cn_name
                                : leagueTeamsPropertiesStats[index].data
                                    .teamsStats[teamIndex - 1].team.name
                            }}
                          </div></a
                        >
                      </div>
                      <div class="num" data-v-042072c0="">
                        {{
                          leagueTeamsPropertiesStats[index].data.teamsStats[
                            teamIndex - 1
                          ][propertyObject.property]
                        }}
                      </div>
                    </div>
                    <div class="w100 viewall" data-v-042072c0="">
                      <div
                        @click="toggleViewAll(index)"
                        class="viewallBtn"
                        data-v-042072c0=""
                      >
                        {{ $t("football.league-details.show-less") }} &gt;
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="display: none"
      data-v-59f3daaa=""
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-59f3daaa=""
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
      ></span>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
    leagueDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueStandings: null,
      loading: true,
      playerStatsloading: true,
      teamStatsloading: true,
      showLess: true,
      leaguePlayersPropertiesStats: null,

      playerStatsTeamProperties: [
        { viewAll: false, property: "goals", name: "goals" },
      ],

      leagueTeamsPropertiesStats: null,
      teamProperties: [{ viewAll: false, property: "goals", name: "goals" }],
    };
  },
  methods: {
    toggleViewAllPlayer(index) {
      this.playerStatsTeamProperties[index].viewAll =
        !this.playerStatsTeamProperties[index].viewAll;
    },
    toggleViewAll(index) {
      this.teamProperties[index].viewAll = !this.teamProperties[index].viewAll;
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data.standings;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendAllPlayersStatsRequests(leagueId) {
      let promisesArray = [];
      this.playerStatsTeamProperties.forEach((propertyObject) => {
        promisesArray.push(
          this.$axios.get(
            this.BASE_SERVER_URI +
              `/api/football/league/season/playersStats/${leagueId}?playerProperty=${propertyObject.property}`
          )
        );
      });
      Promise.all(promisesArray)
        .then((values) => {
          console.log(values);
          this.leaguePlayersPropertiesStats = values;
          this.playerStatsloading = false;
        })
        .catch((error) => console.log(error));
    },
    sendAllTeamStatsRequests(leagueId) {
      let promisesArray = [];
      this.teamProperties.forEach((propertyObject) => {
        promisesArray.push(
          this.$axios.get(
            this.BASE_SERVER_URI +
              `/api/football/league/season/teamsStats/${leagueId}?teamProperty=${propertyObject.property}`
          )
        );
      });
      Promise.all(promisesArray)
        .then((values) => {
          this.leagueTeamsPropertiesStats = values;
          this.teamStatsloading = false;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.sendAllPlayersStatsRequests(this.leagueId);
    this.sendAllTeamStatsRequests(this.leagueId);

    this.getLeagueStandings(this.leagueId);
  },
};
</script>
