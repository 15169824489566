<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div
    class="ft_teams view border-box"
    data-v-9a805c60=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="teamheader" data-v-9a805c60="">
      <div class="backFil" data-v-9a805c60="">
        <div class="header" data-v-48b34d9b="" data-v-9a805c60="" style="">
          <div class="van-sticky"></div>
        </div>
      </div>
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-9a805c60=""
      >
        <div
          itemprop="logo"
          class="team_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
          data-v-9a805c60=""
        >
          <img
            :src="
              teamDetails && teamDetails.id
                ? 'https://sportyclub.online/images/cricket/teams/' +
                  teamDetails.id +
                  '/' +
                  teamDetails.id +
                  '.png'
              : bannerImagePath('default-team.png')
            "
            @error="$event.target.src = bannerImagePath('default-team.png')"
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div itemprop="name" class="team_name" data-v-9a805c60="">
          {{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
          }}
        </div>
      </div>
      <div data-v-9a805c60="">
        <div class="van-sticky">
          <div class="van-tabs van-tabs--line" data-v-9a805c60="">
            <div
              class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
            >
              <div
                role="tablist"
                class="van-tabs__nav van-tabs__nav--line"
                style="border-color: #ffffff; background: #285898"
              >
                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgb(255, 255, 255)"
                  :style="
                    activeTab === 'overview'
                      ? 'color:white'
                      : 'color: rgba(255, 255, 255, 0.6)'
                  "
                  v-on:click="toggleTab('overview')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.overview") }}</span
                  >
                </div>
                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgba(255, 255, 255, 0.6)"
                  :style="activeTab === 'squad' ? 'color:white' : ''"
                  v-on:click="toggleTab('squad')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.squad") }}</span
                  >
                </div>

                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgba(255, 255, 255, 0.6)"
                  :style="activeTab === 'schedule' ? 'color:white' : ''"
                  v-on:click="toggleTab('schedule')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.schedule") }}</span
                  >
                </div>
                <div
                  role="tab"
                  class="van-tab van-tab--complete"
                  style="color: rgba(255, 255, 255, 0.6)"
                  :style="activeTab === 'standings' ? 'color:white' : ''"
                  v-on:click="toggleTab('standings')"
                >
                  <span class="van-tab__text">
                    {{ $t("football.league-details.standings") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="breadcrumb-box iconfont" data-v-e49cc106="" data-v-9a805c60="">
      <div class="display-i-l" data-v-e49cc106="">
        <a
          :href="'/' + this.$i18n.locale + '/live/cricket'"
          class="ele link"
          data-v-e49cc106=""
          ><span itemprop="name" data-v-e49cc106="">
            {{ $t("cricket.league-details.cricket-live-score") }}</span
          >
        </a>
      </div>
      <span class="jt" data-v-e49cc106=""></span>

      <div class="display-i-l" data-v-e49cc106="">
        <a target="" class="ele link" data-v-e49cc106=""
          ><span itemprop="name" data-v-e49cc106="">
            {{
              this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                ? teamDetails.cn_name
                : teamDetails.name
                ? teamDetails.name
                : ""
            }}
            {{ $t("football.league-details.schedule") }},
            {{ $t("football.league-details.standings") }}</span
          >
        </a>
      </div>
    </div>
    <div class="content" data-v-9a805c60="" v-if="activeTab === 'overview'">
      <div class="team_overview" data-v-7a9b3945="" data-v-9a805c60="">
        <div class="info" data-v-7a9b3945="">
          <div class="info_title" data-v-7a9b3945="">
            {{ $t("football.league-details.team-info") }}
          </div>
          <div class="info_content" data-v-7a9b3945="">
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">National Stadium</div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamDetails &&
                  teamDetails.venue &&
                  teamDetails.venue.stadium &&
                  teamDetails.venue.stadium.name
                    ? teamDetails.venue.stadium.name
                    : "-"
                }}
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">Is National</div>
              <div class="value" data-v-7a9b3945="">
                {{ teamDetails?.national ? "Yes" : "No" }}
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.country") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                <template v-if="teamDetails">
                  <template
                    v-if="
                      teamDetails.category &&
                      teamDetails.category.country &&
                      teamDetails.category.country.name
                    "
                  >
                    {{ teamDetails.category.country.name }}
                  </template>
                  <template
                    v-else-if="
                      teamDetails.category && teamDetails.category.name
                    "
                  >
                    {{ teamDetails.category.name }}
                  </template>
                  <template v-else></template>
                </template>
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.total-players") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamSquad &&
                  teamSquad.players &&
                  Array.isArray(teamSquad.players)
                    ? teamSquad.players.length
                    : "-"
                }}
              </div>
            </div>
            <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.foreign-players") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamSquad &&
                  teamSquad.foreignPlayers &&
                  Array.isArray(teamSquad.foreignPlayers)
                    ? teamSquad.foreignPlayers.length
                    : "-"
                }}
              </div>
            </div>
            <div
              itemprop="location"
              class="infoItem"
              style="border-top: 1px solid #f1f1f1"
              data-v-7a9b3945=""
            >
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.national-players") }}
              </div>
              <div itemprop="name" class="value" data-v-7a9b3945="">
                {{
                  teamSquad &&
                  teamSquad.nationalPlayers &&
                  Array.isArray(teamSquad.nationalPlayers)
                    ? teamSquad.nationalPlayers.length
                    : "-"
                }}
              </div>
            </div>

            <div
              itemprop="location"
              class="infoItem"
              style="border-top: 1px solid #f1f1f1"
              data-v-7a9b3945=""
            >
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.support-staff") }}
              </div>
              <div itemprop="name" class="value" data-v-7a9b3945="">
                {{
                  teamSquad &&
                  teamSquad.supportStaff &&
                  Array.isArray(teamSquad.supportStaff)
                    ? teamSquad.supportStaff.length
                    : "-"
                }}
              </div>
            </div>
            <!-- <div class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.website") }}
              </div>
              <div class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.website ? teamDetails.website : "-"
                }}
              </div>
            </div>
            <div itemprop="location" class="infoItem" data-v-7a9b3945="">
              <div class="key" data-v-7a9b3945="">
                {{ $t("football.league-details.total-market-value") }}
              </div>
              <div itemprop="address" class="value" data-v-7a9b3945="">
                {{
                  teamDetails && teamDetails.market_value
                    ? teamDetails.market_value / 1000000 + " €"
                    : "-"
                }}
              </div>
            </div> -->
          </div>
        </div>
        <div class="title-box" data-v-7a9b3945="">
          <span class="text" data-v-7a9b3945=""
            >About
            {{
              this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                ? teamDetails.cn_name
                : teamDetails.name
                ? teamDetails.name
                : ""
            }}</span
          >
        </div>
        <div class="dec-box" data-v-7a9b3945="">
          <div class="text" data-v-7a9b3945="">
            <p>
              <a>
                {{
                  this.$i18n.locale == "cn" &&
                  teamDetails &&
                  teamDetails.cn_name
                    ? teamDetails.cn_name
                    : teamDetails.name
                    ? teamDetails.name
                    : ""
                }}
                live score</a
              >, schedule, match results, standings, minute by minute updated
              live results and match statistics.
            </p>
          </div>
          <div class="text mt-20" data-v-7a9b3945="">
            <p>
              You can click on any player from the roster on the squad page and
              see his personal information such as nationality, date of birth,
              height, preferred foot, position, player value, transfer history
              etc. There are also statistics for each player in all competitions
              with all total played and started matches, minutes played, number
              of goals scored, number of cards and much more. AiScore
              <a style="color: var(--main-text-color)" href="/">cricket live score</a> is
              available as iPhone and iPad app, Android app on Google Play and
              Windows phone app. You can find us in all stores on different
              languages searching for "AiScore". Install AiScore app and follow
              all
              {{
                this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                  ? teamDetails.cn_name
                  : teamDetails.name
                  ? teamDetails.name
                  : ""
              }}
              matches live on your mobile!
            </p>
          </div>
        </div>
      </div>
    </div>

    <TeamSquad v-if="activeTab === 'squad'" :teamSquad="teamSquad" />

    <TeamMatches v-if="activeTab === 'schedule'" :teamId="teamId" />
    <TeamStandings v-if="activeTab === 'standings'" :teamId="teamId" />

    <MobileFooter />
  </div>
</template>
<script>
import TeamSquad from "./tabs/team-squad.vue";
import TeamMatches from "./tabs/team-matches.vue";
import TeamStandings from "./tabs/team-standings.vue";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  components: {
    TeamMatches,
    TeamSquad,
    TeamStandings,
  },

  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totalPlayers: null,
      activeTab: "overview",
      seasonDialogStatus: false,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    // console.log(this.teamId);
    // this.getTeamDetails(itemId);
    // this.getTeamSquad(itemId);
    this.callApis();
  },
  methods: {
    async callApis() {
      try {
        const response = await this.getTeamDetails(this.teamId);
        this.teamDetails = response.data.team;
      } catch (error) {
        console.error(error);
      }

      try {
        const response2 = await this.getTeamSquad(this.teamId);
        this.teamSquad = response2.data;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    // method to get hot leagues list
    async getTeamDetails(teamId) {
      return this.$axios.get(
        this.BASE_SERVER_URI + `/api/cricket/teams/details/${teamId}`
      );
    },
    // method to get team squad
    async getTeamSquad(teamId) {
      return this.$axios.get(
        this.BASE_SERVER_URI + `/api/cricket/teams/squad/${teamId}`
      );
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
