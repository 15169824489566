<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="innerMatchInfo detail view border-box bg" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
    >
      <span class="el-breadcrumb__item"
        ><span role="link" class="el-breadcrumb__inner"
          ><a target="_blank" style="color: var(--main-text-color)">
            {{ $t("football.league-details.basketball-live-score") }}
          </a> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
      <span class="el-breadcrumb__item"
        ><span role="link" class="el-breadcrumb__inner">
          <a
            v-if="todayMatchDetails.hasLeagueDetails === 1"
            target="_blank"
            style="color: var(--main-text-color)"
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              todayMatchDetails.leagueInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.competition_id
            "
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
          <a v-else target="_blank" style="color: var(--main-text-color)">
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
      <span class="el-breadcrumb__item" aria-current="page"
        ><span role="link" class="el-breadcrumb__inner">
          <h1 class="fs-12 font-400">
            <a
              v-if="
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.awayInfo
              "
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/basketball/team/details/' +
                todayMatchDetails.homeInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.home_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.cn_name
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                }}
              </b>
            </a>

            vs
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/basketball/team/details/' +
                todayMatchDetails.awayInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.away_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.cn_name
                    ? todayMatchDetails.awayInfo.cn_name
                    : todayMatchDetails.awayInfo.en_name
                }}
              </b>
            </a>
            {{ $t("football.league-details.live-score-prediction") }}
            <span v-if="todayMatchDetails && todayMatchDetails.match_time">
              {{ getFormattedBroadcrumbDate(todayMatchDetails.match_time) }}
            </span>
          </h1> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
    </div>
    <!-- end::broadcrumb -->
    <div class="matchTop">
      <div class="text-center info">
        <a
          target="_blank"
          style="color: var(--main-text-color); cursor: pointer"
          class=""
          :href="
            '/' +
            this.$i18n.locale +
            '/basketball/league/details/' +
            todayMatchDetails.leagueInfo.en_name
              .toLowerCase()
              .replace(/\s/g, '-') +
            '/' +
            todayMatchDetails.competition_id
          "
        >
          <b>
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo &&
              todayMatchDetails.leagueInfo.cn_name
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </b>
        </a>

        <span class="cutOffLine">/</span>
        <span
          itemprop="startDate"
          v-if="todayMatchDetails && todayMatchDetails.match_time"
        >
          {{ getFormattedDate(todayMatchDetails.match_time) }}
        </span>
      </div>
      <div class="matchInfo">
        <div class="teamLeft">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              todayMatchDetails.homeInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            target="_blank"
            class="teamName"
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.homeInfo
                ? todayMatchDetails.homeInfo.cn_name
                : todayMatchDetails.homeInfo.en_name
            }}
          </a>

          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              todayMatchDetails.homeInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            target="_blank"
            class="teamName"
          >
            <img
              alt="JS Saoura U21"
              title="JS Saoura U21"
              :src="
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.logo
                  ? todayMatchDetails.homeInfo.logo + '!w60'
                  : '/images/default-football.jpg'
              "
              data-v-60167e7a=""
            />
          </a>
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div
              class="score flex justify-center scoreRed"
              v-if="
                todayMatchDetails &&
                (liveMatches.includes(todayMatchDetails.status_id) ||
                  todayMatchDetails.status_id == 10) &&
                todayMatchDetails.homeInfo
              "
            >
              {{
                addSectionsScore(
                  todayMatchDetails.homeInfo.section_1,
                  todayMatchDetails.homeInfo.section_2,
                  todayMatchDetails.homeInfo.section_3,
                  todayMatchDetails.homeInfo.section_4
                )
              }}
            </div>
          </div>
        </div>
        <div class="matchScore scoreColorRed" style="position: relative">
          <span
            class=""
            v-if="
              todayMatchDetails &&
              todayMatchDetails.statusDescription &&
              todayMatchDetails.counter_timing
            "
          >
            {{ todayMatchDetails.statusDescription }}
            {{ todayMatchDetails.counter_timing }}
          </span>
          <span
            v-else-if="
              todayMatchDetails.status_id == 1 ||
              todayMatchDetails.status_id == 10
            "
          >
            VS
          </span>
        </div>
        <div class="teamRight">
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div
              class="score flex justify-center scoreRed"
              v-if="
                todayMatchDetails &&
                (liveMatches.includes(todayMatchDetails.status_id) ||
                  todayMatchDetails.status_id == 10) &&
                todayMatchDetails.awayInfo
              "
            >
              {{
                addSectionsScore(
                  todayMatchDetails.awayInfo.section_1,
                  todayMatchDetails.awayInfo.section_2,
                  todayMatchDetails.awayInfo.section_3,
                  todayMatchDetails.awayInfo.section_4
                )
              }}
            </div>
          </div>
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            target="_blank"
            class="teamName"
          >
            <img
              :src="
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.logo
                  ? todayMatchDetails.awayInfo.logo + '!w60'
                  : '/images/default-football.jpg'
              "
            />
          </a>
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            target="_blank"
            class="teamName"
            v-if="this.$i18n.locale == 'cn'"
          >
            {{
              todayMatchDetails &&
              todayMatchDetails.awayInfo &&
              todayMatchDetails.awayInfo.cn_name
                ? todayMatchDetails.awayInfo.cn_name
                : todayMatchDetails.awayInfo.en_name
            }}
          </a>

          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            target="_blank"
            class="teamName"
            v-else
          >
            {{
              todayMatchDetails &&
              todayMatchDetails.awayInfo &&
              todayMatchDetails.awayInfo.en_name
                ? todayMatchDetails.awayInfo.en_name
                : todayMatchDetails.awayInfo.cn_name
            }}
          </a>
        </div>
      </div>
    </div>
    <!-- begin::tabs -->
    <div
      justify-content="true"
      class="tab-bar"
      data-v-274dfa8a=""
      data-v-4a160e70=""
    >
      <div class="content-box" data-v-274dfa8a="">
        <div class="child" data-v-274dfa8a="">
          <a
            class="tab"
            :class="status == 'overview' ? 'active' : ''"
            data-v-274dfa8a=""
            v-on:click="toggleTabs('overview')"
          >
            {{ $t("football.league-details.overview") }}
          </a>

          <a
            :class="status == 'odds' ? 'active' : ''"
            v-on:click="toggleTabs('odds')"
            class="tab"
            data-v-274dfa8a=""
          >
            {{ $t("football.league-details.odds") }}
          </a>
        </div>
      </div>
    </div>
    <!-- end::tabs -->
    <!-- begin::overview -->
    <div
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'overview' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOverviewTab :matchId="todayMatchDetails.id" />
      <LiveMatchH2HTab :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::overview -->
    <!-- begin::odds -->
    <div
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'odds' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOdds :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::odds -->
  </div>

  <DesktopFooter />
</template>

<script>
import metaDataUtils from "@/utils/metaDataUtils";
import LiveMatchOverviewTab from "./tabs/live-match-overview.vue";
import LiveMatchH2HTab from "./tabs/match-h2h.vue";
import LiveMatchOdds from "./tabs/match-odds.vue";


export default {
  components: {
    LiveMatchOverviewTab,
    LiveMatchH2HTab,
    LiveMatchOdds,


  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [2, 3, 4, 5, 6, 7, 8, 9],
    };
  },
  mixins: [metaDataUtils],
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/match/meta/` +
            matchId
        )
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },

    // add section score
    addSectionsScore(section_1, section_2, section_3, section_4) {
      return section_1 + section_2 + section_3 + section_4;
    },
  },
};
</script>
