<template>
  <div
    class="cricket view border-box"
    data-v-250f1619=""
    style="min-height: 0px !important"
  >
    <div>
      <div class="homeHeader" data-v-736e8afd="">
        <div class="screenBox flex align-center" data-v-736e8afd="">
          <!-- begin::tabs -->
          <div class="changTabBox flex-1" data-v-736e8afd="">
            <span
              class="changeItem bh ml-6"
              data-v-736e8afd=""
              :class="
                liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
              "
              ><span
                class="liveContentBox"
                data-v-736e8afd=""
                v-on:click="getTodayMatchList('live')"
                ><i class="iconfont icon-jinhangzhong" data-v-736e8afd=""></i>
                {{ $t("football.live-score.live") }}
              </span></span
            >
            <span
              :class="
                liveActivebtn == 'finished' ? 'activeElsTab' : 'notActiveTab'
              "
              v-on:click="getTodayMatchList('finished')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.finished") }}
            </span>
            <span
              :class="liveActivebtn == 'soon' ? 'activeElsTab' : 'notActiveTab'"
              v-on:click="getTodayMatchList('soon')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.soon") }}
            </span>

            <a
              :href="'/' + this.$i18n.locale + '/cricket/news/list'"
              :class="
                liveActivebtn == 'latest-news' ? 'activeElsTab' : 'notActiveTab'
              "
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.league-details.cricket-news") }}
            </a>
          </div>
          <!-- sort matches based on time -->

          <!-- end::tabs -->
        </div>
      </div>
      <div class="detail view border-box" data-v-250f1619="" v-if="loading">
        <div
          class="loading van-loading van-loading--spinner van-loading--vertical"
        >
          <span class="van-loading__spinner van-loading__spinner--spinner"
            ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
            ><i></i><i></i><i></i
          ></span>
        </div>
      </div>
      <SearchMatchList
        v-if="searchListUpcoming || searchListLive"
        :searchMatchListUpcoming="searchListUpcoming"
        :searchMatchListLive="searchListLive"
      />
      <template v-if="!searchListUpcoming && !searchListLive">
        <div
          v-if="
            countLiveMatches == 0 ||
            (Array.isArray(todayMatchList) && todayMatchList.length === 0)
          "
          data-v-abef72c2=""
          class="flex justify-center align-center flex-col contentBox"
          style="height: 3.5rem"
        >
          <img
            data-v-abef72c2=""
            :src="bannerImagePath('default-container.png')"
            class="nodata"
          />

          <div data-v-abef72c2="" class="color-999 noDataText">
            {{ $t("football.league-details.no-live-match") }}
          </div>
        </div>
        <div class="w100" v-for="(league, key) in todayMatchList" :key="key">
          <div style="width: 100%">
            <!-- begin::show league & country -->
            <div
              class="list_title"
              :class="
                leagueFavoriteStatus[league[0].unique_tournament_id]
                  ? 'collectComp'
                  : ''
              "
            >
              <i
                v-if="false"
                class="iconfont collectImg"
                :class="
                  leagueFavoriteStatus[league[0].unique_tournament_id]
                    ? 'icon-yishoucang'
                    : 'icon-weishoucang'
                "
                @click="toggleFavorite(league[0].unique_tournament_id)"
              ></i>
              <div
                class="flex align-center"
                style="overflow: hidden; width: 8.4rem"
              >
                <div
                  class="listImg van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                >
                  <img
                    class="van-image__img"
                    :src="
                      league[0] &&
                      league[0].tournament &&
                      league[0].tournament.uniqueTournament &&
                      league[0].tournament.uniqueTournament.id
                        ? `https://sportyclub.online//images/cricket/leagues/${league[0].tournament.uniqueTournament.id}/${league[0].tournament.uniqueTournament.id}.png`
                        : bannerImagePath('default-country.png')
                    "
                    @error="
                      $event.target.src = bannerImagePath('default-country.png')
                    "
                    alt=""
                    lazy="loaded"
                    style="object-fit: contain"
                  />
                </div>
                <span class="countryText">
                  <span data-v-f1b1cb6e="" class="country-name minitext">
                    <template v-if="league[0] && league[0].tournament">
                      <template
                        v-if="
                          league[0].tournament.category &&
                          league[0].tournament.category.country &&
                          league[0].tournament.category.country.name
                        "
                      >
                        {{ league[0].tournament.category.country.name }}
                      </template>
                      <template
                        v-else-if="
                          league[0].tournament.category &&
                          league[0].tournament.category.name
                        "
                      >
                        {{ league[0].tournament.category.name }}
                      </template>
                      <template v-else></template>
                    </template>
                  </span>
                  :</span
                >

                <a
                  v-if="
                    league &&
                    league[0] &&
                    league[0].tournament &&
                    league[0].tournament.uniqueTournament &&
                    league[0].tournament.uniqueTournament.id
                  "
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/league/details/' +
                    league[0].tournament.uniqueTournament.name +
                    '/' +
                    league[0].tournament.uniqueTournament.id
                  "
                  class="compText"
                  style="font-size: 0.32rem"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].tournament.cn_name
                      ? league[0].tournament.cn_name
                      : league[0].tournament.name
                      ? league[0].tournament.name
                      : ""
                  }}
                </a>
                <a v-else class="compText">
                  {{
                    this.$i18n.locale == "cn" &&
                    league[0].tournament &&
                    league[0].tournament.cn_name
                      ? league[0].tournament.cn_name
                      : league[0].tournament && league[0].tournament.name
                      ? league[0].tournament.name
                      : ""
                  }}
                </a>
              </div>
              <div class="flex userNumBox">
                <!-- <span class="iconfont icon-renshu"></span> -->
                <span class="userNum"> </span>
              </div>
            </div>
            <!-- end::show league & country -->
            <!-- begin::match info -->
            <div class="w100 list_active">
              <div
                class="list"
                v-for="match in league"
                :key="match.id"
                style="height: 1.38rem; position: relative"
              >
                <div class="w100 flex h100 contentListBox">
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/match/details/' +
                      match.homeTeam.name.toLowerCase() +
                      '-vs-' +
                      match.homeTeam.name.toLowerCase() +
                      '/' +
                      match.id
                    "
                    class="flex flex-1 h100 allBox"
                  >
                    <div class="w100 flex h100 align-center">
                      <div
                        class="flex align-center justify-center listBox flex-column"
                      >
                        <span
                          class="fs-12 text-center"
                          style="color: rgb(153, 153, 153)"
                        >
                          {{ formatUnixTimestamp(match.startTimestamp) }}
                        </span>

                        <div
                          class="flex fs-12 align-center color-r text-center"
                        >
                          <span class="fs-12" style="white-space: nowrap">{{
                            match?.status?.description
                          }}</span>
                        </div>
                      </div>
                      <div class="team flex flex-col padding5">
                        <div class="flex-1 align-center flex">
                          <div class="flex align-center" style="width: 0.4rem">
                            <div class="flex-1 align-center flex">
                              <div
                                class="flex align-center"
                                style="width: 0.4rem"
                              >
                                <div
                                  class="imgw4 van-image van-image--round"
                                  style="overflow: hidden; border-radius: 50%"
                                >
                                  <img
                                    :src="
                                      match.homeTeam && match.homeTeam.id
                                        ? 'https://sportyclub.online//images/cricket/teams/' +
                                          match.homeTeam.id +
                                          '/' +
                                          match.homeTeam.id +
                                          '.png'
                                        : bannerImagePath('default-team.png')
                                    "
                                    @error="
                                      $event.target.src =
                                        bannerImagePath('default-team.png')
                                    "
                                    lazy="loaded"
                                    alt=""
                                    class="van-image__img"
                                    style="object-fit: contain"
                                  />
                                </div>
                              </div>
                              <div
                                class="w-o-h fs-14 pl2"
                                style="max-width: 3.6rem"
                              >
                                &nbsp;
                                <template v-if="match && match.homeTeam"
                                  >{{
                                    this.$i18n.locale == "cn" &&
                                    match.homeTeam?.cn_name
                                      ? match.homeTeam?.cn_name
                                      : match.homeTeam?.name
                                      ? match.homeTeam?.name
                                      : ""
                                  }}({{ match.homeTeam?.shortName }})
                                </template>
                              </div>
                              <img
                                v-if="
                                  match.changes && match.changes.changes
                                    ? match.changes.changes.includes(
                                        'homeScore.innings'
                                      )
                                    : false
                                "
                                src="/images/banqiu-banzi.png"
                                alt="#"
                                class="svgPostop ballIcon ballIconLeft"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="flex-1 align-center flex">
                          <div class="flex align-center" style="width: 0.4rem">
                            <div class="flex-1 align-center flex">
                              <div
                                class="flex align-center"
                                style="width: 0.4rem"
                              >
                                <div
                                  class="imgw4 van-image van-image--round"
                                  style="overflow: hidden; border-radius: 50%"
                                >
                                  <img
                                    :src="
                                      match.awayTeam && match.awayTeam.id
                                        ? 'https://sportyclub.online//images/cricket/teams/' +
                                          match.awayTeam.id +
                                          '/' +
                                          match.awayTeam.id +
                                          '.png'
                                        : bannerImagePath('default-team.png')
                                    "
                                    @error="
                                      $event.target.src =
                                        bannerImagePath('default-team.png')
                                    "
                                    lazy="loaded"
                                    alt=""
                                    class="van-image__img"
                                    style="object-fit: contain"
                                  />
                                </div>
                              </div>
                              <div
                                class="w-o-h fs-14 pl2"
                                style="max-width: 3.6rem"
                              >
                                &nbsp;
                                <template v-if="match && match.awayTeam"
                                  >{{
                                    this.$i18n.locale == "cn" &&
                                    match.awayTeam?.cn_name
                                      ? match.awayTeam?.cn_name
                                      : match.awayTeam?.name
                                      ? match.awayTeam?.name
                                      : ""
                                  }}({{ match.awayTeam?.shortName }})
                                </template>
                              </div>
                              <img
                                v-if="
                                  match.changes && match.changes.changes
                                    ? match.changes.changes.includes(
                                        'awayScore.innings'
                                      )
                                    : false
                                "
                                src="/images/banqiu-banzi.png"
                                alt="#"
                                class="svgPostop ballIcon ballIconLeft"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="icon-setting">
                        <span class="iconfont icon-shipinzhibo"></span>
                      </div>

                      <div class="teamScore">
                        <div class="teamScore">
                          <div class="w100 flex h100">
                            <div class="flex-1 h100 padding5">
                              <div
                                class="flex flex-col align-center w100"
                                style="height: 100%; line-height: 12px"
                              >
                                <div
                                  class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  <div
                                    class="flex-1 text-center scoreWeight"
                                    style="
                                      font-family: Roboto-Regular, Roboto;
                                      font-weight: bolder;
                                    "
                                  >
                                    <span
                                      class="scoreRed"
                                      style="
                                        font-size: 0.32rem;
                                        padding: 0px 2px;
                                      "
                                    >
                                      <span
                                        data-v-89734b7c=""
                                        :class="{
                                          scoreRed: false,
                                        }"
                                      >
                                        <span
                                          v-for="inn in match.homeScore.innings"
                                          :key="inn"
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          {{ inn.score + "/" }}
                                          {{ inn.wickets }} ({{ inn.overs }})
                                          &nbsp;
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  <div
                                    class="flex-1 text-center"
                                    style="
                                      font-family: Roboto-Regular, Roboto;
                                      font-weight: bolder;
                                    "
                                  >
                                    <span
                                      class="scoreRed"
                                      style="
                                        font-size: 0.32rem;
                                        padding: 0px 2px;
                                      "
                                    >
                                      <span
                                        data-v-89734b7c=""
                                        :class="{
                                          scoreRed: false,
                                        }"
                                      >
                                        <span
                                          v-for="inn in match.awayScore.innings"
                                          :key="inn"
                                          data-v-89734b7c=""
                                          class=""
                                        >
                                          {{ inn.score + "/" }}
                                          {{ inn.wickets }} ({{ inn.overs }})
                                          &nbsp;
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- end::match info -->
          </div>
        </div>
        <FeaturedMatches />
        <!-- begin::upcoming -->
        <UpcomingMatches />
        <!-- end::upcoming -->
      </template>
    </div>
  </div>
  <!--begin::footer -->
  <MobileFooter />
  <!-- end::footter -->
</template>
<!-- begin::scripts -->
<script>
import UpcomingMatches from "./tabs/upcoming-matches.vue";
import FeaturedMatches from "./tabs/featured-matches.vue";
import SearchMatchList from "./tabs/search-matches-list";
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, DateMixin],
  components: {
    UpcomingMatches,

    SearchMatchList,
    FeaturedMatches,
  },
  data() {
    return {
      searchListUpcoming: null,
      searchListLive: null,
      todayUpcomingMatchList: null,
      todayMatchList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [],
      FinishedMatches: [100],
      leagueFavoriteStatus: {}, // Store favorite status for each league
      match_status: {
        FIRST_INNINGS_HOME_TEAM: 532,
        FIRST_INNINGS_AWAY_TEAM: 533,
        SECOND_INNINGS_HOME_TEAM: 534,
        SECOND_INNINGS_AWAY_TEAM: 535,
      },
    };
  },
  created() {
    this.getTodayMatchList("live");
    this.getUpcomingMatchList();
  },
  mounted() {
    // search functionality
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        console.log(this.todayMatchList);
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayTeam.cn_name &&
                  matchObject.homeTeam.cn_name &&
                  matchObject.tournament.cn_name
                ) {
                  return (
                    matchObject.awayTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayTeam.cn_name &&
                    matchObject.homeTeam.cn_name &&
                    matchObject.tournament.cn_name
                  ) {
                    return (
                      matchObject.awayTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (!matchObject.awayTeam && !matchObject.awayTeam.name)
                  return false;
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayTeam.cn_name &&
                  matchObject.homeTeam.cn_name &&
                  matchObject.tournament.cn_name
                ) {
                  return (
                    matchObject.awayTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeTeam.name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.tournament.name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            console.log("inteam", inTeam);
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayTeam.cn_name &&
                    matchObject.homeTeam.cn_name &&
                    matchObject.tournament.cn_name
                  ) {
                    return (
                      matchObject.awayTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeTeam.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.tournament.name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getUpcomingMatchList() {
      this.loading = true;
      const currentDate = new Date().toISOString().split("T")[0];
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api/cricket/today/all/matches/${currentDate}`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data;
          this.loading = false;
        })
        .catch((e) => console.error(e));
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`cricket_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`cricket_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get today matchlist
    getTodayMatchList(type) {
      let url;
      switch (type) {
        case "live":
          this.liveActivebtn = "live";
          url = `${this.BASE_SERVER_URI}/api/cricket/today/live/matches`;
          break;
        case "finished":
          this.liveActivebtn = "finished";
          url = `${this.BASE_SERVER_URI}/api/cricket/today/matches/100`;
          break;
        case "soon":
          this.liveActivebtn = "soon";
          url = `${this.BASE_SERVER_URI}/api/cricket/today/matches/0`;
          break;

        default:
          this.liveActivebtn = "latest-news";
          // url = `${this.BASE_SERVER_URI}/api/cricket/today/live/matches`;
          break;
      }

      this.loading = true;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          this.todayMatchList = response.data;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
