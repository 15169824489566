<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div class="w100">
    <div class="analyseContent" data-v-2115270e="">
      <div class="teamBtnBox" data-v-2115270e="">
        <span
          class="homeTeam"
          data-v-2115270e=""
          :class="activeTab == 'all' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('all')"
        >
          {{ $t("football.league-details.h2h") }}
        </span>
        <span
          class="awayTeam ml-8"
          data-v-2115270e=""
          :class="activeTab == 'home' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('home')"
        >
          {{ $t("football.league-details.home-team") }}
        </span>
        <span
          class="awayTeam ml-8"
          data-v-2115270e=""
          :class="activeTab == 'away' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('away')"
        >
          {{ $t("football.league-details.away-team") }}
        </span>
      </div>
      <div class="flex" data-v-2115270e="">
        <div class="contentBox flex-1" data-v-2115270e="">
          <div class="allBox" data-v-2115270e="">
            <div class="headerBox" data-v-2115270e="">
              <p data-v-2115270e="">
                <span data-v-2115270e="">
                  {{ $t("football.league-details.h2h") }}
                </span>
              </p>
              <!-- <p data-v-2115270e="">
                <span class="select flexCenter" data-v-2115270e="">
                  6
                  <i class="iconfont icon-xiala" data-v-2115270e=""></i>
                  <span
                    class="selectNum"
                    style="display: none"
                    data-v-2115270e=""
                    ><span class="flexCenter" data-v-2115270e="">6</span
                    ><span class="flexCenter" data-v-2115270e="">12</span
                    ><span class="flexCenter" data-v-2115270e="">18</span
                    ><span class="flexCenter" data-v-2115270e="">24</span></span
                  ></span
                >
              </p> -->
            </div>
            <!-- <div class="screenBox" data-v-2115270e="">
              <p class="mr-16" style="display: " data-v-2115270e="">
                <span data-v-2115270e=""></span>
                <span data-v-2115270e="">Home</span>
                <span style="" data-v-2115270e=""> - Perth Redbacks </span>
              </p>
              <p data-v-2115270e="">
                <span data-v-2115270e=""></span>
                <span data-v-2115270e="">This league</span>
              </p>
            </div> -->
            <div class="infoBox" data-v-2115270e="">
              <span data-v-2115270e=""
                ><span class="round win flexCenter" data-v-2115270e="">W</span>
                <span class="text" data-v-2115270e="">X6</span></span
              >

              <span class="leftMargin20" data-v-2115270e=""
                ><span class="round loser flexCenter" data-v-2115270e=""
                  >L</span
                >
                <span class="text" data-v-2115270e="">X0</span></span
              >
              <!-- <span
                class="leftMargin20 infoItem"
                style="display: "
                data-v-2115270e=""
                ><span class="pts" data-v-2115270e="">pts</span>
                <span class="text" data-v-2115270e="">
                  97.7 - 65.7 per game
                </span>
              </span> -->
            </div>

            <template
              v-if="
                todayMatchDetails &&
                todayMatchDetails.matchH2H &&
                todayMatchDetails.matchH2H[activeTab]
              "
            >
              <div
                class="matchContent"
                data-v-2115270e=""
                v-for="(matchArray, leagueName) in todayMatchDetails
                  ?.matchH2H?.[activeTab] ?? []"
                :key="leagueName"
              >
                <p class="header" data-v-2115270e="">
                  <span class="comp flexCenter" data-v-2115270e=""
                    ><img
                      :src="
                        matchArray[0] &&
                        matchArray[0].leagueInfo &&
                        matchArray[0].leagueInfo.countryDetails &&
                        matchArray[0].leagueInfo.countryDetails.logo
                          ? matchArray[0].leagueInfo.countryDetails.logo +
                            '!w30'
                         : bannerImagePath('default-country.png')
                      "
                      lazy="loaded"
                      alt="#"
                      data-v-2115270e=""
                    />
                    <span class="country flexCenter" data-v-2115270e=""
                      ><template
                        v-if="matchArray[0] && matchArray[0].leagueInfo"
                      >
                        <template v-if="this.$i18n.locale == 'cn'">
                          <template
                            v-if="
                              matchArray[0].leagueInfo.countryDetails &&
                              matchArray[0].leagueInfo.countryDetails.cn_name
                            "
                          >
                            {{
                              matchArray[0].leagueInfo.countryDetails.cn_name
                            }}
                          </template>
                          <template
                            v-else-if="
                              matchArray[0].leagueInfo.categoryDetails &&
                              matchArray[0].leagueInfo.categoryDetails.cn_name
                            "
                          >
                            {{
                              matchArray[0].leagueInfo.categoryDetails.cn_name
                            }}
                          </template>
                          <template
                            v-else-if="
                              matchArray[0].leagueInfo.countryDetails &&
                              matchArray[0].leagueInfo.countryDetails.name
                            "
                          >
                            {{ matchArray[0].leagueInfo.countryDetails.name }}
                          </template>
                          <template
                            v-else-if="
                              matchArray[0].leagueInfo.categoryDetails &&
                              matchArray[0].leagueInfo.categoryDetails.name
                            "
                          >
                            {{ matchArray[0].leagueInfo.categoryDetails.name }}
                          </template>
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              matchArray[0].leagueInfo.countryDetails &&
                              matchArray[0].leagueInfo.countryDetails.name
                            "
                          >
                            {{ matchArray[0].leagueInfo.countryDetails.name }}
                          </template>
                          <template
                            v-else-if="
                              matchArray[0].leagueInfo.categoryDetails &&
                              matchArray[0].leagueInfo.categoryDetails.name
                            "
                          >
                            {{ matchArray[0].leagueInfo.categoryDetails.name }}
                          </template>
                          <template v-else> </template>
                        </template>
                      </template>
                    </span>
                    <span class="flexCenter" data-v-2115270e="">{{
                      leagueName
                    }}</span></span
                  >
                </p>
                <ul
                  v-for="match in matchArray"
                  :key="match"
                  class="matchBox"
                  data-v-2115270e=""
                >
                  <li
                    itemscope="itemscope"
                    itemtype="http://schema.org/SportsEvent"
                    data-v-2115270e=""
                  >
                    <p
                      v-if="
                        match.matchStatus == 10 &&
                        match.homeInfo &&
                        match.awayInfo
                      "
                      class="collect flexCenter"
                      data-v-2115270e=""
                    >
                      <span
                        v-if="match.homeInfo.score > match.awayInfo.score"
                        data-v-2115270e=""
                        class="round flexCenter"
                        :class="{
                          win: match.homeInfo.score > match.awayInfo.score,
                        }"
                      >
                        W
                      </span>
                      <span
                        v-else-if="match.homeInfo.score < match.awayInfo.score"
                        data-v-2115270e=""
                        class="round flexCenter"
                        :class="{
                          loser: match.homeInfo.score < match.awayInfo.score,
                        }"
                      >
                        L
                      </span>
                      <span
                        v-else-if="match.homeInfo.score == match.awayInfo.score"
                        data-v-2115270e=""
                        class="round flexCenter"
                      >
                        <i class="iconfont icon-pingju" data-v-19df9d94=""></i>
                      </span>
                    </p>

                    <a target="_self" itemprop="url" data-v-2115270e=""
                      ><p
                        itemprop="startDate"
                        class="time flexCenter"
                        data-v-2115270e=""
                      >
                        {{ match.matchTime }}
                      </p>
                      <p class="teamBox" data-v-2115270e="">
                        <span class="teamBoxItem" data-v-2115270e=""
                          ><span style="display: " data-v-2115270e=""
                            ><img
                              :src="
                                match.homeInfo.logo
                                  ? match.homeInfo.logo
                                  : bannerImagePath('default-team.png')
                              "
                              itemprop="logo"
                              data-v-2115270e=""
                          /></span>
                          <span
                            itemprop="name"
                            class="teamName"
                            data-v-2115270e=""
                          >
                            <template v-if="match && match.homeInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.homeInfo.cn_name
                                  ? match.homeInfo.cn_name
                                  : match.homeInfo.name
                                  ? match.homeInfo.name
                                  : ""
                              }}
                            </template>
                          </span>
                        </span>
                        <span class="teamBoxItem" data-v-2115270e=""
                          ><span style="display: " data-v-2115270e=""
                            ><img
                              :src="
                                match.awayInfo.logo
                                  ? match.awayInfo.logo
                                  : bannerImagePath('default-team.png')
                              "
                              alt="South West Slammers Logo"
                              itemprop="logo"
                              data-v-2115270e=""
                          /></span>
                          <span
                            itemprop="name"
                            class="teamName"
                            data-v-2115270e=""
                            ><template v-if="match && match.awayInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.awayInfo.cn_name
                                  ? match.awayInfo.cn_name
                                  : match.awayInfo.name
                                  ? match.awayInfo.name
                                  : ""
                              }}
                            </template></span
                          ></span
                        >
                      </p>

                      <p class="scoreBox" data-v-2115270e="">
                        <span
                          class="flexCenter"
                          :class="{
                            winText:
                              match.homeInfo.score > match.awayInfo.score,
                            loserText:
                              match.homeInfo.score < match.awayInfo.score,
                          }"
                          data-v-2115270e=""
                        >
                          {{ match?.homeInfo?.score ?? "0" }}
                        </span>
                        <span
                          class="flexCenter"
                          :class="{
                            winText:
                              match.awayInfo.score > match.homeInfo.score,
                            loserText:
                              match.awayInfo.score < match.homeInfo.score,
                          }"
                          data-v-2115270e=""
                        >
                          {{ match?.awayInfo?.score ?? "0" }}
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </div>
            </template>
            <span v-else>
              <small style="font-size: 15px !important; margin-left: 20px">
                {{ $t("football.live-score.temporarily-no-data") }}
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateUtils from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },
  mixins: [dateUtils, DynamicImagePath],
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      activeTab: "all",
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTabs(state) {
      this.activeTab = state;
    },
  },
};
</script>
