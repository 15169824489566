<template>
  <div
    class="cricket view border-box"
    data-v-250f1619=""
    style="min-height: 0px !important"
  >
    <div>
      <div class="homeHeader" data-v-736e8afd="">
        <div class="screenBox flex align-center" data-v-736e8afd="">
          <!-- begin::tabs -->
          <div class="changTabBox flex-1" data-v-736e8afd="">
            <span
              class="changeItem bh"
              data-v-736e8afd=""
              :class="liveActivebtn == 'all' ? 'activeElsTab' : 'notActiveTab'"
              v-on:click="getTodayMatchList('all')"
            >
              {{ $t("football.live-score.all") }}
            </span>
            <span
              class="changeItem bh ml-6"
              data-v-736e8afd=""
              :class="
                liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
              "
              ><span
                class="liveContentBox"
                data-v-736e8afd=""
                v-on:click="getTodayMatchList('live')"
                ><i class="iconfont icon-jinhangzhong" data-v-736e8afd=""></i>
                {{ $t("football.live-score.live") }}
              </span></span
            >
            <span
              :class="
                liveActivebtn == 'finished' ? 'activeElsTab' : 'notActiveTab'
              "
              v-on:click="getTodayMatchList('finished')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.finished") }}
            </span>
            <span
              :class="
                liveActivebtn == 'upcoming' ? 'activeElsTab' : 'notActiveTab'
              "
              v-on:click="getTodayMatchList('upcoming')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.soon") }}
            </span>
          </div>
          <!-- sort matches based on time -->
          <!-- end::tabs -->
        </div>
      </div>
      <div class="detail view border-box" data-v-250f1619="" v-if="loading">
        <div
          class="loading van-loading van-loading--spinner van-loading--vertical"
        >
          <span class="van-loading__spinner van-loading__spinner--spinner"
            ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
            ><i></i><i></i><i></i
          ></span>
        </div>
      </div>
      <SearchMatchList
        v-if="searchListUpcoming || searchListLive"
        :searchMatchListUpcoming="searchListUpcoming"
        :searchMatchListLive="searchListLive"
      />
      <template v-if="!searchListUpcoming && !searchListLive">
        <div
          v-if="countLiveMatches == 0"
          data-v-abef72c2=""
          class="flex justify-center align-center flex-col contentBox"
          style="height: 3.5rem"
        >
          <img
            data-v-abef72c2=""
            :src="bannerImagePath('default-container.png')"
            class="nodata"
          />

          <div data-v-abef72c2="" class="color-999 noDataText">
            {{ $t("football.league-details.no-live-match") }}
          </div>
        </div>
        <div class="w100" v-for="(league, key) in todayMatchList" :key="key">
          <div style="width: 100%">
            <!-- begin::show league & country -->
            <div class="list_title">
              <i class="iconfont collectImg" style="visibility: hidden"></i>
              <div
                class="flex align-center"
                style="overflow: hidden; width: 8.4rem"
              >
                <div
                  class="listImg van-image van-image--round"
                  itemprop="logo"
                  style="overflow: hidden; border-radius: 50%"
                >
                  <img
                    class="van-image__img"
                    v-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      ((league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo) ||
                        (league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name) ||
                        (league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name))
                    "
                    :src="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo
                        ? league[0].leagueInfo.countryDetails.logo
                       : bannerImagePath('default-country.png')
                    "
                    alt=""
                    lazy="loaded"
                    style="object-fit: contain"
                  />
                </div>

                <span data-v-89734b7c="" class="countryText">
                  <span data-v-f1b1cb6e="" class="country-name minitext">
                    <template v-if="league[0] && league[0].leagueInfo">
                      <template v-if="this.$i18n.locale == 'cn'">
                        <template
                          v-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.cn_name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.cn_name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}:
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            league[0].leagueInfo.countryDetails &&
                            league[0].leagueInfo.countryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.countryDetails.name }}:
                        </template>
                        <template
                          v-else-if="
                            league[0].leagueInfo.categoryDetails &&
                            league[0].leagueInfo.categoryDetails.name
                          "
                        >
                          {{ league[0].leagueInfo.categoryDetails.name }}:
                        </template>
                        <template v-else> </template>
                      </template>
                    </template>
                  </span>
                </span>

                <a
                  v-if="
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo &&
                    league[0].hasLeagueDetails
                  "
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/table_tennis/league/details/' +
                    league[0].leagueInfo.en_name
                      .toLowerCase()
                      .replace(/[\s/]+/g, '-') +
                    '/' +
                    league[0].unique_tournament_id
                  "
                  class="compText"
                  style="font-size: 0.32rem"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
                <a v-else class="compText">
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
              </div>
              <div class="flex userNumBox">
                <!-- <span class="iconfont icon-renshu"></span> -->
                <span class="userNum"> </span>
              </div>
            </div>
            <!-- end::show league & country -->
            <!-- begin::match info -->
            <div class="w100 list_active">
              <div
                class="list"
                v-for="match in league"
                :key="match.id"
                style="height: 1.38rem; position: relative"
              >
                <div class="w100 flex h100 contentListBox">
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/table_tennis/match/details/' +
                      match.homeInfo.en_name.toLowerCase().replace(/[\s/]+/g, '-') +
                      '-vs-' +
                      match.awayInfo.en_name.toLowerCase().replace(/[\s/]+/g, '-') +
                      '/' +
                      match.id
                    "
                    class="flex flex-1 h100 allBox"
                  >
                    <!-- <i class="iconfont collectImg icon-weishoucang"></i> -->
                    <div class="w100 flex h100 align-center">
                      <div
                        class="flex align-center justify-center listBox flex-column"
                      >
                        <span
                          class="fs-12 text-center"
                          style="color: rgb(153, 153, 153)"
                        >
                          {{
                            readableTime(this.$i18n.locale, match.match_time)
                          }}
                        </span>

                        <div
                          class="flex fs-12 align-center color-r text-center"
                        >
                          <span class="fs-12" style="white-space: nowrap">{{
                            match.status_id &&
                            this.FinishedMatches.includes(match.status_id)
                              ? $t("football.league-details.full-time")
                              : match?.statusDescription
                          }}</span>
                        </div>
                      </div>
                      <div class="team flex flex-col padding5">
                        <div class="flex-1 align-center flex">
                          <div class="flex align-center" style="width: 0.4rem">
                            <div class="flex-1 align-center flex">
                              <div
                                class="flex align-center"
                                style="width: 0.4rem"
                              >
                                <div
                                  class="imgw4 van-image van-image--round"
                                  itemprop="logo"
                                  style="overflow: hidden; border-radius: 50%"
                                >
                                  <img
                                    v-if="match.homeInfo"
                                    :src="
                                      match.homeInfo.logo
                                        ? match.homeInfo.logo
                                      : bannerImagePath('default-team.png')
                                    "
                                    lazy="loaded"
                                    alt=""
                                    class="van-image__img"
                                    style="object-fit: contain"
                                  />
                                </div>
                              </div>
                              <div
                                itemprop="homeTeam"
                                class="w-o-h fs-14"
                                style="padding-left: 0.2rem; max-width: 4.8rem"
                              >
                                <template v-if="match && match.homeInfo"
                                  >{{
                                    this.$i18n.locale == "cn" &&
                                    match.homeInfo.cn_name
                                      ? match.homeInfo.cn_name
                                      : match.homeInfo.en_name
                                      ? match.homeInfo.en_name
                                      : ""
                                  }}
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="flex-1 align-center flex">
                          <div class="flex align-center" style="width: 0.4rem">
                            <div class="flex-1 align-center flex">
                              <div
                                class="flex align-center"
                                style="width: 0.4rem"
                              >
                                <div
                                  class="imgw4 van-image van-image--round"
                                  itemprop="logo"
                                  style="overflow: hidden; border-radius: 50%"
                                >
                                  <img
                                    v-if="match.awayInfo"
                                    :src="
                                      match.awayInfo.logo
                                        ? match.awayInfo.logo
                                      : bannerImagePath('default-team.png')
                                    "
                                    lazy="loaded"
                                    alt="Punjab Royals"
                                    class="van-image__img"
                                    style="object-fit: contain"
                                  />
                                </div>
                              </div>
                              <div
                                itemprop="awayTeam"
                                class="w-o-h fs-14 pl2"
                                style="padding-left: 0.2rem; max-width: 4.8rem"
                              >
                                <template v-if="match && match.awayInfo"
                                  >{{
                                    this.$i18n.locale == "cn" &&
                                    match.awayInfo.cn_name
                                      ? match.awayInfo.cn_name
                                      : match.awayInfo.en_name
                                      ? match.awayInfo.en_name
                                      : ""
                                  }}
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="icon-setting">
                        <span
                          v-if="liveMatches.includes(match.status_id)"
                          class="iconfont icon-shipinzhibo"
                        ></span>
                      </div>
                      <div class="teamScore">
                        <div class="w100 flex h100">
                          <div
                            class="flex-1 h100 padding5"
                            style="border-right: 1px solid rgb(227, 227, 227)"
                          >
                            <div
                              class="flex flex-col align-center w100"
                              style="height: 100%; line-height: 12px"
                            >
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="onescore"
                                    style="
                                      font-size: 0.32rem;
                                      padding: 0px 2px;
                                      font-family: Roboto-Medium !important;
                                      color: #87c31d !important;
                                    "
                                    >{{
                                      match.scores &&
                                      getCurrentSetNumberFromStatusId(
                                        match.status_id
                                      ) != -1 &&
                                      match.scores[
                                        `p${getCurrentSetNumberFromStatusId(
                                          match.status_id
                                        )}`
                                      ]
                                        ? match.scores[
                                            `p${getCurrentSetNumberFromStatusId(
                                              match.status_id
                                            )}`
                                          ][0]
                                        : 0
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="onescore"
                                    style="
                                      font-size: 0.32rem;
                                      padding: 0px 2px;
                                      font-family: Roboto-Medium !important;
                                      color: #87c31d !important;
                                    "
                                    >{{
                                      match.scores &&
                                      getCurrentSetNumberFromStatusId(
                                        match.status_id
                                      ) != -1 &&
                                      match.scores[
                                        `p${getCurrentSetNumberFromStatusId(
                                          match.status_id
                                        )}`
                                      ]
                                        ? match.scores[
                                            `p${getCurrentSetNumberFromStatusId(
                                              match.status_id
                                            )}`
                                          ][1]
                                        : 0
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex-1 h100 padding5">
                            <div
                              class="flex flex-col align-center w100"
                              style="height: 100%; line-height: 12px"
                            >
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center scoreWeight"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="scoreRed"
                                    style="font-size: 0.32rem; padding: 0px 2px"
                                    >{{
                                      match.scores && match.scores.ft
                                        ? match.scores.ft[0]
                                        : ""
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div
                                class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                style="font-family: Roboto-Regular, Roboto"
                              >
                                <div
                                  class="flex-1 text-center"
                                  style="
                                    font-family: Roboto-Regular, Roboto;
                                    font-weight: bolder;
                                  "
                                >
                                  <span
                                    class="scoreRed"
                                    style="font-size: 0.32rem; padding: 0px 2px"
                                    >{{
                                      match.scores && match.scores.ft
                                        ? match.scores.ft[1]
                                        : ""
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- end::match info -->
          </div>
        </div>
        <!-- begin::upcoming -->
        <UpcomingMatches />
        <!-- end::upcoming -->
      </template>
    </div>
  </div>
  <!--begin::footer -->
  <MobileFooter />
  <!-- end::footter -->
</template>
<!-- begin::scripts -->
<script>
import UpcomingMatches from "./tabs/upcoming-matches.vue";
import SearchMatchList from "./tabs/search-matches-list";
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],

  components: {
    UpcomingMatches,
    
    SearchMatchList,
  },
  data() {
    return {
      searchListUpcoming: null,
      searchListLive: null,
      todayUpcomingMatchList: null,

      todayMatchList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [],
      FinishedMatches: [100],
      leagueFavoriteStatus: {}, // Store favorite status for each league
      sets_match_status: [51, 52, 53, 54, 55, 56, 57],
      match_status: {
        NOT_STARTED: 1,
        FIRST_SET: 51,
        SECOND_SET: 52,
        THIRD_SET: 53,
        FOURTH_SET: 54,
        FIFTH_SET: 55,
        SIXTH_SET: 56,
        SEVENTH_SET: 57,
      },
    };
  },
  created() {
    this.getTodayMatchList(this.liveActivebtn);
    this.getUpcomingMatchList();
  },
  mounted() {
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getUpcomingMatchList() {
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/table_tennis/match/list/today?matchStatus=upcoming`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.table_tennisMatchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `tennis_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`tennis_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`tennis_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get today matchlist
    getTodayMatchList(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/table_tennis/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.table_tennisMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCurrentSetNumberFromStatusId(status_id) {
      let val = this.sets_match_status.findIndex((value) => status_id == value);
      return val == -1 ? val : val + 1;
    },
  },
};
</script>
