<template>
  <span data-v-4a160e70="" v-if="!loading">
    <!-- begin::bet365 -->
    <div
      class="flex justify-between flex-col live"
      data-v-3bb54c1c=""
      data-v-4a160e70=""
      v-if="
        liveMatchDetails &&
        liveMatchDetails.matchDetails &&
        liveMatchDetails.matchDetails.matchOdds &&
        liveMatchDetails.matchDetails.matchOdds.bet365
      "
    >
      <div class="flex odds" data-v-3bb54c1c="">
        <div class="table flex-1 oddType" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <div class="oddContent" data-v-3bb54c1c="">
              <img
                data-v-3bb54c1c=""
                src="/images/odds/bet365.png"
                alt="#"
                class="oddType"
              />
            </div>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.opening-odds") }}
            </span>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.pre-match-odds") }}
            </span>
          </div>
        </div>
        <!-- begin::bigSmall -->
        <div class="table flex-1 eu" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            {{ $t("football.league-details.to-win") }}
            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::bigSmall opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][1]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][1].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall opening odds -->
          <!-- begin::bigSmall pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ][1]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                          liveMatchDetails.matchDetails.matchOdds.bet365.euro
                            .length - 1
                        ][1].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                          liveMatchDetails.matchDetails.matchOdds.bet365.euro
                            .length - 1
                        ][3].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall pre-match odds -->
        </div>
        <!-- end::bigSmall -->
        <!-- begin::Asian Handicaps -->
        <div class="table flex-1 asia" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.spread") }}
            </span>

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::Asian Opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #f6fcff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365
                      .asia[0][2] !== undefined
                      ? liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] < 0
                        ? "+" +
                          processNumber(
                            -liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[0][2]
                          )
                        : liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2] > 0
                        ? "-" +
                          processNumber(
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[0][2]
                          )
                        : "0"
                      : "-"
                  }}
                </span>

                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0]
                      ? (
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][1] + 1
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365
                      .asia[0][2] !== undefined
                      ? liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] < 0
                        ? "-" +
                          processNumber(
                            -liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[0][2]
                          )
                        : liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2] > 0
                        ? "+" +
                          processNumber(
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[0][2]
                          )
                        : "0"
                      : "-"
                  }}
                </span>

                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0]
                      ? (
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][3] + 1
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::Asian Opening odds -->
          <!-- begin::Asian pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 252, 249);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia.length >
                      0 &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ][3] !== undefined
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][3] < 0
                        ? "+" +
                          processNumber(
                            -liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ][2]
                          )
                        : liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2] > 0
                        ? "-" +
                          processNumber(
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ][2]
                          )
                        : liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2] == 0
                        ? "0"
                        : "-"
                      : "-"
                  }}
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ]
                      ? (
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][1] + 1
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia.length >
                      0 &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ][1] !== undefined
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][1] < 0
                        ? "-" +
                          processNumber(
                            -liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ][1]
                          )
                        : liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][1] > 0
                        ? "+" +
                          processNumber(
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ][1]
                          )
                        : "0"
                      : "-"
                  }}
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia.length >
                      0 &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ]
                      ? (
                          (liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2] || 0) + 1
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::Asian pre-match odds -->
        </div>
        <!-- end::Asian Handicap -->
        <!-- begin::over under -->
        <div class="table flex-1 bs" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.total-points") }}</span
            >
          </div>
          <!-- begin::over-under opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="handicap">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365
                          .bigSmall[0][3]
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365
                          .bigSmall[0][2]
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365
                          .bigSmall[0][4]
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::over-under opening odds -->
          <!-- begin::over-under pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="handicap">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall.length - 1
                        ][3]
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][2]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall.length - 1
                        ][2]
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="">
           
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][4]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall.length - 1
                        ][4]
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- begin::over-under pre-match odds -->
        </div>
        <!-- end::over under -->
      </div>
    </div>
    <!-- else part -->
    <div
      class="flex justify-between flex-col live"
      data-v-3bb54c1c=""
      data-v-4a160e70=""
      v-else
    >
      <div class="flex odds" data-v-3bb54c1c="">
        <div class="table flex-1 oddType" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <div class="oddContent" data-v-3bb54c1c="">
              <img
                data-v-3bb54c1c=""
                src="/images/odds/bet365.png"
                alt="#"
                class="oddType"
              />
            </div>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.opening-odds") }}
            </span>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.pre-match-odds") }}
            </span>
          </div>
        </div>
        <!-- begin::bigSmall -->
        <div class="table flex-1 eu" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">{{
              $t("football.league-details.to-win")
            }}</span>

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::bigSmall opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall opening odds -->
          <!-- begin::bigSmall pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall pre-match odds -->
        </div>
        <!-- end::bigSmall -->
        <!-- begin::Asian Handicaps -->
        <div class="table flex-1 asia" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.spread") }}</span
            >

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::Asian Opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #f6fcff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>

                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
            </div>
          </div>
          <!-- end::Asian Opening odds -->
          <!-- begin::Asian pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 252, 249);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
            </div>
          </div>
          <!-- end::Asian pre-match odds -->
        </div>
        <!-- end::Asian Handicap -->
        <!-- begin::over under -->
        <div class="table flex-1 bs" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.total-points") }}</span
            >
            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::over-under opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="handicap"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" >
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::over-under opening odds -->
          <!-- begin::over-under pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class="handicap"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="" style="cursor:auto !important;">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- begin::over-under pre-match odds -->
        </div>
        <!-- end::over under -->
      </div>
    </div>
    <!-- end::else part -->
    <!--end::bet365 -->
  </span>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
      liveMatches: [2, 3, 4, 5, 6, 7],
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/details/` + matchId)
        .then((response) => {
          this.liveMatchDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to check decimal value
    processNumber(value) {
      if (value == 0) {
        return value;
      }

      const stringValue = value.toString();
      const decimalIndex = stringValue.indexOf(".");

      if (decimalIndex !== -1 && decimalIndex < stringValue.length - 2) {
        const decimalPart = stringValue.slice(
          decimalIndex + 1,
          decimalIndex + 3
        );

        if (decimalPart === "25") {
          return `${Math.floor(value)}/${Math.floor(value) + 0.5}`;
        } else if (decimalPart === "75") {
          return `${Math.floor(value) + 0.5}/${Math.floor(value)}`;
        }
      }

      if (stringValue.endsWith(".5")) {
        return stringValue;
      }

      // Default return when none of the conditions are met
      return stringValue;
    },
  },
};
</script>
