<template>
  <div data-fetch-key="0" class="footer border-box" data-v-2c0a9f08="">
    <div class="contact" data-v-2c0a9f08="">
      <div class="about-us bd-bottom" data-v-2c0a9f08="">
        <div class="flex" data-v-2c0a9f08="">
          <div class="foot-l" data-v-2c0a9f08="">
            <svg aria-hidden="true" class="icon w-114 h-30" data-v-2c0a9f08="">
              <use xlink:href="#icon-AiScorelogo" data-v-2c0a9f08=""></use>
            </svg>
            <div
              class="fs-12 mt-24 mb-24 line-h-16"
              data-v-2c0a9f08=""
              v-if="this.$i18n.locale == 'cn'"
            >
              欢迎来到运动俱乐部！在 Sportyclub，我们热衷于
              运动的。我们的目标是成为所有体育爱好者的一站式商店
              全世界。我们希望成为首选平台，不仅
              为您带来最新的分数和结果，同时还提供
              您可以了解游戏中最重要的球员的内幕消息
              各种运动。我们为您提供深入的分析，玩家
              简介和独家采访。作为本网站的用户，您
              将获得现场体育赛事、实时比分、即将到来的赛程，
              新闻和统计数据。我们提供实时分数更新和
              每场比赛的详细统计数据。我们的平台还提供
              全面报道即将举行的比赛，以便您随时掌握
              您最喜欢的球队并相应地计划您的观看时间表。
              此外，我们的新闻部分提供最新的更新，专家
              分析以及对球员和教练的独家采访。 无论您是对比赛预览还是赛后感兴趣
              分析，Sportyclub 可以满足您的需求。
            </div>
            <div
              class="fs-12 mt-24 mb-24 line-h-16 text-white"
              data-v-2c0a9f08=""
              v-else
              style="color: white"
            >
              Welcome To Sportyclub! At Sportyclub, we are passionate about
              sports. Our goal is to be a one-stop shop for sports lovers all
              over the world. We want to be the go-to platform that not only
              brings you the most up-to-date scores and results but also gives
              you the inside scoop on the game’s most important players across
              various sports. We provide you with in-depth analyses, player
              profiles, and exclusive interviews. As a user of this website, you
              will get access to live sports, live scores, upcoming schedules,
              news, and statistics. We offer real-time score updates and
              detailed statistics for every game. Our platform also provides
              comprehensive coverage of upcoming matches, so you can keep track
              of your favorite teams and plan your viewing schedule accordingly.
              Additionally, our news section offers the latest updates, expert
              analyses, and exclusive interviews with players and coaches.
              Whether you're interested in match previews or post-match
              analyses, Sportyclub has you covered.
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bt-map bd-bottom" data-v-2c0a9f08="">
        <div class="flex" data-v-2c0a9f08="">
          <div class="box" data-v-2c0a9f08="">
            <div class="title" data-v-2c0a9f08="">
              <a data-v-2c0a9f08="">
                {{ $t("football.league-details.cricket-hot-leagues") }}</a
              >
            </div>
            <ul class="list" data-v-2c0a9f08="">
              <li
                data-v-2c0a9f08=""
                v-for="hotLeague in cricketHotLeaguesList"
                :key="hotLeague.id"
              >
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/league/details/' +
                    hotLeague.nameEn.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    hotLeague.id
                  "
                  target="_blank"
                  class="link"
                  data-v-2c0a9f08=""
                  >{{ hotLeague.nameEn }}</a
                >
              </li>
            </ul>
          </div>
          <div class="box" data-v-2c0a9f08="">
            <div class="title" data-v-2c0a9f08="">
              <a data-v-2c0a9f08="">{{
                $t("football.league-details.football-hot-leagues")
              }}</a>
            </div>
            <ul class="list" data-v-2c0a9f08="">
              <li
                data-v-2c0a9f08=""
                v-for="league in footballHotLeaguesList"
                :key="league"
              >
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/league/details/' +
                    league.nameEn.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    league.id
                  "
                  target="_blank"
                  class="link"
                  data-v-2c0a9f08=""
                >
                  {{
                    this.$i18n.locale == "cn" ? league.nameCn : league.nameEn
                  }}</a
                >
              </li>
            </ul>
          </div>

          <div class="box" data-v-2c0a9f08="">
            <div class="title" data-v-2c0a9f08="">
              {{ $t("football.league-details.basketball-hot-leagues") }}
            </div>
            <ul class="list" data-v-2c0a9f08="">
              <li
                data-v-2c0a9f08=""
                v-for="league in basketballHotLeaguesList"
                :key="league"
              >
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/basketball/league/details/' +
                    league.nameEn.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    league.id
                  "
                  target="_blank"
                  class="link"
                  data-v-2c0a9f08=""
                >
                  {{
                    this.$i18n.locale == "cn" ? league.nameCn : league.nameEn
                  }}</a
                >
              </li>
            </ul>
          </div>
          <div class="box" data-v-2c0a9f08="">
            <div class="title" data-v-2c0a9f08="">
              {{ $t("football.league-details.tennis-hot-leagues") }}
            </div>
            <ul class="list" data-v-2c0a9f08="">
              <li
                data-v-2c0a9f08=""
                v-for="league in tennisHotLeaguesList"
                :key="league"
              >
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/tennis/league/details/' +
                    league.nameEn.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    league.id
                  "
                  target="_blank"
                  class="link"
                  data-v-2c0a9f08=""
                >
                  {{
                    this.$i18n.locale == "cn" ? league.nameCn : league.nameEn
                  }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="clearfix-row text-center mt-24 mb-24" data-v-2c0a9f08="">
        <a
          href="https://www.linkedin.com/in/sportyclub-live-5852392a5/"
          target="_blank"
          rel="sponsored"
          class="mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/linkedin.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
        <a
          href="https://www.facebook.com/sportyclub.lives/"
          target="_blank"
          rel="sponsored"
          class="ml-15 mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/facebook.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
        <a
          href="https://www.tiktok.com/@sportyclub.live"
          target="_blank"
          rel="sponsored"
          class="ml-15 mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/tiktok.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
      </div>

      <div data-v-2c0a9f08="" class="clearfix-row text-center fs-12">
        <a
          data-v-2c0a9f08=""
          :href="
            this.$i18n.locale == 'cn'
              ? 'https://sportyclub.live/cn/about-us'
              : 'https://sportyclub.live/en/about-us'
          "
          target="_blank"
          rel="nofollow"
          class="text-item"
        >
          {{ $t("football.league-details.about-us") }}
        </a>
        <a
          :href="
            this.$i18n.locale == 'cn'
              ? 'https://sportyclub.live/cn/privacy-policy'
              : 'https://sportyclub.live/en/privacy-policy'
          "
          data-v-2c0a9f08=""
          target="_blank"
          rel="nofollow"
          class="text-item"
          >{{ $t("football.league-details.privacy-policy") }}</a
        >

        <a
          data-v-2c0a9f08=""
          :href="
            this.$i18n.locale == 'cn'
              ? 'https://sportyclub.live/cn/terms-and-conditions'
              : 'https://sportyclub.live/en/terms-and-conditions'
          "
          target="_blank"
          rel="nofollow"
          class="text-item"
          >{{ $t("football.league-details.terms-and-conditions") }}</a
        >
      </div>

      <div
        class="clearfix-row text-center fs-12 mb-16 mt-10 color-w"
        data-v-2c0a9f08=""
      >
        <span
          class="text-item"
          style="color: rgba(255, 255, 255, 0.4)"
          data-v-2c0a9f08=""
        >
          <span v-if="this.$i18n.locale == 'cn'">
            版权所有@2024 Sportyclub Live
          </span>

          <span v-else> Copyright @ 2024 Sportyclub Live </span>
        </span>

        <div class="dmca-badge-container" data-v-2c0a9f08="">
          <br />
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=d8947d4b-444d-43ba-a6b5-b2b66372cbe2"
            title="DMCA.com Protection Status"
            class="dmca-badge"
          >
            <img
              src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=d8947d4b-444d-43ba-a6b5-b2b66372cbe2"
              alt="DMCA.com Protection Status"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cricketHotLeaguesList: null,
      footballHotLeaguesList: null,
      basketballHotLeaguesList: null,
      tennisHotLeaguesList: null,
    };
  },
  mounted() {
    // Load the DMCA badge script after component mounts
    const script = document.createElement("script");
    script.src = "https://images.dmca.com/Badges/DMCABadgeHelper.min.js";
    script.async = true;
    document.head.appendChild(script);
  },
  created() {
    this.getCricketHotLeaguesList();
    this.getFootballHotLeaguesList();
    this.getBasketballHotLeaguesList();
    this.getTennisHotLeaguesList();
  },
  methods: {
    // method to get hot leagues list cricket
    getCricketHotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/league/list`)
        .then((response) => {
          this.cricketHotLeaguesList = response.data.topLeagues;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list football
    getFootballHotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/league/list`)
        .then((response) => {
          this.footballHotLeaguesList = response.data.footballHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list of basketball
    getBasketballHotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/league/list`)
        .then((response) => {
          this.basketballHotLeaguesList =
            response.data.basketballHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTennisHotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/league/list`)
        .then((response) => {
          this.tennisHotLeaguesList = response.data.tennisHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
