<template>
  <div>
    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px)"
      v-for="(league, key) in todayUpcomingMatchList"
      :key="key"
    >
      <div class="comp-container">
        <!-- begin::league title -->
        <div
          data-v-f1b1cb6e=""
          data-id="2j374oirla4qo6d"
          class="title"
          :class="
            leagueFavoriteStatus[league[0].competition_id] ? 'collectName' : ''
          "
        >
          <i
            class="iconfont"
            :class="
              leagueFavoriteStatus[league[0].competition_id]
                ? 'icon-yishoucang'
                : 'icon-weishoucang'
            "
            @click="toggleFavorite(league[0].competition_id)"
            style="visibility: visible"
          ></i>

          <i
            data-v-f1b1cb6e=""
            class="country-logo squareLogo"
            style="height: -12px !important"
          >
            <img
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                ((league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.logo) ||
                  (league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.name) ||
                  (league[0].leagueInfo.categoryDetails &&
                    league[0].leagueInfo.categoryDetails.name))
              "
              :src="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.logo
                  ? league[0].leagueInfo.countryDetails.logo
                 : bannerImagePath('default-country.png')
              "
              style="width: 100%"
            />
          </i>

          <span
            v-if="this.$i18n.locale == 'cn'"
            data-v-f1b1cb6e=""
            class="country-name minitext"
          >
            <span
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.cn_name
              "
            >
              {{ league[0].leagueInfo.countryDetails.cn_name }}:
            </span>

            <span
              v-else-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.categoryDetails &&
                league[0].leagueInfo.categoryDetails.cn_name
              "
            >
              {{ league[0].leagueInfo.categoryDetails.cn_name }}:
            </span>
          </span>

          <span v-else data-v-f1b1cb6e="" class="country-name minitext">
            <span
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.name
              "
            >
              {{ league[0].leagueInfo.countryDetails.name }}:
            </span>
            <span
              v-else-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.categoryDetails &&
                league[0].leagueInfo.categoryDetails.name
              "
            >
              {{ league[0].leagueInfo.categoryDetails.name }}:
            </span>
          </span>

          <a
            v-if="league[0].hasLeagueDetails"
            data-v-f1b1cb6e=""
            :href="
              '/' +
              this.$i18n.locale +
              '/football/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "
            target="_blank"
            class="compe-name minitext"
          >
            {{
              this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                ? league[0].leagueInfo.cn_name
                : league[0].leagueInfo.en_name
                ? league[0].leagueInfo.en_name
                : ""
            }}
          </a>
          <a
            v-else
            data-v-f1b1cb6e=""
            target="_blank"
            class="compe-name minitext"
            style="curson: not-allowed"
          >
            {{
              this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                ? league[0].leagueInfo.cn_name
                : league[0].leagueInfo.en_name
                ? league[0].leagueInfo.en_name
                : ""
            }}
          </a>

          <div data-v-f1b1cb6e="" class="flex-1">
            <span data-v-f1b1cb6e="" class="watchNum" style="margin-right: 1px">
            </span>
          </div>
          <ul data-v-f1b1cb6e="" class="odds">
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">1</span>
            </li>
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">X</span>
            </li>
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">2</span>
            </li>
          </ul>
        </div>

        <!-- end::league title -->
        <!-- begin::teams -->
        <div class="comp-list" v-for="match in league" :key="match.id">
          <a
            v-if="match && match.homeInfo && match.awayInfo"
            data-v-0d00ff82=""
            :href="
              '/' +
              this.$i18n.locale +
              '/football/match/details/' +
              match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '-vs-' +
              match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              match.id
            "
            target="_blank"
            class="match-container"
            ><span data-v-0d00ff82="" class="left-box"
              ><span data-v-0d00ff82="" class="v-st-box">
                <span data-v-0d00ff82="" class="time minitext">
                  {{ readableTime(this.$i18n.locale, match.match_time) }}
                </span>
                <span
                  data-v-0d00ff82=""
                  class="status minitext on twinkle"
                  v-if="liveMatches.includes(match.status_id)"
                >
                  {{
                    onGoingMatchTime(match.counter_timing, match.status_id)
                  }}</span
                ></span
              >

              <span data-v-0d00ff82="" class="v-tt-box">
                <!-- begin::home info -->
                <span data-v-0d00ff82="" class="team home"
                  ><span data-v-0d00ff82="" class="flex-1 teamBox teamHomeBox">
                    <!-- begin::teams info -->
                    <span data-v-0d00ff82="" class="nameBox"
                      ><span
                        data-v-0d00ff82=""
                        itemprop="homeTeam"
                        class="name minitext maxWidth160"
                      >
                        {{
                          match &&
                          match.homeInfo &&
                          match.homeInfo.cn_name &&
                          this.$i18n.locale == "cn"
                            ? match.homeInfo.cn_name
                            : match.homeInfo.en_name
                            ? match.homeInfo.en_name
                            : ""
                        }}
                      </span></span
                    >
                    <span
                      data-v-0d00ff82=""
                      class="teamLogoBox teamLogoHomeBox"
                    >
                      <img
                        v-if="match.homeInfo"
                        :src="
                          match.homeInfo.logo
                            ? match.homeInfo.logo
                            : '/images/default-football.jpg'
                        "
                      />
                    </span> </span
                ></span>
                <!-- end::home info -->
                <!-- begin::away info -->
                <!-- begin::score section -->
                <span data-v-0d00ff82="" class="scores on"
                  ><span data-v-0d00ff82="" class="score-home minitext">
                    {{
                      liveMatches.includes(match.status_id) ||
                      FinishedMatches.includes(match.status_id)
                        ? match.homeInfo.home_score
                        : ""
                    }}
                  </span>
                  <span
                    data-v-0d00ff82=""
                    class="or"
                    style="background-color: transparent !important"
                    >-</span
                  >
                  <span data-v-0d00ff82="" class="score-away minitext">
                    {{
                      liveMatches.includes(match.status_id) ||
                      FinishedMatches.includes(match.status_id)
                        ? match.awayInfo.away_score
                        : ""
                    }}
                  </span>
                </span>

                <!-- end::score section -->
                <span data-v-0d00ff82="" class="team away"
                  ><span data-v-0d00ff82="" class="flex-1 teamBox teamAwayBox"
                    ><span
                      data-v-0d00ff82=""
                      class="teamLogoBox teamLogoAwayBox"
                    >
                      <img
                        v-if="match.awayInfo"
                        :src="
                          match.awayInfo.logo
                            ? match.awayInfo.logo
                            : '/images/default-football.jpg'
                        "
                      />
                    </span>
                    <span data-v-0d00ff82="" class="nameBox"
                      ><span
                        data-v-0d00ff82=""
                        itemprop="awayTeam"
                        class="name minitext maxWidth160"
                      >
                        {{
                          match &&
                          match.awayInfo &&
                          match.awayInfo.cn_name &&
                          this.$i18n.locale == "cn"
                            ? match.awayInfo.cn_name
                            : match.awayInfo.en_name
                            ? match.awayInfo.en_name
                            : ""
                        }}
                      </span></span
                    >
                  </span></span
                >
                <!-- end::away info -->
              </span>
              <!-- end::teams info -->
            </span>
            <ul
              data-v-0d00ff82=""
              class="odds oddhoverGreen newOddsBox"
              v-if="
                match &&
                match.odds &&
                match.odds != '' &&
                match.odds.euro &&
                match.odds.euro != ''
              "
            >
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text">
                  {{ match.odds.euro[2] ? match.odds.euro[2] : "-" }}
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                  {{ match.odds.euro[3] ? match.odds.euro[3] : "-" }}
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text loseBottom">
                  {{ match.odds.euro[4] ? match.odds.euro[4] : "-" }}
                </span>
              </li>
            </ul>
            <ul data-v-0d00ff82="" class="odds oddhoverGreen newOddsBox" v-else>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text">
                  <b>-</b>
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                  <b>-</b>
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text loseBottom">
                  <b>-</b>
                </span>
              </li>
            </ul>
          </a>
        </div>
        <!-- end::teams -->
      </div>
    </div>
  </div>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
export default {
  mixins: [DateMixin],
  data() {
    return {
      todayMatchList: null,
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
      countryList: null,
      countryLeagueActive: "",
      todayUpcomingMatchList: null,

      leagueFavoriteStatus: {}, // Store favorite status for each league
    };
  },
  created() {
    this.getUpcomingMatchList("upcoming");
  },

  methods: {
    loadFavoriteStatus() {
      if (this.todayUpcomingMatchList) {
        console.log(
          "Today's Upcoming Match List:",
          this.todayUpcomingMatchList
        );
        Object.values(this.todayUpcomingMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const competitionId = match.competition_id;

              const favoriteStatus = localStorage.getItem(
                `football_${competitionId}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`football_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayUpcomingMatchList) {
        this.todayUpcomingMatchList.forEach((league) => {
          const leagueId = league[0].competition_id;
          const favoriteStatus = localStorage.getItem(`football_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },

    // method to get upcoming matchlist
    getUpcomingMatchList(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.footballMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      if (status_code == 3) {
        return "HT";
      }
      return minutes;
    },
  },
  // mounted() {
  //   this.loadFavoriteStatus(); // Load favorite statuses from local storage
  // },
};
</script>
