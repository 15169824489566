<template>
  <div class="searchBox flex align-center" style="" data-v-736e8afd="">
    <!-- <span data-v-736e8afd=""
      ><i
        class="iconfont activeBall icon-zuqiu-weixuanzhong"
        data-v-736e8afd=""
      ></i>
      <i class="iconfont icon-xiala" data-v-736e8afd=""></i
    ></span> -->
    <div class="inputBox flex align-center ml-12" data-v-736e8afd="">
      <i class="iconfont icon-sousuo ml-12" data-v-736e8afd=""></i>
      <input
        @input="searchingDebounce"
        v-model="searchText"
        value=""
        class="ml-8"
        data-v-736e8afd=""
        autofocus
      />
      <i
        class="iconfont icon-guanbi"
        style="display: none"
        data-v-736e8afd=""
      ></i>
    </div>
    <span
      @click="() => this.$emit('closeSearchBox')"
      class="searchIconBox flex justify-center align-center ml-8"
      data-v-736e8afd=""
      ><i class="iconfont icon-guanbi" data-v-736e8afd=""></i
    ></span>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SearchBox",
  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    searchingDebounce: _.debounce(function () {
      this.emitter.emit("searchText", { text: this.searchText });
    }, 500),
  },
};
</script>

<style>
</style>