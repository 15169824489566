<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->

  <div
    type="overview"
    class="odds-box"
    data-v-826fe974=""
    data-v-22540cb9=""
    v-else
  >
    <div class="title-box flex align-center justify-between" data-v-826fe974="">
      <a class="text" data-v-826fe974="">{{
        $t("football.league-details.odds")
      }}</a>
    </div>
    <div class="list" data-v-826fe974="">
      <div class="oddsBox" data-v-0e07873a="" data-v-826fe974="">
        <div class="oddsContent" data-v-0e07873a="">
          <p class="oddsType flex" data-v-0e07873a="">
            <span class="placeholder" data-v-0e07873a=""></span>
            <span
              data-v-0e07873a=""
              class="oddsItem flex-1 justify-center align-center"
            >
              1
            </span>
            <span
              data-v-0e07873a=""
              class="oddsItem flex-1 justify-center align-center"
            >
              X
            </span>
            <span
              data-v-0e07873a=""
              class="oddsItem flex-1 justify-center align-center"
            >
              2
            </span>
          </p>
          <!-- begin::bet365 company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.bet365
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/bet365.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::bet365 company -->
          <!-- begin::crown company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.Crown
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/Crown.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.Crown.euro &&
                    liveMatchDetails.matchDetails.matchOdds.Crown.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.Crown.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::crown company -->
          <!-- begin:: Ladbrokes company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.Ladbrokes
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/Ladbrokes.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                        .euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                        .euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro &&
                    liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                        .euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Ladbrokes
                        .euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.Ladbrokes.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end:: Ladbrokes company -->
          <!-- begin::10bet company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.bet10
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/10bet.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet10.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet10.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.bet10.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::10bet company -->
          <!-- begin::12bet company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.bet12
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/12bet.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet12.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet12.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.bet12.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::12bet company -->
          <!-- begin::wewbet company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.Wewbet
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/wewbet.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.Wewbet.euro &&
                    liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro &&
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.Wewbet.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::wewbet company -->
          <!-- begin::bet18 company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.bet18
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/18bet.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet18.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet18.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.bet18.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::bet18 company -->
          <!-- begin::bet188 company -->
          <div
            data-v-0e07873a=""
            class="oddsBox flex"
            v-if="
              liveMatchDetails &&
              liveMatchDetails.matchDetails &&
              liveMatchDetails.matchDetails.matchOdds &&
              liveMatchDetails.matchDetails.matchOdds.bet188
            "
          >
            <div
              data-v-0e07873a=""
              class="oddsBoxLeft flex justify-center align-center"
            >
              <img
                data-v-0e07873a=""
                src="/images/odds/188bet.png"
                class="companyLogo"
              />
            </div>
            <div data-v-0e07873a="" class="oddsBoxContent flex-1">
              <div data-v-0e07873a="" class="oddsItem flex flex-1 border3">
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][2]
                        ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][2].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center borderR"
                  style="color: rgb(230, 47, 43)"
                >
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet188.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet188.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
                <span
                  data-v-0e07873a=""
                  class="flex flex-1 justify-center align-center"
                >
                  <span
                    data-v-0e07873a=""
                    class="flex justify-center align-center"
                    style="color: rgb(93, 180, 0)"
                  >
                    {{
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro &&
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro[0] &&
                      liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][4]
                        ? liveMatchDetails.matchDetails.matchOdds.bet188.euro[0][4].toFixed(
                            2
                          )
                        : "-"
                    }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <!-- end::bet188 company -->
          <p class="hint" data-v-0e07873a="">
            {{ $t("football.league-details.gamble-responsibly") }}
          </p>
          <p class="oddsTypeText mt-12" data-v-0e07873a="">
            <span class="openingBox" data-v-0e07873a=""
              ><span class="chunk opening" data-v-0e07873a=""></span>
              <span data-v-0e07873a="">
                {{ $t("football.league-details.opening-odds") }}</span
              ></span
            >
            <span class="openingBox mt-8" data-v-0e07873a=""
              ><span class="chunk pre-match" data-v-0e07873a=""></span>
              <span data-v-0e07873a="">
                {{ $t("football.league-details.pre-match-odds") }}</span
              ></span
            >
          </p>
          <!---->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/details/` + matchId)
        .then((response) => {
          this.liveMatchDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
