<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-8948368c="">
    <!-- begin::breadcrumb -->
    <div class="breadcrumb-box" data-v-8948368c="" v-if="!loading">
      <div class="content-box" data-v-8948368c="">
        <ul class="breadcrumb" data-v-8948368c="">
          <li data-v-8948368c="">
            <a
              :href="'/' + this.$i18n.locale + '/live/cricket'"
              class="link"
              data-v-8948368c=""
            >
            {{ $t("cricket.league-details.cricket-live-score") }}
            </a>
          </li>
          <li data-v-8948368c="">
            {{ leagueDetails && leagueDetails.name ? leagueDetails.name : "" }}
          </li>
          <li data-v-8948368c="">
            <h1 class="text" data-v-8948368c="">
              {{ $t("football.league-details.winners") }},
              {{ $t("football.league-details.champions") }},
              {{ $t("football.league-details.standings") }} &nbsp;
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::breadcrumb -->
    <div class="team" data-v-8948368c="">
      <!-- begin::title holder info -->
      <div
        class="competition-header"
        data-v-26a596f7=""
        data-v-8948368c=""
        v-if="!loading"
      >
        <div class="content border-box flex align-center" data-v-26a596f7="">
          <div class="p-l flex-1 flex align-center" data-v-26a596f7="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-26a596f7=""
            >
              <img
                :src="
                  'https://sportyclub.online//images/cricket/leagues/' +
                  leagueId +
                  '/' +
                  leagueId +
                  '.png'
                "



                @error="$event.target.src = bannerImagePath('default-nation.png')"
                class="team-logo"
                data-v-26a596f7=""
              />
              <!-- begin::title -->
              <div class="pl-15 pr-15" data-v-26a596f7="">
                <div class="fs-24 font-500 flex" data-v-26a596f7="">
                  <span
                    style="
                      max-width: 330px;
                      font-weight: 500;
                      font-family: Rubik-Medium, MicrosoftYaHei,
                        MicrosoftYaHei-Bold, PingFang SC;
                    "
                    data-v-26a596f7=""
                  >
                    {{
                      leagueDetails && leagueDetails.name
                        ? leagueDetails.name
                        : ""
                    }}
                  </span>
                </div>
                <!-- begin::season list -->
                <div class="mt-8 flex" data-v-26a596f7="">
                  <div
                    data-v-64546046=""
                    class="el-select select-transfers-year ml-12 el-select--mini"
                  >
                    <div
                      v-on:click="toggleSeasonList(seasonData)"
                      class="el-input el-input--mini el-input--suffix"
                      :class="seasonData ? 'is-focus' : ''"
                    >
                      <input
                        :placeholder="seasonYear"
                        class="el-input__inner"
                      /><span class="el-input__suffix"
                        ><span class="el-input__suffix-inner"
                          ><i
                            class="el-select__caret el-input__icon el-icon-arrow-up"
                          ></i></span
                      ></span>
                    </div>
                    <div
                      class="el-select-dropdown el-popper"
                      style="min-width: 74px"
                      :style="seasonData ? 'display: block' : 'display: none'"
                    >
                      <div class="el-scrollbar">
                        <div
                          class="el-select-dropdown__wrap el-scrollbar__wrap"
                          style="margin-bottom: -6px; margin-right: -6px"
                        >
                          <ul
                            class="el-scrollbar__view el-select-dropdown__list"
                            v-on:click="toggleSeasonList(seasonData)"
                          >
                            <li
                              v-for="season in seasonsList"
                              :key="season"
                              data-v-64546046=""
                              class="el-select-dropdown__item selected"
                              v-on:click="
                                toggleSeasonId(season.id, season.year)
                              "
                            >
                              <span>{{ season.year }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end::season list -->
                </div>
              </div>
              <!-- end::title -->
              <div
                class="flex flex-col fs-12 pl-15 ml-l"
                style="border-left: 1px dashed #dcdddf"
                data-v-26a596f7=""
              >
                <div class="flex mb-xxs" data-v-26a596f7="">
                  <div
                    class="color-999 font-300"
                    style="width: 140px"
                    data-v-26a596f7=""
                  >
                    {{ $t("football.league-details.current-title-holder") }} :
                  </div>
                  <a
                    v-if="
                      leagueDetails &&
                      leagueDetails.titleHolder &&
                      leagueDetails.titleHolder.id
                    "
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/team/details/' +
                      leagueDetails.titleHolder.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      leagueDetails.titleHolder.id
                    "
                    class="color-333 w-o-h cur-pointer"
                    style="max-width: 240px"
                    data-v-26a596f7=""
                  >
                    {{
                      leagueDetails.titleHolder.name
                        ? leagueDetails.titleHolder.name
                        : ""
                    }}
                  </a>
                  <a v-else> - </a>
                </div>
                <div class="flex mb-xxs" data-v-26a596f7="">
                  <div
                    class="color-999 font-300"
                    style="width: 140px"
                    data-v-26a596f7=""
                  >
                    {{ $t("football.league-details.most-title-holder") }} :
                  </div>
                  <span
                    v-if="
                      leagueDetails &&
                      leagueDetails.mostTitlesTeams &&
                      leagueDetails.mostTitlesTeams != ''
                    "
                  >
                    <a
                      v-for="team in leagueDetails.mostTitlesTeams"
                      :key="team"
                      class="color-333 w-o-h cur-pointer"
                      style="max-width: 240px"
                      data-v-26a596f7=""
                    >
                      {{ team.name ? team.name : "" }}

                      &nbsp;
                    </a>
                  </span>
                  <span v-else> - </span>
                </div>
              </div>
            </div>
          </div>
          <!-- begin::current seasom stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-26a596f7=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ currentSeason }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.current-title-holder") }}
                </div>

                <span>
                  {{
                    leagueDetails &&
                    leagueDetails.titleHolder &&
                    leagueDetails.titleHolder.name
                      ? leagueDetails.titleHolder.name
                      : "-"
                  }}
                </span>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div
                  class="color-666 font-300 mb-xxs"
                  style="transform-origin: left"
                  data-v-5ea21537=""
                >
                  {{ $t("football.league-details.no-of-teams") }}
                </div>
                <div>
                  {{
                    leagueInfo && leagueInfo.numberOfCompetitors
                      ? leagueInfo.numberOfCompetitors
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.start-date") }}
                </div>
                <div>
                  <small>
                    {{ getTimeStampDate(leagueDetails.startDateTimestamp) }}
                  </small>
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.end-date") }}
                </div>
                <div>
                  <small>
                    {{ getTimeStampDate(leagueDetails.endDateTimestamp) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <!-- end::current season stats -->
        </div>
      </div>
      <!-- end::title holder info -->
      <!-- begin::tabs -->
      <div
        class="tab-bar"
        data-v-274dfa8a=""
        data-v-8948368c=""
        v-if="!loading"
      >
        <div class="content-box" data-v-274dfa8a="">
          <div class="child" data-v-274dfa8a="">
            <!-- recent matches -->
            <a
              class="tab"
              :class="activeTab === 'recent' ? 'active' : ''"
              data-v-274dfa8a=""
              v-on:click="toggleTab('recent')"
            >
            {{ $t("football.league-details.recent") }}
            </a>
            <!-- upcoming matches -->

            <a
              class="tab"
              :class="activeTab === 'upcoming' ? 'active' : ''"
              data-v-274dfa8a=""
              v-on:click="toggleTab('upcoming')"
            >
            {{ $t("football.league-details.upcoming") }}
            </a>
            <!-- standings -->
            <a
              class="tab"
              :class="activeTab === 'standings' ? 'active' : ''"
              data-v-274dfa8a=""
              v-on:click="toggleTab('standings')"
            >
              {{ $t("football.league-details.standings") }}
            </a>
            <!-- player stats -->
            <a
              class="tab"
              style="cursor: pointer"
              data-v-274dfa8a=""
              v-on:click="toggleTab('playersStats')"
              :class="activeTab === 'playersStats' ? 'active' : ''"
            >
              {{ $t("football.league-details.players-stats") }}
            </a>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
      <!-- begin::league matches -->
      <span v-if="!loading">
        <RecentMatches
          v-if="activeTab === 'recent'"
          :leagueId="leagueId"
          :seasonId="seasonId"
          :leagueInfo="leagueInfo"
        />
        <UpcomingMatches
          v-if="activeTab === 'upcoming'"
          :leagueId="leagueId"
          :seasonId="seasonId"
        />
        <PlayersStats
          v-if="activeTab === 'playersStats'"
          :leagueId="leagueId"
          :seasonId="seasonId"
        />
      </span>

      <!-- begin::standings -->
      <span v-if="activeTab === 'standings'">
        <LeagueStandings :leagueId="leagueId" :seasonId="seasonId" />
      </span>
      <!-- end::standings -->
    </div>
  </div>
  <!-- begin::seasons list -->
  <DesktopFooter />
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import LeagueStandings from "./tabs/league-standings.vue";
import PlayersStats from "./tabs/players-stats.vue";
import RecentMatches from "./tabs/recent-matches.vue";
import UpcomingMatches from "./tabs/upcoming-matches.vue";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    LeagueStandings,
    PlayersStats,
    RecentMatches,
    UpcomingMatches,
  },

  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "recent",
      seasonData: false,
      seasonId: null,
      playersStats: null,
      currentSeason: null,
      leagueInfo: null,
      seasonYear: null,
      loadingStandings: true,
      metaData: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route

    const leagueName = this.$route.params.slug;
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(leagueName,itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueName,leagueId) {


      // hotLeague.nameEn.toLowerCase().replace(/\s/g, '-') +
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/league/details/${leagueName}/${leagueId}`
        )
        .then((response) => {
          this.leagueDetails = response.data.uniqueTournament;
          
          this.getSeasonList(leagueId);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get seasons of league
    getSeasonList(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/standings/list/${leagueId}`
        )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.seasons &&
            response.data.seasons.length > 0
          ) {
            this.seasonsList = response.data.seasons;
            const season = response.data.seasons[0];
            this.seasonId = season.id;
            this.seasonYear = season.year;
            this.currentSeason = season.name;

            this.toggleSeasonId(this.seasonId, this.seasonYear);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to toggle season id
    toggleSeasonId(id, year) {
      this.seasonId = id;
      this.seasonYear = year;

      this.getLeagueInfo(this.leagueId, this.seasonId);
    },

    // method to get league
    getLeagueInfo(leagueId, seasonId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/info/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.leagueInfo = response.data.info;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to toggle season
    toggleSeasonList(tab) {
      this.seasonData = !tab;
    },
    // method to toggle tabs
    toggleTab(tab) {
      this.activeTab = tab;
      console.log(tab);
    },

    // method to convert timestamp to readable date
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();

      // Format the date
      return month + " " + day + ", " + year;
    },
  },
};
</script>
