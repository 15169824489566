<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-6ad89ef3="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-6ad89ef3="">
      <div class="content-box" data-v-6ad89ef3="">
        <ul class="breadcrumb" data-v-6ad89ef3="">
          <li data-v-6ad89ef3="">
            <a
              :href="'/' + this.$i18n.locale + '/live/baseball'"
              class="link"
              data-v-6ad89ef3=""
            >
              {{ $t("baseball.league-details.baseball-live-score") }}
            </a>
          </li>

          <li data-v-6ad89ef3="">
            <h1 class="text" data-v-6ad89ef3="">
              {{
                this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                  ? teamDetails.cn_name
                  : teamDetails.name
                  ? teamDetails.name
                  : ""
              }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="teamsBox view border-box" data-v-6ad89ef3="">
      <div class="tourHeader" data-v-1eddfbef="">
        <div class="topInfo flex align-center" data-v-1eddfbef="">
          <img
            :src="
              teamDetails && teamDetails.logo
                ? teamDetails.logo
               : bannerImagePath('default-nation.png')
            "
            :alt="
              this.$i18n.locale == 'cn' && teamDetails
                ? teamDetails.cn_name
                : teamDetails.name
            "
            class="teamImg"
            data-v-1eddfbef=""
          />
          <div class="teamInfo" data-v-1eddfbef="">
            <p class="teamName" data-v-1eddfbef="">
              {{
                this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
                  ? teamDetails.cn_name
                  : teamDetails.name
                  ? teamDetails.name
                  : ""
              }}
            </p>
            <!---->
          </div>
        </div>
        <div class="tab flex align-center" data-v-1eddfbef="" style="">
          <a
            @click="toggleTab('overview')"
            target="_self"
            class="flex align-center"
            data-v-1eddfbef=""
            style="cursor: pointer"
          >
            {{ $t("football.league-details.overview") }}
            <span v-if="activeTab === 'overview'" data-v-1eddfbef=""></span
          ></a>
        </div>
      </div>

      <div
        v-if="activeTab === 'overview'"
        class="contentBox"
        data-v-1eddfbef=""
      >
        <div class="scheduleBox" style="width: 100%" data-v-1eddfbef="">
          <div
            data-v-1eddfbef=""
            class="flex justify-center align-center flex-col"
            style="height: 300px"
          >
            <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
      activeTab: "overview",
    };
  },
  created() {
    // Accessing the ID parameter from the routes
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getTeamSquad(itemId);
    this.getMetaData(itemId);
  },

  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team squad
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/baseball/team/squad/${teamId}`)
        .then((response) => {
          this.teamSquad = response.data.teamPlayers;
          this.totlePlayers = response.data.teamPlayers.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
