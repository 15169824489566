<template>
  <div class="vue-recycle-scroller__item-view">
    <div data-v-46436e01="" class="user">
      <div data-v-46436e01="" class="tag_commingMatches">
        <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
        <span data-v-46436e01="" class="text">
          {{ $t("football.live-score.today-upcoming-matches") }}</span
        >
      </div>
    </div>
  </div>

  <div
    class="vue-recycle-scroller__item-view"
    v-for="(league, key) in todayUpcomingMatchList"
    :key="key"
  >
    <div data-v-46436e01="" class="user">
      <div data-v-46436e01="" class="flex align-center">
        <div data-v-2eea2747="" data-v-46436e01="" style="width: 100%">
          <div
            data-v-2eea2747=""
            class="list_title"
            :class="
              leagueFavoriteStatus[league[0].competition_id] ? 'collectCom' : ''
            "
          >
            <div data-v-2eea2747="" class="topt flex">
              <div
                data-v-2eea2747=""
                style="z-index: -1; width: 13.03%; min-width: 124px"
              ></div>
              <div
                data-v-2eea2747=""
                style="
                  z-index: -1;
                  margin-left: 10px;
                  width: 21%;
                  min-width: 200px;
                "
              ></div>
              <div data-v-2eea2747="" class="flex-1 headBoxRight">
                <span
                  data-v-2eea2747=""
                  class="watchNum"
                  style="margin-right: 4px"
                  ><i
                    data-v-2eea2747=""
                    class="iconfont icon-renshu"
                    style="display: none"
                  ></i>
                  <span data-v-2eea2747="" style="display: none"
                    >163</span
                  ></span
                >
              </div>
              <div data-v-2eea2747="" class="Moneyline">
                <div
                  data-v-2eea2747=""
                  class="text-center oddsTypeText_bsk oddsWidth1 bh"
                >
                  {{ $t("football.league-details.to-win") }}
                </div>
                <div
                  data-v-2eea2747=""
                  class="text-center oddsTypeText_bsk oddsWidth2 bh"
                >
                  {{ $t("football.league-details.spread") }}
                </div>
                <div
                  data-v-2eea2747=""
                  class="flex-1 text-center oddsTypeText_bsk oddsWidth3 bh"
                >
                  {{ $t("football.league-details.total-points") }}
                </div>
              </div>
            </div>
            <div data-v-2eea2747="" class="flex align-center topLeftBox">
    
              <i
                class="iconfont"
                :class="
                  leagueFavoriteStatus[league[0].competition_id]
                    ? 'icon-yishoucang'
                    : 'icon-weishoucang'
                "
                @click="toggleFavorite(league[0].competition_id)"
                style="visibility: visible"
              ></i>
              <img
                data-v-2eea2747=""
                v-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  ((league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.logo) ||
                              (league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name) ||
                              (league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name))
                "
                :src="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.logo
                    ? league[0].leagueInfo.countryDetails.logo
                   : bannerImagePath('default-country.png')
                "
                alt="China"
                class="countryLogo"
              />
              <span data-v-2eea2747="" class="countryText">
                <span
                  v-if="this.$i18n.locale == 'cn'"
                  data-v-f1b1cb6e=""
                  class="country-name minitext"
                >
                  <span
                    v-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.cn_name
                    "
                  >
                    {{ league[0].leagueInfo.countryDetails.cn_name }}:
                  </span>
                  <span
                    v-else-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.categoryDetails &&
                      league[0].leagueInfo.categoryDetails.cn_name
                    "
                  >
                    {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                  </span>
                </span>

                <span v-else data-v-f1b1cb6e="" class="country-name minitext">
                  <span
                    v-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.name
                    "
                  >
                    {{ league[0].leagueInfo.countryDetails.name }}:
                  </span>
                  <span
                    v-else-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.categoryDetails &&
                      league[0].leagueInfo.categoryDetails.name
                    "
                  >
                    {{ league[0].leagueInfo.categoryDetails.name }}:
                  </span>
                </span>
              </span>
              <span
                v-if="
                  league &&
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo
                "
              >
                <a
                  v-if="league[0].hasLeagueDetails"
                  data-v-2eea2747=""
                  :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "

                  target="_blank"
                  class="compText"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
                <a
                  v-else
                  target="_blank"
                  class="compText"
                  style="cursor: not-allowed"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
              </span>
            </div>
          </div>
          <div data-v-2eea2747="">
            <div
              data-v-2eea2747=""
              class="list"
              v-for="match in league"
              :key="match.id"
            >
              <a
              v-if="match && match.homeInfo && match.awayInfo"
                data-v-2eea2747=""
                :href="
                                '/' +
                                this.$i18n.locale +
                                '/basketball/match/details/' + match.homeInfo.en_name.toLowerCase() .replace(/\s/g, '-') + '-vs-'+ match.awayInfo.en_name.toLowerCase() .replace(/\s/g, '-') +  '/' +
                                match.id
                              "
                target="_blank"
                class="flex flex-1"
                style="height: 100%"
              >
                <div
                  data-v-2eea2747=""
                  class="flex align-center listBox justify-around"
                >
                  <span
                    data-v-2eea2747=""
                    class="fs-13 matchTime"
                    style="color: rgb(153, 153, 153)"
                  >
                    {{ readableTime(this.$i18n.locale, match.match_time) }}
                  </span>
                  <div
                    data-v-2eea2747=""
                    class="flex flex-col fs-13 align-center text-center barStatus barItem flex-1"
                  >
                    <span data-v-2eea2747=""></span>
                    <span data-v-2eea2747="" class="barStatusLine">-</span>
                    <span data-v-2eea2747=""></span>
                  </div>
                </div>
                <div data-v-2eea2747="" class="team flex flex-col padding5">
                  <div data-v-2eea2747="" class="flex-1 align-center flex">
                    <span data-v-2eea2747="" class="teamLogoBox"
                      ><img
                        data-v-2eea2747=""
                        v-if="match.homeInfo"
                        :src="
                          match.homeInfo.logo
                            ? match.homeInfo.logo
                            : '/images/default-football.jpg'
                        "
                    /></span>
                    <div data-v-2eea2747="" class="w-o-h elseTeamName">
                      <template v-if="match && match.homeInfo">
                        {{
                          this.$i18n.locale == "cn" && match.homeInfo.cn_name
                            ? match.homeInfo.cn_name
                            : match.homeInfo.en_name
                            ? match.homeInfo.en_name
                            : ""
                        }}
                      </template>
                    </div>
                  </div>
                  <div data-v-2eea2747="" class="flex-1 align-center flex">
                    <span data-v-2eea2747="" class="teamLogoBox"
                      ><img
                        data-v-2eea2747=""
                        v-if="match.awayInfo"
                        :src="
                          match.awayInfo.logo
                            ? match.awayInfo.logo
                            : '/images/default-football.jpg'
                        "
                    /></span>
                    <div
                      data-v-2eea2747=""
                      itemprop="awayTeam"
                      class="w-o-h elseTeamName"
                    >
                      <template v-if="match && match.awayInfo">
                        {{
                          this.$i18n.locale == "cn" && match.awayInfo.cn_name
                            ? match.awayInfo.cn_name
                            : match.awayInfo.en_name
                            ? match.awayInfo.en_name
                            : ""
                        }}
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  data-v-2eea2747=""
                  class="teamScore padding5"
                  v-if="
                    liveMatches.includes(match.status_id) ||
                    FinishedMatches.includes(match.status_id)
                  "
                >
                  <div
                    data-v-2eea2747=""
                    class="flex flex-col align-center"
                    style="height: 100%"
                  >
                    <div
                      data-v-2eea2747=""
                      class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                      style="font-family: Roboto-Regular, Roboto"
                    >
                      <div data-v-2eea2747="" class="svgBallIconBox"></div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center isVisible"
                      >
                        <span data-v-2eea2747="" class=""
                          ><span
                            data-v-2eea2747=""
                            class=""
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            {{
                              match &&
                              match.homeInfo &&
                              match.homeInfo.section_1
                                ? match.homeInfo.section_1
                                : "0"
                            }}
                          </span></span
                        >
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center isVisible"
                      >
                        <span data-v-2eea2747="" class=""
                          ><span
                            data-v-2eea2747=""
                            class=""
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            {{
                              match &&
                              match.homeInfo &&
                              match.homeInfo.section_2
                                ? match.homeInfo.section_2
                                : "0"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center isVisible"
                      >
                        <span data-v-2eea2747="" class=""
                          ><span
                            data-v-2eea2747=""
                            class=""
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            {{
                              match &&
                              match.homeInfo &&
                              match.homeInfo.section_3
                                ? match.homeInfo.section_3
                                : "0"
                            }}</span
                          ></span
                        >
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center isVisible"
                      >
                        <span data-v-2eea2747="" class="scoreWeight"
                          ><span
                            data-v-2eea2747=""
                            class=""
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            {{
                              match &&
                              match.homeInfo &&
                              match.homeInfo.section_4
                                ? match.homeInfo.section_4
                                : "0"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-2eea2747=""
                  class="flex-1 icon-setting"
                  style="border-right: 1px solid rgb(234, 234, 234)"
                >
                  <i
                    data-v-2eea2747=""
                    class="iconfont icon-donghuazhibo videoAn"
                  ></i>
                </div>
                <div
                  data-v-2eea2747=""
                  class="flex Moneyline moneyLineContent"
                  style="position: relative"
                >
                  <div data-v-2eea2747="" class="flex flex-1 oddsItem">
                    <div
                      data-v-2eea2747=""
                      class="text-center le lh oddTextColor oddsItemBox oddsWidth1"
                    >
                      <div data-v-2eea2747="">
                        <div data-v-2eea2747="">
                          <div data-v-2eea2747="" class="oddhoverGreen">
                            {{
                              match &&
                              match.odds &&
                              match.odds.euro &&
                              match.odds.euro[2] !== ""
                                ? match.odds.euro[2].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div data-v-2eea2747="" class="oddhoverGreen oddTop">
                            {{
                              match &&
                              match.odds &&
                              match.odds.euro &&
                              match.odds.euro[4] !== ""
                                ? match.odds.euro[4].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      data-v-2eea2747=""
                      class="text-center le lh oddsItemBox oddsWidth1"
                      style="display: none"
                    ></div>
                    <div
                      data-v-2eea2747=""
                      class="text-center flex le oddsItemBox oddsWidth2"
                    >
                      <div data-v-2eea2747="" class="flex-1 lh handicapColor">
                        <div data-v-2eea2747="">
                          {{
                            match &&
                            match.odds &&
                            match.odds.asia &&
                            match.odds.asia[3] !== ""
                              ? -match.odds.asia[3].toFixed(2)
                              : "-"
                          }}
                        </div>
                        <div data-v-2eea2747="" class="oddTop">
                          {{
                            match &&
                            match.odds &&
                            match.odds.asia &&
                            match.odds.asia[3] !== ""
                              ? match.odds.asia[3].toFixed(2)
                              : "-"
                          }}
                        </div>
                      </div>
                      <div data-v-2eea2747="" class="flex-1 lh oddTextColor">
                        <div data-v-2eea2747="" class="oddhoverGreen">
                          {{
                            match &&
                            match.odds &&
                            match.odds.asia &&
                            match.odds.asia[2] !== ""
                              ? match.odds.asia[2].toFixed(2)
                              : "-"
                          }}
                        </div>
                        <div data-v-2eea2747="" class="oddhoverGreen oddTop">
                          {{
                            match &&
                            match.odds &&
                            match.odds.asia &&
                            match.odds.asia[4] !== ""
                              ? match.odds.asia[4].toFixed(2)
                              : "-"
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      data-v-2eea2747=""
                      class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                    >
                      <div data-v-2eea2747="" class="flex-1 lh handicapColor">
                        <div data-v-2eea2747="">
                          <span data-v-2eea2747="">O</span>
                          {{
                            match &&
                            match.odds &&
                            match.odds.bigSmall &&
                            match.odds.bigSmall[3] !== ""
                              ? match.odds.bigSmall[3].toFixed(2)
                              : "-"
                          }}
                        </div>
                        <div data-v-2eea2747="" class="oddTop">
                          <span data-v-2eea2747="">U</span>
                          {{
                            match &&
                            match.odds &&
                            match.odds.bigSmall &&
                            match.odds.bigSmall[3] !== ""
                              ? match.odds.bigSmall[3].toFixed(2)
                              : "-"
                          }}
                        </div>
                      </div>
                      <div data-v-2eea2747="" class="flex-1 lh oddTextColor">
                        <div data-v-2eea2747="" class="oddhoverGreen">
                          {{
                            match &&
                            match.odds &&
                            match.odds.bigSmall &&
                            match.odds.bigSmall[2] !== ""
                              ? match.odds.bigSmall[2].toFixed(2)
                              : "-"
                          }}
                        </div>
                        <div data-v-2eea2747="" class="oddhoverGreen oddTop">
                          {{
                            match &&
                            match.odds &&
                            match.odds.bigSmall &&
                            match.odds.bigSmall[4] !== ""
                              ? match.odds.bigSmall[4].toFixed(2)
                              : "-"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],

  data() {
    return {
      todayMatchList: null,
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
      countryList: null,
      countryLeagueActive: "",
      todayUpcomingMatchList: null,
      leagueFavoriteStatus: {}, // Store favorite status for each league
    };
  },
  created() {
    this.getUpcomingMatchList("upcoming");
  },

  methods: {

    loadFavoriteStatus() {
      if (this.todayUpcomingMatchList) {
        console.log(
          "Today's Upcoming Match List:",
          this.todayUpcomingMatchList
        );
        Object.values(this.todayUpcomingMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const competitionId = match.competition_id;

              const favoriteStatus = localStorage.getItem(
                `basketball_${competitionId}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`basketball_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayUpcomingMatchList) {
        this.todayUpcomingMatchList.forEach((league) => {
          const leagueId = league[0].competition_id;
          const favoriteStatus = localStorage.getItem(`basketball_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get upcoming matchlist
    getUpcomingMatchList(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.basketballMatchList;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
