<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-ed44d852="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-ed44d852="">
      <div class="content-box" data-v-ed44d852="">
        <ul class="breadcrumb" data-v-ed44d852="">
          <li data-v-ed44d852="">
            <a
              :href="'/' + this.$i18n.locale + '/live/football'"
              class="link"
              data-v-8948368c=""
            >
              {{ $t("football.league-details.football-live-score") }}
            </a>
          </li>
          <li data-v-ed44d852="">
            <a
              :href="
                '/' +
                this.$i18n.locale +
                '/football/team/details/' +
                playerDetails.teamDetails.name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                playerDetails.teamDetails.id
              "
              class="link"
              data-v-ed44d852=""
            >
              {{
                this.$i18n.locale == "cn" &&
                playerDetails.teamDetails &&
                playerDetails.teamDetails.cn_name
                  ? playerDetails.teamDetails.cn_name
                  : playerDetails.teamDetails.name
              }}
            </a>
          </li>
          <li data-v-ed44d852="">
            <h1 itemprop="name" class="text" data-v-ed44d852="">
              {{
                this.$i18n.locale == "cn" &&
                playerDetails &&
                playerDetails.cn_name
                  ? playerDetails.cn_name
                  : playerDetails.name
              }}
              {{ $t("football.league-details.stats-and-transfer-history") }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="player" data-v-ed44d852="">
      <div class="player-header" data-v-04359244="" data-v-ed44d852="">
        <div class="content border-box flex align-center" data-v-04359244="">
          <div class="p-l flex-1 flex align-center" data-v-04359244="">
            <!-- begin::county & team name -->
            <div
              class="flex align-center"
              style="height: 88px; width: 430px"
              data-v-04359244=""
            >
              <img
                :src="
                  playerDetails && playerDetails.logo ? playerDetails.logo : ''
                "
                class="player-logo"
                data-v-04359244=""
              />
              <div class="pl-15" data-v-04359244="">
                <div class="fs-24 font-500 flex" data-v-04359244="">
                  <span data-v-04359244="">
                    {{
                      this.$i18n.locale == "cn" &&
                      playerDetails &&
                      playerDetails.cn_name
                        ? playerDetails.cn_name
                        : playerDetails.name
                    }}
                  </span>
                  <span
                    class="flex justify-center align-center mr-12"
                    data-v-04359244=""
                  ></span>
                </div>
                <div class="mt-8 flex align-center" data-v-04359244="">
                  <div
                    class="country-logo"
                    data-v-04359244=""
                    v-if="
                      playerDetails &&
                      playerDetails.countryDetails &&
                      playerDetails.countryDetails.logo
                    "
                  >
                    <img
                      :src="
                        playerDetails.countryDetails.logo
                          ? playerDetails.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      data-v-04359244=""
                    />
                  </div>

                  <span class="ml-xs fs-14 color-666" data-v-04359244="">
                    <span v-if="this.$i18n.locale == 'cn'">
                      {{
                        playerDetails &&
                        playerDetails.countryDetails &&
                        playerDetails.countryDetails.cn_name
                          ? playerDetails.countryDetails.cn_name
                          : ""
                      }}
                    </span>
                    <span v-else>
                      {{
                        playerDetails &&
                        playerDetails.countryDetails &&
                        playerDetails.countryDetails.en_name
                          ? playerDetails.countryDetails.en_name
                          : ""
                      }}
                    </span>
                  </span>

                  <div
                    class="border-right ml-xs mr-xs"
                    data-v-04359244=""
                  ></div>
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/basketball/team/details/' +
                      playerDetails.teamDetails.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      playerDetails.teamDetails.id
                    "
                    class="cur-pointer"
                    data-v-04359244=""
                    ><img
                      :src="
                        playerDetails && playerDetails.teamDetails.logo
                          ? playerDetails.teamDetails.logo
                          : ''
                      "
                      class="country-logo"
                      data-v-04359244=""
                  /></a>
                  <a
                  :href="
                      '/' +
                      this.$i18n.locale +
                      '/basketball/team/details/' +
                      playerDetails.teamDetails.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      playerDetails.teamDetails.id
                    "
                    class="pl-xs fs-14 color-666 cur-pointer"
                    data-v-04359244=""
                  >
                    {{
                      this.$i18n.locale == "cn" && playerDetails.teamDetails
                        ? playerDetails.teamDetails.cn_name
                        : playerDetails.teamDetails.name
                    }}
                  </a>
                </div>
              </div>
            </div>
            <!-- end::county & team name -->
            <!-- begin::player details -->
            <div
              class="fs-12 color-333 flex-1 pl-15 border-box flex flex-col justify-between"
              style="height: 72px; border-left: 1px dashed #dcdddf"
              data-v-04359244=""
            >
              <div class="desc flex align-center" data-v-04359244="">
                <div class="title color-666 font-300" data-v-04359244="">
                  {{ $t("football.league-details.birthday") }} :
                </div>
                <div data-v-04359244="">
                  {{ getFormattedDate(playerDetails.birthday) }}
                </div>
              </div>
              <div class="desc flex align-center" data-v-04359244="">
                <div class="title color-666 font-300" data-v-04359244="">
                  {{ $t("football.league-details.position") }} :
                </div>
                <div data-v-04359244="">
                  {{
                    playerDetails && playerDetails.position
                      ? playerDetails.position
                      : "-"
                  }}
                </div>
              </div>
              <div class="desc flex align-center" data-v-04359244="">
                <div class="title color-666 font-300" data-v-04359244="">
                  {{ $t("football.league-details.shirt-number") }} :
                </div>
                <div data-v-04359244="">
                  {{
                    playerDetails && playerDetails.shirt_number
                      ? playerDetails.shirt_number
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <!-- end::player details -->
          </div>
          <!-- befin::player stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-04359244=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ $t("football.league-details.player-stats") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.height") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails && playerDetails.height
                      ? playerDetails.height
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div
                  class="color-666 font-300 mb-xxs"
                  style="transform-origin: left"
                  data-v-5ea21537=""
                >
                  {{ $t("football.league-details.weight") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails && playerDetails.weight
                      ? playerDetails.weight
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.age") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails && playerDetails.age ? playerDetails.age : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.player-value") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    playerDetails && playerDetails.salary
                      ? playerDetails.salary
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- end::player stats -->
        </div>
      </div>

      <div class="wrapper" data-v-ed44d852="">
        <div class="flex" data-v-d32d9226="" data-v-ed44d852="">
          <div class="p-l flex-1" data-v-d32d9226="">
            <div
              class="container-box mt-12"
              data-v-7c947e55=""
              data-v-d32d9226=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ $t("football.league-details.more-info") }}
                </span>
              </div>
              <div
                class="flex justify-center align-center flex-col"
                style="height: 500px"
              >
                <img :src="bannerImagePath('default-container.png')" />
                <div class="color-999 fs-12 mt-12">
                  {{ $t("football.live-score.temporarily-no-data") }}
                </div>
              </div>
            </div>
          </div>

          <div class="p-r" data-v-d32d9226="">
            <div
              class="container-box mt-12"
              data-v-7c947e55=""
              data-v-d32d9226=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{
                    this.$i18n.locale == "cn" &&
                    playerDetails &&
                    playerDetails.cn_name
                      ? playerDetails.cn_name
                      : playerDetails.name
                  }}
                </span>
                <span class="color-999 fs-12" data-v-7c947e55=""></span>
              </div>
              <div
                class="f-s-12 color-666 line-h-16 ml-15 mr-15 mt-15 pb-20"
                data-v-7c947e55=""
                data-v-d32d9226=""
              >
                <p v-if="this.$i18n.locale == 'cn'">
                  {{
                    playerDetails && playerDetails.cn_name
                      ? playerDetails.cn_name
                      : ""
                  }}
                  的个人资料页面显示了所有比赛和比赛的统计数据
                  他参加的比赛。您可以获取
                  {{
                    playerDetails && playerDetails.cn_name
                      ? playerDetails.cn_name
                      : ""
                  }}
                  最重要的数据，包括平均球员评分、进球、助攻、红牌和
                  其他相关数据也显示在AiScore上。

                  <br />
                </p>
                <p v-else>
                  The profile page of
                  {{
                    playerDetails && playerDetails.name
                      ? playerDetails.name
                      : ""
                  }}
                  displays all matches and competitions with statistics for all
                  the matches he played in. You can get most important stats of
                  {{
                    playerDetails && playerDetails.name
                      ? playerDetails.name
                      : ""
                  }}
                  , including average player rating, goals, assists, cards and
                  other relevant data are also displayed on AiScore.<br /><br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<script>

import metaDataUtils from "@/utils/metaDataUtils";

export default {

  data() {
    return {
      playerDetails: null,
      loading: true,
      playerId: null,
    };
  },
  mixins: [metaDataUtils],
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.playerId;
    this.playerId = itemId;
    this.getPlayerDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(playerId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/player/meta/` + playerId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getPlayerDetails(playerId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/basketball/player/details/${playerId}`
        )
        .then((response) => {
          this.playerDetails = response.data.playerDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormattedDate(timeStamp) {
      if (timeStamp != "") {
        const date = new Date(timeStamp);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      }
      return "-";
    },
  },
};
</script>
