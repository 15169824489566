<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <div class="w100 view border-box" data-v-46436e01="" v-if="!loading">
    <!-- begin::broadcumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: black; padding-left: 0"
    >
      <span class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">
          <a href="/" target="_blank" style="cursor: pointer">
            <h1 class="liveScoreh1" v-if="this.$i18n.locale == 'cn'">
              {{ $t("hockey.live-score.aiScore-hockey-live-score") }}
            </h1>
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </span>
    </div>
    <!-- end::broudcumb -->
    <div
      class="index-view flex justify-between basketball theme_init"
      data-v-46436e01=""
    >
      <div data-v-94567e5a="" data-v-46436e01="" class="index-l fs-13">
        <!-- begin::hot league list -->
        <HotLeagues />
        <!-- end::league list -->
        <!-- begin::languages -->
        <CountryList />
        <!-- end::languages -->
      </div>

      <span v-if="!loading">
        <div data-v-46436e01="">
          <!-- begin::banner -->
          <div
            class="bannerBox"
            data-v-d4c6fef0=""
            :style="toggleBannerState ? 'display:none' : 'display:block'"
          >
            <img
              :src="bannerImagePath('general-banner.png')"
              alt="#"
              class="banner"
              data-v-d4c6fef0=""
            />
            <i
              style="
                position: absolute;
                right: 20px;
                top: 10px;
                color: white;
                cursor: pointer;
              "
              class="iconfont icon-guanbi close"
              data-v-d4c6fef0=""
              v-on:click="toggleBanner(state)"
            ></i>
          </div>
          <!-- end::banner -->

          <div
            class="index-r"
            style="width: 992px; margin: 0"
            data-v-46436e01=""
          >
            <div class="match-score" data-v-46436e01=""></div>
            <div
              data-v-46436e01=""
              class="index-tab flex text-center justify-between"
              style="box-sizing: content-box"
            >
              <!-- begin::tabs -->
              <div class="changTabBox" data-v-d4c6fef0="">
                <span
                  :class="
                    liveActivebtn == 'all' ? 'activeElsTab' : 'notActiveTab'
                  "
                  class="changeItem bh"
                  data-v-d4c6fef0=""
                  v-on:click="gettodayMatchList('all')"
                >
                  {{ $t("football.live-score.all") }}
                </span>
                <span
                  :class="
                    liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
                  "
                  class="changeItem bh ml-12"
                  data-v-d4c6fef0=""
                  v-on:click="gettodayMatchList('live')"
                  ><span class="liveContentBox" data-v-d4c6fef0=""
                    ><i
                      class="iconfont icon-jinhangzhong liveImg"
                      data-v-d4c6fef0=""
                    ></i>
                    {{ $t("football.live-score.live") }}
                    <span data-v-d4c6fef0="" class="total">{{
                      countLiveMatches != 0 ? countLiveMatches : ""
                    }}</span></span
                  ></span
                >
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                  v-on:click="gettodayMatchList('finished')"
                  :class="
                    liveActivebtn == 'finished'
                      ? 'activeElsTab'
                      : 'notActiveTab'
                  "
                >
                  {{ $t("football.live-score.finished") }}
                </span>
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                  v-on:click="gettodayMatchList('upcoming')"
                  :class="
                    liveActivebtn == 'upcoming'
                      ? 'activeElsTab'
                      : 'notActiveTab'
                  "
                >
                  {{ $t("football.live-score.soon") }}
                </span>
              </div>
              <!-- end::tabs -->
            </div>
            <SearchMatches
              v-if="searchListUpcoming || searchListLive"
              :searchMatchListUpcoming="searchListUpcoming"
              :searchMatchListLive="searchListLive"
            />
            <div
              v-if="!searchListUpcoming && !searchListLive"
              data-v-46436e01=""
              class="vue-recycle-scroller scroller ready page-mode direction-vertical"
            >
              <div class="vue-recycle-scroller__item-wrapper">
                <div
                  v-if="
                    countLiveMatches === 0 &&
                    Object.entries(todayMatchList).length == 0
                  "
                  class="flex justify-center align-center flex-col"
                >
                  <img :src="bannerImagePath('default-container.png')" />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.league-details.no-live-match") }}
                  </div>
                </div>
                <div
                  v-else
                  class="vue-recycle-scroller__item-view"
                  v-for="(league, key) in todayMatchList"
                  :key="key"
                >
                  <div data-v-46436e01="" class="user">
                    <div data-v-46436e01="" class="flex align-center">
                      <div
                        data-v-89734b7c=""
                        data-v-46436e01=""
                        style="width: 100%"
                      >
                        <div
                          data-v-89734b7c=""
                          class="list_title"
                          :class="
                            leagueFavoriteStatus[league[0].unique_tournament_id]
                              ? 'collectCom'
                              : ''
                          "
                        >
                          <div data-v-89734b7c="" class="topt flex">
                            <div
                              data-v-89734b7c=""
                              style="
                                z-index: -1;
                                width: 13.03%;
                                min-width: 124px;
                              "
                            ></div>
                            <div
                              data-v-89734b7c=""
                              style="
                                z-index: -1;
                                margin-left: 10px;
                                width: 21%;
                                min-width: 200px;
                              "
                            ></div>
                            <div
                              data-v-89734b7c=""
                              class="scoreBox"
                              style="width: 30%"
                            >
                              <div
                                data-v-89734b7c=""
                                style="width: 150px"
                              ></div>
                              <div
                                data-v-89734b7c=""
                                style="width: 150px"
                              ></div>
                              <div data-v-89734b7c="" class="flex-1"></div>
                            </div>
                            <div
                              data-v-89734b7c=""
                              class="flex-1 headBoxRight"
                            ></div>
                            <div data-v-89734b7c="" class="Moneyline">
                              <div
                                data-v-89734b7c=""
                                class="text-center oddsTypeText oddsWidth1 bh"
                              >
                                {{ $t("football.league-details.to-win") }}
                              </div>
                              <div
                                class="text-center oddsTypeText oddsWidth2 bh"
                              >
                                {{
                                  $t("football.league-details.handicap") +
                                  " - " +
                                  $t("football.league-details.sets")
                                }}
                              </div>
                              <div
                                data-v-89734b7c=""
                                class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                              >
                                {{ $t("football.league-details.total-points") }}
                              </div>
                            </div>
                          </div>
                          <div
                            data-v-89734b7c=""
                            class="flex align-center topLeftBox"
                          >
                            <i
                              class="iconfont"
                              :class="
                                leagueFavoriteStatus[
                                  league[0].unique_tournament_id
                                ]
                                  ? 'icon-yishoucang'
                                  : 'icon-weishoucang'
                              "
                              @click="
                                toggleFavorite(league[0].unique_tournament_id)
                              "
                              style="visibility: visible"
                            ></i>

                            <img
                              data-v-89734b7c=""
                              v-if="
                                league[0] &&
                                league[0].leagueInfo &&
                                ((league[0].leagueInfo.countryDetails &&
                                  league[0].leagueInfo.countryDetails.logo) ||
                                  (league[0].leagueInfo.countryDetails &&
                                    league[0].leagueInfo.countryDetails.name) ||
                                  (league[0].leagueInfo.categoryDetails &&
                                    league[0].leagueInfo.categoryDetails.name))
                              "
                              :src="
                                league[0] &&
                                league[0].leagueInfo &&
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.logo
                                  ? league[0].leagueInfo.countryDetails.logo
                                 : bannerImagePath('default-country.png')
                              "
                          
                              class="countryLogo"
                            />

                            <span data-v-89734b7c="" class="countryText">
                              <span
                                data-v-f1b1cb6e=""
                                class="country-name minitext"
                              >
                                <template
                                  v-if="league[0] && league[0].leagueInfo"
                                >
                                  <template v-if="this.$i18n.locale == 'cn'">
                                    <template
                                      v-if="
                                        league[0].leagueInfo.countryDetails &&
                                        league[0].leagueInfo.countryDetails
                                          .cn_name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.countryDetails
                                          .cn_name
                                      }}:
                                    </template>
                                    <template
                                      v-else-if="
                                        league[0].leagueInfo.categoryDetails &&
                                        league[0].leagueInfo.categoryDetails
                                          .cn_name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.categoryDetails
                                          .cn_name
                                      }}:
                                    </template>
                                    <template
                                      v-else-if="
                                        league[0].leagueInfo.countryDetails &&
                                        league[0].leagueInfo.countryDetails.name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.countryDetails
                                          .name
                                      }}:
                                    </template>
                                    <template
                                      v-else-if="
                                        league[0].leagueInfo.categoryDetails &&
                                        league[0].leagueInfo.categoryDetails
                                          .name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.categoryDetails
                                          .name
                                      }}:
                                    </template>
                                  </template>
                                  <template v-else>
                                    <template
                                      v-if="
                                        league[0].leagueInfo.countryDetails &&
                                        league[0].leagueInfo.countryDetails.name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.countryDetails
                                          .name
                                      }}:
                                    </template>
                                    <template
                                      v-else-if="
                                        league[0].leagueInfo.categoryDetails &&
                                        league[0].leagueInfo.categoryDetails
                                          .name
                                      "
                                    >
                                      {{
                                        league[0].leagueInfo.categoryDetails
                                          .name
                                      }}:
                                    </template>
                                    <template v-else> </template>
                                  </template>
                                </template>
                              </span>
                            </span>

                            <a
                              v-if="
                                league &&
                                league[0] &&
                                league[0].leagueInfo &&
                                league[0].leagueInfo &&
                                league[0].hasLeagueDetails
                              "
                              data-v-2eea2747=""
                              :href="
                                '/' +
                                this.$i18n.locale +
                                '/hockey/league/details/' +
                                league[0].leagueInfo.en_name
                                  .toLowerCase()
                                  .replace(/\s/g, '-') +
                                '/' +
                                league[0].unique_tournament_id
                              "
                              target="_blank"
                              class="compText"
                            >
                              {{
                                this.$i18n.locale == "cn" &&
                                league[0].leagueInfo.cn_name
                                  ? league[0].leagueInfo.cn_name
                                  : league[0].leagueInfo.en_name
                                  ? league[0].leagueInfo.en_name
                                  : ""
                              }}
                            </a>
                            <a
                              v-else
                              target="_blank"
                              class="compText"
                              style="cursor: not-allowed"
                            >
                              {{
                                this.$i18n.locale == "cn" &&
                                league[0].leagueInfo.cn_name
                                  ? league[0].leagueInfo.cn_name
                                  : league[0].leagueInfo.en_name
                                  ? league[0].leagueInfo.en_name
                                  : ""
                              }}
                            </a>
                          </div>
                        </div>
                        <div data-v-89734b7c="">
                          <div
                            data-v-89734b7c=""
                            class="list newList"
                            style="height: 64px"
                            v-for="match in league"
                            :key="match.id"
                          >
                            <div data-v-89734b7c="" class="list">
                              <div
                                data-v-89734b7c=""
                                class="flex align-center justify-center collectImgBox"
                              >
                                <!-- <i
                                  data-v-89734b7c=""
                                  class="iconfont icon-weishoucang collectImg"
                                  style="visibility: visible"
                                ></i> -->
                              </div>
                              <a
                                data-v-89734b7c=""
                                :href="
                                  '/' +
                                  this.$i18n.locale +
                                  '/hockey/match/details/' +
                                  match.homeInfo.en_name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '-vs-' +
                                  match.homeInfo.en_name
                                    .toLowerCase()
                                    .replace(/\s/g, '-') +
                                  '/' +
                                  match.id
                                "
                                target="_blank"
                                itemscope="itemscope"
                                itemtype="http://schema.org/SportsEvent"
                                class="flex flex-1"
                                style="height: 100%"
                              >
                                <div
                                  data-v-89734b7c=""
                                  class="flex align-center listBox justify-around"
                                >
                                  <span
                                    data-v-89734b7c=""
                                    class="fs-13 matchTime"
                                    style="color: rgb(153, 153, 153)"
                                  >
                                    {{
                                      readableTime(
                                        this.$i18n.locale,
                                        match.match_time
                                      )
                                    }}
                                  </span>
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-col fs-13 align-center color-r text-center barItem"
                                  >
                                    <span data-v-89734b7c="">{{
                                      match.status_id &&
                                      this.FinishedMatches.includes(
                                        match.status_id
                                      )
                                        ? $t(
                                            "football.league-details.full-time"
                                          )
                                        : match?.statusDescription
                                    }}</span>
                                  </div>
                                </div>
                                <div
                                  data-v-89734b7c=""
                                  class="team flex flex-col padding5"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span data-v-89734b7c="" class="teamLogoBox"
                                      ><img
                                        data-v-89734b7c=""
                                        v-if="match.homeInfo"
                                        :src="
                                          match.homeInfo.logo
                                            ? match.homeInfo.logo
                                          : bannerImagePath('default-team.png')
                                        "
                                        alt=""
                                    /></span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      <template v-if="match && match.homeInfo">
                                        {{
                                          this.$i18n.locale == "cn" &&
                                          match.homeInfo.cn_name
                                            ? match.homeInfo.cn_name
                                            : match.homeInfo.en_name
                                            ? match.homeInfo.en_name
                                            : ""
                                        }}
                                      </template>
                                    </div>
                                    <span
                                      data-v-89734b7c=""
                                      style="margin-left: 5px; display: none"
                                      >[]</span
                                    >
                                  </div>
                                  <div
                                    data-v-89734b7c=""
                                    class="flex-1 align-center flex"
                                  >
                                    <span
                                      data-v-89734b7c=""
                                      class="teamLogoBox"
                                    >
                                      <img
                                        data-v-89734b7c=""
                                        v-if="match.awayInfo"
                                        :src="
                                          match.awayInfo.logo
                                            ? match.awayInfo.logo
                                          : bannerImagePath('default-team.png')
                                        "
                                        alt=""
                                      />
                                    </span>
                                    <div
                                      data-v-89734b7c=""
                                      class="w-o-h elseTeamName"
                                    >
                                      <template v-if="match && match.awayInfo"
                                        >{{
                                          this.$i18n.locale == "cn" &&
                                          match.awayInfo.cn_name
                                            ? match.awayInfo.cn_name
                                            : match.awayInfo.en_name
                                            ? match.awayInfo.en_name
                                            : ""
                                        }}
                                      </template>
                                    </div>
                                    <span
                                      data-v-89734b7c=""
                                      style="margin-left: 5px; display: none"
                                      >[]</span
                                    >
                                  </div>
                                </div>
                                <div class="teamScore padding5">
                                  <div
                                    class="flex flex-col align-center"
                                    style="height: 100%"
                                  >
                                    <template
                                      v-if="match.status_id && match.scores"
                                    >
                                      <div
                                        v-for="teamIndex in 2"
                                        :key="teamIndex"
                                        class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                                        style="
                                          font-family: Roboto-Regular, Roboto;
                                          position: relative;
                                        "
                                      >
                                        <!-- <div
                                          data-v-89734b7c=""
                                          class="svgBallIconBox"
                                        >
                                          <img
                                            
                                            data-v-89734b7c=""
                                            src="/images/hockey-ball.png"
                                            alt="#"
                                            class="svgBallIcon"
                                          />
                                        </div> -->
                                        <div class="bigScore scoreWeight">
                                          <span
                                            class="scoreRed"
                                            style="
                                              font-size: 13px;
                                              padding: 0px 2px;
                                            "
                                            >{{
                                              match.scores && match.scores.ft
                                                ? match.scores.ft[teamIndex - 1]
                                                : ""
                                            }}</span
                                          >
                                        </div>
                                        <template
                                          v-for="setIndex in sets_match_status.length"
                                          :key="setIndex"
                                        >
                                          <div
                                            class="flex-1 text-center"
                                            :class="{
                                              isVisible:
                                                match.status_id >=
                                                sets_match_status[setIndex - 1],
                                              noVisible:
                                                match.status_id <
                                                sets_match_status[setIndex - 1],
                                            }"
                                          >
                                            <span
                                              v-if="
                                                match.scores[`p${setIndex}`]
                                              "
                                              :class="{
                                                scoreWeight:
                                                  teamIndex - 1 == 0
                                                    ? match.scores[
                                                        `p${setIndex}`
                                                      ][teamIndex - 1] >
                                                      match.scores[
                                                        `p${setIndex}`
                                                      ][teamIndex]
                                                    : match.scores[
                                                        `p${setIndex}`
                                                      ][teamIndex - 1] >
                                                      match.scores[
                                                        `p${setIndex}`
                                                      ][teamIndex - 2],
                                              }"
                                              ><span
                                                :class="{
                                                  scoreRed:
                                                    match.status_id ==
                                                    sets_match_status[
                                                      setIndex - 1
                                                    ],
                                                }"
                                                style="
                                                  font-family: Roboto-Regular,
                                                    Roboto;
                                                "
                                                >{{
                                                  match.scores[`p${setIndex}`][
                                                    teamIndex - 1
                                                  ]
                                                }}</span
                                              ></span
                                            >
                                          </div>
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>

                                <div
                                  data-v-89734b7c=""
                                  class="flex-1 icon-setting"
                                  style="
                                    border-right: 1px solid rgb(234, 234, 234);
                                  "
                                ></div>
                                <div
                                  data-v-89734b7c=""
                                  class="flex Moneyline moneyLineContent"
                                  style="position: relative"
                                >
                                  <div
                                    data-v-89734b7c=""
                                    class="flex flex-1 oddsItem"
                                  >
                                    <div
                                      data-v-89734b7c=""
                                      class="text-center le padding5 lh oddsItemBox oddsWidth1"
                                    >
                                      <div data-v-89734b7c="">
                                        <div data-v-89734b7c="">
                                          <div
                                            data-v-89734b7c=""
                                            class="oddhoverGreen"
                                          >
                                            {{
                                              match.odds && match.odds.euro
                                                ? parseFloat(
                                                    match?.odds?.euro[1]
                                                  ).toFixed(2)
                                                : "-"
                                            }}
                                          </div>
                                          <div
                                            data-v-89734b7c=""
                                            class="oddhoverGreen oddTop"
                                          >
                                            {{
                                              match.odds && match.odds.euro
                                                ? parseFloat(
                                                    match?.odds?.euro[3]
                                                  ).toFixed(2)
                                                : "-"
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="flex le padding5 oddsItemBox oddsWidth2"
                                    >
                                      <div class="flex-1 lh handicapColor">
                                        <div>
                                          {{
                                            match.odds && match.odds.asia
                                              ? match.odds.asia[2] * -1 > 0
                                                ? "+" +
                                                  parseFloat(
                                                    match.odds.asia[2] * -1
                                                  ).toFixed(1)
                                                : match.odds.asia[2] * -1
                                              : "-"
                                          }}
                                        </div>
                                        <div class="oddTop">
                                          {{
                                            match.odds && match.odds.asia
                                              ? match.odds.asia[2] > 0
                                                ? "+" +
                                                  parseFloat(
                                                    match.odds.asia[2]
                                                  ).toFixed(1)
                                                : match.odds.asia[2]
                                              : "-"
                                          }}
                                        </div>
                                      </div>
                                      <div class="flex-1 lh oddTextColor">
                                        <div
                                          class="oddhoverGreen"
                                          style="text-align: center"
                                        >
                                          {{
                                            match.odds && match.odds.asia
                                              ? parseFloat(
                                                  match.odds.asia[1]
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                        <div
                                          class="oddhoverGreen oddTop"
                                          style="text-align: center"
                                        >
                                          {{
                                            match.odds && match.odds.asia
                                              ? parseFloat(
                                                  match?.odds?.asia[3]
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      data-v-89734b7c=""
                                      class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                                    >
                                      <div
                                        data-v-89734b7c=""
                                        class="flex-1 lh handicapColor"
                                      >
                                        <div data-v-89734b7c="">
                                          {{
                                            match.odds && match.odds.bigSmall
                                              ? "O " +
                                                parseFloat(
                                                  match.odds.bigSmall[2]
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                        <div data-v-89734b7c="" class="oddTop">
                                          {{
                                            match.odds && match.odds.bigSmall
                                              ? "U " +
                                                parseFloat(
                                                  match.odds.bigSmall[2]
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                      </div>
                                      <div
                                        data-v-89734b7c=""
                                        class="flex-1 lh oddTextColor"
                                      >
                                        <div
                                          data-v-89734b7c=""
                                          class="oddhoverGreen"
                                        >
                                          {{
                                            match.odds && match.odds.bigSmall
                                              ? parseFloat(
                                                  match.odds.bigSmall[1] + 1
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                        <div
                                          data-v-89734b7c=""
                                          class="oddhoverGreen oddTop"
                                        >
                                          {{
                                            match.odds && match.odds.bigSmall
                                              ? parseFloat(
                                                  match.odds.bigSmall[3] + 1
                                                ).toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <UpcomingMatches
                  v-if="
                    thereIsUpcomingMatches &&
                    !searchListUpcoming &&
                    !searchListLive
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<!-- begin::scripts -->

<script>

import CountryList from "./tabs/countries-list.vue";
import HotLeagues from "./tabs/hot-leagues.vue";
import UpcomingMatches from "./tabs/upcoming-matches-list.vue";
import SearchMatches from "./tabs/search-matches-list.vue";
import LiveScoringMixin from "@/utils/liveScoreComputedDataMixin";

import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {

  mixins: [LiveScoringMixin,DateMixin,DynamicImagePath],
  components: {


    CountryList,
    HotLeagues,
    UpcomingMatches,
    SearchMatches,
  },

  data() {
    return {
      searchListUpcoming: null,
      searchListLive: null,
      todayUpcomingMatchList: null,
      todayMatchList: null,
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",
      isTimeActive: false,
      toggleBannerState: false,

      sets_match_status: [30, 31, 32],
      match_status: {
        NOT_STARTED: 1,
        FIRST_PERIOD: 30,
        SECOND_PERIOD: 31,
        THIRD_PERIOD: 32,
      },
    };
  },
  created() {
    this.gethotLeaguesList();
    this.gettodayMatchList(this.liveActivebtn);
    this.getCountryLeagues();
    this.getUpcomingMatchList();

    //  refresh frontend every 30 seconds
    setInterval(() => {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/hockey/match/list/today?matchStatus=${this.liveActivebtn}`
        )
        .then((response) => {
          this.todayMatchList = response.data.hockeyMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100000);
  },
  mounted() {
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },
  methods: {

    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `hockey_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`hockey_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`hockey_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.hockeyHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    gettodayMatchList(status) {
      this.liveActivebtn = status;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/hockey/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.hockeyMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get country list
    getCountryLeagues() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/country/list`)
        .then((response) => {
          this.countryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get League Details
    checkCountryActiveLeague(countryId) {
      if (this.countryLeagueActive == countryId) {
        this.countryLeagueActive = "";
      } else {
        this.countryLeagueActive = countryId;
      }
    },
    // method to toggle banner
    toggleBanner(state) {
      this.toggleBannerState = !state;
    },

    addSectionsScore(section_1, section_2, section_3, section_4) {
      return section_1 + section_2 + section_3 + section_4;
    },

    getUpcomingMatchList() {
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/hockey/match/list/today?matchStatus=upcoming`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.hockeyMatchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}
</style>
