<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-if="!loading && matchLineups != ''">
    <div class="content" data-v-c36567d6="">
      <div data-v-02ab54fc="" data-v-c36567d6="" class="team_squad">
        <div data-v-02ab54fc="" class="w100">
          <div data-v-02ab54fc="" class="title">
            {{ $t("football.league-details.home-team-squad") }}
          </div>
          <div data-v-02ab54fc="" class="content list_active">
            <span
              v-if="
                matchLineups && matchLineups.home && matchLineups.home.players
              "
            >
              <span v-for="home in matchLineups.home.players" :key="home.id">
                <a
                  data-v-02ab54fc=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/player-details/' +
                    home.player.id
                  "
                  class="w100 itemContent"
                  style="display: block"
                >
                  <div
                    data-v-02ab54fc=""
                    class="w100 h100 flex align-center justify-between"
                  >
                    <div data-v-02ab54fc="" class="flex-1 contentBox">
                      <div data-v-02ab54fc="" class="shirtNumber">
                        {{ home?.player?.jerseyNumber ?? "-" }}
                      </div>
                      <div
                        data-v-02ab54fc=""
                        class="playerLogo van-image van-image--round"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            home.player && home.player.id
                              ? 'https://sportyclub.online/images/cricket/players/' +
                                home.player.id +
                                '/' +
                                home.player.id +
                                '.png'
                            : bannerImagePath('default-team.png')
                          "
                          @error="
                            $event.target.src = bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <div data-v-02ab54fc="" class="playerInfo">
                        <span data-v-02ab54fc="" class="name">
                          {{
                            this.$i18n.locale == "cn" &&
                            home.player &&
                            home.player.cn_name
                              ? home.player.cn_name
                              : home.player.name
                              ? home.player.name
                              : ""
                          }}
                        </span>
                      </div>
                    </div>
                    <span data-v-02ab54fc="" class="marketValue">{{
                      home.position
                    }}</span>
                  </div>
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div data-v-02ab54fc="" data-v-c36567d6="" class="team_squad">
        <div data-v-02ab54fc="" class="w100">
          <div data-v-02ab54fc="" class="title">
            {{ $t("football.league-details.away-team-squad") }}
          </div>
          <div data-v-02ab54fc="" class="content list_active">
            <span
              v-if="
                matchLineups && matchLineups.away && matchLineups.away.players
              "
            >
              <span v-for="away in matchLineups.away.players" :key="away.id">
                <a
                  data-v-02ab54fc=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/player-details/' +
                    away.player.id
                  "
                  class="w100 itemContent"
                  style="display: block"
                >
                  <div
                    data-v-02ab54fc=""
                    class="w100 h100 flex align-center justify-between"
                  >
                    <div data-v-02ab54fc="" class="flex-1 contentBox">
                      <div data-v-02ab54fc="" class="shirtNumber">
                        {{ away?.player?.jerseyNumber ?? "-" }}
                      </div>
                      <div
                        data-v-02ab54fc=""
                        class="playerLogo van-image van-image--round"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            away.player && away.player.id
                              ? 'https://sportyclub.online/images/cricket/players/' +
                                away.player.id +
                                '/' +
                                away.player.id +
                                '.png'
                            : bannerImagePath('default-team.png')
                          "
                          @error="
                            $event.target.src = bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <div data-v-02ab54fc="" class="playerInfo">
                        <span data-v-02ab54fc="" class="name">
                          {{
                            this.$i18n.locale == "cn" &&
                            away.player &&
                            away.player.cn_name
                              ? away.player.cn_name
                              : away.player.name
                              ? away.player.name
                              : ""
                          }}
                        </span>
                      </div>
                    </div>
                    <span data-v-02ab54fc="" class="marketValue">{{
                      away.position
                    }}</span>
                  </div>
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </span>
  <span v-else>
    <div
      class="flex justify-center align-center flex-col"
      style="height: 400px"
      data-v-4291ef99=""
    >
      <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
      <div class="color-999 fs-12 mt-12">
        {{ $t("football.live-score.temporarily-no-data") }}
      </div>
    </div>
  </span>
  <br />
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  name: "MatchLineups",
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
    };
  },
  created() {
    this.getMatchLineups(this.matchId);
  },
  methods: {
    // method to get home team matches
    getMatchLineups(matchId) {
      console.log(matchId);
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/lineups/${matchId}`
        )
        .then((response) => {
          this.matchLineups = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>