<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <div class="w100 view border-box" data-v-46436e01="" v-if="!loading">
    <!-- begin::broadcumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: black; padding-left: 0"
    >
      <span class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">
          <a href="/" target="_blank" style="cursor: pointer">
            <h1 class="liveScoreh1" v-if="this.$i18n.locale == 'cn'">
              {{ $t("football.live-score.aiScore-football-live-score") }}
            </h1>
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right"></i>
      </span>
    </div>
    <!-- end::broudcumb -->
    <div
      class="index-view flex justify-between basketball theme_init"
      data-v-46436e01=""
    >
      <div data-v-94567e5a="" data-v-46436e01="" class="index-l fs-13">
        <!-- begin::hot league list -->
        <HotLeagues />
        <!-- end::league list -->
        <!-- begin::languages -->
        <CountryList />
        <!-- end::languages -->
      </div>

      <span v-if="!loading">
        <div data-v-46436e01="">
          <!-- begin::banner -->
          <div
            class="bannerBox"
            data-v-d4c6fef0=""
            :style="toggleBannerState ? 'display:none' : 'display:block'"
          >
            <img
              :src="bannerImagePath('general-banner.png')"
              alt="#"
              class="banner"
              data-v-d4c6fef0=""
            />
            <i
              style="
                position: absolute;
                right: 20px;
                top: 10px;
                color: white;
                cursor: pointer;
              "
              class="iconfont icon-guanbi close"
              data-v-d4c6fef0=""
              v-on:click="toggleBanner(state)"
            ></i>
          </div>
          <!-- end::banner -->
          <div
            class="index-r"
            style="width: 992px; margin: 0"
            data-v-46436e01=""
          >
            <div class="match-score" data-v-46436e01=""></div>
            <div
              data-v-46436e01=""
              class="index-tab flex text-center justify-between"
              style="box-sizing: content-box"
            >
              <!-- begin::tabs -->
              <div class="changTabBox" data-v-d4c6fef0="">
                <span
                  :class="
                    liveActivebtn == 'all' ? 'activeElsTab' : 'notActiveTab'
                  "
                  class="changeItem bh"
                  data-v-d4c6fef0=""
                  v-on:click="getTodayMatchList('all')"
                >
                  {{ $t("football.live-score.all") }}
                </span>
                <span
                  :class="
                    liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
                  "
                  class="changeItem bh ml-12"
                  data-v-d4c6fef0=""
                  v-on:click="getTodayMatchList('live')"
                  ><span class="liveContentBox" data-v-d4c6fef0=""
                    ><i
                      class="iconfont icon-jinhangzhong liveImg"
                      data-v-d4c6fef0=""
                    ></i>
                    {{ $t("football.live-score.live") }}
                    <span data-v-d4c6fef0="" class="total">{{
                      countLiveMatches != 0 ? countLiveMatches : ""
                    }}</span></span
                  ></span
                >
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                  v-on:click="getTodayMatchList('finished')"
                  :class="
                    liveActivebtn == 'finished'
                      ? 'activeElsTab'
                      : 'notActiveTab'
                  "
                >
                  {{ $t("football.live-score.finished") }}
                </span>
                <span
                  class="changeItem bh ml-12 notActiveTab"
                  data-v-d4c6fef0=""
                  v-on:click="getTodayMatchList('upcoming')"
                  :class="
                    liveActivebtn == 'upcoming'
                      ? 'activeElsTab'
                      : 'notActiveTab'
                  "
                >
                  {{ $t("football.live-score.soon") }}
                </span>
              </div>
              <!-- end::tabs -->
            </div>
            <SearchMatches
              v-if="searchListUpcoming || searchListLive"
              :searchMatchListUpcoming="searchListUpcoming"
              :searchMatchListLive="searchListLive"
            />

            <div
              v-if="!searchListUpcoming && !searchListLive"
              data-v-46436e01=""
              class="vue-recycle-scroller scroller ready page-mode direction-vertical"
            >
              <div class="vue-recycle-scroller__item-wrapper">
                <div
                  v-if="
                    countLiveMatches === 0 &&
                    Object.entries(todayMatchList).length == 0
                  "
                  class="flex justify-center align-center flex-col"
                >
                  <img :src="bannerImagePath('default-container.png')" />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.league-details.no-live-match") }}
                  </div>
                </div>
                <div
                  v-else
                  class="vue-recycle-scroller__item-view"
                  style="transform: translateY(0px)"
                  v-for="(league, key) in todayMatchList"
                  :key="key"
                >
                  <div data-v-46436e01="" class="user">
                    <div data-v-46436e01="" class="flex align-center">
                      <div
                        data-v-a06a59d6=""
                        data-v-46436e01=""
                        style="width: 100%"
                      >
                        <div
                          data-v-a06a59d6=""
                          class="list_title"
                          :class="
                            leagueFavoriteStatus[league[0].unique_tournament_id]
                              ? 'collectCom'
                              : ''
                          "
                        >
                          <div data-v-a06a59d6="" class="topt flex">
                            <div
                              data-v-a06a59d6=""
                              style="
                                z-index: -1;
                                width: 13.03%;
                                min-width: 124px;
                              "
                            ></div>
                            <div
                              data-v-a06a59d6=""
                              style="
                                z-index: -1;
                                margin-left: 10px;
                                width: 21%;
                                min-width: 200px;
                              "
                            ></div>
                            <div data-v-a06a59d6="" class="flex-1 headBoxRight">
                              <span
                                data-v-a06a59d6=""
                                class="watchNum"
                                style="margin-right: 1px"
                                ><i
                                  data-v-a06a59d6=""
                                  class="iconfont icon-renshu1"
                                ></i>
                                <span data-v-a06a59d6=""></span
                              ></span>
                            </div>
                            <div data-v-a06a59d6="" class="Moneyline">
                              <div
                                data-v-a06a59d6=""
                                class="text-center oddsTypeText oddsWidth1 bh"
                              >
                                {{ $t("football.league-details.to-win") }}
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="text-center oddsTypeText oddsWidth2 bh"
                              >
                                {{ $t("football.league-details.handicap") }}
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="flex-1 text-center oddsTypeText oddsWidth3 bh"
                              >
                                {{ $t("football.league-details.total") }}
                              </div>
                            </div>
                          </div>
                          <div
                            data-v-2eea2747=""
                            class="flex align-center topLeftBox"
                          >
                            <i
                              class="iconfont"
                              :class="
                                leagueFavoriteStatus[
                                  league[0].unique_tournament_id
                                ]
                                  ? 'icon-yishoucang'
                                  : 'icon-weishoucang'
                              "
                              @click="
                                toggleFavorite(league[0].unique_tournament_id)
                              "
                              style="visibility: visible"
                            ></i>
                            <img
                              data-v-2eea2747=""
                              v-if="
                                league[0] &&
                                league[0].leagueInfo &&
                                ((league[0].leagueInfo.countryDetails &&
                                  league[0].leagueInfo.countryDetails.logo) ||
                                  (league[0].leagueInfo.countryDetails &&
                                    league[0].leagueInfo.countryDetails.name) ||
                                  (league[0].leagueInfo.categoryDetails &&
                                    league[0].leagueInfo.categoryDetails.name))
                              "
                              :src="
                                league[0] &&
                                league[0].leagueInfo &&
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.logo
                                  ? league[0].leagueInfo.countryDetails.logo
                                 : bannerImagePath('default-country.png')
                              "
                              alt="China"
                              class="countryLogo"
                            />
                            <span data-v-2eea2747="" class="countryText">
                              <span
                                v-if="this.$i18n.locale == 'cn'"
                                data-v-f1b1cb6e=""
                                class="country-name minitext"
                              >
                                <span
                                  v-if="
                                    league[0] &&
                                    league[0].leagueInfo &&
                                    league[0].leagueInfo.countryDetails &&
                                    league[0].leagueInfo.countryDetails.cn_name
                                  "
                                >
                                  {{
                                    league[0].leagueInfo.countryDetails.cn_name
                                  }}
                                </span>
                                <span
                                  v-else-if="
                                    league[0] &&
                                    league[0].leagueInfo &&
                                    league[0].leagueInfo.categoryDetails &&
                                    league[0].leagueInfo.categoryDetails.cn_name
                                  "
                                >
                                  {{
                                    league[0].leagueInfo.categoryDetails.cn_name
                                  }}
                                </span>
                              </span>

                              <span
                                v-else
                                data-v-f1b1cb6e=""
                                class="country-name minitext"
                              >
                                <span
                                  v-if="
                                    league[0] &&
                                    league[0].leagueInfo &&
                                    league[0].leagueInfo.countryDetails &&
                                    league[0].leagueInfo.countryDetails.name
                                  "
                                >
                                  {{
                                    league[0].leagueInfo.countryDetails.name
                                  }}:
                                </span>
                                <span
                                  v-else-if="
                                    league[0] &&
                                    league[0].leagueInfo &&
                                    league[0].leagueInfo.categoryDetails &&
                                    league[0].leagueInfo.categoryDetails.name
                                  "
                                >
                                  {{
                                    league[0].leagueInfo.categoryDetails.name
                                  }}
                                </span>
                              </span>
                              :</span
                            >
                            <span
                              v-if="
                                league &&
                                league[0] &&
                                league[0].leagueInfo &&
                                league[0].leagueInfo
                              "
                            >
                              <a
                                v-if="league[0].hasLeagueDetails"
                                data-v-2eea2747=""
                                :href="
                                  '/' +
                                  this.$i18n.locale +
                                  '/tennis/league/details/' +
                                  league[0].leagueInfo.en_name +
                                  '/' +
                                  league[0].unique_tournament_id
                                "
                                target="_blank"
                                class="compText"
                              >
                                {{
                                  this.$i18n.locale == "cn" &&
                                  league[0].leagueInfo.cn_name
                                    ? league[0].leagueInfo.cn_name
                                    : league[0].leagueInfo.en_name
                                    ? league[0].leagueInfo.en_name
                                    : ""
                                }}
                              </a>
                              <a
                                v-else
                                target="_blank"
                                class="compText"
                                style="cursor: not-allowed"
                              >
                                {{
                                  this.$i18n.locale == "cn" &&
                                  league[0].leagueInfo.cn_name
                                    ? league[0].leagueInfo.cn_name
                                    : league[0].leagueInfo.en_name
                                    ? league[0].leagueInfo.en_name
                                    : ""
                                }}
                              </a>
                            </span>
                          </div>
                        </div>
                        <div data-v-a06a59d6="">
                          <div
                            data-v-a06a59d6=""
                            class="list"
                            v-for="match in league"
                            :key="match.id"
                          >
                            <div
                              data-v-a06a59d6=""
                              class="flex align-center justify-center collectImgBox"
                            ></div>
                            <a
                              data-v-a06a59d6=""
                              :href="
                                '/' +
                                this.$i18n.locale +
                                '/tennis/match/details/' +
                                match.homeInfo.en_name
                                  .toLowerCase()
                                  .replace(/[\s/]+/g, '-') +
                                '-vs-' +
                                match.awayInfo.en_name
                                  .toLowerCase()
                                  .replace(/[\s/]+/g, '-') +
                                '/' +
                                match.id
                              "
                              target="_blank"
                              class="flex flex-1"
                              style="height: 100%"
                              ><div
                                data-v-a06a59d6=""
                                class="flex align-center listBox justify-around"
                              >
                                <span
                                  data-v-a06a59d6=""
                                  class="fs-13 matchTime"
                                  style="color: rgb(153, 153, 153)"
                                >
                                  {{
                                    readableTime(
                                      this.$i18n.locale,
                                      match.match_time
                                    )
                                  }}
                                </span>
                                <div
                                  data-v-a06a59d6=""
                                  class="flex flex-col color-999 fs-13 align-center text-center barItem color-r"
                                >
                                  <span data-v-2eea2747=""
                                    ><b>{{
                                      match.status_id &&
                                      this.FinishedMatches.includes(
                                        match.status_id
                                      )
                                        ? $t(
                                            "football.league-details.full-time"
                                          )
                                        : match?.statusDescription
                                    }}</b></span
                                  >
                                </div>
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="team flex flex-col padding5"
                              >
                                <div
                                  data-v-a06a59d6=""
                                  class="flex-1 align-center flex"
                                >
                                  <span data-v-2eea2747="" class="teamLogoBox"
                                    ><img
                                      data-v-2eea2747=""
                                      v-if="match.homeInfo"
                                      :src="
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo
                                          : bannerImagePath('default-player.png')
                                      "
                                  /></span>
                                  <div
                                    data-v-a06a59d6=""
                                    itemprop="homeTeam"
                                    class="w-o-h elseTeamName"
                                  >
                                    <span v-if="this.$i18n.locale == 'cn'">
                                      {{
                                        match &&
                                        match.homeInfo &&
                                        match.homeInfo.cn_name
                                          ? match.homeInfo.cn_name
                                          : ""
                                      }}
                                    </span>
                                    <span v-else>
                                      {{
                                        match &&
                                        match.homeInfo &&
                                        match.homeInfo.en_name
                                          ? match.homeInfo.en_name
                                          : ""
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  data-v-a06a59d6=""
                                  class="flex-1 align-center flex"
                                >
                                  <span data-v-2eea2747="" class="teamLogoBox"
                                    ><img
                                      data-v-2eea2747=""
                                      v-if="match.awayInfo"
                                      :src="
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo
                                          : bannerImagePath('default-player.png')
                                      "
                                  /></span>
                                  <div
                                    data-v-a06a59d6=""
                                    itemprop="awayTeam"
                                    class="w-o-h elseTeamName"
                                  >
                                    <span v-if="this.$i18n.locale == 'cn'">
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        match.awayInfo.cn_name
                                          ? match.awayInfo.cn_name
                                          : ""
                                      }}
                                    </span>
                                    <span v-else>
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        match.awayInfo.en_name
                                          ? match.awayInfo.en_name
                                          : ""
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="teamScore padding5"
                                v-if="
                                  liveMatches.includes(match.status_id) ||
                                  FinishedMatches.includes(match.status_id)
                                "
                              >
                                <div
                                  data-v-a06a59d6=""
                                  class="flex flex-col align-center"
                                  style="height: 100%"
                                >
                                  <div
                                    data-v-a06a59d6=""
                                    class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                                    style="font-family: Roboto-Regular, Roboto"
                                  >
                                    <div data-v-a06a59d6="" class="bigScore">
                                      <span data-v-a06a59d6="" class="scoreRed">
                                        {{
                                          match &&
                                          match.homeInfo &&
                                          (match.homeInfo.totalScore ||
                                            match.homeInfo.totalScore == 0)
                                            ? match.homeInfo.totalScore
                                            : "-"
                                        }}</span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                        >
                                          <div
                                            data-v-a06a59d6=""
                                            class="flex-1 text-center isVisible showTieBreak"
                                          >
                                            <span data-v-a06a59d6="" class=""
                                              ><span
                                                data-v-a06a59d6=""
                                                class="scoresBox"
                                                ><span
                                                  data-v-a06a59d6=""
                                                  class="tieBreak"
                                                >
                                                  {{
                                                    match &&
                                                    match.homeInfo &&
                                                    (match.homeInfo.x1 ||
                                                      match.homeInfo.x1 == 0)
                                                      ? match.homeInfo.x1
                                                      : ""
                                                  }}</span
                                                >
                                                {{
                                                  match &&
                                                  match.homeInfo &&
                                                  (match.homeInfo.p1 ||
                                                    match.homeInfo.p1 == 0)
                                                    ? match.homeInfo.p1
                                                    : "-"
                                                }}
                                              </span></span
                                            >
                                          </div>
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible showTieBreak"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.homeInfo &&
                                              (match.homeInfo.x2 ||
                                                match.homeInfo.x2 == 0)
                                                ? match.homeInfo.x2
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.homeInfo &&
                                            (match.homeInfo.p2 ||
                                              match.homeInfo.p2 == 0)
                                              ? match.homeInfo.p2
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible showTieBreak"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.homeInfo &&
                                              (match.homeInfo.x3 ||
                                                match.homeInfo.x3 == 0)
                                                ? match.homeInfo.x3
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.homeInfo &&
                                            (match.homeInfo.p3 ||
                                              match.homeInfo.p3 == 0)
                                              ? match.homeInfo.p3
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                        >
                                          <span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.homeInfo &&
                                              (match.homeInfo.x4 ||
                                                match.homeInfo.x4 == 0)
                                                ? match.homeInfo.x4
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.homeInfo &&
                                            (match.homeInfo.p4 ||
                                              match.homeInfo.p4 == 0)
                                              ? match.homeInfo.p4
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                  </div>
                                  <div
                                    data-v-a06a59d6=""
                                    class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                                    style="font-family: Roboto-Regular, Roboto"
                                  >
                                    <div
                                      data-v-a06a59d6=""
                                      class="bigScore scoreRed"
                                    >
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        (match.awayInfo.totalScore ||
                                          match.awayInfo.totalScore == 0)
                                          ? match.awayInfo.totalScore
                                          : "-"
                                      }}
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.awayInfo &&
                                              (match.awayInfo.x1 ||
                                                match.awayInfo.x1 == 0)
                                                ? match.awayInfo.x1
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.awayInfo &&
                                            (match.awayInfo.p1 ||
                                              match.awayInfo.p1 == 0)
                                              ? match.awayInfo.p1
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.awayInfo &&
                                              (match.awayInfo.x2 ||
                                                match.awayInfo.x2 == 0)
                                                ? match.awayInfo.x2
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.awayInfo &&
                                            (match.awayInfo.p2 ||
                                              match.awayInfo.p2 == 0)
                                              ? match.awayInfo.p2
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.awayInfo &&
                                              (match.awayInfo.x3 ||
                                                match.awayInfo.x3 == 0)
                                                ? match.awayInfo.x3
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.awayInfo &&
                                            (match.awayInfo.p3 ||
                                              match.awayInfo.p3 == 0)
                                              ? match.awayInfo.p3
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 text-center isVisible"
                                    >
                                      <span data-v-a06a59d6="" class=""
                                        ><span
                                          data-v-a06a59d6=""
                                          class="scoresBox"
                                          ><span
                                            data-v-a06a59d6=""
                                            class="tieBreak"
                                          >
                                            {{
                                              match &&
                                              match.awayInfo &&
                                              (match.awayInfo.x4 ||
                                                match.awayInfo.x4 == 0)
                                                ? match.awayInfo.x4
                                                : ""
                                            }}</span
                                          >
                                          {{
                                            match &&
                                            match.awayInfo &&
                                            (match.awayInfo.p4 ||
                                              match.awayInfo.p4 == 0)
                                              ? match.awayInfo.p4
                                              : "-"
                                          }}
                                        </span></span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                data-v-a06a59d6=""
                                class="flex-1 icon-setting"
                                style="
                                  border-right: 1px solid rgb(234, 234, 234);
                                "
                              ></div>
                              <div
                                data-v-a06a59d6=""
                                class="flex Moneyline moneyLineContent"
                                style="position: relative"
                              >
                                <div
                                  data-v-a06a59d6=""
                                  class="flex flex-1 oddsItem"
                                >
                                  <div
                                    data-v-a06a59d6=""
                                    class="text-center le padding5 lh oddsItemBox oddsWidth1"
                                  >
                                    <div data-v-a06a59d6="">
                                      <div data-v-a06a59d6="">
                                        <div
                                          data-v-a06a59d6=""
                                          class="oddhoverGreen oddColorGreen"
                                        >
                                          {{
                                            match &&
                                            match.odds &&
                                            match.odds != "" &&
                                            match.odds.euro &&
                                            match.odds.euro != ""
                                              ? match.odds.euro[1].toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                        <div
                                          data-v-a06a59d6=""
                                          class="oddhoverGreen oddTop oddColorRed"
                                        >
                                          {{
                                            match &&
                                            match.odds &&
                                            match.odds != "" &&
                                            match.odds.euro &&
                                            match.odds.euro != ""
                                              ? match.odds.euro[3].toFixed(2)
                                              : "-"
                                          }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    data-v-a06a59d6=""
                                    class="text-center flex le padding5 oddsItemBox oddsWidth2"
                                  >
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 lh handicapColor"
                                      style=""
                                    >
                                      <div data-v-a06a59d6="">
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds != "" &&
                                          match.odds.asia &&
                                          match.odds.asia != ""
                                            ? match.odds.asia[2] > 0
                                              ? "-" +
                                                match.odds.asia[2].toFixed(2)
                                              : "+" +
                                                Math.abs(
                                                  match.odds.asia[2]
                                                ).toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                      <div data-v-a06a59d6="" class="oddTop">
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds !== "" &&
                                          match.odds.asia &&
                                          match.odds.asia !== "" &&
                                          match.odds.asia[2] !== undefined
                                            ? Math.abs(
                                                match.odds.asia[2]
                                              ).toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                    </div>
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 lh oddTextColor"
                                      style=""
                                    >
                                      <div
                                        data-v-a06a59d6=""
                                        class="oddhoverGreen"
                                      >
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds.asia &&
                                          match.odds.asia.length > 1
                                            ? match.odds.asia[1].toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                      <div
                                        data-v-a06a59d6=""
                                        class="oddhoverGreen oddTop"
                                      >
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds.asia &&
                                          match.odds.asia.length > 1
                                            ? match.odds.asia[3].toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    data-v-a06a59d6=""
                                    class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                                  >
                                    <div
                                      data-v-a06a59d6=""
                                      class="flex-1 lh handicapColor"
                                      style=""
                                    >
                                      <div
                                        data-v-a06a59d6=""
                                        style="text-align: center"
                                      >
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds.asia &&
                                          match.odds.bigSmall &&
                                          match.odds.bigSmall.length > 1
                                            ? match.odds.bigSmall[1].toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                      <div
                                        data-v-a06a59d6=""
                                        class="oddTop"
                                        style="text-align: center"
                                      >
                                        {{
                                          match &&
                                          match.odds &&
                                          match.odds.asia &&
                                          match.odds.bigSmall &&
                                          match.odds.bigSmall.length > 3
                                            ? match.odds.bigSmall[3].toFixed(2)
                                            : "-"
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- begin::upcoming matches -->
        <div data-v-46436e01="" v-if="!searchListUpcoming && !searchListLive">
          <div class="index-r" style="width: 992px" data-v-46436e01="">
            <div
              data-v-46436e01=""
              class="vue-recycle-scroller scroller ready page-mode direction-vertical"
            >
              <UpcomingMatches
                v-if="
                  thereIsUpcomingMatches &&
                  !searchListUpcoming &&
                  !searchListLive
                "
              />
            </div>
          </div>
        </div>
        <!-- end::upcoming matches -->
      </span>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<!-- begin::scripts -->
<script>
import DateMixin from "@/utils/dateUtils";
import CountryList from "./tabs/countries-list.vue";
import HotLeagues from "./tabs/hot-leagues.vue";
import UpcomingMatches from "./tabs/upcoming-matches-list.vue";
import SearchMatches from "./tabs/search-matches-list.vue";
import LiveScoringMixin from "@/utils/liveScoreComputedDataMixin";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  components: {
    CountryList,
    HotLeagues,
    UpcomingMatches,
    SearchMatches,
  },

  mixins: [LiveScoringMixin, DateMixin, DynamicImagePath],
  data() {
    return {
      todayUpcomingMatchList: null,
      searchListUpcoming: null,
      searchListLive: null,
      todayMatchList: null,
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [51, 52, 53, 54, 55],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",
      isTimeActive: false,
      toggleBannerState: false,
      leagueFavoriteStatus: {}, // Store favorite status for each league
    };
  },
  mounted() {
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },
  created() {
    this.gethotLeaguesList();
    this.getTodayMatchList(this.liveActivebtn);
    this.getCountryLeagues();
    this.getUpcomingMatchList();

    //  refresh frontend every 30 seconds
    setInterval(() => {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/tennis/match/list/today?matchStatus=${this.liveActivebtn}`
        )
        .then((response) => {
          this.todayMatchList = response.data.tennisMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100000);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `tennis_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`tennis_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`tennis_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.footballHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get today matchlist
    getTodayMatchList(status) {
      this.liveActivebtn = status;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/tennis/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.tennisMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get country list
    getCountryLeagues() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/country/list`)
        .then((response) => {
          this.countryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get League Details

    // method to toggle banner
    toggleBanner(state) {
      this.toggleBannerState = !state;
    },

    getUpcomingMatchList() {
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/tennis/match/list/today?matchStatus=upcoming`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.tennisMatchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}
</style>
