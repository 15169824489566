<template>
  <div style="width: 1200px; margin: 0 auto" data-v-5754b2d9="">
    <div class="analyseContent" data-v-1b5acd91="" data-v-5754b2d9="">
      <div class="flex" data-v-1b5acd91="">
        <!-- begin::home team h2h -->
        <div data-v-1b5acd91="" class="contentBox flex-1 rightMargin12">
          <div data-v-1b5acd91="" class="allBox">
            <!-- begin::header -->
            <div data-v-1b5acd91="" class="headerBox">
              <p data-v-1b5acd91="">
                <span data-v-1b5acd91=""
                  >{{
                    !loading
                      ? matchInfo.homeTeam.name
                      : $t("football.league-details.home-team-h2h")
                  }}
                  {{ $t("football.league-details.team-h2h") }}
                </span>
              </p>
            </div>

            <div data-v-1b5acd91="" class="infoBox">
              <span data-v-1b5acd91=""
                ><span data-v-1b5acd91="" class="round win flexCenter">W</span>
                <span data-v-1b5acd91="" class="text">X3</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20"
                ><span data-v-1b5acd91="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-1b5acd91="" class="text">X2</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20 infoItem"
                ><span data-v-1b5acd91="" class="pts">pts</span>
                <span data-v-1b5acd91="" class="text">
                  0.0 - 0.0 per game
                </span></span
              >
            </div>
            <!-- end::header -->
            <!-- if loader -->
            <div data-v-1b5acd91="" class="matchContent" v-if="homeLoading">
              <div>
                <img
                  src="/images/default-loader.gif"
                  style="width: 120px; height: 60px !important"
                />
              </div>
            </div>
            <!-- display data -->
            <div
              data-v-1b5acd91=""
              class="matchContent"
              v-else-if="
                !homeLoading && homeTeamMatches && homeTeamMatches != ''
              "
            >
              <div>
                <ul data-v-1b5acd91="" class="matchBox">
                  <li
                    data-v-1b5acd91=""
                    v-for="match in homePaginatedMatches"
                    :key="match.id"
                  >
                    <a
                      data-v-1b5acd91=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      target="_blank"
                    >
                      <p data-v-1b5acd91="" class="collect flexCenter">
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-if="match.status.code == 90"
                        >
                          D
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter win"
                          v-else-if="match.winnerCode == 1"
                        >
                          W
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-else
                        >
                          L
                        </span>
                      </p>

                      <p
                        data-v-1b5acd91=""
                        itemprop="startDate"
                        class="time flexCenter"
                      >
                        {{
                          displayDateTimeFromUnixTimestamp(match.startTimestamp)
                        }}
                      </p>
                      <p data-v-1b5acd91="" class="teamBox">
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.homeTeam.id +
                                '/' +
                                match.homeTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span data-v-1b5acd91="" class="teamName">{{
                            match.homeTeam.name
                          }}</span></span
                        >
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.awayTeam.id +
                                '/' +
                                match.awayTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span
                            data-v-1b5acd91=""
                            itemprop="name"
                            class="teamName"
                            >{{ match.awayTeam.name }}</span
                          ></span
                        >
                      </p>

                      <p data-v-1b5acd91="" class="scoreBox">
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.homeScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.awayScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </div>
              <div class="text-center mt-24">
                <div class="el-pagination">
                  <button
                    type="button"
                    class="btn-prev"
                    @click="homePrevPage"
                    :disabled="homeCurrentPage === 1"
                  >
                    <i class="el-icon el-icon-arrow-left"></i>
                  </button>
                  <ul class="el-pager">
                    <li
                      v-for="page in homeTotalPages"
                      :key="page"
                      :class="{
                        number: true,
                        active: page === homeCurrentPage,
                      }"
                      @click="homeGoToPage(page)"
                    >
                      {{ page }}
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn-next"
                    @click="homeNextPage"
                    :disabled="homeCurrentPage === homeTotalPages"
                  >
                    <i class="el-icon el-icon-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- if no data -->
            <div data-v-1b5acd91="" class="matchContent" v-else>
              <div>
                <div
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                  data-v-8d245a92=""
                  data-v-7c947e55=""
                >
                  <img src="/images/themes/red/no-data.png" />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::home team h2h -->
        <!-- begin::away team h2h -->
        <div data-v-1b5acd91="" class="contentBox flex-1 rightMargin12">
          <div data-v-1b5acd91="" class="allBox">
            <!-- begin::header -->

            <div data-v-1b5acd91="" class="headerBox">
              <p data-v-1b5acd91="">
                <span data-v-1b5acd91=""
                  >{{
                    !loading
                      ? matchInfo.awayTeam.name
                      : $t("football.league-details.away-team-h2h")
                  }}
                  {{ $t("football.league-details.team-h2h") }}
                </span>
              </p>
            </div>

            <div data-v-1b5acd91="" class="infoBox">
              <span data-v-1b5acd91=""
                ><span data-v-1b5acd91="" class="round win flexCenter">W</span>
                <span data-v-1b5acd91="" class="text">X3</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20"
                ><span data-v-1b5acd91="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-1b5acd91="" class="text">X2</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20 infoItem"
                ><span data-v-1b5acd91="" class="pts">pts</span>
                <span data-v-1b5acd91="" class="text">
                  0.0 - 0.0 per game
                </span></span
              >
            </div>
            <!-- end::header -->
            <!-- if loader -->
            <div data-v-1b5acd91="" class="matchContent" v-if="awayLoading">
              <div>
                <img
                  src="/images/default-loader.gif"
                  style="width: 120px; height: 60px !important"
                />
              </div>
            </div>
            <!-- display data -->
            <div
              data-v-1b5acd91=""
              class="matchContent"
              v-else-if="
                !awayLoading && awayTeamMatches && awayTeamMatches.length > 0
              "
            >
              <div>
                <ul data-v-1b5acd91="" class="matchBox">
                  <li
                    data-v-1b5acd91=""
                    v-for="match in awayPaginatedMatches"
                    :key="'away_' + match.id"
                  >
                    <a
                      data-v-1b5acd91=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      target="_blank"
                      itemprop="url"
                    >
                      <p data-v-1b5acd91="" class="collect flexCenter">
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-if="match.status.code == 90"
                        >
                          D
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter win"
                          v-else-if="match.winnerCode == 1"
                        >
                          W
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-else
                        >
                          L
                        </span>
                      </p>

                      <p
                        data-v-1b5acd91=""
                        itemprop="startDate"
                        class="time flexCenter"
                      >
                        {{
                          displayDateTimeFromUnixTimestamp(match.startTimestamp)
                        }}
                      </p>
                      <p data-v-1b5acd91="" class="teamBox">
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.homeTeam.id +
                                '/' +
                                match.homeTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span data-v-1b5acd91="" class="teamName">{{
                            match.homeTeam.name
                          }}</span></span
                        >
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.awayTeam.id +
                                '/' +
                                match.awayTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span
                            data-v-1b5acd91=""
                            itemprop="name"
                            class="teamName"
                            >{{ match.awayTeam.name }}</span
                          ></span
                        >
                      </p>

                      <p data-v-1b5acd91="" class="scoreBox">
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.homeScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.awayScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </div>

              <div class="text-center mt-24">
                <div class="el-pagination">
                  <button
                    type="button"
                    class="btn-prev"
                    @click="awayPrevPage"
                    :disabled="awayCurrentPage === 1"
                  >
                    <i class="el-icon el-icon-arrow-left"></i>
                  </button>
                  <ul class="el-pager">
                    <li
                      v-for="page in awayTotalPages"
                      :key="page"
                      :class="{
                        number: true,
                        active: page === awayCurrentPage,
                      }"
                      @click="awayGoToPage(page)"
                    >
                      {{ page }}
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn-next"
                    @click="awayNextPage"
                    :disabled="awayCurrentPage === awayTotalPages"
                  >
                    <i class="el-icon el-icon-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- if no data -->
            <div data-v-1b5acd91="" class="matchContent" v-else>
              <div>
                <div
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                  data-v-8d245a92=""
                  data-v-7c947e55=""
                >
                  <img src="/images/themes/red/no-data.png" />
                  <div class="color-999 fs-12 mt-12">No data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::away team h2h -->
        <!-- begin::match h2h -->

        <div data-v-1b5acd91="" class="contentBox flex-1 rightMargin12">
          <div data-v-1b5acd91="" class="allBox">
            <!-- begin::header -->
            <div data-v-1b5acd91="" class="headerBox">
              <p data-v-1b5acd91="">
                <span data-v-1b5acd91=""
                  >{{
                    !loading
                      ? matchInfo.homeTeam.name +
                        " vs " +
                        matchInfo.awayTeam.name
                      : "Home vs Away"
                  }}
                  H2H</span
                >
              </p>
            </div>

            <div data-v-1b5acd91="" class="infoBox">
              <span data-v-1b5acd91=""
                ><span data-v-1b5acd91="" class="round win flexCenter">W</span>
                <span data-v-1b5acd91="" class="text">X3</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20"
                ><span data-v-1b5acd91="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-1b5acd91="" class="text">X2</span></span
              >
              <span data-v-1b5acd91="" class="leftMargin20 infoItem"
                ><span data-v-1b5acd91="" class="pts">pts</span>
                <span data-v-1b5acd91="" class="text">
                  0.0 - 0.0 per game
                </span></span
              >
            </div>
            <!-- end::header -->
            <!-- if loader -->
            <div data-v-1b5acd91="" class="matchContent" v-if="matchLoading">
              <div>
                <img
                  src="/images/default-loader.gif"
                  style="width: 120px; height: 60px !important"
                />
              </div>
            </div>
            <!-- display data -->
            <div
              data-v-1b5acd91=""
              class="matchContent"
              v-else-if="!matchLoading && matchH2H !== ''"
            >
              <div v-for="(league, leagueName) in matchH2H" :key="league">
                <p data-v-1b5acd91="" class="header">
                  <span data-v-1b5acd91="" class="comp flexCenter">
                    <span data-v-1b5acd91="" class="flexCenter">
                      <b>{{ leagueName }}</b>
                    </span></span
                  >
                </p>
                <ul data-v-1b5acd91="" class="matchBox">
                  <li
                    data-v-1b5acd91=""
                    v-for="match in league"
                    :key="match.id"
                  >
                    <a
                      data-v-1b5acd91=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      target="_blank"
                      itemprop="url"
                    >
                      <p data-v-1b5acd91="" class="collect flexCenter">
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-if="match.status.code == 90"
                        >
                          D
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter win"
                          v-else-if="match.winnerCode == 1"
                        >
                          W
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="round flexCenter loser"
                          v-else-if="match.winnerCode == 2"
                        >
                          L
                        </span>
                      </p>

                      <p
                        data-v-1b5acd91=""
                        itemprop="startDate"
                        class="time flexCenter"
                      >
                        {{
                          displayDateTimeFromUnixTimestamp(match.startTimestamp)
                        }}
                      </p>
                      <p data-v-1b5acd91="" class="teamBox">
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.homeTeam.id +
                                '/' +
                                match.homeTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span data-v-1b5acd91="" class="teamName">{{
                            match.homeTeam.name
                          }}</span></span
                        >
                        <span data-v-1b5acd91="" class="teamBoxItem"
                          ><span data-v-1b5acd91=""
                            ><img
                              data-v-1b5acd91=""
                              :src="
                                'https://sportyclub.online//images/cricket/teams/' +
                                match.awayTeam.id +
                                '/' +
                                match.awayTeam.id +
                                '.png'
                              "
                              @error="
                                $event.target.src = bannerImagePath(
                                  'default-country.png'
                                )
                              "
                          /></span>
                          <span
                            data-v-1b5acd91=""
                            itemprop="name"
                            class="teamName"
                            >{{ match.awayTeam.name }}</span
                          ></span
                        >
                      </p>

                      <p data-v-1b5acd91="" class="scoreBox">
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.homeScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                        <span
                          data-v-1b5acd91=""
                          class="flexCenter"
                          v-for="inn in match.awayScore.innings"
                          :key="inn"
                        >
                          {{
                            inn.score +
                            "/" +
                            inn.wickets +
                            " (" +
                            inn.overs +
                            ")"
                          }}
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- if no data -->
            <div data-v-1b5acd91="" class="matchContent" v-else>
              <div>
                <div
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                  data-v-8d245a92=""
                  data-v-7c947e55=""
                >
                  <img src="/images/themes/red/no-data.png" />
                  <div class="color-999 fs-12 mt-12">No data</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end::match h2h -->
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    homeTeamId: {
      type: Number,
      default: null,
    },
    awayTeamId: {
      type: Number,
      default: null,
    },
    customId: {
      type: Number,
      default: null,
    },
    matchId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      homeTeamMatches: null,
      awayTeamMatches: null,
      matchH2H: null,
      homeLoading: true,
      awayLoading: true,
      matchLoading: true,
      matchInfo: null,
      loading: true,
      homeCurrentPage: 1,
      homeMatchesPerPage: 5,
      awayCurrentPage: 1,
      awayMatchesPerPage: 5,
    };
  },
  computed: {
    awayTotalPages() {
      return Math.ceil(this.awayTeamMatches.length / this.awayMatchesPerPage);
    },
    awayPaginatedMatches() {
      const startIndex = (this.awayCurrentPage - 1) * this.awayMatchesPerPage;
      const endIndex = startIndex + this.awayMatchesPerPage;
      return this.awayTeamMatches.slice(startIndex, endIndex);
    },

    homeTotalPages() {
      return Math.ceil(this.homeTeamMatches.length / this.homeMatchesPerPage);
    },
    homePaginatedMatches() {
      const startIndex = (this.homeCurrentPage - 1) * this.homeMatchesPerPage;
      const endIndex = startIndex + this.homeMatchesPerPage;
      return this.homeTeamMatches.slice(startIndex, endIndex);
    },
  },
  created() {
    this.getMatchInfo(this.matchId);
    this.getHomeTeamDetails(this.homeTeamId);

    this.getAwayTeamDetails(this.awayTeamId);
    this.getMatchH2H(this.customId);
  },
  methods: {
    awayPrevPage() {
      if (this.awayCurrentPage > 1) {
        this.awayCurrentPage--;
      }
    },
    awayNextPage() {
      if (this.awayCurrentPage < this.awayTotalPages) {
        this.awayCurrentPage++;
      }
    },
    awayGoToPage(page) {
      this.awayCurrentPage = page;
    },
    homePrevPage() {
      if (this.homeCurrentPage > 1) {
        this.homeCurrentPage--;
      }
    },
    homeNextPage() {
      if (this.homeCurrentPage < this.homeTotalPages) {
        this.homeCurrentPage++;
      }
    },
    homeGoToPage(page) {
      this.homeCurrentPage = page;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    // method to get match h2h
    getMatchInfo(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/details/${matchId}`
        )
        .then((response) => {
          this.matchInfo = response.data.event;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get home team matches
    getHomeTeamDetails(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/teams/h2h/previous-matches/${teamId}`
        )
        .then((response) => {
          this.homeTeamMatches = response.data.events.reverse();
          this.homeLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get away team matches
    getAwayTeamDetails(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/teams/h2h/previous-matches/${teamId}`
        )
        .then((response) => {
          this.awayTeamMatches = response.data.events.reverse();
          this.awayLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get league h2h
    getMatchH2H(customeId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/teams/h2h/match/${customeId}`
        )
        .then((response) => {
          this.matchH2H = response.data;

          this.matchLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    displayDateTimeFromUnixTimestamp(unixTimestamp) {
      // Convert Unix timestamp to milliseconds
      var date = new Date(unixTimestamp * 1000);

      // Define days of the week
      var daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dayOfWeek = daysOfWeek[date.getDay()];
      var month = date.toLocaleString("default", { month: "long" });
      var dayOfMonth = date.getDate();
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
      return (
        dayOfWeek +
        ", " +
        month +
        " " +
        dayOfMonth +
        ", " +
        year +
        " " +
        hours +
        ":" +
        formattedMinutes
      );
    },
  },
};
</script>
