<template>
  <div data-fetch-key="0" class="footer border-box" data-v-2c0a9f08="">
    <div class="contact" data-v-2c0a9f08="">
      <div class="about-us bd-bottom" data-v-2c0a9f08="">
        <div class="flex" data-v-2c0a9f08="">
          <div class="foot-l" data-v-2c0a9f08="">
            <svg aria-hidden="true" class="icon w-114 h-30" data-v-2c0a9f08="">
              <use xlink:href="#icon-AiScorelogo" data-v-2c0a9f08=""></use>
            </svg>
            <div
              class="fs-12 mt-24 mb-24 line-h-16"
              data-v-2c0a9f08=""
              v-if="this.$i18n.locale == 'cn'"
            >
              AiScore 足球比分直播为您提供来自超过 2600
              个足球联赛、杯赛和锦标赛的无与伦比的足球实时比分和足球结果。获取英超联赛、西甲联赛、意甲联赛、德甲联赛、法甲联赛、荷甲联赛、俄罗斯超级联赛、巴西甲级联赛、美国职业足球大联盟、
              AiScore.com 上的超级联赛和锦标赛。 AiScore
              为所有足球迷提供实时比分、足球即时比分、足球比分、联赛、杯赛和锦标赛的联赛表和赛程，不仅包括英格兰超级联赛、西班牙西甲、意大利甲级联赛、德国甲级联赛等最受欢迎的足球联赛德甲、法国甲级联赛，而且还来自世界各地的众多足球国家，包括来自北美、南美、亚洲和非洲。我们的足球
              LiveScore 记分卡实时更新，让您及时了解今天发生的所有足球比赛
              LiveScore 更新以及每个足球和足球联赛的所有已完成足球比赛的足球
              LiveScore
              结果。在比赛页面上，我们的足球记分卡可让您查看每场足球比赛之前所有比赛的比赛结果。在
              AiScore.com 上获取所有足球直播结果！
            </div>
            <div class="fs-12 mt-24 mb-24 line-h-16" data-v-2c0a9f08="" v-else>
              AiScore Football LiveScore provides you with unparalleled football
              live scores and football results from over 2600+ football leagues,
              cups and tournaments. Get live scores, halftime and full time
              soccer results, goal scorers and assistants, cards, substitutions,
              match statistics and live stream from Premier League, La Liga,
              Serie A, Bundesliga, Ligue 1, Eredivisie, Russian Premier League,
              Brasileirão, MLS, Super Lig and Championship on AiScore.com.
              AiScore offers to all the soccer fans live scores, soccer
              LiveScore, soccer scores, league tables and fixtures for leagues,
              cups and tournaments, and not only from the most popular football
              leagues as England Premier League, Spain La Liga, Italy Serie A,
              Germany Bundesliga, France Ligue 1, but also from a large range of
              football countries all over the world, including from North and
              South America, Asia and Africa. Our football LiveScore scorecards
              are updated live in real-time to keep you up to date with all the
              football match LiveScore updates happening today along with
              football LiveScore results for all finished football matches for
              every football & soccer league. On the match page, our football
              scorecards are allowing you to view past game results for all
              previously played fixtures for every football competitions. Get
              all of your football live results on AiScore.com!
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix-row text-center mt-24 mb-24" data-v-2c0a9f08="">
        <a
          target="_blank"
          rel="sponsored"
          class="mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/twitter.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
        <a
          target="_blank"
          rel="sponsored"
          class="ml-15 mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/facebook.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
        <a
          target="_blank"
          rel="sponsored"
          class="ml-15 mytransition"
          data-v-2c0a9f08=""
          ><img
            src="/images/socials-icons/instagram.png"
            class="h-24"
            data-v-2c0a9f08=""
        /></a>
      </div>

      <div
        class="clearfix-row text-center fs-12 mb-16 mt-10 color-w"
        data-v-2c0a9f08=""
      >
        <span
          class="text-item"
          style="color: rgba(255, 255, 255, 0.4)"
          data-v-2c0a9f08=""
          >Copyright @ 2024 AiScore</span
        >
        <span
          class="text-item"
          style="color: rgba(255, 255, 255, 0.4)"
          data-v-2c0a9f08=""
          >18+ Gamble Responsibly</span
        >
      </div>
      <div class="line" data-v-2c0a9f08=""></div>
      <div
        class="text-center fs-12 mt-10 color-w flex align-center justify-center"
        style="padding-bottom: 18px"
        data-v-2c0a9f08=""
      >
        <a
          target="_blank"
          rel="nofollow"
          class="forto mytransition"
          style="display: flex; align-items: center; line-height: 13px"
          data-v-2c0a9f08=""
          ><i
            data-v-1a9bb128=""
            class="iconfont icon-thesports thesportsIcon"
            data-v-2c0a9f08=""
          ></i>
          <span
            class="text-item thesports"
            style="line-height: 13px"
            data-v-2c0a9f08=""
            >AiScore's Sports Data Provider</span
          ></a
        >
      </div>
    </div>
  </div>
</template>
