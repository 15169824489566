<template>
  <div class="header desktop-version">
    <!-- begin::header -->
    <TopHeader
      v-on:settingsClicked="toggleSettingsDialog"
      v-on:singinClicked="toggleSinginDialog"
      v-on:signOut="signOut"
      :userInfo="userInfo"
    />
    <!-- end::header -->
    <!-- begin::menubar header -->
    <header class="sub-header" data-v-698032b0="">
      <nav
        class="inner h-bar-100 flex border-box justify-between"
        data-v-698032b0=""
      >
        <div class="flex flex-1" style="opacity: 1" data-v-698032b0="">
          <div id="ballTypeRef" class="flex" data-v-698032b0="">
            <a
              :href="'/' + this.$i18n.locale + '/live/cricket'"
              class="sub-tab"
              style="position: relative"
              data-v-698032b0=""
              :class="{ active: activeButton === 'cricket' }"
              @click="
                changeActive(
                  'cricket',
                  '/' + this.$i18n.locale + '/live/cricket'
                )
              "
              ><span
                class="text-box flex"
                style="white-space: nowrap"
                data-v-698032b0=""
              >
                <svg
                  v-if="activeButton === 'cricket'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-banqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-banqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>
                <span class="text font-500" data-v-698032b0="">{{
                  $t("common.menu.cricket")
                }}</span>

                <!-- <span class="livenum" data-v-698032b0="">{{
                  countCricketLiveMatches != 0 ? countCricketLiveMatches : ""
                }}</span> -->
              </span>
            </a>
            <a
              :href="'/' + this.$i18n.locale + '/live/football'"
              :class="{ active: activeButton === 'football' }"
              @click="
                changeActive(
                  'football',
                  '/' + this.$i18n.locale + '/live/football'
                )
              "
              class="sub-tab"
              style="position: relative"
              data-v-698032b0=""
              ><span
                class="text-box flex"
                style="white-space: nowrap"
                data-v-698032b0=""
              >
                <svg
                  v-if="activeButton === 'football'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-zuqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-zuqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>
                <span class="text font-500" data-v-698032b0="">
                  {{ $t("common.menu.football") }}
                </span>
                <span class="livenum" data-v-698032b0="">{{
                  countFootballLiveMatches != 0 ? countFootballLiveMatches : ""
                }}</span>
              </span></a
            >
            <a
              :href="'/' + this.$i18n.locale + '/live/basketball'"
              :class="{ active: activeButton === 'basketball' }"
              @click="
                changeActive(
                  'basketball',
                  '/' + this.$i18n.locale + '/live/basketball'
                )
              "
              class="sub-tab"
              style="position: relative"
              data-v-698032b0=""
              ><span
                class="text-box flex"
                style="white-space: nowrap"
                data-v-698032b0=""
              >
                <svg
                  v-if="activeButton === 'basketball'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-lanqiu-yixuanzhong1"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-lanqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span class="text font-500" data-v-698032b0="">
                  {{ $t("common.menu.basketball") }}</span
                >

                <span class="livenum" data-v-698032b0="">{{
                  countBasketballLiveMatches != 0
                    ? countBasketballLiveMatches
                    : ""
                }}</span>
              </span></a
            >

            <a
              :href="'/' + this.$i18n.locale + '/live/tennis'"
              :class="{ active: activeButton === 'tennis' }"
              @click="
                changeActive('tennis', '/' + this.$i18n.locale + '/live/tennis')
              "
              class="sub-tab"
              style="position: relative"
              data-v-698032b0=""
              ><span
                class="text-box flex"
                style="white-space: nowrap"
                data-v-698032b0=""
              >
                <svg
                  v-if="activeButton === 'tennis'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-wangqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-a-wangqiuweixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span class="text font-500" data-v-698032b0="">{{
                  $t("common.menu.tennis")
                }}</span>
                <span class="livenum" data-v-698032b0="">{{
                  countTennisLiveMatches != 0 ? countTennisLiveMatches : ""
                }}</span>
              </span></a
            >

            <a
              :href="'/' + this.$i18n.locale + '/live/baseball'"
              class="sub-tab"
              style="position: relative"
              data-v-698032b0=""
              :class="{ active: activeButton === 'baseball' }"
              @click="
                changeActive(
                  'baseball',
                  '/' + this.$i18n.locale + '/live/baseball'
                )
              "
            >
              <span
                class="text-box flex"
                style="white-space: nowrap"
                data-v-698032b0=""
              >
                <svg
                  v-if="activeButton === 'baseball'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-bangqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-bangqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span class="text font-500" data-v-698032b0=""
                  >{{ $t("common.menu.baseball") }}
                </span>
                <span class="livenum" data-v-698032b0=""
                  >{{
                    countBaseballLiveMatches != 0
                      ? countBaseballLiveMatches
                      : ""
                  }}
                </span>
              </span>
            </a>
            <a
              data-v-698032b0=""
              :href="'/' + this.$i18n.locale + '/live/volleyball'"
              class="sub-tab"
              style="position: relative"
              :class="{ active: activeButton === 'volleyball' }"
              @click="
                changeActive(
                  'volleyball',
                  '/' + this.$i18n.locale + '/live/volleyball'
                )
              "
            >
              <span
                data-v-698032b0=""
                class="text-box flex"
                style="white-space: nowrap"
              >
                <svg
                  v-if="activeButton === 'volleyball'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-paiqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-paiqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span data-v-698032b0="" class="text font-500"
                  >{{ $t("common.menu.volleyball") }}
                </span>
                <span class="livenum" data-v-698032b0="">{{
                  countVolleyballLiveMatches != 0
                    ? countVolleyballLiveMatches
                    : ""
                }}</span>
              </span>
            </a>
            <a
              data-v-698032b0=""
              :href="'/' + this.$i18n.locale + '/live/table_tennis'"
              class="sub-tab"
              style="position: relative"
              :class="{ active: activeButton === 'table_tennis' }"
              @click="
                changeActive(
                  'table_tennis',
                  '/' + this.$i18n.locale + '/live/table_tennis'
                )
              "
            >
              <span
                data-v-698032b0=""
                class="text-box flex"
                style="white-space: nowrap"
              >
                <svg
                  v-if="activeButton === 'table_tennis'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-pingpangqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-pingpangqiu-yixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span data-v-698032b0="" class="text font-500"
                  >{{ $t("common.menu.table_tennis") }}
                </span>
                <span class="livenum" data-v-698032b0="">{{
                  countTableTennisLiveMatches != 0
                    ? countTableTennisLiveMatches
                    : ""
                }}</span>
              </span>
            </a>

            <a
              data-v-698032b0=""
              :href="'/' + this.$i18n.locale + '/live/hockey'"
              class="sub-tab"
              style="position: relative"
              :class="{ active: activeButton === 'hockey' }"
              @click="
                changeActive('hockey', '/' + this.$i18n.locale + '/live/hockey')
              "
            >
              <span
                data-v-698032b0=""
                class="text-box flex"
                style="white-space: nowrap"
              >
                <svg
                  v-if="activeButton === 'hockey'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-bingqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-bingqiu-weixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span data-v-698032b0="" class="text font-500"
                  >{{ $t("common.menu.hockey") }}
                </span>
                <span class="livenum" data-v-698032b0="">{{
                  countHockeyLiveMatches != 0 ? countHockeyLiveMatches : ""
                }}</span>
              </span>
            </a>
            <a
              data-v-698032b0=""
              :href="'/' + this.$i18n.locale + '/live/badminton'"
              class="sub-tab"
              style="position: relative"
              :class="{ active: activeButton === 'badminton' }"
              @click="
                changeActive(
                  'badminton',
                  '/' + this.$i18n.locale + '/live/badminton'
                )
              "
            >
              <span
                data-v-698032b0=""
                class="text-box flex"
                style="white-space: nowrap"
              >
                <svg
                  v-if="activeButton === 'badminton'"
                  aria-hidden="true"
                  class="activeBallIcon"
                  data-v-698032b0=""
                >
                  <use
                    xlink:href="#icon-yumaoqiu-yixuanzhong"
                    data-v-698032b0=""
                  ></use>
                </svg>
                <i
                  v-else
                  class="iconfont icon-yumaoqiu-yixuanzhong"
                  data-v-698032b0=""
                ></i>

                <span data-v-698032b0="" class="text font-500"
                  >{{ $t("common.menu.badminton") }}
                </span>
                <span class="livenum" data-v-698032b0="">{{
                  countBadmintonLiveMatches != 0
                    ? countBadmintonLiveMatches
                    : ""
                }}</span>
              </span>
            </a>
          </div>
        </div>
      </nav>
    </header>
    <!-- end::menubar header -->
    <!-- settings dialog modal -->
    <SettingsDialog
      v-if="settingsDialogStatus"
      v-on:closeDialog="toggleSettingsDialog"
    />
    <SigninDialogDesktop
      v-if="signinDialogStatus"
      v-on:closeSinginDialog="toggleSinginDialog"
    />
    <!-- settings dialog modal end -->
  </div>
  <!-- begin::mobile version menu bars -->
  <div class="mobile-version">
    <div class="homeHeader" data-v-736e8afd="" style="height: 105px">
      <div class="flex align-center headerTop" data-v-736e8afd="">
        <a
          href="/"
          target="_self"
          class="leftBox flex flex-1 align-center"
          data-v-736e8afd=""
          ><img
            src="/images/logo/logo.png"
            alt="#"
            data-v-736e8afd=""
            style="width: 150px !important; height: 80px !important"
        /></a>
        <div
          class="rightBox flex flex-1 align-center justify-end"
          data-v-736e8afd=""
        >
          <span
            @click="toggleSearchBox"
            class="searchIconBox flex justify-center align-center"
            data-v-736e8afd=""
            ><i class="iconfont icon-sousuo" data-v-736e8afd=""></i
          ></span>
          <span
            @click="toggleSettingsDialog"
            class="searchIconBox flex justify-center align-center ml-8"
            data-v-736e8afd=""
            ><i class="iconfont icon-caidan" data-v-736e8afd=""></i
          ></span>
        </div>
      </div>
      <SearchBox v-if="searchBoxStatus" v-on:closeSearchBox="toggleSearchBox" />

      <ul class="ballBox flex" data-v-736e8afd="" v-if="!searchBoxStatus">
        <li
          class="flex justify-center align-center flex-1"
          data-v-736e8afd=""
          @click="changeActive('cricket', '/en/live/cricket')"
          :class="activeButton == 'cricket' ? 'activeBall' : ''"
        >
          <a
            :href="'/' + this.$i18n.locale + '/live/cricket'"
            target="_self"
            data-v-736e8afd=""
            style="color: var(--main-theme-font-color)"
          >
            <div class="iconBox" data-v-736e8afd="">
              <i
                class="iconfont icon-banqiu-weixuanzhong"
                data-v-736e8afd=""
              ></i>
              <span data-v-736e8afd="" class="liveNum">
                {{
                  countCricketLiveMatches != 0 ? countCricketLiveMatches : ""
                }}</span
              >
            </div>
            <span class="name ballName" data-v-736e8afd="">{{
              $t("common.menu.cricket")
            }}</span>
          </a>
        </li>
        <li
          :class="activeButton == 'football' ? 'activeBall' : ''"
          class="flex justify-center align-center flex-1"
          @click="changeActive('football', '/en/live/football')"
        >
          <a
            style="color: var(--main-theme-font-color)"
            :href="'/' + this.$i18n.locale + '/live/football'"
            target="_self"
            data-v-736e8afd=""
          >
            <div class="iconBox" data-v-736e8afd="">
              <i
                class="iconfont icon-zuqiu-weixuanzhong"
                data-v-736e8afd=""
              ></i>
              <span data-v-736e8afd="" class="liveNum">
                {{
                  countFootballLiveMatches != 0 ? countFootballLiveMatches : ""
                }}</span
              >
            </div>
            <span class="name ballName" data-v-736e8afd="">
              {{ $t("common.menu.football") }}</span
            >
          </a>
        </li>
        <li
          :class="activeButton == 'basketball' ? 'activeBall' : ''"
          class="flex justify-center align-center flex-1"
          @click="changeActive('basketball', '/en/live/basketball')"
        >
          <a
            style="color: var(--main-theme-font-color)"
            :href="'/' + this.$i18n.locale + '/live/basketball'"
            target="_self"
            data-v-736e8afd=""
          >
            <div class="iconBox" data-v-736e8afd="">
              <i
                class="iconfont icon-lanqiu-weixuanzhong"
                data-v-736e8afd=""
              ></i>
              <span data-v-736e8afd="" class="liveNum">
                {{
                  countBasketballLiveMatches != 0
                    ? countBasketballLiveMatches
                    : ""
                }}</span
              >
            </div>
            <span class="name ballName" data-v-736e8afd="">{{
              $t("common.menu.basketball")
            }}</span>
          </a>
        </li>

        <li
          class="flex justify-center align-center flex-1"
          data-v-736e8afd=""
          @click="changeActive('other', getSportLink(selectedSport))"
          :class="activeButton == 'other' ? 'activeBall' : ''"
        >
          <a
            target="_self"
            data-v-736e8afd=""
            style="color: var(--main-theme-font-color)"
          >
            <div class="iconBox" data-v-736e8afd="">
              <i
                :class="getIconClass(selectedSport)"
                class="iconfont"
                data-v-736e8afd=""
              ></i>
              <span data-v-736e8afd="" class="liveNum">
                {{
                  getSportsLiveMatches(selectedSport) != 0
                    ? getSportsLiveMatches(selectedSport)
                    : ""
                }}</span
              >
            </div>
            <span class="name ballName" data-v-736e8afd=""
              >{{ selectedSport }}
            </span>
          </a>
        </li>

        <!-- othersBox -->
        <li
          class="flex justify-center align-center"
          data-v-736e8afd=""
          :class="displayOthers ? 'activeBall' : ''"
          @click="toggleDisplayOthers"
        >
          <a
            target="_self"
            data-v-736e8afd=""
            style="color: var(--main-theme-font-color)"
          >
            <div class="iconBox" data-v-736e8afd="">
              <i class="iconfont icon-xiala" data-v-736e8afd=""></i>
            </div>
            <span class="name" data-v-736e8afd="">{{
              $t("football.league-details.others")
            }}</span>
          </a>
        </li>
      </ul>

      <ul
        class="ballList"
        :style="{
          display: !displayOthers ? 'none' : 'block',
          bottom: '1.17rem',
        }"
        data-v-736e8afd=""
      >
        <li
          data-v-736e8afd=""
          class=""
          v-for="sport in sports"
          :key="sport"
          @click="selectSport(sport)"
        >
          <a
            :href="getSportLink(sport)"
            data-v-736e8afd=""
            target="_self"
            class="flex align-center"
            ><i
              data-v-736e8afd=""
              class="iconfont"
              :class="getIconClass(sport)"
            ></i>
            <span data-v-736e8afd="" class="name"> {{ sport }}</span>
            <span
              data-v-736e8afd=""
              class="liveNum"
              v-if="getSportsLiveMatches(sport) > 0"
            >
              {{ getSportsLiveMatches(sport) }}
            </span></a
          >
        </li>
      </ul>

      <SettingsDialogMobile
        v-if="settingsDialogStatus"
        v-on:closeDialog="toggleSettingsDialog"
      />
    </div>
  </div>
  <!-- end::mobile version menu bar -->
</template>
<script>
import TopHeader from "./header-section.vue";
import SettingsDialog from "./settings-dialog.vue";
import SettingsDialogMobile from "./settings-dialog-mobile.vue";
import SigninDialogDesktop from "./signin-dialog-desktop.vue";
import SearchBox from "./search-box.vue";
export default {
  name: "MenuBar",

  components: {
    TopHeader,
    SettingsDialog,
    SigninDialogDesktop,
    SettingsDialogMobile,
    SearchBox,
  },
  data() {
    return {
      userInfo: null,
      countFootballLiveMatches: null,
      countBasketballLiveMatches: null,
      countCricketLiveMatches: null,
      countBaseballLiveMatches: null,
      countTennisLiveMatches: null,
      countTableTennisLiveMatches: null,
      countHockeyLiveMatches: null,
      searchBoxStatus: false,
      activeButton: null,
      activeUrl: null,
      activeUrlMobile: null,
      settingsDialogStatus: false,
      signinDialogStatus: false,
      displayOthers: false,
      sports: [
        this.$t("common.menu.baseball"),
        this.$t("common.menu.hockey"),
        this.$t("common.menu.tennis"),
        this.$t("common.menu.volleyball"),
        this.$t("common.menu.table_tennis"),
        this.$t("common.menu.badminton"),
      ], // Add more sports as needed
      selectedSport:
        localStorage.getItem("selectedSport") || this.$t("common.menu.tennis"), // Retrieve selected sport from local storage
      desktopSelectedSport:
        localStorage.getItem("desktopSelectedSport") || "hockey", // Retrieve selected sport from local storage
    };
  },
  created() {
    this.getLiveEvents();

    setInterval(() => {
      this.getLiveEvents();
    }, 60000);
  },
  beforeMount() {
    const storedActiveUrl = localStorage.getItem("activeUrl");
    this.activeButton = this.setActiveButtonBasedOnActiveUrl(storedActiveUrl);
    this.activeUrl = storedActiveUrl;

    this.userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
  },
  mounted() {},
  computed: {
    liveMatchesCountHolder: function () {
      return {
        countFootballLiveMatches: this.countFootballLiveMatches,
        countBasketballLiveMatches: this.countBasketballLiveMatches,
        countCricketLiveMatches: this.countCricketLiveMatches,
        countBaseballLiveMatches: this.countBaseballLiveMatches,
        countTennisLiveMatches: this.countTennisLiveMatches,
        countTableTennisLiveMatches: this.countTableTennisLiveMatches,
        countBadmintonLiveMatches: this.countBadmintonLiveMatches,
        countHockeyLiveMatches: this.countHockeyLiveMatches,
      };
    },
  },

  methods: {
    toggleSearchBox() {
      this.emitter.emit("searchText", { text: "" });
      this.searchBoxStatus = !this.searchBoxStatus;
    },
    signOut() {
      this.userInfo = null;
    },
    toggleSettingsDialog() {
      this.settingsDialogStatus = !this.settingsDialogStatus;
    },
    toggleSinginDialog(userInfo) {
      this.userInfo = userInfo;
      this.signinDialogStatus = !this.signinDialogStatus;
    },

    getSportLink(sport) {
      // Define the href links based on the selected sport
      switch (sport) {
        case this.$t("common.menu.baseball"):
          return "/" + this.$i18n.locale + "/live/baseball";
        case this.$t("common.menu.tennis"):
          return "/" + this.$i18n.locale + "/live/tennis";
        case this.$t("common.menu.hockey"):
          return "/" + this.$i18n.locale + "/live/hockey";
        case this.$t("common.menu.volleyball"):
          return "/" + this.$i18n.locale + "/live/volleyball";
        case this.$t("common.menu.table_tennis"):
          return "/" + this.$i18n.locale + "/live/table_tennis";
        case this.$t("common.menu.badminton"):
          return "/" + this.$i18n.locale + "/live/badminton";
      }
    },
    getSportsLiveMatches(sport) {
      // get the Live Matches of each Sports
      switch (sport) {
        case this.$t("common.menu.baseball"):
          return this.countBaseballLiveMatches;
        case this.$t("common.menu.tennis"):
          return this.countTennisLiveMatches;
        case this.$t("common.menu.volleyball"):
          return this.countVolleyballLiveMatches;
        case this.$t("common.menu.table_tennis"):
          return this.countTableTennisLiveMatches;
        case this.$t("common.menu.hockey"):
          return this.countHockeyLiveMatches;
        case this.$t("common.menu.badminton"):
          return this.countBadmintonLiveMatches;
      }
    },
    getIconClass(sport) {
      // Define the icon classes based on the selected sport
      return {
        "icon-bingqiu-weixuanzhong": sport === this.$t("common.menu.hockey"),
        "icon-bangqiu-weixuanzhong": sport === this.$t("common.menu.baseball"),
        "icon-a-wangqiuweixuanzhong": sport === this.$t("common.menu.tennis"),
        "icon-paiqiu-weixuanzhong": sport === this.$t("common.menu.volleyball"),
        "icon-pingpangqiu-yixuanzhong":
          sport === this.$t("common.menu.table_tennis"),
        "icon-yumaoqiu-yixuanzhong": sport === this.$t("common.menu.badminton"),
        // Add more conditions for other sports as needed
      };
    },
    selectSport(sport) {
      this.selectedSport = sport;
      localStorage.setItem("selectedSport", sport);
    },
    desktopSelectSport(sport, url) {
      // console.log("desktopSelectSport" + sport);
      this.desktopSelectedSport = sport;
      localStorage.setItem("desktopSelectedSport", sport);
      this.changeActive(sport, url);
    },
    changeActive(sport, url) {
      // console.log("changeActive", url);
      if (this.activeButton !== sport) {
        this.activeButton = sport;
        this.activeUrl = url;
        // Save the activeButton and its URL to localStorage
        localStorage.setItem("activeButton", sport);
        localStorage.setItem("activeUrl", url);
        // You may want to navigate to the stored URL as well
        this.$router.push({ path: url });
      }
    },
    setActiveButtonBasedOnActiveUrl(activeUrl) {
      // console.log("setactive", activeUrl);

      let activeButton = "";
      if (activeUrl.includes("/football")) {
        activeButton = "football";
      } else if (activeUrl.includes("/basketball")) {
        activeButton = "basketball";
      } else if (activeUrl.includes("/table_tennis")) {
        activeButton = "table_tennis";
      } else if (activeUrl.includes("/tennis")) {
        activeButton = "tennis";
      } else if (activeUrl.includes("/cricket")) {
        activeButton = "cricket";
      } else if (activeUrl.includes("/baseball")) {
        activeButton = "baseball";
      } else if (activeUrl.includes("/volleyball")) {
        activeButton = "volleyball";
      } else if (activeUrl.includes("/hockey")) {
        activeButton = "hockey";
      } else if (activeUrl.includes("/badminton")) {
        activeButton = "badminton";
      } else {
        activeButton = ""; // Default to football if no match is found
      }

      // console.log("return active button", activeButton);
      return activeButton;
    },
    toggleDisplayOthers() {
      this.displayOthers = !this.displayOthers;
    },
    convertToLower(str) {
      return str.toLowerCase();
    },

    getLiveEvents() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/events/live`)
        .then((response) => {
          this.countFootballLiveMatches = response.data.footballLiveEvents;
          this.countBasketballLiveMatches = response.data.basketballLiveEvents;
          this.countBadmintonLiveMatches = response.data.badmintonLiveEvents;
          this.countBaseballLiveMatches = response.data.baseballLiveEvents;
          this.countCricketLiveMatches = response.data.cricketLiveEvents;
          this.countHockeyLiveMatches = response.data.hockeyLiveEvents;
          this.countTableTennisLiveMatches =
            response.data.tableTennisLiveEvents;
          this.countTennisLiveMatches = response.data.tennisLiveEvents;
          this.countVolleyballLiveMatches = response.data.volleyballLiveEvents;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
