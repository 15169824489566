<template>
  <span data-v-4a160e70="" v-if="!loading">
    <!-- begin::bet365 -->
    <div
      class="flex justify-between flex-col live"
      data-v-3bb54c1c=""
      data-v-4a160e70=""
      v-if="
        liveMatchDetails &&
        liveMatchDetails.matchDetails &&
        liveMatchDetails.matchDetails.matchOdds &&
        liveMatchDetails.matchDetails.matchOdds.bet365
      "
    >
      <div class="flex odds" data-v-3bb54c1c="">
        <div class="table flex-1 oddType" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <div class="oddContent" data-v-3bb54c1c="">
              <img
                data-v-3bb54c1c=""
                src="/images/odds/bet365.png"
                alt="#"
                class="oddType"
              />
            </div>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.opening-odds") }}
            </span>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.pre-match-odds") }}
            </span>
          </div>
        </div>
        <!-- begin::bigSmall -->
        <div class="table flex-1 eu" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            {{ $t("football.league-details.to-win") }}
            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::bigSmall opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][1]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][1].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall opening odds -->
          <!-- begin::bigSmall pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ][1]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                          liveMatchDetails.matchDetails.matchOdds.bet365.euro
                            .length - 1
                        ][1].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                      liveMatchDetails.matchDetails.matchOdds.bet365.euro
                        .length - 1
                    ][3]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                          liveMatchDetails.matchDetails.matchOdds.bet365.euro
                            .length - 1
                        ][3].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall pre-match odds -->
        </div>
        <!-- end::bigSmall -->
        <!-- begin::Asian Handicaps -->
        <div class="table flex-1 asia" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{
                $t("football.league-details.handicap") +
                " - " +
                $t("football.league-details.runs")
              }}
            </span>

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::Asian Opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #f6fcff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0][2] <
                      0
                      ? "+" +
                        processNumber(
                          -liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0] &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] > 0
                      ? "-" +
                        processNumber(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0] &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] == 0
                      ? "0"
                      : "-"
                  }}
                </span>

                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[0][1].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0][2] <
                      0
                      ? "-" +
                        processNumber(
                          -liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0] &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] > 0
                      ? "+" +
                        processNumber(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .asia[0][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0] &&
                        liveMatchDetails.matchDetails.matchOdds.bet365
                          .asia[0][2] == 0
                      ? "0"
                      : "-"
                  }}
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[0]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[0][3].toFixed(
                          2
                        )
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::Asian Opening odds -->
          <!-- begin::Asian pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 252, 249);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ][2] < 0
                      ? "+" +
                        processNumber(
                          -liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][2] > 0
                      ? "-" +
                        processNumber(
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][2] == 0
                      ? "0"
                      : "-"
                  }}
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][1].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ][2] < 0
                      ? "-" +
                        processNumber(
                          -liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][2] > 0
                      ? "+" +
                        processNumber(
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia
                              .length - 1
                          ][2]
                        )
                      : liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                        liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][2] == 0
                      ? "0"
                      : "-"
                  }}
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                      liveMatchDetails.matchDetails.matchOdds.bet365.asia
                        .length - 1
                    ]
                      ? liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                          liveMatchDetails.matchDetails.matchOdds.bet365.asia
                            .length - 1
                        ][3].toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::Asian pre-match odds -->
        </div>
        <!-- end::Asian Handicap -->
        <!-- begin::over under -->
        <div class="table flex-1 bs" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.total-points") }}</span
            >
          </div>
          <!-- begin::over-under opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="handicap">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[0][2]
                        ).toFixed(1)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[0][1]
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[0][3]
                        ).toFixed(1)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- end::over-under opening odds -->
          <!-- begin::over-under pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="handicap">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][2]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall.length - 1
                          ][2]
                        ).toFixed(1)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][1]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall.length - 1
                          ][1]
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="">
                  {{
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ] &&
                    liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                      liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall
                        .length - 1
                    ][3]
                      ? parseFloat(
                          liveMatchDetails.matchDetails.matchOdds.bet365
                            .bigSmall[
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall.length - 1
                          ][3]
                        ).toFixed(2)
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!-- begin::over-under pre-match odds -->
        </div>
        <!-- end::over under -->
      </div>
    </div>
    <!-- else part -->
    <div
      class="flex justify-between flex-col live"
      data-v-3bb54c1c=""
      data-v-4a160e70=""
      v-else
    >
      <div class="flex odds" data-v-3bb54c1c="">
        <div class="table flex-1 oddType" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <div class="oddContent" data-v-3bb54c1c="">
              <img
                data-v-3bb54c1c=""
                src="/images/odds/bet365.png"
                alt="#"
                class="oddType"
              />
            </div>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.opening-odds") }}
            </span>
          </div>
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <span class="flex align-center" data-v-3dbaeb93="">
              {{ $t("football.league-details.pre-match-odds") }}
            </span>
          </div>
        </div>
        <!-- begin::bigSmall -->
        <div class="table flex-1 eu" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">{{
              $t("football.league-details.to-win")
            }}</span>

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::bigSmall opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall opening odds -->
          <!-- begin::bigSmall pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>

              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::bigSmall pre-match odds -->
        </div>
        <!-- end::bigSmall -->
        <!-- begin::Asian Handicaps -->
        <div class="table flex-1 asia" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.spread") }}</span
            >

            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::Asian Opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #f6fcff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>

                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
            </div>
          </div>
          <!-- end::Asian Opening odds -->
          <!-- begin::Asian pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 252, 249);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="flex-1 handicap leftText">
                  -
                </span>
                <span data-v-3dbaeb93="" class="flex-1 rightText"> - </span>
              </div>
            </div>
          </div>
          <!-- end::Asian pre-match odds -->
        </div>
        <!-- end::Asian Handicap -->
        <!-- begin::over under -->
        <div class="table flex-1 bs" data-v-3bb54c1c="">
          <div class="head" data-v-3bb54c1c="">
            <span data-v-3bb54c1c="">
              {{ $t("football.league-details.total-points") }}</span
            >
            <div class="oddContent" data-v-3bb54c1c=""></div>
          </div>
          <!-- begin::over-under opening odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: 1px solid #eeeeee;
              border-left-color: #2196f3;
              background-color: #edf7ff;
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="handicap"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- end::over-under opening odds -->
          <!-- begin::over-under pre-match odds -->
          <div
            class="row box"
            style="
              position: relative;
              border-bottom: none;
              border-left-color: rgb(255, 186, 90);
              background-color: rgb(255, 249, 242);
            "
            data-v-3dbaeb93=""
            data-v-3bb54c1c=""
          >
            <div class="flex w100" data-v-3dbaeb93="">
              <div class="col color-999 cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class="handicap"> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
              <div class="col cur-pointer" data-v-3dbaeb93="">
                <span data-v-3dbaeb93="" class=""> - </span>
              </div>
            </div>
          </div>
          <!-- begin::over-under pre-match odds -->
        </div>
        <!-- end::over under -->
      </div>
    </div>
    <!-- end::else part -->
    <!--end::bet365 -->

    <div
      class="basketballLive border-box"
      v-if="
        liveMatchDetails.matchDetails &&
        liveMatchDetails.matchDetails.status_id != match_status.NOT_STARTED
      "
    >
      <div class="left-box" style="margin-right: 0px">
        <div class="scoresDetails detailLive" data-v-10a474e8="">
          <div class="linetop" data-v-10a474e8="">
            <div class="title" data-v-878ec15a="">
              <div class="firstBoxContent" data-v-878ec15a=""></div>
              <div
                v-for="i in 5"
                :key="i"
                :class="{
                  active:
                    getSetNumberFromMatchStatusId(
                      liveMatchDetails.matchDetails.status_id
                    ) == i,
                }"
                class="flex-1"
                data-v-878ec15a=""
              >
                {{ i }}
              </div>

              <div class="flex-1" data-v-878ec15a=""></div>
            </div>
            <div
              v-for="(teamString, teamIndex) in teamsIndex"
              :key="teamIndex"
              class="content"
              data-v-10a474e8=""
            >
              <div class="nameOver not-allow" data-v-10a474e8="">
                <img
                  :src="
                    liveMatchDetails.matchDetails &&
                    liveMatchDetails.matchDetails[`${teamString}Info`] &&
                    liveMatchDetails.matchDetails[`${teamString}Info`].logo
                      ? liveMatchDetails.matchDetails[`${teamString}Info`]
                          .logo + '!w60'
                    : bannerImagePath('default-team.png')
                  "
                  alt="#"
                  class="elseBallTeamLogo"
                  data-v-10a474e8=""
                />
                <span class="elseBallTeamName bh" data-v-10a474e8="">{{
                  this.$i18n.locale == "cn" &&
                  liveMatchDetails.matchDetails &&
                  liveMatchDetails.matchDetails[`${teamString}Info`] &&
                  liveMatchDetails.matchDetails[`${teamString}Info`].cn_name
                    ? liveMatchDetails.matchDetails[`${teamString}Info`].cn_name
                    : liveMatchDetails.matchDetails[`${teamString}Info`].en_name
                }}</span>
              </div>
              <div
                v-for="index in numberOfSetsSoFar"
                :key="index"
                class="flex-1"
                style="display: "
                data-v-10a474e8=""
                :class="{
                  insideLoserColor:
                    liveMatchDetails.matchDetails.scores &&
                    liveMatchDetails.matchDetails.scores[`p${index}`] &&
                    getSetNumberFromMatchStatusId(
                      liveMatchDetails.matchDetails.status_id
                    ) != index &&
                    teamIndex == 0
                      ? liveMatchDetails.matchDetails.scores[`p${index}`][
                          teamIndex
                        ] <
                        liveMatchDetails.matchDetails.scores[`p${index}`][
                          teamIndex + 1
                        ]
                      : liveMatchDetails.matchDetails.scores[`p${index}`]
                      ? liveMatchDetails.matchDetails.scores[`p${index}`][
                          teamIndex
                        ] <
                        liveMatchDetails.matchDetails.scores[`p${index}`][
                          teamIndex - 1
                        ]
                      : false,
                  active:
                    getSetNumberFromMatchStatusId(
                      liveMatchDetails.matchDetails.status_id
                    ) == index,
                }"
              >
              <!-- v-if="
                    index <=
                      getSetNumberFromMatchStatusId(
                        liveMatchDetails.matchDetails.status_id
                      ) ||
                    index <=
                      getPauseNumberFromMatchStatusId(
                        liveMatchDetails.matchDetails.status_id
                      )
                  " -->
                <span
                  data-v-10a474e8=""
                  class=""
                  >{{
                    liveMatchDetails.matchDetails.scores &&
                    liveMatchDetails.matchDetails.scores["p" + index] &&
                    liveMatchDetails.matchDetails.scores["p" + index][teamIndex]
                      ? liveMatchDetails.matchDetails.scores["p" + index][
                          teamIndex
                        ]
                      : "-"
                  }}
                </span>
              </div>

              <div
                class="flex-1 active"
                style="font-size: 13px; font-weight: bolder"
                data-v-10a474e8=""
              >
                <span data-v-10a474e8="" class="">
                  {{
                    liveMatchDetails.matchDetails.scores &&
                    liveMatchDetails.matchDetails.scores["ft"] &&
                    liveMatchDetails.matchDetails.scores["ft"][teamIndex]
                      ? liveMatchDetails.matchDetails.scores["ft"][teamIndex]
                      : "-"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],

  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
      liveMatches: [],
      teamsIndex: ["home", "away"],
      sets_match_status: [51, 52, 53, 54, 55, 56, 57],
      pauses_match_status: [331, 332, 333, 334, 333, 336, 337],
      match_status: {
        NOT_STARTED: 1,
        FIRST_SET: 51,
        SECOND_SET: 52,
        THIRD_SET: 53,
        FOURTH_SET: 54,
        FIFTH_SET: 55,
        SIXTH_SET: 56,
        SEVENTH_SET: 57,
      },
    };
  },
  computed: {
    numberOfSetsSoFar: function () {
      let numberOfSetsSoFar = Object.entries(
        this.liveMatchDetails.matchDetails.scores
      ).reduce((acc, cur) => (cur[0].startsWith("p") ? acc + 1 : acc), 0);
      return this.liveMatchDetails &&
        this.liveMatchDetails.matchDetails &&
        this.liveMatchDetails.matchDetails.scores &&
        numberOfSetsSoFar > 5
        ? numberOfSetsSoFar
        : 5;
    },
  },
  created() {
    this.getliveMatchDetails(this.matchId);
  },
  methods: {
    getSetNumberFromMatchStatusId(status_id) {
      let setNumber = this.sets_match_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
    getPauseNumberFromMatchStatusId(status_id) {
      let setNumber = this.pauses_match_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
    // method to get today matchlist
    getliveMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/table_tennis/match/details/` + matchId
        )
        .then((response) => {
          this.liveMatchDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to check decimal value
    processNumber(value) {
      if (value == 0) {
        return value;
      }

      const stringValue = value.toString();
      const decimalIndex = stringValue.indexOf(".");

      if (decimalIndex !== -1 && decimalIndex < stringValue.length - 2) {
        const decimalPart = stringValue.slice(
          decimalIndex + 1,
          decimalIndex + 3
        );

        if (decimalPart === "25") {
          return `${Math.floor(value)}/${Math.floor(value) + 0.5}`;
        } else if (decimalPart === "75") {
          return `${Math.floor(value) + 0.5}/${Math.floor(value)}`;
        }
      }

      if (stringValue.endsWith(".5")) {
        return stringValue;
      }

      // Default return when none of the conditions are met
      return stringValue;
    },
  },
};
</script>
