<template>
  <div class="container">
    <!-- Conditionally Render Top Row -->
    <div
      v-if="showTopRow"
      class="top-row"
      style="background-color: var(--main-theme-fav-color)"
    >
      <!-- Image and Title Column -->
      <div style="padding: 10px; color: white">
        <!-- Image -->
        Top Stories
      </div>
    </div>
    <br />
    <!-- First Row -->
    <div v-if="showTopRow" class="top-row">
      <!-- First News (1st Column) -->
      <div class="top-col top-image-title-col">
        <a :href="getNewsLink(latestNews.id)">
          <!-- Image with Overlay -->
          <div class="image-container">
            <img
              style="height: 250px !important"
              :src="latestNews.imgSrc"
              :alt="'Latest Image'"
              class="top-image"
            />
            <div class="overlay">
              <i class="iconfont icon-readmore"></i>
              <span class="overlay-text">Read More</span>
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- First Title -->
          <div style="margin-top: 10px !important">
            <a
              :href="getNewsLink(latestNews.id)"
              class="first-news-link first-title"
            >
              {{ newsTitles[0] }}.</a
            >
          </div>
        </a>
      </div>
      <!-- Second News (2nd Column) -->
      <div class="top-col top-image-title-col">
        <!-- Image with Overlay -->
        <a :href="getNewsLink(secondNews.id)">
          <div class="image-container">
            <img
              style="height: 250px !important"
              :src="secondNews.imgSrc"
              :alt="'Second Image'"
              class="top-image"
            />
            <div class="overlay">
              <i class="iconfont icon-readmore"></i>
              <span class="overlay-text">Read More</span>
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- Second Title -->
          <div style="margin-top: 10px !important">
            <a
              :href="getNewsLink(secondNews.id)"
              class="first-news-link first-title"
            >
              {{ newsTitles[1] }}.</a
            >
          </div>
        </a>
      </div>
    </div>
    <br />
    <!-- Second Row -->
    <div v-if="showTopRow" class="top-row">
      <!-- Third News (1st Column) -->
      <div class="top-col top-image-title-col">
        <a :href="getNewsLink(thirdNews.id)">
          <!-- Image with Overlay -->
          <div class="image-container">
            <img
              style="height: 250px !important"
              :src="thirdNews.imgSrc"
              :alt="'Third Image'"
              class="top-image"
            />
            <div class="overlay">
              <i class="iconfont icon-readmore"></i>
              <span class="overlay-text">Read More</span>
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- Third Title -->
          <div class="first-title" style="margin-top: 10px !important">
            <a
              :href="getNewsLink(thirdNews.id)"
              class="first-news-link first-title"
            >
              {{ newsTitles[2] }}.</a
            >
          </div>
        </a>
      </div>
      <!-- Fourth News (2nd Column) -->
      <div class="top-col top-image-title-col">
        <a :href="getNewsLink(fourthNews.id)">
          <!-- Image with Overlay -->
          <div class="image-container">
            <img
              style="height: 250px !important"
              :src="fourthNews.imgSrc"
              :alt="'Fourth Image'"
              class="top-image"
            />
            <div class="overlay">
              <i class="iconfont icon-readmore"></i>
              <span class="overlay-text">Read More</span>
              <i class="iconfont icon-arrow-right"></i>
            </div>
          </div>
          <!-- Fourth Title -->
          <div style="margin-top: 10px !important">
            <a
              :href="getNewsLink(fourthNews.id)"
              class="first-news-link first-title"
            >
              {{ newsTitles[3] }}.</a
            >
          </div>
        </a>
      </div>
    </div>
    <br />
    <!-- Third Row -->
    <div v-if="showTopRow" class="third-row">
      <!-- List of Remaining Titles -->
      <ul class="news-list">
        <li
          v-for="(title, index) in remainingNewsTitles"
          :key="index"
          class="news-item"
        >
          <a
            :href="getNewsLink(remainingNewsIds[index])"
            class="news-link only-title"
            style="color: black"
          >
            <span
              class="iconfont icon-lujingbeifen fs-12 color-999"
              style="transform: scale(0.8); margin-right: 5px"
            ></span>
            {{ title }}.
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomCSSComponent",
  data() {
    return {
      latestNews: {},
      secondNews: {},
      thirdNews: {},
      fourthNews: {},
      newsTitles: [], // Array to hold news titles for first page
      newsIds: [], // Array to hold news IDs for links for first page
      newsTitlesPage2: [], // Array to hold news titles for second page
      newsIdsPage2: [], // Array to hold news IDs for links for second page
      remainingNewsTitles: [], // Array to hold remaining news titles
      remainingNewsIds: [], // Array to hold remaining news IDs
      showTopRow: false, // Flag to determine if the top row should be shown
    };
  },
  created() {
    this.getNewsList();
    this.getNewsListPage2();
  },
  methods: {
    getNewsList() {
      this.$axios
        .get(`https://sportyclub.online/api/cricket/news/list?pageNumber=1`)
        .then((response) => {
          if (response.status === 200) {
            const newsList = response.data.cricketNewsList
              .slice(0, 10) // Get up to 10 news items
              .map((news) => ({
                id: news.id,
                imgSrc: `https://sportyclub.online${news.image}`, // Use the original image URL
                title: news.title,
              }));
            this.latestNews = newsList[0];
            this.secondNews = newsList[1];
            this.thirdNews = newsList[2];
            this.fourthNews = newsList[3];
            this.newsTitles = newsList.map((item) => item.title); // Include all titles
            this.newsIds = newsList.map((item) => item.id); // Include all IDs for links

            this.remainingNewsTitles = newsList
              .slice(4)
              .map((item) => item.title); // Remaining titles from page 1
            this.remainingNewsIds = newsList.slice(4).map((item) => item.id); // Remaining IDs from page 1

            this.showTopRow = true; // Set flag to show the top row
          } else {
            this.showTopRow = false; // Hide the top row if response is not successful
          }
        })
        .catch((error) => {
          console.log(error);
          this.showTopRow = false; // Hide the top row if there's an error
        });
    },
    getNewsListPage2() {
      this.$axios
        .get(`https://sportyclub.online/api/cricket/news/list?pageNumber=2`)
        .then((response) => {
          if (response.status === 200) {
            const newsListPage2 = response.data.cricketNewsList
              .slice(0, 10) // Get up to 10 news items
              .map((news) => ({
                id: news.id,
                title: news.title,
                imgSrc: `https://sportyclub.online${news.image}`, // Use the original image URL
              }));
            this.newsTitlesPage2 = newsListPage2.map((item) => item.title); // Include all titles
            this.newsIdsPage2 = newsListPage2.map((item) => item.id); // Include all IDs for links

            this.remainingNewsTitles = [
              ...this.remainingNewsTitles,
              ...this.newsTitlesPage2,
            ]; // Combine titles from page 1 and page 2
            this.remainingNewsIds = [
              ...this.remainingNewsIds,
              ...this.newsIdsPage2,
            ]; // Combine IDs from page 1 and page 2
          } else {
            console.log("Failed to fetch news list for page 2");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNewsLink(id) {
      const locale = this.$i18n.locale;
      return `/${locale}/cricket/news/details/${id}`;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.top-row {
  display: flex;
}

.third-row {
  display: flex;
  flex-direction: column; /* Change to column to stack items vertically */
}

.top-col {
  width: 50%; /* Adjust width for columns */
  padding: 10px;
}

.top-image-title-col {
  display: flex;
  flex-direction: column;
}

.top-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay-text {
  margin: 0 10px;
}

.news-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.news-item {
  margin: 5px 0;
}

.first-news-link {
  text-decoration: none;
}
</style>
