<template>
  <div class="content" data-v-59f3daaa="">
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="top: 10rem"
      data-v-59f3daaa=""
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-59f3daaa=""
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
      ></span>
    </div>
    <div
      v-else-if="noDataInAll"
      class="flex justify-center align-center flex-col"
      data-v-4291ef99=""
      style="height: 400px"
    >
      <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
      <div class="color-999 fs-12 mt-12">
        {{ $t("football.live-score.temporarily-no-data") }}
      </div>
    </div>
    <div v-else class="bk_stats w100" data-v-460a6726="" data-v-59f3daaa="">
      <div class="bk_statscomp" data-v-042072c0="" data-v-460a6726="">
        <div
          v-for="(propertyObject, index) in teamProperties"
          :key="index"
          class="w100 items"
          style=""
          data-v-042072c0=""
        >
          <div class="w100 text-center title" data-v-042072c0="">
            {{ $t("football.league-details." + propertyObject.name) }}
          </div>
          <template v-if="!leagueTeamsPropertiesStats[index].data">
            <div
              class="flex justify-center align-center flex-col"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </template>
          <template v-else-if="!propertyObject.viewAll">
            <div
              v-for="teamIndex in 3"
              :key="teamIndex"
              class="w100 flex align-center justify-between list_active2"
              data-v-042072c0=""
            >
              <div class="des" data-v-042072c0="">
                <div
                  class="ind"
                  :class="
                    teamIndex == 1
                      ? 'first'
                      : teamIndex == 2
                      ? 'second'
                      : 'third'
                  "
                  data-v-042072c0=""
                >
                  {{ teamIndex }}
                </div>
                <div
                  itemprop="logo"
                  class="myImg van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                  data-v-042072c0=""
                >
                  <img
                    :src="
                      leagueTeamsPropertiesStats[index].data.playersStats[
                        teamIndex - 1
                      ].player.logo
                    "
                    alt=""
                    class="van-image__img"
                    style="object-fit: cover"
                  />
                </div>
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/basketball/team/details/' +
                    leagueTeamsPropertiesStats[index].data.playersStats[
                      teamIndex - 1
                    ].player.name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    leagueTeamsPropertiesStats[index].data.playersStats[
                      teamIndex - 1
                    ].player.id
                  "
                  class="flex"
                  data-v-042072c0=""
                  ><div class="playerName" data-v-042072c0="">
                    {{
                      this.$i18n.locale == "cn" &&
                      leagueTeamsPropertiesStats[index].data.playersStats[
                        teamIndex - 1
                      ].player.cn_name
                        ? leagueTeamsPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ].player.cn_name
                        : leagueTeamsPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ].player.name
                    }}
                  </div>
                </a>
              </div>
              <div class="num" data-v-042072c0="">
                {{
                  leagueTeamsPropertiesStats[index].data.playersStats[
                    teamIndex - 1
                  ][propertyObject.property]
                }}
              </div>
            </div>

            <div class="w100 viewall" data-v-042072c0="">
              <div
                @click="toggleViewAll(index)"
                class="viewallBtn"
                data-v-042072c0=""
              >
                {{ $t("football.league-details.show-more") }} &gt;
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="teamIndex in 15"
              :key="teamIndex"
              class="w100 flex align-center justify-between list_active2"
              data-v-042072c0=""
            >
              <div class="des" data-v-042072c0="">
                <div
                  class="ind"
                  :class="
                    teamIndex == 1
                      ? 'first'
                      : teamIndex == 2
                      ? 'second'
                      : 'third'
                  "
                  data-v-042072c0=""
                >
                  {{ teamIndex }}
                </div>
                <div
                  itemprop="logo"
                  class="myImg van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                  data-v-042072c0=""
                >
                  <img
                    :src="
                      leagueTeamsPropertiesStats[index].data.playersStats[
                        teamIndex - 1
                      ].player.logo
                    "
                    alt=""
                    class="van-image__img"
                    style="object-fit: cover"
                  />
                </div>
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/basketball/team/details/' +
                    leagueTeamsPropertiesStats[index].data.playersStats[
                      teamIndex - 1
                    ].team.name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    leagueTeamsPropertiesStats[index].data.playersStats[
                      teamIndex - 1
                    ].team.id
                  "
                  class="flex"
                  data-v-042072c0=""
                  ><div class="playerName" data-v-042072c0="">
                    {{
                      this.$i18n.locale == "cn" &&
                      leagueTeamsPropertiesStats[index].data.playersStats[
                        teamIndex - 1
                      ].player.cn_name
                        ? leagueTeamsPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ].player.cn_name
                        : leagueTeamsPropertiesStats[index].data.playersStats[
                            teamIndex - 1
                          ].player.name
                    }}
                  </div>
                </a>
              </div>
              <div class="num" data-v-042072c0="">
                {{
                  leagueTeamsPropertiesStats[index].data.playersStats[
                    teamIndex - 1
                  ][propertyObject.property]
                }}
              </div>
            </div>
            <div class="w100 viewall" data-v-042072c0="">
              <div
                @click="toggleViewAll(index)"
                class="viewallBtn"
                data-v-042072c0=""
              >
                {{ $t("football.league-details.show-less") }} &lt;
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      noDataInAll: false,
      loading: true,
      leagueDetails: null,
      activeTab: "goals",
      teamsPropertyStats: null,
      showLess: true,
      leagueTeamsPropertiesStats: null,
      teamProperties: [
        { viewAll: false, property: "points", name: "total-points" },
        { viewAll: false, property: "assists", name: "assists" },
        { viewAll: false, property: "minutes_played", name: "minutes_played" },
        { viewAll: false, property: "rebounds", name: "rebounds" },
        { viewAll: false, property: "turnovers", name: "turnovers" },
        { viewAll: false, property: "steals", name: "steals" },
        { viewAll: false, property: "blocks", name: "blocks" },
        { viewAll: false, property: "personal_fouls", name: "fouls" },
        { viewAll: false, property: "scope", name: "scope" },
        { viewAll: false, property: "court", name: "court" },
        { viewAll: false, property: "first", name: "first" },
      ],
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    // this.getTeamStats(this.leagueId, this.activeTab);
    this.sendAllTeamStatsRequests(this.leagueId);
  },
  methods: {
    toggleViewAll(index) {
      this.teamProperties[index].viewAll = !this.teamProperties[index].viewAll;
    },
    sendAllTeamStatsRequests(leagueId) {
      let promisesArray = [];
      this.teamProperties.forEach((propertyObject) => {
        promisesArray.push(
          this.$axios.get(
            this.BASE_SERVER_URI +
              `/api/basketball/league/season/playersStats/${leagueId}?playerProperty=${propertyObject.property}`
          )
        );
      });
      Promise.all(promisesArray)
        .then((responses) => {
          this.leagueTeamsPropertiesStats = responses;
          this.noDataInAll =
            responses.filter((response) => response.data != "").length === 0;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
    getTeamStats(leagueId, property) {
      this.activeTab = property;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/league/season/playersStats/${leagueId}?teamProperty=${property}`
        )
        .then((response) => {
          this.teamsPropertyStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showAllRecords(state) {
      this.showLess = !state;
    },
  },
};
</script>
