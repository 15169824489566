<template>
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div class="content" style="opacity: 1">
    <div data-v-2115270e="" class="analyseContent">
      <div data-v-2115270e="" class="teamBtnBox">
        <span
          data-v-2115270e=""
          class="homeTeam"
          :class="activeTab == 'all' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('all')"
        >
          {{ $t("football.league-details.h2h") }} </span
        ><span
          data-v-2115270e=""
          class="awayTeam ml-8"
          :class="activeTab == 'home' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('home')"
        >
          {{ $t("football.league-details.home-team") }}</span
        ><span
          data-v-2115270e=""
          class="awayTeam ml-8"
          :class="activeTab == 'away' ? 'activeTeam' : ''"
          v-on:click="toggleTabs('away')"
        >
          {{ $t("football.league-details.away-team") }}
        </span>
      </div>
      <div data-v-2115270e="" class="flex">
        <div data-v-2115270e="" class="contentBox flex-1">
          <div data-v-2115270e="" class="allBox">
            <span
              v-if="
                todayMatchDetails &&
                todayMatchDetails.matchH2H &&
                todayMatchDetails.matchH2H[activeTab]
              "
            >
              <div
                data-v-2115270e=""
                class="matchContent"
                v-for="(home, key) in todayMatchDetails.matchH2H[activeTab]"
                :key="key"
              >
                <p data-v-2115270e="" class="header">
                  <span data-v-2115270e="" class="comp flexCenter"
                    ><img
                      data-v-2115270e=""
                      :src="
                        home[0] &&
                        home[0].leagueInfo &&
                        home[0].leagueInfo.countryDetails &&
                        home[0].leagueInfo.countryDetails.logo
                          ? home[0].leagueInfo.countryDetails.logo + '!w30'
                         : bannerImagePath('default-country.png')
                      "
                      alt="#"
                    />
                    <span data-v-2115270e="" class="country flexCenter">
                      {{
                        home[0] &&
                        home[0].leagueInfo &&
                        home[0].leagueInfo.countryDetails &&
                        this.$i18n.locale == "cn"
                          ? home[0].leagueInfo.countryDetails.cn_name
                          : home[0].leagueInfo.countryDetails.name
                      }}:</span
                    >
                    <span data-v-2115270e="" class="flexCenter">
                      {{
                        home[0] &&
                        home[0].leagueInfo &&
                        home[0].leagueInfo.countryDetails &&
                        this.$i18n.locale == "cn"
                          ? home[0].leagueInfo.cn_name
                          : home[0].leagueInfo.name
                      }}</span
                    ></span
                  >
                  <span data-v-2115270e="" class="score flexCenter">HT</span>
                  <span data-v-2115270e="" class="score flexCenter">FT</span>
                </p>
                <ul data-v-2115270e="" class="matchBox">
                  <span v-for="(match, index) in home" :key="index">
                    <li data-v-2115270e="">
                      <p
                        data-v-2115270e=""
                        class="collect flexCenter"
                        v-if="
                          match.matchStatus == 8 &&
                          match.homeInfo &&
                          match.awayInfo &&
                          match.homeInfo.score < match.awayInfo.score
                        "
                      >
                        <span data-v-2115270e="" class="round flexCenter loser">
                          L
                        </span>
                      </p>

                      <p
                        data-v-2115270e=""
                        class="collect flexCenter"
                        v-if="
                          match.matchStatus == 8 &&
                          match.homeInfo &&
                          match.awayInfo &&
                          match.homeInfo.score > match.awayInfo.score
                        "
                      >
                        <span data-v-2115270e="" class="round flexCenter loser">
                          W
                        </span>
                      </p>
                      <p
                        data-v-2115270e=""
                        class="collect flexCenter"
                        v-if="
                          match.matchStatus == 8 &&
                          match.homeInfo &&
                          match.awayInfo &&
                          match.homeInfo.score == match.awayInfo.score
                        "
                      >
                        <i class="iconfont icon-pingju" data-v-19df9d94=""></i>
                      </p>

                      <a data-v-2115270e="" target="_self" itemprop="url">
                        <p
                          data-v-2115270e=""
                          itemprop="startDate"
                          class="time flexCenter"
                        >
                          {{ match.matchTime }}
                        </p>
                        <p data-v-2115270e="" class="teamBox">
                          <span data-v-2115270e="" class="teamBoxItem"
                            ><span data-v-2115270e=""
                              ><img
                                data-v-2115270e=""
                                :src="
                                  match &&
                                  match.homeInfo.logo &&
                                  match.homeInfo.logo
                                    ? match.homeInfo.logo + '!w30'
                                   : bannerImagePath('default-country.png')
                                "
                            /></span>
                            <span
                              data-v-2115270e=""
                              itemprop="name"
                              class="teamName"
                              >{{
                                match &&
                                match.homeInfo &&
                                this.$i18n.locale == "cn"
                                  ? match.homeInfo.cn_name
                                  : match.homeInfo.name
                              }}</span
                            ></span
                          >
                          <span data-v-2115270e="" class="teamBoxItem"
                            ><span data-v-2115270e=""
                              ><img
                                data-v-2115270e=""
                                :src="
                                  match &&
                                  match.awayInfo.logo &&
                                  match.awayInfo.logo
                                    ? match.awayInfo.logo + '!w30'
                                   : bannerImagePath('default-country.png')
                                "
                                itemprop="logo"
                            /></span>
                            <span
                              data-v-2115270e=""
                              itemprop="name"
                              class="teamName"
                              >{{
                                match &&
                                match.awayInfo &&
                                this.$i18n.locale == "cn"
                                  ? match.awayInfo.cn_name
                                  : match.awayInfo.name
                              }}</span
                            ></span
                          >
                        </p>
                        <p data-v-2115270e="" class="scoreBox">
                          <span data-v-2115270e="" class="flexCenter">
                            {{
                              match &&
                              match.homeInfo &&
                              match.homeInfo.halfScore
                                ? match.homeInfo.halfScore
                                : "0"
                            }}</span
                          >
                          <span data-v-2115270e="" class="flexCenter">
                            {{
                              match &&
                              match.awayInfo &&
                              match.awayInfo.halfScore
                                ? match.awayInfo.halfScore
                                : "0"
                            }}
                          </span>
                        </p>
                        <p data-v-2115270e="" class="scoreBox">
                          <span data-v-2115270e="" class="flexCenter loserText">
                            {{
                              match && match.homeInfo && match.homeInfo.score
                                ? match.homeInfo.score
                                : "0"
                            }}
                          </span>
                          <span data-v-2115270e="" class="flexCenter winText">
                            {{
                              match && match.awayInfo && match.awayInfo.score
                                ? match.awayInfo.score
                                : "0"
                            }}
                          </span>
                        </p>
                      </a>
                    </li>
                  </span>
                </ul>
              </div>
            </span>
            <span v-else>
              <div class="scheduleBox" data-v-db02cb7a="">
                <div
                  class="flex justify-center align-center flex-col"
                  data-v-4291ef99=""
                  style="height: 400px"
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      activeTab: "all",
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTabs(state) {
      this.activeTab = state;
    },
  },
};
</script>
