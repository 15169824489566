<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div
    class="innerMatchInfo detail view border-box"
    data-v-cce4c3e6=""
    v-if="!loading"
  >
    <div aria-label="Breadcrumb" class="el-breadcrumb breadBox">
      <span class="el-breadcrumb__item"
        ><span role="link" class="el-breadcrumb__inner"
          ><a target="_blank" itemprop="item name" style="color: var(--main-text-color)">
            {{ $t("football.league-details.tennis-live-score") }}
          </a> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
      <span class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner">
          <a
            v-if="todayMatchDetails.hasLeagueDetails === 1"
            :href="
              '/' +
              this.$i18n.locale +
              '/tennis/league/details/' +
              todayMatchDetails.leagueInfo.en_name
                .toLowerCase()
                .replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.unique_tournament_id
            "
            target="_blank"
            style="color: var(--main-text-color)"
            class=""
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
          <a v-else target="_blank" style="color: var(--main-text-color)" class="">
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
      <span class="el-breadcrumb__item" aria-current="page"
        ><span role="link" class="el-breadcrumb__inner"
          ><h1 itemprop="name" class="fs-12 font-400">
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.homeInfo &&
              todayMatchDetails.homeInfo.cn_name
                ? todayMatchDetails.homeInfo.cn_name
                : todayMatchDetails.homeInfo.en_name
            }}
            vs
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.awayInfo &&
              todayMatchDetails.awayInfo.cn_name
                ? todayMatchDetails.awayInfo.cn_name
                : todayMatchDetails.awayInfo.en_name
            }}
            live
            {{ $t("football.league-details.live-score-prediction") }}
            <span v-if="todayMatchDetails && todayMatchDetails.match_time">
              {{ getFormattedBroadcrumbDate(todayMatchDetails.match_time) }}
            </span>
          </h1> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
    </div>
    <div class="matchTop">
      <div class="text-center info">
        <a
          :href="
            '/' +
            this.$i18n.locale +
            '/tennis/league/details/' +
            todayMatchDetails.leagueInfo.en_name
              .toLowerCase()
              .replace(/[\s/]+/g, '-') +
            '/' +
            todayMatchDetails.unique_tournament_id
          "
          target="_blank"
          class=""
        >
          {{
            this.$i18n.locale == "cn" &&
            todayMatchDetails &&
            todayMatchDetails.leagueInfo &&
            todayMatchDetails.leagueInfo.cn_name
              ? todayMatchDetails.leagueInfo.cn_name
              : todayMatchDetails.leagueInfo.en_name
          }}</a
        >

        <span class="cutOffLine">/</span>
        <span v-if="todayMatchDetails && todayMatchDetails.match_time">
          {{ getFormattedDate(todayMatchDetails.match_time) }}
        </span>
      </div>
      <div class="matchInfo">
        <div class="teamLeft">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/tennis/team/details/' +
              todayMatchDetails.homeInfo.en_name
                .toLowerCase()
                .replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            target=""
            class="teamName not-allow"
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.homeInfo &&
              todayMatchDetails.homeInfo.cn_name
                ? todayMatchDetails.homeInfo.cn_name
                : todayMatchDetails.homeInfo.en_name
            }}
          </a>
          <div class="flex" style="cursor: pointer">
            <a
              :href="
                '/' +
                this.$i18n.locale +
                '/tennis/team/details/' +
                todayMatchDetails.homeInfo.en_name
                  .toLowerCase()
                  .replace(/[\s/]+/g, '-') +
                '/' +
                todayMatchDetails.home_team_id
              "
              target="_blank"
              class="teamName"
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.logo
                    ? todayMatchDetails.homeInfo.logo + '!w60'
                    : bannerImagePath('default-player.png')
                "
                class="roundImg roundImg1 leftRoundImg"
              />
            </a>
          </div>

          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div
              class="score flex justify-center scoreRed"
              v-if="
                todayMatchDetails &&
                (liveMatches.includes(todayMatchDetails.status_id) ||
                  todayMatchDetails.status_id == 100)
              "
            ></div>
          </div>
        </div>
        <div class="matchScore scoreColorRed" style="position: relative">
          <span class="">
            {{
              todayMatchDetails && todayMatchDetails.statusDescription
                ? todayMatchDetails.statusDescription
                : "VS"
            }}
          </span>
          <span class="">
            {{
              todayMatchDetails.status_id == 1 &&
              todayMatchDetails.status_id == 100
                ? "vs"
                : ""
            }}
          </span>
        </div>
        <div class="teamRight">
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div
              class="score flex justify-center scoreRed"
              v-if="
                todayMatchDetails &&
                (liveMatches.includes(todayMatchDetails.status_id) ||
                  todayMatchDetails.status_id == 100) &&
                todayMatchDetails.awayInfo
              "
            ></div>
            <div class="detailtopIcon2" data-v-cce4c3e6=""></div>
          </div>

          <div class="flex" style="cursor: pointer">
            <a
              :href="
                '/' +
                this.$i18n.locale +
                '/tennis/team/details/' +
                todayMatchDetails.awayInfo.en_name
                  .toLowerCase()
                  .replace(/[\s/]+/g, '-') +
                '/' +
                todayMatchDetails.away_team_id
              "
              target="_blank"
              class="teamName"
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.logo
                    ? todayMatchDetails.awayInfo.logo + '!w60'
                    : bannerImagePath('default-player.png')
                "
                class="roundImg roundImg1"
              />
            </a>
          </div>
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/tennis/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            class="teamName not-allow"
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.awayInfo &&
              todayMatchDetails.awayInfo.cn_name
                ? todayMatchDetails.awayInfo.cn_name
                : todayMatchDetails.awayInfo.en_name
            }}
          </a>
        </div>
      </div>
    </div>
    <div class="tab-bar color-333 fs-14 flex align-center">
      <div class="tab" :class="status == 'overview' ? 'active' : ''">
        <a v-on:click="toggleTabs('overview')">
          {{ $t("football.league-details.overview") }}</a
        >
      </div>
    </div>

    <!-- begin::overview -->
    <div
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'overview' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOverviewTab :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::overview -->

    <div style="width: 1200px; margin: 0 auto" data-v-cce4c3e6="">
      <div class="detailLive" data-v-cce4c3e6="">
        <div class="basketballLive border-box">
          <div class="left-box">
            <div class="scoresDetails detailLive" data-v-4698dc36="">
              <div class="linetop" data-v-4698dc36="">
                <div class="title" data-v-4698dc36="">
                  <div class="firstBoxContent" data-v-4698dc36=""></div>
                  <div class="flex-1" data-v-4698dc36="">1</div>
                  <div class="flex-1 active" data-v-4698dc36="">2</div>
                  <div class="flex-1" data-v-4698dc36="">3</div>

                  <div class="flex-1" data-v-4698dc36="">4</div>
                  <div class="flex-1" data-v-4698dc36=""></div>
                </div>
                <div class="content" data-v-4698dc36="">
                  <div
                    class="nameOver elseDoubleTeamNameOver"
                    data-v-4698dc36=""
                  >
                    <span data-v-4698dc36="">
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/tennis/team/details/' +
                          todayMatchDetails.homeInfo.en_name
                            .toLowerCase()
                            .replace(/[\s/]+/g, '-') +
                          '/' +
                          todayMatchDetails.home_team_id
                        "
                      >
                        <img
                          :src="
                            todayMatchDetails &&
                            todayMatchDetails.homeInfo &&
                            todayMatchDetails.homeInfo.logo
                              ? todayMatchDetails.homeInfo.logo
                              : bannerImagePath('default-player.png')
                          "
                          alt="#"
                          class="elseBallTeamLogo"
                          data-v-67490073=""
                        />
                        <span v-if="this.$i18n.locale == 'cn'">
                          {{
                            todayMatchDetails &&
                            todayMatchDetails.homeInfo &&
                            todayMatchDetails.homeInfo.cn_name
                              ? todayMatchDetails.homeInfo.cn_name
                              : ""
                          }}
                        </span>
                        <span v-else>
                          {{
                            todayMatchDetails &&
                            todayMatchDetails.homeInfo &&
                            todayMatchDetails.homeInfo.en_name
                              ? todayMatchDetails.homeInfo.en_name
                              : ""
                          }}
                        </span>
                      </a>
                    </span>
                  </div>
                  <div class="flex-1" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        (todayMatchDetails.homeInfo.p1 ||
                          todayMatchDetails.homeInfo.p1 == 0)
                          ? todayMatchDetails.homeInfo.p1
                          : "-"
                      }}
                    </span>
                  </div>
                  <div class="flex-1 active" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        (todayMatchDetails.homeInfo.p2 ||
                          todayMatchDetails.homeInfo.p2 == 0)
                          ? todayMatchDetails.homeInfo.p2
                          : "-"
                      }}
                    </span>
                  </div>
                  <div class="flex-1 insideLoserColor" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        (todayMatchDetails.homeInfo.p3 ||
                          todayMatchDetails.homeInfo.p3 == 0)
                          ? todayMatchDetails.homeInfo.p3
                          : "-"
                      }}</span
                    >
                  </div>

                  <div
                    class="flex-1 active"
                    style="font-size: 13px; font-weight: bolder"
                    data-v-4698dc36=""
                  >
                    {{
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      (todayMatchDetails.homeInfo.p4 ||
                        todayMatchDetails.homeInfo.p4 == 0)
                        ? todayMatchDetails.homeInfo.p4
                        : "-"
                    }}
                  </div>
                  <div class="flex-1 greenColor" data-v-4698dc36="">
                    {{
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      (todayMatchDetails.homeInfo.totalScore ||
                        todayMatchDetails.homeInfo.totalScore == 0)
                        ? todayMatchDetails.homeInfo.totalScore
                        : "-"
                    }}
                  </div>
                </div>
                <div class="content" data-v-4698dc36="">
                  <div
                    class="nameOver elseDoubleTeamNameOver"
                    data-v-4698dc36=""
                  >
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/tennis/team/details/' +
                        todayMatchDetails.homeInfo.en_name
                          .toLowerCase()
                          .replace(/[\s/]+/g, '-') +
                        '/' +
                        todayMatchDetails.home_team_id
                      "
                    >
                      <img
                        :src="
                          todayMatchDetails &&
                          todayMatchDetails.awayInfo &&
                          todayMatchDetails.awayInfo.logo
                            ? todayMatchDetails.awayInfo.logo
                            : bannerImagePath('default-player.png')
                        "
                        alt="#"
                        class="elseBallTeamLogo"
                        data-v-67490073=""
                      />
                      <span v-if="this.$i18n.locale == 'cn'">
                        {{
                          todayMatchDetails &&
                          todayMatchDetails.awayInfo &&
                          todayMatchDetails.awayInfo.cn_name
                            ? todayMatchDetails.awayInfo.cn_name
                            : ""
                        }}
                      </span>
                      <span v-else>
                        {{
                          todayMatchDetails &&
                          todayMatchDetails.awayInfo &&
                          todayMatchDetails.awayInfo.en_name
                            ? todayMatchDetails.awayInfo.en_name
                            : ""
                        }}
                      </span>
                    </a>
                  </div>
                  <div class="flex-1 insideLoserColor" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        (todayMatchDetails.awayInfo.p1 ||
                          todayMatchDetails.awayInfo.p1 == 0)
                          ? todayMatchDetails.awayInfo.p1
                          : "-"
                      }}
                    </span>
                  </div>
                  <div class="flex-1 active" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        (todayMatchDetails.awayInfo.p2 ||
                          todayMatchDetails.awayInfo.p2 == 0)
                          ? todayMatchDetails.awayInfo.p2
                          : "-"
                      }}
                    </span>
                  </div>
                  <div class="flex-1 insideLoserColor" data-v-4698dc36="">
                    <span class="scoresBox" data-v-4698dc36="">
                      {{
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        (todayMatchDetails.awayInfo.p3 ||
                          todayMatchDetails.awayInfo.p3 == 0)
                          ? todayMatchDetails.awayInfo.p3
                          : "-"
                      }}
                    </span>
                  </div>

                  <div
                    class="flex-1 active"
                    style="font-size: 13px; font-weight: bolder"
                    data-v-4698dc36=""
                  >
                    {{
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      (todayMatchDetails.awayInfo.p4 ||
                        todayMatchDetails.awayInfo.p4 == 0)
                        ? todayMatchDetails.awayInfo.p4
                        : "-"
                    }}
                  </div>
                  <div class="flex-1 greenColor" data-v-4698dc36="">
                    {{
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      (todayMatchDetails.awayInfo.totalScore ||
                        todayMatchDetails.awayInfo.totalScore == 0)
                        ? todayMatchDetails.awayInfo.totalScore
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-box">
            <div class="matchInfoBox" data-v-6a3cfba8="">
              <div class="title flex align-center" data-v-6a3cfba8="">
                {{ $t("football.league-details.match-info") }}
              </div>
              <div class="contentBox" data-v-6a3cfba8="">
                <p class="title2" data-v-6a3cfba8="">
                  {{ $t("football.league-details.match-details") }}
                </p>
                <ul class="mt-8" data-v-6a3cfba8="">
                  <li data-v-6a3cfba8="" class="">
                    <span data-v-6a3cfba8=""
                      >{{ $t("football.league-details.league") }}:</span
                    >

                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/tennis/league/details/' +
                        todayMatchDetails.leagueInfo.en_name
                          .toLowerCase()
                          .replace(/[\s/]+/g, '-') +
                        todayMatchDetails.unique_tournament_id
                      "
                      target="_blank"
                      data-v-6a3cfba8=""
                    >
                      {{
                        this.$i18n.locale == "cn" &&
                        todayMatchDetails &&
                        todayMatchDetails.leagueInfo &&
                        todayMatchDetails.leagueInfo.en_name
                          ? todayMatchDetails.leagueInfo.cn_name
                          : todayMatchDetails.leagueInfo.en_name
                      }}
                    </a>
                  </li>
                  <li class="mt-4" data-v-6a3cfba8="">
                    <span data-v-6a3cfba8=""
                      >{{ $t("football.league-details.name") }}:</span
                    >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      todayMatchDetails.homeInfo.cn_name
                        ? todayMatchDetails.homeInfo.cn_name
                        : todayMatchDetails.homeInfo.en_name
                    }}
                    vs
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      todayMatchDetails.awayInfo.cn_name
                        ? todayMatchDetails.awayInfo.cn_name
                        : todayMatchDetails.awayInfo.en_name
                    }}
                  </li>
                  <li class="mt-4" data-v-6a3cfba8="">
                    <span data-v-6a3cfba8=""
                      >{{ $t("football.league-details.date") }}:</span
                    >
                    <span data-v-6a3cfba8="">
                      {{
                        getFormattedBroadcrumbDate(todayMatchDetails.match_time)
                      }}</span
                    >
                  </li>
                  <li class="mt-4" data-v-6a3cfba8="">
                    <span data-v-6a3cfba8="">
                      {{ $t("football.league-details.match-status") }}:</span
                    >
                    <span data-v-6a3cfba8="">
                      {{
                        todayMatchDetails && todayMatchDetails.statusDescription
                          ? todayMatchDetails.statusDescription
                          : ""
                      }}</span
                    >
                  </li>
                  <li class="mt-4" data-v-6a3cfba8="">
                    <span data-v-6a3cfba8=""
                      >{{ $t("football.league-details.stadium") }}:</span
                    >
                    <span data-v-6a3cfba8="">-</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LiveMatchOverviewTab from "./tabs/live-match-overview.vue";

import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    LiveMatchOverviewTab,

  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [51, 52, 53, 54, 55],
    };
  },


  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },

    // add section score
  },
};
</script>
