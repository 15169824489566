<template>
  <div
    class="content"
    data-v-06d4278f=""
    :style="{ 'min-height': loading ? '400px' : false }"
  >
    <!-- begin::loading -->
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      data-v-06d4278f=""
      style="top: 10rem"
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-06d4278f=""
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
      ></span>
    </div>
    <!-- end::loading -->

    <template v-if="!loading && this.matchStandings && this.matchSeasons">
      <div class="seasonFilter" data-v-55e099c6="" @click="toggleSeasonDialog">
        <div class="flex h100 align-center" data-v-55e099c6="">
          <div
            itemprop="logo"
            class="seasonCompImg van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-55e099c6=""
          ></div>
          <span class="filterText" data-v-55e099c6="">
            {{ seasonName }}
          </span>
        </div>
        <i class="seasonIcon van-icon van-icon-play" data-v-55e099c6=""></i>
      </div>
      <div
        class="w100 btn-group flex align-center"
        style="
          flex-wrap: wrap;
          background: rgb(241, 241, 241);
          border-bottom: 1px solid rgb(227, 227, 227, 0.5);
        "
        data-v-55e099c6=""
      >
        <a
          v-for="(league, key) in matchSeasons"
          :key="league"
          target="_self"
          class="standing-btn ml-xs mb-xs"
          :class="{ active: key === activeLeague }"
          data-v-55e099c6=""
          @click="activateLeague(key)"
          style="
            width: 3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
        >
          {{
            league && league.tournament && league.tournament.name
              ? league.tournament.name
              : ""
          }}
        </a>
      </div>

      <div
        data-v-8808ea54=""
        data-v-06d4278f=""
        class="basketball_standing"
        style=""
      >
        <div data-v-8808ea54="" style="width: 100%">
          <div
            data-v-8808ea54=""
            class="w100"
            v-for="standingDetail in matchStandings"
            :key="standingDetail.id"
          >
            <div data-v-8808ea54="" class="dataName">
              <span data-v-8808ea54="" class="name flex align-center"
                ><div
                  data-v-8808ea54=""
                  class="comp_logo van-image"
                  itemprop="logo"
                  style="overflow: hidden; border-radius: 0px"
                >
                  <img
                    v-if="leagueId"
                    :src="
                      'https://sportyclub.online/images/cricket/leagues/' +
                      leagueId +
                      '/' +
                      leagueId +
                      '.png'
                    "
                    @error="
                      $event.target.src = bannerImagePath('default-country.png')
                    "
                    class="van-image__img"
                    style="object-fit: contain"
                  />
                </div>
                {{ standingDetail?.name }}
              </span>
            </div>
            <div data-v-8808ea54="" class="tableTitle">
              <div
                data-v-8808ea54=""
                class="t1 font-400"
                style="box-sizing: border-box; width: 5rem"
              >
                <span data-v-8808ea54="" class="index">#</span>
                <span data-v-8808ea54="" style="margin-left: 0.21rem">
                  {{ $t("football.league-details.team") }}
                </span>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  T
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div
                  data-v-8808ea54=""
                  class="text-center w"
                  style="width: 100%"
                >
                  W
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div
                  data-v-8808ea54=""
                  class="text-center l"
                  style="width: 100%"
                >
                  L
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div
                  data-v-8808ea54=""
                  class="text-center d"
                  style="width: 100%"
                >
                  D
                </div>
              </div>

              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  {{ $t("football.league-details.points") }}
                </div>
              </div>
            </div>
            <div data-v-8808ea54="" class="contentBox">
              <div
                v-for="(rowDetails, index) in standingDetail.rows"
                :key="index"
                data-v-8808ea54=""
                class="w100 flex align-center cbox list_active2"
                @click="goToTeamDetails(rowDetails?.team?.id)"
              >
                <div
                  data-v-8808ea54=""
                  class="flex align-center contentTeamBox"
                >
                  <div data-v-8808ea54="" class="teamIndex">
                    {{
                      rowDetails && rowDetails.position
                        ? rowDetails.position
                        : ""
                    }}
                  </div>
                  <div
                    data-v-8808ea54=""
                    class="teamImg van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        rowDetails && rowDetails.team && rowDetails.team.id
                          ? 'https://sportyclub.online/images/cricket/teams/' +
                            rowDetails.team.id +
                            '/' +
                            rowDetails.team.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src = bannerImagePath('default-team.png')
                      "
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <a
                    data-v-8808ea54=""
                    class="teamName van-ellipsis"
                    style="margin-left: 0.21rem"
                  >
                    {{ rowDetails.team.name }}
                  </a>
                </div>
                <div data-v-8808ea54="" class="flex-1 flex">
                  <div data-v-8808ea54="" class="flex-1 text-center">
                    <div data-v-8808ea54="" class="detailData">
                      {{ rowDetails?.matches }}
                    </div>
                  </div>
                  <div data-v-8808ea54="" class="flex-1 text-center">
                    <div data-v-8808ea54="" class="detailData">
                      <span class="w">{{ rowDetails?.wins }}</span>
                    </div>
                  </div>
                  <div data-v-8808ea54="" class="flex-1 text-center l">
                    <div data-v-8808ea54="" class="detailData">
                      <span class="l"> {{ rowDetails?.losses }}</span>
                    </div>
                  </div>
                  <div data-v-8808ea54="" class="flex-1 text-center d">
                    <div data-v-8808ea54="" class="detailData">
                      <span class="d">{{ rowDetails?.draws }}</span>
                    </div>
                  </div>

                  <div data-v-8808ea54="" class="flex-1 text-center">
                    <div data-v-8808ea54="" class="detailData">
                      {{ rowDetails?.points }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!loading && (!this.matchStandings || !this.matchSeasons)"
      data-v-8808ea54=""
      data-v-06d4278f=""
      class="basketball_standing"
      style=""
    >
      <div data-v-8808ea54="" style="width: 100%">
        <div data-v-8808ea54="" class="w100">
          <div data-v-8808ea54="" class="contentBox">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 400px"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <template v-if="seasonDialogStatus">
    <div
      @click="toggleSeasonDialog"
      class="van-overlay"
      style="z-index: 2003"
    ></div>
    <div
      data-v-0e623cd2=""
      data-v-3cc6ec0a=""
      class="van-popup van-popup--bottom van-popup--safe-area-inset-bottom"
      style="max-height: 70%; z-index: 2004"
    >
      <div data-v-0e623cd2="" class="popcontent">
        <div data-v-0e623cd2="" class="w100 myOptions">
          <div
            v-for="season in reversedActiveLeague"
            :key="season"
            data-v-0e623cd2=""
            class="item"
            :class="{ active: season.id == activeSeason }"
            @click="activateSeason(season.id)"
          >
            {{ season.year }}
          </div>

          <!-- <div data-v-0e623cd2="" class="item">1995</div> -->
        </div>
      </div>
      <div data-v-0e623cd2="" @click="toggleSeasonDialog" class="item cancel">
        Cancel
      </div>
    </div>
  </template>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],

  data() {
    return {
      matchSeasons: null,
      loading: true,
      activeLeague: null,
      activeSeason: null,
      matchStandings: null,
      seasonDialogStatus: false,
      activeLeagueSeasons: null,
    };
  },
  props: {
    teamId: {
      type: Number,
      default: null,
    },
  },

  created() {
    this.getMatchSeasons(this.teamId);
  },
  computed: {
    reversedActiveLeague() {
      if (this.activeLeague) {
        // Make a copy of the array and reverse it
        return this.activeLeague.slice().reverse();
      } else {
        return [];
      }
    },
    seasonName() {
      return this.matchSeasons?.[this.activeLeague]?.seasons.filter(
        (season) => season.id == this.activeSeason
      )?.[0]?.name;
    },
    activeLeagueAndActiveSeason() {
      return `${this.activeLeague}|${this.activeSeason}`;
    },
  },

  watch: {
    // activeLeagueAndActiveSeason: function (newValue) {
    //   // console.log("activeLeague", newValue);
    //   console.log(newValue);
    //   const [newActiveLeague, newActiveSeason] = newValue.split("|");
    //   this.getMatchStandings(newActiveLeague, newActiveSeason);
    // },
    // activeLeague: function (newValue) {

    // },

    activeLeague: function (newValue) {
      console.log("new active League", newValue);
      this.activeLeagueSeasons = this.matchSeasons[newValue].seasons;
      this.activeSeason = this.activeLeagueSeasons[0].id;
    },
    activeSeason: function (newValue) {
      console.log("new active season", newValue);

      this.getMatchStandings(this.activeLeague, newValue);
    },
  },

  methods: {
    goToTeamDetails(teamId) {
      if (teamId)
        window.location.href =
          "/" + this.$i18n.locale + "/cricket/team/details/" + teamId;
    },
    getMatchSeasons(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/teams/seasons/${teamId}`)
        .then((response) => {
          this.matchSeasons = response.data;
          if (this.matchSeasons) {
            let matchSeasonsArray = Object.entries(this.matchSeasons);
            this.activeLeague = matchSeasonsArray[0][0] ?? null;

            this.activeSeason = matchSeasonsArray[0][1].seasons[0].id ?? null;
            this.activeLeagueSeasons =
              this.matchSeasons[this.activeLeague].seasons;
          } else {
            this.loading = false;
          }

          // this.getMatchStandings(this.activeLeague, this.activeSeason);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // get match stadnings
    getMatchStandings(leagueId, seasonId) {
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/singe/season/standings/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.matchStandings = response.data.standings;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    activateLeague(leagueId) {
      this.activeLeague = leagueId;
    },
    activateSeason(seasonId) {
      this.activeSeason = seasonId;
      this.toggleSeasonDialog();
    },
    toggleSeasonDialog() {
      console.log("toggling");
      this.seasonDialogStatus = !this.seasonDialogStatus;
    },
  },
};
</script>

<style>
</style>