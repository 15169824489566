<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-if="!loading">
    <div class="innerMatchInfo" data-v-ef85c778="">
      <!-- begin::header -->
      <div class="header" data-v-54d9a5ac="" data-v-ef85c778="">
        <div class="van-sticky">
          <header
            class="first-header"
            style="background: #299442"
            data-v-54d9a5ac=""
          >
            <div
              class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
              data-v-54d9a5ac=""
            >
              <div
                class="proMod2 flex align-center justify-center van-col"
                style="
                  font-size: 0.27rem;
                  opacity: 0.6;
                  white-space: normal;
                  line-height: unset;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                data-v-54d9a5ac=""
              >
                <span
                  class="formatClass"
                  style="opacity: 1; text-align: center; margin-left: 30px"
                  data-v-54d9a5ac=""
                >
                  <span v-if="this.$i18n.locale == 'cn'">
                    {{
                      todayMatchDetails && todayMatchDetails.leagueInfo
                        ? todayMatchDetails.leagueInfo.cn_name
                        : todayMatchDetails.leagueInfo.en_name
                    }}
                  </span>

                  <span v-else>
                    {{
                      todayMatchDetails && todayMatchDetails.leagueInfo
                        ? todayMatchDetails.leagueInfo.en_name
                        : ""
                    }}
                  </span>

                  {{ getFormattedDate(todayMatchDetails.match_time) }}
                </span>
              </div>
            </div>
          </header>
        </div>
      </div>
      <!-- end::header -->
      <div class="matchTop" data-v-ef85c778="">
        <div class="matchInfo" data-v-ef85c778="">
          <!-- begin::home Info -->
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/football/team/details/' +
              todayMatchDetails.homeInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.homeInfo.id
            "
            class="teamLeft"
            data-v-ef85c778=""
          >
            <div
              itemprop="logo"
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.logo
                    ? todayMatchDetails.homeInfo.logo + '!w100'
                  : bannerImagePath('default-team.png')
                "
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              <span v-if="this.$i18n.locale == 'cn'">
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                }}
              </span>

              <span v-else>
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.en_name
                    : todayMatchDetails.homeInfo.en_name
                }}
              </span>
            </div>
          </a>
          <!-- end::home Info -->
          <!-- begin::score -->
          <div class="matchScore" data-v-ef85c778="">
            <span v-if="liveMatches.includes(todayMatchDetails.status_id)">
              <span data-v-ef85c778="" class="timeScore">
                {{
                  onGoingMatchTime(
                    todayMatchDetails.counter_timing,
                    todayMatchDetails.status_id
                  )
                }}</span
              >
              <span class="Twinkle timeScore" data-v-ef85c778="">'</span>
            </span>
            <div class="score" data-v-ef85c778="">
              <span v-if="liveMatches.includes(todayMatchDetails.status_id)">
                {{
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.home_score
                    ? todayMatchDetails.homeInfo.home_score
                    : "0"
                }}
              </span>

              <span v-if="todayMatchDetails.status_id == 8">
                {{
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.home_score
                    ? todayMatchDetails.homeInfo.home_score
                    : "0"
                }}
              </span>

              <span v-if="liveMatches.includes(todayMatchDetails.status_id)">
                {{
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.home_score
                    ? todayMatchDetails.awayInfo.home_score
                    : "0"
                }}
              </span>

              <span v-if="todayMatchDetails.status_id == 8">
                {{
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.home_score
                    ? todayMatchDetails.awayInfo.home_score
                    : "0"
                }}
              </span>
              <span v-if="todayMatchDetails.status_id == 1"> vs </span>
            </div>
            <div class="half_score" data-v-ef85c778="">
              <span v-if="liveMatches.includes(todayMatchDetails.status_id)">
                HT
                {{
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.half_time_score
                    ? todayMatchDetails.homeInfo.half_time_score
                    : "0"
                }}-{{
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.half_time_score
                    ? todayMatchDetails.awayInfo.half_time_score
                    : "0"
                }}
              </span>

              <span v-if="todayMatchDetails.status_id == 8">
                HT
                {{
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.half_time_score
                    ? todayMatchDetails.homeInfo.half_time_score
                    : "0"
                }}-{{
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.half_time_score
                    ? todayMatchDetails.awayInfo.half_time_score
                    : "0"
                }}
              </span>

              <span v-if="todayMatchDetails.status_id == 1"> HT </span>
            </div>
          </div>
          <!-- end::score -->
          <!-- begin::away info-->
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/football/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.awayInfo.id
            "
            class="teamRight"
            data-v-ef85c778=""
          >
            <div
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.logo
                    ? todayMatchDetails.awayInfo.logo + '!w100'
                  : bannerImagePath('default-team.png')
                "
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : todayMatchDetails.awayInfo.en_name
              }}
            </div>
          </a>
          <!-- end::away info -->
        </div>
      </div>
      <!-- begin::tabs -->
      <div class="van-sticky">
        <div class="van-tabs van-tabs--line" data-v-ef85c778="">
          <div
            class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
          >
            <div
              role="tablist"
              class="van-tabs__nav van-tabs__nav--line"
              style="border-color: var(--main-text-color); background: #ffffff"
            >
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('overview')"
                :style="status == 'overview' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.overview") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('odds')"
                :style="status == 'odds' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.odds") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('h2h')"
                :style="status == 'h2h' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.h2h") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('lineups')"
                :style="status == 'lineups' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text">{{
                  $t("football.league-details.lineups")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
    </div>
    <span v-if="status == 'overview'">
      <MatchOverview
        :matchId="matchId"
        :todayMatchDetails="todayMatchDetails"
      />
    </span>
    <!-- begin::squad -->
    <span v-if="status == 'lineups'">
      <MatchLineups :matchId="matchId" />
    </span>
    <!-- end::squad -->
    <!-- begin::squad -->
    <span v-if="status == 'h2h'">
      <MatchH2H :matchId="matchId" />
    </span>
    <!-- end::squad -->
    <!-- begin::squad -->
    <span v-if="status == 'odds'">
      <MatchOdds :matchId="matchId" />
    </span>
    <!-- end::squad -->
  </span>
  <MobileFooter />
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import MatchLineups from "./tabs/match-lineups.vue";
import MatchOverview from "./tabs/match-overview.vue";
import MatchH2H from "./tabs/match-h2h.vue";
import MatchOdds from "./tabs/match-odds.vue";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    MatchLineups,
    MatchOverview,
    MatchH2H,
    
    MatchOdds,
  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [2, 3, 4, 5, 6, 7],
      metaData: null,
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.matchId = itemId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },
    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      if (status_code == 3) {
        return "HT";
      }
      return minutes;
    },
  },
};
</script>
