<template>
  <div class="content-box view border-box">
    <div class="page-box">
      <div class="clearfix-row text-center">
        <div class="clearfix-row">
          <div class="avatar-box">
            <ul class="el-upload-list el-upload-list--picture-card"></ul>
            <div tabindex="0" class="el-upload el-upload--picture-card">
              <i
                class="avatar"
                :style="
                  user.avatar
                    ? `
              background-image: url('${this.BASE_SERVER_URI + user.avatar}');
              background-size: cover;
            `
                    : `
              background-image: url(/images/avatar.png);
              background-size: cover;
              background-color: rgb(233, 234, 238);
            `
                "
              ></i>
              <div class="setting" @click="openInput">
                <i class="iconfont icon-camera"></i>
                <span class="text">Change</span>
              </div>
              <input
                ref="fileInput"
                @change="uploadImage"
                type="file"
                name="avatar"
                accept="image/gif,image/jpeg,image/jpg,image/png"
                class="el-upload__input"
              />
            </div>
          </div>
        </div>
        <div class="clearfix-row">
          <div class="display-i-l">
            <div class="name-box">
              <span class="text">{{ user?.name }}</span>
              <!-- <a class="edit-btn">Edit</a> -->
            </div>
          </div>
        </div>
        <div class="clearfix-row mt-105 mb-150">
          <div class="display-i-l fs-12 color-999 text-left w-360 line-h-20">
            <div>{{ $t("common.menu.continue-with-google") }}</div>
            <div class="border-bottom">{{ user?.email }}</div>
            <!-- <div>Your profile will sync on all devices</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <span>
    <FooterModule />
  </span>
</template>
<!-- begin::scripts -->

<script>
import FooterModule from "./commons/desktop-footer.vue";

export default {
  components: {
    FooterModule,
  },
  data() {
    return {
      user: null,
      file: null,
    };
  },
  beforeMount() {
    if (localStorage.getItem("userInfo")) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    }
  },
  methods: {
    openInput() {
      this.$refs.fileInput.click();
      // window.$("input[type='file']").click();
    },
    uploadImage() {
      const fd = new FormData();
      fd.append("avatar", this.$refs.fileInput.files[0]);

      this.$axios
        .post(this.BASE_SERVER_URI + "/api/user/avatar", fd, {
          headers: localStorage.getItem("apiToken")
            ? { Authorization: "Bearer " + localStorage.getItem("apiToken") }
            : {},
        })
        .then((res) => {
          localStorage.setItem(
            "userInfo",
            JSON.stringify({ ...this.user, avatar: res.data.url })
          );
          this.user = { ...this.user, avatar: res.data.url };
          window.location.reload();
        })
        .catch((e) => console.error(e))
        .finally(() => (this.$refs.fileInput.value = null));
    },
  },
};
</script>

<style>
@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}
</style>
