<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-else>
    <div class="innerMatchInfo" data-v-ef85c778="">
      <!-- begin::header -->
      <div class="topBox" bis_skin_checked="1">
        <div
          class="header"
          data-v-54d9a5ac=""
          bis_skin_checked="1"
          style="height: 50px"
        >
          <div class="van-sticky van-sticky--fixed" bis_skin_checked="1">
            <header
              class="first-header"
              style="background: #608220"
              data-v-54d9a5ac=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-54d9a5ac=""
                bis_skin_checked="1"
              >
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="
                    font-size: 0.27rem;
                    opacity: 0.6;
                    white-space: normal;
                    line-height: unset;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  data-v-54d9a5ac=""
                  bis_skin_checked="1"
                >
                  <span
                    class="formatClass"
                    style="opacity: 1"
                    data-v-54d9a5ac=""
                    ><span data-v-54d9a5ac=""
                      ><span data-v-54d9a5ac="">
                        {{
                          this.$i18n.locale == "cn" &&
                          todayMatchDetails &&
                          todayMatchDetails.leagueInfo
                            ? todayMatchDetails.leagueInfo.cn_name
                            : todayMatchDetails.leagueInfo.en_name
                        }}
                      </span>
                      <span itemprop="startDate" data-v-54d9a5ac="">
                        {{ getFormattedDate(todayMatchDetails.match_time) }}
                      </span></span
                    ></span
                  >
                </div>
              </div>
            </header>
          </div>
        </div>
        <div class="matchTop">
          <div class="matchInfo">
            <div class="teamLeft">
              <div
                class="van-image van-image--round imgw112"
                style="
                  overflow: hidden;
                  border-radius: 50%;
                  background: #ffffff;
                "
              >
                <img
                  :src="
                    todayMatchDetails &&
                    todayMatchDetails.homeInfo &&
                    todayMatchDetails.homeInfo.logo
                      ? todayMatchDetails.homeInfo.logo + '!w60'
                      : bannerImagePath('default-player.png')
                  "
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div class="teamName van-multi-ellipsis--l2">
                <span v-if="this.$i18n.locale == 'cn'">
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.homeInfo &&
                    todayMatchDetails.homeInfo.cn_name
                      ? todayMatchDetails.homeInfo.cn_name
                      : ""
                  }}
                </span>
                <span v-else>
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.homeInfo &&
                    todayMatchDetails.homeInfo.en_name
                      ? todayMatchDetails.homeInfo.en_name
                      : ""
                  }}
                </span>
              </div>
              <div class="flex flex-col justify-center" style="width: 1.12rem">
                <div class="rateBox"></div>
              </div>
            </div>
            <div class="matchScore">
              <span
                class="color-f timeScore"
                v-if="
                  todayMatchDetails &&
                  liveMatches.includes(todayMatchDetails.status_id)
                "
                >{{ todayMatchDetails.statusDescription }}</span
              >
              <div
                class="score flex justify-center"
                v-if="
                  todayMatchDetails &&
                  (liveMatches.includes(todayMatchDetails.status_id) ||
                    todayMatchDetails.status_id == 100)
                "
              >
                <div class="flex justify-center">
                  <span class="margin01">
                    {{
                      addAllScoreTennis(
                        todayMatchDetails.homeInfo.p1,
                        todayMatchDetails.homeInfo.p2,
                        todayMatchDetails.homeInfo.p3,
                        todayMatchDetails.homeInfo.p4
                      )
                    }}</span
                  >
                  <span>-</span>
                  <span class="margin01">
                    {{
                      addAllScoreTennis(
                        todayMatchDetails.awayInfo.p1,
                        todayMatchDetails.awayInfo.p2,
                        todayMatchDetails.awayInfo.p3,
                        todayMatchDetails.awayInfo.p4
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="score flex justify-center" v-else>
                <div class="flex justify-center">
                  <span>VS</span>
                </div>
              </div>
              <div
                class="w100 flex align-center justify-center pointsText"
                v-if="
                  todayMatchDetails &&
                  (liveMatches.includes(todayMatchDetails.status_id) ||
                    todayMatchDetails.status_id == 100)
                "
              >
                <div class="scoresBox">
                  {{
                    todayMatchDetails && todayMatchDetails.homeInfo
                      ? todayMatchDetails.homeInfo.totalScore
                      : ""
                  }}
                  -
                  {{
                    todayMatchDetails && todayMatchDetails.awayInfo
                      ? todayMatchDetails.awayInfo.totalScore
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div class="teamRight">
              <div
                itemprop="logo"
                class="van-image van-image--round imgw112"
                style="
                  overflow: hidden;
                  border-radius: 50%;
                  background: #ffffff;
                "
              >
                <img
                  :src="
                    todayMatchDetails &&
                    todayMatchDetails.awayInfo &&
                    todayMatchDetails.awayInfo.logo
                      ? todayMatchDetails.awayInfo.logo + '!w60'
                      : bannerImagePath('default-player.png')
                  "
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div itemprop="name" class="teamName van-multi-ellipsis--l2">
                <span v-if="this.$i18n.locale == 'cn'">
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.awayInfo &&
                    todayMatchDetails.awayInfo.cn_name
                      ? todayMatchDetails.awayInfo.cn_name
                      : ""
                  }}
                </span>
                <span v-else>
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.awayInfo &&
                    todayMatchDetails.awayInfo.en_name
                      ? todayMatchDetails.awayInfo.en_name
                      : ""
                  }}
                </span>
              </div>
              <div class="flex flex-col justify-center" style="width: 1.12rem">
                <div class="rateBox"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-if="status == 'overview'">
      <MatchOverview
        :matchId="matchId"
        :todayMatchDetails="todayMatchDetails"
      />
    </span>
    <span>
      <MatchOdds :matchId="matchId" />
    </span>
  </span>
  <MobileFooter />
</template>
<script>
import MatchOverview from "./tabs/match-overview.vue";

import MatchOdds from "./tabs/match-odds.vue";
import metaDataUtils from '@/utils/metaDataUtils';
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    MatchOverview,
    
    MatchOdds,
  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [51, 52, 53, 54, 55],
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.matchId = itemId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId)
  },

  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },
    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },
    addAllScoreTennis(val1, val2, val3, val4) {
      val1 = val1 || 0;
      val2 = val2 || 0;
      val3 = val3 || 0;
      val4 = val4 || 0;
      return val1 + val2 + val3 + val4;
    },
  },
};
</script>
