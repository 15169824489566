<template>
  <span class="mobile-version">
    <MobileLiveScoring />
  </span>
  <span class="desktop-version">
    <DesktopLiveScoring />
  </span>
</template>
<!-- begin::scripts -->
<script>
import DesktopLiveScoring from "./desktop-version/live-scoring.vue";
import MobileLiveScoring from "./mobile-version/live-scoring.vue";
export default {
  mounted() {
    // Set document title
    document.title = "Cricket  Live Scores | Sportyclub Live";

    // Function to create and append meta tags
    const createMetaTag = (name, content) => {
      const metaTag = document.createElement("meta");
      metaTag.setAttribute("name", name);
      metaTag.content = content;
      document.head.appendChild(metaTag);
    };

    // Create meta description tag
    createMetaTag(
      "description",
      "Get live cricket scores and updates on Sportyclub Live. Stay updated with the latest cricket matches, live commentary, and real-time scores."
    );

    // Create meta keywords tag
    createMetaTag(
      "keywords",
      "live cricket scores, cricket live updates, cricket live commentary, real-time cricket scores, Sportyclub Live"
    );

    // Create canonical link tag
    const canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    canonicalLink.setAttribute(
      "href",
      "https://sportyclub.live/en/live/basketball"
    );
    document.head.appendChild(canonicalLink);
  },
  components: {
    DesktopLiveScoring,
    MobileLiveScoring
  },
};
</script>
