<template>
  <div data-v-8502b08a="" class="page-box view border-box">
    <div data-v-8502b08a="" class="text-box container">
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        重要信息和我们是谁
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        IMPORTANT INFORMATION AND WHO WE ARE
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        这些条款和条件概述了使用 Sportyclub Live
        的规则和规定。通过访问本网站，我们假设您接受
        这些条款和条件。如果出现以下情况，请勿继续使用 Sportyclub Live
        您不同意接受本声明中规定的所有条款和条件
        页。以下术语适用于这些条款和条件，
        隐私声明和免责声明以及所有协议：“客户”、“您”和“您的”是指您、登录本网站的人以及
        符合公司的条款和条件。
        “公司”、“我们自己”、“我们”、“我们的”和“我们的”均指我们公司。
        “一方”、“各方”或“我们”指的是客户和我们自己。
      </p>

      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        These terms and conditions outline the rules and regulations for the use
        of Sportyclub Live. By accessing this website, we assume you accept
        these terms and conditions. Do not continue to use Sportyclub Live if
        you do not agree to take all of the terms and conditions stated on this
        page. The following terminology applies to these Terms and Conditions,
        Privacy Statement and Disclaimer Notice and all Agreements: "Client",
        "You" and "Your" refer to you, the person log on this website and
        compliant to the Company’s terms and conditions. "The Company",
        "Ourselves", "We", "Our" and "Us", refer to our Company. "Party",
        "Parties", or "Us", refers to both the Client and ourselves.
      </p>

      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        重要信息和我们是谁
      </p>

      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        YOU MUST NOT:
      </p>
      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li>重新发布来自 Sportyclub Live 的材料。</li>
        <li>出售、出租 Sportyclub Live 的材料或对其进行再许可。</li>
        <li>复制、复制或复印来自 Sportyclub Live 的材料。</li>
        <li>重新分发 Sportyclub Live 的内容。</li>
      </ul>

      <ul class="list" v-else>
        <li>Republish material from Sportyclub Live.</li>
        <li>Sell, rent, or sub-license material from Sportyclub Live.</li>
        <li>Reproduce, duplicate or copy material from Sportyclub Live.</li>
        <li>Redistribute content from Sportyclub Live.</li>
      </ul>
      <br />

      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        从我们的网站上删除链接
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        REMOVE OF LINKS FROM OUR WEBSITE
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        如果您发现我们网站上的任何链接出于任何原因具有攻击性，您可以随时联系并通知我们。我们会考虑删除链接的请求，但我们没有义务做出回应
        直接给你。
      </p>
      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us at any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        免责声明
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        DISCLAIMER OF LIABILITY
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们对材料中的任何声明不承担任何责任。您不得依赖我们在 Sportyclub Live
        上发布的任何声明 没有首先听取专家的专业建议。中什么也没有
        材料是为任何特定目的或应任何人的要求而提供的
        特定的人。我们不提供任何形式的保证，也不提供任何保证
        材料的表示形式——信息、名称、图像、图片、徽标和图标——可在hindustantimes.com
        中找到。您可以通过以下方式访问其他网站 来自 Sportyclub Live
        的链接。这些网站不受我们的控制，我们对其任何内容不承担任何责任。我们对以下任何损失或损害不承担责任（无论此类损失是可预见的、可预见的、已知的还是其他的）：
      </p>
      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        We do not accept any responsibility for any statement in the material.
        You must not rely on any statement we have published on Sportyclub Live
        without first taking specialist professional advice. Nothing in the
        material is provided for any specific purpose or at the request of any
        particular person. We give no warranties of any kind and without any
        representations for material—information, names, images, pictures, logos
        and icons—found in hindustantimes.com. You can access other sites via
        links from Sportyclub Live. These sites are not under our control and we
        are not responsible in any way for any of their contents. We are not
        liable for any of the following losses or damages (whether such losses
        where foreseen, foreseeable, known or otherwise):
      </p>

      <ul class="list" v-if="this.$i18n.locale == 'cn'">
        <li>数据丢失。</li>
        <li>业务损失。</li>
        <li>失去机会。</li>
        <li>商誉损失或声誉受损。</li>
        <li>第三方遭受的损失。</li>
      </ul>
      <ul class="list" v-else>
        <li>Loss of Data.</li>
        <li>Loss of Business.</li>
        <li>Loss of opportunity.</li>
        <li>Loss of goodwill or injury to reputation.</li>
        <li>Losses suffered by third parties.</li>
      </ul>
      <br />
      <p
        data-v-8502b08a=""
        class="fs-18 font-500 heading"
        v-if="this.$i18n.locale == 'cn'"
      >
        使用条款的变更
      </p>
      <p data-v-8502b08a="" class="fs-18 font-500 heading" v-else>
        CHANGES OF TERMS OF USE
      </p>
      <p
        data-v-8502b08a=""
        class="pb-24 color-666 par"
        v-if="this.$i18n.locale == 'cn'"
      >
        我们保留随时修改这些使用条款的权利。任何
        使用条款的更改将发布在网站上，您在发布更改后继续使用本网站即表示您同意这些更改，并且您受这些使用条款当时最新版本的约束。如果您不接受这些使用条款的任何更改，您必须停止使用本网站。我们保留随时更改网站的权利，恕不另行通知。
      </p>
      <p data-v-8502b08a="" class="pb-24 color-666 par" v-else>
        We reserve the right, to modify these Terms of Use at any time. Any
        changes to Terms of Use will be posted on the Site and your continued
        use of the Site following a posting of changes is your agreement to the
        changes and you are bound by the then-current version of these Terms of
        Use. If any changes to these Terms of Use are unacceptable to you, you
        must discontinue use of the Site. We reserve the right to change the
        Site without notice to you, at any time.
      </p>
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<style scoped>
.par {
  color: black;
  font-family: "arial";
  font-size: 17px !important;
  text-align: justify;
}
.heading {
  color: black;
  margin-bottom: 15px;
  font-family: "arial";
  font-weight: bold;
  font-size: 18px !important;
}

.list {
  color: black;
  list-style-type: disc;
  font-family: "arial";
  font-size: 17px !important;
  margin-bottom: 15px;
  margin-left: 40px;
}
</style>