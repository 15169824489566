<template>
  <div class="w100">
    <div data-v-40b57f9e="" class="volleyball_overview">
      <div class="left">
        <div
          class="topScore"
          v-if="todayMatchDetails.status_id != match_status.NOT_STARTED"
        >
          <div
            style="
              width: 100%;
              padding: 0.1rem 0.32rem 0.05rem 0.32rem;
              box-sizing: border-box;
            "
          >
            <div class="w100 js flex">
              <div class="border-bottom" style="width: 4rem"></div>
              <div v-for="i in numberOfSetsSoFar " :key="i" class="border-bottom flex-1">
                {{ i }}
              </div>

              <div class="border-bottom flex-1">S</div>
            </div>
            <div class="w100 detailScore flex">
              <div
                class="col"
                style="
                  width: 4rem;
                  padding-left: 0.32rem;
                  border-right: 1px solid #eeeeee;
                "
              >
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        todayMatchDetails.homeInfo.logo
                          ? todayMatchDetails.homeInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      todayMatchDetails.homeInfo.cn_name
                        ? todayMatchDetails.homeInfo.cn_name
                        : todayMatchDetails.homeInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_1"></use>
                  </svg>
                </div>
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        todayMatchDetails.awayInfo.logo
                          ? todayMatchDetails.awayInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      todayMatchDetails.awayInfo.cn_name
                        ? todayMatchDetails.awayInfo.cn_name
                        : todayMatchDetails.awayInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_2"></use>
                  </svg>
                </div>
              </div>

              <div
                v-for="index in numberOfSetsSoFar"
                :key="index"
                class="col flex-1"
                :class="{
                  active:
                    getSetNumberFromMatchStatusId(
                      todayMatchDetails.status_id
                    ) == index,
                }"
                style="border-right: 1px solid #eeeeee"
              >
                <template v-for="teamIndex in 2" :key="teamIndex">
                  <div
                    class="scoreText"
                    :class="{
                      colorMax:
                        todayMatchDetails.scores &&
                        todayMatchDetails.scores[`p${index}`] &&
                        getSetNumberFromMatchStatusId(
                          todayMatchDetails.status_id
                        ) != index &&
                        teamIndex - 1 == 0
                          ? todayMatchDetails.scores[`p${index}`][
                              teamIndex - 1
                            ] > todayMatchDetails.scores[`p${index}`][teamIndex]
                          : todayMatchDetails.scores[`p${index}`]
                          ? todayMatchDetails.scores[`p${index}`][
                              teamIndex - 1
                            ] >
                            todayMatchDetails.scores[`p${index}`][teamIndex - 2]
                          : false,
                    }"
                  >
                    {{
                      todayMatchDetails.scores &&
                      todayMatchDetails.scores["p" + index]
                        ? todayMatchDetails.scores["p" + index][teamIndex - 1]
                        : "-"
                    }}
                  </div>
                </template>
              </div>

              <div
                class="col flex-1 active"
                style="border-right: 1px solid #eeeeee"
              >
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ft"] &&
                    todayMatchDetails.scores["ft"][0]
                      ? todayMatchDetails.scores["ft"][0]
                      : 0
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails.scores &&
                    todayMatchDetails.scores["ft"] &&
                    todayMatchDetails.scores["ft"][1]
                      ? todayMatchDetails.scores["ft"][1]
                      : 0
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-v-826fe974=""
        data-v-40b57f9e=""
        class="odds-box"
        type="overview"
      >
        <div data-v-826fe974="" class="list">
          <div data-v-0e07873a="" data-v-826fe974="" class="oddsBox">
            <div data-v-0e07873a="" class="oddsContent">
              <p data-v-0e07873a="" class="oddsType flex">
                <span data-v-0e07873a="" class="placeholder"></span>

                <span
                  data-v-0e07873a=""
                  class="oddsItem flex-1 justify-center align-center"
                >
                  {{ $t("football.league-details.to-win") }}
                </span>
              </p>
              <div data-v-0e07873a="" class="oddsBox flex">
                <div
                  data-v-0e07873a=""
                  class="oddsBoxLeft flex justify-center align-center"
                >
                  <img
                    data-v-0e07873a=""
                    src="/images/odds/bet365.png"
                    alt="#"
                    class="companyLogo"
                  />
                </div>
                <div data-v-0e07873a="" class="oddsBoxContent flex-1">
                  <div data-v-0e07873a="" class="oddsItem flex flex-1 border2">
                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center borderR"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][1]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][1].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >

                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][3]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
              <p data-v-0e07873a="" class="hint">
                {{ $t("football.league-details.gamble-responsibly") }}
              </p>
              <p data-v-0e07873a="" class="oddsTypeText mt-12">
                <span data-v-0e07873a="" class="openingBox"
                  ><span data-v-0e07873a="" class="chunk opening"></span>
                  <span data-v-0e07873a="">
                    {{ $t("football.league-details.opening-odds") }}
                  </span></span
                >
                <span data-v-0e07873a="" class="openingBox mt-8"
                  ><span data-v-0e07873a="" class="chunk pre-match"></span>
                  <span data-v-0e07873a="">{{
                    $t("football.league-details.pre-match-odds")
                  }}</span></span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div data-v-40b57f9e="" class="info"></div>
    </div>

    <div class="prefetch"></div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    todayMatchDetails: {
      type: Object,
      default: null,
    },
  },
  computed: {
    totalHome: function () {
      return Object.entries(this.todayMatchDetails.scores).reduce(
        (acc, cur) => (acc = cur[0].startsWith("p") ? acc + cur[1][0] : 0),
        0
      );
    },
    totalAway: function () {
      return Object.entries(this.todayMatchDetails.scores).reduce(
        (acc, cur) => (acc = cur[0].startsWith("p") ? acc + cur[1][1] : 0),
        0
      );
    },
    numberOfSetsSoFar: function () {
      let numberOfSetsSoFar =
        this.todayMatchDetails && this.todayMatchDetails.scores
          ? Object.entries(this.todayMatchDetails.scores).reduce(
              (acc, cur) => (cur[0].startsWith("p") ? acc + 1 : acc),
              0
            )
          : 0;
      return numberOfSetsSoFar > 3 ? numberOfSetsSoFar : 3;
    },
  },

  data() {
    return {
      match_status: {
        NOT_STARTED: 1,
        FIRST_SET: 51,
        SECOND_SET: 52,
        THIRD_SET: 53,
        FOURTH_SET: 54,
        FIFTH_SET: 55,
      },
      sets_match_status: [51, 52, 53, 54, 55, 56, 57],

      matchLineups: null,
      loading: true,
      matchDetails: null,
    };
  },
  methods: {
    getSetNumberFromMatchStatusId(status_id) {
      let setNumber = this.sets_match_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
  },
  created() {
    this.matchDetails = this.todayMatchDetails;
  },
};
</script>