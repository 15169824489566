<template>
  <div
    class="ft_teams view border-box"
    data-v-c36567d6=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="teamheader" data-v-c36567d6="">
      <div class="backFil" data-v-c36567d6="">
        <div class="header" data-v-54d9a5ac="" data-v-c36567d6="" style="">
          <div class="van-sticky">
            <header
              class="first-header"
              style="background: #285898"
              data-v-54d9a5ac=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-54d9a5ac=""
              >
                <div class="left van-col van-col--4" data-v-54d9a5ac="">
                  <span
                    class="icon iconfont myIcon iconback"
                    style="opacity: 0.6"
                    data-v-54d9a5ac=""
                  ></span>
                </div>
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="font-size: 0.4rem"
                  data-v-54d9a5ac=""
                >
                  <span
                    data-v-54d9a5ac=""
                    class="formatClass"
                    style="opacity: 1"
                    ><span data-v-54d9a5ac=""
                      ><span data-v-54d9a5ac=""></span>
                      <span data-v-54d9a5ac=""></span></span
                  ></span>
                </div>
                <div class="right van-col van-col--4" data-v-54d9a5ac=""></div>
              </div>
            </header>
          </div>
        </div>
      </div>
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-c36567d6=""
      >
        <div
          class="team_logo van-image van-image--round"
          style="overflow: hidden; border-radius: 50%"
          data-v-c36567d6=""
        >
          <img
            :src="
              teamDetails && teamDetails.logo
                ? teamDetails.logo
               : bannerImagePath('default-nation.png')
            "
            class="van-image__img"
            style="object-fit: contain"
          />
        </div>
        <div class="team_name" data-v-c36567d6="">
          {{
            this.$i18n.locale == "cn" && teamDetails
              ? teamDetails.cn_name
              : teamDetails.name
          }}
        </div>

        <div
          class="des flex align-center justify-center"
          data-v-c36567d6=""
          v-if="teamDetails && teamDetails.countryDetails"
        >
          <div
            class="country_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-c36567d6=""
          >
            <img
              :src="
                teamDetails &&
                teamDetails.countryDetails &&
                teamDetails.countryDetails.logo
                  ? teamDetails.countryDetails.logo
                 : bannerImagePath('default-nation.png')
              "
              alt="England"
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span class="country" data-v-c36567d6="">
            &nbsp;
            {{
              this.$i18n.locale == "cn" &&
              teamDetails &&
              teamDetails.countryDetails
                ? teamDetails.countryDetails.cn_name
                : teamDetails.countryDetails.name
            }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="breadcrumb-box iconfont"
      style=""
      data-v-14e526a2=""
      data-v-c36567d6=""
    >
      <div class="display-i-l" data-v-14e526a2="">
        <a
          :href="'/' + this.$i18n.locale + '/live/tennis'"
          class="ele link"
          data-v-14e526a2=""
          ><span data-v-14e526a2="">
            {{ $t("football.league-details.tennis-live-score") }}</span
          >
        </a>
      </div>
      <span class="jt" data-v-14e526a2=""></span>
      <h1 data-v-14e526a2="" class="ele text lastText" style="display: inline">
        <span data-v-14e526a2="">
          {{
            this.$i18n.locale == "cn" && teamDetails && teamDetails.cn_name
              ? teamDetails.cn_name
              : teamDetails.name
          }}
          schedule 2023-2024</span
        >
      </h1>
    </div>
    <div class="content" data-v-c36567d6="">
      <div data-v-fdbe674e="" data-v-c36567d6="" class="team_overview">
        <div data-v-fdbe674e="" class="info">
          <div data-v-fdbe674e="" class="info_content">
            <div data-v-fdbe674e="" class="infoItem">
              <div data-v-fdbe674e="" class="key">
                {{ $t("football.league-details.type") }}
              </div>
              <div data-v-fdbe674e="" itemprop="coach" class="value">
                {{
                  teamDetails && teamDetails.type == 1
                    ? $t("football.league-details.single")
                    : $t("football.league-details.double")
                }}
              </div>
            </div>
            <div data-v-fdbe674e="" class="infoItem">
              <div data-v-fdbe674e="" class="key">
                {{ $t("football.league-details.gender") }}
              </div>
              <div data-v-fdbe674e="" class="value">
                {{
                  teamDetails && teamDetails.type == 1
                    ? $t("football.league-details.male")
                    : $t("football.league-details.female")
                }}
              </div>
            </div>
            <div data-v-fdbe674e="" class="infoItem">
              <div data-v-fdbe674e="" class="key">
                {{ $t("football.league-details.birthday") }}
              </div>
              <div
                data-v-fdbe674e=""
                class="value"
                v-if="
                  teamDetails &&
                  teamDetails.extra &&
                  teamDetails.extra.birthday &&
                  teamDetails.extra.birthday != ''
                "
              >
                {{ formatDate(teamDetails.extra.birthday) }}
              </div>

              <div data-v-fdbe674e="" class="value" v-else>-</div>
            </div>
            <div
              data-v-fdbe674e=""
              i
              class="infoItem"
              style="border-top: 1px solid rgb(241, 241, 241)"
            >
              <div data-v-fdbe674e="" class="key">
                {{ $t("football.league-details.is-national") }}
              </div>
              <div data-v-fdbe674e="" itemprop="name" class="value">
                {{
                  teamDetails && teamDetails.national == 0
                    ? $t("football.league-details.no")
                    : $t("football.league-details.yes")
                }}
              </div>
            </div>
            <div data-v-fdbe674e="" class="infoItem">
              <div data-v-fdbe674e="" class="key">
                {{ $t("football.league-details.country") }}
              </div>
              <div data-v-fdbe674e="" class="value">
                {{
                  teamDetails &&
                  teamDetails.countryDetails &&
                  this.$i18n.locale == "cn"
                    ? teamDetails.countryDetails.en_name
                    : teamDetails.countryDetails.name
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],

  data() {
    return {
      teamDetails: null,

      loading: true,
      teamId: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getMetaData(itemId);
  },

  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(timestamp) {
      if (timestamp) {
        // Convert Unix timestamp to milliseconds
        var date = new Date(timestamp * 1000);
        var day = date.getDate();
        var month = date.getMonth() + 1; // Months are zero indexed
        var year = date.getFullYear();

        // Pad day and month with leading zeros if necessary
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        return day + "/" + month + "/" + year;
      }
      return "";
    },
  },
};
</script>
