<template>
  <span class="desktop-version">
    <DesktopVersion />
  </span>
  <span class="mobile-version">
    <MobileVersion />
  </span>
</template>


<script>
import DesktopVersion from "./desktop-version/news-details-desktop.vue";
import MobileVersion from "./mobile-version/news-details-mobile.vue";
export default {
  components: {
    DesktopVersion,
    MobileVersion,
  },
};
</script>
