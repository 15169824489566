<template>
  <div class="wrapper" data-v-8948368c="" v-if="!loading">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-08c9d60b="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
          </div>
          <div
            class="basketball_standing_tour"
            data-v-28884d50=""
            v-if="leagueStandings && leagueStandings.standings"
          >
            <div style="width: 100%">
              <template
                v-for="(group, index) in leagueStandings.standings"
                :key="index"
              >
                <div>
                  <span class="dataName">
                    {{ group.name }}
                    <div class="trans"></div>
                  </span>
                  <div
                    style="
                      width: 100%;
                      height: 4px;
                      background: rgb(222, 224, 229);
                    "
                  ></div>
                  <div class="tableTitle">
                    <div
                      class="t1 font-400"
                      style="box-sizing: border-box; width: 212px"
                    >
                      # {{ $t("football.league-details.teams") }}
                    </div>
                    <div class="font-400 t">
                      <div class="" style="margin-left: -40px">W</div>
                    </div>
                    <div class="font-400 t" style="margin-left: -70px">
                      <div class="">L</div>
                    </div>
                    <div class="font-400 t" style="margin-left: -10px">
                      <div class="">PPG</div>
                    </div>

                    <div class="font-400 t" style="margin-left: -20px">
                      <div class="">OPP PPG</div>
                    </div>

                    <div class="font-400 t">
                      <div class="">
                        {{ $t("football.league-details.won-rate") }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="contentBox"
                    v-for="standing in group.rows"
                    :key="standing.id"
                  >
                    <div class="font-400 flex align-items-center cbox">
                      <div class="flex align-items-center contentTeamBox">
                        <div
                          class="teamIndex"
                          style="
                            width: 20px;
                            height: 20px;
                            background-color: transparent;
                            color: rgb(51, 51, 51);
                          "
                        >
                          {{ standing.position }}
                        </div>
                        <img
                          :src="
                            standing &&
                            standing.teamInfo &&
                            standing.teamInfo.logo
                              ? standing.teamInfo.logo + '!w40'
                            : bannerImagePath('default-team.png')
                          "
                          width="20"
                          height="20"
                          style="cursor: pointer"
                        />
                        <div class="teamName" style="cursor: pointer">
                          <a
                            target="_blank"
                            :href="
                              '/' +
                              this.$i18n.locale +
                              '/basketball/team/details/' +
                              standing.teamInfo.name
                                .toLowerCase()
                                .replace(/[\s/]+/g, '-') +
                              standing.teamInfo.id
                            "
                          >
                            {{
                              this.$i18n.locale == "cn" &&
                              standing &&
                              standing.teamInfo &&
                              standing.teamInfo.cn_name
                                ? standing.teamInfo.cn_name
                                : standing.teamInfo.name
                            }}
                          </a>
                        </div>
                      </div>

                      <div class="flex-1 flex">
                        <div class="flex-1 text-center">
                          <div class="detailData">{{ standing.won }}</div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData">{{ standing.lost }}</div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData bl">
                            {{ standing.points_avg }}
                          </div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData OPPPPG">
                            {{ standing.points_against_avg }}
                          </div>
                        </div>

                        <div class="flex-1 text-center">
                          <div class="detailData pctback">
                            {{ standing.won_rate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!--begin:: if data is  not available -->
          <div
            class="flex justify-center align-center flex-col"
            style="height: 500px"
            v-else
          >
            <img :src="bannerImagePath('default-container.png')" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
          <!-- end::if data is  not available -->
        </div>
      </div>
      <!-- begin::right top scorer player -->
      <div class="p-r" data-v-08c9d60b="">
        <div>
          <div class="right" data-v-5021e98f="">
            <div class="tpBox" data-v-4291ef99="" data-v-5021e98f="">
              <div class="fs-12 color-999 pl-15 pr-15" data-v-4291ef99="">
                <div class="padTop">
                  {{ $t("football.league-details.league-info") }}
                </div>
              </div>
              <div class="table TopPlayer" data-v-4291ef99="">
                <div
                  class="flex justify-center align-center flex-col"
                  style="height: 400px"
                  data-v-4291ef99=""
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::right top scorer player -->
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      leagueStandings: null,
      loading: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getLeagueStandings(this.leagueId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/tennis/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
