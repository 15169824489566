<template>
  <div class="content" data-v-3aaba216="">
    <div data-v-582c2586="" data-v-3aaba216="" class="team_squad">
      <div data-v-582c2586="" class="w100">
        <div data-v-582c2586="" class="title">
          <div data-v-582c2586="" class="flex align-center">
            <div
              data-v-582c2586=""
              class="shirtNumber"
              style="margin-right: 0.27rem"
            >
              #
            </div>
            {{ $t("football.league-details.players") }}
          </div>
          <span data-v-582c2586="">
            {{ $t("football.league-details.position") }}
          </span>
        </div>
        <div
          v-if="teamSquad && teamSquad != '' && teamSquad.players"
          data-v-582c2586=""
          class="content list_active"
        >
          <a
            v-for="player in teamSquad.players"
            :key="player"
            data-v-582c2586=""
            :href="
              player.player && player.player.id
                ? '/' +
                  this.$i18n.locale +
                  '/cricket/player/details/' +    player.player.name.toLowerCase().replace(/\s/g, '-')  + '/' +
                  player.player.id
                : ''
            "
            class="w100 itemContent"
            style="display: block"
            ><div
              data-v-582c2586=""
              class="w100 h100 flex align-center justify-between"
            >
              <div data-v-582c2586="" class="flex-1 contentBox">
                <div data-v-582c2586="" class="shirtNumber">
                  {{
                    player?.player?.jerseyNumber
                      ? player?.player?.jerseyNumber
                      : "-"
                  }}
                </div>
                <div
                  data-v-582c2586=""
                  class="playerLogo van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                >
                  <img
                    :src="
                      player && player.player && player.player.id
                        ? 'https://sportyclub.online//images/cricket/players/' +
                          player.player.id +
                          '/' +
                          player.player.id +
                          '.png'
                        : bannerImagePath('default-player.png')
                    "
                    @error="$event.target.src = bannerImagePath('default-player.png')"
                    class="van-image__img"
                    style="object-fit: cover"
                  />
                </div>
                <div data-v-582c2586="" class="playerInfo">
                  <span data-v-582c2586="" class="name">{{
                    this.$i18n.locale == "cn" &&
                    player &&
                    player.player &&
                    player.player.cn_name
                      ? player.player.cn_name
                      : player.player.name
                      ? player.player.name
                      : ""
                  }}</span>
                </div>
              </div>
              <span data-v-582c2586="" class="marketValue">{{
                player && player.player && player.player.position
                  ? player.player.position
                  : "-"
              }}</span>
            </div>
          </a>
        </div>
        <div v-else data-v-582c2586="" class="content list_active">
          <div
            class="flex justify-center align-center flex-col"
            style="height: 500px"
          >
            <img :src="bannerImagePath('default-container.png')" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],

  props: {
    teamSquad: {
      type: Object,
      default: null,
    },
    methods:{
      bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    }
  },
};
</script>

<style>
</style>