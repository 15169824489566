export default {
    computed: {
        thereIsLiveMatches: function () {
            return this.countLiveMatches && this.countLiveMatches > 0
                ? true
                : false;
        },

        thereIsUpcomingMatches() {
            return this.todayUpcomingMatchList &&
                typeof this.todayUpcomingMatchList === "object" &&
                Object.keys(this.todayUpcomingMatchList).length > 0
                ? true
                : false;
        },
    },
};
