<template>
  <div id="__layout">
    <div data-v-250f1619="" id="app" class="font-en">
      <div
        data-v-6efd1af3=""
        data-v-250f1619=""
        class="privacy-view view border-box"
      >
        <p
          data-v-6efd1af3=""
          class="t1 text-center"
          v-if="this.$i18n.locale == 'cn'"
        >
          联系我们
        </p>
        <p data-v-6efd1af3="" class="t1 text-center" v-else>Contact Us</p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          如果您对运动俱乐部服务有任何疑问，请通过以下方式联系我们：
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          If you have any enquiry concerning sportyclub service, please contact
          us via:
        </div>

        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li><b>电子邮件 : </b>sportyclub.live@gmail.com</li>
          <li><b>电话 : </b>+971542984926</li>
        </ul>
        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li><b>Email : </b>sportyclub.live@gmail.com</li>
          <li><b>Phone : </b>+971542984926</li>
        </ul>
      </div>
    </div>
  </div>
  <span>
    <MobileFooter />
  </span>
</template>
    
    <script>
export default {};
</script>
    
    <style>
</style>