<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-59710436="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-59710436="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-59710436="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name
                  : leagueDetails.name
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>

          <div
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
            v-if="loading"
          >
            <div class="mt-8 flex justify-between" data-v-37d31aab="">
              <div
                class="el-select select-transfers-year el-select--mini"
                style="width: 120px"
                data-v-37d31aab=""
              >
                <img
                  src="/images/default-loader.gif"
                  style="width: 130%; margin-left: -50px"
                />
              </div>
            </div>
          </div>

          <div
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
            v-else-if="
              !loading && teamsPropertyStats && teamsPropertyStats.teamsStats
            "
          >
            <div class="mt-8 flex justify-between" data-v-37d31aab="">
              <div
                class="el-select select-transfers-year el-select--mini"
                style="width: 120px"
                data-v-37d31aab=""
              ></div>
            </div>
            <div class="table mt-8" data-v-37d31aab="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12"
                data-v-37d31aab=""
              >
                <div class="col" style="width: 116px" data-v-37d31aab="">
                  &nbsp;
                </div>
                <div
                  class="col flex-1 justify-start pl-15 border-box"
                  data-v-37d31aab=""
                >
                  #
                  <span style="margin-left: 48px" data-v-37d31aab="">{{
                    $t("football.league-details.players")
                  }}</span>
                </div>
                <div data-v-37d31aab="" class="col flex-1">
                  {{ $t("football.league-details.goals") }}
                </div>
              </div>
              <div
                class="body flex fs-12 color-333 align-start"
                data-v-37d31aab=""
              >
                <div
                  class="flex flex-wrap"
                  style="width: 116px"
                  data-v-37d31aab=""
                >
                  <!-- toggleTopPlayers -->
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'goals'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('goals')"
                  >
                    {{ $t("football.league-details.goals") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer active"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'assists'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('assists')"
                  >
                    {{ $t("football.league-details.assists") }}
                  </div>

                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'minutes_played'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('minutes_played')"
                  >
                    {{ $t("football.league-details.minutes_played") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'red_cards'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('red_cards')"
                  >
                    {{ $t("football.league-details.red_cards") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'yellow_cards'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('yellow_cards')"
                  >
                    {{ $t("football.league-details.yellow_cards") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'shots'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('shots')"
                  >
                    {{ $t("football.league-details.shots") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'shots_on_target'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('shots_on_target')"
                  >
                    {{ $t("football.league-details.shots_on_target") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'dribble'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('dribble')"
                  >
                    {{ $t("football.league-details.dribble") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'dribble_succ'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('dribble_succ')"
                  >
                    {{ $t("football.league-details.dribble_succ") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'clearances'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('clearances')"
                  >
                    {{ $t("football.league-details.clearances") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: rgb(242, 248, 252)"
                    :style="
                      activeTab == 'interceptions'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('interceptions')"
                  >
                    {{ $t("football.league-details.interceptions") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'minutes_played'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('minutes_played')"
                  >
                    {{ $t("football.league-details.steals") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'passes'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('passes')"
                  >
                    {{ $t("football.league-details.passes") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'key_passes'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('key_passes')"
                  >
                    {{ $t("football.league-details.key_passes") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'crosses'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('crosses')"
                  >
                    {{ $t("football.league-details.crosses") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'crosses_accuracy'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('crosses_accuracy')"
                  >
                    {{ $t("football.league-details.fouls") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'fouls'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('fouls')"
                  >
                    {{ $t("football.league-details.long_ball") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'long_balls'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('long_balls')"
                  >
                    {{ $t("football.league-details.long_ball_accuracy") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'duels'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('duels')"
                  >
                    {{ $t("football.league-details.duels") }}
                  </div>
                  <div
                    class="col color-p w-bar-100 row cur-pointer"
                    style="height: 48px; background: #f2f8fc"
                    :style="
                      activeTab == 'duels_won'
                        ? 'background:rgb(225, 243, 255)'
                        : 'background: rgb(242, 248, 252)'
                    "
                    data-v-37d31aab=""
                    v-on:click="toggleTopPlayers('duels_won')"
                  >
                    {{ $t("football.league-details.duels_won") }}
                  </div>
                </div>
                <div class="flex-1 align-start" data-v-37d31aab="">
                  <template
                    v-if="
                      teamsPropertyStats &&
                      teamsPropertyStats.teamsStats &&
                      teamsPropertyStats.teamsStats != ''
                    "
                  >
                    <div
                      class="flex flex-wrap align-start"
                      data-v-37d31aab=""
                      v-for="(property, index) in teamsPropertyStats.teamsStats"
                      :key="property.id"
                    >
                      <div
                        data-v-37d31aab=""
                        class="row flex w-bar-100"
                        v-if="index < 20"
                      >
                        <a
                          data-v-37d31aab=""
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            property.team.name
                              .toLowerCase()
                              .replace(/\s/g, '-') +
                            '/' +
                            property.team.id
                          "
                          class="col cur-pointer justify-start flex-1 pl-15 border-box"
                          ><span data-v-37d31aab="" class="number color-999">{{
                            ++index
                          }}</span>
                          <span
                            data-v-37d31aab=""
                            class="player-logo mr-xs ml-15"
                          >
                            <img
                              data-v-37d31aab=""
                              :src="
                                property && property.team && property.team.logo
                                  ? property.team.logo + '!w40'
                                  : bannerImagePath('default-player.png')
                              "
                            />
                          </span>
                          <span data-v-37d31aab="" class="flex flex-col fs-12"
                            ><span data-v-37d31aab="" class="color-333">
                              {{
                                this.$i18n.locale == "cn"
                                  ? property.team.cn_name
                                  : property.team.name
                              }}
                            </span>
                          </span></a
                        >
                        <div
                          data-v-37d31aab=""
                          class="col flex-1 color-p font-500"
                        >
                          {{ property[activeTab] }}

                          {{
                            activeTab == "goals" && property.penalty != 0
                              ? "(" + property.penalty + ")"
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            class="SquadStats pl-15 pr-15 pb-24"
            data-v-37d31aab=""
            data-v-59710436=""
            data-v-7c947e55=""
            v-else
          >
            <div class="border-box" data-v-51bacf47="">
              <br />
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <div
                  data-v-1eddfbef=""
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                >
                  <img
                    :src="bannerImagePath('default-container.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-r" data-v-59710436="">
        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-00737b1a=""
          v-if="loadingStandings"
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <img
            src="/images/default-loader.gif"
            style="width: 25%; margin-left: 0px"
          />
        </div>
        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-00737b1a=""
          v-else-if="
            !loadingStandings && leagueStandings && leagueStandings.standings
          "
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>

          <div
            data-v-b056b882=""
            data-v-00737b1a=""
            data-v-7c947e55=""
            v-if="!loadingStandings && leagueStandings"
          >
            <div class="table Standing pl-15 pr-15" data-v-b056b882="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12"
                data-v-b056b882=""
              >
                <div class="col col-40 justify-between" data-v-b056b882="">
                  <span class="s-0-85 ml-5" data-v-b056b882="">#</span>
                  <span class="s-0-85" data-v-b056b882="">
                    {{ $t("football.league-details.teams") }}</span
                  >
                  <span data-v-b056b882="">&nbsp;</span>
                </div>
                <div class="col col-10 s-0-85" data-v-b056b882="">P</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">W</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">D</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">L</div>
                <div class="col col-10 s-0-85 white-space-n" data-v-b056b882="">
                  {{ $t("football.league-details.goals") }}
                </div>
                <div class="col col-10 s-0-85" data-v-b056b882="">
                  {{ $t("football.league-details.points") }}
                </div>
              </div>
              <template
                v-for="(group, index) in leagueStandings.standings"
                :key="index"
              >
                <div
                  class="head flex justify-between align-center color-999 border-box fs-12"
                  data-v-b056b882=""
                >
                  <div class="col col-40 justify-between" data-v-b056b882="">
                    <span class="s-0-85" data-v-b056b882="">
                      {{
                        group.group != 0
                          ? $t("football.league-details.group") +
                            " " +
                            group.group
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="body" data-v-b056b882="">
                  <div
                    class="row flex fs-12 color-333"
                    data-v-b056b882=""
                    v-for="standing in group.rows"
                    :key="standing.id"
                  >
                    <div
                      class="col col-40 justify-start border-box"
                      data-v-b056b882=""
                    >
                      <span class="color-999 position" data-v-b056b882="">{{
                        standing.position
                      }}</span>
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/football/team/details/' +
                          standing.teamInfo.name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '/' +
                          standing.id
                        "
                        class="ml-xs mr-xs color-333"
                        data-v-b056b882=""
                      >
                        <img
                          :src="
                            standing &&
                            standing.teamInfo &&
                            standing.teamInfo.logo
                              ? standing.teamInfo.logo + '!w40'
                            : bannerImagePath('default-team.png')
                          "
                          class="team-logo"
                          data-v-b056b882=""
                      /></a>
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/football/team/details/' +
                          standing.teamInfo.name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '/' +
                          standing.id
                        "
                        class="flex-1 h-bar-100 team-name"
                        data-v-b056b882=""
                      >
                        {{
                          this.$i18n.locale == "cn"
                            ? standing.teamInfo.cn_name
                            : standing.teamInfo.name
                        }}
                      </a>
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.total }}
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.won }}
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.draw }}
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.loss }}
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.goals }}
                    </div>
                    <div class="col col-10" data-v-b056b882="">
                      {{ standing.points }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-00737b1a=""
          v-else
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
            <!---->
          </div>
          <div data-v-b056b882="" data-v-00737b1a="" data-v-7c947e55="">
            <div class="table Standing pl-15 pr-15" data-v-b056b882="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12"
                data-v-b056b882=""
              >
                <div class="col col-40 justify-between" data-v-b056b882="">
                  <span class="s-0-85 ml-5" data-v-b056b882="">#</span>
                  <span class="s-0-85" data-v-b056b882="">
                    {{ $t("football.league-details.teams") }}</span
                  >
                  <span data-v-b056b882="">&nbsp;</span>
                </div>
                <div class="col col-10 s-0-85" data-v-b056b882="">P</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">W</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">D</div>
                <div class="col col-10 s-0-85" data-v-b056b882="">L</div>
                <div class="col col-10 s-0-85 white-space-n" data-v-b056b882="">
                  {{ $t("football.league-details.goals") }}
                </div>
                <div class="col col-10 s-0-85" data-v-b056b882="">
                  {{ $t("football.league-details.points") }}
                </div>
              </div>

              <br />
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <div
                  data-v-1eddfbef=""
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                >
                  <img
                    :src="bannerImagePath('default-container.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-59710436=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.league-info") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="LeagueInfo fs-12 pl-15 pr-15"
            data-v-bb874c34=""
            data-v-59710436=""
            data-v-7c947e55=""
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <a
                :href="
                  leagueDetails &&
                  leagueDetails.title_holder &&
                  leagueDetails.title_holder.id
                    ? '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      leagueDetails.title_holder.name.toLowerCase().replace(/\s/g, '-') +
                      '/' +
                      leagueDetails.title_holder.id
                    : ''
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
                ><img
                  :src="
                    leagueDetails &&
                    leagueDetails.title_holder &&
                    leagueDetails.title_holder.logo
                      ? leagueDetails.title_holder.logo + '!w40'
                    : bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                >
                  {{
                    this.$i18n.locale == "cn"
                      ? leagueDetails.title_holder.cn_name
                      : leagueDetails.title_holder.name
                  }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  {{ $t("football.league-details.current-title-holder") }}
                </span>
              </a>
              <a
                :href="
                  leagueDetails &&
                  leagueDetails.most_titles &&
                  leagueDetails.most_titles.id
                    ? '/' +
                      this.$i18n.locale +
                      '/football/team/details/' + leagueDetails.most_titles.name.toLowerCase().replace(/\s/g, '-') + '/' +
                      leagueDetails.most_titles.id
                    : ''
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
                ><img
                  :src="
                    leagueDetails &&
                    leagueDetails.most_titles &&
                    leagueDetails.most_titles.logo
                      ? leagueDetails.most_titles.logo + '!w40'
                    : bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                >
                  {{
                    this.$i18n.locale == "cn"
                      ? leagueDetails.most_titles.cn_name
                      : leagueDetails.most_titles.name
                  }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  {{ $t("football.league-details.most-title-holder") }}
                </span></a
              >
            </div>
            <!-- begin::league info -->
            <div class="container-box" data-v-bb874c34="">
              <div class="font-500 mb-xs" data-v-bb874c34="">
                {{ $t("football.league-details.info") }}
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.players") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.totalPlayers }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.teams") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.totalTeams }}</div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.red_cards") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.red_cards }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.yellow_cards") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.yellow_cards }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.shots") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.shots }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.goals") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.goals }}</div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.fouls") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.fouls }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.assists") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.assists }}</div>
              </div>
            </div>
            <!-- end::league Info  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      activeTab: "goals",
      teamsPropertyStats: null,
      leagueStandings: null,
      loading: true,
      loadingStandings: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getLeagueStandings(this.leagueId);
    this.getTeamStats(this.leagueId, this.activeTab);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data;
          this.loadingStandings = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.activeTab = status;
      this.getTeamStats(this.leagueId, this.activeTab);
    },
    getTeamStats(leagueId, property) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/teamsStats/${leagueId}?teamProperty=${property}`
        )
        .then((response) => {
          this.teamsPropertyStats = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
