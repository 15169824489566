<template>
  <div class="team" data-v-27524a2e="">
    <div class="wrapper" data-v-27524a2e="">
      <div class="flex" data-v-771adb1a="" data-v-27524a2e="">
        <div class="p-l flex-1" data-v-771adb1a="">
          <div
            class="container-box mb-12"
            data-v-7c947e55=""
            data-v-771adb1a=""
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span
                class="color-p font-500 w-o-h active"
                data-v-7c947e55=""
                style="cursor: pointer"
                ><span> {{ $t("football.league-details.team-squad") }} </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div
              class="Squad color-333 pl-15 pr-15 pb-24"
              data-v-0939e9fc=""
              data-v-771adb1a=""
              data-v-7c947e55=""
            >
              <div class="table mt-8" data-v-0939e9fc="">
                <div
                  class="head flex justify-between align-center color-999 border-box fs-12"
                  data-v-0939e9fc=""
                >
                  <div class="col" style="width: 200px" data-v-0939e9fc="">
                    {{ $t("football.league-details.title") }}
                  </div>
                  <div class="col flex-1" style="width: " data-v-0939e9fc="">
                    {{ $t("football.league-details.position") }}
                  </div>
                  <div class="col flex-1" style="width: " data-v-0939e9fc="">
                    {{ $t("football.league-details.gender") }}
                  </div>
                  <div class="col flex-1" style="width: " data-v-0939e9fc="">
                    {{ $t("football.league-details.birthday") }}
                  </div>
                  <div class="col flex-1" style="width: " data-v-0939e9fc="">
                    {{ $t("football.league-details.country") }}
                  </div>
                </div>

                <div class="body" data-v-0939e9fc="" v-if="loading">
                  <img
                    src="/images/default-loader.gif"
                    alt=""
                    style="width: 100px"
                  />
                </div>

                <div
                  class="body"
                  data-v-0939e9fc=""
                  v-else-if="!loading && teamSquad && teamSquad != ''"
                >
                  <div
                    v-for="squad in teamSquad"
                    :key="squad"
                    class="row flex fs-12 color-333 cur-pointer"
                    data-v-0939e9fc=""
                  >
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/player/details/' +
                        squad.player.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        squad.player.id
                      "
                      class="col justify-start border-box"
                      data-v-0939e9fc=""
                      style="width: 200px; padding-left: 12px"
                      ><img
                        :src="
                          'https://sportyclub.online//images/cricket/players/' +
                          squad.player.id +
                          '/' +
                          squad.player.id +
                          '.png'
                        "
                        @error="
                          $event.target.src =
                            bannerImagePath('default-player.png')
                        "
                        class="player-logo mr-xs"
                        data-v-0939e9fc=""
                        style="width: 35px !important; height: 35px !important"
                      /><span class="flex flex-col fs-12" data-v-0939e9fc=""
                        ><span class="color-p" data-v-0939e9fc=""
                          ><span itemprop="name" data-v-0939e9fc="">{{
                            squad.player.name
                          }}</span></span
                        ><span
                          class="flex align-center color-999 s-0-85"
                          data-v-0939e9fc=""
                          style="transform-origin: left center"
                          ><span class="dot" data-v-0939e9fc=""></span
                          ><span data-v-0939e9fc="">{{
                            squad.player.team.name
                          }}</span></span
                        ></span
                      ></a
                    >
                    <div class="col flex-1" data-v-0939e9fc="">
                      {{ squad.player.position ? squad.player.position : "-" }}
                    </div>

                    <div
                      itemprop="endDate"
                      class="col flex-1 color-999"
                      data-v-0939e9fc=""
                    >
                      {{
                        squad && squad.player && squad.player.gender
                          ? squad.player.gender
                          : "-"
                      }}
                    </div>
                    <div
                      itemprop="endDate"
                      class="col flex-1 color-999"
                      data-v-0939e9fc=""
                    >
                      {{
                        squad.player.national
                          ? $t("football.league-details.yes")
                          : $t("football.league-details.no")
                      }}
                    </div>
                    <div
                      itemprop="endDate"
                      class="col flex-1 color-999"
                      data-v-0939e9fc=""
                    >
                      {{ squad.player.country.name }}
                    </div>
                  </div>
                </div>

                <!-- begin::default container -->
                <div class="body" data-v-0939e9fc="" v-else>
                  <div
                    class="container-box mb-12"
                    data-v-7c947e55=""
                    data-v-08c9d60b=""
                  >
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 500px"
                    >
                      <img :src="bannerImagePath('no-data.png')" />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::default container -->
              </div>
            </div>
          </div>
        </div>
        <div class="p-r" data-v-29b39e42="">
          <div
            class="container-box mb-12 pb-24"
            data-v-7c947e55=""
            data-v-29b39e42=""
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                {{
                  !matchLoading &&
                  featuredMatch &&
                  featuredMatch.tournament &&
                  featuredMatch.tournament &&
                  featuredMatch.tournament.name
                    ? featuredMatch.tournament.name
                    : "Featured Match"
                }}
              </span>
            </div>

            <div
              class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
              data-v-bb874c34=""
              data-v-29b39e42=""
              data-v-7c947e55=""
              v-if="matchLoading"
            >
              <div class="o-h team-box" data-v-bb874c34="">
                <img
                  src="/images/default-loader.gif"
                  style="
                    width: 120px !important;
                    height: 60px !important;
                    margin-left: -50px;
                  "
                />
              </div>
            </div>
            <div
              class="LeagueInfo fs-12 pl-15 pr-15 mt-12"
              data-v-bb874c34=""
              data-v-29b39e42=""
              data-v-7c947e55=""
              v-if="!matchLoading"
            >
              <div class="o-h team-box" data-v-bb874c34="">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/team/details/' +
                    featuredMatch.homeTeam.name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    featuredMatch.homeTeam.id
                  "
                  class="item flex align-center justify-center flex-col cur-pointer"
                  data-v-bb874c34=""
                >
                  <img
                    :src="
                      featuredMatch.homeTeam.id
                        ? 'https://sportyclub.online//images/cricket/teams/' +
                          featuredMatch.homeTeam.id +
                          '/' +
                          featuredMatch.homeTeam.id +
                          '.png'
                        : bannerImagePath('default-team.png')
                    "
                    @error="
                      $event.target.src = bannerImagePath('default-team.png')
                    "
                    class="logo"
                    data-v-bb874c34=""
                  />
                  <span
                    class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                    style="line-height: 24px"
                    data-v-bb874c34=""
                  >
                    {{ featuredMatch.homeTeam.name }}
                  </span>
                  <span
                    class="color-999"
                    style="margin-top: 1px"
                    data-v-bb874c34=""
                  >
                    <span>
                      {{ featuredMatchH2H ? featuredMatchH2H.homeWins : "" }}
                    </span>
                  </span>
                </a>
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/team/details/' +
                    featuredMatch.awayTeam.name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    featuredMatch.awayTeam.id
                  "
                  class="item flex align-center justify-center flex-col cur-pointer"
                  data-v-bb874c34=""
                >
                  <img
                    :src="
                      featuredMatch.awayTeam.id
                        ? 'https://sportyclub.online//images/cricket/teams/' +
                          featuredMatch.awayTeam.id +
                          '/' +
                          featuredMatch.awayTeam.id +
                          '.png'
                        : bannerImagePath('default-team.png')
                    "
                    @error="
                      $event.target.src = bannerImagePath('default-team.png')
                    "
                    class="logo"
                    data-v-bb874c34=""
                  />
                  <span
                    class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                    style="line-height: 24px"
                    data-v-bb874c34=""
                    >{{ featuredMatch.awayTeam.name }}</span
                  >
                  <span
                    class="color-999"
                    style="margin-top: 1px"
                    data-v-bb874c34=""
                  >
                    <span>
                      {{ featuredMatchH2H ? featuredMatchH2H.awayWins : "" }}
                    </span>
                  </span>
                </a>
              </div>
              <div class="container-box" data-v-bb874c34="">
                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.date") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{ timestampToDate(featuredMatch.startTimestamp) }}
                  </div>
                </div>
                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.time") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{ timestampToTime(featuredMatch.startTimestamp) }}
                  </div>
                </div>

                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.toss-win") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{
                      featuredMatch && featuredMatch.tossWin
                        ? featuredMatch.tossWin
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.toss-decision") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{
                      featuredMatch && featuredMatch.tossDecision
                        ? featuredMatch.tossDecision
                        : "-"
                    }}
                  </div>
                </div>

                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.venue") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{
                      featuredMatch &&
                      featuredMatch.venue &&
                      featuredMatch.venue.city &&
                      featuredMatch.venue.city.name
                        ? featuredMatch.venue.city.name
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between Stadium-item"
                  data-v-bb874c34=""
                >
                  <div class="color-999" data-v-bb874c34="">
                    {{ $t("football.league-details.country") }}
                  </div>
                  <div data-v-bb874c34="">
                    {{
                      featuredMatch &&
                      featuredMatch.venue &&
                      featuredMatch.venue.country &&
                      featuredMatch.venue.country.name
                        ? featuredMatch.venue.country.name
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import metaDataUtils from "@/utils/dynamicImagePath";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],
  props: {
    teamId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      teamSquad: null,
      loading: true,
      featuredMatch: null,
      featuredMatchH2H: true,
      matchLoading: true,
    };
  },
  created() {
    this.getTeamSquad(this.teamId);
    this.getFeaturedMatch(this.teamId);
  },
  methods: {
    // method to get today matchlist
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/teams/squad/` + teamId)
        .then((response) => {
          this.teamSquad = response.data.players;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get featured match
    getFeaturedMatch(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/teams/next-matches/${teamId}`
        )
        .then((response) => {
          this.getFeaturedMatchDetails(response.data.events[0].id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get h2h
    getMatchH2H(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/single/match/h2h/${matchId}`
        )
        .then((response) => {
          if (response.data.teamDuel !== null) {
            this.featuredMatchH2H = response.data.teamDuel;
          } else {
            // Handle the case when teamDuel is null
            // For example, display a message or set a default value
            console.log("teamDuel is null");
          }
          this.matchLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to convert timeStamp
    timestampToDate(timestamp) {
      // Create a new Date object from the timestamp (in milliseconds)
      var date = new Date(timestamp * 1000);

      // Array of month names
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Extract day, month, and year
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return day + " " + months[monthIndex] + " " + year;
    },
    // method to convert timestamp to time
    timestampToTime(timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    // method to get featured match detailss
    getFeaturedMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/details/${matchId}`
        )
        .then((response) => {
          this.featuredMatch = response.data.event;
          this.getMatchH2H(matchId);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    timestampToReadable(timestamp) {
      // Check if timestamp is valid
      if (!timestamp || isNaN(timestamp) || timestamp < 0) {
        return "Invalid Date";
      }

      // Convert timestamp to milliseconds
      var date = new Date(timestamp * 1000);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return "Invalid Date";
      }

      // Array of month names
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Get day, month, and year
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      // Format the date
      var readableDate = day + " " + monthNames[monthIndex] + ", " + year;

      return readableDate;
    },
  },
};
</script>
