<template>
  <div class="content" data-v-c36567d6="">
    <div class="team_overview" data-v-fdbe674e="" data-v-c36567d6="">
      <!---->

      <div class="dec-box" data-v-fdbe674e="">
        <div class="text" data-v-fdbe674e="">
          <b> {{ $t("football.league-details.about-this-match") }} </b>
          <br />
          <p v-if="this.$i18n.locale == 'cn'">
      
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.cn_name
                  : "-"
              }}
     
            对阵
         
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : "-"
              }}







            （以及在线视频直播）于 UTC 时间 {{
              new Date(todayMatchDetails.match_timing)
                .toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .slice(0, 5)
            }}  在 Cup 开始。在
        
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.cn_name
                  : "-"
              }}
      
            对阵
         
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : "-"
              }}
      
            比分直播 你能查询到
       
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.cn_name
                  : "-"
              }}
       
            对阵
      
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : "-"
              }}
     
            以往所有的交锋记录。您可以在不同的商店找到我们 语言为“AiScore”。安装
            AiScore 应用程序 和 在您的手机上关注
       
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.cn_name
                  : "-"
              }}
        
            对阵
        
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : "-"
              }}
        
            的比赛直播！
          </p>
          <p v-else>
           
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.en_name
                  : "-"
              }}
        
            vs
         
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.en_name
                  : "-"
              }}
     
            (and video online live stream) starts at
            {{
              new Date(todayMatchDetails.match_timing)
                .toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .slice(0, 5)
            }}
            UTC time in Cup. Here on
        
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.name
                  : "-"
              }}
      
            vs
         
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.name
                  : "-"
              }}
       

            LiveScore you can find all
           
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.name
                  : "-"
              }}
       
            vs
       
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.name
                  : "-"
              }}
     
            previous results sorted by their H2H matches. <br /><br /><span
              style="font-size: 13px; font-weight: 700"
            ></span
            >You can find us in all stores on different languages as "AiScore".
            Install AiScore app on and follow
      
              {{
                todayMatchDetails && todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.name
                  : "-"
              }}
      
            vs
        
              {{
                todayMatchDetails && todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.name
                  : "-"
              }}
      

            live on your mobile!
          </p>

          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    todayMatchDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
      matchDetails: null,
    };
  },
  created() {
    this.matchDetails = this.todayMatchDetails;
  },
};
</script>
