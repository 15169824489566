<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <div class="detail view border-box back" data-v-4a160e70="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-4a160e70="">
      <div class="content-box" data-v-4a160e70="">
        <ul class="breadcrumb" data-v-4a160e70="">
          <li data-v-4a160e70="">
            <a
              :href="'/' + this.$i18n.locale + '/live/football'"
              class="link"
              data-v-4a160e70=""
            >
              {{ $t("football.league-details.football-live-score") }}
            </a>
          </li>
          <li data-v-4a160e70="">
            <a
              v-if="todayMatchDetails.hasLeagueDetails === 1"
              :href="
                '/' +
                this.$i18n.locale +
                '/football/league/details/' +
                todayMatchDetails.leagueInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.competition_id
              "
              class="link"
              data-v-4a160e70=""
            >
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.leagueInfo
                  ? todayMatchDetails.leagueInfo.cn_name
                  : todayMatchDetails.leagueInfo.en_name
              }}
            </a>
            <a v-else class="link" data-v-4a160e70="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.leagueInfo
                  ? todayMatchDetails.leagueInfo.cn_name
                  : todayMatchDetails.leagueInfo.en_name
              }}
            </a>
          </li>
          <li data-v-4a160e70="">
            <h1 itemprop="name" class="text" data-v-4a160e70="">
              <a
                v-if="
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.awayInfo
                "
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/football/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                }}
              </a>

              vs

              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/football/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.cn_name
                    : todayMatchDetails.awayInfo.en_name
                }}
              </a>

              {{ $t("football.league-details.live-score-prediction") }}

              {{ getFormattedBroadcrumbDate(todayMatchDetails.match_time) }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div
      itemtype="http://schema.org/SportsEvent"
      class="top color-333 flex-col flex align-center"
      data-v-60167e7a=""
      data-v-4a160e70=""
    >
      <div class="comp-name" data-v-60167e7a="">
        <a href="" data-v-60167e7a="">
          {{
            this.$i18n.locale == "cn" &&
            todayMatchDetails &&
            todayMatchDetails.leagueInfo
              ? todayMatchDetails.leagueInfo.cn_name
              : todayMatchDetails.leagueInfo.en_name
          }}
          {{ getFormattedDate(todayMatchDetails.match_time) }}
        </a>
      </div>
      <!-- begin::home & away Info -->
      <div class="flex w-bar-100 homeBox" data-v-60167e7a="">
        <div class="home-box" data-v-60167e7a="">
          <div class="text font-500 teamName" data-v-60167e7a="">
            <a
              data-v-60167e7a=""
              :href="
                '/' +
                this.$i18n.locale +
                '/football/team/details/' +
                todayMatchDetails.homeInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.home_team_id
              "
            >
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.homeInfo
                  ? todayMatchDetails.homeInfo.cn_name
                  : todayMatchDetails.homeInfo.en_name
              }}
            </a>
          </div>
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/football/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            class="team-logo"
            data-v-60167e7a=""
            ><img
              alt="JS Saoura U21"
              title="JS Saoura U21"
              :src="
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.logo
                  ? todayMatchDetails.homeInfo.logo + '!w60'
                  : '/images/default-football.jpg'
              "
              itemprop="logo"
              data-v-60167e7a=""
            />
          </a>
        </div>
        <!-- begin::home -->
        <div class="h-top-center matchStatus3" data-v-60167e7a="">
          <!-- check if match is live -->

          <div
            class="font-bold home-score"
            data-v-60167e7a=""
            v-if="liveMatches.includes(todayMatchDetails.status_id)"
          >
            <span data-v-60167e7a="">
              {{
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.home_score
                  ? todayMatchDetails.homeInfo.home_score
                  : "0"
              }}
            </span>
          </div>

          <!-- check if match is finished -->
          <div
            class="font-bold home-score"
            data-v-60167e7a=""
            v-if="todayMatchDetails.status_id == 8"
          >
            <span data-v-60167e7a="">{{
              todayMatchDetails &&
              todayMatchDetails.homeInfo &&
              todayMatchDetails.homeInfo.home_score
                ? todayMatchDetails.homeInfo.home_score
                : "0"
            }}</span>
          </div>
          <!-- check if match is upcoming -->

          <div
            class="font-bold home-score"
            data-v-60167e7a=""
            v-if="todayMatchDetails.status_id == 1"
          >
            <span data-v-60167e7a="">{{
              todayMatchDetails &&
              todayMatchDetails.homeInfo &&
              todayMatchDetails.homeInfo.home_score
                ? todayMatchDetails.homeInfo.home_score
                : "-"
            }}</span>
          </div>

          <div class="flex-1 text-center scoreBox" data-v-60167e7a="">
            <div class="h-16 m-b-4" data-v-60167e7a="">
              <span
                class="notUnderway"
                data-v-60167e7a=""
                v-if="todayMatchDetails.status_id == 1"
              >
                VS
              </span>
              <span
                data-v-60167e7a=""
                v-if="liveMatches.includes(todayMatchDetails.status_id)"
                style="color: #c72a1d !important"
              >
                {{
                  onGoingMatchTime(
                    todayMatchDetails.counter_timing,
                    todayMatchDetails.status_id
                  )
                }}
                <span class="loader__dot" style="margin-left: 2px !important"
                  >'</span
                >
              </span>
              <span
                class="notUnderway"
                data-v-60167e7a=""
                v-if="todayMatchDetails.status_id == 8"
              >
                {{ $t("football.league-details.full-time") }}
              </span>
            </div>

            <!-- check if match is live -->
            <div
              class="f-s-12 smallStatus"
              data-v-60167e7a=""
              v-if="liveMatches.includes(todayMatchDetails.status_id)"
            >
              {{ $t("football.league-details.ht") }}

              {{
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.half_time_score
                  ? todayMatchDetails.homeInfo.half_time_score
                  : "0"
              }}-{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.half_time_score
                  ? todayMatchDetails.awayInfo.half_time_score
                  : "0"
              }}
            </div>
            <!-- check if match is live -->
            <div
              class="f-s-12 smallStatus"
              data-v-60167e7a=""
              v-if="todayMatchDetails.status_id == 8"
            >
              {{ $t("football.league-details.ht") }}
              {{
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.half_time_score
                  ? todayMatchDetails.homeInfo.half_time_score
                  : "0"
              }}-{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.half_time_score
                  ? todayMatchDetails.awayInfo.half_time_score
                  : "0"
              }}
            </div>
            <!-- check if match is upcoming -->
            <div
              class="f-s-12 smallStatus"
              data-v-60167e7a=""
              v-if="todayMatchDetails.status_id == 1"
            >
              {{ $t("football.league-details.ht") }}
              {{
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.half_time_score
                  ? todayMatchDetails.homeInfo.half_time_score
                  : ""
              }}-{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.half_time_score
                  ? todayMatchDetails.awayInfo.half_time_score
                  : ""
              }}
            </div>
          </div>

          <!-- check if match is live -->
          <div
            class="font-bold away-score"
            data-v-60167e7a=""
            v-if="liveMatches.includes(todayMatchDetails.status_id)"
          >
            <span data-v-60167e7a=""
              >{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.away_score
                  ? todayMatchDetails.awayInfo.away_score
                  : "0"
              }}
            </span>
          </div>

          <!-- check if match is finished -->
          <div
            class="font-bold away-score"
            data-v-60167e7a=""
            v-if="todayMatchDetails.status_id == 8"
          >
            <span data-v-60167e7a=""
              >{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.away_score
                  ? todayMatchDetails.awayInfo.away_score
                  : "0"
              }}
            </span>
          </div>
          <!-- check if match is finished -->
          <div
            class="font-bold away-score"
            data-v-60167e7a=""
            v-if="todayMatchDetails.status_id == 1"
          >
            <span data-v-60167e7a=""
              >{{
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.away_score
                  ? todayMatchDetails.awayInfo.away_score
                  : "-"
              }}
            </span>
          </div>
        </div>
        <!-- end::home -->
        <!-- begin::away -->
        <div class="away-box" data-v-60167e7a="">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/football/team/details/' +
              todayMatchDetails.awayInfo.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            class="team-logo"
            data-v-60167e7a=""
            ><img
              :src="
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.logo
                  ? todayMatchDetails.awayInfo.logo + '!w60'
                  : '/images/default-football.jpg'
              "
              data-v-60167e7a=""
          /></a>
          <div class="text font-500 teamName" data-v-60167e7a="">
            <a
              :href="
                '/' +
                this.$i18n.locale +
                '/football/team/details/' +
                todayMatchDetails.awayInfo.en_name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.away_team_id
              "
              class="team-logo"
              data-v-60167e7a=""
            >
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.awayInfo
                  ? todayMatchDetails.awayInfo.cn_name
                  : todayMatchDetails.awayInfo.en_name
              }}
            </a>
          </div>
        </div>
        <!-- end::away -->
      </div>
      <!-- end::home & away Info -->
    </div>

    <!-- begin::tabs -->
    <div
      justify-content="true"
      class="tab-bar"
      data-v-274dfa8a=""
      data-v-4a160e70=""
    >
      <div class="content-box" data-v-274dfa8a="">
        <div class="child" data-v-274dfa8a="">
          <a
            class="tab"
            :class="status == 'overview' ? 'active' : ''"
            data-v-274dfa8a=""
            v-on:click="toggleTabs('overview')"
          >
            {{ $t("football.league-details.overview") }}
          </a>

          <a
            :class="status == 'odds' ? 'active' : ''"
            v-on:click="toggleTabs('odds')"
            class="tab"
            data-v-274dfa8a=""
          >
            {{ $t("football.league-details.odds") }}
          </a>

          <a
            :class="status == 'lineups' ? 'active' : ''"
            v-on:click="toggleTabs('lineups')"
            class="tab"
            data-v-274dfa8a=""
          >
            {{ $t("football.league-details.lineups") }}
          </a>
        </div>
      </div>
    </div>
    <!-- end::tabs -->
    <!-- begin::overview -->
    <div
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'overview' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOverviewTab :matchId="todayMatchDetails.id" />
      <LiveMatchH2HTab :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::overview -->
    <!-- begin::odds -->
    <div
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'odds' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOdds :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::odds -->

    <!-- begin::odds -->
    <span :style="status == 'lineups' ? 'display:block' : 'display:none'">
      <MatchLineups
        :matchId="todayMatchDetails.id"
        :matchDetails="todayMatchDetails"
      />
    </span>
    <!-- end::odds -->
  </div>
  <DesktopFooter />
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import LiveMatchOverviewTab from "./tabs/live-match-overview.vue";
import LiveMatchH2HTab from "./tabs/match-h2h.vue";
import LiveMatchOdds from "./tabs/match-odds.vue";
import MatchLineups from "./tabs/match-lineups.vue";

export default {
  components: {
    LiveMatchOverviewTab,
    LiveMatchH2HTab,
    LiveMatchOdds,
    MatchLineups,

  },
  mixins: [metaDataUtils],
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: null,
      liveMatches: [2, 3, 4, 5, 6, 7],
      metaData: null,
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },
    // method to calculate the time for live match
    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      if (status_code == 3) {
        return "HT";
      }
      return minutes;
    },
  },
};
</script>
