<template>
  <div data-v-417eeb1a="" class="all-comps">
    <div data-v-417eeb1a="" class="title">
      {{ $t("football.live-score.other-leages") }}
    </div>
    <div
      data-v-417eeb1a=""
      class="data-box"
      v-for="(country, key) in countryList"
      :key="key"
    >
      <div
        data-v-417eeb1a=""
        class="comps-item"
        v-on:click="checkCountryActiveLeague(country.id)"
        :class="countryLeagueActive == country.id ? 'active' : ''"
        :data-attr="countryLeagueActive"
        :data-attr-id="country.id"
      >
        <div
          data-v-417eeb1a=""
          class="text"
          v-on:click="getSingleCountryLeagues(country.id)"
        >
          <div data-v-417eeb1a="" class="country-logo">
            <img
              :src="country.logo + '!w30'"
              data-v-417eeb1a=""
              alt="United Arab Emirates"
              title="United Arab Emirates"
            />
          </div>
          <span data-v-417eeb1a="" class="ml-xs">
            {{ this.$i18n.locale == "cn" ? country.cn_name : country.name }}
          </span>
        </div>
        <!---->
      </div>

      <span>
        <div
          data-v-417eeb1a=""
          class="comps-box"
          :style="
            countryLeagueActive == country.id ? 'display:block' : 'display:none'
          "
          v-for="league in singleCountryLeagues"
          :key="league.id"
        >
          <div
            style="cursor: pointer"
            data-v-417eeb1a=""
            :data-attr="countryLeagueActive"
            :data-attr-id="country.id"
            class="item w-o-h"
            v-if="countryLeagueActive == country.id"
          >
            <a
    
              :href="
                '/' +
                this.$i18n.locale +
                '/football/league/details/' +
                league.name.toLowerCase().replace(/\s/g, '-') +
                '/' +
                league.id
              "
              data-v-94567e5a=""
              target="_blank"
              class="item w-o-h comName"
              v-if="league.hasLeagueDetails"
              style="cursor: pointer"
            >
              <span
                data-v-94567e5a=""
                class="link"
                style="margin-left: -20px !important"
              >
                {{
                  this.$i18n.locale == "cn" && league && league.cn_name
                    ? league.cn_name
                    : league.name
                }}</span
              >
            </a>
            <a
              data-v-94567e5a=""
              target="_blank"
              class="item w-o-h comName"
              v-else
              style="cursor: not-allowed"
            >
              <span
                data-v-94567e5a=""
                class="link"
                style="margin-left: -20px !important"
              >
                {{
                  this.$i18n.locale == "cn" && league && league.cn_name
                    ? league.cn_name
                    : league.name
                }}</span
              >
            </a>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      countryList: null,
      categoryList: null,
      countryLeagueActive: "",
      singleCountryLeagues: null,
      singleCategoryLeagues: null,
    };
  },
  created() {
    this.getCountryLeaguesList();
    this.getCategoryLeaguesList();
  },
  methods: {
    // method to get country list
    getCountryLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/country/list`)
        .then((response) => {
          this.countryList = response.data.countryList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get category list
    getCategoryLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/category/list`)
        .then((response) => {
          this.categoryList = response.data.categoryList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to call single country leagues
    getSingleCountryLeagues(leagueId) {
      this.singleCountryLeagues = null;

      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/country/leagues/${leagueId}`)
        .then((response) => {
          this.singleCountryLeagues = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to call single country leagues
    getSingleCategoryLeagues(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/football/category/leagues/${leagueId}`
        )
        .then((response) => {
          this.singleCategoryLeagues = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get League Details
    checkCountryActiveLeague(countryId) {
      if (this.countryLeagueActive == countryId) {
        this.countryLeagueActive = "";
      } else {
        this.countryLeagueActive = countryId;
      }
    },
  },
};
</script>
