<template>
  <div class="content-box" data-v-4a160e70="">
    <span mode="out-in" data-v-4a160e70="">
      <div class="clearfix-row" data-v-9bac93fa="" data-v-4a160e70="">
        <div class="detail-box" data-v-9bac93fa="">
          <div
            class="title color-blue fs-14 border-box font-500"
            data-v-9bac93fa=""
          >
            {{ $t("football.league-details.lineups") }}
          </div>
          <div class="content border-box" data-v-9bac93fa="">
            <div class="table mt-20" data-v-9bac93fa="">
              <div class="head" data-v-9bac93fa="">
                <div class="col" data-v-9bac93fa="">
                  <a
                    target="_self"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      matchDetail.homeInfo.en_name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      matchDetail.homeInfo.id
                    "
                    class="ml-xs cur-pointer"
                    data-v-9bac93fa=""
                    ><img
                      :src="matchDetail.homeInfo.logo"
                      class="w-24"
                      data-v-9bac93fa=""
                  /></a>
                  <div class="desc ml-xs" data-v-9bac93fa="">
                    <a
                      target="_self"
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/football/team/details/' +
                        matchDetail.homeInfo.en_name
                          .toLowerCase()
                          .replace(/\s/g, '-') +
                        '/' +
                        matchDetail.homeInfo.id
                      "
                      class="name color-333 fs-14 cur-pointer"
                      data-v-9bac93fa=""
                    >
                      {{
                        this.$i18n.locale == "cn"
                          ? matchDetail.homeInfo.cn_name
                          : matchDetail.homeInfo.en_name
                      }}</a
                    >
                  </div>
                </div>
              </div>

              <span
                v-if="
                  matchLineups && matchLineups.home && matchLineups.home != ''
                "
              >
                <div
                  class="row"
                  data-v-9bac93fa=""
                  style="float: right; width: 50%"
                  v-for="homePlayer in matchLineups.home"
                  :key="homePlayer.id"
                >
                  <div class="col fs-12 pr-15 pl-15" data-v-9bac93fa="">
                    <div class="flex flex-1 align-center" data-v-9bac93fa="">
                      <div class="color-b shirtNumber" data-v-9bac93fa="">
                        {{ homePlayer.shirt_number }}
                      </div>
                      <a
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/football/player/details/' +
                          homePlayer.name.toLowerCase().replace(/\s/g, '-') +
                          '/' +
                          homePlayer.id
                        "
                        class="ml-xs cur-pointer"
                        data-v-9bac93fa=""
                        ><img
                          :src="
                            homePlayer && homePlayer.logo
                              ? homePlayer.logo
                              : bannerImagePath('default-player.png')
                          "
                          c
                          class="br-50 player-logo"
                          data-v-9bac93fa=""
                      /></a>
                      <div class="desc ml-xs" data-v-9bac93fa="">
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/player/details/' +
                            homePlayer.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            homePlayer.id
                          "
                          class="name color-333 cur-pointer"
                          data-v-9bac93fa=""
                          >{{
                            this.$i18n.locale == "cn"
                              ? homePlayer.cn_name
                              : homePlayer.name
                          }}
                        </a>
                        <div class="sub mt-xss" data-v-9bac93fa="">
                          <span class="color-999" data-v-9bac93fa="">{{
                            homePlayer.position
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>

              <span v-else>
                <br />
                <div class="contentBox" data-v-1eddfbef="">
                  <div
                    class="scheduleBox"
                    data-v-1eddfbef=""
                    style="width: 100%"
                  >
                    <div
                      data-v-1eddfbef=""
                      class="flex justify-center align-center flex-col"
                      style="height: 300px"
                    >
                      <img
                        :src="bannerImagePath('default-container.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <div class="table mt-20" data-v-9bac93fa="">
              <div class="head" data-v-9bac93fa="">
                <div class="col" data-v-9bac93fa="">
                  <a
                    target="_self"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      matchDetail.awayInfo.en_name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      matchDetail.awayInfo.id
                    "
                    class="ml-xs cur-pointer"
                    data-v-9bac93fa=""
                    ><img
                      :src="matchDetail.awayInfo.logo"
                      class="w-24"
                      data-v-9bac93fa=""
                  /></a>
                  <div class="desc ml-xs" data-v-9bac93fa="">
                    <a
                      target="_self"
                      href="/"
                      class="name color-333 fs-14 cur-pointer"
                      data-v-9bac93fa=""
                    >
                      {{
                        this.$i18n.locale == "cn"
                          ? matchDetail.awayInfo.cn_name
                          : matchDetail.awayInfo.en_name
                      }}
                    </a>
                  </div>
                </div>
              </div>
              <span
                v-if="
                  matchLineups && matchLineups.away && matchLineups.away != 0
                "
              >
                <div
                  class="row"
                  data-v-9bac93fa=""
                  style="float: right; width: 50%"
                  v-for="awayPlayer in matchLineups.away"
                  :key="awayPlayer.id"
                >
                  <div class="col fs-12 pr-15 pl-15" data-v-9bac93fa="">
                    <div class="flex flex-1 align-center" data-v-9bac93fa="">
                      <div class="color-b shirtNumber" data-v-9bac93fa="">
                        {{ awayPlayer.shirt_number }}
                      </div>
                      <a
                        target="_self"


                        :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/player/details/' +
                            awayPlayer.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            awayPlayer.id
                          "




                        class="ml-xs cur-pointer"
                        data-v-9bac93fa=""
                        ><img
                          :src="
                            awayPlayer && awayPlayer.logo
                              ? awayPlayer.logo
                              : bannerImagePath('default-player.png')
                          "
                          class="br-50 player-logo"
                          data-v-9bac93fa=""
                      /></a>
                      <div class="desc ml-xs" data-v-9bac93fa="">
                        <a
                          target="_self"
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/player/details/' +
                            awayPlayer.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            awayPlayer.id
                          "
                          class="name color-333 cur-pointer"
                          data-v-9bac93fa=""
                          >{{
                            this.$i18n.locale == "cn"
                              ? awayPlayer.cn_name
                              : awayPlayer.name
                          }}
                        </a>
                        <div class="sub mt-xss" data-v-9bac93fa="">
                          <span class="color-999" data-v-9bac93fa="">{{
                            awayPlayer.position
                          }}</span>
                        </div>
                      </div>
                      <!---->
                    </div>
                    <!---->
                  </div>
                </div>
              </span>
              <span v-else>
                <br />
                <div class="contentBox" data-v-1eddfbef="">
                  <div
                    class="scheduleBox"
                    data-v-1eddfbef=""
                    style="width: 100%"
                  >
                    <div
                      data-v-1eddfbef=""
                      class="flex justify-center align-center flex-col"
                      style="height: 300px"
                    >
                      <img
                        :src="bannerImagePath('default-container.png')"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
  <div class="football-index-page view border-box theme-init" v-if="!loading">
    <!-- begin::broadcumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
      style="color: #7a7d8a; padding-left: 0"
    >
      <span class="el-breadcrumb__item" aria-current="page"
        ><span role="link" class="el-breadcrumb__inner"
          ><a href="/" target="_blank" style="cursor: pointer">
            <h1 class="liveScoreh1" v-if="this.$i18n.locale == 'cn'">
              {{ $t("football.live-score.aiScore-football-live-score") }}
            </h1>
          </a></span
        ><i class="el-breadcrumb__separator el-icon-arrow-right"></i
      ></span>
    </div>
    <!-- end::broudcumb -->
    <div class="page-content-box" style="width: ">
      <div class="flex" data-v-d4c6fef0="">
        <div data-v-417eeb1a="" data-v-d4c6fef0="" class="index-left fs-13">
          <!-- begin::hot league list -->
          <HotLeagues />
          <!-- end::league list -->
          <!-- begin::languages -->
          <CountryList />
          <!-- end::languages -->
        </div>
        <!-- begin::table -->

        <!-- end::table -->
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },

    matchDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
      matchDetail: null,
    };
  },
  created() {
    this.getLiveMatchLineups(this.matchId);
    this.matchDetail = this.matchDetails;
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to get today matchlist
    getLiveMatchLineups(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/lineups/` + matchId)
        .then((response) => {
          this.matchLineups = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
