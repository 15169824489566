<template>
  <span v-if="loading">
    <LoadingSection />
  </span>
  <!-- begin::teamplate -->
  <div
    class="innerMatchInfo detail view border-box bg"
    v-if="!loading && todayMatchDetails && todayMatchDetails != ''"
  >
    <!-- begin::broadcrumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
    >
      <span class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner">
          <a target="_blank" style="color: var(--main-text-color)">
            {{ $t("cricket.league-details.cricket-live-score") }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i>
      </span>
      <span class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner">
          <a
            target="_blank"
            style="color: var(--main-text-color)"
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/league/details/' +
              todayMatchDetails.tournament.uniqueTournament.name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.tournament.uniqueTournament.id
            "
          >
            {{ todayMatchDetails.tournament.uniqueTournament.name }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"> </i>
      </span>
      <span class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">
          <h1 class="fs-12 font-400">
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/cricket/team/details/' +
                todayMatchDetails.homeTeam.name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.homeTeam.id
              "
            >
              <b>
                {{ todayMatchDetails.homeTeam.name }}
              </b>
            </a>
            vs
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/cricket/team/details/' +
                todayMatchDetails.awayTeam.name
                  .toLowerCase()
                  .replace(/\s/g, '-') +
                '/' +
                todayMatchDetails.awayTeam.id
              "
            >
              <b>
                {{ todayMatchDetails.awayTeam.name }}
              </b>
            </a>
            {{ $t("football.league-details.live-score-prediction") }}
            <span>
              {{ unixTimestampToTime(todayMatchDetails.startTimestamp) }}
            </span>
          </h1> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
    </div>
    <!-- end::broadcrumb -->
    <!-- begin::header -->
    <div class="matchTop">
      <div class="text-center info">
        <a
          :href="
            '/' +
            this.$i18n.locale +
            '/cricket/team/details/' +
            todayMatchDetails.tournament.uniqueTournament.name
              .toLowerCase()
              .replace(/\s/g, '-') +
            '/' +
            todayMatchDetails.tournament.uniqueTournament.id
          "
          target="_blank"
          class=""
          >{{ todayMatchDetails.tournament.uniqueTournament.name }}</a
        >

        <span class="cutOffLine">/</span>
        <span itemprop="startDate">
          {{ unixTimestampToTime(todayMatchDetails.startTimestamp) }}</span
        >
      </div>

      <div class="matchInfo">
        <div class="teamLeft">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +
              todayMatchDetails.homeTeam.name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.homeTeam.id
            "
            target="_blank"
            class="teamName"
          >
            {{
              todayMatchDetails.homeTeam.name.length > 18
                ? todayMatchDetails.homeTeam.shortName
                : todayMatchDetails.homeTeam.name
            }}
          </a>

          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +
              todayMatchDetails.homeTeam.name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.homeTeam.id
            "
            target="_blank"
            class="teamName"
          >
            <img
              :src="
                'https://sportyclub.online//images/cricket/teams/' +
                todayMatchDetails.homeTeam.id +
                '/' +
                todayMatchDetails.homeTeam.id +
                '.png'
              "
              @error="$event.target.src = bannerImagePath('default-team.png')"
            />
          </a>
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div class="detailtopIcon1" data-v-5754b2d9=""></div>
          </div>
        </div>
        <div class="matchScore scoreColorRed" style="position: relative">
          <p>
            <!-- begin::homeScpre -->
            <span
              v-for="homeInning in todayMatchDetails.homeScore.innings"
              :key="homeInning"
            >
              {{
                homeInning.score +
                "/" +
                homeInning.wickets +
                " (" +
                homeInning.overs +
                ")"
              }}
              &nbsp;
            </span>
            <!-- end::homeScore -->
            <span v-if="todayMatchDetails.status.description != '1st Inning'">
              VS
            </span>
            <span
              v-for="awayInning in todayMatchDetails.awayScore.innings"
              :key="awayInning"
            >
              {{
                awayInning.score +
                "/" +
                awayInning.wickets +
                " (" +
                awayInning.overs +
                ")"
              }}
              &nbsp;
            </span>
          </p>
          <span class="cricketStatus" style="color: brown">
            <b>
              {{
                todayMatchDetails && todayMatchDetails.note
                  ? todayMatchDetails.note
                  : todayMatchDetails.status.description
              }}
            </b>
          </span>
        </div>
        <div class="teamRight">
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div class="score flex justify-center scoreRed hideScore">0</div>
            <div class="detailtopIcon2" data-v-5754b2d9=""></div>
          </div>

          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +
              todayMatchDetails.awayTeam.name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.awayTeam.id
            "
            target="_blank"
            class="teamName"
          >
            <img
              :src="
                'https://sportyclub.online//images/cricket/teams/' +
                todayMatchDetails.awayTeam.id +
                '/' +
                todayMatchDetails.awayTeam.id +
                '.png'
              "
              @error="$event.target.src = bannerImagePath('default-team.png')"
            />
          </a>
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +
              todayMatchDetails.awayTeam.name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              todayMatchDetails.awayTeam.id
            "
            target="_blank"
            class="teamName"
          >
            {{
              todayMatchDetails.awayTeam.name.length > 18
                ? todayMatchDetails.awayTeam.shortName
                : todayMatchDetails.awayTeam.name
            }}</a
          >
        </div>
      </div>
    </div>
    <!-- begin::tabs -->
    <div class="tab-bar color-333 fs-14 flex align-center">
      <div
        class="tab"
        :class="activeTab == 'lineups' ? 'active' : ''"
        v-on:click="toggleTabs('lineups')"
      >
        <a target="_self">{{ $t("football.league-details.lineups") }}</a>
      </div>
      <div
        class="tab"
        :class="activeTab == 'scorecard' ? 'active' : ''"
        v-on:click="toggleTabs('scorecard')"
      >
        <a target="_self">{{ $t("football.league-details.score-card") }}</a>
      </div>
      <div
        class="tab"
        :class="activeTab == 'h2h' ? 'active' : ''"
        v-on:click="toggleTabs('h2h')"
      >
        <a target="_self">{{ $t("football.league-details.h2h") }}</a>
      </div>
    </div>
    <!-- end::tabs -->
    <!-- end::header -->
    <!-- lineups -->
    <MatchLineups
      v-if="activeTab == 'lineups' && !loading"
      :matchId="matchId"
    />
    <!-- scoreboard -->

    <MatchScoreBoard v-if="activeTab == 'scorecard'" :matchId="matchId" />

    <!-- match head to head -->

    <MatchH2H
      v-if="activeTab == 'h2h' && !loading"
      :matchId="matchId"
      :homeTeamId="homeTeamId"
      :awayTeamId="awayTeamId"
      :customId="customId"
    />
  </div>
  <!-- end::main template -->

  <DesktopFooter />
</template>

<script>
import MatchScoreBoard from "./tabs/matchScore-board.vue";
import MatchH2H from "./tabs/match-H2H.vue";
import MatchLineups from "./tabs/match-lineups.vue";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],
  components: {
    MatchScoreBoard,
    MatchH2H,
    MatchLineups,
  },

  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      activeTab: null,
      homeTeamId: null,
      awayTeamId: null,
      customId: null,
      matchId: null,
      statusCode: null,
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.matchId = itemId;
    this.getTodayMatchDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    // method to toggle tabs
    toggleTabs(tab) {
      this.activeTab = tab;
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/details/` +
            matchId
        )
        .then((response) => {
          this.todayMatchDetails = response.data.event;
          this.homeTeamId = response.data.event.homeTeam.id;
          this.awayTeamId = response.data.event.awayTeam.id;
          this.customId = response.data.event.customId;
          this.statusCode = response.data.event.status.code;


          this.activeTab = this.statusCode == 0 ? "h2h" : "lineups";

          // finished match status == 100
          // upcoming match status == 0

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    unixTimestampToTime(unixTimestamp) {
      // Create a new Date object

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      const date = new Date(unixTimestamp * 1000);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const dayOfWeek = days[date.getDay()];
      const month = months[date.getMonth()];
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();

      return `${formattedHours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm} ${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`;
    },

    // method to apply meta descriptions,keywords etc using api call
    getMetaData() {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/match/meta/` + this.matchId
        )
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
