<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-29b39e42="" data-v-6c1525da="">
      <div class="p-l flex-1" data-v-29b39e42="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-29b39e42="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ activeLeagueTitle ? activeLeagueTitle : $t('football.league-details.standings') }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="fs-12 color-666 pl-15 pr-15 pb-xxs"
            data-v-77277ac5=""
            data-v-29b39e42=""
            data-v-7c947e55=""
          >
            <!-- if loading -->
            <div class="border-box" data-v-51bacf47="" v-if="loading">
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <img
                  src="/images/default-loader.gif"
                  style="width: 123px; height: 60px"
                />
              </div>
              <br />
              <br />
            </div>

            <!-- display data -->
            <div
              class="w100"
              data-v-77277ac5=""
              v-else-if="!loading && matchSeasons != ''"
            >
              <div class="stage-box mt-15" data-v-77277ac5="">
                <span
                  v-for="league in matchSeasons"
                  :key="league"
                  class="stage pl-15 pr-15 border-box"
                  :class="
                    league.tournament.id == activeLeagueId ? 'active' : ''
                  "
                  data-v-77277ac5=""
                  v-on:click="getActiveLeague(league.tournament.id)"
                  >{{ league.tournament.name }}</span
                >
              </div>
              <!--  -->

              <!-- begin::dropdown -->
              <div
                class="el-select select-transfers-year el-select--mini"
                style="width: 150px"
                data-v-3f728e22=""
                :style="!activeLeague ? 'display:none' : ''"
              >
                <divs
                  class="el-input el-input--mini el-input--suffix"
                  v-on:click="toggleDropDown(toggleState)"
                >
                  <input
                    type="text"
                    readonly="readonly"
                    autocomplete="off"
                    placeholder="Select League"
                    class="el-input__inner"
                  /><span class="el-input__suffix"
                    ><span class="el-input__suffix-inner"
                      ><i
                        class="el-select__caret el-input__icon el-icon-arrow-up"
                      ></i></span
                  ></span>
                </divs>
                <div
                  class="el-select-dropdown el-popper"
                  style="
                    min-width: 150px;
                    transform-origin: center top;
                    z-index: 2009;
                    position: absolute;
                  "
                  :style="toggleState ? 'display:none' : 'display:block'"
                  x-placement="bottom-start"
                >
                  <div class="el-scrollbar" style="">
                    <div
                      class="el-select-dropdown__wrap el-scrollbar__wrap"
                      style="margin-bottom: -6px; margin-right: -6px"
                    >
                      <ul class="el-scrollbar__view el-select-dropdown__list">
                        <li
                          class="el-select-dropdown__item"
                          data-v-be3a8a6a=""
                          v-for="season in reversedActiveLeague"
                          :key="season"
                          v-on:click="
                            getMatchStandings(
                              activeLeagueId,
                              season.id,
                              toggleState
                            )
                          "
                        >
                          <span> {{ season.year }} </span>
                        </li>
                      </ul>
                    </div>
                    <div class="el-scrollbar__bar is-horizontal">
                      <div
                        class="el-scrollbar__thumb"
                        style="transform: translateX(0%)"
                      ></div>
                    </div>
                    <div class="el-scrollbar__bar is-vertical">
                      <div
                        class="el-scrollbar__thumb"
                        style="transform: translateY(0%); height: 24.3636%"
                      ></div>
                    </div>
                  </div>
                  <div
                    x-arrow=""
                    class="popper__arrow"
                    style="left: 35px"
                  ></div>
                </div>
              </div>

              <!-- end::dropdown -->
              <div class="border-box" data-v-51bacf47="">
                <div class="w100" data-v-975a1620="" data-v-77277ac5="">
                  <div class="mt-30" data-v-975a1620="">
                    <div class="w100" data-v-975a1620="" v-if="standingLoading">
                      <img
                        src="/images/default-loader.gif"
                        style="width: 123px; height: 60px"
                      />
                    </div>
                    <div
                      class="w100"
                      data-v-975a1620=""
                      v-if="!standingLoading && matchStandings"
                    >
                      <!-- if data is available -->
                      <div
                        class="border-box"
                        data-v-975a1620=""
                        v-for="group in matchStandings"
                        :key="group"
                      >
                        <div class="title mt-xs" data-v-975a1620="">
                          <span
                            class="year color-666 relative"
                            data-v-975a1620=""
                          >
                            {{ group.name }}
                            <span class="triangle" data-v-975a1620=""></span
                          ></span>
                        </div>
                        <div
                          class="table flex-1 fs-12"
                          style="border-top: 1px solid #eeeeee"
                          data-v-7f09763e=""
                          data-v-975a1620=""
                        >
                          <div
                            class="row fs-12 color-333 h-40"
                            style="background: rgba(244, 245, 248, 1)"
                            data-v-7f09763e=""
                          >
                            <div
                              class="col border-right flex-4"
                              data-v-7f09763e=""
                            >
                              <span style="margin-left: 20px" data-v-7f09763e=""
                                >#</span
                              >
                              <span class="pl-30 ml-xs" data-v-7f09763e="">
                                {{ $t("football.league-details.teams") }}
                              </span>
                            </div>
                            <div
                              class="col flex-1dot5 pwdl border-right"
                              data-v-7f09763e=""
                            >
                              <span class="flex-1" data-v-7f09763e="">T</span>
                              <span class="flex-1 w" data-v-7f09763e="">W</span>
                              <span class="flex-1 d" data-v-7f09763e="">L</span>
                              <span class="flex-1 l" data-v-7f09763e="">D</span>
                            </div>

                            <div
                              class="col pts flex-0dot5 justify-center"
                              data-v-7f09763e=""
                            >
                              Pts
                            </div>
                          </div>
                          <div
                            data-team-id="0m2q15ix41fm76x"
                            class="row fs-12 color-333"
                            style="height: 40px; position: relative"
                            data-v-7f09763e=""
                            v-for="team in group.rows"
                            :key="team"
                          >
                            <div
                              class="flex align-center col h-40 border-right flex-4"
                              style="overflow: auto"
                              data-v-7f09763e=""
                              :style="
                                team.team.id == teamId
                                  ? 'background-color:rgb(236,242,251)'
                                  : ''
                              "
                            >
                              <a
                                target="_blank"
                                :href="
                                  '/' +
                                  this.$i18n.locale +
                                  '/cricket/team/details/' +
                                  team.team.name.toLowerCase().replace(/\s/g, '-')  +
                                  '/' +
                                  team.team.id
                                "
                                class="color-333 flex align-center w-o-h"
                                data-v-7f09763e=""
                                ><span
                                  class="num"
                                  style="
                                    lex-shrink: 0;
                                    background: #00d200 !important;
                                    color: #fff !important;
                                    margin-left: 15px;
                                  "
                                  data-v-7f09763e=""
                                >
                                  {{ team.position }}
                                </span>
                                <img
                                  :src="
                                    'https://sportyclub.online//images/cricket/teams/' +
                                    team.team.id +
                                    '/' +
                                    team.team.id +
                                    '.png'
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  class="ml-xs w-20"
                                  style="flex-shrink: 0"
                                  data-v-7f09763e=""
                                />
                                <span
                                  class="ml-xs team-name w-o-h"
                                  data-v-7f09763e=""
                                  >{{ team.team.name }}</span
                                ></a
                              >
                            </div>
                            <div
                              class="col flex-1dot5 pwdl border-right h-40"
                              data-v-7f09763e=""
                              :style="
                                team.team.id == teamId
                                  ? 'background-color:rgb(236,242,251)'
                                  : ''
                              "
                            >
                              <span class="flex-1" data-v-7f09763e="">{{
                                team.matches
                              }}</span>
                              <span class="flex-1 w" data-v-7f09763e="">{{
                                team.wins
                              }}</span>
                              <span class="flex-1 d" data-v-7f09763e="">{{
                                team.losses
                              }}</span>
                              <span class="flex-1 l" data-v-7f09763e="">{{
                                team.draws
                              }}</span>
                            </div>

                            <div
                              class="col pts flex-0dot5 justify-center h-40"
                              data-v-7f09763e=""
                            >
                              {{ team.points }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- if data is not availables -->
            <div class="border-box" data-v-51bacf47="" v-else>
              <div class="scheduleBox" data-v-1eddfbef="" style="width: 100%">
                <div
                  data-v-1eddfbef=""
                  class="flex justify-center align-center flex-col"
                  style="height: 300px"
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      <!-- begin::sidebar -->
      <div class="p-r" data-v-29b39e42="">
        <div class="teamInfo" data-v-ef6263b6="" data-v-cd45ac08="">
          <div class="info" data-v-ef6263b6="">
            <div class="fs-12 color-999 pl-15 pr-15" data-v-ef6263b6="">
              <div class="padTop" style="border-bottom: none; margin-bottom: 0">
                {{ $t("football.league-details.team-info") }}
              </div>
            </div>
            <div style="width: 100%" data-v-ef6263b6="">
              <div class="pl-15 pr-15 mt-15" data-v-ef6263b6="">
                <div class="fs-14 font-500" data-v-ef6263b6="">   {{ $t("football.league-details.leagues") }}</div>
                <div
                  class="flex Competitions mt-12"
                  data-v-ef6263b6=""
                  v-if="loading"
                >
                  <img src="/images/default-loader.gif" style="width: 25%" />
                </div>
                <div
                  class="flex Competitions mt-12"
                  data-v-ef6263b6=""
                  v-else-if="!loading && matchSeasons != ''"
                >
                  <div
                    class="team flex flex-col align-center color-333"
                    data-v-ef6263b6=""
                    v-for="league in matchSeasons"
                    :key="league"
                  >
                    <a
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/league/details/' +
                        league.tournament.name.toLowerCase().replace(/\s/g, '-')  + '/' +
                        league.tournament.id
                      "
                      target="_blank"
                    >
                      <img
                        :src="
                          'https://sportyclub.online//images/cricket/leagues/' +
                          league.tournament.id +
                          '/' +
                          league.tournament.id +
                          '.png'
                        "
                        @error="
                          $event.target.src = bannerImagePath('default-nation.png')
                        "
                        class="team-logo cur"
                        data-v-ef6263b6=""
                    /></a>
                    <a
                      target="_blank"
                      class="text-center infoValue"
                      data-v-ef6263b6=""
                      >{{ league.tournament.name }}
                    </a>
                  </div>
                </div>

                <div
                  class="container-box mb-12 pb-15"
                  data-v-7c947e55=""
                  data-v-8d245a92=""
                  v-else
                >
                  <div
                    class="flex justify-center align-center flex-col"
                    style="height: 300px"
                    data-v-8d245a92=""
                    data-v-7c947e55=""
                  >
                    <img :src="bannerImagePath('no-data.png')" />
                    <div class="color-999 fs-12 mt-12">{{ $t("football.league-details.no-data") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::sidebar -->
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    teamId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      matchStandings: null,
      toggleState: true,
      activeLeague: null,
      activeLeagueId: null,
      matchSeasons: null,
      standingLoading: null,
      activeLeagueTitle: null,
    };
  },
  computed: {
    reversedActiveLeague() {
      if (this.activeLeague) {
        // Make a copy of the array and reverse it
        return this.activeLeague.slice().reverse();
      } else {
        return [];
      }
    }
  },
  created() {
    this.getMatchSeasons(this.teamId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    getMatchSeasons(teamId) {
      console.log(teamId);
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/teams/seasons/${teamId}`
        )
        .then((response) => {
          this.matchSeasons = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // get match stadnings
    getMatchStandings(leagueId, seasonId, toggleState) {
      this.standingLoading = true;
      this.toggleDropDown(toggleState);
      this.$axios
        .get(  this.BASE_SERVER_URI +
          `/api/cricket/league/singe/season/standings/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.matchStandings = response.data.standings;

          this.standingLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get active league
    getActiveLeague(id) {
      this.activeLeague = this.matchSeasons[id]["seasons"];
      this.activeLeagueId = this.matchSeasons[id]["tournament"]["id"];

      this.activeLeagueTitle = this.matchSeasons[id]["tournament"]["name"];
    },
    // method to toggle dropdown
    toggleDropDown(state) {
      this.toggleState = !state;
    },
  },
};
</script>
