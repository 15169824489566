<template>
    <span class="mobile-version">
      <MobileVersion />
    </span>
    <span class="desktop-version">
      <DesktopVersion />
    </span>
  </template>
  <script>
  import DesktopVersion from "./desktop-version/player-details.vue";
  import MobileVersion from "./mobile-version/player-details.vue";
  export default {
    components: {
      DesktopVersion,
      MobileVersion,
    },
  };
  </script>
  