<template>
  <div class="wrapper" data-v-8948368c="" v-if="!loading">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-08c9d60b="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
          </div>
          <div
            class="basketball_standing_tour"
            data-v-28884d50=""
            v-if="leagueStandings && leagueStandings.standings"
          >
            <div style="width: 100%">
              <template
                v-for="(group, index) in leagueStandings.standings"
                :key="index"
              >
                <div>
                  <span class="dataName">
                    {{ group.name }}
                    <div class="trans"></div>
                  </span>
                  <div
                    style="
                      width: 100%;
                      height: 4px;
                      background: rgb(222, 224, 229);
                    "
                  ></div>
                  <div class="tableTitle">
                    <div
                      class="t1 font-400"
                      style="box-sizing: border-box; width: 212px"
                    >
                      # {{ $t("football.league-details.teams") }}
                    </div>
                    <div class="font-400 t">
                      <div class="" style="margin-left: -40px">W</div>
                    </div>
                    <div class="font-400 t" style="margin-left: -70px">
                      <div class="">L</div>
                    </div>
                    <div class="font-400 t" style="margin-left: -10px">
                      <div class="">PPG</div>
                    </div>

                    <div class="font-400 t" style="margin-left: -20px">
                      <div class="">OPP PPG</div>
                    </div>

                    <div class="font-400 t">
                      <div class="">
                        {{ $t("football.league-details.won-rate") }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="contentBox"
                    v-for="standing in group.rows"
                    :key="standing.id"
                  >
                    <div class="font-400 flex align-items-center cbox">
                      <div class="flex align-items-center contentTeamBox">
                        <div
                          class="teamIndex"
                          style="
                            width: 20px;
                            height: 20px;
                            background-color: transparent;
                            color: rgb(51, 51, 51);
                          "
                        >
                          {{ standing.position }}
                        </div>
                        <img
                          :src="
                            standing &&
                            standing.teamInfo &&
                            standing.teamInfo.logo
                              ? standing.teamInfo.logo + '!w40'
                            : bannerImagePath('default-team.png')
                          "
                          width="20"
                          height="20"
                          style="cursor: pointer"
                        />
                        <div class="teamName" style="cursor: pointer">
                          <a
                            target="_blank"
                            :href="
                              '/' +
                              this.$i18n.locale +
                              '/basketball/team/details/' +
                              standing.teamInfo.name
                                .toLowerCase()
                                .replace(/\s/g, '-') +
                              '/' +
                              standing.teamInfo.id
                            "
                          >
                            {{
                              this.$i18n.locale == "cn" &&
                              standing &&
                              standing.teamInfo &&
                              standing.teamInfo.cn_name
                                ? standing.teamInfo.cn_name
                                : standing.teamInfo.name
                            }}
                          </a>
                        </div>
                      </div>

                      <div class="flex-1 flex">
                        <div class="flex-1 text-center">
                          <div class="detailData">{{ standing.won }}</div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData">{{ standing.lost }}</div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData bl">
                            {{ standing.points_avg }}
                          </div>
                        </div>
                        <div class="flex-1 text-center">
                          <div class="detailData OPPPPG">
                            {{ standing.points_against_avg }}
                          </div>
                        </div>

                        <div class="flex-1 text-center">
                          <div class="detailData pctback">
                            {{ standing.won_rate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!--begin:: if data is  not available -->
          <div
            class="flex justify-center align-center flex-col"
            style="height: 500px"
            v-else
          >
            <img :src="bannerImagePath('default-container.png')" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
          <!-- end::if data is  not available -->
        </div>
      </div>
      <!-- begin::right top scorer player -->
      <div class="p-r" data-v-08c9d60b="">
        <div
          v-if="
            (playersPropertyStats &&
              playersPropertyStats.playersStats &&
              playersPropertyStats.playersStats.length !== 0) ||
            (leagueTeamsStats &&
              leagueTeamsStats.teamsStats &&
              leagueTeamsStats.teamsStats.length !== 0)
          "
        >
          <!-- begin::players -->
          <div
            class="container-box mb-12 pb-15"
            data-v-7c947e55=""
            data-v-08c9d60b=""
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                {{
                  this.$i18n.locale == "cn" &&
                  leagueDetails &&
                  leagueDetails.cn_name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                }}
                {{ $t("football.league-details.players-stats") }}
              </span>
              <span class="color-999 fs-12" data-v-7c947e55=""></span>
            </div>
            <div
              class="mt-12"
              data-v-09c12dba=""
              data-v-08c9d60b=""
              data-v-7c947e55=""
            >
              <div class="pl-15 pr-15 fs-12 color-999 flex" data-v-09c12dba="">
                <div
                  class="Assists ml-15"
                  data-v-09c12dba=""
                  :class="topPlayers == 'points' ? 'active' : ''"
                  v-on:click="toggleTopPlayers('points')"
                >
                  {{ $t("football.league-details.points-full-form") }}
                </div>

                <div
                  class="Assists ml-15"
                  :class="topPlayers == 'assists' ? 'active' : ''"
                  data-v-09c12dba=""
                  v-on:click="toggleTopPlayers('assists')"
                >
                  {{ $t("football.league-details.assists") }}
                </div>
                <div
                  class="Assists ml-15"
                  data-v-09c12dba=""
                  :class="topPlayers == 'rebounds' ? 'active' : ''"
                  v-on:click="toggleTopPlayers('rebounds')"
                >
                  {{ $t("football.league-details.rebounds") }}
                </div>
              </div>
              <div class="table TopPlayer" data-v-09c12dba="">
                <div
                  class="head flex justify-between align-center color-999 border-box fs-12 pl-15 pr-15"
                  data-v-09c12dba=""
                >
                  <div class="col col-1" data-v-09c12dba="">#</div>
                  <div
                    class="col col-25 white-space-n"
                    data-v-09c12dba=""
                    style="margin-left: -100px !important"
                  >
                    {{ $t("football.league-details.players") }}
                  </div>

                  <div class="col col-25 white-space-n" data-v-09c12dba="">
                    <span data-v-09c12dba="">{{
                      $t("football.league-details.points-per-game")
                    }}</span>
                  </div>
                </div>
                <div
                  class="body pl-15 pr-15"
                  style="min-height: 569px"
                  data-v-09c12dba=""
                >
                  <span
                    v-if="
                      playersPropertyStats && playersPropertyStats.playersStats
                    "
                  >
                    <template
                      v-for="(
                        player, index
                      ) in playersPropertyStats.playersStats"
                      :key="player.id"
                    >
                      <div
                        class="row flex fs-12 color-333"
                        data-v-09c12dba=""
                        v-if="index < 10"
                      >
                        <div class="col col-5 color-999" data-v-09c12dba="">
                          {{ ++index }}
                        </div>
                        <div
                          class="col flex-1 justify-start ml-xxs cur-pointer"
                          data-v-09c12dba=""
                        >
                          <a
                            target="_self"
                            class="player-logo f-s-0"
                            data-v-09c12dba=""
                          >
                            <img
                              :src="
                                player && player.player && player.player.logo
                                  ? player.player.logo + '!w40'
                                  : bannerImagePath('default-player.png')
                              "
                              data-v-09c12dba=""
                            />
                          </a>
                          <div class="fs-12 ml-xs" data-v-09c12dba="">
                            <a
                              target="_self"
                              class="mb-xxs display-block color-333"
                              data-v-09c12dba=""
                            >
                              {{
                                this.$i18n.locale == "cn" &&
                                player &&
                                player.player &&
                                player.player.cn_name
                                  ? player.player.cn_name
                                  : player.player.name
                              }}
                            </a>
                          </div>
                        </div>

                        <div class="col col-25" data-v-09c12dba="">
                          {{ player.points }}
                        </div>
                      </div>
                    </template>
                  </span>
                  <span v-else>
                    <img src="/images/default-loader.gif" style="width: 25%" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::players -->
          <!-- begin::teams -->
          <div
            class="container-box mb-12 pb-15"
            data-v-7c947e55=""
            data-v-08c9d60b=""
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                {{
                  this.$i18n.locale == "cn" &&
                  leagueDetails &&
                  leagueDetails.cn_name
                    ? leagueDetails.cn_name
                    : leagueDetails.name
                }}
                {{ $t("football.league-details.teams-stats") }}
              </span>
              <span class="color-999 fs-12" data-v-7c947e55=""></span>
            </div>
            <div
              class="mt-12"
              data-v-09c12dba=""
              data-v-08c9d60b=""
              data-v-7c947e55=""
            >
              <div class="table TopPlayer" data-v-09c12dba="">
                <div
                  class="head flex justify-between align-center color-999 border-box fs-12 pl-15 pr-15"
                  data-v-09c12dba=""
                >
                  <div class="col col-1" data-v-09c12dba="">#</div>
                  <div
                    class="col col-25 white-space-n"
                    data-v-09c12dba=""
                    style="margin-left: -100px !important"
                  >
                    {{ $t("football.league-details.teams") }}
                  </div>

                  <div class="col col-25 white-space-n" data-v-09c12dba="">
                    <span data-v-09c12dba="">{{
                      $t("football.league-details.points-per-game")
                    }}</span>
                  </div>
                </div>
                <div
                  class="body pl-15 pr-15"
                  style="min-height: 569px"
                  data-v-09c12dba=""
                >
                  <span v-if="leagueTeamsStats && leagueTeamsStats.teamsStats">
                    <template
                      v-for="(player, index) in leagueTeamsStats.teamsStats"
                      :key="player.id"
                    >
                      <div
                        class="row flex fs-12 color-333"
                        data-v-09c12dba=""
                        v-if="index < 10"
                      >
                        <div class="col col-5 color-999" data-v-09c12dba="">
                          {{ ++index }}
                        </div>
                        <div
                          class="col flex-1 justify-start ml-xxs cur-pointer"
                          data-v-09c12dba=""
                        >
                          <a
                            target="_self"
                            class="player-logo f-s-0"
                            data-v-09c12dba=""
                          >
                            <img
                              :src="
                                player && player.team && player.team.logo
                                  ? player.team.logo + '!w40'
                                  : bannerImagePath('default-player.png')
                              "
                              data-v-09c12dba=""
                            />
                          </a>
                          <div class="fs-12 ml-xs" data-v-09c12dba="">
                            <a
                              target="_self"
                              class="mb-xxs display-block color-333"
                              data-v-09c12dba=""
                            >
                              {{
                                this.$i18n.locale == "cn" &&
                                player &&
                                player.team &&
                                player.team.cn_name
                                  ? player.team.cn_name
                                  : player.team.name
                              }}
                            </a>
                          </div>
                        </div>

                        <div class="col col-25" data-v-09c12dba="">
                          {{ player.points }}
                        </div>
                      </div>
                    </template>
                  </span>
                  <span v-else>
                    <img src="/images/default-loader.gif" style="width: 25%" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::teams -->
        </div>
        <div v-else>
          <div class="right" data-v-5021e98f="">
            <div class="tpBox" data-v-4291ef99="" data-v-5021e98f="">
              <div class="fs-12 color-999 pl-15 pr-15" data-v-4291ef99="">
                <div class="padTop">
                  {{ $t("football.league-details.league-info") }}
                </div>
              </div>
              <div class="table TopPlayer" data-v-4291ef99="">
                <div
                  class="flex justify-center align-center flex-col"
                  style="height: 400px"
                  data-v-4291ef99=""
                >
                  <img
                    :src="bannerImagePath('no-data.png')"
                    style="width: 123px; height: 74px"
                  />
                  <div class="color-999 fs-12 mt-12">
                    {{ $t("football.live-score.temporarily-no-data") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::right top scorer player -->
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      topPlayers: "points",
      leagueStandings: null,
      leagueTeamsStats: null,
      playersPropertyStats: null,
      loading: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getLeagueStandings(this.leagueId);
    this.getPlayerStats(this.leagueId, this.topPlayers);
    this.getLeagueTeamStats(this.leagueId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.topPlayers = status;
      this.getPlayerStats(this.leagueId, this.topPlayers);
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlayerStats(leagueId, property) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/league/season/playersStats/${leagueId}?playerProperty=${property}`
        )
        .then((response) => {
          this.playersPropertyStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team stats
    getLeagueTeamStats(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/basketball/league/season/teamsStats/${leagueId}`
        )
        .then((response) => {
          this.leagueTeamsStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
