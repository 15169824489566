<template>
  <div data-v-94567e5a="" class="allComps pr-12 pb-15">
    <div data-v-94567e5a="" class="title font-500 mb-8">
      {{ $t("football.live-score.other-leages") }}
    </div>
    <div
      data-v-94567e5a=""
      class="data-box"
      v-for="(country, key) in countryList"
      :key="key"
    >
      <div
        data-v-94567e5a=""
        class="comps-item flex justify-between"
        :class="countryLeagueActive == country.id ? 'activeItem' : ''"
        v-on:click="checkCountryActiveLeague(country.id)"
      >
        <div
          data-v-94567e5a=""
          class="flex align-center"
          v-on:click="getSingleCountryLeagues(country.id)"
        >
          <div data-v-94567e5a="" class="country-logo">
            <img
              data-v-94567e5a=""
              :src="
                country.logo
                  ? country.logo + '!w30'
                 : bannerImagePath('default-country.png')
              "
              data-v-417eeb1a=""
            />
          </div>
          <span data-v-94567e5a="" class="ml-xs">
            {{
              this.$i18n.locale == "cn" && country.cn_name
                ? country.cn_name
                : country.name
                ? country.name
                : ""
            }}</span
          >
        </div>
      </div>
      <span>
        <div
          data-v-94567e5a=""
          class="comps-box color-666"
          :style="
            countryLeagueActive == country.id ? 'display:block' : 'display:none'
          "
          v-for="league in singleCountryLeagues"
          :key="league.id"
        >
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/hockey/league/details/' +
              league.name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league.id
            "
            data-v-94567e5a=""
            target="_blank"
            class="item w-o-h comName"
            v-if="league.hasLeagueDetails"
            style="cursor: pointer"
          >
            <span data-v-94567e5a="" class="link">
              {{
                this.$i18n.locale == "cn" && league.cn_name
                  ? league.cn_name
                  : league.name
                  ? league.name
                  : ""
              }}</span
            >
          </a>
          <a
            data-v-94567e5a=""
            target="_blank"
            class="item w-o-h comName"
            v-else
            style="cursor: not-allowed"
          >
            <span data-v-94567e5a="" class="link">
              {{
                this.$i18n.locale == "cn" && league.cn_name
                  ? league.cn_name
                  : league.name
                  ? league.name
                  : ""
              }}</span
            >
          </a>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  data() {
    return {
      countryList: null,
      categoryList: null,
      countryLeagueActive: "",
      singleCountryLeagues: null,
      singleCategoryLeagues: null,
    };
  },
  created() {
    this.getCountryLeaguesList();
  },
  methods: {
    // method to get country list
    getCountryLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/country/list`)
        .then((response) => {
          this.countryList = response.data.countryList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to call single country leagues
    getSingleCountryLeagues(leagueId) {
      this.singleCountryLeagues = null;

      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/country/leagues/${leagueId}`)
        .then((response) => {
          this.singleCountryLeagues = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get League Details
    checkCountryActiveLeague(countryId) {
      if (this.countryLeagueActive == countryId) {
        this.countryLeagueActive = "";
      } else {
        this.countryLeagueActive = countryId;
      }
    },
  },
};
</script>
