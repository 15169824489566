<template>
  <!-- begin::loader -->
  <div class="detail view border-box" data-v-250f1619="" v-if="loading">
    <div class="loading van-loading van-loading--spinner van-loading--vertical">
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <!-- end::leader -->
  <span v-else>
    <div class="innerMatchInfo detail view border-box" data-v-250f1619="">
      <div class="header" data-v-54d9a5ac="">
        <div class="van-sticky">
          <header
            class="first-header"
            style="background: #825020"
            data-v-54d9a5ac=""
          >
            <div
              class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
              data-v-54d9a5ac=""
            >
              <div
                @click="() => this.$router.back()"
                class="left van-col van-col--4"
                data-v-54d9a5ac=""
              >
                <span
                  class="icon iconfont myIcon iconback"
                  style="opacity: 1"
                  data-v-54d9a5ac=""
                ></span>
              </div>
              <div
                class="proMod2 flex align-center justify-center van-col"
                style="
                  font-size: 0.27rem;
                  opacity: 0.6;
                  white-space: normal;
                  line-height: unset;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                data-v-54d9a5ac=""
              >
                <span class="formatClass" style="opacity: 1" data-v-54d9a5ac=""
                  ><span data-v-54d9a5ac=""
                    ><span data-v-54d9a5ac="">
                      {{
                        this.$i18n.locale == "cn" &&
                        todayMatchDetails.tournament &&
                        todayMatchDetails.tournament.cn_name
                          ? todayMatchDetails.tournament.cn_name +
                            " " +
                            $t("football.league-details.league") +
                            " "
                          : todayMatchDetails.tournament.name
                          ? todayMatchDetails.tournament.name +
                            " " +
                            $t("football.league-details.league") +
                            " "
                          : ""
                      }}
                    </span>
                    <span itemprop="startDate" data-v-54d9a5ac="">
                      {{
                        getFormattedDate(todayMatchDetails.startTimestamp)
                      }}</span
                    ></span
                  ></span
                >
              </div>
              <div class="right van-col van-col--4" data-v-54d9a5ac=""></div>
            </div>
          </header>
        </div>
      </div>
      <div class="matchTop" style="height: 3.92rem; background: #825020">
        <div class="matchInfo" data-v-ef85c778="">
          <!-- begin::home Info -->
          <a
            v-if="todayMatchDetails.homeTeam && todayMatchDetails.homeTeam.id"
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +    todayMatchDetails.homeTeam.name + '/' +
              todayMatchDetails.homeTeam.id
            "
            class="teamLeft"
            data-v-ef85c778=""
          >
            <div
              itemprop="logo"
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails.homeTeam && todayMatchDetails.homeTeam.id
                    ? 'https://sportyclub.online//images/cricket/teams/' +
                      todayMatchDetails.homeTeam.id +
                      '/' +
                      todayMatchDetails.homeTeam.id +
                      '.png'
                  : bannerImagePath('default-team.png')
                "
                @error="$event.target.src = bannerImagePath('default-team.png')"
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.homeTeam &&
                todayMatchDetails.homeTeam.cn_name
                  ? todayMatchDetails.homeTeam.cn_name
                  : todayMatchDetails.homeTeam.name
                  ? todayMatchDetails.homeTeam.name
                  : ""
              }}
            </div>
            <img
              v-if="
                todayMatchDetails.changes && todayMatchDetails.changes.changes
                  ? todayMatchDetails.changes.changes.includes(
                      'homeScore.innings'
                    )
                  : false
              "
              src="/images/banqiu-banzi.png"
              alt="#"
              class="svgPostop ballIconSize2"
            />
          </a>
          <!-- end::home Info -->

          <!-- begin::score -->
          <div
            v-if="
              todayMatchDetails &&
              todayMatchDetails.awayScore &&
              todayMatchDetails.awayScore instanceof Array &&
              todayMatchDetails.awayScore.length == 0 &&
              todayMatchDetails.homeScore &&
              todayMatchDetails.homeScore instanceof Array &&
              todayMatchDetails.homeScore.length == 0
            "
            class="matchScore1"
          >
            <div class="status1Box"><div class="vs">vs</div></div>
          </div>

          <div v-else class="matchScore">
            <span class="timeScore">{{
              todayMatchDetails?.status?.description
            }}</span>
            <div
              class="score"
              style="font-size: 0.3rem"
              v-if="
                todayMatchDetails.homeScore &&
                todayMatchDetails.homeScore.innings &&
                todayMatchDetails.awayScore &&
                todayMatchDetails.awayScore.innings
              "
            >
              <template
                v-for="homeInning in todayMatchDetails.homeScore.innings"
                :key="homeInning"
                >{{ homeInning.score + "/" + homeInning.wickets }}</template
              >
              -
              <template
                v-for="awayInning in todayMatchDetails.awayScore.innings"
                :key="awayInning"
                >{{ awayInning.score + "/" + awayInning.wickets }}</template
              >
            </div>

            <div
              class="half_score"
              v-if="
                todayMatchDetails.homeScore &&
                todayMatchDetails.homeScore.innings &&
                todayMatchDetails.awayScore &&
                todayMatchDetails.awayScore.innings
              "
            >
              <template
                v-for="homeInning in todayMatchDetails.homeScore.innings"
                :key="homeInning"
                >{{ homeInning.overs }}</template
              >
              -
              <template
                v-for="awayInning in todayMatchDetails.awayScore.innings"
                :key="awayInning"
                >{{ awayInning.overs }}</template
              >
              ov
            </div>
          </div>
          <!-- end::score -->
          <!-- begin::away info-->
          <a
            v-if="todayMatchDetails.awayTeam && todayMatchDetails.awayTeam.id"
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/team/details/' +  todayMatchDetails.awayTeam.name + '/' +
              todayMatchDetails.awayTeam.id
            "
            class="teamRight"
          >
            <div
              class="teamLogo van-image van-image--round"
              style="overflow: hidden; border-radius: 50%"
              data-v-ef85c778=""
            >
              <img
                :src="
                  todayMatchDetails.awayTeam && todayMatchDetails.awayTeam.id
                    ? 'https://sportyclub.online//images/cricket/teams/' +
                      todayMatchDetails.awayTeam.id +
                      '/' +
                      todayMatchDetails.awayTeam.id +
                      '.png'
                  : bannerImagePath('default-team.png')
                "
                @error="$event.target.src = bannerImagePath('default-team.png')"
                class="van-image__img"
                style="object-fit: contain"
              />
            </div>
            <div class="teamName van-multi-ellipsis--l2" data-v-ef85c778="">
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.awayTeam &&
                todayMatchDetails.awayTeam.cn_name
                  ? todayMatchDetails.awayTeam.cn_name
                  : todayMatchDetails.awayTeam.name
                  ? todayMatchDetails.awayTeam.name
                  : ""
              }}
            </div>
            <img
              v-if="
                todayMatchDetails.status_id ==
                  match_status.FIRST_INNINGS_AWAY_TEAM ||
                todayMatchDetails.status_id ==
                  match_status.SECOND_INNINGS_AWAY_TEAM
              "
              src="/images/banqiu-banzi.png"
              alt="#"
              class="svgPostop ballIconSize2"
            />
          </a>
          <!-- end::away info -->
        </div>
      </div>
      <!-- begin::tabs -->
      <div class="van-sticky">
        <div class="van-tabs van-tabs--line" data-v-ef85c778="">
          <div
            class="van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom"
          >
            <div
              role="tablist"
              class="van-tabs__nav van-tabs__nav--line"
              style="
                border-color: rgb(15, 128, 218);
                background: rgb(255, 255, 255);
              "
            >
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('overview')"
                :style="
                  activeTab == 'overview' ? 'color: rgb(15, 128, 218)' : ''
                "
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.overview") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('lineups')"
                :style="
                  activeTab == 'lineups' ? 'color: rgb(15, 128, 218)' : ''
                "
              >
                <span class="van-tab__text"
                  >{{ $t("football.league-details.lineups") }}
                </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('h2h')"
                :style="activeTab == 'h2h' ? 'color: rgb(15, 128, 218)' : ''"
              >
                <span class="van-tab__text">H2H </span>
              </div>
              <div
                role="tab"
                aria-selected="true"
                class="van-tab van-tab--complete"
                v-on:click="toggleTabs('scoreboard')"
                :style="
                  activeTab == 'scoreboard' ? 'color: rgb(15, 128, 218)' : ''
                "
              >
                <span class="van-tab__text">Scoreboard </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
      <!-- begin::broadcrumb -->

      <div
        class="breadcrumb-box iconfont"
        style="background-color: #fff"
        data-v-e49cc106=""
      >
        <div class="display-i-l" data-v-e49cc106="">
          <a
            href="https://m.aiscore.com/cricket"
            target=""
            class="ele link"
            data-v-e49cc106=""
            ><span data-v-e49cc106="">
              {{ $t("cricket.league-details.cricket-live-score") }}
            </span>
          </a>
        </div>
        <span class="jt" data-v-e49cc106=""></span>
        <span class="ele text" style="display: inline" data-v-e49cc106="">
          <span data-v-e49cc106="">
            <a
              target="_blank"
              style="color: var(--main-text-color)"
              v-if="todayMatchDetails.tournament"
              :href="
                '/' +
                this.$i18n.locale +
                '/cricket/league-details/' +
                todayMatchDetails.tournament.id
              "
            >
              {{
                this.$i18n.locale == "cn" &&
                todayMatchDetails &&
                todayMatchDetails.tournament &&
                todayMatchDetails.tournament.cn_name
                  ? todayMatchDetails.tournament.cn_name
                  : todayMatchDetails.tournament.name
                  ? todayMatchDetails.tournament.name
                  : ""
              }}
            </a>
          </span>
        </span>
        <span class="jt" data-v-e49cc106=""></span>

        <h1
          class="ele text lastText"
          style="display: inline"
          data-v-e49cc106=""
        >
          <span data-v-e49cc106="">
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                todayMatchDetails && todayMatchDetails.homeTeam.id
                  ? '/' +
                    this.$i18n.locale +
                    '/cricket/team/details/' +
                    todayMatchDetails.homeTeam.id
                  : ''
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.homeTeam &&
                  todayMatchDetails.homeTeam.cn_name
                    ? todayMatchDetails.homeTeam.cn_name
                    : todayMatchDetails.homeTeam.name
                    ? todayMatchDetails.homeTeam.name
                    : ""
                }}
              </b>
            </a>

            vs
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/cricket/team/details/' +
                todayMatchDetails.away_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.awayTeam &&
                  todayMatchDetails.awayTeam.cn_name
                    ? todayMatchDetails.awayTeam.cn_name
                    : todayMatchDetails.awayTeam.name
                    ? todayMatchDetails.awayTeam.name
                    : ""
                }}
              </b>
            </a>
          </span>
        </h1>
      </div>
      <!-- end::broadcrumb -->
      <!-- begin body of tabs -->

      <MatchOverview
        v-if="activeTab == 'overview'"
        :todayMatchDetails="todayMatchDetails"
      />
      <MatchLineups
        v-if="activeTab == 'lineups'"
        :matchId="matchId"
        :todayMatchDetails="todayMatchDetails"
      />

      <MatchH2H v-if="activeTab == 'h2h'" :matchInfo="todayMatchDetails" />

      <MatchScoreBoard v-if="activeTab == 'scoreboard'" :matchId="matchId" />
      <!-- end body of tabs -->
    </div>
  </span>
  <MobileFooter />
</template>
<script>
import MatchLineups from "./tabs/match-lineups.vue";
import MatchOverview from "./tabs/match-overview.vue";
import MatchH2H from "./tabs/match-h2h.vue";
import MatchScoreBoard from "./tabs/matchScore-board.vue";
// import MatchOdds from "./tabs/match-odds.vue";
import DateMixin from "@/utils/dateUtils";

import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],
  name: "MatchDetailsMobile",
  components: {
    MatchLineups,
    MatchOverview,
    MatchH2H,
    MatchScoreBoard,
  },

  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      liveMatches: [],
      FinishedMatches: [100],
      match_status: {
        FIRST_INNINGS_HOME_TEAM: 532,
        FIRST_INNINGS_AWAY_TEAM: 533,
        SECOND_INNINGS_HOME_TEAM: 534,
        SECOND_INNINGS_AWAY_TEAM: 535,
      },

      activeTab: "overview",

      matchId: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.matchId = itemId;
    this.getTodayMatchDetails(itemId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/details/` +
            matchId
        )
        .then((response) => {
          this.todayMatchDetails = response.data.event;
          console.log("match", this.todayMatchDetails);

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    toggleTabs(activeTab) {
      return (this.activeTab = activeTab);
    },
    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      if (status_code == 3) {
        return "HT";
      }
      return minutes;
    },
  },
};
</script>
