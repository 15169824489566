<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-8948368c="" v-if="!loading">
    <!-- begin::breadcrumb -->
    <div class="breadcrumb-box" data-v-8948368c="">
      <div class="content-box" data-v-8948368c="">
        <ul class="breadcrumb" data-v-8948368c="">
          <li data-v-8948368c="">
            <a
              :href="'/' + this.$i18n.locale + '/live/cricket'"
              class="link"
              data-v-8948368c=""
            >
              {{ $t("football.league-details.cricket-live-score") }}
            </a>
          </li>
          <li data-v-8948368c="">
            {{ teamDetails && teamDetails.name ? teamDetails.name : "" }}
          </li>
          <li data-v-8948368c="">
            <h1 class="text" data-v-8948368c="">
              {{ $t("football.league-details.winners") }},
              {{ $t("football.league-details.champions") }},
              {{ $t("football.league-details.standings") }} &nbsp;
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::breadcrumb -->
    <div class="team" data-v-8948368c="" v-if="!loading">
      <!-- begin::title holder info -->
      <div class="competition-header" data-v-26a596f7="" data-v-8948368c="">
        <div class="content border-box flex align-center" data-v-26a596f7="">
          <div class="p-l flex-1 flex align-center" data-v-26a596f7="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-26a596f7=""
            >
              <img
                :src="
                  'https://sportyclub.online//images/cricket/teams/' +
                  teamDetails.id +
                  '/' +
                  teamDetails.id +
                  '.png'
                "
                @error="$event.target.src = bannerImagePath('default-team.png')"
                class="team-logo"
                data-v-26a596f7=""
              />
              <!-- begin::title -->
              <div class="pl-15 pr-15" data-v-26a596f7="">
                <div class="fs-24 font-500 flex" data-v-26a596f7="">
                  <span
                    style="
                      max-width: 330px;
                      font-weight: 500;
                      font-family: Rubik-Medium, MicrosoftYaHei,
                        MicrosoftYaHei-Bold, PingFang SC;
                    "
                    data-v-26a596f7=""
                  >
                    {{
                      teamDetails && teamDetails.name ? teamDetails.name : ""
                    }}
                  </span>
                </div>
                <!-- begin::season list -->
                <div class="mt-8 flex" data-v-26a596f7="">
                  <span
                    class="ml-xs fs-14 color-666"
                    style="line-height: 24px"
                    data-v-26a596f7=""
                    ><b>
                      {{ teamDetails.category.name }}
                    </b></span
                  >
                </div>
              </div>
              <!-- end::title -->
            </div>
          </div>
          <!-- begin::current seasom stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-26a596f7=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ $t("football.league-details.team-information") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.country") }}
                </div>
                <div class="value font-100" data-v-5ea21537="">
                  {{
                    teamDetails &&
                    teamDetails.country &&
                    teamDetails.country.name
                      ? teamDetails.country.name
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div
                  class="color-666 font-300 mb-xxs"
                  style="transform-origin: left"
                  data-v-5ea21537=""
                >
                  {{ $t("football.league-details.is-national") }}
                </div>
                <div class="value font-100" data-v-5ea21537="">
                  {{ teamDetails.national ? "Yes" : "No" }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.gender") }}
                </div>
                <div class="value font-100" data-v-5ea21537="">
                  {{ teamDetails.gender == "M" ? "Male" : "Female" }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.category") }}
                </div>
                <div class="value font-100" data-v-5ea21537="">
                  {{ teamDetails.category.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- end::current season statss -->
        </div>
      </div>
      <!-- end::title holder info -->
      <!-- begin::tabs -->
      <div
        class="tab-bar"
        data-v-274dfa8a=""
        data-v-8948368c=""
        v-if="!loading"
      >
        <div class="content-box" data-v-274dfa8a="">
          <div class="child" data-v-274dfa8a="">
            <a
              class="tab"
              :class="activeTab === 'schedule' ? 'active' : ''"
              :style="activeTab === 'schedule' ? 'font-weight:bold' : ''"
              data-v-274dfa8a=""
              v-on:click="toggleTab('schedule')"
            >
              {{ $t("football.league-details.finished") }}
            </a>
            <a
              class="tab"
              :class="activeTab === 'upcoming-matches' ? 'active' : ''"
              :style="
                activeTab === 'upcoming-matches' ? 'font-weight:bold' : ''
              "
              data-v-274dfa8a=""
              v-on:click="toggleTab('upcoming-matches')"
            >
              {{ $t("football.league-details.upcoming") }}
            </a>

            <a
              class="tab"
              style="cursor: pointer"
              data-v-274dfa8a=""
              v-on:click="toggleTab('team-squad')"
              :style="activeTab === 'team-squad' ? 'font-weight:bold' : ''"
              :class="activeTab === 'team-squad' ? 'active' : ''"
            >
              {{ $t("football.league-details.team-squad") }}
            </a>

            <a
              class="tab"
              style="cursor: pointer"
              data-v-274dfa8a=""
              :style="activeTab === 'team-standings' ? 'font-weight:bold' : ''"
              v-on:click="toggleTab('team-standings')"
              :class="activeTab === 'team-standings' ? 'active' : ''"
            >
              {{ $t("football.league-details.standings") }}
            </a>
          </div>
        </div>
      </div>
      <!-- end::tabs -->
      <!-- begin::import components -->
      <span v-if="!loading && activeTab === 'schedule'">
        <teamMatches :teamId="teamId" />
      </span>

      <span v-if="!loading && activeTab === 'upcoming-matches'">
        <UpcomingMatches :teamId="teamId" />
      </span>

      <span v-if="!loading && activeTab === 'team-squad'">
        <TeamSquad :teamId="teamId" />
      </span>
      <span v-if="!loading && activeTab === 'team-standings'">
        <TeamStandings :teamId="teamId" />
      </span>
      <!-- end::import components -->
    </div>
  </div>

  <DesktopFooter />
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import teamMatches from "./tabs/team-matches.vue";
import UpcomingMatches from "./tabs/upcoming-matches.vue";
import TeamSquad from "./tabs/team-squad.vue";
import TeamStandings from "./tabs/team-standings.vue";

import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],

  components: {
    TeamSquad,
    teamMatches,
    TeamStandings,
    UpcomingMatches,
  },

  data() {
    return {
      teamDetails: null,
      loading: true,
      teamId: null,
      activeTab: "schedule",
      previousMatches: null,
      metaData: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/teams/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/cricket/teams/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data.team;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to toggle tabs
    toggleTab(tab) {
      this.activeTab = tab;
      console.log(tab);
    },
    // method to convert timestamp to readable date
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();

      return month + " " + day + ", " + year;
    },
  },
};
</script>
