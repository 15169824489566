<template>
  <div class="w100" v-if="!loading">
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.live-score.today-upcoming-matches") }}</span
      >
    </div>
    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px)"
    >
      <div
        class="w100"
        v-for="(league, key) in todayUpcomingMatches"
        :key="key"
        style="cursor: pointer"
      >
        <div class="list_active" style="width: 100%">
          <div
            class="list_title"
            :class="
              leagueFavoriteStatus[league[0].competition_id]
                ? 'collectComp'
                : ''
            "
          >
            &nbsp;
            <i
              class="iconfont"
              :class="
                leagueFavoriteStatus[league[0].competition_id]
                  ? 'icon-yishoucang'
                  : 'icon-weishoucang'
              "
              @click="toggleFavorite(league[0].competition_id)"
              style="visibility: visible"
            ></i>

            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
            >
              <div
                class="listImg van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  class="van-image__img"
                  v-if="
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo) ||
                      (league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name) ||
                      (league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name))
                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>
              <span class="countryText" v-if="this.$i18n.locale == 'cn'">
                <span
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.cn_name
                  "
                >
                  {{ league[0].leagueInfo.countryDetails.cn_name }}:
                </span>
                <span
                  v-else-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.categoryDetails &&
                    league[0].leagueInfo.categoryDetails.cn_name
                  "
                >
                  {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                </span>
              </span>
              <span class="countryText" v-else>
                <span
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.name
                  "
                >
                  {{ league[0].leagueInfo.countryDetails.name }}:
                </span>
                <span
                  v-else-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.categoryDetails &&
                    league[0].leagueInfo.categoryDetails.name
                  "
                >
                  {{ league[0].leagueInfo.categoryDetails.name }}:
                </span>
              </span>
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/football/league/details/' +
                  league[0].leagueInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  league[0].competition_id
                "
                class="compText"
                style="font-size: 0.32rem"
              >
                <span v-if="this.$i18n.locale == 'cn'">
                  {{
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : ""
                  }}
                </span>
                <span v-else>
                  {{
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </span>
              </a>
            </div>
          </div>
          <div class="w100" v-for="match in league" :key="match.id">
            <div class="list" style="height: 1.38rem">
              <div class="flex flex-1 contentBox">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/match/details/' +
                    match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                    '-vs-' +
                    match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    match.id
                  "
                  class="flex allBox"
                >
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        itemprop="startDate"
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ readableTime(this.$i18n.locale, match.match_time) }}
                      </span>
                      <span
                        class="fs-12 color-999 van-ellipsis"
                        style="max-width: 1.6rem"
                      >
                      </span>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex" style="height: 50%">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div
                            class="imgw4 van-image van-image--round"
                            itemprop="logo"
                            style="overflow: hidden; border-radius: 50%"
                          >
                            <img
                              alt="Uzbekistan"
                              class="van-image__img"
                              v-if="match.homeInfo"
                              :src="
                                match.homeInfo.logo
                                  ? match.homeInfo.logo
                                : bannerImagePath('default-team.png')
                              "
                              lazy="loaded"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                        <div
                          class="w-o-h flex align-center h100"
                          style="padding-left: 0.2rem; width: 5rem"
                        >
                          <div
                            itemprop="homeTeam"
                            class="w-o-h h100 fs-14"
                            style="margin-right: 0.1rem; max-width: 4.5rem"
                          >
                            {{
                              this.$i18n.locale == "cn" &&
                              match.homeInfo.cn_name
                                ? match.homeInfo.cn_name
                                : match.homeInfo.en_name
                                ? match.homeInfo.en_name
                                : ""
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-1 align-center flex" style="height: 50%">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div
                            class="imgw4 van-image van-image--round"
                            itemprop="logo"
                            style="overflow: hidden; border-radius: 50%"
                          >
                            <img
                              alt="Uzbekistan"
                              class="van-image__img"
                              v-if="match.awayInfo"
                              :src="
                                match.awayInfo.logo
                                  ? match.awayInfo.logo
                                : bannerImagePath('default-team.png')
                              "
                              lazy="loaded"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                        <div
                          class="w-o-h flex align-center"
                          style="padding-left: 0.2rem; width: 5rem"
                        >
                          {{
                            this.$i18n.locale == "cn" && match.awayInfo.cn_name
                              ? match.awayInfo.cn_name
                              : match.awayInfo.en_name
                              ? match.awayInfo.en_name
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="icon-setting" style="">
                      <span
                        class="iconfont icon-donghuazhibo"
                        style="color: rgb(255, 168, 48)"
                      ></span>
                    </div>

                    <div class="teamScore" style="height: calc(1.38rem - 1px)">
                      <div
                        class="flex flex-col align-center"
                        style="
                          height: 100%;
                          color: rgb(153, 153, 153);
                          line-height: 12px;
                        "
                      ></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],

  data() {
    return {
      todayUpcomingMatches: null,
      loading: true,
      leagueFavoriteStatus: {}, // Store favorite status for each league
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
    };
  },
  created() {
    this.gettodayUpcomingMatches("upcoming");
  },
  methods: {
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        console.log("Today's Upcoming Match List:", this.todayMatchList);
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const competitionId = match.competition_id;
              const favoriteStatus = localStorage.getItem(
                `football_${competitionId}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`football_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].competition_id;
          const favoriteStatus = localStorage.getItem(`football_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },

    // method to get today matchlist
    gettodayUpcomingMatches(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayUpcomingMatches = response.data.footballMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

