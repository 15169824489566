<template>
  <div
    class="bk_players view border-box"
    data-v-e64882c8=""
    data-v-250f1619=""
    v-if="!loading"
  >
    <div class="playerheader" data-v-e64882c8="">
      <!-- begin::header -->
      <div class="backFil" data-v-e64882c8="">
        <div class="header" data-v-48b34d9b="" data-v-e64882c8="">
          <div class="van-sticky">
            <header
              class="first-header"
              style="background: #a64521"
              data-v-48b34d9b=""
            >
              <div
                class="inner h-bar-100 border-box van-row van-row--flex van-row--justify-space-between"
                data-v-48b34d9b=""
              >
                <div
                  class="left van-col van-col--4"
                  data-v-48b34d9b=""
                  @click="goBack"
                >
                  <span
                    class="icon iconfont myIcon iconback"
                    style="opacity: 0.6"
                    data-v-48b34d9b=""
                  ></span>
                </div>
                <div
                  class="proMod2 flex align-center justify-center van-col"
                  style="font-size: 0.4rem"
                  data-v-48b34d9b=""
                >
                  <span
                    class="formatClass"
                    style="opacity: 1"
                    data-v-48b34d9b=""
                    ><span data-v-48b34d9b=""
                      ><span data-v-48b34d9b=""></span>
                      <span data-v-48b34d9b=""></span></span
                  ></span>
                </div>
                <div class="right van-col van-col--4" data-v-48b34d9b=""></div>
              </div>
            </header>
          </div>
        </div>
      </div>

      <!-- end::header -->
      <!-- begin::player Info -->
      <div
        class="commonHeadInfo flex align-center justify-center flex-column"
        data-v-e64882c8=""
      >
        <div class="player_logo" style="position: relative" data-v-e64882c8="">
          <div
            class="player_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-e64882c8=""
          >
            <img
              :src="
                playerDetails && playerDetails.logo
                  ? playerDetails.logo + '!w60'
                  : ''
              "
              class="van-image__img"
              style="object-fit: cover"
            />
          </div>
        </div>
        <div class="player_name" data-v-e64882c8="">
          {{
            this.$i18n.locale == "cn" && playerDetails && playerDetails.cn_name
              ? playerDetails.cn_name
              : playerDetails.name
          }}
        </div>
        <!-- des -->
        <div class="des flex align-center justify-center" data-v-e64882c8="">
          <span class="division" data-v-e64882c8="">
            {{
              this.$i18n.locale == "cn" &&
              playerDetails &&
              playerDetails.teamDetails &&
              playerDetails.teamDetails.cn_name
                ? playerDetails.teamDetails.cn_name
                : playerDetails &&
                  playerDetails.teamDetails &&
                  playerDetails.teamDetails.name
                ? playerDetails.teamDetails.name
                : ""
            }}
            /
          </span>
          <span class="sj" style="line-height: 0.26rem" data-v-e64882c8="">
            {{ playerDetails?.position }}
          </span>
          <span style="color: #fff; font-size: 0.26rem" data-v-e64882c8=""
            >&nbsp;/&nbsp;</span
          >
          <div
            class="country_logo van-image van-image--round"
            style="overflow: hidden; border-radius: 50%"
            data-v-e64882c8=""
          >
            <img
              :src="
                playerDetails &&
                playerDetails.teamDetails &&
                playerDetails.teamDetails.countryDetails &&
                playerDetails.teamDetails.countryDetails.logo
                  ? playerDetails.teamDetails.countryDetails.logo + '!w60'
                  : bannerImagePath('default-nation.png')
              "
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span class="country" data-v-e64882c8=""
            ><template v-if="playerDetails && playerDetails.teamDetails">
              <template v-if="this.$i18n.locale == 'cn'">
                <template
                  v-if="
                    playerDetails.teamDetails.countryDetails &&
                    playerDetails.teamDetails.countryDetails.cn_name
                  "
                >
                  {{ playerDetails.teamDetails.countryDetails.cn_name }}
                </template>
                <template
                  v-else-if="
                    playerDetails.teamDetails.categoryDetails &&
                    playerDetails.teamDetails.categoryDetails.cn_name
                  "
                >
                  {{ playerDetails.teamDetails.categoryDetails.cn_name }}
                </template>
                <template
                  v-else-if="
                    playerDetails.teamDetails.countryDetails &&
                    playerDetails.teamDetails.countryDetails.name
                  "
                >
                  {{ playerDetails.teamDetails.countryDetails.name }}
                </template>
                <template
                  v-else-if="
                    playerDetails.teamDetails.categoryDetails &&
                    playerDetails.teamDetails.categoryDetails.name
                  "
                >
                  {{ playerDetails.teamDetails.categoryDetails.name }}
                </template>
              </template>
              <template v-else>
                <template
                  v-if="
                    playerDetails.teamDetails.countryDetails &&
                    playerDetails.teamDetails.countryDetails.name
                  "
                >
                  {{ playerDetails.teamDetails.countryDetails.name }}
                </template>
                <template
                  v-else-if="
                    playerDetails.teamDetails.categoryDetails &&
                    playerDetails.teamDetails.categoryDetails.name
                  "
                >
                  {{ playerDetails.teamDetails.categoryDetails.name }}
                </template>
                <template v-else> </template>
              </template>
              :
            </template></span
          >
        </div>
        <!-- end des -->
      </div>
      <!-- end::player Info -->
    </div>
    <!-- begin breadcrumb -->
    <div
      class="breadcrumb-box iconfont"
      style="background-color: "
      data-v-e49cc106=""
      data-v-e64882c8=""
    >
      <div class="display-i-l" data-v-e49cc106="">
        <a
          :href="'/' + this.$i18n.locale + '/live/basketball'"
          target=""
          class="ele link"
          data-v-e49cc106=""
          ><span data-v-e49cc106="">
            {{ $t("basketball.league-details.basketball-live-score") }}
          </span>
        </a>
      </div>
      <template
        v-if="
          playerDetails &&
          playerDetails.teamDetails &&
          playerDetails.teamDetails.leagueDetails
        "
      >
        <span class="jt" data-v-e49cc106=""></span>

        <div class="display-i-l" data-v-e49cc106="">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              playerDetails.leagueDetails.en_name
                .toLowerCase()
                .replace(/\s/g, '-') +
              '/' +
              playerDetails.teamDetails.leagueDetails.id
            "
            target=""
            class="ele link"
            data-v-e49cc106=""
            ><span data-v-e49cc106="">{{
              this.$i18n.locale == "cn" &&
              playerDetails.teamDetails.leagueDetails.cn_name
                ? playerDetails.teamDetails.leagueDetails.cn_name
                : playerDetails.teamDetails.leagueDetails.name
                ? playerDetails.teamDetails.leagueDetails.name
                : ""
            }}</span>
          </a>
        </div>
      </template>

      <template v-if="playerDetails && playerDetails.teamDetails">
        <span class="jt" data-v-e49cc106=""></span>

        <div class="display-i-l" data-v-e49cc106="">
          <a
            :href="
              '/' +
              this.$i18n.locale +
              '/basketball/team/details/' +
              playerDetails.teamDetails.name.toLowerCase().replace(/\s/g, '-') +
              playerDetails.teamDetails.id
            "
            target=""
            class="ele link"
            data-v-e49cc106=""
            ><span data-v-e49cc106="">{{
              this.$i18n.locale == "cn" && playerDetails.teamDetails.cn_name
                ? playerDetails.teamDetails.cn_name
                : playerDetails.teamDetails.name
                ? playerDetails.teamDetails.name
                : ""
            }}</span>
          </a>
        </div>
      </template>
      <span class="jt" data-v-e49cc106=""></span>

      <h1 class="ele text lastText" style="display: inline" data-v-e49cc106="">
        <span data-v-e49cc106="">
          {{
            (this.$i18n.locale == "cn" && playerDetails && playerDetails.cn_name
              ? playerDetails.cn_name
              : playerDetails.name) +
            " " +
            $t(
              "football.league-details.stats-age-height-contract-college-salary"
            )
          }}
        </span>
      </h1>
    </div>
    <div class="content" data-v-e64882c8="">
      <div class="bk_players" data-v-1631ea68="" data-v-e64882c8="">
        <div
          class="w100"
          data-v-1631ea68=""
          style="padding: 0.21rem 0.21rem 0 0.21rem"
        >
          <!-- begin::player info -->
          <div
            class="title"
            data-v-1631ea68=""
            style="
              border-top-style: solid;
              border-width: 2px;
              border-color: white;
            "
          >
            {{ $t("football.league-details.info") }}
          </div>
          <div class="infoContent" data-v-1631ea68="">
            <div
              class="row van-row"
              data-v-1631ea68=""
              style="min-height: 250px"
            >
              <div class="part part1 van-col van-col--12" data-v-1631ea68="">
                <div class="box" data-v-1631ea68="">
                  <div class="infoT" data-v-1631ea68="">
                    {{ $t("football.league-details.birthday") }}
                  </div>
                  <div class="text" data-v-1631ea68="">
                    {{ getBirthdayFromTimestamp(playerDetails?.birthday) }}
                  </div>
                </div>
              </div>
              <div class="part part2 van-col van-col--12" data-v-1631ea68="">
                <div class="box" data-v-1631ea68="">
                  <div class="infoT" data-v-1631ea68="">
                    {{ $t("football.league-details.position") }}
                  </div>
                  <div class="text" data-v-1631ea68="">
                    {{ playerDetails?.position }}
                  </div>
                </div>
              </div>
              <div class="part part3 van-col van-col--12" data-v-1631ea68="">
                <div class="box" data-v-1631ea68="">
                  <div class="infoT" data-v-1631ea68="">
                    {{ $t("football.league-details.height") }} /
                    {{ $t("football.league-details.weight") }}
                  </div>
                  <div class="text" data-v-1631ea68="">
                    {{ playerDetails?.height }} / {{ playerDetails?.weight }}
                  </div>
                </div>
              </div>
              <div class="part part4 van-col van-col--12" data-v-1631ea68="">
                <div class="box" data-v-1631ea68="">
                  <div class="infoT" data-v-1631ea68="">
                    {{ $t("football.league-details.salary") }}
                  </div>
                  <div class="text" data-v-1631ea68="">
                    $ {{ getSalaryHumanReadable(playerDetails?.salary) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="round" data-v-1631ea68="">
              <div class="round1" data-v-1631ea68="">
                <div
                  class="playerShirt van-image"
                  style="overflow: hidden; border-radius: 0px"
                  data-v-1631ea68=""
                >
                  <img
                    src="/images/basketball-shirt.png"
                    alt="imgalt"
                    class="van-image__img"
                    style="object-fit: contain"
                  />
                </div>
                <div class="shirtNumber" data-v-1631ea68="">
                  {{ playerDetails?.shirt_number }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MobileFooter />
  </div>
</template>
<script>
import dateUtils from "@/utils/dateUtils";
import metaDataUtils from "@/utils/metaDataUtils";
import numberUtils from "@/utils/numberUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [dateUtils, numberUtils, metaDataUtils, DynamicImagePath],
  data() {
    return {
      playerDetails: null,
      loading: true,
      playerId: null,
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.playerId;
    this.playerId = itemId;
    this.getPlayerDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    goBack() {
      this.$router.back();
    },
    // methpd to get meta tags
    getMetaData(playerId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/basketball/player/meta/` + playerId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getPlayerDetails(playerId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/basketball/player/details/${playerId}`
        )
        .then((response) => {
          this.playerDetails = response.data.playerDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormattedDate(timeStamp) {
      if (timeStamp != "") {
        const date = new Date(timeStamp);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
      }
      return "-";
    },
  },
};
</script>
