<template>
  <span class="mobile-version">
    <MobileLiveScoring />
  </span>
  <span class="desktop-version">
    <DesktopLiveScoring />
  </span>
</template>
<!-- begin::scripts -->
<script>
import DesktopLiveScoring from "./desktop-version/live-scoring.vue";
import MobileLiveScoring from "./mobile-version/live-scoring.vue";
export default {
  components: {
    DesktopLiveScoring,
    MobileLiveScoring
  },
};
</script>
