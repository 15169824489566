<template>
  <div class="content" data-v-59f3daaa="">
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="top: 10rem"
      data-v-59f3daaa=""
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-59f3daaa=""
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
      ></span>
    </div>
    <div
      v-else-if="noDataInAll"
      class="flex justify-center align-center flex-col"
      data-v-4291ef99=""
      style="height: 400px"
    >
      <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
      <div class="color-999 fs-12 mt-12">
        {{ $t("cricket.live-score.temporarily-no-data") }}
      </div>
    </div>
    <div v-else class="bk_stats w100" data-v-460a6726="" data-v-59f3daaa="">
      <div class="bk_statscomp" data-v-042072c0="" data-v-460a6726="">
        <div
          v-for="(propertyObject, index) in playerProperties"
          :key="index"
          class="w100 items"
          style=""
          data-v-042072c0=""
        >
          <div class="w100 text-center title" data-v-042072c0="">
            {{ propertyObject.name }}
          </div>
          <template v-if="!playerPropertyStats[propertyObject.property]">
            <div
              class="flex justify-center align-center flex-col"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </template>
          <template v-else-if="!propertyObject.viewAll">
            <template v-for="playerIndex in 3" :key="playerIndex">
              <div
                v-if="
                  playerPropertyStats[propertyObject.property][playerIndex - 1]
                "
                class="w100 flex align-center justify-between list_active2"
                data-v-042072c0=""
              >
                <div class="des" data-v-042072c0="">
                  <div
                    class="ind"
                    :class="
                      playerIndex == 1
                        ? 'first'
                        : playerIndex == 2
                        ? 'second'
                        : 'third'
                    "
                    data-v-042072c0=""
                  >
                    {{ playerIndex }}
                  </div>
                  <div
                    itemprop="logo"
                    class="myImg van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                    data-v-042072c0=""
                  >
                    <img
                      v-if="
                        playerPropertyStats[propertyObject.property][
                          playerIndex - 1
                        ]?.player?.id
                      "
                      :src="
                        'https://sportyclub.online//images/cricket/players/' +
                        playerPropertyStats[propertyObject.property][
                          playerIndex - 1
                        ]?.player?.id +
                        '/' +
                        playerPropertyStats[propertyObject.property][
                          playerIndex - 1
                        ]?.player?.id +
                        '.png'
                      "
                      alt=""
                      class="van-image__img"
                      style="
                        object-fit: cover;
                        background-color: rgb(210, 210, 210);
                      "
                      @error="$event.target.src = '/images/avatar.png'"
                    />
                    <img
                      v-else
                      src="
                      /images/avatar.png
                    "
                      alt=""
                      class="van-image__img"
                      style="
                        object-fit: cover;
                        background-color: rgb(210, 210, 210);
                      "
                    />
                  </div>
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/team/details' +
                      playerPropertyStats[propertyObject.property][
                        playerIndex - 1
                      ].player.name.toLowerCase().replace(/\s/g, '-') +
                      playerPropertyStats[propertyObject.property][
                        playerIndex - 1
                      ].player.id
                    "
                    class="flex"
                    data-v-042072c0=""
                  >
                    <div class="playerName" data-v-042072c0="">
                      {{
                        this.$i18n.locale == "cn" &&
                        playerPropertyStats[propertyObject.property][
                          playerIndex - 1
                        ].player.cn_name
                          ? playerPropertyStats[propertyObject.property][
                              playerIndex - 1
                            ].player.cn_name
                          : playerPropertyStats[propertyObject.property][
                              playerIndex - 1
                            ].player.name
                      }}
                    </div>
                  </a>
                </div>
                <div class="num" data-v-042072c0="">
                  {{
                    playerPropertyStats[propertyObject.property][
                      playerIndex - 1
                    ].statistics[propertyObject.property]
                  }}
                </div>
              </div>
            </template>

            <div
              v-if="playerPropertyStats[propertyObject.property].length > 3"
              class="w100 viewall"
              data-v-042072c0=""
            >
              <div
                @click="toggleViewAll(propertyObject.property)"
                class="viewallBtn"
                data-v-042072c0=""
              >
                {{ $t("football.league-details.show-more") }} &gt;
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(player, playerIndex) in playerPropertyStats[
                propertyObject.property
              ]"
              :key="playerIndex"
              class="w100 flex align-center justify-between list_active2"
              data-v-042072c0=""
            >
              <div class="des" data-v-042072c0="">
                <div
                  class="ind"
                  :class="
                    playerIndex + 1 == 1
                      ? 'first'
                      : playerIndex + 1 == 2
                      ? 'second'
                      : playerIndex + 1 == 3
                      ? 'third'
                      : 'playerName'
                  "
                  data-v-042072c0=""
                >
                  {{ playerIndex + 1 }}
                </div>
                <div
                  itemprop="logo"
                  class="myImg van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                  data-v-042072c0=""
                >
                  <img
                    v-if="
                      playerPropertyStats[propertyObject.property][playerIndex]
                        ?.player?.id
                    "
                    :src="
                      'https://sportyclub.online//images/cricket/players/' +
                      playerPropertyStats[propertyObject.property][playerIndex]
                        ?.player?.id +
                      '/' +
                      playerPropertyStats[propertyObject.property][playerIndex]
                        ?.player?.id +
                      '.png'
                    "
                    alt=""
                    class="van-image__img"
                    style="
                      object-fit: cover;
                      background-color: rgb(210, 210, 210);
                    "
                    @error="$event.target.src = '/images/avatar.png'"
                  />
                  <img
                    v-else
                    src="
                      /images/avatar.png
                    "
                    alt=""
                    class="van-image__img"
                    style="
                      object-fit: cover;
                      background-color: rgb(210, 210, 210);
                    "
                  />
                </div>
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/cricket/team/details/' +
                    playerPropertyStats[propertyObject.property][playerIndex]
                      .player.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    playerPropertyStats[propertyObject.property][playerIndex]
                      .player.id
                  "
                  class="flex"
                  data-v-042072c0=""
                  ><div class="playerName" data-v-042072c0="">
                    {{
                      this.$i18n.locale == "cn" &&
                      playerPropertyStats[propertyObject.property][playerIndex]
                        .player.cn_name
                        ? playerPropertyStats[propertyObject.property][
                            playerIndex
                          ].player.cn_name
                        : playerPropertyStats[propertyObject.property][
                            playerIndex
                          ].player.name
                    }}
                  </div>
                </a>
              </div>
              <!-- <div class="num" data-v-042072c0="">
                {{
                  playerPropertyStats[propertyObject.property][playerIndex]
                    .statistics[propertyObject.property]
                }}
              </div> -->
            </div>
            <div class="w100 viewall" data-v-042072c0="">
              <div
                @click="toggleViewAll(propertyObject.property)"
                class="viewallBtn"
                data-v-042072c0=""
              >
                {{ $t("football.league-details.show-less") }} &lt;
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Number,
      default: null,
    },
    seasonId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      noDataInAll: false,
      loading: true,
      leagueDetails: null,
      activeTab: "goals",
      playerPropertyStats: null,
      showLess: true,
      leagueTeamsPropertiesStats: null,
      playerProperties: [
        { viewAll: false, property: "runs_Scored", name: "Runs Scored" },
        { viewAll: false, property: "wickets", name: "Wickets" },
        { viewAll: false, property: "hundreds", name: "Most Centuries" },
        { viewAll: false, property: "fifties", name: "Most Fifties" },
        { viewAll: false, property: "fours", name: "Most Fours" },
        { viewAll: false, property: "sixes", name: "Most Sixes" },
      ],
      playersStats: null,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getPlayerStats(this.leagueId, this.seasonId);
  },
  watch: {
    seasonId: function (newSeasonId) {
      console.log("in watch");
      this.getPlayerStats(this.leagueId, newSeasonId);
    },
  },
  methods: {
    toggleViewAll(property) {
      let playerProperty = this.playerProperties.find(
        (obj) => obj.property === property
      );

      if (playerProperty) playerProperty.viewAll = !playerProperty.viewAll;
      // this.playerProperties.find(
      //   (obj) => obj.property === property
      // )[0].viewAll = !this.playerProperties.find(
      //   (obj) => obj.property === property
      // )[0].viewAll;
    },

    // method to get player stats
    getPlayerStats(leagueId, seasonId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/players/stats/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.playerPropertyStats = response.data.topPlayers;
          if (!this.playerPropertyStats) this.noDataInAll = true;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showAllRecords(state) {
      this.showLess = !state;
    },
  },
};
</script>
