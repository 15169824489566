<template>
  <div>
    <div class="v-modal" tabindex="0" style="z-index: 2000"></div>

    <div class="el-dialog__wrapper" style="z-index: 2001">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="dialog"
        class="el-dialog login-model"
        style="margin-top: 15vh; width: 420px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title"></span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i
              class="el-dialog__close el-icon el-icon-close"
              @click="close(null)"
            ></i>
          </button>
        </div>
        <div class="el-dialog__body">
          <div class="clearfix-row text-center">
            <span
              class="avatar"
              :style="`background:url(/images/avatar.png) no-repeat bottom #e9eaee`"
            ></span>
          </div>
          <div v-if="tab == 'signin'" class="clearfix-row text-center">
            <a class="sing-in-btn"> {{ $t("common.menu.signin") }} </a>
          </div>

          <div v-else-if="tab == 'signup'" class="clearfix-row text-center">
            <a class="sing-in-btn"> {{ $t("common.menu.signup") }} </a>
          </div>

          <div class="clearfix-row text-center mt-8">
            <a @click.prevent="() => (this.tab = 'signup')" class="fs-12">
              {{ $t("common.menu.create-new-account") }}
            </a>
            <span class="fs-12 color-999">
              {{ " " + $t("common.menu.or") + " " }}
            </span>
            <a @click.prevent="() => (this.tab = 'signin')" class="fs-12">{{
              $t("common.menu.login")
            }}</a>
          </div>

          <template v-if="tab == 'signin'">
            <div
              v-if="loading"
              class="clearfix-row view border-box"
              data-v-8948368c=""
            >
              <div class="detail view border-box" data-v-250f1619="">
                <div
                  class="loading van-loading van-loading--spinner van-loading--vertical"
                >
                  <span
                    class="van-loading__spinner van-loading__spinner--spinner"
                    style="justify-content: center"
                    ><img
                      src="/images/default-loader.gif"
                      style="width: 30% !important"
                      alt=""
                  /></span>
                </div>
                <div class="prefetch"></div>
              </div>
            </div>

            <div v-else class="clearfix-row mt-8">
              <form @submit.prevent="normalLogin">
                <div
                  v-if="signInAlert?.status"
                  class="alert alert-dismissible fade show"
                  :class="`alert-${signInAlert?.color}`"
                  role="alert"
                >
                  <strong>{{ $t("common.menu." + signInAlert?.text) }}</strong>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">{{
                    $t("common.menu.email")
                  }}</label>
                  <input
                    type="email"
                    v-model="user.email"
                    required
                    class="form-control"
                    :class="{
                      'is-invalid': signInErrors?.validation?.email
                        ? true
                        : false,
                    }"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div
                    v-if="signInErrors?.validation?.email"
                    class="invalid-feedback"
                  >
                    {{ signInErrors?.validation?.email[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">{{
                    $t("common.menu.password")
                  }}</label>
                  <input
                    v-model="user.password"
                    required
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn"
                    style="
                      color: #fff;
                      background-color: var(--main-text-color);
                      border-color: var(--main-text-color);
                    "
                  >
                    {{ $t("common.menu.signin") }}
                  </button>
                </div>
              </form>
            </div>
          </template>

          <template v-else-if="tab == 'signup'">
            <div
              v-if="registerLoading"
              class="clearfix-row view border-box"
              data-v-8948368c=""
            >
              <div class="detail view border-box" data-v-250f1619="">
                <div
                  class="loading van-loading van-loading--spinner van-loading--vertical"
                >
                  <span
                    class="van-loading__spinner van-loading__spinner--spinner"
                    style="justify-content: center"
                    ><img
                      src="/images/default-loader.gif"
                      style="width: 30% !important"
                      alt=""
                  /></span>
                </div>
                <div class="prefetch"></div>
              </div>
            </div>
            <div v-else class="clearfix-row mt-8">
              <form @submit.prevent="normalSignup">
                <div class="form-group">
                  <div
                    v-if="signUpAlert?.status"
                    class="alert alert-dismissible fade show"
                    :class="`alert-${signUpAlert?.color}`"
                    role="alert"
                  >
                    <strong>{{
                      $t("common.menu." + signUpAlert?.text)
                    }}</strong>
                  </div>
                  <label for="exampleInputname1">{{
                    $t("common.menu.name")
                  }}</label>
                  <input
                    type="text"
                    v-model="userRegister.name"
                    required
                    class="form-control"
                    id="exampleInputname1"
                    aria-describedby="nameHelp"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">{{
                    $t("common.menu.email")
                  }}</label>
                  <input
                    type="email"
                    v-model="userRegister.email"
                    :class="{
                      'is-invalid': signUpErrors?.validation?.email
                        ? true
                        : false,
                    }"
                    required
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div
                    v-if="signUpErrors?.validation?.email"
                    class="invalid-feedback"
                  >
                    {{ signUpErrors?.validation?.email[0] }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="exampleInputPassword1">{{
                    $t("common.menu.password")
                  }}</label>
                  <input
                    v-model="userRegister.password"
                    :class="{
                      'is-invalid': signUpErrors?.validation?.password
                        ? true
                        : false,
                    }"
                    type="password"
                    required
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                  <div
                    v-if="signUpErrors?.validation?.password"
                    class="invalid-feedback"
                  >
                    {{ signUpErrors?.validation?.password[0] }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="exampleInputPassword2">{{
                    $t("common.menu.confirm-password")
                  }}</label>
                  <input
                    v-model="userRegister.password_confirmation"
                    type="password"
                    required
                    class="form-control"
                    id="exampleInputPassword2"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn"
                    style="
                      color: #fff;
                      background-color: var(--main-text-color);
                      border-color: var(--main-text-color);
                    "
                  >
                    {{ $t("common.menu.signup") }}
                  </button>
                </div>
              </form>
            </div>
          </template>
          <div class="divider">
            <span class="or">or</span>
          </div>
          <div class="clearfix-row text-center mt-8">
            <!-- <a
              class="fb-login-button link facebook"
              xfbml="true"
              autologappevents="true"
              @click="loginGithub"
            >
              <i class="icon icon-facebook"></i>
              <span class="text"> Continue with github </span>
            </a> -->

            <a class="link google" @click="loginGoogle">
              <i class="icon icon-google"></i>

              <svg class="icon">
                <svg id="icon-google" viewBox="0 0 1024 1024">
                  <path
                    d="M0 0m73.142857 0l877.714286 0q73.142857 0 73.142857 73.142857l0 877.714286q0 73.142857-73.142857 73.142857l-877.714286 0q-73.142857 0-73.142857-73.142857l0-877.714286q0-73.142857 73.142857-73.142857Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M127.817143 318.098286c69.229714-140.909714 221.366857-237.385143 378.806857-236.470857 107.300571-3.218286 215.808 37.083429 293.229714 111.725714a27835.977143 27835.977143 0 0 0-121.197714 118.052571c-46.921143-41.472-108.982857-66.048-172.032-62.939428-107.52-0.182857-206.482286 76.946286-240.128 177.773714-46.445714-35.803429-93.184-71.204571-138.678857-108.141714z"
                    fill="#EB4F43"
                  ></path>
                  <path
                    d="M127.597714 705.718857c-61.549714-119.332571-61.915429-268.470857 0.219429-387.657143 45.494857 36.937143 92.233143 72.411429 138.678857 108.178286a272.128 272.128 0 0 0 0.109714 171.373714 11985.92 11985.92 0 0 0-139.008 108.105143z"
                    fill="#FBC017"
                  ></path>
                  <path
                    d="M512 433.883429c134.546286 0.146286 269.092571-0.731429 403.565714 0.365714 30.537143 142.372571-9.069714 304.676571-118.820571 404.297143-44.617143-35.584-90.770286-69.302857-134.948572-105.472 44.690286-33.572571 78.957714-83.236571 86.930286-139.190857-78.848-0.109714-157.732571 0.109714-236.617143-0.109715-0.256-53.284571-0.073143-106.569143-0.073143-159.890285z"
                    fill="#4889F3"
                  ></path>
                  <path
                    d="M127.597714 705.718857a11985.92 11985.92 0 0 1 138.971429-108.105143c19.2 54.966857 55.844571 103.862857 104.484571 136.082286 85.357714 57.563429 205.641143 57.051429 290.742857-0.621714 44.178286 36.169143 90.331429 69.888 134.948572 105.472-90.550857 85.650286-222.829714 116.150857-344.246857 100.096-137.874286-17.92-263.533714-108.361143-324.900572-232.923429z"
                    fill="#3CAB5B"
                  ></path>
                </svg>
              </svg>

              <span class="text">
                {{ $t("common.menu.continue-with-google") }}
              </span>
            </a>
            <div class="error-box"></div>
          </div>
        </div>
        <!---->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SinginDialogDesktop",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      userRegister: {
        email: "",
        password: "",
        name: "",
        password_confirmation: "",
      },
      error: false,
      loading: false,
      registerLoading: false,
      tab: "signin",
      signInErrors: {
        validation: {},
        credentials: {},
      },
      signUpErrors: {
        validation: {},
      },
      signInAlert: {},
      signUpAlert: {},
    };
  },
  mounted() {
    window.addEventListener("message", this.onMessage, false);
    // this.getCsrf();
  },

  beforeUnmount() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    switchToSignup() {
      this.tab = "signup";
    },
    close(data) {
      this.$emit("closeSinginDialog", data ?? null);
    },

    loginGithub() {
      const newWindow = openWindow("", "message");
      this.$axios
        .get(this.BASE_SERVER_URI + "/api/auth/github")
        .then((response) => {
          console.log(response);
          newWindow.location.href = response.data.url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    loginGoogle() {
      const newWindow = openWindow("", "message");
      this.$axios
        .get(this.BASE_SERVER_URI + "/api/auth/google")
        .then((response) => {
          console.log(response);
          newWindow.location.href = response.data.url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    // getCsrf() {
    //   this.$axios
    //     .get(this.BASE_SERVER_URI + "/sanctum/csrf-cookie", {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       this.normalLogin();
    //       console.log(response);
    //     })
    //     .catch((e) => console.log(e));
    // },
    normalLogin() {
      this.loading = true;
      this.signInErrors.credentials = {};
      this.signInErrors.validation = {};
      this.$axios
        .post(
          this.BASE_SERVER_URI + "/api/login",
          {
            email: this.user.email,
            password: this.user.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("apiToken", res.data.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          this.showSignInAlert(
            {
              text: res.data.msg,
              color: "success",
              status: true,
            },
            () => {
              this.close(res.data.user);
            }
          );
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.signInErrors.credentials = e.response.data;
            // show alert and remove it after time

            this.showSignInAlert({
              text: e.response.data.msg,
              color: "danger",
              status: true,
            });
            this.user = {
              email: "",
              password: "",
            };
          } else if (e.response.status === 422) {
            this.signInErrors.validation = e.response.data.errors;
            e.value = e.response.data.errors;
          }
        })
        .finally(() => (this.loading = false));
    },

    normalSignup() {
      this.registerLoading = true;
      this.signUpErrors.validation = {};
      this.$axios
        .post(
          this.BASE_SERVER_URI + "/api/register",
          {
            name: this.userRegister.name,
            email: this.userRegister.email,
            password: this.userRegister.password,
            password_confirmation: this.userRegister.password_confirmation,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.userRegister = {
            email: "",
            password: "",
            name: "",
            password_confirmation: "",
          };
          this.showSignUpAlert(
            {
              text: res.data.msg,
              color: "success",
              status: true,
            },
            () => (this.tab = "signin")
          );
        })
        .catch((e) => {
          if (e.response.status === 422) {
            this.signUpErrors.validation = e.response.data.errors;
            e.value = e.response.data.errors;
          }
          console.log(e);
        })
        .finally(() => (this.registerLoading = false));
    },
    // This method save the new token and username
    onMessage(e) {
      if (
        e.origin !== this.BASE_SERVER_URI ||
        !e.data.name ||
        !e.data.email ||
        !e.data.avatar
      ) {
        return;
      }
      localStorage.setItem("userInfo", JSON.stringify(e.data));
      this.close(e.data);
    },
    showSignInAlert(alertObject, doSomethingAfterTimeout) {
      this.signInAlert = alertObject;
      setTimeout(() => {
        this.signInAlert = {};
        if (typeof doSomethingAfterTimeout === "function")
          doSomethingAfterTimeout();
      }, 2000);
    },
    showSignUpAlert(alertObject, doSomethingAfterTimeout) {
      this.signUpAlert = alertObject;
      setTimeout(() => {
        this.signUpAlert = {};
        if (typeof doSomethingAfterTimeout === "function")
          doSomethingAfterTimeout();
      }, 2000);
    },
  },
};
function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }

  options = { url, title, width: 600, height: 720, ...options };

  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;

  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");

  const newWindow = window.open(url, title, optionsStr);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
</script>

<style>
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 1rem;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button,
input {
  overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  overflow: visible;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.divider {
  text-align: center;
  margin-top: 20px;
  position: relative;
}

.divider:before,
.divider:after {
  content: "";
  display: inline-block;
  width: 45%;
  border-top: 1px solid #ccc;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.divider:before {
  right: 5%;
}

.divider:after {
  left: 5%;
}

.or {
  display: inline-block;
  background-color: #fff;
  position: relative;
  font-weight: bold;
}
</style>