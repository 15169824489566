<template>
  <span
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
  >
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.live-score.search-results") }}
      </span>
    </div>
  </span>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    data-v-abef72c2=""
    class="flex justify-center align-center flex-col contentBox"
    style="height: 400px"
  >
    <img data-v-abef72c2="" src="/images/no-data-search.png" class="nodata" />
    <div data-v-abef72c2="" class="color-999 noDataText">
      {{ $t("football.live-score.nothing-found") }}
    </div>
  </div>


  <!-- live search result -->
  <template v-else>

    <div style="min-height: 500px">
      <div class="w100" v-for="(league, key) in searchMatchListLive" :key="key">
        <div class="list_active" style="width: 100%">
          <!-- begin::show league & country -->
          <div class="list_title">
            &nbsp;
            <i class="iconfont" style="visibility: hidden"></i>
            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
            >
              <div
                class="listImg van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  class="van-image__img"
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.logo) ||
                              (league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name) ||
                              (league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name))                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>
              <span class="countryText">
                <template v-if="league[0] && league[0].leagueInfo">
                  <template v-if="this.$i18n.locale == 'cn'">
                    <template
                      v-if="
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.cn_name }}:
                    </template>
                    <template
                      v-else-if="
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                    </template>
                    <template
                      v-else-if="
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.name }}:
                    </template>
                    <template
                      v-else-if="
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.name }}:
                    </template>
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.name }}:
                    </template>
                    <template
                      v-else-if="
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.name }}:
                    </template>
                    <template v-else> </template>
                  </template>
                </template>
              </span>

              <a
                v-if="
                  league &&
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo &&
                  league[0].hasLeagueDetails
                "
                  :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "

                class="compText"
                style="font-size: 0.32rem"
              >
                {{
                  this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                    ? league[0].leagueInfo.cn_name
                    : league[0].leagueInfo.en_name
                    ? league[0].leagueInfo.en_name
                    : ""
                }}
              </a>
              <a v-else class="compText">
                {{
                  this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                    ? league[0].leagueInfo.cn_name
                    : league[0].leagueInfo.en_name
                    ? league[0].leagueInfo.en_name
                    : ""
                }}
              </a>
            </div>
          </div>
          <!-- end::show league & country -->
          <!-- begin::match info -->
          <div class="w100" v-for="match in league" :key="match.id">
            <div class="list" style="height: 1.38rem">
              <div class="flex flex-1 contentBox" style="height: 100%">
                <a
                :href="
                                '/' +
                                this.$i18n.locale +
                                '/basketball/match/details/' + match.homeInfo.en_name.toLowerCase() .replace(/\s/g, '-') + '-vs-'+ match.awayInfo.en_name.toLowerCase() .replace(/\s/g, '-') +  '/' +
                                match.id
                              "
                  class="flex allBox"
                >
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ readableTime(this.$i18n.locale, match.match_time) }}
                      </span>
                      <div class="flex fs-12 align-center color-r text-center">
                        <span class="fs-12"
                          >{{
                            match.status_id &&
                            this.FinishedMatches.includes(match.status_id)
                              ? $t("football.league-details.full-time")
                              : match?.statusDescription
                          }}
                        </span>

                        <span
                          v-if="match && match.counter_timing"
                          class="fs-12"
                        >
                          -{{ match.counter_timing }}
                        </span>
                      </div>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex" style="height: 50%">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div
                            class="imgw4 van-image van-image--round"
                            itemprop="logo"
                            style="overflow: hidden; border-radius: 50%"
                          >
                            <img
                              alt="Uzbekistan"
                              class="van-image__img"
                              v-if="match.homeInfo"
                              :src="
                                match.homeInfo.logo
                                  ? match.homeInfo.logo
                                : bannerImagePath('default-team.png')
                              "
                              lazy="loaded"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                        <div
                          class="w-o-h flex align-center h100"
                          style="padding-left: 0.2rem; width: 5rem"
                        >
                          <div
                            itemprop="homeTeam"
                            class="w-o-h h100 fs-14"
                            style="margin-right: 0.1rem; max-width: 4.5rem"
                          >
                            <template v-if="match && match.homeInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.homeInfo.cn_name
                                  ? match.homeInfo.cn_name
                                  : match.homeInfo.en_name
                                  ? match.homeInfo.en_name
                                  : ""
                              }}
                            </template>
                          </div>
                        </div>
                      </div>
                      <div class="flex-1 align-center flex" style="height: 50%">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div
                            class="imgw4 van-image van-image--round"
                            itemprop="logo"
                            style="overflow: hidden; border-radius: 50%"
                          >
                            <img
                              alt="Uzbekistan"
                              class="van-image__img"
                              v-if="match.awayInfo"
                              :src="
                                match.awayInfo.logo
                                  ? match.awayInfo.logo
                                : bannerImagePath('default-team.png')
                              "
                              lazy="loaded"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                        <div
                          class="w-o-h flex align-center"
                          style="padding-left: 0.2rem; width: 5rem"
                        >
                          <template v-if="match && match.awayInfo">
                            {{
                              this.$i18n.locale == "cn" &&
                              match.awayInfo.cn_name
                                ? match.awayInfo.cn_name
                                : match.awayInfo.en_name
                                ? match.awayInfo.en_name
                                : ""
                            }}
                          </template>
                        </div>
                      </div>
                    </div>
                    <div
                      class="icon-setting"
                      v-if="liveMatches.includes(match.status_id)"
                    >
                      <span class="iconfont icon-shipinzhibo"></span>
                    </div>

                    <div class="teamScore" style="height: calc(1.38rem - 1px)">
                      <div
                        class="flex flex-col align-center"
                        style="
                          height: 100%;
                          line-height: 12px;
                          padding: 5px 0px;
                        "
                      >
                        <div
                          class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                          style="font-family: Roboto-Regular, Roboto"
                        >
                          <div
                            class="flex-1 text-center scoreWeight"
                            style="
                              font-family: Roboto-Regular, Roboto;
                              font-weight: bolder;
                            "
                          >
                            <span class="scoreRed" style="font-size: 0.32rem">
                              {{
                                (liveMatches.includes(match.status_id) ||
                                  FinishedMatches.includes(match.status_id)) &&
                                match &&
                                match.homeInfo
                                  ? addSectionsScore(
                                      match.homeInfo.section_1,
                                      match.homeInfo.section_2,
                                      match.homeInfo.section_3,
                                      match.homeInfo.section_4
                                    )
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                          style="font-family: Roboto-Regular, Roboto"
                        >
                          <div
                            class="flex-1 text-center"
                            style="
                              font-family: Roboto-Regular, Roboto;
                              font-weight: bolder;
                            "
                          >
                            <span class="scoreRed" style="font-size: 0.32rem">
                              {{
                                (liveMatches.includes(match.status_id) ||
                                  FinishedMatches.includes(match.status_id)) &&
                                match &&
                                match.awayInfo
                                  ? addSectionsScore(
                                      match.awayInfo.section_1,
                                      match.awayInfo.section_2,
                                      match.awayInfo.section_3,
                                      match.awayInfo.section_4
                                    )
                                  : ""
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- end::match info -->
        </div>
      </div>

      <!-- upcoming search result -->
      <div class="w100">
        <div
          class="vue-recycle-scroller__item-view"
          style="transform: translateY(0px)"
        >
          <div
            class="w100"
            v-for="(league, key) in searchMatchListUpcoming"
            :key="key"
          >
            <div class="list_active" style="width: 100%">
              <div class="list_title">
                &nbsp;
                <i class="iconfont" style="visibility: visible"></i>
                <div
                  class="flex align-center"
                  style="overflow: hidden; width: 8.4rem"
                >
                  <div
                    class="listImg van-image van-image--round"
                    itemprop="logo"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      class="van-image__img"
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo
                      "
                      :src="
                        league[0].leagueInfo.countryDetails.logo
                          ? league[0].leagueInfo.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      alt=""
                      lazy="loaded"
                      style="object-fit: contain"
                    />
                  </div>
                  <span class="countryText" v-if="this.$i18n.locale == 'cn'">
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.cn_name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                    </span>
                  </span>
                  <span class="countryText" v-else>
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.name }}:
                    </span>
                  </span>
                  <a
                    v-if="league[0].hasLeagueDetails"
                    :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "

                    class="compText"
                    style="font-size: 0.32rem"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                  <a v-else class="compText" style="font-size: 0.32rem">
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                </div>
              </div>
              <div class="w100" v-for="match in league" :key="match.id">
                <div class="list" style="height: 1.38rem">
                  <div class="flex flex-1 contentBox">
                    <a
                    v-if="match && match.homeInfo && match.awayInfo"
                    :href="
                                '/' +
                                this.$i18n.locale +
                                '/basketball/match/details/' + match.homeInfo.en_name.toLowerCase() .replace(/\s/g, '-') + '-vs-'+ match.awayInfo.en_name.toLowerCase() .replace(/\s/g, '-') +  '/' +
                                match.id
                              "
                      class="flex allBox"
                    >
                      <div class="w100 flex h100 align-center">
                        <div
                          class="flex align-center justify-center listBox flex-column"
                        >
                          <span
                            itemprop="startDate"
                            class="fs-12 text-center"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{
                              readableTime(this.$i18n.locale, match.match_time)
                            }}
                          </span>
                          <span
                            class="fs-12 color-999 van-ellipsis"
                            style="max-width: 1.6rem"
                          >
                          </span>
                        </div>
                        <div class="team flex flex-col padding5">
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  alt="Uzbekistan"
                                  class="van-image__img"
                                  v-if="match.homeInfo"
                                  :src="
                                    match.homeInfo.logo
                                      ? match.homeInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center h100"
                              style="padding-left: 0.2rem; width: 5rem"
                            >
                              <div
                                itemprop="homeTeam"
                                class="w-o-h h100 fs-14"
                                style="margin-right: 0.1rem; max-width: 4.5rem"
                              >
                                <span v-if="this.$i18n.locale == 'cn'">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.cn_name
                                      ? match.homeInfo.cn_name
                                      : ""
                                  }}
                                </span>
                                <span v-else>
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.en_name
                                      ? match.homeInfo.en_name
                                      : ""
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  alt="Uzbekistan"
                                  class="van-image__img"
                                  v-if="match.awayInfo"
                                  :src="
                                    match.awayInfo.logo
                                      ? match.awayInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center"
                              style="padding-left: 0.2rem; width: 5rem"
                            >
                              <span v-if="this.$i18n.locale == 'cn'">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.cn_name
                                    ? match.awayInfo.cn_name
                                    : ""
                                }}
                              </span>
                              <span v-else>
                                {{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.en_name
                                    ? match.awayInfo.en_name
                                    : ""
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="icon-setting" style="">
                          <span
                            class="iconfont icon-donghuazhibo"
                            style="color: rgb(255, 168, 48)"
                          ></span>
                        </div>

                        <div
                          class="teamScore"
                          style="height: calc(1.38rem - 1px)"
                        >
                          <div
                            class="flex flex-col align-center"
                            style="
                              height: 100%;
                              color: rgb(153, 153, 153);
                              line-height: 12px;
                            "
                          >
                            <div class="flex oddDataBox">
                              <div
                                class="flex align-center"
                                style="flex: 1 1 0%; font-size: 0.32rem"
                              >
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match?.odds?.euro[2]).toFixed(
                                        2
                                      )
                                    : "-"
                                }}
                              </div>
                              <div
                                class="flex align-center"
                                style="flex: 1 1 0%; font-size: 0.32rem"
                              >
                                {{
                                  match.odds && match.odds.euro
                                    ? parseFloat(match?.odds?.euro[4]).toFixed(
                                        2
                                      )
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],
  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      liveMatches: [2, 3, 4, 5, 6, 7, 8, 9],
      FinishedMatches: [10],
    };
  },
  created() {},
  computed: {},
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    addSectionsScore(section_1, section_2, section_3, section_4) {
      return section_1 + section_2 + section_3 + section_4;
    },

  },
};
</script>
