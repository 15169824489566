<template>
  <div
    class="football view border-box"
    data-v-250f1619=""
    style="min-height: 0px !important"
  >
    <div style="display: ">
      <div class="homeHeader" data-v-736e8afd="">
        <div class="screenBox flex align-center" data-v-736e8afd="">
          <!-- begin::tabs -->
          <div class="changTabBox flex-1" data-v-736e8afd="">
            <span
              class="changeItem bh"
              data-v-736e8afd=""
              :class="liveActivebtn == 'all' ? 'activeElsTab' : 'notActiveTab'"
              v-on:click="getTodayMatchList('all')"
            >
              {{ $t("football.live-score.all") }}
            </span>
            <span
              class="changeItem bh ml-6"
              data-v-736e8afd=""
              :class="
                liveActivebtn == 'live' ? 'activeLiveTab' : 'notActiveTab'
              "
              ><span
                class="liveContentBox"
                data-v-736e8afd=""
                v-on:click="getTodayMatchList('live')"
                ><i class="iconfont icon-jinhangzhong" data-v-736e8afd=""></i>
                {{ $t("football.live-score.live") }}
              </span></span
            >
            <span
              :class="
                liveActivebtn == 'finished' ? 'activeElsTab' : 'notActiveTab'
              "
              v-on:click="getTodayMatchList('finished')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.finished") }}
            </span>
            <span
              :class="
                liveActivebtn == 'upcoming' ? 'activeElsTab' : 'notActiveTab'
              "
              v-on:click="getTodayMatchList('upcoming')"
              class="changeItem bh ml-6"
              data-v-736e8afd=""
            >
              {{ $t("football.live-score.soon") }}
            </span>
          </div>
          <!-- sort matches based on time -->
          <!-- end::tabs -->
        </div>
      </div>
      <SearchMatchList
        v-if="searchListUpcoming || searchListLive"
        :searchMatchListUpcoming="searchListUpcoming"
        :searchMatchListLive="searchListLive"
      />
      <template v-if="!searchListUpcoming && !searchListLive">
        <div class="detail view border-box" data-v-250f1619="" v-if="loading">
          <div
            class="loading van-loading van-loading--spinner van-loading--vertical"
          >
            <span class="van-loading__spinner van-loading__spinner--spinner"
              ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
              ><i></i><i></i><i></i
            ></span>
          </div>
        </div>
        <div
          v-if="countLiveMatches == 0"
          data-v-abef72c2=""
          class="flex justify-center align-center flex-col contentBox"
          style="height: 3.5rem"
        >
          <img
            data-v-abef72c2=""
            :src="bannerImagePath('default-container.png')"
            class="nodata"
          />
          <!---->
          <div data-v-abef72c2="" class="color-999 noDataText">
            {{ $t("football.league-details.no-live-match") }}
          </div>
        </div>
        <div
          class="w100"
          v-for="(league, key) in todayMatchList"
          :key="key"
          style="cursor: pointer"
        >
          <div class="list_active" style="width: 100%">
            <!-- begin::show league & country -->
            <div
              class="list_title"
              :class="
                leagueFavoriteStatus[league[0].competition_id]
                  ? 'collectComp'
                  : ''
              "
            >
              &nbsp;
              <i
                class="iconfont"
                :class="
                  leagueFavoriteStatus[league[0].competition_id]
                    ? 'icon-yishoucang'
                    : 'icon-weishoucang'
                "
                @click="toggleFavorite(league[0].competition_id)"
                style="visibility: visible"
              ></i>
              <div
                class="flex align-center"
                style="overflow: hidden; width: 8.4rem"
              >
                <div
                  class="listImg van-image van-image--round"
                  itemprop="logo"
                  style="overflow: hidden; border-radius: 50%"
                >
                  <img
                    class="van-image__img"
                    v-if="
                      league[0] &&
                      league[0].leagueInfo &&
                      ((league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo) ||
                        (league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name) ||
                        (league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name))
                    "
                    :src="
                      league[0] &&
                      league[0].leagueInfo &&
                      league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo
                        ? league[0].leagueInfo.countryDetails.logo
                       : bannerImagePath('default-country.png')
                    "
                    alt=""
                    lazy="loaded"
                    style="object-fit: contain"
                  />
                </div>
                <span class="countryText">
                  <template v-if="league[0] && league[0].leagueInfo">
                    <template v-if="this.$i18n.locale == 'cn'">
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                      <template v-else> </template>
                    </template>
                  </template>
                </span>
                <a
                  v-if="
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].hasLeagueDetails
                  "
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/league/details/' +
                    league[0].leagueInfo.en_name
                      .toLowerCase()
                      .replace(/\s/g, '-') +
                    '/' +
                    league[0].competition_id
                  "
                  class="compText"
                  style="font-size: 0.32rem"
                >
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
                <a v-else class="compText">
                  {{
                    this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                      ? league[0].leagueInfo.cn_name
                      : league[0].leagueInfo.en_name
                      ? league[0].leagueInfo.en_name
                      : ""
                  }}
                </a>
              </div>
            </div>
            <!-- end::show league & country -->
            <!-- begin::match info -->
            <div class="w100" v-for="match in league" :key="match.id">
              <div class="list" style="height: 1.38rem">
                <div class="flex flex-1 contentBox" style="height: 100%">
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/football/match/details/' +
                      match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                      '-vs-' +
                      match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                      '/' +
                      match.id
                    "
                    class="flex allBox"
                  >
                    <div class="w100 flex h100 align-center">
                      <div
                        class="flex align-center justify-center listBox flex-column"
                      >
                        <span
                          class="fs-12 text-center"
                          style="color: rgb(153, 153, 153)"
                        >
                          {{
                            readableTime(this.$i18n.locale, match.match_time)
                          }}
                        </span>
                        <span
                          class="fs-12 color-999 van-ellipsis color-r"
                          style="max-width: 1.6rem"
                          v-if="liveMatches.includes(match.status_id)"
                        >
                          {{
                            onGoingMatchTime(
                              match.counter_timing,
                              match.status_id
                            )
                          }}<span class="Twinkle">'</span></span
                        >
                      </div>
                      <div class="team flex flex-col padding5">
                        <div
                          class="flex-1 align-center flex"
                          style="height: 50%"
                        >
                          <div class="flex align-center" style="width: 0.4rem">
                            <div
                              class="imgw4 van-image van-image--round"
                              itemprop="logo"
                              style="overflow: hidden; border-radius: 50%"
                            >
                              <img
                                alt="Uzbekistan"
                                class="van-image__img"
                                v-if="match.homeInfo"
                                :src="
                                  match.homeInfo.logo
                                    ? match.homeInfo.logo
                                  : bannerImagePath('default-team.png')
                                "
                                lazy="loaded"
                                style="object-fit: contain"
                              />
                            </div>
                          </div>
                          <div
                            class="w-o-h flex align-center h100"
                            style="padding-left: 0.2rem; width: 5rem"
                          >
                            <div
                              itemprop="homeTeam"
                              class="w-o-h h100 fs-14"
                              style="margin-right: 0.1rem; max-width: 4.5rem"
                            >
                              <template v-if="match && match.homeInfo">
                                {{
                                  this.$i18n.locale == "cn" &&
                                  match.homeInfo.cn_name
                                    ? match.homeInfo.cn_name
                                    : match.homeInfo.en_name
                                    ? match.homeInfo.en_name
                                    : ""
                                }}
                              </template>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex-1 align-center flex"
                          style="height: 50%"
                        >
                          <div class="flex align-center" style="width: 0.4rem">
                            <div
                              class="imgw4 van-image van-image--round"
                              itemprop="logo"
                              style="overflow: hidden; border-radius: 50%"
                            >
                              <img
                                alt="Uzbekistan"
                                class="van-image__img"
                                v-if="match.awayInfo"
                                :src="
                                  match.awayInfo.logo
                                    ? match.awayInfo.logo
                                  : bannerImagePath('default-team.png')
                                "
                                lazy="loaded"
                                style="object-fit: contain"
                              />
                            </div>
                          </div>
                          <div
                            class="w-o-h flex align-center"
                            style="padding-left: 0.2rem; width: 5rem"
                          >
                            <template v-if="match && match.awayInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.awayInfo.cn_name
                                  ? match.awayInfo.cn_name
                                  : match.awayInfo.en_name
                                  ? match.awayInfo.en_name
                                  : ""
                              }}
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="icon-setting"
                        v-if="liveMatches.includes(match.status_id)"
                      >
                        <span class="iconfont icon-shipinzhibo"></span>
                      </div>

                      <div
                        class="teamScore"
                        style="height: calc(1.38rem - 1px)"
                      >
                        <div
                          class="flex flex-col align-center"
                          style="
                            height: 100%;
                            line-height: 12px;
                            padding: 5px 0px;
                          "
                        >
                          <div
                            class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div
                              class="flex-1 text-center scoreWeight"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                font-weight: bolder;
                              "
                            >
                              <span class="scoreRed" style="font-size: 0.32rem">
                                {{
                                  (liveMatches.includes(match.status_id) ||
                                    FinishedMatches.includes(
                                      match.status_id
                                    )) &&
                                  match &&
                                  match.homeInfo
                                    ? match.homeInfo.home_score
                                    : ""
                                }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div
                              class="flex-1 text-center"
                              style="
                                font-family: Roboto-Regular, Roboto;
                                font-weight: bolder;
                              "
                            >
                              <span class="scoreRed" style="font-size: 0.32rem">
                                {{
                                  (liveMatches.includes(match.status_id) ||
                                    FinishedMatches.includes(
                                      match.status_id
                                    )) &&
                                  match &&
                                  match.awayInfo
                                    ? match.awayInfo.away_score
                                    : ""
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- end::match info -->
          </div>
        </div>
        <!-- begin::upcoming -->
        <UpcomingMatches />
        <!-- end::upcoming -->
      </template>
    </div>
  </div>
  <!--begin::footer -->
  <MobileFooter />
  <!-- end::footter -->
</template>
<!-- begin::scripts -->
<script>
import DateMixin from "@/utils/dateUtils";
import UpcomingMatches from "./tabs/upcoming-matches.vue";
import SearchMatchList from "./tabs/search-matches-list";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],
  components: {
    UpcomingMatches,
    SearchMatchList,
  },
  data() {
    return {
      searchListUpcoming: null,
      searchListLive: null,
      todayUpcomingMatchList: null,
      todayMatchList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "live",
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
      leagueFavoriteStatus: {}, // Store favorite status for each league
    };
  },
  created() {
    this.getTodayMatchList(this.liveActivebtn);
    this.getUpcomingMatchList();
  },
  mounted() {
    this.emitter.on("searchText", ({ text }) => {
      console.log(text);
      if (text === "") {
        this.searchListUpcoming = null;
        this.searchListLive = null;
      } else if (text && this.todayMatchList && this.todayUpcomingMatchList) {
        let result1 = [];
        let result2 = [];
        Object.entries(this.todayMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;

            if (inTeam)
              result1.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );

        Object.entries(this.todayUpcomingMatchList).forEach(
          ([leagueName, matchesArray]) => {
            let inTeam =
              matchesArray.filter((matchObject) => {
                if (
                  this.$i18n?.locale === "cn" &&
                  matchObject.awayInfo.cn_name &&
                  matchObject.homeInfo.cn_name &&
                  matchObject.leagueInfo.cn_name
                ) {
                  return (
                    matchObject.awayInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.cn_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                } else {
                  return (
                    matchObject.awayInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.homeInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase()) ||
                    matchObject.leagueInfo.en_name
                      .toLowerCase()
                      .includes(text.toLowerCase())
                  );
                }
              }).length > 0;
            if (inTeam)
              result2.push([
                leagueName,
                matchesArray.filter((matchObject) => {
                  if (
                    this.$i18n?.locale === "cn" &&
                    matchObject.awayInfo.cn_name &&
                    matchObject.homeInfo.cn_name &&
                    matchObject.leagueInfo.cn_name
                  ) {
                    return (
                      matchObject.awayInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.cn_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  } else {
                    return (
                      matchObject.awayInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.homeInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                      matchObject.leagueInfo.en_name
                        .toLowerCase()
                        .includes(text.toLowerCase())
                    );
                  }
                }),
              ]);
          }
        );
        this.searchListLive = Object.fromEntries(result1);
        this.searchListUpcoming = Object.fromEntries(result2);
      }
    });
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        console.log("Today's Upcoming Match List:", this.todayMatchList);
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const competitionId = match.competition_id;
              const favoriteStatus = localStorage.getItem(
                `football_${competitionId}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  competitionId,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`football_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].competition_id;
          const favoriteStatus = localStorage.getItem(`football_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get today matchlist
    getTodayMatchList(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.footballMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loading = false;
          this.loadFavoriteStatus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
 

    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);

      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }

      if (status_code == 3) {
        return "HT";
      }

      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }

      if (this.liveMatches.includes(status_code)) {
        return "90+";
      }
    },
    getUpcomingMatchList() {
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=upcoming`
        )
        .then((response) => {
          this.todayUpcomingMatchList = response.data.footballMatchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
