<template>
  <div id="__layout">
    <div data-v-250f1619="" id="app" class="font-en">
      <div
        data-v-6efd1af3=""
        data-v-250f1619=""
        class="privacy-view view border-box"
      >
        <p
          data-v-6efd1af3=""
          class="t1 text-center"
          v-if="this.$i18n.locale == 'cn'"
        >
          条款和条件
        </p>
        <p data-v-6efd1af3="" class="t1 text-center" v-else>
          TERMS AND CONDITIONS
        </p>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          重要信息和我们是谁
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>
          IMPORTANT INFORMATION AND WHO WE ARE
        </p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          这些条款和条件概述了规则和条例 使用 Sportyclub
          Live。通过访问本网站，我们假设您 接受这些条款和条件。不要继续使用
          Sportyclub 如果您不同意接受所有条款和条件，则继续生存
          本页注明。以下术语适用于这些条款 和条件、隐私声明和免责声明以及所有
          协议：“客户”、“您”和“您的”是指您，即登录者
          本网站并遵守公司的条款和条件。 “这
          公司”、“我们自己”、“我们”、“我们的”和“我们的”，均指我们公司。
          “一方”、“各方”或“我们”指的是客户和我们自己。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          These terms and conditions outline the rules and regulations for the
          use of Sportyclub Live. By accessing this website, we assume you
          accept these terms and conditions. Do not continue to use Sportyclub
          Live if you do not agree to take all of the terms and conditions
          stated on this page. The following terminology applies to these Terms
          and Conditions, Privacy Statement and Disclaimer Notice and all
          Agreements: "Client", "You" and "Your" refer to you, the person log on
          this website and compliant to the Company’s terms and conditions. "The
          Company", "Ourselves", "We", "Our" and "Us", refer to our Company.
          "Party", "Parties", or "Us", refers to both the Client and ourselves.
        </div>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          你不能：
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>YOU MUST NOT:</p>

        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          样式="“list-style-type：光盘；margin-left：20px”"
        >
          <li data-v-6efd1af3="">重新发布来自 Sportyclub Live 的材料。</li>
          <li data-v-6efd1af3="">
            出售、出租来自 Sportyclub Live 的材料或对其进行再许可。
          </li>
          <li data-v-6efd1af3="">
            复制、复制或复印来自 Sportyclub Live 的材料。
          </li>

          <li data-v-6efd1af3="">重新分发 Sportyclub Live 的内容。</li>
        </ul>

        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">Republish material from Sportyclub Live.</li>
          <li data-v-6efd1af3="">
            Sell, rent, or sub-license material from Sportyclub Live.
          </li>
          <li data-v-6efd1af3="">
            Reproduce, duplicate or copy material from Sportyclub Live.
          </li>

          <li data-v-6efd1af3="">Redistribute content from Sportyclub Live.</li>
        </ul>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          从我们的网站上删除链接
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>
          REMOVE OF LINKS FROM OUR WEBSITE
        </p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          如果您发现我们网站上的任何链接出于任何原因具有攻击性，您可以随时联系并通知我们。我们会考虑删除链接的请求，但我们没有义务这样做或
          直接回复你。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us at any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </div>
        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          免责声明:
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>DISCLAIMER OF LIABILITY:</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们对材料中的任何声明不承担任何责任。在未事先征求专业建议的情况下，您不得依赖我们在
          Sportyclub Live
          上发布的任何声明。本材料中的任何内容均不是出于任何特定目的或应任何特定人士的要求提供的。我们不提供任何形式的保证
          材料的任何表述——信息、名称、图像、图片、
          徽标和图标——可在hindustantimes.com 中找到。您可以通过 Sportyclub Live
          的链接访问其他网站。这些网站不属于我们的
          控制权，我们对其任何内容不承担任何责任。我们对以下任何损失或损害不承担责任
          （无论此类损失是可预见的、可预见的、已知的还是其他情况）：
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          We do not accept any responsibility for any statement in the material.
          You must not rely on any statement we have published on Sportyclub
          Live without first taking specialist professional advice. Nothing in
          the material is provided for any specific purpose or at the request of
          any particular person. We give no warranties of any kind and without
          any representations for material—information, names, images, pictures,
          logos and icons—found in hindustantimes.com. You can access other
          sites via links from Sportyclub Live. These sites are not under our
          control and we are not responsible in any way for any of their
          contents. We are not liable for any of the following losses or damages
          (whether such losses where foreseen, foreseeable, known or otherwise):
        </div>
        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">数据丢失。</li>
          <li data-v-6efd1af3="">业务损失。</li>
          <li data-v-6efd1af3="">失去机会</li>
          <li data-v-6efd1af3="">商誉损失或声誉受损。</li>
          <li data-v-6efd1af3="">第三方遭受的损失。</li>
        </ul>

        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">Loss of Data.</li>
          <li data-v-6efd1af3="">Loss of Business.</li>
          <li data-v-6efd1af3="">Loss of Opportunity</li>

          <li data-v-6efd1af3="">Loss of goodwill or injury to reputation.</li>
          <li data-v-6efd1af3="">Losses suffered by third parties.</li>
        </ul>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          使用条款的变更：
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>CHANGES OF TERMS OF USE:</p>
        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          我们保留随时修改这些使用条款的权利。任何
          使用条款的更改将发布在网站上，您在发布更改后继续使用本网站即表示您同意
          更改，并且您受这些更改的当时版本的约束
          使用条款。如果对这些使用条款的任何更改是不可接受的
          您，您必须停止使用本网站。我们保留随时更改网站的权利，恕不另行通知。
        </div>
        <div data-v-6efd1af3="" class="text" v-else>
          We reserve the right, to modify these Terms of Use at any time. Any
          changes to Terms of Use will be posted on the Site and your continued
          use of the Site following a posting of changes is your agreement to
          the changes and you are bound by the then-current version of these
          Terms of Use. If any changes to these Terms of Use are unacceptable to
          you, you must discontinue use of the Site. We reserve the right to
          change the Site without notice to you, at any time.
        </div>
      </div>
    </div>
  </div>
  <span>
    <MobileFooter />
  </span>
</template>
    
    <script>
export default {};
</script>
    
    <style>
</style>