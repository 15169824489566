<template>
  <div class="content" data-v-06d4278f="">
    <!-- begin::loading -->
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      data-v-06d4278f=""
      style="top: 10rem"
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-06d4278f=""
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
      ></span>
    </div>
    <!-- end::loading -->

    <div
      v-if="(!loading && !leagueStandings === '') || !leagueStandings === null"
      data-v-8808ea54=""
      data-v-06d4278f=""
      class="basketball_standing"
      style=""
    >
      <div data-v-8808ea54="" style="width: 100%">
        <div
          data-v-8808ea54=""
          class="w100"
          v-for="standingDetail in leagueStandings"
          :key="standingDetail.id"
        >
          <div data-v-8808ea54="" class="tableTitle">
            <div
              data-v-8808ea54=""
              class="t1 font-400"
              style="box-sizing: border-box; width: 5rem"
            >
              <span data-v-8808ea54="" class="index">#</span>
              <span data-v-8808ea54="" style="margin-left: 0.21rem">
                {{ $t("football.league-details.team") }}
              </span>
            </div>
            <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                T
              </div>
            </div>
            <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                W
              </div>
            </div>
            <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                L
              </div>
            </div>
            <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                D
              </div>
            </div>
            <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                {{ $t("football.league-details.points") }}
              </div>
            </div>
          </div>
          <div data-v-8808ea54="" class="contentBox">
            <div
              v-for="(rowDetails, index) in standingDetail.rows"
              :key="index"
              data-v-8808ea54=""
              class="w100 flex align-center cbox list_active2"
            >
              <div data-v-8808ea54="" class="flex align-center contentTeamBox">
                <div data-v-8808ea54="" class="teamIndex">
                  {{
                    rowDetails && rowDetails.position ? rowDetails.position : ""
                  }}
                </div>
                <div
                  data-v-8808ea54=""
                  class="teamImg van-image van-image--round"
                  style="overflow: hidden; border-radius: 50%"
                >
                  <img
                    :src="
                      rowDetails.teamInfo && rowDetails.teamInfo.logo
                        ? rowDetails.teamInfo.logo + '!w30'
                      : bannerImagePath('default-team.png')
                    "
                    class="van-image__img"
                    style="object-fit: contain"
                  />
                </div>
                <a
                  data-v-8808ea54=""
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/football/team/details/' +
                    rowDetails.teamInfo.name.toLowerCase().replace(/\s/g, '-') +
                    '/' +
                    rowDetails.team_id
                  "
                  class="teamName van-ellipsis"
                  style="margin-left: 0.21rem"
                >
                  {{ rowDetails.teamInfo.name }}
                </a>
              </div>
              <div data-v-8808ea54="" class="flex-1 flex">
                <div data-v-8808ea54="" class="flex-1 text-center">
                  <div data-v-8808ea54="" class="detailData">
                    {{ rowDetails?.total }}
                  </div>
                </div>
                <div data-v-8808ea54="" class="flex-1 text-center">
                  <div data-v-8808ea54="" class="detailData">
                    {{ rowDetails?.won }}
                  </div>
                </div>
                <div data-v-8808ea54="" class="flex-1 text-center">
                  <div data-v-8808ea54="" class="detailData">
                    {{ rowDetails?.loss }}
                  </div>
                </div>
                <div data-v-8808ea54="" class="flex-1 text-center">
                  <div data-v-8808ea54="" class="detailData">
                    {{ rowDetails?.draw }}
                  </div>
                </div>
                <div data-v-8808ea54="" class="flex-1 text-center">
                  <div data-v-8808ea54="" class="detailData">
                    {{ rowDetails?.points }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      data-v-8808ea54=""
      data-v-06d4278f=""
      class="basketball_standing"
      style=""
    >
      <div data-v-8808ea54="" style="width: 100%">
        <div data-v-8808ea54="" class="w100">
          <div data-v-8808ea54="" class="contentBox">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 400px"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
    leagueDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leagueStandings: null,
      loading: true,
    };
  },
  created() {
    this.getLeagueStandings(this.leagueId);
  },
  methods: {
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data.standings;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
