<template>
  <div :class="theme">
    <MENUBAR :activeButton="activeButton" />
    <router-view></router-view>
  </div>
</template>
<script>
import MENUBAR from "./components/commons/menu-bar.vue";
export default {
  data() {
    return {
      themes: ["red", "green", "blue", "black"],
      selectedTheme: localStorage.getItem("selectedTheme") || "red", // Default theme is red if local storage is empty
    };
  },
  components: {
    MENUBAR,
  },
  mounted() {
    // Set the selectedTheme from local storage on mount
    const savedTheme = localStorage.getItem("selectedTheme");
    if (savedTheme) {
      this.selectedTheme = savedTheme;
    }

    // Listen for theme change and save it to local storage
    this.emitter.on("themeChange", ({ color }) => {
      this.selectedTheme = color;
      localStorage.setItem("selectedTheme", color);
    });
  },
  computed: {
    theme() {
      return this.selectedTheme;
    },
    activeButton() {
      return this.$route.params.sport || null;
    },
  },
  methods: {
    handleSportSelected(sport) {
      // Do something with the selected sport (e.g., update the active state in the parent component)
      console.log(`Selected sport: ${sport}`);
    },
    getImagePath(imageName) {
      return `/images/themes/${this.theme}/${imageName}`;
    },
  },
};
</script>
<style lang="css">
/* Import variables.css here if needed */
/* Update styles based on the theme */
.red {
  --main-theme-color: #e65050;
  --main-theme-font-color: #f4d9d9;
  --main-theme-fav-color: rgb(230, 80, 80); /* New main theme color */
  --main-text-color: #e65050;
}

.green {
  --main-theme-color: #086802;
  --main-theme-font-color: #daf4d9;
  --main-theme-fav-color: rgb(8, 104, 2); /* New main theme color */
  --main-text-color: #086802;
}

.blue {
  --main-theme-color: #0f80da;
  --main-theme-font-color: #cfe6f8;
  --main-theme-fav-color: rgb(15, 128, 218); /* New main theme color */
  --main-text-color: #0f80da;
}

.black {
  --main-theme-color: #000000;
  --main-theme-font-color: #ede4e4;
  --main-theme-fav-color: rgb(0, 0, 0); /* New main theme color */
  --main-text-color: #000000;
}
</style>
