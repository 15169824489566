<template>
  <br />
  <div class="team" data-v-27524a2e="">
    <div class="wrapper" data-v-27524a2e="">
      <div class="flex" data-v-698f63f4="" data-v-27524a2e="">
        <div class="p-l flex-1" data-v-698f63f4="">
          <div
            class="container-box"
            data-v-7c947e55=""
            data-v-698f63f4=""
            :style="pagLoading ? 'opacity:0.5' : ''"
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span
                class="color-p font-500 w-o-h"
                data-v-7c947e55=""
                style="font-size: 20px !important"
              >
                Latest News
              </span>
            </div>
            <p style="margin-left: 20px" v-if="loading">
              <br />
              <img src="/images/default-loader.gif" style="width: 100px" />
            </p>
            <div
              class="Trophies fs-12 color-333 pl-15 pr-15 pb-24"
              data-v-06dc1492=""
              data-v-698f63f4=""
              data-v-7c947e55=""
              v-if="!loading"
            >
              <div class="pl-n flex flex-wrap" data-v-06dc1492="">
                <div
                  v-for="news in newsList"
                  :key="news.id"
                  class="item flex flex-col align-center justify-center"
                  data-v-06dc1492=""
                  style="width: 350px; height: 380px"
                >
                  <a
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/football/news/details/' +
                      news.id
                    "
                    target="_blank"
                    class="image-wrapper"
                  >
                    <div class="image-container">
                      <img
                        :src="'https://sportyclub.online/' + news.image"
                        class="Trophy zoom"
                        style="width: 330px; height: 260px"
                        data-v-06dc1492=""
                      />
                      <div class="overlay">
                        <i class="iconfont icon-readmore"></i>
                        <span class="overlay-text">Read More</span>
                        <i class="iconfont icon-arrow-right"></i>
                      </div>
                    </div>
                    <div class="color-999">
                      <p
                        style="
                          font-size: 15px;
                          text-align: justify;
                          padding: 10px;
                          font-weight: bold;
                        "
                      >
                        {{ news.title }}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="text-center mt-24" data-v-6c892a26="" v-if="!loading">
              <div class="el-pagination" data-v-6c892a26="">
                <button
                  type="button"
                  class="btn-prev"
                  @click="prevPage"
                  :disabled="currentPage <= 1"
                >
                  <i class="el-icon el-icon-arrow-left"></i>
                </button>
                <ul class="el-pager">
                  <li
                    class="number"
                    v-for="num in displayedPages"
                    :key="num"
                    @click="goToPage(num)"
                    :class="{ active: num === currentPage }"
                  >
                    {{ num }}
                  </li>
                  <li
                    v-if="showStartEllipsis"
                    class="el-icon more btn-quickprev el-icon-more"
                  ></li>
                  <li
                    v-if="showEndEllipsis"
                    class="el-icon more btn-quickprev el-icon-more"
                  ></li>
                </ul>
                <button
                  type="button"
                  class="btn-next"
                  @click="nextPage"
                  :disabled="currentPage >= totalPages"
                >
                  <i class="el-icon el-icon-arrow-right"></i>
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
    <DesktopFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      changeNum: 1,
      page: 10,
      newsList: [],
      currentPage: 1,
      totalPages: 1,
      pagLoading: true,
    };
  },

  created() {
    this.getNewsList(1);
  },
  computed: {
    displayedPages() {
      const maxButtons = 10;
      const pages = [];
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      if (totalPages <= maxButtons) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        const maxVisible = maxButtons - 2;
        const start = Math.max(1, currentPage - Math.floor(maxVisible / 2));
        const end = Math.min(totalPages, start + maxVisible - 1);

        if (start > 1) {
          pages.push(1);
          if (start > 2) {
            pages.push("...");
          }
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages) {
          if (end < totalPages - 1) {
            pages.push("...");
          }
          pages.push(totalPages);
        }
      }
      return pages;
    },
    showStartEllipsis() {
      return this.displayedPages[0] === "...";
    },
    showEndEllipsis() {
      return this.displayedPages[this.displayedPages.length - 1] === "...";
    },
  },

  methods: {
    getNewsList(pageNum) {
      this.pagLoading = true;
      this.$axios
        .get(
          `https://sportyclub.online/api/football/news/list?pageNumber=${pageNum}`
        )
        .then((response) => {
          this.newsList = response.data.footballNewsList;
          this.currentPage = parseInt(response.data.currentPage);
          this.totalPages = parseInt(response.data.totalPages) - 1;
          this.loading = false;
          this.pagLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.getNewsList(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.getNewsList(this.currentPage + 1);
      }
    },
    goToPage(pageNum) {
      if (pageNum !== "..." && pageNum !== this.currentPage) {
        this.getNewsList(pageNum);
      }
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  position: relative;
  display: block;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.zoom {
  transition: transform 0.3s ease;
}

.zoom:hover {
  transform: scale(1.1); /* Zoom in effect */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.overlay i.iconfont {
  font-size: 16px; /* Adjust the size of the icons */
  color: white; /* White color for icons */
  margin: 0 5px;
}

.overlay-text {
  font-size: 16px;
  margin: 0 5px;
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-family: Arial, sans-serif; /* Smooth font for pagination */
}

.el-pagination button,
.el-pagination .number {
  background-color: #f0f0f0; /* Light background for buttons */
  color: #333; /* Dark text color for readability */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  margin: 0 5px;

  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.el-pagination button:hover,
.el-pagination .number:hover {
  background-color: var(
    --main-theme-fav-color
  ); /* Primary color for hover effect */
  color: white;
  transform: scale(1.05); /* Slight zoom effect */
}

.el-pagination .number.active {
  background-color: var(
    --main-theme-fav-color
  ); /* Primary color for active page */
  color: white;
  border-color: var(
    --main-theme-fav-color
  ); /* Match border with active background */
}

.el-pagination button:disabled {
  background-color: #e0e0e0; /* Disabled state background */
  color: #999; /* Disabled state text color */
  cursor: not-allowed;
  transform: none; /* Remove zoom effect for disabled buttons */
}

.el-pagination button i,
.el-pagination .number i {
  font-size: 16px;
  margin: 0 5px;
}

.el-pagination .el-icon-arrow-left,
.el-pagination .el-icon-arrow-right {
  font-size: 18px; /* Larger arrow icons */
  transition: color 0.3s, transform 0.3s;
}

.el-pagination .el-icon-arrow-left:hover,
.el-pagination .el-icon-arrow-right:hover {
  color: #007bff; /* Primary color on hover */
  transform: scale(1.2); /* Larger hover effect for arrows */
}

.el-pagination .el-icon-more {
  font-size: 20px; /* Larger ellipsis icon */
}

.el-pagination .el-icon-more::before {
  content: "..."; /* Display ellipsis */
}
</style>
