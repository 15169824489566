<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div
    class="innerMatchInfo detail view border-box bg"
    v-if="!loading && todayMatchDetails != ''"
  >
    <!-- begin::broadcrumb -->
    <div
      aria-label="Breadcrumb"
      role="navigation"
      class="el-breadcrumb breadBox"
    >
      <span class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner">
          <a target="_blank" style="color: var(--main-text-color)">
            {{ $t("table_tennis.league-details.table_tennis-live-score") }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i>
      </span>
      <span class="el-breadcrumb__item">
        <span role="link" class="el-breadcrumb__inner">
          <a
            v-if="todayMatchDetails.hasLeagueDetails === 1"
            target="_blank"
            :href="
              '/' +
              this.$i18n.locale +
              '/table_tennis/league/details/' +
              todayMatchDetails.leagueInfo.en_name
                .toLowerCase()
                .replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.unique_tournament_id
            "
            style="color: var(--main-text-color)"
          >
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo &&
              todayMatchDetails.leagueInfo.cn_name
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
          <a v-else target="_blank" style="color: var(--main-text-color)">
            {{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.leagueInfo &&
              todayMatchDetails.leagueInfo.cn_name
                ? todayMatchDetails.leagueInfo.cn_name
                : todayMatchDetails.leagueInfo.en_name
            }}
          </a>
        </span>
        <i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"> </i>
      </span>
      <span class="el-breadcrumb__item" aria-current="page">
        <span role="link" class="el-breadcrumb__inner">
          <h1 class="fs-12 font-400">
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/table_tennis/team/details/' +
                todayMatchDetails.homeInfo.en_name.replace(/[\s/]+/g, '-') +
                '/' +
                todayMatchDetails.home_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  todayMatchDetails.homeInfo.cn_name
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                }}
              </b>
            </a>

            vs
            <a
              target="_blank"
              style="color: var(--main-text-color); cursor: pointer"
              :href="
                '/' +
                this.$i18n.locale +
                '/table_tennis/team/details/' +
                todayMatchDetails.awayInfo.en_name.replace(/[\s/]+/g, '-') +
                '/' +
                todayMatchDetails.away_team_id
              "
            >
              <b>
                {{
                  this.$i18n.locale == "cn" &&
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  todayMatchDetails.awayInfo.cn_name
                    ? todayMatchDetails.awayInfo.cn_name
                    : todayMatchDetails.awayInfo.en_name
                }}</b
              >
            </a>
            {{ $t("football.league-details.live-score-prediction") }}
            <span v-if="todayMatchDetails && todayMatchDetails.match_time">
              {{ getFormattedBroadcrumbDate(todayMatchDetails.match_time) }}
            </span>
          </h1> </span
        ><i class="el-breadcrumb__separator el-icon-arrow-right arrowColor"></i
      ></span>
    </div>
    <!-- end::broadcrumb -->
    <div class="matchTop">
      <div class="text-center info">
        <a
          target="_blank"
          style="color: var(--main-text-color); cursor: pointer"
          class=""
          :href="
            '/' +
            this.$i18n.locale +
            '/table_tennis/league/details/' +
            todayMatchDetails.leagueInfo.en_name
              .toLowerCase()
              .replace(/[\s/]+/g, '-') +
            '/' +
            todayMatchDetails.unique_tournament_id
          "
        >
          {{
            this.$i18n.locale == "cn" &&
            todayMatchDetails &&
            todayMatchDetails.leagueInfo &&
            todayMatchDetails.leagueInfo.cn_name
              ? todayMatchDetails.leagueInfo.cn_name
              : todayMatchDetails.leagueInfo.en_name
          }}
        </a>

        <span class="cutOffLine">/</span>
        <span
          itemprop="startDate"
          v-if="todayMatchDetails && todayMatchDetails.match_time"
        >
          {{ getFormattedDate(todayMatchDetails.match_time) }}</span
        >
      </div>
      <div class="matchInfo">
        <div class="teamLeft">
          <a
            itemprop="homeTeam"
            :href="
              '/' +
              this.$i18n.locale +
              '/table_tennis/team/details/' +
              todayMatchDetails.homeInfo.en_name.replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            target="_blank"
            class="teamName"
            >{{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.homeInfo &&
              todayMatchDetails.homeInfo.cn_name
                ? todayMatchDetails.homeInfo.cn_name
                : todayMatchDetails.homeInfo.en_name
                ? todayMatchDetails.homeInfo.en_name
                : ""
            }}
          </a>
          <a
            itemprop="homeTeam"
            :href="
              '/' +
              this.$i18n.locale +
              '/table_tennis/team/details/' +
              todayMatchDetails.homeInfo.en_name.replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.home_team_id
            "
            target="_blank"
            class="teamName"
          >
            <img
              itemprop="logo"
              :src="
                todayMatchDetails &&
                todayMatchDetails.homeInfo &&
                todayMatchDetails.homeInfo.logo
                  ? todayMatchDetails.homeInfo.logo + '!w60'
                : bannerImagePath('default-team.png')
              "
            />
          </a>
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div class="score flex justify-center scoreRed">
              {{
                todayMatchDetails.scores &&
                todayMatchDetails.scores.ft &&
                todayMatchDetails.status_id != 1
                  ? todayMatchDetails.scores.ft[0]
                  : ""
              }}
            </div>
            <div class="detailtopIcon1" data-v-5754b2d9=""></div>
          </div>
        </div>

        <div
          v-if="this.notStartedStatus.includes(todayMatchDetails.status_id)"
          class="matchScore1"
        >
          <div class="status1Box"><div class="vs">vs</div></div>
        </div>

        <div
          v-else-if="this.FinishedMatches.includes(todayMatchDetails.status_id)"
          class="matchScore"
        >
          <span>{{ $t("football.league-details.full-time") }}</span>
        </div>

        <div v-else class="matchScore scoreColorRed" style="position: relative">
          <span>{{
            todayMatchDetails.statusDescription &&
            matchStatusDescription[todayMatchDetails.statusDescription]
              ? matchStatusDescription[todayMatchDetails.statusDescription]
              : todayMatchDetails.statusDescription
          }}</span>
          <div class="smallScoreTop" data-v-66108a68="">
            {{
              todayMatchDetails.status_id &&
              getSetNumberFromMatchStatusId(todayMatchDetails.status_id) !=
                -1 &&
              todayMatchDetails.scores &&
              todayMatchDetails.scores[
                "p" + getSetNumberFromMatchStatusId(todayMatchDetails.status_id)
              ]
                ? todayMatchDetails.scores[
                    "p" +
                      getSetNumberFromMatchStatusId(todayMatchDetails.status_id)
                  ][0]
                : ""
            }}
            -
            {{
              todayMatchDetails.status_id &&
              getSetNumberFromMatchStatusId(todayMatchDetails.status_id) !=
                -1 &&
              todayMatchDetails.scores &&
              todayMatchDetails.scores[
                "p" + getSetNumberFromMatchStatusId(todayMatchDetails.status_id)
              ]
                ? todayMatchDetails.scores[
                    "p" +
                      getSetNumberFromMatchStatusId(todayMatchDetails.status_id)
                  ][1]
                : ""
            }}
          </div>
        </div>

        <div class="teamRight">
          <div
            class="flex flex-col justify-center"
            style="width: 70px; position: relative"
          >
            <div class="score flex justify-center scoreRed">
              {{
                todayMatchDetails.scores &&
                todayMatchDetails.scores.ft &&
                todayMatchDetails.status_id != 1
                  ? todayMatchDetails.scores.ft[1]
                  : ""
              }}
            </div>
            <div class="detailtopIcon2" data-v-5754b2d9=""></div>
          </div>
          <a
            itemprop="awayTeam"
            :href="
              '/' +
              this.$i18n.locale +
              '/table_tennis/team/details/' +
              todayMatchDetails.awayInfo.en_name.replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            target="_blank"
            class="teamName"
          >
            <img
              itemprop="logo"
              :src="
                todayMatchDetails &&
                todayMatchDetails.awayInfo &&
                todayMatchDetails.awayInfo.logo
                  ? todayMatchDetails.awayInfo.logo + '!w60'
                : bannerImagePath('default-team.png')
              "
            />
          </a>
          <a
            itemprop="awayTeam"
            :href="
              '/' +
              this.$i18n.locale +
              '/table_tennis/team/details/' +
              todayMatchDetails.awayInfo.en_name.replace(/[\s/]+/g, '-') +
              '/' +
              todayMatchDetails.away_team_id
            "
            target="_blank"
            class="teamName"
            >{{
              this.$i18n.locale == "cn" &&
              todayMatchDetails &&
              todayMatchDetails.awayInfo &&
              todayMatchDetails.awayInfo.cn_name
                ? todayMatchDetails.awayInfo.cn_name
                : todayMatchDetails.awayInfo.en_name
                ? todayMatchDetails.awayInfo.en_name
                : ""
            }}</a
          >
        </div>
      </div>
    </div>
    <!-- begin::tabs -->
    <div
      justify-content="true"
      class="tab-bar"
      data-v-274dfa8a=""
      data-v-4a160e70=""
    >
      <div class="content-box" data-v-274dfa8a="">
        <div class="child" data-v-274dfa8a="">
          <a
            class="tab"
            :class="status == 'overview' ? 'active' : ''"
            data-v-274dfa8a=""
            v-on:click="toggleTabs('overview')"
          >
            {{ $t("football.league-details.overview") }}
          </a>

          <a
            :class="status == 'odds' ? 'active' : ''"
            v-on:click="toggleTabs('odds')"
            class="tab"
            data-v-274dfa8a=""
          >
            {{ $t("football.league-details.odds") }}
          </a>
        </div>
      </div>
    </div>
    <!-- end::tabs -->
    <!-- begin::overview -->
    <div
      v-if="status == 'overview'"
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'overview' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOverviewTab :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::overview -->
    <!-- begin::odds -->
    <div
      v-if="status == 'odds'"
      class="content-box"
      data-v-4a160e70=""
      :style="status == 'odds' ? 'display:block' : 'display:none'"
    >
      <LiveMatchOdds :matchId="todayMatchDetails.id" />
    </div>
    <!-- end::odds -->

    <!-- begin::odds -->

    <!-- end::odds -->
  </div>

  <DesktopFooter />
</template>

<script>
import LiveMatchOverviewTab from "./tabs/live-match-overview.vue";
import LiveMatchOdds from "./tabs/match-odds.vue";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],

  components: {
    LiveMatchOverviewTab,

    LiveMatchOdds,
  

  },
  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      status: "overview",
      liveMatches: [],
      matchStatusDescription: {
        S1: this.$i18n.t("football.league-details.set") + " 1",
        S2: this.$i18n.t("football.league-details.set") + " 2",
        S3: this.$i18n.t("football.league-details.set") + " 3",
        S4: this.$i18n.t("football.league-details.set") + " 4",
        S5: this.$i18n.t("football.league-details.set") + " 5",
        S6: this.$i18n.t("football.league-details.set") + " 6",
        S7: this.$i18n.t("football.league-details.set") + " 7",
      },
      sets_match_status: [51, 52, 53, 54, 55, 56, 57],
      FinishedMatches: [100],
      notStartedStatus: [1],
    };
  },

  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.matchId;
    this.getTodayMatchDetails(itemId);
    this.toggleTabs("overview");
    this.getMetaData(itemId);
  },

  methods: {
    // methpd to get meta tags
    getMetaData(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/table_tennis/match/meta/` + matchId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/table_tennis/match/details/` + matchId
        )
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get formatted date for broadcrumb
    getFormattedBroadcrumbDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return date.toLocaleDateString("en-US", options);
    },

    getFormattedDate(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Date(timestamp * 1000).toLocaleString(
        "en-US",
        options
      );
      return formattedDate;
    },

    toggleTabs(status) {
      return (this.status = status);
    },

    // add section score
    addSectionsScore(section_1, section_2, section_3, section_4) {
      return section_1 + section_2 + section_3 + section_4;
    },
    getSetNumberFromMatchStatusId(status_id) {
      let setNumber = this.sets_match_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
  },
};
</script>
