<template>
  <div class="content" data-v-59f3daaa="">
    <div
      v-if="loadingFeatured || loadingLeague"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="top: 10rem"
      data-v-59f3daaa=""
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-59f3daaa=""
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
        ><i data-v-59f3daaa=""></i><i data-v-59f3daaa=""></i
      ></span>
    </div>
    <div v-else data-v-812788c4="" data-v-59f3daaa="" class="bk_tour_overview">
      <div data-v-812788c4="" class="w100">
        <div v-if="!loadingFeatured" data-v-812788c4="" class="feature">
          <div data-v-812788c4="" class="feature_title">Featured Match</div>
          <a data-v-812788c4="" class="feature_content flex">
            <div
              data-v-812788c4=""
              class="flex-2 h100 flex-column justify-center flex align-center"
            >
              <div data-v-812788c4="" itemprop="startDate" class="time"></div>
              <div
                data-v-812788c4=""
                class="tour_teamLogo van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  v-if="
                    featureMatch &&
                    featureMatch[0] &&
                    featureMatch[0].homeTeam &&
                    featureMatch[0].homeTeam.id
                  "
                  :src="
                    featureMatch[0].awayTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        featureMatch[0].homeTeam.id +
                        '/' +
                        featureMatch[0].homeTeam.id +
                        '.png'
                    : bannerImagePath('default-team.png')
                  "
                  @error="$event.target.src = bannerImagePath('default-team.png')"
                  alt=""
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div
                data-v-812788c4=""
                itemprop="homeTeam"
                class="teamName van-ellipsis"
                style="max-width: 3.5rem"
              >
                {{
                  featureMatch &&
                  featureMatch[0] &&
                  featureMatch[0].homeTeam &&
                  featureMatch[0].homeTeam.name
                    ? featureMatch[0].homeTeam.name
                    : ""
                }}
              </div>
            </div>
            <div
              data-v-812788c4=""
              class="flex-1 h100 flex-column justify-center flex align-center"
            >
              <div data-v-812788c4="" class="status text-center">
                <!-- <span data-v-812788c4="" class="grey">FT</span> -->
              </div>
              <span
                v-if="
                  featureMatch &&
                  featureMatch[0] &&
                  featureMatch[0].awayScore &&
                  featureMatch[0].awayScore.innings &&
                  featureMatch[0].awayScore.innings.inning1 &&
                  featureMatch[0].homeScore &&
                  featureMatch[0].homeScore.innings &&
                  featureMatch[0].homeScore.innings.inning1
                "
                data-v-812788c4=""
                class="score"
              >
                {{
                  featureMatch[0].homeScore.innings.inning1.score
                    ? featureMatch[0].homeScore.innings.inning1.score
                    : "-"
                }}/{{
                  featureMatch[0].homeScore.innings.inning1.wickets
                    ? featureMatch[0].homeScore.innings.inning1.wickets
                    : "-"
                }}
                -
                {{
                  featureMatch[0].awayScore.innings.inning1.score
                    ? featureMatch[0].awayScore.innings.inning1.score
                    : "-"
                }}/{{
                  featureMatch[0].awayScore.innings.inning1.wickets
                    ? featureMatch[0].awayScore.innings.inning1.wickets
                    : "-"
                }}
              </span>
              <div
                v-if="
                  featureMatch &&
                  featureMatch[0] &&
                  featureMatch[0].awayScore &&
                  featureMatch[0].awayScore.innings &&
                  featureMatch[0].awayScore.innings.inning1 &&
                  featureMatch[0].homeScore &&
                  featureMatch[0].homeScore.innings &&
                  featureMatch[0].homeScore.innings.inning1
                "
                data-v-812788c4=""
                class="teamName"
              >
                ({{
                  featureMatch[0].homeScore.innings.inning1.overs
                    ? featureMatch[0].homeScore.innings.inning1.overs
                    : "-"
                }}) - ({{
                  featureMatch[0].awayScore.innings.inning1.overs
                    ? featureMatch[0].awayScore.innings.inning1.overs
                    : "-"
                }}) ov
              </div>
            </div>
            <div
              data-v-812788c4=""
              class="flex-2 h100 flex-column justify-center flex align-center"
            >
              <div data-v-812788c4="" class="time"></div>
              <div
                data-v-812788c4=""
                class="tour_teamLogo van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  v-if="
                    featureMatch &&
                    featureMatch[0] &&
                    featureMatch[0].awayTeam &&
                    featureMatch[0].awayTeam.id
                  "
                  :src="
                    featureMatch[0].awayTeam.id
                      ? 'https://sportyclub.online//images/cricket/teams/' +
                        featureMatch[0].awayTeam.id +
                        '/' +
                        featureMatch[0].awayTeam.id +
                        '.png'
                    : bannerImagePath('default-team.png')
                  "
                  @error="$event.target.src = bannerImagePath('default-team.png')"
                  alt="Serbia"
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              <div
                data-v-812788c4=""
                itemprop="awayTeam"
                class="teamName van-ellipsis"
                style="max-width: 3.5rem"
              >
                {{
                  featureMatch &&
                  featureMatch[0] &&
                  featureMatch[0].awayTeam &&
                  featureMatch[0].awayTeam.name
                    ? featureMatch[0].awayTeam.name
                    : ""
                }}
              </div>
            </div></a
          >
        </div>
        <div v-if="!loadingLeague" class="info" data-v-61a7efa4="">
          <div class="info_title" data-v-61a7efa4="">Info</div>
          <div class="info_content" data-v-61a7efa4="">
            <div class="infoItem" data-v-61a7efa4="">
              <div class="key" data-v-61a7efa4="">
                {{ $t("football.league-details.current-title-holder") }}
              </div>
              <div class="value" data-v-61a7efa4="">
                {{ leagueDetails?.titleHolder?.name }}
              </div>
            </div>
            <div
              v-if="leagueInfo && leagueInfo.mostTitlesTeams"
              class="infoItem"
              data-v-61a7efa4=""
            >
              <div class="key" data-v-61a7efa4="">
                {{ $t("football.league-details.most-title-holder") }}
              </div>
              <div class="value" data-v-61a7efa4="">
                {{
                  leagueDetails.mostTitlesTeams
                    .map((obj) => obj.name)
                    .join(", ")
                }}
              </div>
            </div>
            <div
              v-if="leagueInfo && leagueInfo.hostCountries"
              class="infoItem"
              data-v-61a7efa4=""
            >
              <div class="key" data-v-61a7efa4="">Host</div>
              <div class="value" data-v-61a7efa4="">
                {{ leagueInfo.hostCountries.join(", ") }}
              </div>
            </div>
            <div class="infoItem" data-v-61a7efa4="">
              <div class="key" data-v-61a7efa4="">
                {{ $t("football.league-details.no-of-teams") }}
              </div>
              <div class="value" data-v-61a7efa4="">
                {{
                  leagueInfo && leagueInfo.numberOfCompetitors
                    ? leagueInfo.numberOfCompetitors
                    : "-"
                }}
              </div>
            </div>
            <div class="infoItem" data-v-61a7efa4="">
              <div class="key" data-v-61a7efa4="">
                {{ $t("football.league-details.start-date") }}
              </div>
              <div class="value" data-v-61a7efa4="">
                {{ getTimeStampDate(leagueDetails.startDateTimestamp) }}
              </div>
            </div>
            <div class="infoItem" data-v-61a7efa4="">
              <div class="key" data-v-61a7efa4="">
                {{ $t("football.league-details.end-date") }}
              </div>
              <div class="value" data-v-61a7efa4="">
                {{ getTimeStampDate(leagueDetails.endDateTimestamp) }}
              </div>
            </div>
            <div class="infoItem" data-v-61a7efa4="" style="display: none">
              <div class="key" data-v-61a7efa4=""></div>
              <div class="value" data-v-61a7efa4=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateUtils from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,dateUtils],
  props: {
    leagueId: {
      type: Number,
      default: null,
    },
    leagueDetails: {
      type: Object,
      default: null,
    },
    seasonId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      leagueStandings: null,
      loadingLeague: true,
      loadingFeatured: true,
      playerStatsloading: true,
      teamStatsloading: true,
      showLess: true,
      leaguePlayersPropertiesStats: null,
      leagueInfo: null,

      playerStatsTeamProperties: [
        { viewAll: false, property: "goals", name: "goals" },
      ],

      leagueTeamsPropertiesStats: null,
      teamProperties: [{ viewAll: false, property: "goals", name: "goals" }],
    };
  },
  created() {

    this.getFeaturedMatch(this.leagueId);
    this.getLeagueInfo(this.leagueId, this.seasonId);
  },
  watch: {
    seasonId: function (newSeasonId) {
      console.log("in watch");
      this.getLeagueInfo(this.leagueId, newSeasonId);
    },
  },
  methods: {
    toggleViewAllPlayer(index) {
      this.playerStatsTeamProperties[index].viewAll =
        !this.playerStatsTeamProperties[index].viewAll;
    },
    toggleViewAll(index) {
      this.teamProperties[index].viewAll = !this.teamProperties[index].viewAll;
    },
    // method to get featured match
    getFeaturedMatch(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/featured/match/${leagueId}`
        )
        .then((response) => {
          this.featureMatch = response.data.featuredEvents;
          this.loadingFeatured = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLeagueInfo(leagueId, seasonId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/season/info/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.leagueInfo = response.data.info;
          this.loadingLeague = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
