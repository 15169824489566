<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-6ad89ef3="" v-if="!loading">
    <!-- begin::broadcrumb -->
    <div class="breadcrumb-box" data-v-6ad89ef3="">
      <div class="content-box" data-v-6ad89ef3="">
        <ul class="breadcrumb" data-v-6ad89ef3="">
          <li data-v-6ad89ef3="">
            <a
              :href="'/' + this.$i18n.locale + '/live/football'"
              itemprop="item name"
              class="link"
              data-v-6ad89ef3=""
            >
              {{ $t("football.league-details.football-live-score") }}
            </a>
          </li>

          <li data-v-6ad89ef3="">
            <h1
              class="text"
              data-v-6ad89ef3=""
              v-if="this.$i18n.locale == 'cn'"
            >
              {{
                teamDetails && teamDetails.cn_name ? teamDetails.cn_name : ""
              }}
            </h1>
            <h1 class="text" data-v-6ad89ef3="" v-else>
              {{ teamDetails && teamDetails.name ? teamDetails.name : "" }}
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::broadcrumb -->
    <div class="team" data-v-6ad89ef3="">
      <div class="team-header" data-v-2f158089="" data-v-6ad89ef3="">
        <div class="content border-box flex align-center" data-v-2f158089="">
          <div class="p-l flex-1 flex align-center" data-v-2f158089="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-2f158089=""
            >
              <img
                :src="
                  teamDetails && teamDetails.logo
                    ? teamDetails.logo
                    : bannerImagePath('default-nation.png')
                "
                class="team-logo"
                data-v-2f158089=""
              />
              <div class="pl-15" data-v-2f158089="">
                <div class="fs-24 font-500 color-333" data-v-2f158089="">
                  <span data-v-2f158089="">
                    {{
                      this.$i18n.locale == "cn" &&
                      teamDetails &&
                      teamDetails.cn_name &&
                      teamDetails.name
                        ? teamDetails.cn_name
                        : teamDetails.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- begin::team stats -->
          <div
            class="headRight flex flex-col fs-12"
            data-v-5ea21537=""
            data-v-2f158089=""
          >
            <div class="top color-w" data-v-5ea21537="">
              <div data-v-5ea21537="">
                {{ $t("football.league-details.stats") }}
              </div>
            </div>
            <div
              class="flex-1 flex color-666 pl-15 pr-15 border-box justify-between text-center align-center"
              data-v-5ea21537=""
            >
              <div class="flex-1" data-v-5ea21537="">
                <div class="font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.squad-size") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    teamDetails && teamDetails.total_players
                      ? teamDetails.total_players
                      : "-"
                  }}
                </div>
              </div>

              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.average-age") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    teamDetails && teamDetails.foreign_players
                      ? teamDetails.foreign_players
                      : "-"
                  }}
                </div>
              </div>
              <div class="flex-1" data-v-5ea21537="">
                <div class="color-666 font-300 mb-xxs" data-v-5ea21537="">
                  {{ $t("football.league-details.total-market-value") }}
                </div>
                <div class="value font-500" data-v-5ea21537="">
                  {{
                    teamDetails && teamDetails.market_value
                      ? teamDetails.market_value / 1000000 + " €"
                      : "-"
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- end::team stats -->
        </div>
      </div>

      <!-- begin::team squad -->
      <div class="wrapper" data-v-6ad89ef3="">
        <div class="flex" data-v-771adb1a="" data-v-6ad89ef3="">
          <div class="p-l flex-1" data-v-771adb1a="">
            <div
              class="container-box mb-12"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ $t("football.league-details.team-squad") }}
                </span>
              </div>
              <div
                class="Squad color-333 pl-15 pr-15 pb-24"
                data-v-0939e9fc=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div class="table mt-8" data-v-0939e9fc="">
                  <div
                    class="head flex justify-between align-center color-999 border-box fs-12"
                    data-v-0939e9fc=""
                  >
                    <div class="col" style="width: 200px" data-v-0939e9fc="">
                      {{ $t("football.league-details.players") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.age") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.height") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.weight") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.market-value") }}
                    </div>
                    <div class="col flex-1" style="width: " data-v-0939e9fc="">
                      {{ $t("football.league-details.position") }}
                    </div>
                  </div>
                  <div class="body" data-v-0939e9fc="">
                    <div class="border-box" data-v-51bacf47="">
                      <br />

                      <br />
                    </div>
                    <div v-if="teamSquad != ''">
                      <div
                        class="row flex fs-12 color-333 cur-pointer"
                        data-v-0939e9fc=""
                        v-for="team in teamSquad"
                        :key="team.id"
                      >
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/player/details/' +
                            team.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            team.id
                          "
                          class="col justify-start border-box"
                          style="width: 200px; padding-left: 12px"
                          data-v-0939e9fc=""
                        >
                          <span class="flex flex-col fs-12" data-v-0939e9fc=""
                            ><span class="color-p" data-v-0939e9fc=""
                              ><span data-v-0939e9fc="">
                                {{
                                  this.$i18n.locale == "cn"
                                    ? team.cn_name
                                    : team.name
                                }}
                              </span></span
                            >
                            <span
                              class="flex align-center color-999 s-0-85"
                              style="transform-origin: left"
                              data-v-0939e9fc=""
                            >
                              <span data-v-0939e9fc="">
                                {{
                                  team && team.nationality
                                    ? team.nationality
                                    : ""
                                }}
                              </span></span
                            ></span
                          ></a
                        >
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team && team.age ? team.age : "-" }}
                        </div>
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team && team.height ? team.height + "cm" : "-" }}
                        </div>
                        <div class="col flex-1" data-v-0939e9fc="">
                          {{ team && team.weight ? team.weight + "kg" : "-" }}
                        </div>
                        <div class="col flex-1 color-p" data-v-0939e9fc="">
                          {{
                            team && team.market_value
                              ? "€" + team.market_value / 1000000
                              : "-"
                          }}
                        </div>
                        <div
                          itemprop="endDate"
                          class="col flex-1 color-999"
                          data-v-0939e9fc=""
                        >
                          {{ team && team.position ? team.position : "-" }}
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="contentBox" data-v-1eddfbef="">
                        <div
                          class="scheduleBox"
                          data-v-1eddfbef=""
                          style="width: 100%"
                        >
                          <div
                            data-v-1eddfbef=""
                            class="flex justify-center align-center flex-col"
                            style="height: 300px"
                          >
                            <img
                              :src="bannerImagePath('default-container.png')"
                              style="width: 123px; height: 74px"
                            />
                            <div class="color-999 fs-12 mt-12">
                              {{
                                $t("football.live-score.temporarily-no-data")
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-r" data-v-771adb1a="">
            <div
              class="container-box mb-12 pb-24"
              data-v-7c947e55=""
              data-v-771adb1a=""
            >
              <div class="title flex justify-between" data-v-7c947e55="">
                <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                  {{ $t("football.league-details.team-info") }}
                </span>
                <span class="color-999 fs-12" data-v-7c947e55=""></span>
              </div>
              <div
                class="teamInfo color-333 fs-12 mt-12"
                data-v-5fcdd480=""
                data-v-771adb1a=""
                data-v-7c947e55=""
              >
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.total-players") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.total_players
                        ? teamDetails.total_players
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.foreign-players") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.total_players
                        ? teamDetails.total_players
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.market-value") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.market_value
                        ? teamDetails.market_value / 1000000 + " €"
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.market-value-currency") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.market_value_currency
                        ? teamDetails.market_value_currency
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.national-players") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.national_players
                        ? teamDetails.national_players
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.website") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.website
                        ? teamDetails.website
                        : "-"
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-between mt-15 pl-15 pr-15"
                  data-v-5fcdd480=""
                >
                  <div class="color-999" data-v-5fcdd480="">
                    {{ $t("football.league-details.foundation-time") }}
                  </div>
                  <div data-v-5fcdd480="">
                    {{
                      teamDetails && teamDetails.foundation_time
                        ? teamDetails.foundation_time
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TodayMatches />
      </div>
      <!-- end::team squad -->
    </div>
  </div>
  <span>
    <DesktopFooter />
  </span>
</template>
<script>
import metaDataUtils from "@/utils/metaDataUtils";
import TodayMatches from "./tabs/live-matches.vue";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, metaDataUtils],
  components: { TodayMatches },

  data() {
    return {
      teamDetails: null,
      teamSquad: null,
      loading: true,
      teamId: null,
      totlePlayers: null,
      metaData: null,
    };
  },
  created() {
    // Accessing the ID parameter from the routes
    const itemId = this.$route.params.teamId;
    this.teamId = itemId;
    this.getTeamDetails(itemId);
    this.getTeamSquad(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // methpd to get meta tags
    getMetaData(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/meta/` + teamId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get hot leagues list
    getTeamDetails(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/details/${teamId}`)
        .then((response) => {
          this.teamDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team squad
    getTeamSquad(teamId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/team/squad/${teamId}`)
        .then((response) => {
          this.teamSquad = response.data.teamPlayers;
          this.totlePlayers = response.data.teamPlayers.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
