<template>
  <span v-if="!loading" mode="out-in" data-v-4a160e70="">
    <div class="analyseContent" data-v-25ea6f07="" data-v-4a160e70="">
      <div class="flex" data-v-25ea6f07="">
        <!-- begin::all league h2h -->
        <div data-v-25ea6f07="" class="contentBox flex-1 rightMargin12">
          <!-- begin::allBox -->
          <div data-v-25ea6f07="" class="allBox">
            <!-- begin::dropdown -->
            <div data-v-25ea6f07="" class="headerBox">
              <p data-v-25ea6f07="">
                <img
                  data-v-25ea6f07=""
                  :src="
                    todayMatchDetails &&
                    todayMatchDetails.leagueInfo &&
                    todayMatchDetails.leagueInfo.logo
                      ? todayMatchDetails.leagueInfo.logo + '!w30'
                     : bannerImagePath('default-country.png')
                  "
                  alt="#"
                /><span data-v-25ea6f07="">{{
                  todayMatchDetails &&
                  todayMatchDetails.leagueInfo &&
                  this.$i18n.locale == "cn"
                    ? todayMatchDetails.leagueInfo.cn_name
                    : todayMatchDetails.leagueInfo.en_name
                }}</span>
              </p>
              <p data-v-3a4ccff5="">
                <span
                  data-v-3a4ccff5=""
                  class="select flexCenter"
                  v-on:click="leagueToggleDropdown(leagueDropdownSize)"
                >
                  {{ leagueSize }}
                  <i data-v-3a4ccff5="" class="iconfont icon-xiala"></i>
                  <span
                    data-v-3a4ccff5=""
                    class="selectNum"
                    :style="
                      leagueDropdownSize ? 'display:block' : 'display:none'
                    "
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="leagueToggleSize(2)"
                      >2</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="leagueToggleSize(3)"
                      >3</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="leagueToggleSize(4)"
                      >4</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="leagueToggleSize(5)"
                      >5</span
                    ></span
                  ></span
                >
              </p>
            </div>

            <div data-v-557138e8="" class="screenBox">
              <p data-v-557138e8="" class="mr-16">
                <span
                  data-v-557138e8=""
                  :style="
                    leagueDropdownRadioHome ? 'display:block' : 'display: none'
                  "
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioHome, 'leagueHome')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    leagueDropdownRadioHome ? 'display:none' : 'display: block'
                  "
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioHome, 'leagueHome')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioHome, 'leagueHome')
                  "
                  >&nbsp; {{ $t('football.league-details.home') }}</span
                >
                <span data-v-557138e8=""> -    {{
             
                              this.$i18n.locale == "cn"
                                ? todayMatchDetails.homeInfo.cn_name
                                : todayMatchDetails.homeInfo.en_name
                            }} </span>
              </p>
              <p data-v-557138e8="">
                <span
                  data-v-557138e8=""
                  :style="
                    leagueDropdownRadioLeague
                      ? 'display:block'
                      : 'display: none'
                  "
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioLeague, 'leagueLeague')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    leagueDropdownRadioLeague
                      ? 'display:none'
                      : 'display: block'
                  "
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioLeague, 'leagueLeague')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    leagueToggleRadio(leagueDropdownRadioLeague, 'leagueLeague')
                  "
                  >&nbsp; {{ $t('football.league-details.this-league') }}</span
                >
              </p>
            </div>

            <div data-v-25ea6f07="" class="infoBox">
              <span data-v-25ea6f07=""
                ><span data-v-25ea6f07="" class="round win flexCenter">W</span>
                <span data-v-25ea6f07="" class="text">X3</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><i data-v-25ea6f07="" class="iconfont icon-pingju"></i>
                <span data-v-25ea6f07="" class="text">X1</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><span data-v-25ea6f07="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-25ea6f07="" class="text">X2</span></span
              >
            </div>

            <template
              v-if="
                todayMatchDetails &&
                todayMatchDetails.matchH2H &&
                todayMatchDetails.matchH2H.all
              "
            >
              <span v-if="!leagueDropdownRadioHome">
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.all"
                  :key="key"
                >
                  <span v-if="leagueDropdownRadioLeague">
                    <!-- begin::League Indo-->

                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span
                          v-if="
                            !leagueDropdownRadioHome &&
                            todayMatchDetails.homeInfo.en_name ==
                              match.homeInfo.name
                          "
                        >
                          <li data-v-25ea6f07="" v-if="index < homeSize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                      itemprop="logo"
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        todayMatchDetails.leagueInfo.en_name ==
                        home[0].leagueInfo.name
                      "
                    >
                      <!-- begin::League Indo-->
                      <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                      <!-- end::League Indo-->

                      <!-- begin::Teams Info  -->
                      <ul data-v-25ea6f07="" class="matchBox">
                        <span v-for="(match, index) in home" :key="index">
                          <span
                            v-if="
                              !leagueDropdownRadioHome &&
                              todayMatchDetails.homeInfo.en_name ==
                                match.homeInfo.name
                            "
                          >
                            <li data-v-25ea6f07="" v-if="index < homeSize">
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="match.matchStatus == 1"
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score > match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter win"
                                >
                                  W
                                </span>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score < match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter loser"
                                >
                                  L
                                </span>
                              </p>
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score == match.awayInfo.score
                                "
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <a data-v-25ea6f07="" target="_blank">
                                <p data-v-25ea6f07="" class="time flexCenter">
                                  {{ match.matchTime }}
                                </p>
                                <p data-v-25ea6f07="" class="teamBox">
                                  <!-- begin:: home Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.homeInfo.logo &&
                                          match.homeInfo.logo
                                            ? match.homeInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                      >{{
                                        match &&
                                        match.homeInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.homeInfo.cn_name
                                          : match.homeInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end::home Team -->
                                  <!-- begin::Away Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.awayInfo.logo &&
                                          match.awayInfo.logo
                                            ? match.awayInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                        itemprop="logo"
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                    >
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.awayInfo.cn_name
                                          : match.awayInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end:: Away Team -->
                                </p>
                                <!-- begin::home -->
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.halfScore
                                      ? match.homeInfo.halfScore
                                      : "0"
                                  }}</span>
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.halfScore
                                      ? match.awayInfo.halfScore
                                      : "0"
                                  }}</span>
                                </p>
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      match.homeInfo.score
                                        ? match.homeInfo.score
                                        : "0"
                                    }}
                                  </span>
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.score
                                        ? match.awayInfo.score
                                        : "0"
                                    }}
                                  </span>
                                </p>
                              </a>
                              <!-- end::home -->
                            </li>
                          </span>
                        </span>
                      </ul>
                      <!-- end::Teams Info -->
                    </span>
                  </span>
                </div>
              </span>

              <span v-else-if="!leagueDropdownRadioLeague">
    
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.all"
                  :key="key"
                >
                  <span
                    v-if="
                      todayMatchDetails.leagueInfo.en_name ==
                      home[0].leagueInfo.name
                    "
                  >
                    <!-- begin::League Indo-->
                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if=" home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span>
                          <li data-v-25ea6f07="" v-if="index < leagueSize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                      itemprop="logo"
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                </div>
              </span>

              <span v-else>
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.all"
                  :key="key"
                >
                  <!-- begin::League Indo-->
                  <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>


             
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   



                        
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                  <!-- end::League Indo-->

                  <!-- begin::Teams Info  -->
                  <ul data-v-25ea6f07="" class="matchBox">
                    <span v-for="(match, index) in home" :key="index">
                      <span>
                        <li data-v-25ea6f07="" v-if="index < homeSize">
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="match.matchStatus == 1"
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score > match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter win"
                            >
                              W
                            </span>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score < match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter loser"
                            >
                              L
                            </span>
                          </p>
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score == match.awayInfo.score
                            "
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <a data-v-25ea6f07="" target="_blank">
                            <p data-v-25ea6f07="" class="time flexCenter">
                              {{ match.matchTime }}
                            </p>
                            <p data-v-25ea6f07="" class="teamBox">
                              <!-- begin:: home Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.homeInfo.logo &&
                                      match.homeInfo.logo
                                        ? match.homeInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                  >{{
                                    match &&
                                    match.homeInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.homeInfo.cn_name
                                      : match.homeInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end::home Team -->
                              <!-- begin::Away Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.awayInfo.logo &&
                                      match.awayInfo.logo
                                        ? match.awayInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                    itemprop="logo"
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.awayInfo.cn_name
                                      : match.awayInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end:: Away Team -->
                            </p>
                            <!-- begin::home -->
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.halfScore
                                  ? match.homeInfo.halfScore
                                  : "0"
                              }}</span>
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.halfScore
                                  ? match.awayInfo.halfScore
                                  : "0"
                              }}</span>
                            </p>
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.score
                                    ? match.homeInfo.score
                                    : "0"
                                }}
                              </span>
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.score
                                    ? match.awayInfo.score
                                    : "0"
                                }}
                              </span>
                            </p>
                          </a>
                          <!-- end::home -->
                        </li>
                      </span>
                    </span>
                  </ul>
                  <!-- end::Teams Info -->
                </div>
              </span>
            </template>
          </div>
          <!-- end::allBox -->
        </div>
        <!-- end::all league h2h -->
        <!-- begin::home league h2h -->
        <div data-v-25ea6f07="" class="contentBox flex-1 rightMargin12">
          <!-- begin::allBox -->
          <div data-v-25ea6f07="" class="allBox">
            <!-- begin::dropdown -->
            <div data-v-25ea6f07="" class="headerBox">
              <p data-v-25ea6f07="">
                <img
                  data-v-25ea6f07=""
                  :src="
                    todayMatchDetails &&
                    todayMatchDetails.homeInfo &&
                    todayMatchDetails.homeInfo.logo
                      ? todayMatchDetails.homeInfo.logo + '!w30'
                     : bannerImagePath('default-country.png')
                  "
                  alt="#"
                /><span data-v-25ea6f07="">{{
                  todayMatchDetails &&
                  todayMatchDetails.homeInfo &&
                  this.$i18n.locale == "cn"
                    ? todayMatchDetails.homeInfo.cn_name
                    : todayMatchDetails.homeInfo.en_name
                }}</span>
              </p>
              <p data-v-3a4ccff5="">
                <span
                  data-v-3a4ccff5=""
                  class="select flexCenter"
                  v-on:click="homeToggleDropdown(homeDropdownSize)"
                >
                  {{ homeSize }}
                  <i data-v-3a4ccff5="" class="iconfont icon-xiala"></i>
                  <span
                    data-v-3a4ccff5=""
                    class="selectNum"
                    :style="homeDropdownSize ? 'display:block' : 'display:none'"
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="homeToggleSize(2)"
                      >2</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="homeToggleSize(3)"
                      >3</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="homeToggleSize(4)"
                      >4</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="homeToggleSize(5)"
                      >5</span
                    ></span
                  ></span
                >
              </p>
            </div>

            <div data-v-557138e8="" class="screenBox">
              <p data-v-557138e8="" class="mr-16">
                <span
                  data-v-557138e8=""
                  :style="
                    homeDropdownRadioHome ? 'display:block' : 'display: none'
                  "
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioHome, 'homeHome')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    homeDropdownRadioHome ? 'display:none' : 'display: block'
                  "
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioHome, 'homeHome')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioHome, 'homeHome')
                  "
                  >&nbsp; {{ $t('football.league-details.home') }}</span
                >
          
              </p>
              <p data-v-557138e8="">
                <span
                  data-v-557138e8=""
                  :style="
                    homeDropdownRadioLeague ? 'display:block' : 'display: none'
                  "
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioLeague, 'homeLeague')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    homeDropdownRadioLeague ? 'display:none' : 'display: block'
                  "
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioLeague, 'homeLeague')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    homeToggleRadio(homeDropdownRadioLeague, 'homeLeague')
                  "
                  >&nbsp; {{ $t('football.league-details.this-league') }}</span
                >
              </p>
            </div>

            <div data-v-25ea6f07="" class="infoBox">
              <span data-v-25ea6f07=""
                ><span data-v-25ea6f07="" class="round win flexCenter">W</span>
                <span data-v-25ea6f07="" class="text">X3</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><i data-v-25ea6f07="" class="iconfont icon-pingju"></i>
                <span data-v-25ea6f07="" class="text">X1</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><span data-v-25ea6f07="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-25ea6f07="" class="text">X2</span></span
              >
            </div>

            <template
              v-if="
                todayMatchDetails &&
                todayMatchDetails.matchH2H &&
                todayMatchDetails.matchH2H.home
              "
            >
              <span v-if="!homeDropdownRadioHome">
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.home"
                  :key="key"
                >
                  <span v-if="homeDropdownRadioLeague">
                    <!-- begin::League Indo-->
                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span
                          v-if="
                            !homeDropdownRadioHome &&
                            todayMatchDetails.homeInfo.en_name ==
                              match.homeInfo.name
                          "
                        >
                          <li data-v-25ea6f07="" v-if="index < homeSize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                      itemprop="logo"
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        todayMatchDetails.leagueInfo.en_name ==
                        home[0].leagueInfo.name
                      "
                    >
                      <!-- begin::League Indo-->
                      <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                      <!-- end::League Indo-->

                      <!-- begin::Teams Info  -->
                      <ul data-v-25ea6f07="" class="matchBox">
                        <span v-for="(match, index) in home" :key="index">
                          <span
                            v-if="
                              !homeDropdownRadioHome &&
                              todayMatchDetails.homeInfo.en_name ==
                                match.homeInfo.name
                            "
                          >
                            <li data-v-25ea6f07="" v-if="index < homeSize">
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="match.matchStatus == 1"
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score > match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter win"
                                >
                                  W
                                </span>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score < match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter loser"
                                >
                                  L
                                </span>
                              </p>
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score == match.awayInfo.score
                                "
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <a data-v-25ea6f07="" target="_blank">
                                <p data-v-25ea6f07="" class="time flexCenter">
                                  {{ match.matchTime }}
                                </p>
                                <p data-v-25ea6f07="" class="teamBox">
                                  <!-- begin:: home Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.homeInfo.logo &&
                                          match.homeInfo.logo
                                            ? match.homeInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                      >{{
                                        match &&
                                        match.homeInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.homeInfo.cn_name
                                          : match.homeInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end::home Team -->
                                  <!-- begin::Away Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.awayInfo.logo &&
                                          match.awayInfo.logo
                                            ? match.awayInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                        itemprop="logo"
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                    >
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.awayInfo.cn_name
                                          : match.awayInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end:: Away Team -->
                                </p>
                                <!-- begin::home -->
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.halfScore
                                      ? match.homeInfo.halfScore
                                      : "0"
                                  }}</span>
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.halfScore
                                      ? match.awayInfo.halfScore
                                      : "0"
                                  }}</span>
                                </p>
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      match.homeInfo.score
                                        ? match.homeInfo.score
                                        : "0"
                                    }}
                                  </span>
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.score
                                        ? match.awayInfo.score
                                        : "0"
                                    }}
                                  </span>
                                </p>
                              </a>
                              <!-- end::home -->
                            </li>
                          </span>
                        </span>
                      </ul>
                      <!-- end::Teams Info -->
                    </span>
                  </span>
                </div>
              </span>

              <span v-else-if="!homeDropdownRadioLeague">
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.home"
                  :key="key"
                >
                  <span
                    v-if="
                      todayMatchDetails.leagueInfo.en_name ==
                      home[0].leagueInfo.name
                    "
                  >
                    <!-- begin::League Indo-->
                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span>
                          <li data-v-25ea6f07="" v-if="index < homeSize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                      itemprop="logo"
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                </div>
              </span>

              <span v-else>
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.home"
                  :key="key"
                >
                  <!-- begin::League Indo-->
                  <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                  <!-- end::League Indo-->

                  <!-- begin::Teams Info  -->
                  <ul data-v-25ea6f07="" class="matchBox">
                    <span v-for="(match, index) in home" :key="index">
                      <span>
                        <li data-v-25ea6f07="" v-if="index < homeSize">
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="match.matchStatus == 1"
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score > match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter win"
                            >
                              W
                            </span>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score < match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter loser"
                            >
                              L
                            </span>
                          </p>
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score == match.awayInfo.score
                            "
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <a data-v-25ea6f07="" target="_blank">
                            <p data-v-25ea6f07="" class="time flexCenter">
                              {{ match.matchTime }}
                            </p>
                            <p data-v-25ea6f07="" class="teamBox">
                              <!-- begin:: home Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.homeInfo.logo &&
                                      match.homeInfo.logo
                                        ? match.homeInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                  >{{
                                    match &&
                                    match.homeInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.homeInfo.cn_name
                                      : match.homeInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end::home Team -->
                              <!-- begin::Away Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.awayInfo.logo &&
                                      match.awayInfo.logo
                                        ? match.awayInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                    itemprop="logo"
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.awayInfo.cn_name
                                      : match.awayInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end:: Away Team -->
                            </p>
                            <!-- begin::home -->
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.halfScore
                                  ? match.homeInfo.halfScore
                                  : "0"
                              }}</span>
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.halfScore
                                  ? match.awayInfo.halfScore
                                  : "0"
                              }}</span>
                            </p>
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.score
                                    ? match.homeInfo.score
                                    : "0"
                                }}
                              </span>
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.score
                                    ? match.awayInfo.score
                                    : "0"
                                }}
                              </span>
                            </p>
                          </a>
                          <!-- end::home -->
                        </li>
                      </span>
                    </span>
                  </ul>
                  <!-- end::Teams Info -->
                </div>
              </span>
            </template>
          </div>
          <!-- end::allBox -->
        </div>
        <!-- end::home league h2h -->
        <!-- begin::away league h2h -->
        <div data-v-25ea6f07="" class="contentBox flex-1 rightMargin12">
          <!-- begin::allBox -->
          <div data-v-25ea6f07="" class="allBox">
            <!-- begin::dropdown -->
            <div data-v-25ea6f07="" class="headerBox">
              <p data-v-25ea6f07="">
                <img
                  data-v-25ea6f07=""
                  :src="
                    todayMatchDetails &&
                    todayMatchDetails.awayInfo &&
                    todayMatchDetails.awayInfo.logo
                      ? todayMatchDetails.awayInfo.logo + '!w30'
                     : bannerImagePath('default-country.png')
                  "
                  alt="#"
                /><span data-v-25ea6f07="">{{
                  todayMatchDetails &&
                  todayMatchDetails.awayInfo &&
                  this.$i18n.locale == "cn"
                    ? todayMatchDetails.awayInfo.cn_name
                    : todayMatchDetails.awayInfo.en_name
                }}</span>
              </p>
              <p data-v-3a4ccff5="">
                <span
                  data-v-3a4ccff5=""
                  class="select flexCenter"
                  v-on:click="awayToggleDropdown(awayDropdownSize)"
                >
                  {{ awaySize }}
                  <i data-v-3a4ccff5="" class="iconfont icon-xiala"></i>
                  <span
                    data-v-3a4ccff5=""
                    class="selectNum"
                    :style="awayDropdownSize ? 'display:block' : 'display:none'"
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="awayToggleSize(2)"
                      >2</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="awayToggleSize(3)"
                      >3</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="awayToggleSize(4)"
                      >4</span
                    ><span
                      data-v-3a4ccff5=""
                      class="flexCenter"
                      v-on:click="awayToggleSize(5)"
                      >5</span
                    ></span
                  ></span
                >
              </p>
            </div>

            <div data-v-557138e8="" class="screenBox">
              <p data-v-557138e8="" class="mr-16">
                <span
                  data-v-557138e8=""
                  :style="
                    awayDropdownRadioAway ? 'display:block' : 'display: none'
                  "
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioAway, 'awayAway')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    awayDropdownRadioAway ? 'display:none' : 'display: block'
                  "
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioAway, 'awayAway')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioAway, 'awayAway')
                  "
                  >&nbsp; {{ $t('football.league-details.away') }}</span
                >
             
              </p>
              <p data-v-557138e8="">
                <span
                  data-v-557138e8=""
                  :style="
                    awayDropdownRadioLeague ? 'display:block' : 'display: none'
                  "
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioLeague, 'awayLeague')
                  "
                ></span>
                <i
                  data-v-557138e8=""
                  class="iconfont icon-xuanzhong"
                  :style="
                    awayDropdownRadioLeague ? 'display:none' : 'display: block'
                  "
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioLeague, 'awayLeague')
                  "
                ></i>
                <span
                  data-v-557138e8=""
                  v-on:click="
                    awayToggleRadio(awayDropdownRadioLeague, 'awayLeague')
                  "
                  >&nbsp;  {{ $t('football.league-details.this-league') }}</span
                >
              </p>
            </div>

            <div data-v-25ea6f07="" class="infoBox">
              <span data-v-25ea6f07=""
                ><span data-v-25ea6f07="" class="round win flexCenter">W</span>
                <span data-v-25ea6f07="" class="text">X3</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><i data-v-25ea6f07="" class="iconfont icon-pingju"></i>
                <span data-v-25ea6f07="" class="text">X1</span></span
              >
              <span data-v-25ea6f07="" class="leftMargin20"
                ><span data-v-25ea6f07="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-25ea6f07="" class="text">X2</span></span
              >
            </div>

            <template
              v-if="
                todayMatchDetails &&
                todayMatchDetails.matchH2H &&
                todayMatchDetails.matchH2H.away
              "
            >
              <span v-if="!awayDropdownRadioAway">
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.away"
                  :key="key"
                >
                  <span v-if="awayDropdownRadioLeague">
                    <!-- begin::League Indo-->
                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span
                          v-if="
                            !awayDropdownRadioAway &&
                            todayMatchDetails.awayInfo.en_name ==
                              match.awayInfo.name
                          "
                        >
                          <li data-v-25ea6f07="" v-if="index < awaySize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        todayMatchDetails.leagueInfo.en_name ==
                        home[0].leagueInfo.name
                      "
                    >
                      <!-- begin::League Indo-->
                      <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                      <!-- end::League Indo-->

                      <!-- begin::Teams Info  -->
                      <ul data-v-25ea6f07="" class="matchBox">
                        <span v-for="(match, index) in home" :key="index">
                          <span
                            v-if="
                              !awayDropdownRadioAway &&
                              todayMatchDetails.awayInfo.en_name ==
                                match.awayInfo.name
                            "
                          >
                            <li data-v-25ea6f07="" v-if="index < awaySize">
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="match.matchStatus == 1"
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score > match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter win"
                                >
                                  W
                                </span>
                              </p>

                              <p
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score < match.awayInfo.score
                                "
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                              >
                                <span
                                  data-v-25ea6f07=""
                                  class="round flexCenter loser"
                                >
                                  L
                                </span>
                              </p>
                              <p
                                data-v-25ea6f07=""
                                class="collect flexCenter"
                                v-if="
                                  match.matchStatus == 8 &&
                                  match.homeInfo &&
                                  match.awayInfo &&
                                  match.homeInfo.score == match.awayInfo.score
                                "
                              >
                                <i
                                  data-v-25ea6f07=""
                                  class="iconfont icon-pingju"
                                ></i>
                              </p>

                              <a data-v-25ea6f07="" target="_blank">
                                <p data-v-25ea6f07="" class="time flexCenter">
                                  {{ match.matchTime }}
                                </p>
                                <p data-v-25ea6f07="" class="teamBox">
                                  <!-- begin:: home Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.homeInfo.logo &&
                                          match.homeInfo.logo
                                            ? match.homeInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                      >{{
                                        match &&
                                        match.homeInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.homeInfo.cn_name
                                          : match.homeInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end::home Team -->
                                  <!-- begin::Away Team -->
                                  <span data-v-25ea6f07="" class="teamBoxItem"
                                    ><span data-v-25ea6f07=""
                                      ><img
                                        data-v-25ea6f07=""
                                        :src="
                                          match &&
                                          match.awayInfo.logo &&
                                          match.awayInfo.logo
                                            ? match.awayInfo.logo + '!w30'
                                           : bannerImagePath('default-country.png')
                                        "
                                        itemprop="logo"
                                      />
                                    </span>
                                    <span
                                      data-v-25ea6f07=""
                                      itemprop="name"
                                      class="teamName"
                                    >
                                      {{
                                        match &&
                                        match.awayInfo &&
                                        this.$i18n.locale == "cn"
                                          ? match.awayInfo.cn_name
                                          : match.awayInfo.name
                                      }}
                                    </span></span
                                  >
                                  <!-- end:: Away Team -->
                                </p>
                                <!-- begin::home -->
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.halfScore
                                      ? match.homeInfo.halfScore
                                      : "0"
                                  }}</span>
                                  <span data-v-25ea6f07="" class="flexCenter">{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.halfScore
                                      ? match.awayInfo.halfScore
                                      : "0"
                                  }}</span>
                                </p>
                                <p data-v-25ea6f07="" class="scoreBox">
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.homeInfo &&
                                      match.homeInfo.score
                                        ? match.homeInfo.score
                                        : "0"
                                    }}
                                  </span>
                                  <span data-v-25ea6f07="" class="flexCenter">
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      match.awayInfo.score
                                        ? match.awayInfo.score
                                        : "0"
                                    }}
                                  </span>
                                </p>
                              </a>
                              <!-- end::home -->
                            </li>
                          </span>
                        </span>
                      </ul>
                      <!-- end::Teams Info -->
                    </span>
                  </span>
                </div>
              </span>

              <span v-else-if="!awayDropdownRadioLeague">
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.away"
                  :key="key"
                >
                  <span
                    v-if="
                      todayMatchDetails.leagueInfo.en_name ==
                      home[0].leagueInfo.name
                    "
                  >
                    <!-- begin::League Indo-->
                    <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                    <!-- end::League Indo-->

                    <!-- begin::Teams Info  -->
                    <ul data-v-25ea6f07="" class="matchBox">
                      <span v-for="(match, index) in home" :key="index">
                        <span>
                          <li data-v-25ea6f07="" v-if="index < awaySize">
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="match.matchStatus == 1"
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score > match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter win"
                              >
                                W
                              </span>
                            </p>

                            <p
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score < match.awayInfo.score
                              "
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                            >
                              <span
                                data-v-25ea6f07=""
                                class="round flexCenter loser"
                              >
                                L
                              </span>
                            </p>
                            <p
                              data-v-25ea6f07=""
                              class="collect flexCenter"
                              v-if="
                                match.matchStatus == 8 &&
                                match.homeInfo &&
                                match.awayInfo &&
                                match.homeInfo.score == match.awayInfo.score
                              "
                            >
                              <i
                                data-v-25ea6f07=""
                                class="iconfont icon-pingju"
                              ></i>
                            </p>

                            <a data-v-25ea6f07="" target="_blank">
                              <p data-v-25ea6f07="" class="time flexCenter">
                                {{ match.matchTime }}
                              </p>
                              <p data-v-25ea6f07="" class="teamBox">
                                <!-- begin:: home Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.homeInfo.logo &&
                                        match.homeInfo.logo
                                          ? match.homeInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                    >{{
                                      match &&
                                      match.homeInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.homeInfo.cn_name
                                        : match.homeInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end::home Team -->
                                <!-- begin::Away Team -->
                                <span data-v-25ea6f07="" class="teamBoxItem"
                                  ><span data-v-25ea6f07=""
                                    ><img
                                      data-v-25ea6f07=""
                                      :src="
                                        match &&
                                        match.awayInfo.logo &&
                                        match.awayInfo.logo
                                          ? match.awayInfo.logo + '!w30'
                                         : bannerImagePath('default-country.png')
                                      "
                                      itemprop="logo"
                                    />
                                  </span>
                                  <span
                                    data-v-25ea6f07=""
                                    itemprop="name"
                                    class="teamName"
                                  >
                                    {{
                                      match &&
                                      match.awayInfo &&
                                      this.$i18n.locale == "cn"
                                        ? match.awayInfo.cn_name
                                        : match.awayInfo.name
                                    }}
                                  </span></span
                                >
                                <!-- end:: Away Team -->
                              </p>
                              <!-- begin::home -->
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.halfScore
                                    ? match.homeInfo.halfScore
                                    : "0"
                                }}</span>
                                <span data-v-25ea6f07="" class="flexCenter">{{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.halfScore
                                    ? match.awayInfo.halfScore
                                    : "0"
                                }}</span>
                              </p>
                              <p data-v-25ea6f07="" class="scoreBox">
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.score
                                      ? match.homeInfo.score
                                      : "0"
                                  }}
                                </span>
                                <span data-v-25ea6f07="" class="flexCenter">
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.score
                                      ? match.awayInfo.score
                                      : "0"
                                  }}
                                </span>
                              </p>
                            </a>
                            <!-- end::home -->
                          </li>
                        </span>
                      </span>
                    </ul>
                    <!-- end::Teams Info -->
                  </span>
                </div>
              </span>

              <span v-else>
                <div
                  data-v-25ea6f07=""
                  class="matchContent"
                  v-for="(home, key) in todayMatchDetails.matchH2H.away"
                  :key="key"
                >
                  <!-- begin::League Indo-->
                  <p data-v-25ea6f07="" class="header">
                        <span data-v-25ea6f07="" class="comp flexCenter">
                       <span v-if="  home[0] &&
                              home[0].leagueInfo &&
                              home[0].leagueInfo.countryDetails && home[0].leagueInfo.countryDetails.name">
                        <img
                            data-v-25ea6f07=""
                            :src="
                            
                              home[0].leagueInfo.countryDetails.logo
                                ? home[0].leagueInfo.countryDetails.logo +
                                  '!w30'
                               : bannerImagePath('default-country.png')
                            "
                            alt="#"
                          />
                          <span data-v-25ea6f07="" class="country flexCenter">
                            {{
                        
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.countryDetails.cn_name
                                : home[0].leagueInfo.countryDetails.name
                            }}:
                          </span>
                       </span>
                          <span data-v-25ea6f07="" class="flexCenter">
                            {{
                              home[0] &&
                              home[0].leagueInfo &&
                              this.$i18n.locale == "cn"
                                ? home[0].leagueInfo.cn_name
                                : home[0].leagueInfo.name
                            }}
                          </span></span
                        >
                   
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >HT</span
                      >
                      <span data-v-25ea6f07="" class="score flexCenter"
                        >FT</span
                      >
                    </p>
                  <!-- end::League Indo-->

                  <!-- begin::Teams Info  -->
                  <ul data-v-25ea6f07="" class="matchBox">
                    <span v-for="(match, index) in home" :key="index">
                      <span>
                        <li data-v-25ea6f07="" v-if="index < awaySize">
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="match.matchStatus == 1"
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score > match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter win"
                            >
                              W
                            </span>
                          </p>

                          <p
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score < match.awayInfo.score
                            "
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                          >
                            <span
                              data-v-25ea6f07=""
                              class="round flexCenter loser"
                            >
                              L
                            </span>
                          </p>
                          <p
                            data-v-25ea6f07=""
                            class="collect flexCenter"
                            v-if="
                              match.matchStatus == 8 &&
                              match.homeInfo &&
                              match.awayInfo &&
                              match.homeInfo.score == match.awayInfo.score
                            "
                          >
                            <i
                              data-v-25ea6f07=""
                              class="iconfont icon-pingju"
                            ></i>
                          </p>

                          <a data-v-25ea6f07="" target="_blank">
                            <p data-v-25ea6f07="" class="time flexCenter">
                              {{ match.matchTime }}
                            </p>
                            <p data-v-25ea6f07="" class="teamBox">
                              <!-- begin:: home Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.homeInfo.logo &&
                                      match.homeInfo.logo
                                        ? match.homeInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                  >{{
                                    match &&
                                    match.homeInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.homeInfo.cn_name
                                      : match.homeInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end::home Team -->
                              <!-- begin::Away Team -->
                              <span data-v-25ea6f07="" class="teamBoxItem"
                                ><span data-v-25ea6f07=""
                                  ><img
                                    data-v-25ea6f07=""
                                    :src="
                                      match &&
                                      match.awayInfo.logo &&
                                      match.awayInfo.logo
                                        ? match.awayInfo.logo + '!w30'
                                       : bannerImagePath('default-country.png')
                                    "
                                    itemprop="logo"
                                  />
                                </span>
                                <span
                                  data-v-25ea6f07=""
                                  itemprop="name"
                                  class="teamName"
                                >
                                  {{
                                    match &&
                                    match.awayInfo &&
                                    this.$i18n.locale == "cn"
                                      ? match.awayInfo.cn_name
                                      : match.awayInfo.name
                                  }}
                                </span></span
                              >
                              <!-- end:: Away Team -->
                            </p>
                            <!-- begin::home -->
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.halfScore
                                  ? match.homeInfo.halfScore
                                  : "0"
                              }}</span>
                              <span data-v-25ea6f07="" class="flexCenter">{{
                                match &&
                                match.awayInfo &&
                                match.awayInfo.halfScore
                                  ? match.awayInfo.halfScore
                                  : "0"
                              }}</span>
                            </p>
                            <p data-v-25ea6f07="" class="scoreBox">
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.homeInfo &&
                                  match.homeInfo.score
                                    ? match.homeInfo.score
                                    : "0"
                                }}
                              </span>
                              <span data-v-25ea6f07="" class="flexCenter">
                                {{
                                  match &&
                                  match.awayInfo &&
                                  match.awayInfo.score
                                    ? match.awayInfo.score
                                    : "0"
                                }}
                              </span>
                            </p>
                          </a>
                          <!-- end::home -->
                        </li>
                      </span>
                    </span>
                  </ul>
                  <!-- end::Teams Info -->
                </div>
              </span>
            </template>
          </div>
          <!-- end::allBox -->
        </div>
        <!-- end::away league h2h -->
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      todayMatchDetails: null,
      loading: true,
      leagueSize: 5,
      leagueDropdownSize: false,
      leagueDropdownRadioLeague: true,
      leagueDropdownRadioHome: true,
      homeSize: 5,
      homeDropdownSize: false,
      homeDropdownRadioLeague: true,
      homeDropdownRadioHome: true,
      awaySize: 5,
      awayDropdownSize: false,
      awayDropdownRadioLeague: true,
      awayDropdownRadioAway: true,
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/football/match/details/` + matchId)
        .then((response) => {
          this.todayMatchDetails = response.data.matchDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    leagueToggleSize(size) {
      this.leagueSize = size;
    },
    leagueToggleDropdown(state) {
      this.leagueDropdownSize = !state;
    },
    leagueToggleRadio(state, categoey) {
      if (categoey == "leagueHome") {
        this.leagueDropdownRadioHome = !state;
      } else {
        this.leagueDropdownRadioLeague = !state;
      }
    },
    homeToggleSize(size) {
      this.homeSize = size;
    },
    homeToggleDropdown(state) {
      this.homeDropdownSize = !state;
    },
    homeToggleRadio(state, categoey) {
      if (categoey == "homeHome") {
        this.homeDropdownRadioHome = !state;
      } else {
        this.homeDropdownRadioLeague = !state;
      }
    },
    awayToggleSize(size) {
      this.awaySize = size;
    },
    awayToggleDropdown(state) {
      this.awayDropdownSize = !state;
    },
    awayToggleRadio(state, categoey) {
      if (categoey == "awayAway") {
        this.awayDropdownRadioAway = !state;
      } else {
        this.awayDropdownRadioLeague = !state;
      }
    },
  },
};
</script>
