// src/router/index.js

import { createRouter, createWebHistory } from "vue-router";
import { redirects } from "./redirects"; // Import redirection rules
import routesEn from "./routes.en";
import routesCn from "./routes.cn";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Import redirection rules
    ...redirects,
    // Import English routes
    ...routesEn,
    // Import Chinese routes
    ...routesCn,
  ],
});

export default router;
