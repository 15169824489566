<template>
  <div style="width: 1200px; margin: 0 auto" data-v-5754b2d9="">
    <br />
    <div class="scorecardBox" data-v-22d2c895="" data-v-5754b2d9="">
      <!-- begin::template -->

      <div class="scorecard" data-v-22d2c895="" v-if="loading">
        <p class="title" data-v-22d2c895="">
          {{ $t("football.league-details.score-card") }}
        </p>
        <!-- begin::innings -->
        <div class="btnBox mt-20" data-v-22d2c895="">
          <span>
            <img
              src="/images/default-loader.gif"
              style="width: 120px !important; height: 60px !important"
            />

            &nbsp;
          </span>
        </div>
      </div>

      <div
        class="scorecard"
        data-v-22d2c895=""
        v-else-if="!loading && matchScoreDetails && matchScoreDetails != ''"
      >
        <p class="title" data-v-22d2c895="">
          {{ $t("football.league-details.score-card") }}
        </p>
        <!-- begin::innings -->
        <div class="btnBox mt-20" data-v-22d2c895="">
          <span v-for="inning in matchScoreDetails" :key="inning">
            <span
              :class="inning.number == activeInning ? 'active' : ''"
              class="inline-flex justify-center align-center"
              data-v-22d2c895=""
              v-on:click="toggleInnings(inning.number)"
            >
              {{ inning.battingTeam.name }}
            </span>
            &nbsp;
          </span>
        </div>
        <!-- end::innings -->
        <div class="publicTable mt-20" data-v-5a4085f6="" data-v-22d2c895="">
          <!-- begin::header -->
          <ul class="list header" data-v-5a4085f6="">
            <li
              style="width: 30%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              {{ $t("football.league-details.batsman") }}
            </li>
            <li
              style="width: 30%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              {{ $t("football.league-details.dismissal") }}
            </li>
            <li style="width: 8%; background-color: #f1f1f1" data-v-5a4085f6="">
              R
            </li>
            <li style="width: 8%; background-color: #f1f1f1" data-v-5a4085f6="">
              B
            </li>
            <li style="width: 8%; background-color: #f1f1f1" data-v-5a4085f6="">
              4s
            </li>
            <li style="width: 8%; background-color: #f1f1f1" data-v-5a4085f6="">
              6s
            </li>
            <li style="width: 8%; background-color: #f1f1f1" data-v-5a4085f6="">
              SR
            </li>
          </ul>
          <!-- end::header -->
          <!-- begin::batting team -->
          <template v-for="battingTeam in matchScoreDetails" :key="battingTeam">
            <!-- check active innings -->
            <span v-if="battingTeam.number == activeInning">
              <!-- display battings line -->
              <ul
                class="list tableData"
                data-v-5a4085f6=""
                v-for="batting in battingTeam.battingLine"
                :key="batting"
              >
                <li class="toLeft" style="width: 30%" data-v-5a4085f6="">
                  <a
                    style="color: black; display: flex"
                    target="_blank"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/team/details/' +
                      batting.player.name.toLowerCase().replace(/\s/g, '-') +
                      '/' +
                      batting.player.id
                    "
                  >
                    <img
                      style="
                        width: 40px !important;
                        height: 50px !important;
                        border-radius: 50%;
                        top: 0px;
                      "
                      :src="
                        batting.player.id
                          ? 'https://sportyclub.online//images/cricket/players/' +
                            batting.player.id +
                            '/' +
                            batting.player.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src =
                          bannerImagePath('default-player.png')
                      "
                      alt="#"
                      class="teamLogo"
                      data-v-5a4085f6=""
                    />
                    <div style="align-content: center; margin-left: 0.5rem">
                      <b>
                        {{ batting.player.name }}
                      </b>
                    </div>
                  </a>
                </li>
                <li class="toLeft" style="width: 30%" data-v-5a4085f6="">
                  <!-- if wicket type is Bowled -->
                  <span
                    v-if="batting.wicketTypeId == activeInning"
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      b
                      {{ batting.wicketBowlerName }}
                    </span>
                  </span>

                  <!-- if wicket type is LBW -->
                  <span
                    v-if="batting.wicketTypeId == 2"
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      lbw
                      {{ batting.wicketBowlerName }}
                    </span>
                  </span>
                  <!-- if wicket type is catch -->
                  <span
                    v-if="batting.wicketTypeId == 3"
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      c {{ batting.wicketBowlerName }} b
                      {{ batting.wicketBowlerName }}
                    </span>
                  </span>
                  <!-- if wicket type is runout -->
                  <span
                    v-if="batting.wicketTypeId == 5"
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      r out {{ batting.wicketBowlerName }}
                    </span>
                  </span>
                  <!-- if wicket type is catch & bowl -->
                  <span
                    v-if="batting.wicketTypeId == 6"
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      c & b {{ batting.wicketBowlerName }}
                    </span>
                  </span>
                  <!-- if wicket not out -->
                  <span
                    v-if="
                      batting.wicketTypeId == 8 || batting.wicketTypeId == 10
                    "
                    class="bh"
                    data-v-5a4085f6=""
                  >
                    <span data-v-5a4085f6="" class="">
                      {{ $t("football.league-details.not-out") }}
                    </span>
                  </span>
                  <!-- did not bat-->
                </li>
                <li style="width: 8%" data-v-5a4085f6="" class="">
                  <span title="137" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ batting.wicketTypeId == 9 ? "-" : batting.score }}
                    </span></span
                  >
                </li>
                <li style="width: 8%" data-v-5a4085f6="" class="">
                  <span title="120" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ batting.wicketTypeId == 9 ? "-" : batting.balls }}
                    </span></span
                  >
                </li>
                <li style="width: 8%" data-v-5a4085f6="" class="">
                  <span title="15" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ batting.wicketTypeId == 9 ? "-" : batting.s4 }}
                    </span></span
                  >
                </li>
                <li style="width: 8%" data-v-5a4085f6="" class="">
                  <span title="4" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ batting.wicketTypeId == 9 ? "-" : batting.s6 }}
                    </span></span
                  >
                </li>
                <li style="width: 8%" data-v-5a4085f6="" class="">
                  <span title="114.17" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{
                        batting.wicketTypeId == 9
                          ? "-"
                          : ((batting.score / batting.balls) * 100).toFixed(0)
                      }}
                    </span>
                  </span>
                </li>
              </ul>
            </span>
          </template>
          <!-- end::batting team -->
        </div>
        <!-- begin::display extra and score -->
        <template v-for="extra in matchScoreDetails" :key="extra">
          <span v-if="extra.number == activeInning">
            <div class="mt-10" data-v-22d2c895="">
              {{ $t("football.league-details.extra") }}:
              <span class="ml-10" data-v-22d2c895="">{{ extra.extra }}</span>
            </div>

            <div class="mt-10" data-v-22d2c895="">
              {{ $t("football.league-details.total") }}:
              <span class="ml-10" data-v-22d2c895=""
                >{{ extra.score + "/" + extra.wickets }} ({{ extra.overs }}
                {{ $t("football.league-details.overs") }})</span
              >
            </div>
          </span>
        </template>
        <!-- end::display extra and score -->
        <div class="publicTable mt-30" data-v-5a4085f6="" data-v-22d2c895="">
          <!-- begin::header -->
          <ul class="list header" data-v-5a4085f6="">
            <li
              style="width: 30%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              {{ $t("football.league-details.bowler") }}
            </li>
            <li
              style="width: 14%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              O
            </li>
            <li
              style="width: 14%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              M
            </li>
            <li
              style="width: 14%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              R
            </li>
            <li
              style="width: 14%; background-color: #f1f1f1"
              data-v-5a4085f6=""
            >
              W
            </li>
          </ul>
          <!-- end::header -->
          <!-- display batting line -->
          <template v-for="bowlingTeam in matchScoreDetails" :key="bowlingTeam">
            <!-- check active innings-->
            <span v-if="bowlingTeam.number == activeInning">
              <!-- display bowling line -->
              <ul
                class="list tableData"
                data-v-5a4085f6=""
                v-for="bowler in bowlingTeam.bowlingLine"
                :key="bowler"
              >
                <li class="toLeft" style="width: 30%" data-v-5a4085f6="">
                  <a
                    target="_blank"
                    style="color: black; display: flex"
                    :href="
                      '/' +
                      this.$i18n.locale +
                      '/cricket/player/details/' +
                      bowler.player.name.toLowerCase().replace(/\s/g, '-') +
                      '/' +
                      bowler.player.id
                    "
                  >
                    <img
                      style="
                        width: 40px !important;
                        height: 50px !important;
                        border-radius: 50%;
                        top: 0px;
                      "
                      :src="
                        bowler.player.id
                          ? 'https://sportyclub.online//images/cricket/players/' +
                            bowler.player.id +
                            '/' +
                            bowler.player.id +
                            '.png'
                          : bannerImagePath('default-team.png')
                      "
                      @error="
                        $event.target.src =
                          bannerImagePath('default-player.png')
                      "
                      alt="#"
                      class="teamLogo"
                      data-v-5a4085f6=""
                    />
                    <div
                      data-v-5a4085f6=""
                      style="align-content: center; margin-left: 0.5rem"
                    >
                      <b> {{ bowler.player.name }}</b>
                    </div>
                  </a>
                </li>
                <li style="width: 14%" data-v-5a4085f6="" class="">
                  <span title="9" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ bowler.over }}
                    </span></span
                  >
                </li>
                <li style="width: 14%" data-v-5a4085f6="" class="">
                  <span title="0" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ bowler.maiden }}</span
                    ></span
                  >
                </li>
                <li style="width: 14%" data-v-5a4085f6="" class="">
                  <span title="43" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class="">
                      {{ bowler.run }}
                    </span></span
                  >
                </li>
                <li style="width: 14%" data-v-5a4085f6="" class="">
                  <span title="2" class="bh" data-v-5a4085f6="">
                    <span data-v-5a4085f6="" class=""
                      >{{ bowler.wicket }}
                    </span></span
                  >
                </li>
              </ul>
            </span>
          </template>
        </div>
      </div>

      <!-- begin:: if data is not available -->
      <div
        class="container-box mb-12 pb-12"
        data-v-7c947e55=""
        data-v-8d245a92=""
        v-else
      >
        <div class="w100" data-v-7c947e55="" data-v-8d245a92="">
          <div
            class="w100"
            data-v-975a1620=""
            data-v-8d245a92=""
            data-v-7c947e55=""
          >
            <div data-v-975a1620="">
              <div
                id="standings"
                class="justify-between align-center StatsType border-box flex"
                data-v-975a1620=""
              >
                <div class="color-p fs-14 font-500 w-o-h" data-v-975a1620="">
                  {{ $t("football.league-details.score-card") }}
                </div>
              </div>
              <div
                class="flex justify-center align-center flex-col"
                style="height: 300px"
                data-v-975a1620=""
              >
                <img :src="bannerImagePath('no-data.png')" />
                <div class="color-999 fs-12 mt-12">
                  {{ $t("football.live-score.temporarily-no-data") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::data is not available -->
      <!-- end::template -->
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      matchScoreDetails: null,
      loading: true,
      activeInning: 1,
    };
  },

  created() {
    this.getMatchScore(this.matchId);
  },
  methods: {
    // method to get home team matches
    getMatchScore(matchId) {
      console.log(matchId);
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/single/match/innings/${matchId}`
        )
        .then((response) => {
          this.matchScoreDetails = response.data.innings;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleInnings(num) {
      this.activeInning = num;
    },
  },
};
</script>
