<template>
    <span class="mobile-version">
        <MobileVersion />
    </span>
    <span class="desktop-version">
        <DesktopVersion />
    </span>
</template>


<script>
import DesktopVersion from "./desktop-version/match-details-desktop.vue";
import MobileVersion from "./mobile-version/match-details-mobile.vue";
export default {
    components: {
        DesktopVersion,
        MobileVersion


    },


}

</script>
