<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="competition view border-box" data-v-8948368c="" v-if="!loading">
    <!-- begin::breadcrumb -->
    <div class="breadcrumb-box" data-v-8948368c="">
      <div class="content-box" data-v-8948368c="">
        <ul class="breadcrumb" data-v-8948368c="">
          <li data-v-8948368c="">
            <a
              :href="'/' + this.$i18n.locale + '/live/hockey'"
              class="link"
              data-v-8948368c=""
            >
              {{ $t("hockey.league-details.hockey-live-score") }}
            </a>
          </li>
          <li data-v-8948368c="">
            {{
              leagueDetails &&
              this.$i18n.locale == "cn" &&
              leagueDetails.cn_name
                ? leagueDetails.cn_name
                : leagueDetails.name
                ? leagueDetails.name
                : ""
            }}
          </li>
   
        </ul>
      </div>
    </div>
    <!-- end::breadcrumb -->
    <!-- begin::title holder info -->

    <div class="tourHeader" data-v-1565c0b8="">
      <div class="topInfo flex align-center" data-v-1565c0b8="">
        <img
          :src="
            leagueDetails && leagueDetails.logo
              ? leagueDetails.logo + '!w100'
             : bannerImagePath('default-nation.png')
          "
          :alt="leagueDetails && leagueDetails.name ? leagueDetails.name : ''"
          :title="leagueDetails && leagueDetails.name ? leagueDetails.name : ''"
          class="compImg"
          data-v-1565c0b8=""
        />
        <div class="compInfo" data-v-1565c0b8="">
          <p class="compName" data-v-1565c0b8="">
            {{
              leagueDetails &&
              this.$i18n.locale == "cn" &&
              leagueDetails.cn_name
                ? leagueDetails.cn_name
                : leagueDetails.name
                ? leagueDetails.name
                : ""
            }}
          </p>
          <div class="flex align-center countryInfo" data-v-1565c0b8="">
            <img
              class="countryImg"
              v-if="
                leagueDetails &&
                leagueDetails.countryDetails &&
                leagueDetails.countryDetails.logo
              "
              :src="
                leagueDetails &&
                leagueDetails.countryDetails &&
                leagueDetails.countryDetails.logo
                  ? leagueDetails.countryDetails.logo
                 : bannerImagePath('default-country.png')
              "
              data-v-1565c0b8=""
            />
            <span class="countryName" data-v-1565c0b8="">
              <template v-if="leagueDetails">
                <template v-if="this.$i18n.locale == 'cn'">
                  <template
                    v-if="
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.cn_name
                    "
                  >
                    {{ leagueDetails.countryDetails.cn_name }}
                  </template>
                  <template
                    v-else-if="
                      leagueDetails.categoryDetails &&
                      leagueDetails.categoryDetails.cn_name
                    "
                  >
                    {{ leagueDetails.categoryDetails.cn_name }}
                  </template>
                  <template
                    v-else-if="
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.name
                    "
                  >
                    {{ leagueDetails.countryDetails.name }}
                  </template>
                  <template
                    v-else-if="
                      leagueDetails.categoryDetails &&
                      leagueDetails.categoryDetails.name
                    "
                  >
                    {{ leagueDetails.categoryDetails.name }}
                  </template>
                </template>
                <template v-else>
                  <template
                    v-if="
                      leagueDetails.countryDetails &&
                      leagueDetails.countryDetails.name
                    "
                  >
                    {{ leagueDetails.countryDetails.name }}
                  </template>
                  <template
                    v-else-if="
                      leagueDetails.categoryDetails &&
                      leagueDetails.categoryDetails.name
                    "
                  >
                    {{ leagueDetails.categoryDetails.name }}
                  </template>
                  <template v-else> </template>
                </template>
              </template>
            </span>
          </div>
        </div>
      </div>
      <div class="tab flex align-center" data-v-1565c0b8="">
        <a
          :class="activeTab == 'standings' ? 'active' : ''"
          target="_self"
          class="flex align-center active"
          data-v-1565c0b8=""
          v-on:click="toggleTab('standings')"
        >
          {{ $t("football.league-details.standings") }}
          <span data-v-1565c0b8=""></span
        ></a>


      </div>

 
      <span v-if="activeTab == 'standings'">
        <LeagueStandings :leagueDetail="leagueDetails" :leagueId="leagueId" />
      </span>

    </div>
  </div>
  <DesktopFooter />
</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";
import metaDataUtils from "@/utils/metaDataUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,metaDataUtils],
  components: {
    LeagueStandings,
  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "standings",
    };
  },
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(itemId);
    this.getMetaData(itemId);
  },

  methods: {
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/hockey/league/details/${leagueId}`)
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
