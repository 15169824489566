export default {
    methods: {
        changeTitle(newTitle) {
            if (!newTitle) return;
            document.title = newTitle;
        },
        handleCanonicalLink(url) {
            if (!url) return;
            let canonicalLinkElement = document.querySelector(
                'link[rel="canonical"]'
            );
            if (canonicalLinkElement) canonicalLinkElement.remove();
            let newLink = document.createElement("link");
            newLink.setAttribute("rel", "canonical");
            newLink.setAttribute("href", url);

            document.head.appendChild(newLink);
        },

        handleMetaTags(description, keywords) {
            if (!description || !keywords) return;
            let metaTags = [
                {
                    name: "description",
                    content: description,
                },
                {
                    name: "keywords",
                    content: keywords,
                },
            ];

            for (let x = 0; x < metaTags.length; x++) {
                let oldMeta = document.getElementsByTagName("meta");
                for (let m = 0; m < oldMeta.length; m++) {
                    if (
                        oldMeta[m].name == metaTags[x].name &&
                        metaTags[x].content
                    ) {
                        oldMeta[m].remove();
                    }
                }
                if (
                    metaTags[x] &&
                    metaTags[x].name &&
                    metaTags[x].content &&
                    Object.prototype.hasOwnProperty.call(metaTags[x], "name") &&
                    Object.prototype.hasOwnProperty.call(metaTags[x], "content")
                ) {
                    let meta = document.createElement("meta");
                    meta.name = metaTags[x].name;
                    meta.content = metaTags[x].content;
                    document.head.appendChild(meta);
                }
            }
        },
    },
};
