// src/router/routes.cn.js

import PrivacyPolicy from "../components/commons/privacy-policy/privacy";
import ContactUs from "../components/commons/contact-us/contact-us";
import AboutUS from "../components/commons/about-us/about-us";
import TermsAndConditions from "../components/commons/terms-and-conditions/terms-and-conditions";
import FootballLiveScoring from "../components/football/live-scoring/main-index";
import FootballLeagueDetails from "../components/football/league/league-details";
import FootballTeamDetails from "../components/football/teams/team-details";
import TodayLiveMatch from "../components/football/matches/match-details";
import FootballPlayerDetails from "../components/football/player/player-details";
import BasketballLiveScoring from "../components/basketball/live-scoring/main-index";
import BasketballLeagueDetails from "../components/basketball/league/league-details";
import BasketballMatchDetails from "../components/basketball/matches/match-details";
import BasketballTeamDetails from "../components/basketball/teams/team-details";
import BasketballPlayerDetails from "../components/basketball/player/player-details";
import CricketLiveScoring from "../components/cricket/live-scoring/main-index";
import CricketMatchDetails from "../components/cricket/matches/match-details";
import CricketLeagueDetails from "../components/cricket/league/league-details";
import CricketTeamDetails from "../components/cricket/teams/team-details";
import CricketPlayerDetails from "../components/cricket/player/player-details";
import CricketNewsList from "../components/cricket/news/list/news-list-wrapper";
import CricketNewsDetails from "../components/cricket/news/details/news-details";
import TennisLiveScoring from "../components/tennis/live-scoring/main-index";
import TennisLeagueDetails from "../components/tennis/league/league-details";
import TennisMatchDetails from "../components/tennis/matches/match-details";
import TennisTeamDetails from "../components/tennis/teams/team-details";
import BaseballLiveScoring from "../components/baseball/live-scoring/main-index";
import BaseballMatchDetails from "../components/baseball/matches/match-details";
import BaseballLeagueDetails from "../components/baseball/league/league-details";
import BaseballTeamDetails from "../components/baseball/teams/team-details";
import VolleyballLiveScoring from "../components/volleyball/live-scoring/main-index";
import VolleyballMatchDetails from "../components/volleyball/matches/match-details";
import VolleyballLeagueDetails from "../components/volleyball/league/league-details";
import VolleyballTeamDetails from "../components/volleyball/teams/team-details";
import TableTennisLiveScoring from "../components/table_tennis/live-scoring/main-index";
import TableTennisMatchDetails from "../components/table_tennis/matches/match-details";
import TableTennisLeagueDetails from "../components/table_tennis/league/league-details";
import TableTennisTeamDetails from "../components/table_tennis/teams/team-details";
import BadmintonLiveScoring from "../components/badminton/live-scoring/main-index";
import BadmintonMatchDetails from "../components/badminton/matches/match-details";
import BadmintonLeagueDetails from "../components/badminton/league/league-details";
import BadmintonTeamDetails from "../components/badminton/teams/team-details";
import HockeyLiveScoring from "../components/hockey/live-scoring/main-index";
import HockeyLeagueDetails from "../components/hockey/league/league-details";
import HockeyMatchDetails from "../components/hockey/matches/match-details";
import HockeyTeamDetails from "../components/hockey/teams/team-details";
import UserProfile from "../components/user/profile/main-index.vue";

const routesCn = [
  // Static pages in Chinese
  {
    path: "/cn/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/cn/contact-us",
    component: ContactUs,
  },

  
  {
    path: "/cn/about-us",
    component: AboutUS,
  },
  {
    path: "/cn/terms-and-conditions",
    component: TermsAndConditions,
  },
  // Football routes
  {
    path: "/cn/live/football",
    component: FootballLiveScoring,
  },
  {
    path: "/cn/football/match/details/:slug/:matchId",
    component: TodayLiveMatch,
  },
  {
    path: "/cn/football/league/details/:slug/:leagueId",
    component: FootballLeagueDetails,
  },
  {
    path: "/cn/football/team/details/:slug/:teamId",
    component: FootballTeamDetails,
  },
  {
    path: "/cn/football/player/details/:slug/:playerId",
    component: FootballPlayerDetails,
  },
  // Basketball routes
  {
    path: "/cn/live/basketball",
    component: BasketballLiveScoring,
  },
  {
    path: "/cn/basketball/league/details/:slug/:leagueId",
    component: BasketballLeagueDetails,
  },
  {
    path: "/cn/basketball/match/details/:slug/:matchId",
    component: BasketballMatchDetails,
  },
  {
    path: "/cn/basketball/team/details/:slug/:teamId",
    component: BasketballTeamDetails,
  },
  {
    path: "/cn/basketball/player/details/:slug/:playerId",
    component: BasketballPlayerDetails,
  },
  // Cricket routes
  {
    path: "/cn/live/cricket",
    component: CricketLiveScoring,
  },
  {
    path: "/cn/cricket/match/details/:slug/:matchId",
    component: CricketMatchDetails,
  },
  {
    path: "/cn/cricket/league/details/:slug/:leagueId",
    component: CricketLeagueDetails,
  },
  {
    path: "/cn/cricket/team/details/:slug/:teamId",
    component: CricketTeamDetails,
  },
  {
    path: "/cn/cricket/player/details/:slug/:playerId",
    component: CricketPlayerDetails,
  },
  {
    path: "/cn/cricket/news/list",
    component: CricketNewsList,
  },
  {
    path: "/cn/cricket/news/details/:newsId",
    component: CricketNewsDetails,
  },
  // Tennis routes
  {
    path: "/cn/live/tennis",
    component: TennisLiveScoring,
  },
  {
    path: "/cn/tennis/league/details/:slug/:leagueId",
    component: TennisLeagueDetails,
  },
  {
    path: "/cn/tennis/match/details/:slug/:matchId",
    component: TennisMatchDetails,
  },
  {
    path: "/cn/tennis/team/details/:slug/:teamId",
    component: TennisTeamDetails,
  },
  // Baseball routes
  {
    path: "/cn/live/baseball",
    component: BaseballLiveScoring,
  },
  {
    path: "/cn/baseball/match/details/:slug/:matchId",
    component: BaseballMatchDetails,
  },
  {
    path: "/cn/baseball/league/details/:slug/:leagueId",
    component: BaseballLeagueDetails,
  },
  {
    path: "/cn/baseball/team/details/:slug/:teamId",
    component: BaseballTeamDetails,
  },
  // Volleyball routes
  {
    path: "/cn/live/volleyball",
    component: VolleyballLiveScoring,
  },
  {
    path: "/cn/volleyball/match/details/:slug/:matchId",
    component: VolleyballMatchDetails,
  },
  {
    path: "/cn/volleyball/league/details/:slug/:leagueId",
    component: VolleyballLeagueDetails,
  },
  {
    path: "/cn/volleyball/team/details/:slug/:teamId",
    component: VolleyballTeamDetails,
  },
  // Table Tennis routes
  {
    path: "/cn/live/table_tennis",
    component: TableTennisLiveScoring,
  },
  {
    path: "/cn/table_tennis/match/details/:slug/:matchId",
    component: TableTennisMatchDetails,
  },
  {
    path: "/cn/table_tennis/league/details/:slug/:leagueId",
    component: TableTennisLeagueDetails,
  },
  {
    path: "/cn/table_tennis/team/details/:slug/:teamId",
    component: TableTennisTeamDetails,
  },
  // Badminton routes
  {
    path: "/cn/live/badminton",
    component: BadmintonLiveScoring,
  },
  {
    path: "/cn/badminton/match/details/:slug/:matchId",
    component: BadmintonMatchDetails,
  },
  {
    path: "/cn/badminton/league/details/:slug/:leagueId",
    component: BadmintonLeagueDetails,
  },
  {
    path: "/cn/badminton/team/details/:slug/:teamId",
    component: BadmintonTeamDetails,
  },
  // Hockey routes
  {
    path: "/cn/live/hockey",
    component: HockeyLiveScoring,
  },
  {
    path: "/cn/hockey/match/details/:slug/:matchId",
    component: HockeyMatchDetails,
  },
  {
    path: "/cn/hockey/league/details/:slug/:leagueId",
    component: HockeyLeagueDetails,
  },
  {
    path: "/cn/hockey/team/details/:slug/:teamId",
    component: HockeyTeamDetails,
  },
  // User Profile route
  {
    path: "/cn/user/profile",
    component: UserProfile,
  },
  // Add more routes as needed
];

export default routesCn;
