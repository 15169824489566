<template>
  <header class="first-header" data-v-698032b0="">
    <nav class="inner h-bar-100 flex justify-between border-box" data-v-698032b0="">
      <!-- begin::logo option  -->
      <div class="topLink" data-v-698032b0="">
        <a
          :href="'/' + this.$i18n.locale + '/live/cricket'"
          class="flex align-center"
          data-v-698032b0=""
          ><img
            style="width: 200px !important; height: 32px !important"
            :src="
              this.$i18n.locale == 'cn'
                ? '/images/logo/logo.png'
                : '/images/logo/logo.png'
            "
            alt="#"
            class="pcLogo"
            data-v-698032b0=""
        /></a>
      </div>
      <!-- end::logo option -->
      <!-- begin::Signin & Search option -->
      <div class="flex align-center toolRightBox" data-v-698032b0="">
        <div class="searchBox" data-v-698032b0="">
          <input
            :placeholder="$t('common.menu.search')"
            type="text"
            value=""
            class="searchInput"
            data-v-698032b0=""
            @input="searchingDebounce"
            v-model="searchText"
          />
          <span class="searchIcon" data-v-698032b0=""
            ><i class="iconfont icon-sousuo" data-v-698032b0=""></i
          ></span>
        </div>
        <span v-if="!userInfo" @click="openSingIn" class="signIn bh" data-v-698032b0="">
          {{ $t("common.menu.signin") }}</span
        >
        <div v-else data-v-698032b0="" class="user-box el-dropdown">
          <span
            @click="toggleDropDown"
            data-v-698032b0=""
            class="avatar el-dropdown-selfdefine"
            aria-haspopup="list"
            aria-controls="dropdown-menu-9053"
            role="button"
            tabindex="0"
            :style="
              userInfo.avatar
                ? `
              background-image: url('${this.BASE_SERVER_URI + userInfo.avatar}');
              background-size: cover;
            `
                : `
              background-image: url(/images/avatar.png);
              background-size: cover;
              background-color: rgb(210, 210, 210);
            `
            "
          ></span>
        </div>

        <span class="signIn bh" v-on:click="changeLanguage()" data-v-698032b0="">
          <span v-if="this.$i18n.locale == 'cn'"> English </span>
          <span v-else> 中国人 </span>
        </span>
        <ul
          v-if="dropdownStatus"
          data-v-698032b0=""
          class="el-dropdown-menu el-popper"
          id="dropdown-menu-9053"
          style="
            position: absolute;
            top: 45px;
            left: 1045px;
            transform-origin: center top;
            z-index: 2003;
          "
          x-placement="bottom-end"
        >
          <li data-v-698032b0="" tabindex="-1" class="el-dropdown-menu__item drop-item">
            <a
              data-v-698032b0=""
              :href="'/' + this.$i18n.locale + '/user'"
              class="link"
              >{{ $t("common.menu.profile") }}</a
            >
          </li>
          <li
            data-v-698032b0=""
            tabindex="-1"
            class="el-dropdown-menu__item"
            @click="signOut"
          >
            {{ $t("common.menu.signout") }}
          </li>
          <div x-arrow="" class="popper__arrow" style="left: 68px"></div>
        </ul>

        <div
          class="flex align-center"
          style="justify-content: flex-end"
          data-v-698032b0=""
        >
          &nbsp;
          <div
            class="oddListBox"
            data-v-d4c6fef0=""
            style="background-color: transparent"
            v-on:click="toggleThemeMenu(themeStatus)"
          >
            <span class="text-white" data-v-d4c6fef0="" style="color: white"
              ><b> {{ $t("common.menu.change-theme") }} </b></span
            ><i
              class="iconfont pullIcon"
              :class="themeStatus ? 'icon-xiala' : 'icon-shangla'"
              data-v-d4c6fef0=""
              style="color: white; font-weight: bold"
            ></i>

            <div
              data-v-d4c6fef0=""
              class="oddList"
              style="margin-top: 10px"
              :style="themeStatus ? 'display:none' : ''"
            >
              <!-- begin::red -->
              <p data-v-d4c6fef0="" class="hover-effect" v-on:click="changeTheme('red')">
                <span
                  class="circle-button"
                  style="
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-color: red;
                    border: 2px solid red;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <span class="color" style="color: red; font-weight: bold">●</span>
                </span>

                <span
                  class="color"
                  style="
                    font-weight: bold;
                    color: red;

                    width: 200px !important;
                    position: absolute;
                    top: 12px;
                    left: -25px;
                  "
                  ><b>{{ $t("common.menu.red") }} </b></span
                >
              </p>
              <!-- end::red -->
              <!-- begin:blue -->

              <p data-v-d4c6fef0="" class="hover-effect" v-on:click="changeTheme('blue')">
                <span
                  class="circle-button"
                  style="
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-color: blue;
                    border: 2px solid blue;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <span class="color" style="color: blue; font-weight: bold">●</span>
                </span>

                <span
                  class="color"
                  style="
                    font-weight: bold;
                    color: blue;

                    width: 200px !important;
                    position: absolute;
                    top: 47px;
                    left: -25px;
                  "
                  ><b>{{ $t("common.menu.blue") }} </b></span
                >
              </p>

              <!-- end::blue -->

              <!-- begin:black -->
              <p
                data-v-d4c6fef0=""
                class="hover-effect"
                v-on:click="changeTheme('black')"
              >
                <span
                  class="circle-button"
                  style="
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-color: black;
                    border: 2px solid black;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <span class="color" style="color: black; font-weight: bold">●</span>
                </span>

                <span
                  class="color"
                  style="
                    font-weight: bold;
                    color: black;

                    width: 200px !important;
                    position: absolute;
                    top: 77px;
                    left: -25px;
                  "
                  ><b>{{ $t("common.menu.black") }} </b></span
                >
              </p>
              <!-- end::black -->
              <!-- begin:Green -->
              <p
                data-v-d4c6fef0=""
                class="hover-effect"
                v-on:click="changeTheme('green')"
              >
                <span
                  class="circle-button"
                  style="
                    position: relative;
                    width: 20px;
                    height: 20px;
                    background-color: green;
                    border: 2px solid green;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <span class="color" style="color: green; font-weight: bold">●</span>
                </span>

                <span
                  class="color"
                  style="
                    font-weight: bold;
                    color: green;

                    width: 200px !important;
                    position: absolute;
                    top: 107px;
                    left: -25px;
                  "
                  ><b>{{ $t("common.menu.green") }} </b></span
                >
              </p>
              <!-- end::green -->
            </div>
          </div>
        </div>
      </div>
      <!-- end::Signin & Search option -->
    </nav>
  </header>
</template>

<script>
import _ from "lodash";

export default {
  beforeMount() {
    this.lang = this.$i18n.locale;
  },
  name: "TopHeader",
  data() {
    return {
      dropdownStatus: false,
      searchText: "",
      themeStatus: true,
      lang: "en",
    };
  },
  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  methods: {
    searchingDebounce: _.debounce(function () {
      this.emitter.emit("searchText", { text: this.searchText });
    }, 500),
    changeTheme(color) {
      this.emitter.emit("themeChange", { color: color });
    },
    changeLanguage() {
      // Determine the new language based on the current language
      this.lang = this.lang === "en" ? "cn" : "en";

      // Update URL based on the selected language
      let currentPath = this.$route.path;
      let newPath = currentPath.replace(/^\/(en|cn)\//, "/" + this.lang + "/");

      // Use Vue Router to navigate to the new path
      this.$router.push(newPath).then(() => {
        // Reload the page after navigation to ensure all components are reloaded
        window.location.reload();
      });
    },

    openSingIn() {
      this.$emit("singinClicked");
    },
    signOut() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("apiToken");
      this.$emit("signOut");
      this.toggleDropDown();
      if (this.$route.fullPath.endsWith("/user")) {
        this.$router.push({ path: "/" });
      }
    },
    toggleDropDown() {
      this.dropdownStatus = !this.dropdownStatus;
    },
    toggleThemeMenu(status) {
      this.themeStatus = !status;
    },
  },
};
</script>
<style scoped>
.circle-button {
  transition: box-shadow 0.3s;
}
.circle-button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: lightblue;
}
.hover-effect {
  padding: 10px;
  background-color: white;
  transition: background-color 0.3s; /* Smooth transition */
}

.hover-effect:hover {
  background-color: #d9e2e9; /* Light blue color on hover */
}
</style>
