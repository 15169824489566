<template>
  <br />
  <div class="team" data-v-27524a2e="" v-if="loading">
    <div class="wrapper" data-v-27524a2e="">
      <img src="/images/default-loader.gif" style="width: 100px" />
      Information is Loading...
    </div>
  </div>
  <div class="team" data-v-27524a2e="" v-if="!loading">
    <div class="wrapper" data-v-27524a2e="">
      <div class="flex" data-v-3d1f695b="" data-v-27524a2e="">
        <div class="p-l flex-1" data-v-3d1f695b="">
          <div
            class="container-box mb-12"
            data-v-7c947e55=""
            data-v-3d1f695b=""
            style="border-radius: 5px"
          >
            <div
              data-v-e39f9930=""
              data-v-3d1f695b=""
              class="table Matches pl-15 pr-15 pb-24"
              data-v-7c947e55=""
              style="width: 800px; height: 530px; border-radius: 5px"
            >
              <br />
              <div data-v-e39f9930="" class="body">
                <div data-v-e39f9930="" class="row flex fs-12 color-333">
                  <center>
                    <img
                      :src="'https://sportyclub.online/' + newsDetails.image"
                      style="width: 800px; height: 500px; border-radius: 10px"
                      alt=""
                    />
                  </center>
                </div>
              </div>
            </div>
            <div
              v-html="newsDetails.details"
              style="
                margin-left: 15px;
                text-align: justify;
                color: #999;
                margin-right: 10px;
              "
            ></div>

            <br />
          </div>
        </div>
        <div class="p-r" data-v-3d1f695b="">
          <div
            class="container-box mb-12 pb-24"
            data-v-7c947e55=""
            data-v-3d1f695b=""
          >
            <div class="title flex justify-between" data-v-7c947e55="">
              <span class="color-p font-500 w-o-h" data-v-7c947e55="">
                Latest News
              </span>
              <span class="color-999 fs-12" data-v-7c947e55=""></span>
            </div>
            <div
              v-for="news in latestNews"
              :key="news"
              class="teamInfo color-333 fs-12 mt-12"
              data-v-5fcdd480=""
              data-v-3d1f695b=""
              data-v-7c947e55=""
            >
              <a
                :href="
                  '/' + this.$i18n.locale + '/football/news/details/' + news.id
                "
              >
                <div
                  class="mt-15 pl-15 pr-15 flex align-center"
                  data-v-5fcdd480=""
                >
                  <img
                    :src="'https://sportyclub.online/' + news.image"
                    alt=""
                    style="width: 325px; height: 200px"
                  />
                </div>
                <br />
                <p
                  style="
                    margin-left: 20px;
                    text-align: justify;
                    margin-right: 10px;
                    margin-top: 5px;
                    color: #999;
                    font-weight: bold;
                  "
                >
                  {{ news.title }}
                </p></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <DesktopFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      newsDetails: null,
      latestNews: null,
    };
  },

  created() {
    const itemId = this.$route.params.newsId;
    this.getNewsDetails(itemId);
  },

  methods: {
    getNewsDetails(newsId) {
      this.$axios
        .get(`https://sportyclub.online/api/football/news/details/${newsId}`)
        .then((response) => {
          this.newsDetails = response.data.footballNewsDetails;
          this.latestNews = response.data.footballNewsList;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
