<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-08c9d60b="">
          <!-- display league name -->
          <div
            class="fs-12 color-666 pl-15 pr-15 pb-xxs"
            data-v-77277ac5=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <div class="w100" data-v-51bacf47="" data-v-77277ac5="">
              <div class="mt-10" data-v-51bacf47="">
                <div class="w100" data-v-51bacf47="">
                  <!-- begin::loading -->
                  <div class="border-box" data-v-51bacf47="" v-if="loading">
                    <div
                      id="standings"
                      class="justify-between align-center StatsType border-box flex"
                      data-v-975a1620=""
                    >
                      <div
                        class="color-p fs-14 font-500 w-o-h"
                        data-v-975a1620=""
                      >
                      {{ $t("football.league-details.standings") }}
                      </div>
                    </div>
                    <img
                      src="/images/default-loader.gif"
                      style="width: 20%; margin-left: -50px"
                    />
                    <br />
                  </div>
                  <!-- end::loading -->
                  <!-- begin::display standings -->
                  <div
                    class="border-box"
                    data-v-51bacf47=""
                    v-else-if="
                      !loading && leagueStandings && leagueStandings.standings
                    "
                    :style="standingsLoader ? 'opacity:0.5' : ''"
                  >
                    <div
                      id="standings"
                      class="justify-between align-center StatsType border-box flex"
                      data-v-975a1620=""
                    >
                      <div
                        class="color-p fs-14 font-500 w-o-h"
                        data-v-975a1620=""
                      >
                      {{ $t("football.league-details.standings") }}
                      </div>
                    </div>
                    <template
                      v-for="(group, index) in leagueStandings.standings"
                      :key="index"
                    >
                      <div class="title mt-xs" data-v-51bacf47="">
                        <span
                          class="year color-666 relative"
                          data-v-51bacf47=""
                        >
                          <span style="font-weight: bold; color: brown">
                            {{ group && group.name ? group.name : "" }}
                          </span>

                          <span class="triangle" data-v-51bacf47=""></span
                        ></span>
                      </div>
                      <div
                        class="table flex-1 fs-12"
                        style="border-top: 1px solid #eeeeee"
                        data-v-394fa268=""
                        data-v-51bacf47=""
                      >
                        <!-- begin::table header -->
                        <div
                          class="row fs-12 color-333 h-40"
                          style="background: rgba(244, 245, 248, 1)"
                          data-v-7f09763e=""
                        >
                          <div
                            class="col border-right flex-4"
                            data-v-7f09763e=""
                          >
                            <span style="margin-left: 20px" data-v-7f09763e=""
                              >#</span
                            ><span class="pl-30 ml-xs" data-v-7f09763e="">
                              {{ $t("football.league-details.teams") }}
                            </span>
                          </div>
                          <div
                            class="col flex-1dot5 pwdl border-right"
                            data-v-7f09763e=""
                          >
                            <span class="flex-1" data-v-7f09763e="">T</span
                            ><span class="flex-1 w" data-v-7f09763e="">W</span
                            ><span class="flex-1 d" data-v-7f09763e="">L</span
                            ><span class="flex-1 l" data-v-7f09763e="">D</span>
                          </div>
                          <div
                            class="col pts flex-0dot5 justify-center"
                            data-v-7f09763e=""
                          >
                          {{ $t("football.league-details.points") }}
                          </div>
                        </div>
                        <!-- end::table header -->
                        <!-- begin::table body -->
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/cricket/team/details/' +
                            standing.team.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            standing.team.id
                          "
                          target="_blank"
                          v-for="standing in group.rows"
                          :key="standing.id"
                        >
                          <div
                            data-team-id="0m2q15ix41fm76x"
                            class="row fs-12 color-333"
                            data-v-7f09763e=""
                            style="height: 40px; position: relative"
                          >
                            <div
                              class="flex align-center col h-40 border-right flex-4"
                              data-v-7f09763e=""
                              style="overflow: auto"
                            >
                              <a
                                target="_self"
                                ass="color-333 flex align-center w-o-h"
                                data-v-7f09763e=""
                                ><span
                                  class="num"
                                  data-v-7f09763e=""
                                  style="
                                    background: rgb(0, 210, 0) !important;
                                    color: rgb(255, 255, 255) !important;
                                    margin-left: 15px;
                                  "
                                >
                                  {{ standing.position }}</span
                                ><img
                                  :src="
                                    standing &&
                                    standing.team &&
                                    standing.team.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        standing.team.id +
                                        '/' +
                                        standing.team.id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  class="ml-xs w-20"
                                  data-v-7f09763e=""
                                  style="flex-shrink: 0"
                                /><span
                                  class="ml-xs team-name w-o-h"
                                  data-v-7f09763e=""
                                >
                                  {{
                                    standing &&
                                    standing.team &&
                                    standing.team.name
                                      ? standing.team.name
                                      : ""
                                  }}</span
                                ></a
                              >
                            </div>
                            <div
                              class="col flex-1dot5 pwdl border-right h-40"
                              data-v-7f09763e=""
                              style=""
                            >
                              <span class="flex-1" data-v-7f09763e="">
                                {{ standing.matches }}</span
                              ><span class="flex-1 w" data-v-7f09763e="">
                                {{ standing.wins }}</span
                              ><span class="flex-1 d" data-v-7f09763e="">{{
                                standing.draws
                              }}</span
                              ><span class="flex-1 l" data-v-7f09763e="">{{
                                standing.losses
                              }}</span>
                            </div>
                            <div
                              class="col pts flex-0dot5 justify-center h-40"
                              data-v-7f09763e=""
                            >
                              {{ standing.netRunRate.toFixed(3) }}
                            </div>
                          </div>
                        </a>
                        <!-- end::table body -->
                      </div>
                    </template>
                  </div>
                  <!-- end::display standings -->
                  <!-- begin::if data is not available -->
                  <div class="border-box" data-v-51bacf47="" v-else>
                    <div
                      class="scheduleBox"
                      data-v-1eddfbef=""
                      style="width: 100%"
                    >
                      <div
                        data-v-1eddfbef=""
                        class="flex justify-center align-center flex-col"
                        style="height: 300px"
                      >
                        <img
                          :src="bannerImagePath('default-container.png')"
                          style="width: 123px; height: 74px"
                        />
                        <div class="color-999 fs-12 mt-12">
                          {{ $t("football.live-score.temporarily-no-data") }}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- begin::right top scorer player -->
      <div class="p-r" data-v-08c9d60b="">
        <!-- loading -->
        <div
          v-if="loading"
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-08c9d60b=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.players-stats") }}
            </span>
          </div>
          <div
            class="mt-12"
            data-v-09c12dba=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <img src="/images/default-loader.gif" style="width: 25%" />
          </div>
        </div>
        <!-- begin::player stats -->
        <div
          v-else-if="!loading && playersStats && playersStats.runs_Scored"
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-08c9d60b=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.players-stats") }}
            </span>
          </div>
          <div
            class="mt-12"
            data-v-09c12dba=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <div class="pl-15 pr-15 fs-12 color-999 flex" data-v-09c12dba="">
              <div
                v-on:click="toggleTopPlayers('runs_Scored')"
                class="Goals ml-15"
                :class="topPlayers == 'runs_Scored' ? 'active' : ''"
                data-v-09c12dba=""
              >
              {{ $t("football.league-details.runs") }}
              </div>
              <div
                v-on:click="toggleTopPlayers('wickets')"
                class="Assists ml-15"
                data-v-09c12dba=""
                :class="topPlayers == 'wickets' ? 'active' : ''"
              >
              {{ $t("football.league-details.wickets") }}
              </div>
            </div>
            <div class="table TopPlayer" data-v-09c12dba="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12 pl-15 pr-15"
                data-v-09c12dba=""
              >
                <div class="col col-5" data-v-09c12dba="">#</div>
                <div class="col flex-1" data-v-09c12dba="">
                  {{ $t("football.league-details.players") }}
                </div>
                <div class="col col-20" data-v-09c12dba="">
                  {{ $t("football.league-details.matches") }}
                </div>
                <div class="col col-25 white-space-n" data-v-09c12dba="">
                  <span data-v-09c12dba="">
                    {{ topPlayers }}
                  </span>
                </div>
              </div>
              <div
                class="body pl-15 pr-15"
                style="min-height: 569px"
                data-v-09c12dba=""
              >
                <span v-if="playersStats && playersStats[topPlayers]">
                  <template
                    v-for="(player, index) in playersStats[topPlayers]"
                    :key="player.id"
                  >
                    <div
                      class="row flex fs-12 color-333"
                      data-v-09c12dba=""
                      v-if="index < 10"
                    >
                      <div class="col col-5 color-999" data-v-09c12dba="">
                        {{ ++index }}
                      </div>
                      <div
                        class="col flex-1 justify-start ml-xxs cur-pointer"
                        data-v-09c12dba=""
                      >
                        <a
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/cricket/player/details' +
                            player.player.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            player.player.id
                          "
                          target="_self"
                          class="player-logo f-s-0"
                          data-v-09c12dba=""
                        >
                          <img
                            :src="
                              player && player.player && player.player.id
                                ? 'https://sportyclub.online//images/cricket/players/' +
                                  player.player.id +
                                  '/' +
                                  player.player.id +
                                  '.png'
                                : bannerImagePath('default-player.png')
                            "
                            @error="
                              $event.target.src = bannerImagePath('default-player.png')
                            "
                            data-v-09c12dba=""
                          />
                        </a>
                        <div class="fs-12 ml-xs" data-v-09c12dba="">
                          <a
                            :href="
                              '/' +
                              this.$i18n.locale +
                              '/cricket/player/details' +
                              player.player.name.toLowerCase().replace(/\s/g, '-') +
                              '/' +
                              player.player.id
                            "
                            target="_self"
                            class="mb-xxs display-block color-333"
                            data-v-09c12dba=""
                          >
                            {{
                              player && player && player.player.name
                                ? player.player.name
                                : ""
                            }}
                          </a>
                        </div>
                      </div>
                      <div class="col col-20" data-v-09c12dba="">
                        {{ player.statistics.matches }}
                      </div>
                      <div class="col col-25" data-v-09c12dba="">
                        {{ player.statistics[topPlayers] }}
                      </div>
                    </div>
                  </template>
                </span>
                <!-- begin::loader -->
                <span v-else>
                  <img src="/images/default-loader.gif" style="width: 25%" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end::player stats -->
        <!-- if player stats is empty -->
        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-8d245a92=""
          v-else
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.players-stats") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="flex justify-center align-center flex-col"
            style="height: 300px"
            data-v-8d245a92=""
            data-v-7c947e55=""
          >
            <img :src="bannerImagePath('no-data.png')" />
            <div class="color-999 fs-12 mt-12">        {{ $t("football.live-score.temporarily-no-data") }}</div>
          </div>
        </div>
      </div>
      <!-- end::right top scorer player -->
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueId: {
      type: Number,
      default: null,
    },
    seasonId: {
      type: Number,
      default: null,
    },
  },
  updated() {
    // Check if leagueId and seasonId have changed before calling getLeagueStandings
    if (
      this.leagueId !== this.previousLeagueId ||
      this.seasonId !== this.previousSeasonId
    ) {
      this.getPlayerStats(this.leagueId, this.seasonId);
      this.getLeagueStandings(this.leagueId, this.seasonId);
      this.previousLeagueId = this.leagueId;
      this.previousSeasonId = this.seasonId;
    }
  },

  created() {
    this.getPlayerStats(this.leagueId, this.seasonId);
    this.getLeagueStandings(this.leagueId, this.seasonId);
  },

  data() {
    return {
      leagueDetails: null,
      loading: true,
      playersStats: null,
      topPlayers: "runs_Scored",
      leagueStandings: null,
      standingsLoader: true,
      previousLeagueId: null,
      previousSeasonId: null,
    };
  },

  methods: {
    getLeagueStandings(leagueId, seasonId) {
      this.standingsLoader = true;

      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/league/singe/season/standings/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.leagueStandings = response.data;

          this.loading = false;
          this.standingsLoader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get player stats
    getPlayerStats(leagueId, seasonId) {
      this.$axios
        .get(  this.BASE_SERVER_URI +
          `/api/cricket/league/season/players/stats/${leagueId}/${seasonId}`
        )
        .then((response) => {
          this.playersStats = response.data.topPlayers;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.topPlayers = status;
    },
    getTimeStampDate(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var year = date.getFullYear();

      // Format the date
      return month + " " + day + ", " + year;
    },
  },
};
</script>
