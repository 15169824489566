<template>
  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
  >
    <div data-v-46436e01="" class="user">
      <div data-v-46436e01="" class="tag_commingMatches">
        <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
        <span data-v-46436e01="" class="text">
          {{ $t("football.live-score.search-results") }}</span
        >
      </div>
    </div>
  </div>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
    style="transform: translateY(0px); min-height: 200px"
  >
    <div class="flex justify-center align-center flex-col contentBox">
      <img :src="bannerImagePath('default-container.png')" class="nodata" />

      <div class="color-999 noDataText fs-12">
        {{ $t("football.live-score.nothing-found") }}
      </div>
    </div>
  </div>

  <!-- live search result -->
  <template v-else>

    <div
      data-v-46436e01=""
      class="vue-recycle-scroller scroller ready page-mode direction-vertical"
    >
      <div class="vue-recycle-scroller__item-wrapper">
        <div
          class="vue-recycle-scroller__item-view"
          v-for="(league, key) in searchMatchListLive"
          :key="key"
        >
          <div data-v-46436e01="" class="user">
            <div data-v-46436e01="" class="flex align-center">
              <div data-v-2eea2747="" data-v-46436e01="" style="width: 100%">
                <div
                  data-v-2eea2747=""
                  class="list_title collectName"
                  :class="
                    leagueFavoriteStatus[league[0].competition_id]
                      ? 'collectCom'
                      : ''
                  "
                >
                  <div data-v-2eea2747="" class="topt flex">
                    <div
                      data-v-2eea2747=""
                      style="z-index: -1; width: 13.03%; min-width: 124px"
                    ></div>
                    <div
                      data-v-2eea2747=""
                      style="
                        z-index: -1;
                        margin-left: 10px;
                        width: 21%;
                        min-width: 200px;
                      "
                    ></div>
                    <div data-v-2eea2747="" class="flex-1 headBoxRight">
                      <span
                        data-v-2eea2747=""
                        class="watchNum"
                        style="margin-right: 4px"
                        ><i
                          data-v-2eea2747=""
                          class="iconfont icon-renshu"
                          style="display: none"
                        ></i>
                        <span data-v-2eea2747="" style="display: none"
                          >163</span
                        ></span
                      >
                    </div>
                    <div data-v-2eea2747="" class="Moneyline">
                      <div
                        data-v-2eea2747=""
                        class="text-center oddsTypeText_bsk oddsWidth1 bh"
                      >
                        {{ $t("football.league-details.to-win") }}
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="text-center oddsTypeText_bsk oddsWidth2 bh"
                      >
                        {{ $t("football.league-details.spread") }}
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center oddsTypeText_bsk oddsWidth3 bh"
                      >
                        {{ $t("football.league-details.total-points") }}
                      </div>
                    </div>
                  </div>
                  <div data-v-2eea2747="" class="flex align-center topLeftBox">
                    <i
                      class="iconfont"
                      :class="
                        leagueFavoriteStatus[league[0].competition_id]
                          ? 'icon-yishoucang'
                          : 'icon-weishoucang'
                      "
                      @click="toggleFavorite(league[0].competition_id)"
                      style="visibility: visible"
                    ></i>
                    <img
                      data-v-2eea2747=""
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.logo
                      "
                      :src="
                        league[0].leagueInfo.countryDetails.logo
                          ? league[0].leagueInfo.countryDetails.logo
                         : bannerImagePath('default-country.png')
                      "
                      class="countryLogo"
                    />
                    <span data-v-2eea2747="" class="countryText">
                      <span data-v-f1b1cb6e="" class="country-name minitext">
                        <template v-if="league[0] && league[0].leagueInfo">
                          <template v-if="this.$i18n.locale == 'cn'">
                            <template
                              v-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.cn_name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.cn_name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.cn_name
                              "
                            >
                              {{
                                league[0].leagueInfo.categoryDetails.cn_name
                              }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.categoryDetails.name }}:
                            </template>
                          </template>
                          <template v-else>
                            <template
                              v-if="
                                league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.countryDetails.name }}:
                            </template>
                            <template
                              v-else-if="
                                league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name
                              "
                            >
                              {{ league[0].leagueInfo.categoryDetails.name }}:
                            </template>
                            <template v-else> </template>
                          </template>
                        </template>
                      </span>
                    </span>
                    <a
                      v-if="
                        league &&
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].hasLeagueDetails
                      "
                      data-v-2eea2747=""


                      :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "




                  
                      target="_blank"
                      class="compText"
                    >
                      {{
                        this.$i18n.locale == "cn" &&
                        league[0].leagueInfo.cn_name
                          ? league[0].leagueInfo.cn_name
                          : league[0].leagueInfo.en_name
                          ? league[0].leagueInfo.en_name
                          : ""
                      }}
                    </a>
                    <a
                      v-else
                      target="_blank"
                      class="compText"
                      style="cursor: not-allowed"
                    >
                      {{
                        this.$i18n.locale == "cn" &&
                        league[0].leagueInfo.cn_name
                          ? league[0].leagueInfo.cn_name
                          : league[0].leagueInfo.en_name
                          ? league[0].leagueInfo.en_name
                          : ""
                      }}
                    </a>
                  </div>
                </div>
                <div data-v-2eea2747="">
                  <div
                    data-v-2eea2747=""
                    class="list"
                    v-for="match in league"
                    :key="match.id"
                  >
                    <a
                      data-v-2eea2747=""
                      :href="
                                '/' +
                                this.$i18n.locale +
                                '/basketball/match/details/' + match.homeInfo.en_name.toLowerCase() .replace(/\s/g, '-') + '-vs-'+ match.awayInfo.en_name.toLowerCase() .replace(/\s/g, '-') +  '/' +
                                match.id
                              "
                      target="_blank"
                      class="flex flex-1"
                      style="height: 100%"
                    >
                      <!-- {{ match.id }} -->
                      <div
                        data-v-2eea2747=""
                        class="flex align-center listBox justify-around"
                      >
                        <span
                          data-v-2eea2747=""
                          class="fs-13 matchTime"
                          style="color: rgb(153, 153, 153)"
                        >
                          {{
                            readableTime(this.$i18n.locale, match.match_time)
                          }}
                        </span>
                        <div
                          data-v-2eea2747=""
                          class="flex flex-col fs-13 align-center text-center barStatus barItem flex-1"
                        >
                          <span data-v-2eea2747=""
                            ><b>{{
                              match && match.statusDescription
                                ? match.statusDescription
                                : ""
                            }}</b></span
                          >
                          <div v-if="match && match.counter_timing">
                            <span data-v-2eea2747="" class="barStatusLine"
                              >-</span
                            >
                            <span data-v-2eea2747=""
                              ><b>{{ match.counter_timing }}</b></span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="team flex flex-col padding5"
                      >
                        <div
                          data-v-2eea2747=""
                          class="flex-1 align-center flex"
                        >
                          <span data-v-2eea2747="" class="teamLogoBox"
                            ><img
                              data-v-2eea2747=""
                              v-if="match.homeInfo"
                              :src="
                                match.homeInfo.logo
                                  ? match.homeInfo.logo
                                  : '/images/default-football.jpg'
                              "
                          /></span>
                          <div data-v-2eea2747="" class="w-o-h elseTeamName">
                            <template v-if="match && match.homeInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.homeInfo.cn_name
                                  ? match.homeInfo.cn_name
                                  : match.homeInfo.en_name
                                  ? match.homeInfo.en_name
                                  : ""
                              }}
                            </template>
                          </div>
                        </div>
                        <div
                          data-v-2eea2747=""
                          class="flex-1 align-center flex"
                        >
                          <span data-v-2eea2747="" class="teamLogoBox"
                            ><img
                              data-v-2eea2747=""
                              v-if="match.awayInfo"
                              :src="
                                match.awayInfo.logo
                                  ? match.awayInfo.logo
                                  : '/images/default-football.jpg'
                              "
                          /></span>
                          <div
                            data-v-2eea2747=""
                            itemprop="awayTeam"
                            class="w-o-h elseTeamName"
                          >
                            <template v-if="match && match.awayInfo">
                              {{
                                this.$i18n.locale == "cn" &&
                                match.awayInfo.cn_name
                                  ? match.awayInfo.cn_name
                                  : match.awayInfo.en_name
                                  ? match.awayInfo.en_name
                                  : ""
                              }}
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="teamScore padding5"
                        v-if="
                          liveMatches.includes(match.status_id) ||
                          FinishedMatches.includes(match.status_id)
                        "
                      >
                        <div
                          data-v-2eea2747=""
                          class="flex flex-col align-center"
                          style="height: 100%"
                        >
                          <div
                            data-v-2eea2747=""
                            class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div
                              data-v-2eea2747=""
                              class="svgBallIconBox"
                            ></div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.section_1
                                      ? match.homeInfo.section_1
                                      : ""
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.section_2
                                      ? match.homeInfo.section_2
                                      : ""
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.section_3
                                      ? match.homeInfo.section_3
                                      : ""
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class="scoreWeight"
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  {{
                                    match &&
                                    match.homeInfo &&
                                    match.homeInfo.section_4
                                      ? match.homeInfo.section_4
                                      : ""
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class="scoreWeight"
                                ><span
                                  data-v-2eea2747=""
                                  class="scoreRed"
                                  style="font-family: Roboto-Regular, Roboto"
                                >
                                  {{
                                    addSectionsScore(
                                      match.homeInfo.section_1,
                                      match.homeInfo.section_2,
                                      match.homeInfo.section_3,
                                      match.homeInfo.section_4
                                    )
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center noVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class="scoreRed"
                                  style="font-family: Roboto-Regular, Roboto"
                                  >0</span
                                ></span
                              >
                            </div>
                          </div>
                          <div
                            data-v-2eea2747=""
                            class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                            style="font-family: Roboto-Regular, Roboto"
                          >
                            <div
                              data-v-2eea2747=""
                              class="svgBallIconBox"
                            ></div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                  >{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.section_1
                                      ? match.awayInfo.section_1
                                      : ""
                                  }}
                                </span></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                  >{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.section_2
                                      ? match.awayInfo.section_2
                                      : ""
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                  >{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.section_3
                                      ? match.awayInfo.section_3
                                      : ""
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class=""
                                  style="font-family: Roboto-Regular, Roboto"
                                  >{{
                                    match &&
                                    match.awayInfo &&
                                    match.awayInfo.section_4
                                      ? match.awayInfo.section_4
                                      : ""
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center isVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class="scoreRed"
                                  style="font-family: Roboto-Regular, Roboto"
                                  >{{
                                    addSectionsScore(
                                      match.awayInfo.section_1,
                                      match.awayInfo.section_2,
                                      match.awayInfo.section_3,
                                      match.awayInfo.section_4
                                    )
                                  }}</span
                                ></span
                              >
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 text-center noVisible"
                            >
                              <span data-v-2eea2747="" class=""
                                ><span
                                  data-v-2eea2747=""
                                  class="scoreRed"
                                  style="font-family: Roboto-Regular, Roboto"
                                  >0</span
                                ></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 icon-setting"
                        style="border-right: 1px solid rgb(234, 234, 234)"
                      >
                        <i
                          data-v-2eea2747=""
                          class="iconfont icon-shipinzhibo videoAn"
                        ></i>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex Moneyline moneyLineContent"
                        style="position: relative"
                      >
                        <div data-v-2eea2747="" class="flex flex-1 oddsItem">
                          <div
                            data-v-2eea2747=""
                            class="text-center le lh oddTextColor oddsItemBox oddsWidth1"
                          >
                            <div data-v-2eea2747="">
                              <div data-v-2eea2747="">
                                <div data-v-2eea2747="" class="oddhoverGreen">
                                  {{
                                    match &&
                                    match.odds &&
                                    match.odds.euro &&
                                    typeof match.odds.euro[2] === "number" &&
                                    !isNaN(match.odds.euro[2]) &&
                                    match.odds.euro[2] !== null &&
                                    match.odds.euro[2] !== undefined &&
                                    match.odds.euro[2] !== ""
                                      ? match.odds.euro[2].toFixed(2)
                                      : "-"
                                  }}
                                </div>
                                <div
                                  data-v-2eea2747=""
                                  class="oddhoverGreen oddTop"
                                >
                                  {{
                                    match &&
                                    match.odds &&
                                    match.odds.euro &&
                                    typeof match.odds.euro[4] === "number" &&
                                    !isNaN(match.odds.euro[4]) &&
                                    match.odds.euro[4] !== null &&
                                    match.odds.euro[4] !== undefined &&
                                    match.odds.euro[4] !== ""
                                      ? match.odds.euro[4].toFixed(2)
                                      : "-"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            data-v-2eea2747=""
                            class="text-center le lh oddsItemBox oddsWidth1"
                            style="display: none"
                          ></div>
                          <div
                            data-v-2eea2747=""
                            class="text-center flex le oddsItemBox oddsWidth2"
                          >
                            <div
                              data-v-2eea2747=""
                              class="flex-1 lh handicapColor"
                            >
                              <div data-v-2eea2747="">
                                {{
                                  match.odds &&
                                  match.odds.asia &&
                                  typeof match.odds.asia[3] === "number" &&
                                  !isNaN(match.odds.asia[3]) &&
                                  match.odds.asia[3] !== ""
                                    ? -Number(match.odds.asia[3]).toFixed(2) // This will always have two digits after the decimal point
                                    : "-"
                                }}
                              </div>
                              <div data-v-2eea2747="" class="oddTop">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  typeof match.odds.asia[3] === "number" &&
                                  !isNaN(match.odds.asia[3]) &&
                                  match.odds.asia[3] !== null &&
                                  match.odds.asia[3] !== undefined &&
                                  match.odds.asia[3] !== ""
                                    ? match.odds.asia[3].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 lh oddTextColor"
                            >
                              <div data-v-2eea2747="" class="oddhoverGreen">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  typeof match.odds.asia[2] === "number" &&
                                  !isNaN(match.odds.asia[2]) &&
                                  match.odds.asia[2] !== null &&
                                  match.odds.asia[2] !== undefined &&
                                  match.odds.asia[2] !== ""
                                    ? match.odds.asia[2].toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div
                                data-v-2eea2747=""
                                class="oddhoverGreen oddTop"
                              >
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.asia &&
                                  typeof match.odds.asia[4] === "number" &&
                                  !isNaN(match.odds.asia[4]) &&
                                  match.odds.asia[4] !== null &&
                                  match.odds.asia[4] !== undefined &&
                                  match.odds.asia[4] !== ""
                                    ? match.odds.asia[4].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            data-v-2eea2747=""
                            class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                          >
                            <div
                              data-v-2eea2747=""
                              class="flex-1 lh handicapColor"
                            >
                              <div data-v-2eea2747="">
                                <span data-v-2eea2747="">O</span>
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.bigSmall &&
                                  typeof match.odds.bigSmall[3] === "number" &&
                                  !isNaN(match.odds.bigSmall[3]) &&
                                  match.odds.bigSmall[3] !== null &&
                                  match.odds.bigSmall[3] !== undefined &&
                                  match.odds.bigSmall[3] !== ""
                                    ? match.odds.bigSmall[3].toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div data-v-2eea2747="" class="oddTop">
                                <span data-v-2eea2747="">U</span>
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.bigSmall &&
                                  match.odds.bigSmall[3] !== ""
                                    ? match.odds.bigSmall[3].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="flex-1 lh oddTextColor"
                            >
                              <div data-v-2eea2747="" class="oddhoverGreen">
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.bigSmall &&
                                  match.odds.bigSmall[2] !== ""
                                    ? match.odds.bigSmall[2].toFixed(2)
                                    : "-"
                                }}
                              </div>
                              <div
                                data-v-2eea2747=""
                                class="oddhoverGreen oddTop"
                              >
                                {{
                                  match &&
                                  match.odds &&
                                  match.odds.bigSmall &&
                                  match.odds.bigSmall[4] !== ""
                                    ? match.odds.bigSmall[4].toFixed(2)
                                    : "-"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upcoming search result -->

    <!-- <div
      v-if="Object.entries(searchMatchListUpcoming).length !== 0"
      class="vue-recycle-scroller__item-view"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="tag_commingMatches">
          <i data-v-46436e01="" class="iconfont icon-shaozi"></i>
          <span data-v-46436e01="" class="text">
            {{ $t("football.live-score.today-upcoming-matches") }}</span
          >
        </div>
      </div>
    </div> -->

    <div
      class="vue-recycle-scroller__item-view"
      v-for="(league, key) in searchMatchListUpcoming"
      :key="key"
    >
      <div data-v-46436e01="" class="user">
        <div data-v-46436e01="" class="flex align-center">
          <div data-v-2eea2747="" data-v-46436e01="" style="width: 100%">
            <div
              data-v-2eea2747=""
              class="list_title"
              :class="
                leagueFavoriteStatus[league[0].competition_id]
                  ? 'collectCom'
                  : ''
              "
            >
              <div data-v-2eea2747="" class="topt flex">
                <div
                  data-v-2eea2747=""
                  style="z-index: -1; width: 13.03%; min-width: 124px"
                ></div>
                <div
                  data-v-2eea2747=""
                  style="
                    z-index: -1;
                    margin-left: 10px;
                    width: 21%;
                    min-width: 200px;
                  "
                ></div>
                <div data-v-2eea2747="" class="flex-1 headBoxRight">
                  <span
                    data-v-2eea2747=""
                    class="watchNum"
                    style="margin-right: 4px"
                    ><i
                      data-v-2eea2747=""
                      class="iconfont icon-renshu"
                      style="display: none"
                    ></i>
                    <span data-v-2eea2747="" style="display: none"
                      >163</span
                    ></span
                  >
                </div>
                <div data-v-2eea2747="" class="Moneyline">
                  <div
                    data-v-2eea2747=""
                    class="text-center oddsTypeText_bsk oddsWidth1 bh"
                  >
                    {{ $t("football.league-details.to-win") }}
                  </div>
                  <div
                    data-v-2eea2747=""
                    class="text-center oddsTypeText_bsk oddsWidth2 bh"
                  >
                    {{ $t("football.league-details.spread") }}
                  </div>
                  <div
                    data-v-2eea2747=""
                    class="flex-1 text-center oddsTypeText_bsk oddsWidth3 bh"
                  >
                    {{ $t("football.league-details.total-points") }}
                  </div>
                </div>
              </div>
              <div data-v-2eea2747="" class="flex align-center topLeftBox">
       
                <i
                  class="iconfont"
                  :class="
                    leagueFavoriteStatus[league[0].competition_id]
                      ? 'icon-yishoucang'
                      : 'icon-weishoucang'
                  "
                  @click="toggleFavorite(league[0].competition_id)"
                  style="visibility: visible"
                ></i>
                <img
                  data-v-2eea2747=""
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                              league[0].leagueInfo.countryDetails.logo) ||
                              (league[0].leagueInfo.countryDetails &&
                                league[0].leagueInfo.countryDetails.name) ||
                              (league[0].leagueInfo.categoryDetails &&
                                league[0].leagueInfo.categoryDetails.name))                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt="China"
                  class="countryLogo"
                />
                <span data-v-2eea2747="" class="countryText">
                  <span
                    v-if="this.$i18n.locale == 'cn'"
                    data-v-f1b1cb6e=""
                    class="country-name minitext"
                  >
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.cn_name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.cn_name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                    </span>
                  </span>

                  <span v-else data-v-f1b1cb6e="" class="country-name minitext">
                    <span
                      v-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.countryDetails.name }}:
                    </span>
                    <span
                      v-else-if="
                        league[0] &&
                        league[0].leagueInfo &&
                        league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name
                      "
                    >
                      {{ league[0].leagueInfo.categoryDetails.name }}:
                    </span>
                  </span>
                  :</span
                >
                <span
                  v-if="
                    league &&
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo
                  "
                >
                  <a
                    v-if="league[0].hasLeagueDetails"
                    data-v-2eea2747=""
                    :href="
              '/' +
              this.$i18n.locale +
              '/basketball/league/details/' +
              league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league[0].competition_id
            "

                    target="_blank"
                    class="compText"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                  <a
                    v-else
                    target="_blank"
                    class="compText"
                    style="cursor: not-allowed"
                  >
                    {{
                      this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                        ? league[0].leagueInfo.cn_name
                        : league[0].leagueInfo.en_name
                        ? league[0].leagueInfo.en_name
                        : ""
                    }}
                  </a>
                </span>
              </div>
            </div>
            <div data-v-2eea2747="">
              <div
                data-v-2eea2747=""
                class="list"
                v-for="match in league"
                :key="match.id"
              >
                <a
                v-if="match && match.homeInfo && match.awayInfo"
                  data-v-2eea2747=""
                :href="
                                '/' +
                                this.$i18n.locale +
                                '/basketball/match/details/' + match.homeInfo.en_name.toLowerCase() .replace(/\s/g, '-') + '-vs-'+ match.awayInfo.en_name.toLowerCase() .replace(/\s/g, '-') +  '/' +
                                match.id
                              "
                  target="_blank"
                  class="flex flex-1"
                  style="height: 100%"
                >
                  <div
                    data-v-2eea2747=""
                    class="flex align-center listBox justify-around"
                  >
                    <span
                      data-v-2eea2747=""
                      class="fs-13 matchTime"
                      style="color: rgb(153, 153, 153)"
                    >
                      {{ readableTime(this.$i18n.locale, match.match_time) }}
                    </span>
                    <div
                      data-v-2eea2747=""
                      class="flex flex-col fs-13 align-center text-center barStatus barItem flex-1"
                    >
                      <span data-v-2eea2747=""></span>
                      <span data-v-2eea2747="" class="barStatusLine">-</span>
                      <span data-v-2eea2747=""></span>
                    </div>
                  </div>
                  <div data-v-2eea2747="" class="team flex flex-col padding5">
                    <div data-v-2eea2747="" class="flex-1 align-center flex">
                      <span data-v-2eea2747="" class="teamLogoBox"
                        ><img
                          data-v-2eea2747=""
                          v-if="match.homeInfo"
                          :src="
                            match.homeInfo.logo
                              ? match.homeInfo.logo
                              : '/images/default-football.jpg'
                          "
                      /></span>
                      <div data-v-2eea2747="" class="w-o-h elseTeamName">
                        <template v-if="match && match.homeInfo">
                          {{
                            this.$i18n.locale == "cn" && match.homeInfo.cn_name
                              ? match.homeInfo.cn_name
                              : match.homeInfo.en_name
                              ? match.homeInfo.en_name
                              : ""
                          }}
                        </template>
                      </div>
                    </div>
                    <div data-v-2eea2747="" class="flex-1 align-center flex">
                      <span data-v-2eea2747="" class="teamLogoBox"
                        ><img
                          data-v-2eea2747=""
                          v-if="match.awayInfo"
                          :src="
                            match.awayInfo.logo
                              ? match.awayInfo.logo
                              : '/images/default-football.jpg'
                          "
                      /></span>
                      <div
                        data-v-2eea2747=""
                        itemprop="awayTeam"
                        class="w-o-h elseTeamName"
                      >
                        <template v-if="match && match.awayInfo">
                          {{
                            this.$i18n.locale == "cn" && match.awayInfo.cn_name
                              ? match.awayInfo.cn_name
                              : match.awayInfo.en_name
                              ? match.awayInfo.en_name
                              : ""
                          }}
                        </template>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-2eea2747=""
                    class="teamScore padding5"
                    v-if="
                      liveMatches.includes(match.status_id) ||
                      FinishedMatches.includes(match.status_id)
                    "
                  >
                    <div
                      data-v-2eea2747=""
                      class="flex flex-col align-center"
                      style="height: 100%"
                    >
                      <div
                        data-v-2eea2747=""
                        class="flex align-center justify-center fs-13 color-999 w-bar-100 flex-1"
                        style="font-family: Roboto-Regular, Roboto"
                      >
                        <div data-v-2eea2747="" class="svgBallIconBox"></div>
                        <div
                          data-v-2eea2747=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-2eea2747="" class=""
                            ><span
                              data-v-2eea2747=""
                              class=""
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.section_1
                                  ? match.homeInfo.section_1
                                  : "0"
                              }}
                            </span></span
                          >
                        </div>
                        <div
                          data-v-2eea2747=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-2eea2747="" class=""
                            ><span
                              data-v-2eea2747=""
                              class=""
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.section_2
                                  ? match.homeInfo.section_2
                                  : "0"
                              }}</span
                            ></span
                          >
                        </div>
                        <div
                          data-v-2eea2747=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-2eea2747="" class=""
                            ><span
                              data-v-2eea2747=""
                              class=""
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.section_3
                                  ? match.homeInfo.section_3
                                  : "0"
                              }}</span
                            ></span
                          >
                        </div>
                        <div
                          data-v-2eea2747=""
                          class="flex-1 text-center isVisible"
                        >
                          <span data-v-2eea2747="" class="scoreWeight"
                            ><span
                              data-v-2eea2747=""
                              class=""
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              {{
                                match &&
                                match.homeInfo &&
                                match.homeInfo.section_4
                                  ? match.homeInfo.section_4
                                  : "0"
                              }}</span
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-2eea2747=""
                    class="flex-1 icon-setting"
                    style="border-right: 1px solid rgb(234, 234, 234)"
                  >
                    <i
                      data-v-2eea2747=""
                      class="iconfont icon-donghuazhibo videoAn"
                    ></i>
                  </div>
                  <div
                    data-v-2eea2747=""
                    class="flex Moneyline moneyLineContent"
                    style="position: relative"
                  >
                    <div data-v-2eea2747="" class="flex flex-1 oddsItem">
                      <div
                        data-v-2eea2747=""
                        class="text-center le lh oddTextColor oddsItemBox oddsWidth1"
                      >
                        <div data-v-2eea2747="">
                          <div data-v-2eea2747="">
                            <div data-v-2eea2747="" class="oddhoverGreen">
                              {{
                                match &&
                                match.odds &&
                                match.odds.euro &&
                                match.odds.euro[2] !== ""
                                  ? match.odds.euro[2].toFixed(2)
                                  : "-"
                              }}
                            </div>
                            <div
                              data-v-2eea2747=""
                              class="oddhoverGreen oddTop"
                            >
                              {{
                                match &&
                                match.odds &&
                                match.odds.euro &&
                                match.odds.euro[4] !== ""
                                  ? match.odds.euro[4].toFixed(2)
                                  : "-"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="text-center le lh oddsItemBox oddsWidth1"
                        style="display: none"
                      ></div>
                      <div
                        data-v-2eea2747=""
                        class="text-center flex le oddsItemBox oddsWidth2"
                      >
                        <div data-v-2eea2747="" class="flex-1 lh handicapColor">
                          <div data-v-2eea2747="">
                            {{
                              match &&
                              match.odds &&
                              match.odds.asia &&
                              match.odds.asia[3] !== ""
                                ? -match.odds.asia[3].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div data-v-2eea2747="" class="oddTop">
                            {{
                              match &&
                              match.odds &&
                              match.odds.asia &&
                              match.odds.asia[3] !== ""
                                ? match.odds.asia[3].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                        <div data-v-2eea2747="" class="flex-1 lh oddTextColor">
                          <div data-v-2eea2747="" class="oddhoverGreen">
                            {{
                              match &&
                              match.odds &&
                              match.odds.asia &&
                              match.odds.asia[2] !== ""
                                ? match.odds.asia[2].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div data-v-2eea2747="" class="oddhoverGreen oddTop">
                            {{
                              match &&
                              match.odds &&
                              match.odds.asia &&
                              match.odds.asia[4] !== ""
                                ? match.odds.asia[4].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-2eea2747=""
                        class="flex-1 text-center flex le oddsItemBox oddsWidth3"
                      >
                        <div data-v-2eea2747="" class="flex-1 lh handicapColor">
                          <div data-v-2eea2747="">
                            <span data-v-2eea2747="">O</span>
                            {{
                              match &&
                              match.odds &&
                              match.odds.bigSmall &&
                              match.odds.bigSmall[3] !== ""
                                ? match.odds.bigSmall[3].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div data-v-2eea2747="" class="oddTop">
                            <span data-v-2eea2747="">U</span>
                            {{
                              match &&
                              match.odds &&
                              match.odds.bigSmall &&
                              match.odds.bigSmall[3] !== ""
                                ? match.odds.bigSmall[3].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                        <div data-v-2eea2747="" class="flex-1 lh oddTextColor">
                          <div data-v-2eea2747="" class="oddhoverGreen">
                            {{
                              match &&
                              match.odds &&
                              match.odds.bigSmall &&
                              match.odds.bigSmall[2] !== ""
                                ? match.odds.bigSmall[2].toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div data-v-2eea2747="" class="oddhoverGreen oddTop">
                            {{
                              match &&
                              match.odds &&
                              match.odds.bigSmall &&
                              match.odds.bigSmall[4] !== ""
                                ? match.odds.bigSmall[4].toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],
  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [2, 3, 4, 5, 6, 7, 8, 9],
      FinishedMatches: [100],
      countryList: null,
      countryLeagueActive: "",
      homeTeamInningsStatuses: [
        433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      ],
      awayTeamInningsStatuses: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      ],
      match_status: {
        NOT_STARTED: 1,
        FIRST_INNINGS_AWAY_TEAM: 432,
        FIRST_INNINGS_HOME_TEAM: 433,
        SECOND_INNINGS_AWAY_TEAM: 434,
        SECOND_INNINGS_HOME_TEAM: 435,
        THIRD_INNINGS_AWAY_TEAM: 436,
        THIRD_INNINGS_HOME_TEAM: 437,
        FOURTH_INNINGS_AWAY_TEAM: 438,
        FOURTH_INNINGS_HOME_TEAM: 439,
        FIFTH_INNINGS_AWAY_TEAM: 440,
        FIFTH_INNINGS_HOME_TEAM: 411,
        SIXTH_INNINGS_AWAY_TEAM: 412,
        SIXTH_INNINGS_HOME_TEAM: 413,
        SEVENTH_INNINGS_AWAY_TEAM: 414,
        SEVENTH_INNINGS_HOME_TEAM: 415,
        EIGHTH_INNINGS_AWAY_TEAM: 416,
        EIGHTH_INNINGS_HOME_TEAM: 417,
        NINTH_INNINGS_AWAY_TEAM: 418,
        NINTH_INNINGS_HOME_TEAM: 419,
        EXTRA_INNINGS_AWAY_TEAM: 420,
        EXTRA_INNINGS_HOME_TEAM: 421,
      },
    };
  },

  computed: {
    thereIsUpcomingMatches() {
   
      return true;
    },
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    addSectionsScore(section_1, section_2, section_3, section_4) {
      return section_1 + section_2 + section_3 + section_4;
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `baseball_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`baseball_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`baseball_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
  },
};
</script>
