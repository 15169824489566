<template>
  <span class="mobile-version">
    <MobileVersion />
  </span>
  <span class="desktop-version">
    <DesktopVersion />
  </span>
</template>
<script>
import DesktopVersion from "./contact-us-desktop.vue";
import MobileVersion from "./contact-us-mobile.vue";
export default {
  mounted() {
    document.title = "Terms And Conditions | Sportyclub Live";

    // Create meta description tag
    const metaDescription = document.createElement("meta");
    metaDescription.setAttribute("name", "description");
    metaDescription.content = "Contact Us of Sportyclub Live";
    document.head.appendChild(metaDescription);

    // Create meta keywords tag
    const metaKeywords = document.createElement("meta");
    metaKeywords.setAttribute("name", "keywords");
    metaKeywords.content = "contact us, sportyclub, sportyclub live";
    document.head.appendChild(metaKeywords);

    // Create canonical link tag
    const canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    canonicalLink.setAttribute("href", "https://sportyclub.live/en/contact-us");
    document.head.appendChild(canonicalLink);
  },
  components: {
    DesktopVersion,
    MobileVersion,
  },

};
</script>
