<template>
  <div class="w100" v-if="!loading">
    <div class="upcoming">
      <span class="iconfont icon-shaozi"></span>
      <span class="upcoming_text">
        {{ $t("football.live-score.today-upcoming-matches") }}</span
      >
    </div>
    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px); min-height: 400px"
    >
      <div
        class="w100"
        v-for="(league, key) in todayUpcomingMatches"
        :key="key"
      >
        <div class="list_active" style="width: 100%">
          <div
            class="list_title"
            :class="
              leagueFavoriteStatus[league[0].unique_tournament_id]
                ? 'collectComp'
                : ''
            "
          >
            <i
              class="iconfont collectImg"
              :class="
                leagueFavoriteStatus[league[0].unique_tournament_id]
                  ? 'icon-yishoucang'
                  : 'icon-weishoucang'
              "
              @click="toggleFavorite(league[0].unique_tournament_id)"
            ></i>
            <div
              class="flex align-center"
              style="overflow: hidden; width: 8.4rem"
            >
              <div
                class="listImg van-image van-image--round"
                itemprop="logo"
                style="overflow: hidden; border-radius: 50%"
              >
                <img
                  class="van-image__img"
                  v-if="
                    league[0] &&
                    league[0].leagueInfo &&
                    ((league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.logo) ||
                      (league[0].leagueInfo.countryDetails &&
                        league[0].leagueInfo.countryDetails.name) ||
                      (league[0].leagueInfo.categoryDetails &&
                        league[0].leagueInfo.categoryDetails.name))
                  "
                  :src="
                    league[0] &&
                    league[0].leagueInfo &&
                    league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo
                      ? league[0].leagueInfo.countryDetails.logo
                     : bannerImagePath('default-country.png')
                  "
                  alt=""
                  lazy="loaded"
                  style="object-fit: contain"
                />
              </div>
              <span data-v-89734b7c="" class="countryText">
                <span data-v-f1b1cb6e="" class="country-name minitext">
                  <template v-if="league[0] && league[0].leagueInfo">
                    <template v-if="this.$i18n.locale == 'cn'">
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.cn_name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.cn_name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          league[0].leagueInfo.countryDetails &&
                          league[0].leagueInfo.countryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.countryDetails.name }}:
                      </template>
                      <template
                        v-else-if="
                          league[0].leagueInfo.categoryDetails &&
                          league[0].leagueInfo.categoryDetails.name
                        "
                      >
                        {{ league[0].leagueInfo.categoryDetails.name }}:
                      </template>
                      <template v-else> </template>
                    </template>
                  </template>
                </span>
              </span>
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/table_tennis/league/details/' +
                  league[0].leagueInfo.en_name
                    .toLowerCase()
                    .replace(/[\s/]+/g, '-') +
                  '/' +
                  league[0].unique_tournament_id
                "
                class="compText"
                style="font-size: 0.32rem"
              >
                {{
                  this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                    ? league[0].leagueInfo.cn_name
                    : league[0].leagueInfo.en_name
                    ? league[0].leagueInfo.en_name
                    : ""
                }}
              </a>
            </div>
          </div>
          <div class="w100 list_active">
            <div
              v-for="match in league"
              :key="match.id"
              class="list"
              style="height: 1.38rem; position: relative"
            >
              <div class="w100 flex h100 contentListBox">
                <a
                  :href="
                    '/' +
                    this.$i18n.locale +
                    '/table_tennis/today-match/' +
                    match.id
                  "
                  class="flex flex-1 h100 allBox"
                >
                  <!-- <i class="iconfont collectImg icon-weishoucang"></i> -->
                  <div class="w100 flex h100 align-center">
                    <div
                      class="flex align-center justify-center listBox flex-column"
                    >
                      <span
                        class="fs-12 text-center"
                        style="color: rgb(153, 153, 153)"
                      >
                        {{ readableTime(this.$i18n.locale, match.match_time) }}
                      </span>

                      <div class="flex fs-12 align-center color-r text-center">
                        <span class="fs-12" style="white-space: nowrap">{{
                          match.status_id &&
                          this.FinishedMatches.includes(match.status_id)
                            ? $t("football.league-details.full-time")
                            : match?.statusDescription
                        }}</span>
                      </div>
                    </div>
                    <div class="team flex flex-col padding5">
                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.homeInfo"
                                  :src="
                                    match.homeInfo.logo
                                      ? match.homeInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt="Punjab Royals"
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="homeTeam"
                              class="w-o-h fs-14 pl2"
                              style="padding-left: 0.2rem; max-width: 4.8rem"
                            >
                              <template v-if="match && match.homeInfo">{{
                                this.$i18n.locale == "cn" &&
                                match.homeInfo.cn_name
                                  ? match.homeInfo.cn_name
                                  : match.homeInfo.en_name
                                  ? match.homeInfo.en_name
                                  : ""
                              }}</template>
                            </div>
                            <!---->
                          </div>
                        </div>
                      </div>

                      <div class="flex-1 align-center flex">
                        <div class="flex align-center" style="width: 0.4rem">
                          <div class="flex-1 align-center flex">
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  v-if="match.awayInfo"
                                  :src="
                                    match.awayInfo.logo
                                      ? match.awayInfo.logo
                                    : bannerImagePath('default-team.png')
                                  "
                                  lazy="loaded"
                                  alt="Punjab Royals"
                                  class="van-image__img"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              itemprop="homeTeam"
                              class="w-o-h fs-14 pl2"
                              style="padding-left: 0.2rem; max-width: 4.8rem"
                            >
                              <template v-if="match && match.awayInfo">{{
                                this.$i18n.locale == "cn" &&
                                match.awayInfo.cn_name
                                  ? match.awayInfo.cn_name
                                  : match.awayInfo.en_name
                                  ? match.awayInfo.en_name
                                  : ""
                              }}</template>
                            </div>
                            <!---->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="icon-setting">
                      <span
                        v-if="liveMatches.includes(match.status_id)"
                        class="iconfont icon-shipinzhibo"
                      ></span>
                      <span v-else class="iconfont icon-donghuazhibo"></span>
                    </div>

                    <div class="teamScore padding5" style="width: 1.17rem">
                      <div
                        class="flex flex-col align-center"
                        style="
                          height: 100%;
                          color: rgb(153, 153, 153);
                          line-height: 12px;
                        "
                      >
                        <div class="flex oddDataBox">
                          <div
                            class="flex align-center"
                            style="flex: 1 1 0%; font-size: 0.32rem"
                          >
                            {{
                              match.odds && match.odds.euro
                                ? parseFloat(match?.odds?.euro[1]).toFixed(2)
                                : "-"
                            }}
                          </div>
                          <div
                            class="flex align-center"
                            style="flex: 1 1 0%; font-size: 0.32rem"
                          >
                            {{
                              match.odds && match.odds.euro
                                ? parseFloat(match?.odds?.euro[3]).toFixed(2)
                                : "-"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,DateMixin],
  data() {
    return {
      todayUpcomingMatches: null,

      loading: true,
      leagueFavoriteStatus: {}, // Store favorite status for each league
      liveMatches: [],
      FinishedMatches: [100],
    };
  },
  created() {
    this.gettodayUpcomingMatches("upcoming");
  },
  methods: {
    loadFavoriteStatus() {
      if (this.todayUpcomingMatches) {
        Object.values(this.todayUpcomingMatches).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `tennis_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`tennis_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayUpcomingMatches) {
        this.todayUpcomingMatches.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`tennis_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    // method to get today matchlist
    gettodayUpcomingMatches(status) {
      this.liveActivebtn = status;
      this.loading = true;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/table_tennis/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayUpcomingMatches = response.data.table_tennisMatchList;
          this.countLiveMatches = response.data.liveMatches;
          this.loadFavoriteStatus();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
};
</script>

