<template>
  <div v-if="loading" class="standingBox" data-v-1565c0b8="">
    <p class="title fs-14 flex align-center" data-v-1565c0b8="">
      {{
        leagueDetails && this.$i18n.locale == "cn" && leagueDetails.cn_name
          ? leagueDetails.cn_name +
            " " +
            $t("football.league-details.standings")
          : leagueDetails.name
          ? leagueDetails.name + " " + $t("football.league-details.standings")
          : ""
      }}
    </p>
    <div data-v-1565c0b8="">
      <div style="display: " data-v-1565c0b8="" class="">
        <p class="tableTitle" data-v-1565c0b8=""></p>
        <div class="tableHeader flex" data-v-1565c0b8="">
          <span class="flex justify-center align-center w-52" data-v-1565c0b8=""
            >#</span
          >
          <span class="flex align-center w-360" data-v-1565c0b8="">{{
            $t("football.league-details.team")
          }}</span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            P
          </span>
          <span
            class="flex justify-center align-center flex-1 w"
            data-v-1565c0b8=""
          >
            W
          </span>

          <span
            class="flex justify-center align-center flex-1 l"
            data-v-1565c0b8=""
          >
            L
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            OT
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            {{ $t("football.league-details.goals") }}
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            PTS
          </span>
        </div>
        <ul class="tableContent" data-v-1565c0b8="">
          <div
            class="flex justify-center align-center flex-col"
            style="height: 400px"
            data-v-4291ef99=""
          >
            <div class="clearfix-row view border-box" data-v-8948368c="">
              <div class="detail view border-box" data-v-250f1619="">
                <div
                  class="loading van-loading van-loading--spinner van-loading--vertical"
                  style="text-align: center"
                >
                  <span
                    class="van-loading__spinner van-loading__spinner--spinner"
                    ><img
                      src="/images/default-loader.gif"
                      style="width: 30% !important"
                      alt=""
                  /></span>
                </div>
                <div class="prefetch"></div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <div v-else-if="!leagueStandings" class="standingBox" data-v-1565c0b8="">
    <p class="title fs-14 flex align-center" data-v-1565c0b8="">
      {{
        leagueDetails && this.$i18n.locale == "cn" && leagueDetails.cn_name
          ? leagueDetails.cn_name +
            " " +
            $t("football.league-details.standings")
          : leagueDetails.name
          ? leagueDetails.name + " " + $t("football.league-details.standings")
          : ""
      }}
    </p>
    <div data-v-1565c0b8="">
      <div style="display: " data-v-1565c0b8="" class="">
        <p class="tableTitle" data-v-1565c0b8=""></p>
        <div class="tableHeader flex" data-v-1565c0b8="">
          <span class="flex justify-center align-center w-52" data-v-1565c0b8=""
            >#</span
          >
          <span class="flex align-center w-360" data-v-1565c0b8="">{{
            $t("football.league-details.team")
          }}</span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            P
          </span>
          <span
            class="flex justify-center align-center flex-1 w"
            data-v-1565c0b8=""
          >
            W
          </span>

          <span
            class="flex justify-center align-center flex-1 l"
            data-v-1565c0b8=""
          >
            L
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            OT
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            {{ $t("football.league-details.goals") }}
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            PTS
          </span>
        </div>
        <ul class="tableContent" data-v-1565c0b8="">
          <div
            class="flex justify-center align-center flex-col"
            style="height: 400px"
            data-v-4291ef99=""
          >
            <img :src="bannerImagePath('no-data.png')" style="width: 123px; height: 74px" />
            <div class="color-999 fs-12 mt-12">
              {{ $t("football.live-score.temporarily-no-data") }}
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>

  <div v-else class="standingBox" data-v-1565c0b8="">
    <p class="title fs-14 flex align-center" data-v-1565c0b8="">
      {{
        leagueDetails && this.$i18n.locale == "cn" && leagueDetails.cn_name
          ? leagueDetails.cn_name +
            " " +
            $t("football.league-details.standings")
          : leagueDetails.name
          ? leagueDetails.name + " " + $t("football.league-details.standings")
          : ""
      }}
    </p>
    <div
      data-v-1565c0b8=""
      v-for="(standingDetail, standingName) in leagueStandings"
      :key="standingDetail.id"
    >
      <div style="display: " data-v-1565c0b8="" class="">
        <p class="tableTitle" data-v-1565c0b8="">
          {{ standingName + " " + $t("football.league-details.standings") }}
        </p>
        <div class="tableHeader flex" data-v-1565c0b8="">
          <span class="flex justify-center align-center w-52" data-v-1565c0b8=""
            >#</span
          >
          <span class="flex align-center w-360" data-v-1565c0b8="">{{
            $t("football.league-details.team")
          }}</span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            P
          </span>
          <span
            class="flex justify-center align-center flex-1 w"
            data-v-1565c0b8=""
          >
            W
          </span>

          <span
            class="flex justify-center align-center flex-1 l"
            data-v-1565c0b8=""
          >
            L
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            OT
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            {{ $t("football.league-details.goals") }}
          </span>
          <span
            class="flex justify-center align-center flex-1"
            data-v-1565c0b8=""
          >
            PTS
          </span>
        </div>
        <ul
          class="tableContent"
          data-v-1565c0b8=""
          v-for="(rowDetails, index) in standingDetail.rows"
          :key="index"
        >
          <template v-if="rowDetails.teamInfo">
            <li class="flex" data-v-1565c0b8="">
              <div
                class="flex justify-center align-center w-52"
                data-v-1565c0b8=""
              >
                {{ index + 1 }}
              </div>
              <a
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/hockey/team/details/' +
                  rowDetails.teamInfo.name.toLowerCase().replace(/\s/g, '-') +
                  '/' +
                  rowDetails.team_id
                "
                target="_blank"
                class="flex align-center w-360 teamData"
                data-v-1565c0b8=""
              >
                <img
                  :src="
                    rowDetails.teamInfo && rowDetails.teamInfo.logo
                      ? rowDetails.teamInfo.logo + '!w30'
                    : bannerImagePath('default-team.png')
                  "
                  alt=""
                  data-v-1565c0b8=""
                />
                <span data-v-1565c0b8="">{{
                  rowDetails?.teamInfo?.name
                }}</span></a
              >
              <div
                class="flex justify-center align-center flex-1"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.total }}
              </div>
              <div
                class="flex justify-center align-center flex-1 w"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.win }}
              </div>
              <div
                class="flex justify-center align-center flex-1 l"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.loss }}
              </div>
              <div
                class="flex justify-center align-center flex-1"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.overtime_win }}:{{ rowDetails?.overtime_loss }}
              </div>
              <div
                class="flex justify-center align-center flex-1"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.goals }}:{{ rowDetails?.goals_against }}
              </div>
              <!-- <div
              class="flex justify-center align-center flex-1"
              data-v-1565c0b8=""
            >
              -
            </div> -->
              <!-- <div
              class="flex justify-center align-center flex-1"
              data-v-1565c0b8=""
            >
              -
            </div> -->
              <div
                class="flex justify-center align-center flex-1"
                data-v-1565c0b8=""
              >
                {{ rowDetails?.points }}
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      topPlayers: "points",
      leagueStandings: null,
      leagueTeamsStats: null,
      playersPropertyStats: null,
      loading: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getLeagueStandings(this.leagueId);
    // this.getPlayerStats(this.leagueId, this.topPlayers);
    // this.getLeagueTeamStats(this.leagueId);
  },
  methods: {
    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.topPlayers = status;
      this.getPlayerStats(this.leagueId, this.topPlayers);
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/hockey/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data.standings;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getPlayerStats(leagueId, property) {
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/volleyball/league/season/playersStats/${leagueId}?playerProperty=${property}`
    //     )
    //     .then((response) => {
    //       this.playersPropertyStats = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // // method to get team stats
    // getLeagueTeamStats(leagueId) {
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/volleyball/league/season/teamsStats/${leagueId}`
    //     )
    //     .then((response) => {
    //       this.leagueTeamsStats = response.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>
