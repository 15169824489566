<template>
  <span mode="out-in" data-v-4a160e70="">
    <!-- begin::loader -->
    <div class="detail view border-box" data-v-250f1619="" v-if="loading">
      <div
        class="loading van-loading van-loading--spinner van-loading--vertical"
      >
        <span class="van-loading__spinner van-loading__spinner--spinner"
          ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i
        ></span>
      </div>
      <div class="prefetch"></div>
    </div>
    <!-- end::leader -->

    <div
      activetab="odds"
      class="w100"
      data-v-07c8a370=""
      data-v-4a160e70=""
      v-if="!loading"
    >
      <div class="newOdds" data-v-07c8a370="">
        <p class="oddsType" data-v-07c8a370="">
          <span class="openingBox openingBox1" data-v-07c8a370=""
            ><span class="chunk opening" data-v-07c8a370=""></span>
            <span data-v-07c8a370="">
              {{ $t("football.league-details.opening-odds") }}</span
            ></span
          >
          <span class="openingBox" data-v-07c8a370=""
            ><span class="chunk pre-match" data-v-07c8a370=""></span>
            <span data-v-07c8a370="">
              {{ $t("football.league-details.pre-match-odds") }}</span
            ></span
          >
        </p>
        <div class="content flex" data-v-07c8a370="">
          <div class="comp" data-v-07c8a370="">
            <div class="header" data-v-07c8a370=""></div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex" data-v-07c8a370="">
                <div
                  class="flex-1 text notRightColor"
                  style="border-bottom: none"
                  data-v-07c8a370=""
                >
                  {{ $t("football.league-details.to-win") }}
                </div>
              </div>
            </div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex" data-v-07c8a370="">
                <div class="flex-1 text notRightColor" data-v-07c8a370=""></div>
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.spread") }}
                </div>
                <div class="flex-1 text" data-v-07c8a370=""></div>
              </div>
            </div>
          </div>
          <div class="box" data-v-07c8a370="">
            <div class="header" data-v-07c8a370="">
              <div class="w100 flex brr" data-v-07c8a370="">
                <div class="flex-1 text notRightColor" data-v-07c8a370="">
                  {{ $t("football.league-details.total-points") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- begin bet365 ::company -->
        <div
          class="content"
          style="background: rgb(255, 255, 255)"
          data-v-07c8a370=""
          v-if="
            liveMatchDetails &&
            liveMatchDetails.matchDetails &&
            liveMatchDetails.matchDetails.matchOdds &&
            liveMatchDetails.matchDetails.matchOdds.bet365
          "
        >
          <div data-v-07c8a370="" class="flex w100 borderBottom">
            <div
              data-v-07c8a370=""
              class="comp flex justify-center brb"
              style="width: 144px; align-items: flex-start"
            >
              <img
                data-v-07c8a370=""
                src="/images/odds/bet365.png"
                class="logo"
                style="margin-top: 15px"
              />
            </div>
            <div data-v-07c8a370="" class="flex-1">
              <div data-v-07c8a370="" class="w100 flex-1 brb brr flex">
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0][1]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][1].toFixed(
                                    2
                                  )
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[0][3]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                    2
                                  )
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ][1]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.euro.length - 1
                                  ][1].toFixed(2)
                                : "-"
                            }}</span
                          ></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="">
                          <span data-v-07c8a370="" class="">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .euro[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .euro.length - 1
                              ][3]
                                ? liveMatchDetails.matchDetails.matchOdds.bet365.euro[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.euro.length - 1
                                  ][3].toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0][2] < 0
                                ? "+" +
                                  processNumber(
                                    -liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][2] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][2] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][1] 
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0][2] < 0
                                ? "-" +
                                  processNumber(
                                    -liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][2] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[0][2] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[0]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[0][3] 
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg2"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ][2] < 0
                                ? "+" +
                                  processNumber(
                                    -liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][2] > 0
                                ? "-" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][2] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>

                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ]
                                ? (
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][1] 
                                  ).toFixed(2)
                                : "-"
                            }}
                          </span></span
                        >
                      </div>

                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="asiaItemBox">
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-if="
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia
                            "
                          >
                            {{
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ] &&
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia[
                                liveMatchDetails.matchDetails.matchOdds.bet365
                                  .asia.length - 1
                              ][2] < 0
                                ? "-" +
                                  processNumber(
                                    -liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][2] > 0
                                ? "+" +
                                  processNumber(
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia[
                                      liveMatchDetails.matchDetails.matchOdds
                                        .bet365.asia.length - 1
                                    ][2]
                                  )
                                : liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][2] == 0
                                ? "0"
                                : "-"
                            }}
                          </span>
                          <span
                            data-v-07c8a370=""
                            class="handicap handicapAsia asiaItemLeft"
                            v-else
                          >
                            -
                          </span>
                          <span data-v-07c8a370="" class="asiaItemRight">{{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .asia &&
                            liveMatchDetails.matchDetails.matchOdds.bet365.asia[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .asia.length - 1
                            ]
                              ? (
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .asia[
                                    liveMatchDetails.matchDetails.matchOdds
                                      .bet365.asia.length - 1
                                  ][3] 
                                ).toFixed(2)
                              : "-"
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-07c8a370=""
                  class="flex flex-1 align-center flex-col"
                  style="position: relative"
                >
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr openingBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="box flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][2].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][1].toFixed(
                                  2
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[0]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[0][3].toFixed(
                                  2
                                )
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    data-v-07c8a370=""
                    class="box flex w100 brr preMatchBg1"
                    style="height: 32px; box-sizing: border-box"
                  >
                    <div data-v-07c8a370="" class="ox flex w100 oddsItemBox">
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="" class="handicap">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][2]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][2].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][1]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][1].toFixed(2)
                              : "-"
                          }}</span
                        >
                      </div>
                      <div
                        data-v-07c8a370=""
                        class="oddItems flex align-center justify-center flex-1 preMatchBorderBox borderBottom"
                        style="height: 32px"
                      >
                        <span data-v-07c8a370="">
                          {{
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ] &&
                            liveMatchDetails.matchDetails.matchOdds.bet365
                              .bigSmall[
                              liveMatchDetails.matchDetails.matchOdds.bet365
                                .bigSmall.length - 1
                            ][3]
                              ? liveMatchDetails.matchDetails.matchOdds.bet365.bigSmall[
                                  liveMatchDetails.matchDetails.matchOdds.bet365
                                    .bigSmall.length - 1
                                ][3].toFixed(2)
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end bet365::company -->

        <p class="oddText" data-v-07c8a370="">
          {{ $t("football.league-details.gamble-responsibly") }}
        </p>
        <div class="w100 propUp" data-v-07c8a370=""></div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      liveMatchDetails: null,
      loading: true,
      liveMatches: [],
    };
  },
  created() {
    this.getTodayMatchDetails(this.matchId);
  },
  methods: {
    // method to get today matchlist
    getTodayMatchDetails(matchId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/volleyball/match/details/` + matchId)
        .then((response) => {
          this.liveMatchDetails = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to check decimal value
    processNumber(value) {
      if (value == 0) {
        return value;
      }

      const stringValue = value.toString();
      const decimalIndex = stringValue.indexOf(".");

      if (decimalIndex !== -1 && decimalIndex < stringValue.length - 2) {
        const decimalPart = stringValue.slice(
          decimalIndex + 1,
          decimalIndex + 3
        );

        if (decimalPart === "25") {
          return `${Math.floor(value)}/${Math.floor(value) + 0.5}`;
        } else if (decimalPart === "75") {
          return `${Math.floor(value) + 0.5}/${Math.floor(value)}`;
        }
      }

      if (stringValue.endsWith(".5")) {
        return stringValue;
      }

      // Default return when none of the conditions are met
      return stringValue;
    },
  },
};
</script>
