<template>
  <div class="w100">
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      data-v-06d4278f=""
      style="top: 10rem"
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-06d4278f=""
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
      ></span>
    </div>
    <div v-else data-v-2115270e="" class="analyseContent">
      <div data-v-2115270e="" class="teamBtnBox">
        <span
          data-v-2115270e=""
          class="homeTeam"
          :class="{ activeTeam: this.activeTab === 'h2h' }"
          @click="toggleTabs('h2h')"
        >
          H2H </span
        ><span
          data-v-2115270e=""
          class="awayTeam ml-8"
          :class="{ activeTeam: this.activeTab === 'home' }"
          @click="toggleTabs('home')"
        >
          home</span
        ><span
          data-v-2115270e=""
          class="awayTeam ml-8"
          :class="{ activeTeam: this.activeTab === 'away' }"
          @click="toggleTabs('away')"
        >
          away
        </span>
      </div>
      <div data-v-2115270e="" class="flex">
        <div data-v-2115270e="" class="contentBox flex-1">
          <div data-v-2115270e="" class="allBox">
            <div data-v-2115270e="" class="headerBox">
              <p data-v-2115270e="">
                <span data-v-2115270e="">H2H</span>
              </p>
              <p data-v-2115270e=""></p>
            </div>
            <nav
              v-if="this.activeTab === 'home'"
              aria-label="Page navigation example"
              style="
                display: flex;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                justify-content: center;
              "
            >
              <ul
                class="pagination"
                style="justify-content: space-around; width: 80%"
              >
                <li
                  class="page-item"
                  :class="{ disabled: homeCurrentPage == 1 }"
                >
                  <a class="page-link" tabindex="-1" @click="homePrevPage"
                    >&lt;</a
                  >
                </li>
                <li
                  v-for="page in homeTotalPages"
                  :key="page"
                  @click="homeGoToPage(page)"
                  class="page-item"
                  :class="{ active: page === homeCurrentPage }"
                >
                  <a class="page-link">{{ page }}</a>
                </li>

                <li
                  class="page-item"
                  @click.prevent="homeNextPage"
                  :class="{ disabled: homeCurrentPage == homeTotalPages }"
                >
                  <a class="page-link">&gt;</a>
                </li>
              </ul>
            </nav>
            <nav
              v-if="this.activeTab === 'away'"
              aria-label="Page navigation example"
              style="
                display: flex;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                justify-content: center;
              "
            >
              <ul
                class="pagination"
                style="justify-content: space-around; width: 80%"
              >
                <li
                  class="page-item"
                  :class="{ disabled: awayCurrentPage == 1 }"
                >
                  <a class="page-link" tabindex="-1" @click="awayPrevPage"
                    >&lt;</a
                  >
                </li>
                <li
                  v-for="page in awayTotalPages"
                  :key="page"
                  @click="awayGoToPage(page)"
                  class="page-item"
                  :class="{ active: page === awayCurrentPage }"
                >
                  <a class="page-link">{{ page }}</a>
                </li>

                <li
                  class="page-item"
                  @click.prevent="awayNextPage"
                  :class="{ disabled: awayCurrentPage == awayTotalPages }"
                >
                  <a class="page-link">&gt;</a>
                </li>
              </ul>
            </nav>

            <div data-v-2115270e="" class="infoBox">
              <span data-v-2115270e=""
                ><span data-v-2115270e="" class="round win flexCenter">W</span>
                <span data-v-2115270e="" class="text">X6</span></span
              >

              <span data-v-2115270e="" class="leftMargin20"
                ><span data-v-2115270e="" class="round loser flexCenter"
                  >L</span
                >
                <span data-v-2115270e="" class="text">X0</span></span
              >
              <span data-v-2115270e="" class="leftMargin20 infoItem"
                ><span data-v-2115270e="" class="pts">pts</span>
                <span data-v-2115270e="" class="text">
                  96.2 - 80.3 per game
                </span>
              </span>
            </div>
            <div
              v-if="matchH2h && matchH2h != ''"
              data-v-2115270e=""
              class="matchContent"
            >
              <template v-if="this.activeTab === 'h2h'">
                <template
                  v-for="(league, leagueName) in matchH2h"
                  :key="league"
                >
                  <p data-v-2115270e="" class="header">
                    <span data-v-2115270e="" class="comp flexCenter">
                      <span data-v-2115270e="" class="country flexCenter"
                        ><template v-if="league[0]">
                          <template
                            v-if="
                              league[0].tournament &&
                              league[0].tournament.category &&
                              league[0].tournament.category.country &&
                              league[0].tournament.category.country.name
                            "
                          >
                            {{ league[0].tournament.category.country.name }}
                          </template>
                          <template
                            v-else-if="
                              league[0].tournament.category &&
                              league[0].tournament.category.name
                            "
                          >
                            {{ league[0].tournament.category.name }}
                          </template>
                          <template v-else></template>
                        </template>
                        :</span
                      >
                      <span data-v-2115270e="" class="flexCenter">{{
                        leagueName
                      }}</span>
                    </span>
                  </p>
                  <ul data-v-2115270e="" class="matchBox">
                    <li v-for="match in league" :key="match" data-v-2115270e="">
                      <p data-v-2115270e="" class="collect flexCenter">
                        <template v-if="this.activeTab === 'away'">
                          <span
                            v-if="
                              (match.winnerCode == 1 &&
                                match.homeTeam.id == awayTeamId) ||
                              (match.winnerCode == 2 &&
                                match.awayTeam.id == awayTeamId)
                            "
                            data-v-2115270e=""
                            class="round flexCenter win"
                          >
                            W
                          </span>
                          <span
                            v-else-if="
                              (match.winnerCode == 1 &&
                                match.homeTeam.id != awayTeamId) ||
                              (match.winnerCode == 2 &&
                                match.awayTeam.id != awayTeamId)
                            "
                            data-v-2115270e=""
                            class="round flexCenter loser"
                          >
                            L
                          </span>
                          <span
                            v-else-if="
                              match.winnerCode &&
                              match.winnerCode != 1 &&
                              match.winnerCode != 2
                            "
                            data-v-2115270e=""
                            class="round flexCenter"
                            style="background: #ffba5a"
                          >
                            D
                          </span>
                          <!-- <span v-else data-v-2115270e="" style="color: red">
                          Live
                        </span> -->
                        </template>
                        <template v-else>
                          <span
                            v-if="
                              (match.winnerCode == 1 &&
                                match.homeTeam.id == homeTeamId) ||
                              (match.winnerCode == 2 &&
                                match.awayTeam.id == homeTeamId)
                            "
                            data-v-2115270e=""
                            class="round flexCenter win"
                          >
                            W
                          </span>
                          <span
                            v-else-if="
                              (match.winnerCode == 1 &&
                                match.homeTeam.id != homeTeamId) ||
                              (match.winnerCode == 2 &&
                                match.awayTeam.id != homeTeamId)
                            "
                            data-v-2115270e=""
                            class="round flexCenter loser"
                          >
                            L
                          </span>
                          <span
                            v-else-if="
                              match.winnerCode &&
                              match.winnerCode != 1 &&
                              match.winnerCode != 2
                            "
                            data-v-2115270e=""
                            class="round flexCenter"
                            style="background: #ffba5a"
                          >
                            D
                          </span>
                          <!-- <span v-else data-v-2115270e="" style="color: red">
                          Live
                        </span> -->
                        </template>
                      </p>
                      <a
                        data-v-2115270e=""
                        :href="
                          '/' +
                          this.$i18n.locale +
                          '/cricket/match/details/' +
                          match.homeTeam.name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '-vs-' +
                          match.awayTeam.name
                            .toLowerCase()
                            .replace(/\s/g, '-') +
                          '/' +
                          match.id
                        "
                        target="_self"
                        ><p
                          data-v-2115270e=""
                          itemprop="startDate"
                          class="time flexCenter"
                        >
                          {{ getH2hDateTimeFormat(match.startTimestamp) }}
                        </p>
                        <p data-v-2115270e="" class="teamBox">
                          <span data-v-2115270e="" class="teamBoxItem"
                            ><span data-v-2115270e="">
                              <img
                                data-v-2115270e=""
                                :src="
                                  match.homeTeam && match.homeTeam.id
                                    ? 'https://sportyclub.online/images/cricket/teams/' +
                                      match.homeTeam.id +
                                      '/' +
                                      match.homeTeam.id +
                                      '.png'
                                    : bannerImagePath('default-team.png')
                                "
                                @error="
                                  $event.target.src =
                                    bannerImagePath('default-team.png')
                                "
                              />
                            </span>
                            <span data-v-2115270e="" class="teamName"
                              >{{
                                this.$i18n.locale == "cn" &&
                                match &&
                                match.homeTeam &&
                                match.homeTeam.cn_name
                                  ? match.homeTeam.cn_name
                                  : match.homeTeam.name
                                  ? match.homeTeam.name
                                  : ""
                              }}
                            </span>
                          </span>
                          <span data-v-2115270e="" class="teamBoxItem">
                            <span data-v-2115270e="">
                              <img
                                data-v-2115270e=""
                                :src="
                                  match.awayTeam && match.awayTeam.id
                                    ? 'https://sportyclub.online/images/cricket/teams/' +
                                      match.awayTeam.id +
                                      '/' +
                                      match.awayTeam.id +
                                      '.png'
                                    : bannerImagePath('default-team.png')
                                "
                                @error="
                                  $event.target.src =
                                    bannerImagePath('default-team.png')
                                "
                              />
                            </span>
                            <span data-v-2115270e="" class="teamName"
                              >{{
                                this.$i18n.locale == "cn" &&
                                match &&
                                match.awayTeam &&
                                match.awayTeam.cn_name
                                  ? match.awayTeam.cn_name
                                  : match.awayTeam.name
                                  ? match.awayTeam.name
                                  : ""
                              }}
                            </span>
                          </span>
                        </p>

                        <p data-v-2115270e="" class="scoreBox">
                          <span
                            v-if="match.homeScore && match.homeScore.innings"
                            data-v-2115270e=""
                            class="flexCenter"
                            :class="{
                              winText: match.winnerCode === 1,
                              loserText: match.winnerCode !== 1,
                            }"
                          >
                            <template
                              v-for="homeInning in match.homeScore.innings"
                              :key="homeInning"
                              >{{
                                homeInning.score +
                                "/" +
                                homeInning.wickets +
                                "(" +
                                homeInning.overs +
                                ")"
                              }}
                            </template>
                          </span>
                          <span
                            v-if="match.awayScore && match.awayScore.innings"
                            data-v-2115270e=""
                            class="flexCenter"
                            :class="{
                              winText: match.winnerCode === 2,
                              loserText: match.winnerCode !== 2,
                            }"
                          >
                            <template
                              v-for="awayInning in match.awayScore.innings"
                              :key="awayInning"
                              >{{
                                awayInning.score +
                                "/" +
                                awayInning.wickets +
                                "(" +
                                awayInning.overs +
                                ")"
                              }}
                            </template>
                          </span>
                        </p></a
                      >
                    </li>
                  </ul>
                </template>
              </template>
              <template v-else-if="this.activeTab === 'home'">
                <ul data-v-2115270e="" class="matchBox">
                  <li
                    v-for="match in homePaginatedMatches"
                    :key="match"
                    data-v-2115270e=""
                  >
                    <p data-v-2115270e="" class="collect flexCenter">
                      <template v-if="this.activeTab === 'away'">
                        <span
                          v-if="
                            (match.winnerCode == 1 &&
                              match.homeTeam.id == awayTeamId) ||
                            (match.winnerCode == 2 &&
                              match.awayTeam.id == awayTeamId)
                          "
                          data-v-2115270e=""
                          class="round flexCenter win"
                        >
                          W
                        </span>
                        <span
                          v-else-if="
                            (match.winnerCode == 1 &&
                              match.homeTeam.id != awayTeamId) ||
                            (match.winnerCode == 2 &&
                              match.awayTeam.id != awayTeamId)
                          "
                          data-v-2115270e=""
                          class="round flexCenter loser"
                        >
                          L
                        </span>
                        <span
                          v-else-if="
                            match.winnerCode &&
                            match.winnerCode != 1 &&
                            match.winnerCode != 2
                          "
                          data-v-2115270e=""
                          class="round flexCenter"
                          style="background: #ffba5a"
                        >
                          D
                        </span>
                        <!-- <span v-else data-v-2115270e="" style="color: red">
                          Live
                        </span> -->
                      </template>
                      <template v-else>
                        <span
                          v-if="
                            (match.winnerCode == 1 &&
                              match.homeTeam.id == homeTeamId) ||
                            (match.winnerCode == 2 &&
                              match.awayTeam.id == homeTeamId)
                          "
                          data-v-2115270e=""
                          class="round flexCenter win"
                        >
                          W
                        </span>
                        <span
                          v-else-if="
                            (match.winnerCode == 1 &&
                              match.homeTeam.id != homeTeamId) ||
                            (match.winnerCode == 2 &&
                              match.awayTeam.id != homeTeamId)
                          "
                          data-v-2115270e=""
                          class="round flexCenter loser"
                        >
                          L
                        </span>
                        <span
                          v-else-if="
                            match.winnerCode &&
                            match.winnerCode != 1 &&
                            match.winnerCode != 2
                          "
                          data-v-2115270e=""
                          class="round flexCenter"
                          style="background: #ffba5a"
                        >
                          D
                        </span>
                        <!-- <span v-else data-v-2115270e="" style="color: red">
                          Live
                        </span> -->
                      </template>
                    </p>
                    <a
                      data-v-2115270e=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      target="_self"
                      ><p
                        data-v-2115270e=""
                        itemprop="startDate"
                        class="time flexCenter"
                      >
                        {{ getH2hDateTimeFormat(match.startTimestamp) }}
                      </p>
                      <p data-v-2115270e="" class="teamBox">
                        <span data-v-2115270e="" class="teamBoxItem"
                          ><span data-v-2115270e="">
                            <img
                              data-v-2115270e=""
                              :src="
                                match.homeTeam && match.homeTeam.id
                                  ? 'https://sportyclub.online/images/cricket/teams/' +
                                    match.homeTeam.id +
                                    '/' +
                                    match.homeTeam.id +
                                    '.png'
                                  : bannerImagePath('default-team.png')
                              "
                              @error="
                                $event.target.src =
                                  bannerImagePath('default-team.png')
                              "
                            />
                          </span>
                          <span data-v-2115270e="" class="teamName"
                            >{{
                              this.$i18n.locale == "cn" &&
                              match &&
                              match.homeTeam &&
                              match.homeTeam.cn_name
                                ? match.homeTeam.cn_name
                                : match.homeTeam.name
                                ? match.homeTeam.name
                                : ""
                            }}
                          </span>
                        </span>
                        <span data-v-2115270e="" class="teamBoxItem">
                          <span data-v-2115270e="">
                            <img
                              data-v-2115270e=""
                              :src="
                                match.awayTeam && match.awayTeam.id
                                  ? 'https://sportyclub.online/images/cricket/teams/' +
                                    match.awayTeam.id +
                                    '/' +
                                    match.awayTeam.id +
                                    '.png'
                                  : bannerImagePath('default-team.png')
                              "
                              @error="
                                $event.target.src =
                                  bannerImagePath('default-team.png')
                              "
                            />
                          </span>
                          <span data-v-2115270e="" class="teamName"
                            >{{
                              this.$i18n.locale == "cn" &&
                              match &&
                              match.awayTeam &&
                              match.awayTeam.cn_name
                                ? match.awayTeam.cn_name
                                : match.awayTeam.name
                                ? match.awayTeam.name
                                : ""
                            }}
                          </span>
                        </span>
                      </p>

                      <p data-v-2115270e="" class="scoreBox">
                        <span
                          v-if="match.homeScore && match.homeScore.innings"
                          data-v-2115270e=""
                          class="flexCenter"
                          :class="{
                            winText: match.winnerCode === 1,
                            loserText: match.winnerCode !== 1,
                          }"
                        >
                          <template
                            v-for="homeInning in match.homeScore.innings"
                            :key="homeInning"
                            >{{
                              homeInning.score +
                              "/" +
                              homeInning.wickets +
                              "(" +
                              homeInning.overs +
                              ")"
                            }}
                          </template>
                        </span>
                        <span
                          v-if="match.awayScore && match.awayScore.innings"
                          data-v-2115270e=""
                          class="flexCenter"
                          :class="{
                            winText: match.winnerCode === 2,
                            loserText: match.winnerCode !== 2,
                          }"
                        >
                          <template
                            v-for="awayInning in match.awayScore.innings"
                            :key="awayInning"
                            >{{
                              awayInning.score +
                              "/" +
                              awayInning.wickets +
                              "(" +
                              awayInning.overs +
                              ")"
                            }}
                          </template>
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </template>
              <template v-else-if="this.activeTab === 'away'">
                <ul data-v-2115270e="" class="matchBox">
                  <li
                    v-for="match in awayPaginatedMatches"
                    :key="match"
                    data-v-2115270e=""
                  >
                    <p data-v-2115270e="" class="collect flexCenter">
                      <span
                        v-if="
                          (match.winnerCode == 1 &&
                            match.homeTeam.id == homeTeamId) ||
                          (match.winnerCode == 2 &&
                            match.awayTeam.id == homeTeamId)
                        "
                        data-v-2115270e=""
                        class="round flexCenter win"
                      >
                        W
                      </span>
                      <span
                        v-else-if="
                          (match.winnerCode == 1 &&
                            match.homeTeam.id != homeTeamId) ||
                          (match.winnerCode == 2 &&
                            match.awayTeam.id != homeTeamId)
                        "
                        data-v-2115270e=""
                        class="round flexCenter loser"
                      >
                        L
                      </span>
                      <span
                        v-else-if="
                          match.winnerCode &&
                          match.winnerCode != 1 &&
                          match.winnerCode != 2
                        "
                        data-v-2115270e=""
                        class="round flexCenter"
                        style="background: #ffba5a"
                      >
                        D
                      </span>
                      <!-- <span v-else data-v-2115270e="" style="color: red">
                          Live
                        </span> -->
                    </p>
                    <a
                      data-v-2115270e=""
                      :href="
                        '/' +
                        this.$i18n.locale +
                        '/cricket/match/details/' +
                        match.homeTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '-vs-' +
                        match.awayTeam.name.toLowerCase().replace(/\s/g, '-') +
                        '/' +
                        match.id
                      "
                      target="_self"
                      ><p
                        data-v-2115270e=""
                        itemprop="startDate"
                        class="time flexCenter"
                      >
                        {{ getH2hDateTimeFormat(match.startTimestamp) }}
                      </p>
                      <p data-v-2115270e="" class="teamBox">
                        <span data-v-2115270e="" class="teamBoxItem"
                          ><span data-v-2115270e="">
                            <img
                              data-v-2115270e=""
                              :src="
                                match.homeTeam && match.homeTeam.id
                                  ? 'https://sportyclub.online/images/cricket/teams/' +
                                    match.homeTeam.id +
                                    '/' +
                                    match.homeTeam.id +
                                    '.png'
                                  : bannerImagePath('default-team.png')
                              "
                              @error="
                                $event.target.src =
                                  bannerImagePath('default-team.png')
                              "
                            />
                          </span>
                          <span data-v-2115270e="" class="teamName"
                            >{{
                              this.$i18n.locale == "cn" &&
                              match &&
                              match.homeTeam &&
                              match.homeTeam.cn_name
                                ? match.homeTeam.cn_name
                                : match.homeTeam.name
                                ? match.homeTeam.name
                                : ""
                            }}
                          </span>
                        </span>
                        <span data-v-2115270e="" class="teamBoxItem">
                          <span data-v-2115270e="">
                            <img
                              data-v-2115270e=""
                              :src="
                                match.awayTeam && match.awayTeam.id
                                  ? 'https://sportyclub.online/images/cricket/teams/' +
                                    match.awayTeam.id +
                                    '/' +
                                    match.awayTeam.id +
                                    '.png'
                                  : bannerImagePath('default-team.png')
                              "
                              @error="
                                $event.target.src =
                                  bannerImagePath('default-team.png')
                              "
                            />
                          </span>
                          <span data-v-2115270e="" class="teamName"
                            >{{
                              this.$i18n.locale == "cn" &&
                              match &&
                              match.awayTeam &&
                              match.awayTeam.cn_name
                                ? match.awayTeam.cn_name
                                : match.awayTeam.name
                                ? match.awayTeam.name
                                : ""
                            }}
                          </span>
                        </span>
                      </p>

                      <p data-v-2115270e="" class="scoreBox">
                        <span
                          v-if="match.homeScore && match.homeScore.innings"
                          data-v-2115270e=""
                          class="flexCenter"
                          :class="{
                            winText: match.winnerCode === 1,
                            loserText: match.winnerCode !== 1,
                          }"
                        >
                          <template
                            v-for="homeInning in match.homeScore.innings"
                            :key="homeInning"
                            >{{
                              homeInning.score +
                              "/" +
                              homeInning.wickets +
                              "(" +
                              homeInning.overs +
                              ")"
                            }}
                          </template>
                        </span>
                        <span
                          v-if="match.awayScore && match.awayScore.innings"
                          data-v-2115270e=""
                          class="flexCenter"
                          :class="{
                            winText: match.winnerCode === 2,
                            loserText: match.winnerCode !== 2,
                          }"
                        >
                          <template
                            v-for="awayInning in match.awayScore.innings"
                            :key="awayInning"
                            >{{
                              awayInning.score +
                              "/" +
                              awayInning.wickets +
                              "(" +
                              awayInning.overs +
                              ")"
                            }}
                          </template>
                        </span>
                      </p></a
                    >
                  </li>
                </ul>
              </template>
            </div>
            <div
              v-else
              data-v-8808ea54=""
              data-v-06d4278f=""
              class="basketball_standing"
            >
              <div data-v-8808ea54="" style="width: 100%">
                <div data-v-8808ea54="" class="w100">
                  <div data-v-8808ea54="" class="contentBox">
                    <div
                      class="flex justify-center align-center flex-col"
                      style="height: 400px"
                      data-v-4291ef99=""
                    >
                      <img
                        src="/images/themes/red/no-data.png"
                        style="width: 123px; height: 74px"
                      />
                      <div class="color-999 fs-12 mt-12">
                        {{ $t("football.live-score.temporarily-no-data") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateUtils from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath, dateUtils],
  props: {
    matchInfo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchList: null,
      loading: true,
      activeTab: "h2h",
      matchH2h: null,
      homeTeamId: null,
      awayTeamId: null,
      customId: null,
      homeCurrentPage: 1,
      homeMatchesPerPage: 5,
      awayCurrentPage: 1,
      awayMatchesPerPage: 5,
    };
  },
  computed: {
    awayTotalPages() {
      return Math.ceil(this.awayTeamMatches.length / this.awayMatchesPerPage);
    },
    awayPaginatedMatches() {
      const startIndex = (this.awayCurrentPage - 1) * this.awayMatchesPerPage;
      const endIndex = startIndex + this.awayMatchesPerPage;
      return this.awayTeamMatches.slice(startIndex, endIndex);
    },

    homeTotalPages() {
      return Math.ceil(this.homeTeamMatches.length / this.homeMatchesPerPage);
    },
    homePaginatedMatches() {
      const startIndex = (this.homeCurrentPage - 1) * this.homeMatchesPerPage;
      const endIndex = startIndex + this.homeMatchesPerPage;
      return this.homeTeamMatches.slice(startIndex, endIndex);
    },
  },
  created() {
    this.matchInfo && this.matchInfo
      ? (this.customId = this.matchInfo.customId)
      : "";
    this.matchInfo && this.matchInfo.homeTeam
      ? (this.homeTeamId = this.matchInfo.homeTeam.id)
      : "";
    this.matchInfo && this.matchInfo.awayTeam
      ? (this.awayTeamId = this.matchInfo.awayTeam.id)
      : "";

    this.getMatchH2H(this.customId);

    this.matchInfo && this.matchInfo.homeTeam
      ? this.getHomeTeamDetails(this.matchInfo.homeTeam.id)
      : "";
    this.matchInfo && this.matchInfo.awayTeam
      ? this.getAwayTeamDetails(this.matchInfo.awayTeam.id)
      : "";
  },
  methods: {
    goToPage(page) {
      if (this.activeTab === "home") this.homeGoToPage(page);
      else if (this.activeTab === "away") this.awayGoToPage(page);
    },
    awayPrevPage() {
      if (this.awayCurrentPage > 1) {
        this.awayCurrentPage--;
      }
    },
    awayNextPage() {
      if (this.awayCurrentPage < this.awayTotalPages) {
        this.awayCurrentPage++;
      }
    },
    awayGoToPage(page) {
      this.awayCurrentPage = page;
    },
    homePrevPage() {
      if (this.homeCurrentPage > 1) {
        this.homeCurrentPage--;
      }
    },
    homeNextPage() {
      if (this.homeCurrentPage < this.homeTotalPages) {
        this.homeCurrentPage++;
      }
    },
    homeGoToPage(page) {
      this.homeCurrentPage = page;
    },
    // goToPage(page) {
    //   this.currentPage = page;
    // },
    getHomeTeamDetails(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/teams/h2h/previous-matches/${teamId}`
        )
        .then((response) => {
          console.log("homeTeamDetails", response);

          this.homeTeamMatches = response.data.events.reverse();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get away team matches
    getAwayTeamDetails(teamId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/teams/h2h/previous-matches/${teamId}`
        )
        .then((response) => {
          this.awayTeamMatches = response.data.events.reverse();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get league h2h
    getMatchH2H(customId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI + `/api/cricket/teams/h2h/match/${customId}`
        )
        .then((response) => {
          console.log("matchH2h", response);

          this.matchH2h = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTabs(state) {
      this.activeTab = state;
    },
  },
};
</script>

<style scoped >
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.bd-example ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  font-size: 0.3rem;

  position: relative;
  display: block;
  padding: 0.1rem 0.25rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000000;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.bd-example a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.page-item.disabled .page-link {
  color: #b5b6b8;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
