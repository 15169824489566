<template>
  <!-- <span class="mobile-version">
    <MobileLiveScoring />
  </span> -->
  <span class="desktop-version">
    <DesktopUserProfile />
  </span>
</template>
<!-- begin::scripts -->
<script>
import DesktopUserProfile from "./desktop-version/user-profile.vue";
export default {
  components: {
    DesktopUserProfile,
  },
};
</script>
