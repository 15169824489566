<template>
  <div class="w100">
    <div data-v-40b57f9e="" class="cricket_overview">
      <div
        data-v-826fe974=""
        data-v-40b57f9e=""
        class="odds-box"
        type="overview"
      >
        <div data-v-826fe974="" class="list">
          <!-- <div data-v-0e07873a="" data-v-826fe974="" class="oddsBox">
            <div data-v-0e07873a="" class="oddsContent">
              <p data-v-0e07873a="" class="oddsType flex">
                <span data-v-0e07873a="" class="placeholder"></span>

                <span
                  data-v-0e07873a=""
                  class="oddsItem flex-1 justify-center align-center"
                >
                  {{ $t("football.league-details.to-win") }}
                </span>
              </p>
              <div data-v-0e07873a="" class="oddsBox flex">
                <div
                  data-v-0e07873a=""
                  class="oddsBoxLeft flex justify-center align-center"
                >
                  <img
                    data-v-0e07873a=""
                    src="https://img1.aiscore.com/other/fe8aec51afeb2de633c9.png"
                    alt="#"
                    class="companyLogo"
                  />
                </div>
                <div data-v-0e07873a="" class="oddsBoxContent flex-1">
                  <div data-v-0e07873a="" class="oddsItem flex flex-1 border2">
                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center borderR"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][1]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][1].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >

                    <span
                      data-v-0e07873a=""
                      class="flex flex-1 justify-center align-center"
                    >
                      <span
                        data-v-0e07873a=""
                        class="flex justify-center align-center"
                      >
                        {{
                          todayMatchDetails.matchOdds &&
                          todayMatchDetails.matchOdds.bet365 &&
                          todayMatchDetails.matchOdds.bet365.euro &&
                          todayMatchDetails.matchOdds.bet365.euro[0] &&
                          todayMatchDetails.matchOdds.bet365.euro[0][3]
                            ? todayMatchDetails.matchOdds.bet365.euro[0][3].toFixed(
                                2
                              )
                            : "-"
                        }}
                      </span></span
                    >
                  </div>
                </div>
              </div>
              <p data-v-0e07873a="" class="hint">
                {{ $t("football.league-details.gamble-responsibly") }}
              </p>
              <p data-v-0e07873a="" class="oddsTypeText mt-12">
                <span data-v-0e07873a="" class="openingBox"
                  ><span data-v-0e07873a="" class="chunk opening"></span>
                  <span data-v-0e07873a="">{{
                    $t("football.league-details.opening-odds")
                  }}</span></span
                >
                <span data-v-0e07873a="" class="openingBox mt-8"
                  ><span data-v-0e07873a="" class="chunk pre-match"></span>
                  <span data-v-0e07873a="">{{
                    $t("football.league-details.pre-match-odds")
                  }}</span></span
                >
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <div data-v-40b57f9e="" class="info"></div>
    </div>
    <div
      class="loading van-loading van-loading--spinner van-loading--vertical"
      style="display: none"
    >
      <span class="van-loading__spinner van-loading__spinner--spinner"
        ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
        ><i></i><i></i
      ></span>
    </div>
    <div class="prefetch"></div>
  </div>
  <div class="content" data-v-c36567d6="">
    <div class="team_overview" data-v-fdbe674e="" data-v-c36567d6="">
      <div class="dec-box" data-v-fdbe674e="">
        <div class="text" data-v-fdbe674e="" style="max-height: fit-content">
          <div style="margin-bottom: 0.3rem">
            <b> {{ $t("football.league-details.about-this-match") }}</b>
          </div>

          <p>
            <b>
              {{ todayMatchDetails.homeTeam.name }} vs
              {{ todayMatchDetails.awayTeam.name }}</b
            >
            match is part of
            {{ todayMatchDetails.tournament.uniqueTournament.name }}. The match
            starts on {{ timestampToDate(todayMatchDetails.startTimestamp) }},
            {{ timestampToTime(todayMatchDetails.startTimestamp) }}
            . Here on
            <b>
              {{ todayMatchDetails.homeTeam.name }} vs
              {{ todayMatchDetails.awayTeam.name }}</b
            >
            live score you can find all
            <b>
              {{ todayMatchDetails.homeTeam.name }} vs
              {{ todayMatchDetails.awayTeam.name }}</b
            >
            previous results sorted by their H2H matches.
          </p>

          <br />
          <b style="color: brown">Facts:</b>
          <br />
          <ul>
            <li>
              <b>Match Status</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.status &&
                todayMatchDetails.status.description &&
                todayMatchDetails.status.description
                  ? todayMatchDetails.status.description
                  : "-"
              }}
            </li>
            <li>
              <b>Toss Won</b>:
              {{
                todayMatchDetails && todayMatchDetails.tossWin
                  ? todayMatchDetails.tossWin
                  : "-"
              }}
            </li>
            <li>
              <b>Toss Decision</b>:
              {{
                todayMatchDetails && todayMatchDetails.tossDecision
                  ? todayMatchDetails.tossDecision
                  : "-"
              }}
            </li>

            <li>
              <b>League</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.tournament &&
                todayMatchDetails.tournament.uniqueTournament &&
                todayMatchDetails.tournament.uniqueTournament.name
                  ? todayMatchDetails.tournament.uniqueTournament.name
                  : "-"
              }}
            </li>

            <li>
              <b>Season</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.season &&
                todayMatchDetails.season.name
                  ? todayMatchDetails.season.name
                  : "-"
              }}
            </li>

            <li>
              <b>Venue</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.venue &&
                todayMatchDetails.venue.city &&
                todayMatchDetails.venue.city.name
                  ? todayMatchDetails.venue.city.name
                  : "-"
              }}
            </li>
            <li>
              <b>Stadium</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.venue &&
                todayMatchDetails.venue.stadium &&
                todayMatchDetails.venue.stadium.name
                  ? todayMatchDetails.venue.stadium.name
                  : "-"
              }}
            </li>
            <li>
              <b>Country</b>:
              {{
                todayMatchDetails &&
                todayMatchDetails.venue &&
                todayMatchDetails.venue.country &&
                todayMatchDetails.venue.country.name
                  ? todayMatchDetails.venue.country.name
                  : "-"
              }}
            </li>
          </ul>

          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    todayMatchDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
      matchDetails: null,
    };
  },
  created() {
    this.matchDetails = this.todayMatchDetails;
  },
  methods: {
    // method to convert timeStamp
    timestampToDate(timestamp) {
      // Check if timestamp is defined and numeric
      if (typeof timestamp !== "undefined" && !isNaN(timestamp)) {
        // Create a new Date object from the timestamp (in milliseconds)
        var date = new Date(timestamp * 1000);

        // Array of month names
        var months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Extract day, month, and year
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        // Return formatted date
        return day + " " + months[monthIndex] + " " + year;
      } else {
        // If timestamp is undefined or not numeric, return a dash
        return "-";
      }
    },

    // method to convert unix timestamp
    timestampToTime(timestamp) {
      const gmtOffset = 4 * 60 * 60 * 1000; // GMT+4 offset in milliseconds
      const adjustedTimestamp = timestamp * 1000 + gmtOffset; // Convert seconds to milliseconds and add GMT+4 offset
      const date = new Date(adjustedTimestamp);
      let hours = date.getUTCHours(); // Get UTC hours
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert hours to 12-hour format
      const formattedHours = hours < 10 ? "0" + hours : hours; // Add leading zero if hours < 10
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero if minutes < 10
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    },
  },
};
</script>
