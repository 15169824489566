<template>
  <div
    v-if="loading"
    class="cricket view border-box"
    data-v-250f1619=""
    style="min-height: 0px !important"
  >
    <div>
      <div class="detail view border-box" data-v-250f1619="">
        <div
          class="loading van-loading van-loading--spinner van-loading--vertical"
        >
          <span class="van-loading__spinner van-loading__spinner--spinner"
            ><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
            ><i></i><i></i><i></i
          ></span>
        </div>
      </div>
    </div>
  </div>
  <template v-if="!loading && newsDetails">
    <div
      translate="translate"
      class="bd-example vue-example vue-example-b-card"
    >
      <div style="margin-bottom: 1rem">
        <article class="card mb-2" style="max-width: 20rem">
          <img
            :src="'https://sportyclub.online/' + newsDetails.image"
            alt="Image"
            class="card-img-top"
            loading="lazy"
          />
          <div class="card-body">
            <h4 class="card-title" style="font-weight: 800">
              {{ newsDetails.title }}
            </h4>
            <p
              class="card-text"
              style="font-size: 0.4rem"
              v-html="newsDetails.details"
            ></p>
          </div>
        </article>
      </div>
    </div>
  </template>

  <!--begin::footer -->
  <MobileFooter />
  <!-- end::footter -->
</template>
<!-- begin::scripts -->
<script>
import DateMixin from "@/utils/dateUtils";

export default {
  components: {},
  mixins: [DateMixin],
  data() {
    return {
      loading: true,
      newsDetails: null,
    };
  },
  created() {
    const itemId = this.$route.params.newsId;
    this.getNewsDetails(itemId);
  },

  mounted() {},
  methods: {
    getNewsDetails(newsId) {
      this.$axios
        .get(`https://sportyclub.online/api/football/news/details/${newsId}`)
        .then((response) => {
          this.newsDetails = response.data.footballNewsDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.card {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}
.card-title {
  color: black;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
.bd-example {
  position: relative;
  padding: 0.5rem;
}

.h4,
h4 {
  font-size: 0.5rem;
  margin-bottom: 0.3rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
}

.bd-example .pagination {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.bd-example ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  unicode-bidi: isolate;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  font-size: 0.5rem;

  position: relative;
  display: block;
  padding: 0.1rem 0.25rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.bd-example a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
