<template>
  <div class="w100">
    <div data-v-40b57f9e="" class="volleyball_overview">
      <div class="left">
        <div
          class="topScore"
          v-if="todayMatchDetails.status_id != match_status.NOT_STARTED"
        >
          <div
            style="
              width: 100%;
              padding: 0.1rem 0.32rem 0.05rem 0.32rem;
              box-sizing: border-box;
            "
          >
            <div class="w100 js flex">
              <div class="border-bottom" style="width: 4rem"></div>
              <div
                v-for="i in ['Q1', 'Q2', 'Q3', 'Q4']"
                :key="i"
                class="border-bottom flex-1"
              >
                {{ i }}
              </div>

              <div class="border-bottom flex-1">
                {{ $t("football.league-details.total") }}
              </div>
            </div>
            <div class="w100 detailScore flex">
              <div
                class="col"
                style="
                  width: 4rem;
                  padding-left: 0.32rem;
                  border-right: 1px solid #eeeeee;
                "
              >
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.homeInfo &&
                        todayMatchDetails.homeInfo.logo
                          ? todayMatchDetails.homeInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.homeInfo &&
                      todayMatchDetails.homeInfo.cn_name
                        ? todayMatchDetails.homeInfo.cn_name
                        : todayMatchDetails.homeInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_1"></use>
                  </svg>
                </div>
                <div class="nameBox">
                  <div
                    itemprop="logo"
                    class="imgw43 van-image van-image--round"
                    style="overflow: hidden; border-radius: 50%"
                  >
                    <img
                      :src="
                        todayMatchDetails &&
                        todayMatchDetails.awayInfo &&
                        todayMatchDetails.awayInfo.logo
                          ? todayMatchDetails.awayInfo.logo + '!w60'
                        : bannerImagePath('default-team.png')
                      "
                      alt="#"
                      class="van-image__img"
                      style="object-fit: contain"
                    />
                  </div>
                  <div
                    class="van-ellipsis"
                    style="max-width: 2.5rem; margin: 0 0.1rem"
                  >
                    {{
                      this.$i18n.locale == "cn" &&
                      todayMatchDetails &&
                      todayMatchDetails.awayInfo &&
                      todayMatchDetails.awayInfo.cn_name
                        ? todayMatchDetails.awayInfo.cn_name
                        : todayMatchDetails.awayInfo.en_name
                    }}
                  </div>
                  <svg aria-hidden="true" class="icon fs-12 wangqiu svgPostop">
                    <use xlink:href="#iconbaseball_ball_2"></use>
                  </svg>
                </div>
              </div>

              <div
                v-for="index in 4"
                :key="index"
                class="col flex-1"
                :class="{
                  active:
                    getSetNumberFromMatchStatusId(
                      todayMatchDetails.status_id
                    ) == index,
                }"
                style="border-right: 1px solid #eeeeee"
              >
                <template
                  v-for="(teamName, teamIndex) in ['home', 'away']"
                  :key="teamIndex"
                >
                  <div
                    class="scoreText"
                    :class="{
                      colorMax:
                        todayMatchDetails[teamName + '_scores'] &&
                        todayMatchDetails[teamName + '_scores'][index - 1] &&
                        getSetNumberFromMatchStatusId(
                          todayMatchDetails.status_id
                        ) != index &&
                        teamName == 'home'
                          ? todayMatchDetails['home' + '_scores'][index - 1] >
                            todayMatchDetails['away' + '_scores'][index - 1]
                          : todayMatchDetails[teamName + '_scores'] &&
                            todayMatchDetails[teamName + '_scores'][
                              index - 1
                            ] &&
                            getSetNumberFromMatchStatusId(
                              todayMatchDetails.status_id
                            ) != index &&
                            teamName == 'away'
                          ? todayMatchDetails['away' + '_scores'][index - 1] >
                            todayMatchDetails['home' + '_scores'][index - 1]
                          : false,
                    }"
                  >
                    {{
                      todayMatchDetails[teamName + "_scores"] &&
                      todayMatchDetails[teamName + "_scores"][index - 1]
                        ? todayMatchDetails[teamName + "_scores"][index - 1]
                        : "-"
                    }}
                  </div>
                </template>
              </div>

              <div
                class="col flex-1 active"
                style="border-right: 1px solid #eeeeee"
              >
                <div class="scoreText">
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.home_scores &&
                    todayMatchDetails.home_scores instanceof Array
                      ? todayMatchDetails.home_scores.reduce(
                          (acc, cur) => (acc += cur),
                          0
                        )
                      : "0"
                  }}
                </div>
                <div class="scoreText">
                  {{
                    todayMatchDetails &&
                    todayMatchDetails.away_scores &&
                    todayMatchDetails.away_scores instanceof Array
                      ? todayMatchDetails.away_scores.reduce(
                          (acc, cur) => (acc += cur),
                          0
                        )
                      : "0"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" data-v-c36567d6="">
      <div class="team_overview" data-v-fdbe674e="" data-v-c36567d6="">
        <!---->

        <div class="dec-box" data-v-fdbe674e="">
          <div class="text" data-v-fdbe674e="">
            <b> {{ $t("football.league-details.about-this-match") }} </b>
            <br />
            <p v-if="this.$i18n.locale == 'cn'">
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : "-"
                }}
              </a>
              对阵
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.cn_name
                    : "-"
                }}
              </a>

              （以及在线视频直播）于 UTC 时间 19:00 在 Cup 开始。在
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : "-"
                }}
              </a>
              对阵
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.cn_name
                    : "-"
                }}
              </a>
              比分直播 你能查询到
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : "-"
                }}
              </a>
              对阵
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.cn_name
                    : "-"
                }}
              </a>
              以往所有的交锋记录。您可以在不同的商店找到我们
              语言为“AiScore”。安装 AiScore 应用程序 和 在您的手机上关注
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.cn_name
                    : "-"
                }}
              </a>
              对阵
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.cn_name
                    : "-"
                }}
              </a>
              的比赛直播！
            </p>
            <p v-else>
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.en_name
                    : "-"
                }}
              </a>
              vs
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.en_name
                    : "-"
                }}
              </a>
              (and video online live stream) starts at
              {{
                new Date(todayMatchDetails.match_timing)
                  .toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .slice(0, 5)
              }}
              UTC time in Cup. Here on
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.name
                    : "-"
                }}
              </a>
              vs
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.name
                    : "-"
                }}
              </a>

              LiveScore you can find all
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.name
                    : "-"
                }}
              </a>
              vs
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.name
                    : "-"
                }}
              </a>
              previous results sorted by their H2H matches. <br /><br /><span
                style="font-size: 13px; font-weight: 700"
              ></span
              >You can find us in all stores on different languages as
              "AiScore". Install AiScore app on and follow
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.homeInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.home_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.homeInfo
                    ? todayMatchDetails.homeInfo.name
                    : "-"
                }}
              </a>
              vs
              <a
                style="color: var(--main-text-color)"
                :href="
                  '/' +
                  this.$i18n.locale +
                  '/basketball/team/details/' +
                  todayMatchDetails.awayInfo.en_name
                    .toLowerCase()
                    .replace(/\s/g, '-') +
                  '/' +
                  todayMatchDetails.away_team_id
                "
              >
                {{
                  todayMatchDetails && todayMatchDetails.awayInfo
                    ? todayMatchDetails.awayInfo.name
                    : "-"
                }}
              </a>

              live on your mobile!
            </p>

            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    matchId: {
      type: Object,
      default: null,
    },
    todayMatchDetails: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      matchLineups: null,
      loading: true,
      matchDetails: null,
      match_status: {
        NOT_STARTED: 1,
        FIRST_SECTION: 2,
        SECOND_SECTION: 4,
        THIRD_SECTION: 6,
        FOURTH_SECTION: 8,
        OVERTIME: 9,
      },
      sets_match_status: [2, 4, 6, 8, 9],
      section_over_status: [3, 5, 7],
    };
  },
  created() {
    this.matchDetails = this.todayMatchDetails;
  },
  methods: {
    getSetNumberFromMatchStatusId(status_id) {
      let setNumber = this.sets_match_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
    getSetNumberFromMatchSectionOverStatus(status_id) {
      let setNumber = this.section_over_status.findIndex(
        (matchStatus) => status_id == matchStatus
      );
      return setNumber != -1 ? setNumber + 1 : -1;
    },
  },
};
</script>