<template>
  <div class="flex" data-v-d9dc0c56="" v-if="!loading">
    <div
      class="border-lt active"
      data-v-d9dc0c56=""
      :class="liveActivebtn == 'all' ? 'active' : ''"
      v-on:click="getTodayLiveMatches('live')"
    >
      Live Matches
    </div>
    <div
      class="border-lt"
      data-v-d9dc0c56=""
      :class="liveActivebtn == 'upcoming' ? 'active' : ''"
      v-on:click="getTodayLiveMatches('upcoming')"
    >
      Upcoming Matches
    </div>
    <div
      class="flex-1"
      style="border-left: 1px solid #eeeeee; border-bottom: 1px solid #eee"
      data-v-d9dc0c56=""
    ></div>
  </div>

  <ul gutter="20" class="data-box" data-v-d9dc0c56="" v-if="countMatches > 0">
    <template v-for="league in todayMatchList" :key="league.id">
      <li
        style="width: 20%"
        data-v-d9dc0c56=""
        v-for="match in league"
        :key="match.id"
      >
        <div class="mr-20" data-v-d9dc0c56="">
          <a
            v-if="match && match.homeInfo && match.awayInfo"
            :href="
              '/' +
              this.$i18n.locale +
              '/football/match/details/' +
              match.id
            "
            target="_blank"
            class="link"
            data-v-d9dc0c56=""
          >
            {{
              this.$i18n.locale == "cn" && match.homeInfo.cn_name
                ? match.homeInfo.cn_name
                : match.homeInfo.en_name
                ? match.homeInfo.en_name
                : ""
            }}

            VS

            {{
              this.$i18n.locale == "cn" && match.awayInfo.cn_name
                ? match.awayInfo.cn_name
                : match.awayInfo.en_name
                ? match.awayInfo.en_name
                : ""
            }}
          </a>
        </div>
      </li>
    </template>
  </ul>
  <ul v-else>
    <small> {{ $t("football.live-score.temporarily-no-data") }}</small>
  </ul>
</template>
<script>
export default {
  props: {
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      todayMatchList: null,
      loading: false,
      liveActivebtn: "live",
      countMatches: null,
    };
  },
  created() {
    this.getTodayLiveMatches("live");
  },
  methods: {
    // method to toggle dropdown

    getTodayLiveMatches(status) {
      this.loading = true;
      this.liveActivebtn = status;
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/match/list/today?matchStatus=${status}`
        )
        .then((response) => {
          this.todayMatchList = response.data.footballMatchList;
          this.countMatches = response.data.liveMatches;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
