<template>
  <span
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
  >
    <div data-v-d4c6fef0="" class="match-box">
      <div data-v-d4c6fef0="" class="status-tip-page">
        <i data-v-d4c6fef0="" class="iconfont icon-shaozi"></i>

        {{ $t("football.live-score.search-results") }}
      </div>
    </div>
  </span>

  <div
    v-if="
      Object.entries(searchMatchListUpcoming).length === 0 &&
      Object.entries(searchMatchListLive).length === 0
    "
    class="vue-recycle-scroller__item-view"
    style="transform: translateY(0px); min-height: 200px"
  >
    <div class="flex justify-center align-center flex-col contentBox">
      <img :src="bannerImagePath('default-container.png')" class="nodata" />

      <div class="color-999 noDataText fs-12">
        {{ $t("football.live-score.nothing-found") }}
      </div>
    </div>
  </div>

  <!-- live search result -->
  <template v-else>
    <!-- <span v-if="Object.entries(searchMatchListLive).length !== 0">
      <div data-v-d4c6fef0="" class="match-box">
        <div data-v-d4c6fef0="" class="status-tip-page">
          <i data-v-d4c6fef0="" class="iconfont icon-shaozi"></i>

          {{ $t("football.live-score.live-matches") }}
        </div>
      </div>
    </span> -->

    <div
      class="vue-recycle-scroller__item-view"
      style="transform: translateY(0px)"
      v-for="(league, key) in searchMatchListLive"
      :key="key"
    >
      <div class="comp-container">
        <!-- begin::league title -->
        <div
          data-v-f1b1cb6e=""
          data-id="2j374oirla4qo6d"
          class="title"
          :class="
            leagueFavoriteStatus[league[0].competition_id] ? 'collectName' : ''
          "
        >
          <!-- add  collectName to title-->

          <i
            class="iconfont"
            :class="
              leagueFavoriteStatus[league[0].competition_id]
                ? 'icon-yishoucang'
                : 'icon-weishoucang'
            "
            @click="toggleFavorite(league[0].competition_id)"
            style="visibility: visible"
          ></i>

          <i
            data-v-f1b1cb6e=""
            class="country-logo squareLogo"
            style="height: -12px !important"
          >
            <img
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                ((league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.logo) ||
                  (league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.name) ||
                  (league[0].leagueInfo.categoryDetails &&
                    league[0].leagueInfo.categoryDetails.name))
              "
              :src="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.logo
                  ? league[0].leagueInfo.countryDetails.logo
                 : bannerImagePath('default-country.png')
              "
              alt=""
              style="width: 100%"
            />
          </i>
          <!-- display country, category -->
          <span
            v-if="this.$i18n.locale == 'cn'"
            data-v-f1b1cb6e=""
            class="country-name minitext"
          >
            <span
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.cn_name
              "
            >
              {{ league[0].leagueInfo.countryDetails.cn_name }}: : &nbsp;
            </span>
            <span
              v-else-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.categoryDetails &&
                league[0].leagueInfo.categoryDetails.cn_name
              "
            >
              {{ league[0].leagueInfo.categoryDetails.cn_name }}: : &nbsp;
            </span>
          </span>

          <span v-else data-v-f1b1cb6e="" class="country-name minitext">
            <span
              v-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.countryDetails &&
                league[0].leagueInfo.countryDetails.name
              "
            >
              {{ league[0].leagueInfo.countryDetails.name }}: :
            </span>
            <span
              v-else-if="
                league[0] &&
                league[0].leagueInfo &&
                league[0].leagueInfo.categoryDetails &&
                league[0].leagueInfo.categoryDetails.name
              "
            >
              {{ league[0].leagueInfo.categoryDetails.name }}:
            </span>
          </span>

          <span
            v-if="
              league &&
              league[0] &&
              league[0].leagueInfo &&
              league[0].leagueInfo
            "
          >
            <a
              v-if="league[0].hasLeagueDetails"
              data-v-2eea2747=""
              :href="
                '/' +
                this.$i18n.locale +
                '/football/league/details/' +
                league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                '/' +
                league[0].competition_id
              "
              target="_blank"
              class="compText"
              style="color: black"
            >
              <b>{{
                this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                  ? league[0].leagueInfo.cn_name
                  : league[0].leagueInfo.en_name
                  ? league[0].leagueInfo.en_name
                  : ""
              }}</b>
            </a>
            <a
              v-else
              target="_blank"
              class="compText"
              style="cursor: not-allowed"
            >
              {{
                this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                  ? league[0].leagueInfo.cn_name
                  : league[0].leagueInfo.en_name
                  ? league[0].leagueInfo.en_name
                  : ""
              }}
            </a>
          </span>

          <div data-v-f1b1cb6e="" class="flex-1">
            <span data-v-f1b1cb6e="" class="watchNum" style="margin-right: 1px">
            </span>
          </div>
          <ul data-v-f1b1cb6e="" class="odds">
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">1</span>
            </li>
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">X</span>
            </li>
            <li data-v-f1b1cb6e="">
              <span data-v-f1b1cb6e="">2</span>
            </li>
          </ul>
        </div>

        <!-- end::league title -->
        <!-- begin::teams -->
        <div class="comp-list" v-for="match in league" :key="match.id">
          <a
            data-v-0d00ff82=""
            :href="
              '/' +
              this.$i18n.locale +
              '/football/match/details/' +
              match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '-vs-' +
              match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
              '/' +
              match.id
            "
            target="_blank"
            class="match-container"
            ><span data-v-0d00ff82="" class="left-box"
              ><span data-v-0d00ff82="" class="v-st-box">
                <span data-v-0d00ff82="" class="time minitext">
                  {{ readableTime(this.$i18n.locale, match.match_time) }}
                </span>
                <span
                  data-v-0d00ff82=""
                  class="status minitext on twinkle"
                  v-if="liveMatches.includes(match.status_id)"
                >
                  {{ onGoingMatchTime(match.counter_timing, match.status_id) }}
                </span></span
              >

              <span data-v-0d00ff82="" class="v-tt-box">
                <!-- begin::home info -->
                <span data-v-0d00ff82="" class="team home"
                  ><span data-v-0d00ff82="" class="flex-1 teamBox teamHomeBox">
                    <!-- yellow cards -->
                    <span
                      data-v-0d00ff82=""
                      class="card yellow"
                      v-if="
                        match && match.homeInfo && match.homeInfo.yellow_cards
                      "
                      ><span data-v-0d00ff82="">
                        {{
                          match && match.homeInfo && match.homeInfo.yellow_cards
                            ? match.homeInfo.yellow_cards
                            : ""
                        }}
                      </span>
                    </span>
                    <!-- red cards -->
                    <span
                      data-v-0d00ff82=""
                      class="card yellow"
                      v-if="match && match.homeInfo && match.homeInfo.red_cards"
                      ><span data-v-0d00ff82="">
                        {{
                          match && match.homeInfo && match.homeInfo.red_cards
                            ? match.homeInfo.red_cards
                            : ""
                        }}
                      </span>
                    </span>
                    <!-- begin::teams info -->
                    <span data-v-0d00ff82="" class="nameBox"
                      ><a
                        data-v-0d00ff82=""
                        itemprop="homeTeam"
                        class="name minitext maxWidth160"
                      >
                        {{
                          match &&
                          match.homeInfo &&
                          match.homeInfo.cn_name &&
                          this.$i18n.locale == "cn"
                            ? match.homeInfo.cn_name
                            : match.homeInfo.en_name
                            ? match.homeInfo.en_name
                            : ""
                        }}
                      </a>
                    </span>
                    <span
                      data-v-0d00ff82=""
                      class="teamLogoBox teamLogoHomeBox"
                    >
                      <img
                        v-if="match.homeInfo"
                        :src="
                          match.homeInfo.logo
                            ? match.homeInfo.logo
                            : '/images/default-football.jpg'
                        "
                      />
                    </span> </span
                ></span>
                <!-- end::home info -->
                <!-- begin::away info -->
                <!-- begin::score section -->
                <span
                  data-v-0d00ff82=""
                  class="scores on"
                  v-if="
                    liveMatches.includes(match.status_id) ||
                    FinishedMatches.includes(match.status_id)
                  "
                >
                  <span data-v-0d00ff82="" class="score-home minitext">
                    {{
                      match && match.homeInfo ? match.homeInfo.home_score : "0"
                    }}
                  </span>
                  <span
                    data-v-0d00ff82=""
                    class="or"
                    style="background-color: transparent !important"
                    >-</span
                  >
                  <span data-v-0d00ff82="" class="score-away minitext">
                    {{
                      match && match.awayInfo ? match.awayInfo.away_score : "0"
                    }}
                  </span>
                </span>

                <span
                  data-v-0d00ff82=""
                  class="scores on"
                  v-if="match.status_id == 1"
                >
                  <span
                    data-v-0d00ff82=""
                    class="or"
                    style="margin-left: 25px !important"
                    >-</span
                  >
                </span>

                <!-- end::score section -->
                <span data-v-0d00ff82="" class="team away"
                  ><span data-v-0d00ff82="" class="flex-1 teamBox teamAwayBox"
                    ><span
                      data-v-0d00ff82=""
                      class="teamLogoBox teamLogoAwayBox"
                    >
                      <img
                        v-if="match.awayInfo"
                        :src="
                          match.awayInfo.logo
                            ? match.awayInfo.logo
                            : '/images/default-football.jpg'
                        "
                      />
                    </span>
                    <span data-v-0d00ff82="" class="nameBox">
                      <span
                        data-v-0d00ff82=""
                        itemprop="awayTeam"
                        class="name minitext maxWidth160"
                      >
                        {{
                          match &&
                          match.awayInfo &&
                          match.awayInfo.cn_name &&
                          this.$i18n.locale == "cn"
                            ? match.awayInfo.cn_name
                            : match.awayInfo.en_name
                            ? match.awayInfo.en_name
                            : ""
                        }}
                      </span>
                    </span>
                    <!-- yellow cards -->
                    <span
                      data-v-0d00ff82=""
                      class="card yellow"
                      v-if="
                        match && match.awayInfo && match.awayInfo.yellow_cards
                      "
                      ><span data-v-0d00ff82="">
                        {{
                          match && match.awayInfo && match.awayInfo.yellow_cards
                            ? match.awayInfo.yellow_cards
                            : ""
                        }}
                      </span>
                    </span>
                    <!-- red cards -->
                    <span
                      data-v-0d00ff82=""
                      class="card yellow"
                      v-if="match && match.awayInfo && match.awayInfo.red_cards"
                      ><span data-v-0d00ff82="">
                        {{
                          match && match.awayInfo && match.awayInfo.red_cards
                            ? match.awayInfo.red_cards
                            : ""
                        }}
                      </span>
                    </span>
                  </span></span
                >
                <!-- end::away info -->
              </span>
              <!-- end::teams info -->
              <!-- begin::half & full score -->
              <span
                data-v-0d00ff82=""
                class="v-ft-box"
                v-if="
                  (match && match.homeInfo && match.homeInfo.half_time_score) ||
                  (match && match.awayInfo && match.awayInfo.half_time_score)
                "
                ><span data-v-0d00ff82="" class="half-over">
                  HT
                  {{
                    match &&
                    match.homeInfo &&
                    match.homeInfo.half_time_score !== undefined
                      ? match.homeInfo.half_time_score
                      : "0"
                  }}
                  -
                  {{
                    match &&
                    match.awayInfo &&
                    match.awayInfo.half_time_score !== undefined
                      ? match.awayInfo.half_time_score
                      : "0"
                  }}
                </span>
              </span>
              <!-- else section -->
              <span data-v-0d00ff82="" class="v-ft-box" v-else
                ><span data-v-0d00ff82="" class="half-over">
                  <span style="display: none"> HT 0 - 0 </span>
                </span>
              </span>
              <!-- end else section -->
              <!-- end::half & full score -->
            </span>
            <!-- begin::odds area -->
            <ul
              data-v-0d00ff82=""
              class="odds oddhoverGreen newOddsBox"
              v-if="
                match &&
                match.odds &&
                match.odds != '' &&
                match.odds.euro &&
                match.odds.euro != ''
              "
            >
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text">
                  {{ match.odds.euro[2] ? match.odds.euro[2] : "-" }}
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                  {{ match.odds.euro[3] ? match.odds.euro[3] : "-" }}
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text loseBottom">
                  {{ match.odds.euro[4] ? match.odds.euro[4] : "-" }}
                </span>
              </li>
            </ul>
            <ul data-v-0d00ff82="" class="odds oddhoverGreen newOddsBox" v-else>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text">
                  <b>-</b>
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                  <b>-</b>
                </span>
              </li>
              <li data-v-0d00ff82="">
                <span data-v-0d00ff82="" class="text loseBottom">
                  <b>-</b>
                </span>
              </li>
            </ul>
            <!-- end::odds area -->
          </a>
        </div>

        <!-- end::teams -->
      </div>
    </div>

    <!-- upcoming search result -->

    <!-- <span v-if="Object.entries(searchMatchListUpcoming).length !== 0">
      <div data-v-d4c6fef0="" class="match-box">
        <div data-v-d4c6fef0="" class="status-tip-page">
          <i data-v-d4c6fef0="" class="iconfont icon-shaozi"></i>

          {{ $t("football.live-score.today-upcoming-matches") }}
        </div>
      </div>
    </span> -->

    <div>
      <div
        class="vue-recycle-scroller__item-view"
        style="transform: translateY(0px)"
        v-for="(league, key) in searchMatchListUpcoming"
        :key="key"
      >
        <div class="comp-container">
          <!-- begin::league title -->
          <div
            data-v-f1b1cb6e=""
            data-id="2j374oirla4qo6d"
            class="title"
            :class="
              leagueFavoriteStatus[league[0].competition_id]
                ? 'collectName'
                : ''
            "
          >
            <i
              class="iconfont"
              :class="
                leagueFavoriteStatus[league[0].competition_id]
                  ? 'icon-yishoucang'
                  : 'icon-weishoucang'
              "
              @click="toggleFavorite(league[0].competition_id)"
              style="visibility: visible"
            ></i>

            <i
              data-v-f1b1cb6e=""
              class="country-logo squareLogo"
              style="height: -12px !important"
            >
              <img
                v-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  ((league[0].leagueInfo.countryDetails &&
                    league[0].leagueInfo.countryDetails.logo) ||
                    (league[0].leagueInfo.countryDetails &&
                      league[0].leagueInfo.countryDetails.name) ||
                    (league[0].leagueInfo.categoryDetails &&
                      league[0].leagueInfo.categoryDetails.name))
                "
                :src="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.logo
                    ? league[0].leagueInfo.countryDetails.logo
                   : bannerImagePath('default-country.png')
                "
                style="width: 100%"
              />
            </i>

            <span
              v-if="this.$i18n.locale == 'cn'"
              data-v-f1b1cb6e=""
              class="country-name minitext"
            >
              <span
                v-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.cn_name
                "
              >
                {{ league[0].leagueInfo.countryDetails.cn_name }}:
              </span>

              <span
                v-else-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.categoryDetails &&
                  league[0].leagueInfo.categoryDetails.cn_name
                "
              >
                {{ league[0].leagueInfo.categoryDetails.cn_name }}:
              </span>
            </span>

            <span v-else data-v-f1b1cb6e="" class="country-name minitext">
              <span
                v-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.countryDetails &&
                  league[0].leagueInfo.countryDetails.name
                "
              >
                {{ league[0].leagueInfo.countryDetails.name }}:
              </span>
              <span
                v-else-if="
                  league[0] &&
                  league[0].leagueInfo &&
                  league[0].leagueInfo.categoryDetails &&
                  league[0].leagueInfo.categoryDetails.name
                "
              >
                {{ league[0].leagueInfo.categoryDetails.name }}:
              </span>
            </span>

            <a
              v-if="league[0].hasLeagueDetails"
              data-v-f1b1cb6e=""
              :href="
                '/' +
                this.$i18n.locale +
                '/football/league/details/' +
                league[0].leagueInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                '/' +
                league[0].competition_id
              "
              target="_blank"
              class="compe-name minitext"
            >
              {{
                this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                  ? league[0].leagueInfo.cn_name
                  : league[0].leagueInfo.en_name
                  ? league[0].leagueInfo.en_name
                  : ""
              }}
            </a>
            <a
              v-else
              data-v-f1b1cb6e=""
              target="_blank"
              class="compe-name minitext"
              style="cursor: not-allowed"
            >
              {{
                this.$i18n.locale == "cn" && league[0].leagueInfo.cn_name
                  ? league[0].leagueInfo.cn_name
                  : league[0].leagueInfo.en_name
                  ? league[0].leagueInfo.en_name
                  : ""
              }}
            </a>

            <div data-v-f1b1cb6e="" class="flex-1">
              <span
                data-v-f1b1cb6e=""
                class="watchNum"
                style="margin-right: 1px"
              >
              </span>
            </div>
            <ul data-v-f1b1cb6e="" class="odds">
              <li data-v-f1b1cb6e="">
                <span data-v-f1b1cb6e="">1</span>
              </li>
              <li data-v-f1b1cb6e="">
                <span data-v-f1b1cb6e="">X</span>
              </li>
              <li data-v-f1b1cb6e="">
                <span data-v-f1b1cb6e="">2</span>
              </li>
            </ul>
          </div>

          <!-- end::league title -->
          <!-- begin::teams -->
          <div class="comp-list" v-for="match in league" :key="match.id">
            <a
              v-if="match && match.homeInfo && match.awayInfo"
              data-v-0d00ff82=""
              :href="
                '/' +
                this.$i18n.locale +
                '/football/match/details/' +
                match.homeInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                '-vs-' +
                match.awayInfo.en_name.toLowerCase().replace(/\s/g, '-') +
                '/' +
                match.id
              "
              target="_blank"
              class="match-container"
              ><span data-v-0d00ff82="" class="left-box"
                ><span data-v-0d00ff82="" class="v-st-box">
                  <span data-v-0d00ff82="" class="time minitext">
                    {{ readableTime(this.$i18n.locale, match.match_time) }}
                  </span>
                  <span
                    data-v-0d00ff82=""
                    class="status minitext on twinkle"
                    v-if="liveMatches.includes(match.status_id)"
                  >
                    {{
                      onGoingMatchTime(match.counter_timing, match.status_id)
                    }}</span
                  ></span
                >

                <span data-v-0d00ff82="" class="v-tt-box">
                  <!-- begin::home info -->
                  <span data-v-0d00ff82="" class="team home"
                    ><span
                      data-v-0d00ff82=""
                      class="flex-1 teamBox teamHomeBox"
                    >
                      <!-- begin::teams info -->
                      <span data-v-0d00ff82="" class="nameBox"
                        ><span
                          data-v-0d00ff82=""
                          itemprop="homeTeam"
                          class="name minitext maxWidth160"
                        >
                          <template v-if="match && match.homeInfo">
                            {{
                              this.$i18n.locale == "cn" &&
                              match.homeInfo.cn_name
                                ? match.homeInfo.cn_name
                                : match.homeInfo.en_name
                                ? match.homeInfo.en_name
                                : ""
                            }}
                          </template>
                        </span></span
                      >
                      <span
                        data-v-0d00ff82=""
                        class="teamLogoBox teamLogoHomeBox"
                      >
                        <img
                          v-if="match.homeInfo"
                          :src="
                            match.homeInfo.logo
                              ? match.homeInfo.logo
                              : '/images/default-football.jpg'
                          "
                        />
                      </span> </span
                  ></span>
                  <!-- end::home info -->
                  <!-- begin::away info -->
                  <!-- begin::score section -->
                  <span data-v-0d00ff82="" class="scores on"
                    ><span data-v-0d00ff82="" class="score-home minitext">
                      {{
                        liveMatches.includes(match.status_id) ||
                        FinishedMatches.includes(match.status_id)
                          ? match.homeInfo.home_score
                          : ""
                      }}
                    </span>
                    <span
                      data-v-0d00ff82=""
                      class="or"
                      style="background-color: transparent !important"
                      >-</span
                    >
                    <span data-v-0d00ff82="" class="score-away minitext">
                      {{
                        liveMatches.includes(match.status_id) ||
                        FinishedMatches.includes(match.status_id)
                          ? match.awayInfo.away_score
                          : ""
                      }}
                    </span>
                  </span>

                  <!-- end::score section -->
                  <span data-v-0d00ff82="" class="team away"
                    ><span data-v-0d00ff82="" class="flex-1 teamBox teamAwayBox"
                      ><span
                        data-v-0d00ff82=""
                        class="teamLogoBox teamLogoAwayBox"
                      >
                        <img
                          v-if="match.awayInfo"
                          :src="
                            match.awayInfo.logo
                              ? match.awayInfo.logo
                              : '/images/default-football.jpg'
                          "
                        />
                      </span>
                      <span data-v-0d00ff82="" class="nameBox">
                        <span
                          data-v-0d00ff82=""
                          itemprop="awayTeam"
                          class="name minitext maxWidth160"
                        >
                          <template v-if="match && match.awayInfo">
                            {{
                              this.$i18n.locale == "cn" &&
                              match.awayInfo.cn_name
                                ? match.awayInfo.cn_name
                                : match.awayInfo.en_name
                                ? match.awayInfo.en_name
                                : ""
                            }}
                          </template>
                        </span>
                      </span>
                    </span></span
                  >
                  <!-- end::away info -->
                </span>
                <!-- end::teams info -->
              </span>
              <ul
                data-v-0d00ff82=""
                class="odds oddhoverGreen newOddsBox"
                v-if="
                  match &&
                  match.odds &&
                  match.odds != '' &&
                  match.odds.euro &&
                  match.odds.euro != ''
                "
              >
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text">
                    {{ match.odds.euro[2] ? match.odds.euro[2] : "-" }}
                  </span>
                </li>
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                    {{ match.odds.euro[3] ? match.odds.euro[3] : "-" }}
                  </span>
                </li>
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text loseBottom">
                    {{ match.odds.euro[4] ? match.odds.euro[4] : "-" }}
                  </span>
                </li>
              </ul>
              <ul
                data-v-0d00ff82=""
                class="odds oddhoverGreen newOddsBox"
                v-else
              >
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text">
                    <b>-</b>
                  </span>
                </li>
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text text1X2 dogFallTop">
                    <b>-</b>
                  </span>
                </li>
                <li data-v-0d00ff82="">
                  <span data-v-0d00ff82="" class="text loseBottom">
                    <b>-</b>
                  </span>
                </li>
              </ul>
            </a>
          </div>
          <!-- end::teams -->
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import DateMixin from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],DateMixin,

  props: {
    searchMatchListUpcoming: {
      type: Object,
      default: null,
    },
    searchMatchListLive: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leagueFavoriteStatus: {}, // Store favorite status for each league
      hotLeaguesList: null,
      countLiveMatches: null,
      loading: true,
      liveActivebtn: "",
      liveMatches: [2, 3, 4, 5, 6, 7],
      FinishedMatches: [8],
      countryList: null,
      countryLeagueActive: "",
      homeTeamInningsStatuses: [
        433, 435, 437, 439, 411, 413, 415, 417, 419, 421,
      ],
      awayTeamInningsStatuses: [
        432, 434, 436, 438, 440, 412, 414, 416, 418, 420,
      ],
      match_status: {
        NOT_STARTED: 1,
        FIRST_INNINGS_AWAY_TEAM: 432,
        FIRST_INNINGS_HOME_TEAM: 433,
        SECOND_INNINGS_AWAY_TEAM: 434,
        SECOND_INNINGS_HOME_TEAM: 435,
        THIRD_INNINGS_AWAY_TEAM: 436,
        THIRD_INNINGS_HOME_TEAM: 437,
        FOURTH_INNINGS_AWAY_TEAM: 438,
        FOURTH_INNINGS_HOME_TEAM: 439,
        FIFTH_INNINGS_AWAY_TEAM: 440,
        FIFTH_INNINGS_HOME_TEAM: 411,
        SIXTH_INNINGS_AWAY_TEAM: 412,
        SIXTH_INNINGS_HOME_TEAM: 413,
        SEVENTH_INNINGS_AWAY_TEAM: 414,
        SEVENTH_INNINGS_HOME_TEAM: 415,
        EIGHTH_INNINGS_AWAY_TEAM: 416,
        EIGHTH_INNINGS_HOME_TEAM: 417,
        NINTH_INNINGS_AWAY_TEAM: 418,
        NINTH_INNINGS_HOME_TEAM: 419,
        EXTRA_INNINGS_AWAY_TEAM: 420,
        EXTRA_INNINGS_HOME_TEAM: 421,
      },
    };
  },
  created() {
    // this.getUpcomingMatchList("upcoming");
  },
  computed: {
    thereIsUpcomingMatches() {
   
      return true;
    },
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    loadFavoriteStatus() {
      if (this.todayMatchList) {
        Object.values(this.todayMatchList).forEach((matches) => {
          matches.forEach((match) => {
            if (match && typeof match === "object") {
              const unique_tournament_id = match.unique_tournament_id;

              const favoriteStatus = localStorage.getItem(
                `baseball_${unique_tournament_id}`
              );

              if (favoriteStatus !== null && favoriteStatus === "true") {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  true
                );
              } else {
                this.setReactiveProperty(
                  this.leagueFavoriteStatus,
                  unique_tournament_id,
                  false
                );
              }
            }
          });
        });
      }
    },

    setReactiveProperty(obj, key, value) {
      Object.defineProperty(obj, key, {
        enumerable: true,
        configurable: true,
        get() {
          return value;
        },
        set(newValue) {
          value = newValue;
        },
      });
    },

    toggleFavorite(leagueId) {
      const currentStatus = this.leagueFavoriteStatus[leagueId];
      const newStatus = !currentStatus;
      localStorage.setItem(`baseball_${leagueId}`, newStatus.toString());
      this.leagueFavoriteStatus[leagueId] = newStatus;
    },
    computeFavoriteStatus() {
      if (this.todayMatchList) {
        this.todayMatchList.forEach((league) => {
          const leagueId = league[0].unique_tournament_id;
          const favoriteStatus = localStorage.getItem(`baseball_${leagueId}`);
          this.leagueFavoriteStatus[leagueId] = favoriteStatus === "true";
        });
      }
    },
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);

      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }

      if (status_code == 3) {
        return "HT";
      }

      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }

      if (this.liveMatches.includes(status_code)) {
        return "90+";
      }
    },

    // method to get upcoming matchlist
    // getUpcomingMatchList(status) {
    //   this.liveActivebtn = status;
    //   this.loading = true;
    //   this.$axios
    //     .get(
    //       this.BASE_SERVER_URI +
    //         `/api/baseball/match/list/today?matchStatus=${status}`
    //     )
    //     .then((response) => {
    //       this.todayMatchList = response.data.baseballMatchList;
    //       this.loadFavoriteStatus();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>
