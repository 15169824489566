// src/router/routes.en.js

import PrivacyPolicy from "../components/commons/privacy-policy/privacy";
import AboutUS from "../components/commons/about-us/about-us";
import ContactUs from "../components/commons/contact-us/contact-us";
import TermsAndConditions from "../components/commons/terms-and-conditions/terms-and-conditions";

import FootballLiveScoring from "../components/football/live-scoring/main-index";
import FootballLeagueDetails from "../components/football/league/league-details";
import FootballTeamDetails from "../components/football/teams/team-details";
import TodayLiveMatch from "../components/football/matches/match-details";
import FootballPlayerDetails from "../components/football/player/player-details";

import FootballNewsList from "../components/football/news/list/news-list-wrapper";
import FootballNewsDetails from "../components/football/news/details/news-details";

import BasketballLiveScoring from "../components/basketball/live-scoring/main-index";
import BasketballLeagueDetails from "../components/basketball/league/league-details";
import BasketballMatchDetails from "../components/basketball/matches/match-details";
import BasketballTeamDetails from "../components/basketball/teams/team-details";
import BasketballPlayerDetails from "../components/basketball/player/player-details";

import TennisLiveScoring from "../components/tennis/live-scoring/main-index";
import TennisLeagueDetails from "../components/tennis/league/league-details";
import TennisMatchDetails from "../components/tennis/matches/match-details";
import TennisTeamDetails from "../components/tennis/teams/team-details";

import CricketLiveScoring from "../components/cricket/live-scoring/main-index";
import CricketMatchDetails from "../components/cricket/matches/match-details";
import CricketLeagueDetails from "../components/cricket/league/league-details";
import CricketTeamDetails from "../components/cricket/teams/team-details";
import CricketPlayerDetails from "../components/cricket/player/player-details";
import CricketNewsList from "../components/cricket/news/list/news-list-wrapper";
import CricketNewsDetails from "../components/cricket/news/details/news-details";

import BaseballLiveScoring from "../components/baseball/live-scoring/main-index";
import BaseballMatchDetails from "../components/baseball/matches/match-details";
import BaseballLeagueDetails from "../components/baseball/league/league-details";
import BaseballTeamDetails from "../components/baseball/teams/team-details";

import VolleyballLiveScoring from "../components/volleyball/live-scoring/main-index";
import VolleyballMatchDetails from "../components/volleyball/matches/match-details";
import VolleyballLeagueDetails from "../components/volleyball/league/league-details";
import VolleyballTeamDetails from "../components/volleyball/teams/team-details";

import TableTennisLiveScoring from "../components/table_tennis/live-scoring/main-index";
import TableTennisMatchDetails from "../components/table_tennis/matches/match-details";
import TableTennisLeagueDetails from "../components/table_tennis/league/league-details";
import TableTennisTeamDetails from "../components/table_tennis/teams/team-details";

import BadmintonLiveScoring from "../components/badminton/live-scoring/main-index";
import BadmintonMatchDetails from "../components/badminton/matches/match-details";
import BadmintonLeagueDetails from "../components/badminton/league/league-details";
import BadmintonTeamDetails from "../components/badminton/teams/team-details";

import HockeyLiveScoring from "../components/hockey/live-scoring/main-index";
import HockeyLeagueDetails from "../components/hockey/league/league-details";
import HockeyMatchDetails from "../components/hockey/matches/match-details";
import HockeyTeamDetails from "../components/hockey/teams/team-details";

import UserProfile from "../components/user/profile/main-index.vue";

const routesEn = [
  // Static pages in English
  {
    path: "/en/privacy-policy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content: "Privacy Policy of Sportyclub Live",
        },
        {
          name: "keywords",
          content: "privacy policy, sportyclub, sportyclub live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/privacy-policy",
        },
        // Other meta tags as needed
      ],
    },
  },

  {
    path: "/en/contact-us",
    component: ContactUs,
    meta: {
      title: "Contact Us | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content: "Contact Us of Sportyclub Live",
        },
        {
          name: "keywords",
          content: "contact us, sportyclub, sportyclub live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/contact-us",
        },
        // Other meta tags as needed
      ],
    },
  },

  {
    path: "/en/about-us",
    component: AboutUS,
    meta: {
      title: "About Us | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content: "About Us of Sportyclub Live",
        },
        {
          name: "keywords",
          content: "about us, sportyclub, sportyclub live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/about-us",
        },
        // Other meta tags as needed
      ],
    },
  },
  {
    path: "/en/terms-and-conditions",
    component: TermsAndConditions,
    meta: {
      title: "Terms & Conditions | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content: "Terms & Conditions of Sportyclub Live",
        },
        {
          name: "keywords",
          content: "terms and conditions, sportyclub, sportyclub live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/terms-and-conditions",
        },
        // Other meta tags as needed
      ],
    },
  },

  // Football
  {
    path: "/en/live/football",
    component: FootballLiveScoring,
    meta: {
      title: "Football Live Scores | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live football scores and updates on Sportyclub Live. Stay updated with the latest football matches, live commentary, and real-time scores.",
        },
        {
          name: "keywords",
          content:
            "live football scores, football live updates, football live commentary, real-time football scores, Sportyclub Live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/live/football",
        },
      ],
    },
  },
  {
    path: "/en/football/match/details/:slug/:matchId",
    component: TodayLiveMatch,
  },
  {
    path: "/en/football/league/details/:slug/:leagueId",
    component: FootballLeagueDetails,
  },
  {
    path: "/en/football/team/details/:slug/:teamId",
    component: FootballTeamDetails,
  },
  {
    path: "/en/football/player/details/:slug/:playerId",
    component: FootballPlayerDetails,
  },

  {
    path: "/en/football/news/list",
    component: FootballNewsList,
    meta: {
      title:
        "Today's Football News | International Football News | Football Headlines | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live football scores, football updates of upcoming & finished International, domestic , leagues and T20 matches. Catch all the latest news about football on sportyclub.",
        },
        {
          name: "keywords",
          content:
            "football news, latest news, latest football news, football news headlines,  icc football news, psl news, ipl news, bigbash news, t10 news",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/",
        },
        // Other meta tags as needed
      ],
    },
  },
  {
    path: "/en/football/news/details/:newsId",
    component: FootballNewsDetails,
  },

  // Basketball
  {
    path: "/en/live/basketball",
    component: BasketballLiveScoring,
    meta: {
      title: "Basketball Live Scores | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live basketball scores and updates on Sportyclub Live. Stay updated with the latest basketball matches, live commentary, and real-time scores.",
        },
        {
          name: "keywords",
          content:
            "live basketball scores, basketball live updates, basketball live commentary, real-time basketball scores, Sportyclub Live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/live/basketball",
        },
      ],
    },
  },
  {
    path: "/en/basketball/league/details/:slug/:leagueId",
    component: BasketballLeagueDetails,
  },
  {
    path: "/en/basketball/match/details/:slug/:matchId",
    component: BasketballMatchDetails,
  },
  {
    path: "/en/basketball/team/details/:slug/:teamId",
    component: BasketballTeamDetails,
  },
  {
    path: "/en/basketball/player/details/:slug/:playerId",
    component: BasketballPlayerDetails,
  },

  // Tennis
  {
    path: "/en/live/tennis",
    component: TennisLiveScoring,
  },
  {
    path: "/en/tennis/league/details/:slug/:leagueId",
    component: TennisLeagueDetails,
  },
  {
    path: "/en/tennis/match/details/:slug/:matchId",
    component: TennisMatchDetails,
  },
  {
    path: "/en/tennis/team/details/:slug/:teamId",
    component: TennisTeamDetails,
  },

  // Cricket
  {
    path: "/en/live/cricket",
    component: CricketLiveScoring,
    meta: {
      title: "Cricket  Live Scores | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live cricket scores and updates on Sportyclub Live. Stay updated with the latest cricket matches, live commentary, and real-time scores.",
        },
        {
          name: "keywords",
          content:
            "live cricket scores, cricket live updates, cricket live commentary, real-time cricket scores, Sportyclub Live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/live/cricket",
        },
      ],
    },
  },
  {
    path: "/en/cricket/match/details/:slug/:matchId",
    component: CricketMatchDetails,
  },
  {
    path: "/en/cricket/league/details/:slug/:leagueId",
    component: CricketLeagueDetails,
  },
  {
    path: "/en/cricket/team/details/:slug/:teamId",
    component: CricketTeamDetails,
  },
  {
    path: "/en/cricket/player/details/:slug/:playerId",
    component: CricketPlayerDetails,
  },
  {
    path: "/en/cricket/news/list",
    component: CricketNewsList,
    meta: {
      title:
        "Today's Cricket News | International Cricket News | Cricket Headlines | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live cricket scores, cricket updates of upcoming & finished International, domestic , leagues and T20 matches. Catch all the latest news about cricket on sportyclub.",
        },
        {
          name: "keywords",
          content:
            "cricket news, latest news, latest cricket news, cricket news headlines,  icc cricket news, psl news, ipl news, bigbash news, t10 news",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/",
        },
        // Other meta tags as needed
      ],
    },
  },
  {
    path: "/en/cricket/news/details/:newsId",
    component: CricketNewsDetails,
  },

  // Baseball
  {
    path: "/en/live/baseball",
    component: BaseballLiveScoring,
    meta: {
      title: "Baseball Live Scores | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live baseball scores and updates on Sportyclub Live. Stay updated with the latest baseball matches, live commentary, and real-time scores.",
        },
        {
          name: "keywords",
          content:
            "live baseball scores, baseball live updates, baseball live commentary, real-time baseball scores, Sportyclub Live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/live/baseball",
        },
      ],
    },
  },
  {
    path: "/en/baseball/match/details/:slug/:matchId",
    component: BaseballMatchDetails,
  },
  {
    path: "/en/baseball/league/details/:slug/:leagueId",
    component: BaseballLeagueDetails,
  },
  {
    path: "/en/baseball/team/details/:slug/:teamId",
    component: BaseballTeamDetails,
  },

  // Volleyball
  {
    path: "/en/live/volleyball",
    component: VolleyballLiveScoring,
  },
  {
    path: "/en/volleyball/match/details/:slug/:matchId",
    component: VolleyballMatchDetails,
  },
  {
    path: "/en/volleyball/league/details/:slug/:leagueId",
    component: VolleyballLeagueDetails,
  },
  {
    path: "/en/volleyball/team/details/:slug/:teamId",
    component: VolleyballTeamDetails,
  },

  // Table Tennis
  {
    path: "/en/live/table-tennis",
    component: TableTennisLiveScoring,
  },
  {
    path: "/en/table-tennis/match/details/:slug/:matchId",
    component: TableTennisMatchDetails,
  },
  {
    path: "/en/table-tennis/league/details/:slug/:leagueId",
    component: TableTennisLeagueDetails,
  },
  {
    path: "/en/table-tennis/team/details/:slug/:teamId",
    component: TableTennisTeamDetails,
  },

  // Badminton
  {
    path: "/en/live/badminton",
    component: BadmintonLiveScoring,
    meta: {
      title: "Badminton  Live Scores | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content:
            "Get live badminton scores and updates on Sportyclub Live. Stay updated with the latest badminton matches, live commentary, and real-time scores.",
        },
        {
          name: "keywords",
          content:
            "live badminton scores, badminton live updates, badminton live commentary, real-time badminton scores, Sportyclub Live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/live/badminton",
        },
      ],
    },
  },
  {
    path: "/en/badminton/match/details/:slug/:matchId",
    component: BadmintonMatchDetails,
  },
  {
    path: "/en/badminton/league/details/:slug/:leagueId",
    component: BadmintonLeagueDetails,
  },
  {
    path: "/en/badminton/team/details/:slug/:teamId",
    component: BadmintonTeamDetails,
  },

  // Hockey
  {
    path: "/en/live/hockey",
    component: HockeyLiveScoring,
  },
  {
    path: "/en/hockey/match/details/:slug/:matchId",
    component: HockeyMatchDetails,
  },
  {
    path: "/en/hockey/league/details/:slug/:leagueId",
    component: HockeyLeagueDetails,
  },
  {
    path: "/en/hockey/team/details/:slug/:teamId",
    component: HockeyTeamDetails,
  },

  // User Profile
  {
    path: "/en/user/profile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      title: "User Profile | Sportyclub Live",
      metaTags: [
        {
          name: "description",
          content: "User Profile of Sportyclub Live",
        },
        {
          name: "keywords",
          content: "user profile, sportyclub, sportyclub live",
        },
        {
          name: "canonical",
          content: "https://sportyclub.live/en/user/profile",
        },
        // Other meta tags as needed
      ],
    },
  },
];

export default routesEn;
