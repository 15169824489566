<template>
  <span v-if="loading">
    <LoadingSection />
  </span>

  <div class="clearfix-row view border-box" data-v-8948368c="" v-if="!loading">
    <!-- begin::breadcrumb -->
    <div class="breadcrumb-box" data-v-8948368c="">
      <div class="content-box" data-v-8948368c="">
        <ul class="breadcrumb" data-v-8948368c="">
          <li data-v-8948368c="">
            <a
              :href="'/' + this.$i18n.locale + '/live/tennis'"
              class="link"
              data-v-8948368c=""
            >
              {{ $t("football.league-details.tennis-live-score") }}
            </a>
          </li>
          <li data-v-8948368c="" v-if="this.$i18n.locale == 'cn'">
            {{
              leagueDetails && leagueDetails.cn_name
                ? leagueDetails.cn_name
                : leagueDetails.cn_name
            }}
          </li>
          <li data-v-8948368c="" v-else>
            {{
              leagueDetails && leagueDetails.name
                ? leagueDetails.name
                : leagueDetails.name
            }}
          </li>

          <li data-v-8948368c="">
            <h1 class="text" data-v-8948368c="">
              {{ $t("football.league-details.winners") }},
              {{ $t("football.league-details.champions") }},
              {{ $t("football.league-details.standings") }} &nbsp;
            </h1>
          </li>
        </ul>
      </div>
    </div>
    <!-- end::breadcrumb -->
    <div class="team" data-v-8948368c="">
      <!-- begin::title holder info -->
      <div class="competition-header" data-v-26a596f7="" data-v-8948368c="">
        <div class="content border-box flex align-center" data-v-26a596f7="">
          <div class="p-l flex-1 flex align-center" data-v-26a596f7="">
            <div
              class="flex flex-1 align-center"
              style="height: 88px"
              data-v-26a596f7=""
            >
              <img
                :src="leagueDetails.logo + '!w100'"
                class="team-logo"
                data-v-26a596f7=""
              />
              <!-- begin::title -->
              <div class="pl-15 pr-15" data-v-26a596f7="">
                <div class="fs-24 font-500 flex" data-v-26a596f7="">
                  <span
                    style="
                      max-width: 330px;
                      font-weight: 500;
                      font-family: Rubik-Medium, MicrosoftYaHei,
                        MicrosoftYaHei-Bold, PingFang SC;
                    "
                    data-v-26a596f7=""
                  >
                    <span v-if="this.$i18n.locale == 'cn'">
                      {{
                        leagueDetails && leagueDetails.cn_name
                          ? leagueDetails.cn_name
                          : ""
                      }}
                    </span>
                    <span v-else>
                      {{
                        leagueDetails && leagueDetails.name
                          ? leagueDetails.name
                          : ""
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- display: none; -->
              <!-- end::title -->
            </div>
          </div>
        </div>
      </div>
      <!-- end::title holder info -->
      <span>
        <LeagueStandings :leagueDetail="leagueDetails" :leagueId="leagueId" />
      </span>
      <!-- end::teams stats -->
      <div class="wrapper" data-v-8948368c="">
        <div
          class="inner-temp"
          style="border-radius: 0px"
          data-v-d9dc0c56=""
          data-v-8948368c=""
        >
          <TodayMatches :leagueId="leagueId" />
        </div>
      </div>
      <!-- begin::overview -->
    </div>
  </div>

</template>
<script>
import LeagueStandings from "./tabs/league-standings.vue";

import TodayMatches from "./tabs/today-matches.vue";
import metaDataUtils from '@/utils/metaDataUtils';
export default {
  components: {
    LeagueStandings,

    TodayMatches,
  },
  data() {
    return {
      leagueDetails: null,
      loading: true,
      seasonsList: null,
      leagueId: null,
      leagueStandings: null,
      activeTab: "standings",
    };
  },
  mixins:[metaDataUtils],
  created() {
    // Accessing the ID parameter from the route
    const itemId = this.$route.params.leagueId;
    this.leagueId = itemId;
    this.getLeagueDetails(itemId);
    this.getMetaData(itemId);
  },
  methods: {
    // methpd to get meta tags
    getMetaData(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/league/meta/` + leagueId)
        .then((response) => {
          this.metaData = response.data.contents;
          this.changeTitle(this.metaData?.title);
          this.handleMetaTags(
            this.metaData?.description,
            this.metaData?.keywords
          );
          this.handleCanonicalLink(this.metaData?.url);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get hot leagues list
    getLeagueDetails(leagueId) {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/league/details/${leagueId}`)
        .then((response) => {
          this.leagueDetails = response.data.leagueDetails;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTimeStampDate(timestamp) {
      // Convert timestamp to milliseconds
      const date = new Date(timestamp * 1000); // Assuming timestamp is in seconds, multiply by 1000 for milliseconds
      // Get day, month, and year
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
