<template>
  <div class="content" data-v-9a805c60="">
    <div data-v-9a805c60="" class="football w100">
      <div v-if="nextMatches" class="filter flex align-center justify-between">
        <div class="h100 flex align-center" @click="toggleNextMatches">
          <div
            class="ss van-image van-image--round"
            itemprop="logo"
            style="overflow: hidden; border-radius: 50%"
          >
            <img
              v-if="showNextMatches"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAeFBMVEUAAAAOgtoNgtkPgNwPg9wPgd0OgdsOf9wOgdkOgdwOf9kQgdsQgNwQg9wPgdsPf9sPgdwOgtsNgdkPgtwPgdsPgNoPgNsOf9oPgNoPf9oPgdoPgNoPgdoPgNoPgdsOgNoOgNsOgNsOgNoOgNkPgNoPgdsPgNsPgNr3V9J7AAAAJ3RSTlMANz1CQkNHSElJSk9QUFVWV1xfZs7R09TX2tzd3t/i5ef3+fr7/P2kA3u4AAAAkUlEQVQoz83Q2w6CMAwG4HoWRcEpKCrDA9j3f0PDumG6tFfc2Ks//bK2GcDIWlmbhnx43KYMLWKbUzx+EIsYvfYWYdp6dfac8aU5KVkSn0Qqm1fFgspGqhnA9npZwD9V1tQbzUyH+NrppimZrM7enaiG2kbSLDRJlwyb4YHTkmH9G9brieG8qtYh7+/nydjv/AK8sBcTdVJBCgAAAABJRU5ErkJggg=="
              alt="imgalt"
              class="van-image__img"
              style="object-fit: contain"
            />
            <img
              v-else
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAeFBMVEUAAAAOgtoNgtkPgNwPg9wPgd0OgdsOf9wOgdkOgdwOf9kQgdsQgNwQg9wPgdsPf9sPgdwOgtsNgdkPgtwPgdsPgNoPgNsOf9oPgNoPf9oPgdoPgNoPgdoPgNoPgdsOgNoOgNsOgNsOgNoOgNkPgNoPgdsPgNsPgNr3V9J7AAAAJ3RSTlMANz1CQkNHSElJSk9QUFVWV1xfZs7R09TX2tzd3t/i5ef3+fr7/P2kA3u4AAAAkUlEQVQoz9XQyxKCMAwF0Ci+RcGqKCrFB5j//0OGpkXTSXZuvKs7OdM2U4CvZHW1AiWmRXxudNOUTFZnr1ZUQ2MjaRaGpDOG9XDAacGw+lzW64HhpCwXoW9vpxH8b+bWpqHv7tcxQ4vY5FT3b8RjjF57izBtvDp7JPzRnJRsGa9EKptXxYLKRqoZwPpynv7mUztnfxcTJuw4hgAAAABJRU5ErkJggg=="
              alt="imgalt"
              class="van-image__img"
              style="object-fit: contain"
            />
          </div>
          <span v-if="showNextMatches" class="text">Show previous matches</span>
          <span v-else class="text">Show next matches</span>
        </div>
        <div class="nextMatches">{{ nextMatches.length }}</div>
      </div>

      <div
        v-if="loading"
        class="loading van-loading van-loading--spinner van-loading--vertical"
        data-v-9a805c60=""
      >
        <span
          class="van-loading__spinner van-loading__spinner--spinner"
          data-v-9a805c60=""
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
          ><i data-v-9a805c60=""></i><i data-v-9a805c60=""></i
        ></span>
      </div>
      <div
        v-else
        class="vue-recycle-scroller scroller ready page-mode direction-vertical"
      >
        <div class="user">
          <div class="list_active" style="width: 100%">
            <template v-if="true">
              <!-- next Matches -->
              <div v-if="showNextMatches && nextMatches" class="w100">
                <div
                  v-for="matchNumber in nextMatches.length - 1"
                  :key="matchNumber"
                  class="list"
                  style="height: 1.38rem"
                >
                  <div
                    v-if="nextMatches[matchNumber-1]"
                    itemscope="itemscope"
                    itemtype="http://schema.org/SportsEvent"
                    class="flex flex-1 contentBox"
                    style="height: 100%"
                  >
                    <a itemprop="url" class="flex allBox"
                      ><div class="w100 flex h100 align-center">
                        <div
                          class="flex align-center justify-center listBox flex-column"
                          style="width: 2rem"
                        >
                          <span
                            itemprop="startDate"
                            class="fs-12 text-center"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{
                              getBirthdayFromTimestamp(
                                nextMatches[matchNumber-1].startTimestamp
                              )
                            }}</span
                          >
                          <span
                            class="fs-12 color-999 van-ellipsis"
                            style="max-width: 2rem"
                          >
                            {{
                              nextMatches[matchNumber-1].status.description
                            }}</span
                          >
                        </div>
                        <div
                          class="team flex flex-col padding5"
                          style="width: 4rem"
                        >
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    nextMatches[matchNumber-1].homeTeam &&
                                    nextMatches[matchNumber-1].homeTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        nextMatches[matchNumber-1].homeTeam.id +
                                        '/' +
                                        nextMatches[matchNumber-1].homeTeam.id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  class="van-image__img"
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center h100"
                              style="padding-left: 0.2rem; width: 3.2rem"
                            >
                              <div
                                itemprop="homeTeam"
                                class="w-o-h h100 fs-14"
                                :class="{
                                  teamNameBold:
                                    nextMatches[matchNumber-1].winnerCode == 1,
                                }"
                                style="margin-right: 0.1rem; max-width: 4rem"
                              >
                                {{
                                  nextMatches[matchNumber-1].homeTeam &&
                                  nextMatches[matchNumber-1].homeTeam.name
                                    ? nextMatches[matchNumber-1].homeTeam.name
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    nextMatches[matchNumber-1].awayTeam &&
                                    nextMatches[matchNumber-1].awayTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        nextMatches[matchNumber-1].awayTeam.id +
                                        '/' +
                                        nextMatches[matchNumber-1].awayTeam.id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  alt="Netherlands"
                                  class="van-image__img"
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center h100"
                              style="padding-left: 0.2rem; width: 3.2rem"
                            >
                              <div
                                itemprop="awayTeam"
                                class="w-o-h h100 fs-14"
                                :class="{
                                  teamNameBold:
                                    nextMatches[matchNumber-1].winnerCode == 2,
                                }"
                                style="margin-right: 0.1rem; max-width: 2.7rem"
                              >
                                {{
                                  nextMatches[matchNumber-1].awayTeam &&
                                  nextMatches[matchNumber-1].awayTeam.name
                                    ? nextMatches[matchNumber-1].awayTeam.name
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="teamScore"
                          style="height: calc(-1px + 1.38rem); width: 2.5rem"
                        >
                          <div
                            class="flex flex-col align-center"
                            style="
                              height: 100%;
                              line-height: 12px;
                              padding: 5px 0px;
                            "
                          >
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span class="" style="font-size: 0.32rem"
                                  >-
                                </span>
                              </div>
                            </div>
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span class="" style="font-size: 0.32rem">
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex winnerBox"
                          style="height: 100%; width: 0.7rem"
                        ></div></div
                    ></a>
                  </div>
                </div>
              </div>
              <!-- previous matches -->
              <div class="w100" v-if="!showNextMatches">
                <div
                  v-for="matchNumber in previousMatches.length"
                  :key="matchNumber"
                  class="list"
                  style="height: 1.38rem"
                >
                  <div
                    v-if="previousMatches[matchNumber-1]"
                    itemscope="itemscope"
                    itemtype="http://schema.org/SportsEvent"
                    class="flex flex-1 contentBox"
                    style="height: 100%"
                  >
                    <a itemprop="url" class="flex allBox"
                      ><div class="w100 flex h100 align-center">
                        <div
                          class="flex align-center justify-center listBox flex-column"
                          style="width: 2rem"
                        >
                          <span
                            itemprop="startDate"
                            class="fs-12 text-center"
                            style="color: rgb(153, 153, 153)"
                          >
                            {{
                              getBirthdayFromTimestamp(
                                previousMatches[matchNumber-1].startTimestamp
                              )
                            }}</span
                          >
                          <span
                            class="fs-12 color-999 van-ellipsis"
                            style="max-width: 2rem"
                          >
                            {{
                              previousMatches[matchNumber-1].status.description
                            }}</span
                          >
                        </div>
                        <div
                          class="team flex flex-col padding5"
                          style="width: 4rem"
                        >
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    previousMatches[matchNumber-1].homeTeam &&
                                    previousMatches[matchNumber-1].homeTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        previousMatches[matchNumber-1].homeTeam
                                          .id +
                                        '/' +
                                        previousMatches[matchNumber-1].homeTeam
                                          .id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  class="van-image__img"
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center h100"
                              style="padding-left: 0.2rem; width: 3.2rem"
                            >
                              <div
                                itemprop="homeTeam"
                                class="w-o-h h100 fs-14"
                                :class="{
                                  teamNameBold:
                                    previousMatches[matchNumber-1].winnerCode ==
                                    1,
                                }"
                                style="margin-right: 0.1rem; max-width: 4rem"
                              >
                                {{
                                  previousMatches[matchNumber-1].homeTeam &&
                                  previousMatches[matchNumber-1].homeTeam.name
                                    ? previousMatches[matchNumber-1].homeTeam.name
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex-1 align-center flex"
                            style="height: 50%"
                          >
                            <div
                              class="flex align-center"
                              style="width: 0.4rem"
                            >
                              <div
                                class="imgw4 van-image van-image--round"
                                itemprop="logo"
                                style="overflow: hidden; border-radius: 50%"
                              >
                                <img
                                  :src="
                                    previousMatches[matchNumber-1].awayTeam &&
                                    previousMatches[matchNumber-1].awayTeam.id
                                      ? 'https://sportyclub.online//images/cricket/teams/' +
                                        previousMatches[matchNumber-1].awayTeam
                                          .id +
                                        '/' +
                                        previousMatches[matchNumber-1].awayTeam
                                          .id +
                                        '.png'
                                    : bannerImagePath('default-team.png')
                                  "
                                  @error="
                                    $event.target.src =
                                      bannerImagePath('default-team.png')
                                  "
                                  alt="Netherlands"
                                  class="van-image__img"
                                  lazy="loaded"
                                  style="object-fit: contain"
                                />
                              </div>
                            </div>
                            <div
                              class="w-o-h flex align-center h100"
                              style="padding-left: 0.2rem; width: 3.2rem"
                            >
                              <div
                                itemprop="awayTeam"
                                class="w-o-h h100 fs-14"
                                :class="{
                                  teamNameBold:
                                    previousMatches[matchNumber-1].winnerCode ==
                                    2,
                                }"
                                style="margin-right: 0.1rem; max-width: 2.7rem"
                              >
                                {{
                                  previousMatches[matchNumber-1].awayTeam &&
                                  previousMatches[matchNumber-1].awayTeam.name
                                    ? previousMatches[matchNumber-1].awayTeam.name
                                    : ""
                                }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="teamScore"
                          style="height: calc(-1px + 1.38rem); width: 2.5rem"
                        >
                          <div
                            class="flex flex-col align-center"
                            style="
                              height: 100%;
                              line-height: 12px;
                              padding: 5px 0px;
                            "
                          >
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center"
                                :class="{
                                  scoreWeight:
                                    previousMatches[matchNumber-1].winnerCode ==
                                    1,
                                }"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span
                                  class=""
                                  style="font-size: 0.32rem"
                                  v-if="
                                    previousMatches[matchNumber-1].homeScore &&
                                    previousMatches[matchNumber-1].homeScore
                                      .innings &&
                                    previousMatches[matchNumber-1].homeScore
                                      .innings.inning1
                                  "
                                >
                                  {{
                                    previousMatches[matchNumber-1].homeScore
                                      .innings.inning1.score
                                      ? previousMatches[matchNumber-1].homeScore
                                          .innings.inning1.score + "/"
                                      : ""
                                  }}{{
                                    previousMatches[matchNumber-1].homeScore
                                      .innings.inning1.wickets
                                      ? previousMatches[matchNumber-1].homeScore
                                          .innings.inning1.wickets
                                      : ""
                                  }}{{
                                    previousMatches[matchNumber-1].homeScore
                                      .innings.inning1.overs
                                      ? " (" +
                                        previousMatches[matchNumber-1].homeScore
                                          .innings.inning1.overs +
                                        ")"
                                      : ""
                                  }}
                                </span>
                              </div>
                            </div>
                            <div
                              class="flex align-center justify-center fs-12 color-999 w-bar-100 flex-1"
                              style="font-family: Roboto-Regular, Roboto"
                            >
                              <div
                                class="flex-1 text-center"
                                :class="{
                                  scoreWeight:
                                    previousMatches[matchNumber-1].winnerCode ==
                                    2,
                                }"
                                style="
                                  font-family: Roboto-Regular, Roboto;
                                  font-weight: bolder;
                                "
                              >
                                <span
                                  v-if="
                                    previousMatches[matchNumber-1].awayScore &&
                                    previousMatches[matchNumber-1].awayScore
                                      .innings &&
                                    previousMatches[matchNumber-1].awayScore
                                      .innings.inning1
                                  "
                                  class=""
                                  style="font-size: 0.32rem"
                                  >{{
                                    previousMatches[matchNumber-1].awayScore
                                      .innings.inning1.score
                                      ? previousMatches[matchNumber-1].awayScore
                                          .innings.inning1.score + "/"
                                      : ""
                                  }}{{
                                    previousMatches[matchNumber-1].awayScore
                                      .innings.inning1.wickets
                                      ? previousMatches[matchNumber-1].awayScore
                                          .innings.inning1.wickets
                                      : ""
                                  }}{{
                                    previousMatches[matchNumber-1].awayScore
                                      .innings.inning1.overs
                                      ? " (" +
                                        previousMatches[matchNumber-1].awayScore
                                          .innings.inning1.overs +
                                        ")"
                                      : ""
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex winnerBox"
                          style="height: 100%; width: 0.7rem"
                        >
                          <div
                            v-if="
                              (previousMatches[matchNumber-1].winnerCode == 1 &&
                                previousMatches[matchNumber-1].homeTeam.id ==
                                  teamId) ||
                              (previousMatches[matchNumber-1].winnerCode == 2 &&
                                previousMatches[matchNumber-1].awayTeam.id ==
                                  teamId)
                            "
                            class="round"
                            style="background: rgb(82, 175, 47)"
                          >
                            W
                          </div>

                          <div
                            v-else-if="
                              (previousMatches[matchNumber-1].winnerCode == 1 &&
                                previousMatches[matchNumber-1].homeTeam.id !=
                                  teamId) ||
                              (previousMatches[matchNumber-1].winnerCode == 2 &&
                                previousMatches[matchNumber-1].awayTeam.id !=
                                  teamId)
                            "
                            class="round"
                            style="background: rgb(231, 76, 91)"
                          >
                            L
                          </div>
                          <div v-else class="round" style="background: #ffba5a">
                            D
                          </div>
                        </div>
                      </div></a
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath,dateUtils],
  name: "TeamMatches",
  props: {
    teamId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      previousMatches: null,
      showNextMatches: false,
      nextMatches: null,
    };
  },
  created() {
    this.callApis();
  },
  methods: {
    toggleNextMatches() {
      this.showNextMatches = !this.showNextMatches;
    },
    async callApis() {
      try {
        const response = await this.getPreviousMatches(this.teamId);
        console.log("response", response);

        this.previousMatches = response?.data?.events.reverse() ?? null;
        console.log(this.previousMatches);
      } catch (error) {
        console.error(error);
      }

      try {
        const response2 = await this.getNextMatches(this.teamId);
        console.log("response2", response2);
        this.nextMatches = response2?.data?.events ?? null;
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
    async getPreviousMatches(teamId) {
      return this.$axios.get(
        this.BASE_SERVER_URI +
          `/api/cricket/teams/previous-matches/${teamId}`
      );
    },

    async getNextMatches(teamId) {
      return this.$axios.get(
        this.BASE_SERVER_URI +
          `/api/cricket/teams/next-matches/${teamId}`
      );
    },
  },
};
</script>

<style>
</style>