<template>
  <div class="wrapper" data-v-8948368c="">
    <div class="flex" data-v-08c9d60b="" data-v-8948368c="">
      <div class="p-l flex-1" data-v-08c9d60b="">
        <div class="container-box mb-12" data-v-7c947e55="" data-v-08c9d60b="">
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name +
                    $t("football.league-details.standings")
                  : leagueDetails.name + " Standings"
              }}
            </span>
          </div>
          <div
            class="fs-12 color-666 pl-15 pr-15 pb-xxs"
            data-v-77277ac5=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <div class="w100" data-v-51bacf47="" data-v-77277ac5="">
              <div class="mt-10" data-v-51bacf47="">
                <div class="w100" data-v-51bacf47="">
                  <div class="border-box" data-v-51bacf47="" v-if="loading">
                    <img
                      src="/images/default-loader.gif"
                      style="width: 20%; margin-left: -50px"
                    />
                    <br />
                  </div>
                  <div
                    class="border-box"
                    data-v-51bacf47=""
                    v-else-if="
                      !loading && leagueStandings && leagueStandings.standings
                    "
                  >
                    <template
                      v-for="(group, index) in leagueStandings.standings"
                      :key="index"
                    >
                      <div class="title mt-xs" data-v-51bacf47="">
                        <span
                          class="year color-666 relative"
                          data-v-51bacf47=""
                        >
                          {{
                            group.group != 0
                              ? $t("football.league-details.group") +
                                group.group
                              : ""
                          }}

                          <span class="triangle" data-v-51bacf47=""></span
                        ></span>
                      </div>
                      <div
                        class="table flex-1 fs-12"
                        style="border-top: 1px solid #eeeeee"
                        data-v-394fa268=""
                        data-v-51bacf47=""
                      >
                        <!-- begin::table header -->
                        <div
                          class="row fs-12 color-333 h-40"
                          style="background: rgba(244, 245, 248, 1)"
                          data-v-394fa268=""
                        >
                          <div
                            class="col border-right flex-4"
                            data-v-394fa268=""
                          >
                            <span style="margin-left: 20px" data-v-394fa268=""
                              >#</span
                            >
                            <span class="pl-30 ml-xs" data-v-394fa268="">
                              {{ $t("football.league-details.teams") }}
                            </span>
                          </div>
                          <div
                            class="col flex-1dot5 pwdl border-right"
                            data-v-394fa268=""
                          >
                            <span class="flex-1" data-v-394fa268="">P</span>
                            <span class="flex-1 w" data-v-394fa268="">W</span>
                            <span class="flex-1 d" data-v-394fa268="">D</span>
                            <span class="flex-1 l" data-v-394fa268="">L</span>
                          </div>
                          <div
                            class="col goals flex-0dot5 justify-center border-right"
                            data-v-394fa268=""
                          >
                            {{ $t("football.league-details.goals") }}
                          </div>

                          <div
                            class="col pts flex-0dot5 justify-center"
                            data-v-394fa268=""
                          >
                            {{ $t("football.league-details.points") }}
                          </div>
                        </div>
                        <!-- end::table header -->
                        <!-- begin::table body -->
                        <div
                          data-team-id="0m2q15ix41fm76x"
                          class="row fs-12 color-333"
                          style="height: 40px; position: relative"
                          data-v-394fa268=""
                          v-for="standing in group.rows"
                          :key="standing.id"
                        >
                          <div
                            class="flex align-center col h-40 border-right flex-4"
                            style="overflow: auto"
                            data-v-394fa268=""
                          >
                            <a
                              style="cursor: pointer"
                              target="_self"
                              :href="
                                '/' +
                                this.$i18n.locale +
                                '/football/team/details/' +
                                standing.teamInfo.name
                                  .toLowerCase()
                                  .replace(/\s/g, '-') +
                                '/' +
                                standing.teamInfo.id
                              "
                              class="color-333 flex align-center w-o-h"
                              data-v-394fa268=""
                              ><span
                                class="num"
                                style="
                                  lex-shrink: 0;
                                  background: #00d200 !important;
                                  color: #fff !important;
                                  margin-left: 15px;
                                "
                                data-v-394fa268=""
                              >
                                {{ standing.position }}
                              </span>
                              <img
                                :src="
                                  standing &&
                                  standing.teamInfo &&
                                  standing.teamInfo.logo
                                    ? standing.teamInfo.logo + '!w40'
                                  : bannerImagePath('default-team.png')
                                "
                                class="ml-xs w-20"
                                style="flex-shrink: 0; display: "
                                data-v-394fa268=""
                              />
                              <span
                                class="ml-xs team-name w-o-h"
                                data-v-394fa268=""
                              >
                                {{
                                  this.$i18n.locale == "cn"
                                    ? standing.teamInfo.cn_name
                                    : standing.teamInfo.name
                                }}
                              </span></a
                            >
                          </div>
                          <div
                            class="col flex-1dot5 pwdl border-right h-40"
                            data-v-394fa268=""
                          >
                            <span class="flex-1" data-v-394fa268="">{{
                              standing.total
                            }}</span>
                            <span class="flex-1 w" data-v-394fa268="">{{
                              standing.won
                            }}</span>
                            <span class="flex-1 d" data-v-394fa268="">{{
                              standing.draw
                            }}</span>
                            <span class="flex-1 l" data-v-394fa268="">{{
                              standing.loss
                            }}</span>
                          </div>
                          <div
                            class="col goals flex-0dot5 justify-center border-right h-40"
                            data-v-394fa268=""
                          >
                            {{ standing.goals }}:{{ standing.goals_against }}
                          </div>

                          <div
                            class="col pts flex-0dot5 justify-center h-40"
                            data-v-394fa268=""
                          >
                            {{ standing.points }}
                          </div>
                        </div>

                        <!-- end::table body -->
                      </div>
                    </template>
                  </div>
                  <div class="border-box" data-v-51bacf47="" v-else>
                    <div
                      class="scheduleBox"
                      data-v-1eddfbef=""
                      style="width: 100%"
                    >
                      <div
                        data-v-1eddfbef=""
                        class="flex justify-center align-center flex-col"
                        style="height: 300px"
                      >
                        <img
                          :src="bannerImagePath('default-container.png')"
                          style="width: 123px; height: 74px"
                        />
                        <div class="color-999 fs-12 mt-12">
                          {{ $t("football.live-score.temporarily-no-data") }}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- begin::right top scorer player -->

      <div class="p-r" data-v-08c9d60b="">
        <!-- begin::league Info -->
        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-59710436=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{ $t("football.league-details.league-info") }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="LeagueInfo fs-12 pl-15 pr-15"
            data-v-bb874c34=""
            data-v-59710436=""
            data-v-7c947e55=""
          >
            <div class="o-h team-box" data-v-bb874c34="">
              <a
                :href="
                  leagueDetails &&
                  leagueDetails.title_holder &&
                  leagueDetails.title_holder.id
                    ? '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      leagueDetails.title_holder.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      leagueDetails.title_holder.id
                    : ''
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
                ><img
                  :src="
                    leagueDetails &&
                    leagueDetails.title_holder &&
                    leagueDetails.title_holder.logo
                      ? leagueDetails.title_holder.logo + '!w40'
                    : bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                  >{{
                    this.$i18n.locale == "cn"
                      ? leagueDetails.title_holder.cn_name
                      : leagueDetails.title_holder.name
                  }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  {{ $t("football.league-details.current-title-holder") }}
                </span>
              </a>
              <a
                :href="
                  leagueDetails &&
                  leagueDetails.most_titles &&
                  leagueDetails.most_titles.id
                    ? '/' +
                      this.$i18n.locale +
                      '/football/team/details/' +
                      leagueDetails.most_titles.name
                        .toLowerCase()
                        .replace(/\s/g, '-') +
                      '/' +
                      leagueDetails.most_titles.id
                    : ''
                "
                class="item flex align-center justify-center flex-col cur-pointer"
                data-v-bb874c34=""
                ><img
                  :src="
                    leagueDetails &&
                    leagueDetails.most_titles &&
                    leagueDetails.most_titles.logo
                      ? leagueDetails.most_titles.logo + '!w40'
                    : bannerImagePath('default-team.png')
                  "
                  class="logo"
                  data-v-bb874c34=""
                />
                <span
                  class="mt-8 fs-14 h-24 w-o-h w-bar-100 text-center"
                  style="line-height: 24px"
                  data-v-bb874c34=""
                >
                  {{
                    this.$i18n.locale == "cn"
                      ? leagueDetails.most_titles.cn_name
                      : leagueDetails.most_titles.name
                  }}
                </span>
                <span
                  class="color-999"
                  style="margin-top: 1px"
                  data-v-bb874c34=""
                >
                  {{ $t("football.league-details.most-title-holder") }}
                </span></a
              >
            </div>

            <!-- begin::league info -->
            <div class="container-box" data-v-bb874c34="">
              <div class="font-500 mb-xs" data-v-bb874c34="">
                {{ $t("football.league-details.info") }}
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.players") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.totalPlayers }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.teams") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.totalTeams }}</div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.red_cards") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.red_cards }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.yellow_cards") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.yellow_cards }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.shots") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.shots }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.goals") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.goals }}</div>
              </div>

              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.fouls") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.fouls }}</div>
              </div>
              <div class="flex justify-between Stadium-item" data-v-bb874c34="">
                <div class="color-999" data-v-bb874c34="">
                  {{ $t("football.league-details.assists") }}
                </div>
                <div data-v-bb874c34="">{{ leagueDetails.assists }}</div>
              </div>
            </div>
            <!-- end::league Info  -->
          </div>
        </div>
        <!-- end::league Info -->
        <!-- begin::goals -->

        <div
          v-if="
            playersPropertyStats &&
            playersPropertyStats.playersStats &&
            playersPropertyStats.playersStats.length !== 0
          "
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-08c9d60b=""
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name
                  : leagueDetails.name
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="mt-12"
            data-v-09c12dba=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <div class="pl-15 pr-15 fs-12 color-999 flex" data-v-09c12dba="">
              <div
                class="Goals"
                :class="topPlayers == 'goals' ? 'active' : ''"
                data-v-09c12dba=""
                v-on:click="toggleTopPlayers('goals')"
              >
                {{ $t("football.league-details.goals") }}
              </div>
              <div
                class="Assists ml-15"
                data-v-09c12dba=""
                :class="topPlayers == 'assists' ? 'active' : ''"
                v-on:click="toggleTopPlayers('assists')"
              >
                {{ $t("football.league-details.assists") }}
              </div>
            </div>
            <div class="table TopPlayer" data-v-09c12dba="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12 pl-15 pr-15"
                data-v-09c12dba=""
              >
                <div class="col col-5" data-v-09c12dba="">#</div>
                <div class="col flex-1" data-v-09c12dba="">
                  {{ $t("football.league-details.players") }}
                </div>
                <div class="col col-20" data-v-09c12dba="">
                  {{ $t("football.league-details.matches") }}
                </div>
                <div class="col col-25 white-space-n" data-v-09c12dba="">
                  <span data-v-09c12dba="">{{
                    $t("football.league-details.goals")
                  }}</span>
                </div>
              </div>
              <div
                class="body pl-15 pr-15"
                style="min-height: 569px"
                data-v-09c12dba=""
              >
                <span
                  v-if="
                    playersPropertyStats && playersPropertyStats.playersStats
                  "
                >
                  <template
                    v-for="(player, index) in playersPropertyStats.playersStats"
                    :key="player.id"
                  >
                    <div
                      class="row flex fs-12 color-333"
                      data-v-09c12dba=""
                      v-if="index < 10"
                    >
                      <div class="col col-5 color-999" data-v-09c12dba="">
                        {{ ++index }}
                      </div>
                      <div
                        class="col flex-1 justify-start ml-xxs cur-pointer"
                        data-v-09c12dba=""
                      >
                        <a
                          target="_self"
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/player/details/' +
                            player.player.name
                              .toLowerCase()
                              .replace(/\s/g, '-') +
                            '/' +
                            player.player.id
                          "
                          class="player-logo f-s-0"
                          data-v-09c12dba=""
                        >
                          <img
                            :src="
                              player && player.player && player.player.logo
                                ? player.player.logo + '!w40'
                                : bannerImagePath('default-player.png')
                            "
                            data-v-09c12dba=""
                        /></a>
                        <div class="fs-12 ml-xs" data-v-09c12dba="">
                          <a
                            target="_self"
                            :href="
                              '/' +
                              this.$i18n.locale +
                              '/football/player/details/' +
                              player.player.name
                                .toLowerCase()
                                .replace(/\s/g, '-') +
                              '/' +
                              player.player.id
                            "
                            class="mb-xxs display-block color-333"
                            data-v-09c12dba=""
                          >
                            {{
                              this.$i18n.locale == "cn"
                                ? player.player.cn_name
                                : player.player.name
                            }}
                          </a>
                        </div>
                      </div>
                      <div class="col col-20" data-v-09c12dba="">
                        {{ player.matches }}
                      </div>
                      <div class="col col-25" data-v-09c12dba="">
                        {{ player.goals }}
                      </div>
                    </div>
                  </template>
                </span>
                <span v-else>
                  <img src="/images/default-loader.gif" style="width: 25%" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- end::goals -->
        <!-- begin::teams -->
        <div
          class="container-box mb-12 pb-15"
          data-v-7c947e55=""
          data-v-08c9d60b=""
          v-if="
            leagueTeamsStats &&
            leagueTeamsStats.teamsStats &&
            leagueTeamsStats.teamsStats.length !== 0
          "
        >
          <div class="title flex justify-between" data-v-7c947e55="">
            <span class="color-p font-500 w-o-h" data-v-7c947e55="">
              {{
                this.$i18n.locale == "cn" && leagueDetails && leagueDetails.name
                  ? leagueDetails.cn_name
                  : leagueDetails.name
              }}
            </span>
            <span class="color-999 fs-12" data-v-7c947e55=""></span>
          </div>
          <div
            class="mt-12"
            data-v-09c12dba=""
            data-v-08c9d60b=""
            data-v-7c947e55=""
          >
            <div class="table TopPlayer" data-v-09c12dba="">
              <div
                class="head flex justify-between align-center color-999 border-box fs-12 pl-15 pr-15"
                data-v-09c12dba=""
              >
                <div class="col col-5" data-v-09c12dba="">#</div>
                <div class="col flex-1" data-v-09c12dba="">
                  {{ $t("football.league-details.teams") }}
                </div>
                <div class="col col-20" data-v-09c12dba="">
                  {{ $t("football.league-details.matches") }}
                </div>
                <div class="col col-25 white-space-n" data-v-09c12dba="">
                  <span data-v-09c12dba="">{{
                    $t("football.league-details.goals")
                  }}</span>
                </div>
              </div>
              <div
                class="body pl-15 pr-15"
                style="min-height: 569px"
                data-v-09c12dba=""
              >
                <span v-if="leagueTeamsStats && leagueTeamsStats.teamsStats">
                  <template
                    v-for="(team, index) in leagueTeamsStats.teamsStats"
                    :key="team.id"
                  >
                    <div
                      class="row flex fs-12 color-333"
                      data-v-09c12dba=""
                      v-if="index < 10"
                    >
                      <div class="col col-5 color-999" data-v-09c12dba="">
                        {{ ++index }}
                      </div>
                      <div
                        class="col flex-1 justify-start ml-xxs cur-pointer"
                        data-v-09c12dba=""
                      >
                        <a
                          target="_self"
                          :href="
                            '/' +
                            this.$i18n.locale +
                            '/football/team/details/' +
                            team.team.name.toLowerCase().replace(/\s/g, '-') +
                            '/' +
                            team.team.id
                          "
                          class="player-logo f-s-0"
                          data-v-09c12dba=""
                        >
                          <img
                            :src="
                              team && team.team && team.team.logo
                                ? team.team.logo + '!w40'
                              : bannerImagePath('default-team.png')
                            "
                            data-v-09c12dba=""
                        /></a>
                        <div class="fs-12 ml-xs" data-v-09c12dba="">
                          <a
                            target="_self"
                            :href="
                              '/' +
                              this.$i18n.locale +
                              '/football/team/details/' +
                              team.team.name
                                .toLowerCase()
                                .replace(/\s/g, '-') +
                              '/' +
                              team.team.id
                            "
                            class="mb-xxs display-block color-333"
                            data-v-09c12dba=""
                          >
                            {{
                              this.$i18n.locale == "cn"
                                ? team.team.cn_name
                                : team.team.name
                            }}</a
                          >
                        </div>
                      </div>
                      <div class="col col-20" data-v-09c12dba="">
                        {{ team.matches }}
                      </div>
                      <div class="col col-25" data-v-09c12dba="">
                        {{ team.goals }}
                      </div>
                    </div>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end::teams -->
      </div>
      <!-- end::right top scorer player -->
    </div>
  </div>
</template>

<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  props: {
    leagueDetail: {
      type: Object,
      default: null,
    },
    leagueId: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      leagueDetails: null,
      topPlayers: "goals",
      leagueStandings: null,
      leagueTeamsStats: null,
      playersPropertyStats: null,
      loading: true,
    };
  },
  created() {
    this.leagueDetails = this.leagueDetail;
    this.getLeagueStandings(this.leagueId);
    this.getPlayerStats(this.leagueId, this.topPlayers);
    this.getLeagueTeamStats(this.leagueId);
  },
  methods: {
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // method to toggle dropdown
    toggleTopPlayers(status) {
      this.topPlayers = status;
      this.getPlayerStats(this.leagueId, this.topPlayers);
    },
    getLeagueStandings(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/standings/${leagueId}`
        )
        .then((response) => {
          this.leagueStandings = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPlayerStats(leagueId, property) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/playersStats/${leagueId}?playerProperty=${property}`
        )
        .then((response) => {
          this.playersPropertyStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // method to get team stats
    getLeagueTeamStats(leagueId) {
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/football/league/season/teamsStats/${leagueId}`
        )
        .then((response) => {
          this.leagueTeamsStats = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
