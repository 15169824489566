<template>
  <div class="content" data-v-06d4278f="">
    <!-- begin::loading -->
    <div
      v-if="loading"
      class="loading van-loading van-loading--spinner van-loading--vertical"
      data-v-06d4278f=""
      style="top: 10rem"
    >
      <span
        class="van-loading__spinner van-loading__spinner--spinner"
        data-v-06d4278f=""
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
        ><i data-v-06d4278f=""></i><i data-v-06d4278f=""></i
      ></span>
    </div>
    <!-- end::loading -->
    <template
      v-else-if="!loading && matchScoreDetails && matchScoreDetails != ''"
    >
      <div data-v-2115270e="" class="analyseContent">
        <div data-v-2115270e="" class="teamBtnBox">
          <span
            v-for="inning in matchScoreDetails"
            :key="inning"
            data-v-2115270e=""
            class="ml-8"
            style="
              width: 3rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            :class="inning.number == activeInning ? 'activeTeam' : ''"
            v-on:click="toggleInnings(inning.number)"
          >
            {{ inning.battingTeam.name }}
          </span>
        </div>
      </div>
      <!-- batsman -->
      <div
        data-v-8808ea54=""
        data-v-06d4278f=""
        class="basketball_standing"
        style="margin-bottom: 0.5rem"
      >
        <div data-v-8808ea54="" style="width: 100%">
          <div data-v-8808ea54="" class="w100">
            <!-- <div data-v-8808ea54="" class="dataName">
            <span data-v-8808ea54="" class="name flex align-center"
              ><div
                data-v-8808ea54=""
                class="comp_logo van-image"
                itemprop="logo"
                style="overflow: hidden; border-radius: 0px"
              >
                <img
                  v-if="leagueId"
                  :src="
                    'https://sportyclub.online/images/cricket/leagues/' +
                    leagueId +
                    '/' +
                    leagueId +
                    '.png'
                  "
                  @error="$event.target.src = bannerImagePath('default-country.png')"
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              {{ standingDetail?.name }}
            </span>
          </div> -->
            <div data-v-8808ea54="" class="tableTitle">
              <div
                data-v-8808ea54=""
                class="t1 font-400"
                style="box-sizing: border-box; width: 5rem"
              >
                <!-- <span data-v-8808ea54="" class="index">#</span> -->
                <span data-v-8808ea54="" style="margin-left: 0.21rem">
                  Batsman
                </span>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  R
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  B
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  4s
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  6s
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  SR
                </div>
              </div>
            </div>
            <template
              v-for="battingTeam in matchScoreDetails"
              :key="battingTeam"
            >
              <template v-if="battingTeam.number == activeInning">
                <div data-v-8808ea54="" class="contentBox">
                  <div
                    v-for="batting in battingTeam.battingLine"
                    :key="batting"
                    data-v-8808ea54=""
                    class="w100 flex align-center cbox list_active2"
                  >
                    <div
                      data-v-8808ea54=""
                      class="flex align-center contentTeamBox"
                    >
                      <!-- <div data-v-8808ea54="" class="teamIndex">
                      {{ index }}
                    </div> -->
                      <div
                        data-v-8808ea54=""
                        class="teamImg van-image van-image--round"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            batting.player.id
                              ? 'https://sportyclub.online//images/cricket/players/' +
                                batting.player.id +
                                '/' +
                                batting.player.id +
                                '.png'
                            : bannerImagePath('default-team.png')
                          "
                          @error="
                            $event.target.src = bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <a
                        data-v-8808ea54=""
                        class="teamName van-ellipsis"
                        style="margin-left: 0.21rem"
                      >
                        {{ batting.player.name }}
                      </a>
                    </div>
                    <div data-v-8808ea54="" class="flex-1 flex">
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ batting.wicketTypeId == 9 ? "-" : batting.score }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ batting.wicketTypeId == 9 ? "-" : batting.balls }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ batting.wicketTypeId == 9 ? "-" : batting.s4 }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ batting.wicketTypeId == 9 ? "-" : batting.s6 }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{
                            batting.wicketTypeId == 9
                              ? "-"
                              : ((batting.score / batting.balls) * 100).toFixed(
                                  0
                                )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <!-- end batsman -->

      <div
        class="ft_tour_overview"
        data-v-61a7efa4=""
        data-v-1ebdf8d4=""
        style="margin-bottom: 0.5rem"
      >
        <div data-v-812788c4="" class="w100">
          <div class="info" data-v-61a7efa4="">
            <div class="info_title" data-v-61a7efa4="">Info</div>
            <div class="info_content" data-v-61a7efa4="">
              <template v-for="extra in matchScoreDetails" :key="extra">
                <template v-if="extra.number == activeInning">
                  <div class="infoItem" data-v-61a7efa4="">
                    <div class="key" data-v-61a7efa4="">Extras:</div>
                    <div class="value" data-v-61a7efa4="">
                      {{ extra.extra }}
                    </div>
                  </div>
                  <div class="infoItem" data-v-61a7efa4="">
                    <div class="key" data-v-61a7efa4="">Total:</div>
                    <div class="value" data-v-61a7efa4="">
                      {{ extra.score + "/" + extra.wickets }} ({{ extra.overs }}
                      overs)
                    </div>
                  </div>
                </template>
              </template>

              <div class="infoItem" data-v-61a7efa4="" style="display: none">
                <div class="key" data-v-61a7efa4=""></div>
                <div class="value" data-v-61a7efa4=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- bowler -->

      <div
        data-v-8808ea54=""
        data-v-06d4278f=""
        class="basketball_standing"
        style="margin-bottom: 0.5rem"
      >
        <div data-v-8808ea54="" style="width: 100%">
          <div data-v-8808ea54="" class="w100">
            <!-- <div data-v-8808ea54="" class="dataName">
            <span data-v-8808ea54="" class="name flex align-center"
              ><div
                data-v-8808ea54=""
                class="comp_logo van-image"
                itemprop="logo"
                style="overflow: hidden; border-radius: 0px"
              >
                <img
                  v-if="leagueId"
                  :src="
                    'https://sportyclub.online/images/cricket/leagues/' +
                    leagueId +
                    '/' +
                    leagueId +
                    '.png'
                  "
                  @error="$event.target.src = bannerImagePath('default-country.png')"
                  class="van-image__img"
                  style="object-fit: contain"
                />
              </div>
              {{ standingDetail?.name }}
            </span>
          </div> -->
            <div data-v-8808ea54="" class="tableTitle">
              <div
                data-v-8808ea54=""
                class="t1 font-400"
                style="box-sizing: border-box; width: 5rem"
              >
                <!-- <span data-v-8808ea54="" class="index">#</span> -->
                <span data-v-8808ea54="" style="margin-left: 0.21rem">
                  Bowler
                </span>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  O
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  M
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  R
                </div>
              </div>
              <div
                data-v-8808ea54=""
                class="font-400"
                style="position: relative; flex: 1 1 0%"
              >
                <div data-v-8808ea54="" class="text-center" style="width: 100%">
                  W
                </div>
              </div>
              <!-- <div
              data-v-8808ea54=""
              class="font-400"
              style="position: relative; flex: 1 1 0%"
            >
              <div data-v-8808ea54="" class="text-center" style="width: 100%">
                SR
              </div>
            </div> -->
            </div>
            <template
              v-for="bowlingTeam in matchScoreDetails"
              :key="bowlingTeam"
            >
              <template v-if="bowlingTeam.number == activeInning">
                <div data-v-8808ea54="" class="contentBox">
                  <div
                    v-for="bowler in bowlingTeam.bowlingLine"
                    :key="bowler"
                    data-v-8808ea54=""
                    class="w100 flex align-center cbox list_active2"
                  >
                    <div
                      data-v-8808ea54=""
                      class="flex align-center contentTeamBox"
                    >
                      <!-- <div data-v-8808ea54="" class="teamIndex">
                      {{ index }}
                    </div> -->
                      <div
                        data-v-8808ea54=""
                        class="teamImg van-image van-image--round"
                        style="overflow: hidden; border-radius: 50%"
                      >
                        <img
                          :src="
                            bowler.player.id
                              ? 'https://sportyclub.online//images/cricket/players/' +
                                bowler.player.id +
                                '/' +
                                bowler.player.id +
                                '.png'
                            : bannerImagePath('default-team.png')
                          "
                          @error="
                            $event.target.src = bannerImagePath('default-player.png')
                          "
                          class="van-image__img"
                          style="object-fit: contain"
                        />
                      </div>
                      <a
                        data-v-8808ea54=""
                        class="teamName van-ellipsis"
                        style="margin-left: 0.21rem"
                      >
                        {{ bowler.player.name }}
                      </a>
                    </div>
                    <div data-v-8808ea54="" class="flex-1 flex">
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ bowler.over }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ bowler.maiden }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ bowler.run }}
                        </div>
                      </div>
                      <div data-v-8808ea54="" class="flex-1 text-center">
                        <div data-v-8808ea54="" class="detailData">
                          {{ bowler.wicket }}
                        </div>
                      </div>
                      <!-- <div data-v-8808ea54="" class="flex-1 text-center">
                      <div data-v-8808ea54="" class="detailData">
                        {{
                          batting.wicketTypeId == 9
                            ? "-"
                            : ((batting.score / batting.balls) * 100).toFixed(0)
                        }}
                      </div>
                    </div> -->
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>

    <div
      v-else
      data-v-8808ea54=""
      data-v-06d4278f=""
      class="basketball_standing"
    >
      <div data-v-8808ea54="" style="width: 100%">
        <div data-v-8808ea54="" class="w100">
          <div data-v-8808ea54="" class="contentBox">
            <div
              class="flex justify-center align-center flex-col"
              style="height: 400px"
              data-v-4291ef99=""
            >
              <img
                :src="bannerImagePath('no-data.png')"
                style="width: 123px; height: 74px"
              />
              <div class="color-999 fs-12 mt-12">
                {{ $t("football.live-score.temporarily-no-data") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DynamicImagePath from "@/utils/dynamicImagePath";
export default {
  mixins: [DynamicImagePath],
  name: "StandingsTab",
  props: {
    matchId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      matchScoreDetails: null,
      loading: true,
      activeInning: 1,
    };
  },
  created() {
    this.getMatchScore(this.matchId);
  },

  methods: {
    // method to get home team matches
    getMatchScore(matchId) {
      console.log(matchId);
      this.$axios
        .get(
          this.BASE_SERVER_URI +
            `/api/cricket/single/match/innings/${matchId}`
        )
        .then((response) => {
          this.matchScoreDetails = response.data.innings;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleInnings(num) {
      this.activeInning = num;
    },
  },
};
</script>
