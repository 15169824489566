<template>
  <span class="desktop-version">
    <DesktopVersion />
  </span>
  <span class="mobile-version">
    <MobileVersion />
  </span>
</template>

<script>
import DesktopVersion from "./desktop-version/news-list-desktop.vue";
import MobileVersion from "./mobile-version/news-list-mobile.vue";
export default {
  mounted() {
    document.title =
      "Today's Football News | International Football News | Football Headlines | Sportyclub Live";

    // Create meta description tag
    const metaDescription = document.createElement("meta");
    metaDescription.setAttribute("name", "description");
    metaDescription.content =
      "Get live Football scores, Football updates of upcoming & finished International, domestic , leagues and T20 matches. Catch all the latest news about football on sportyclub.";
    document.head.appendChild(metaDescription);

    // Create meta keywords tag
    const metaKeywords = document.createElement("meta");
    metaKeywords.setAttribute("name", "keywords");
    metaKeywords.content =
      "football news, latest news, latest football news, football news headlines,  icc football news, psl news, ipl news, bigbash news, t10 news";
    document.head.appendChild(metaKeywords);

    // Create canonical link tag
    const canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    canonicalLink.setAttribute(
      "href",
      "https://sportyclub.live/en/football/news/list"
    );
    document.head.appendChild(canonicalLink);
  },
  components: {
    DesktopVersion,
    MobileVersion,
  },
};
</script>
