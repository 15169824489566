export default {
    methods: {
        getSalaryHumanReadable(number) {
            if (isNaN(number)) return "NaN";

            // Check if the number is in billions
            if (Math.abs(number) >= 1e9) {
                return (number / 1e9).toFixed(2) + "B";
            }
            // Check if the number is in millions
            else if (Math.abs(number) >= 1e6) {
                return (number / 1e6).toFixed(2) + "M";
            }
            // Check if the number is in thousands
            else if (Math.abs(number) >= 1e3) {
                return (number / 1e3).toFixed(2) + "K";
            }
            // Otherwise, just return the number
            else {
                return number.toString();
            }
        },
    },
};
