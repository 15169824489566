<template>
  <div id="__layout">
    <div data-v-250f1619="" id="app" class="font-en">
      <div
        data-v-6efd1af3=""
        data-v-250f1619=""
        class="privacy-view view border-box"
      >
        <p
          data-v-6efd1af3=""
          class="t1 text-center"
          v-if="this.$i18n.locale == 'cn'"
        >
          关于我们
        </p>

        <p data-v-6efd1af3="" class="t1 text-center" v-else>About US</p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          欢迎来到运动俱乐部直播！
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          Welcome to the privacy policy of Sportyclub Live !
        </div>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          在 Sportyclub
          Live，我们对运动充满热情。我们的目标是成为世界各地体育爱好者的一站式商店。我们希望为您带来最新的分数和结果。作为本网站的用户，您将可以访问体育赛事直播、实时比分、即将到来的赛程、新闻、
          和统计数据。我们提供实时分数更新和详细统计数据
          每场比赛。我们的平台还全面覆盖
          即将到来的比赛，以便您可以跟踪您最喜欢的球队并制定计划
          您相应的观看时间表。此外，我们的新闻部分还提供
          最新动态、专家分析和独家专访 球员和教练。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          At Sportyclub Live, we are passionate about sports. Our goal is to be
          a one-stop shop for sports lovers all over the world. We want to bring
          you the most up-to-date scores and results. As a user of this website,
          you will get access to live sports, live scores, upcoming schedules,
          news, and statistics. We offer real-time score updates and detailed
          statistics for every game. Our platform also provides comprehensive
          coverage of upcoming matches, so you can keep track of your favorite
          teams and plan your viewing schedule accordingly. Additionally, our
          news section offers the latest updates, expert analyses, and exclusive
          interviews with players and coaches.
        </div>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          我们提供什么:
        </p>
        <p data-v-6efd1af3="" class="t2" v-else>What We Offer:</p>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          在Sportyclub，我们致力于为您提供优质的内容
          其中包括各种各样的运动。无论你是粉丝
          足球、篮球、板球、网球、乒乓球、棒球、
          羽毛球、曲棍球或排球，我们为每个人提供适合的项目。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          At Sportyclub, we are committed to providing you with quality content
          that includes a wide variety of sports. Whether you are fans of
          football, basketball, cricket, tennis, table tennis, baseball,
          badminton, hockey, or volleyball, we have something for everyone.
        </div>

        <p data-v-6efd1af3="" class="t2" v-if="this.$i18n.locale == 'cn'">
          我们的类别:
        </p>

        <p data-v-6efd1af3="" class="t2" v-else>Our Categories:</p>

        <ul
          v-if="this.$i18n.locale == 'cn'"
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">
            <b>球员简介：</b>了解有关您最喜欢的运动员的更多信息。查看
            列出我们深入的球员档案。展示职业生涯的高潮、低谷、
            以及体育偶像和新星的个人故事。
          </li>

          <li data-v-6efd1af3="">
            <b>球队信息：</b>获取有关各个联赛和锦标赛球队的全面详细信息。
          </li>
          <li data-v-6efd1af3="">
            <b>联赛信息：</b>深入了解世界各地不同的联赛和比赛。
          </li>
          <li data-v-6efd1af3="">
            <b>积分榜：</b>不同联赛和锦标赛中球队和球员的最新积分榜。
          </li>
          <li data-v-6efd1af3="">
            <b>实时比赛：</b>正在进行的比赛和比赛的实时更新。
          </li>
          <li data-v-6efd1af3="">
            <b>即将举行的比赛：</b>未来比赛的时间表和详细信息以及 游戏。
          </li>
          <li data-v-6efd1af3="">
            <b>已完成的比赛：</b>已完成的比赛和比赛的结果和分析。
          </li>
        </ul>

        <ul
          v-else
          data-v-6efd1af3=""
          class="text"
          style="list-style-type: disc; margin-left: 20px"
        >
          <li data-v-6efd1af3="">
            <b>Player Profiles:</b> Learn more about your favorite athletes.
            Check out our in-depth player profiles .that showcase career highs,
            lows, and personal stories of sports icons and up-and-coming stars.
          </li>
          <li data-v-6efd1af3="">
            <b>Team Information:</b>Get comprehensive details about teams across
            various leagues and tournaments.
          </li>
          <li data-v-6efd1af3="">
            <b>League Information:</b>Insights into different leagues and
            competitions around the world.
          </li>

          <li data-v-6efd1af3="">
            <b>Live Matches:</b>Real-time updates for ongoing matches and games.
          </li>

          <li data-v-6efd1af3="">
            <b>Upcoming Matches:</b>Schedule and details of future matches and
            games.
          </li>
          <li data-v-6efd1af3="">
            <b>Finished Matches:</b>Results and analyses of completed matches
            and games.
          </li>
        </ul>

        <div data-v-6efd1af3="" class="text" v-if="this.$i18n.locale == 'cn'">
          加入我们的 Sportyclub，成为我们充满活力的体育社区的一部分，
          在这里，对体育的热情与优质内容和实时更新相结合。
        </div>

        <div data-v-6efd1af3="" class="text" v-else>
          Join us at Sportyclub and be a part of our vibrant sports community,
          where passion for sports meets quality content and real-time updates.
        </div>
      </div>
    </div>
  </div>
  <span>
    <MobileFooter />
  </span>
</template>
  
  <script>
export default {};
</script>
  
  <style>
</style>