require("./bootstrap");

import { createApp } from "vue";
import i18n from "./i18n";
import router from "./router/index";
import App from "./App.vue";
import getImagePath from './utils/changeTheme.js'
import axios from "axios";
import serverCnfg from "../config/server";
import { VideoPlayer } from "vue-videojs7";
import MobileFooter from "./components/commons/footers/mobile-footer";
import LoadingSection from "./components/commons/loader-section";
import DesktopFooter from "./components/commons/footers/desktop-footer";
import mitt from "mitt";

// import "bootstrap/dist/css/bootstrap.css";a
const emitter = mitt();
const vueApp = createApp(App);
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
vueApp.config.globalProperties.emitter = emitter;
vueApp.config.globalProperties.$getImagePath = getImagePath
vueApp.config.globalProperties.$axios = axios;
vueApp.config.globalProperties.BASE_SERVER_URI = serverCnfg.BASE_SERVER_URI;
vueApp.component("MobileFooter", MobileFooter);
vueApp.component("DesktopFooter", DesktopFooter);
vueApp.component("LoadingSection", LoadingSection);
vueApp.use(VideoPlayer);
let pathName = location.pathname;
let domianName = location.href;

const storedActiveUrl = localStorage.getItem("activeUrl");

if (
  (pathName.indexOf("cn/") != -1 || domianName.indexOf("cn.") != -1) &&
  i18n.global.locale != "cn"
) {
  // console.log("in cn condition");
  i18n.global.locale = "cn";
} else if (
  (pathName.indexOf("en/") != -1 || domianName.indexOf("en.") != -1) &&
  i18n.global.locale != "en"
) {
  // console.log("in en condition");s
  i18n.global.locale = "en";
}

if (storedActiveUrl != pathName) {
  // console.log("in condition storedActiveurl != pathname");

  // ---------- first time opening the application with cache clear ---------
  if (storedActiveUrl === null && pathName === "/") {
    // console.log("in condition storedActiveUrl === null && pathName === /");
    localStorage.setItem("activeUrl", "/en/live/cricket/");
  }
  // ---------- there is storedActiveUrl but a new window is open and pasted the domainName without any route
  else if (storedActiveUrl !== null && pathName === "/") {
    if (storedActiveUrl.indexOf("cn") !== -1 && i18n.global.locale != "cn") {
      i18n.global.locale = "cn";
    } else if (
      storedActiveUrl.indexOf("en") !== -1 &&
      i18n.global.locale != "en"
    ) {
      i18n.global.locale = "en";
    }
  }
  if (pathName != "/") localStorage.setItem("activeUrl", pathName);
}

vueApp.use(router);
vueApp.use(i18n);
// console.log("before app mount", router.currentRoute, router.getRoutes());
vueApp.mount("#app");
