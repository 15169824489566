<template>
  <br />
  <div class="container-box mb-12 pb-24" data-v-7c947e55="" data-v-751ad9ac="">
    <div class="title flex justify-between" data-v-7c947e55="">
      <span class="color-p font-500 w-o-h" data-v-7c947e55="">
        {{ $t("football.league-details.featured-match") }}
      </span>
      <span class="color-999 fs-12" data-v-7c947e55=""></span>
      <!---->
    </div>
    <div
      class="teamInfo color-333 fs-12 mt-12"
      data-v-5fcdd480=""
      data-v-751ad9ac=""
      data-v-7c947e55=""
      v-for="match in todayFeaturedMatch"
      :key="match.id"
    >
      <div class="flex justify-between pl-15 pr-15" data-v-5fcdd480="">
        <div class="color-999" data-v-5fcdd480="">
          {{ $t("football.league-details.start-time") }}
        </div>
        <div data-v-5fcdd480="">
          {{ formatUnixTimestamp(match.startTimestamp) }}
        </div>
      </div>
      <!-- <div
              class="flex justify-between mt-15 pl-15 pr-15"
              data-v-5fcdd480=""
            >
              <div class="color-999" data-v-5fcdd480="">
                {{ $t("football.league-details.description") }}
              </div>
              <div data-v-5fcdd480="">{{ match.status.description }}</div>
            </div> -->
      <div
        class="cycle-bg mt-15 pl-15 pr-15 flex align-center"
        data-v-5fcdd480=""
        v-on:click="redirectToNewPage(match)"
        style="cursor: pointer"
      >
        <div class="flex-1 flex flex-col align-center" data-v-5fcdd480="">
          <div
            id="foreignPlayers"
            class="cycle"
            data-v-5fcdd480=""
            _echarts_instance_="ec_1721828403362"
            style="-webkit-tap-highlight-color: transparent; user-select: none"
          >
            <img
              style="width: 82px !important; height: 82px !important"
              :src="
                'https://sportyclub.online//images/cricket/teams/' +
                match.homeTeam.id +
                '/' +
                match.homeTeam.id +
                '.png'
              "
              @error="$event.target.src = bannerImagePath('default-team.png')"
              class="rounded-image"
            />
          </div>

          <div class="color-999 mt-15" data-v-5fcdd480="">
            {{ match.homeTeam.name }}
          </div>
        </div>
        <div class="flex-1 flex flex-col align-center" data-v-5fcdd480="">
          <div
            id="nationalPlayers"
            class="cycle"
            data-v-5fcdd480=""
            _echarts_instance_="ec_1721828403363"
            style="-webkit-tap-highlight-color: transparent; user-select: none"
          >
            <img
              style="width: 82px !important; height: 82px !important"
              :src="
                'https://sportyclub.online//images/cricket/teams/' +
                match.awayTeam.id +
                '/' +
                match.awayTeam.id +
                '.png'
              "
              @error="$event.target.src = bannerImagePath('default-team.png')"
              class="rounded-image"
            />
            <div
              style="
                position: relative;
                width: 72px;
                height: 72px;
                padding: 0px;
                margin: 0px;
                border-width: 0px;
                cursor: default;
              "
            ></div>
          </div>

          <div class="color-999 mt-15" data-v-5fcdd480="">
            {{ match.awayTeam.name }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-box mb-12 pb-24" data-v-7c947e55="" data-v-751ad9ac="">
    <div class="title flex justify-between" data-v-7c947e55="">
      <span class="color-p font-500 w-o-h" data-v-7c947e55="">
        {{ $t("football.live-score.popular") }}
      </span>
      <span class="color-999 fs-12" data-v-7c947e55=""></span>
      <!---->
    </div>
    <div
      class="teamInfo color-333 fs-12 mt-12"
      data-v-5fcdd480=""
      data-v-751ad9ac=""
      data-v-7c947e55=""
    >
      <div class="pl-15 pr-15 mt-15" data-v-5fcdd480="">
        <div class="flex Competitions mt-12" data-v-5fcdd480="">
          <!-- Iterate over topLeagues -->
          <a
            v-for="league in topLeagues"
            :key="league.id"
            :href="
              '/' +
              this.$i18n.locale +
              '/cricket/league/details/' +
              league.nameEn.toLowerCase().replace(/\s/g, '-') +
              '/' +
              league.id
            "
            class="team flex flex-col align-center color-333"
            data-v-5fcdd480=""
          >
            <img
              :src="
                'https://sportyclub.online//images/cricket/leagues/' +
                league.id +
                '/' +
                league.id +
                '.png'
              "
              class="team-logo"
              data-v-5fcdd480=""
            />

            <span class="text-center" data-v-5fcdd480="">
              {{ league.nameEnShort }}
            </span>
          </a>
        </div>
      </div>
      <!---->
    </div>
  </div>

  <div class="container-box mt-12" data-v-7c947e55="" data-v-771033de="">
    <div class="title flex justify-between" data-v-7c947e55="">
      <span class="color-p font-500 w-o-h" data-v-7c947e55="">
        {{ $t("football.league-details.hot-headline") }}
      </span>
      <span class="color-999 fs-12" data-v-7c947e55=""></span>
      <!---->
    </div>
    <div
      v-for="news in cricketNewsList"
      :key="news"
      class="f-s-12 color-666 line-h-16 ml-15 mr-15 mt-15 pb-20"
      data-v-7c947e55=""
      data-v-771033de=""
    >
      <a class="icon-text-container" :href="getNewsLink(news.id)">
        <span class="iconfont icon-lujingbeifen fs-12 color-999"></span>
        <span class="title" style="font-size: 15px !important"
          >{{ news.title }}.</span
        >
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DynamicImagePath from "@/utils/dynamicImagePath";

export default {
  mixins: [DynamicImagePath],

  data() {
    return {
      loading: true,
      cricketNewsList: null,
      todayFeaturedMatch: [],
      topLeagues: [], // Store top leagues data
      countdown: "", // Store the countdown timer
    };
  },

  created() {
    this.getTodayFeaturedMatch();
    this.getTopLeagues(); // Fetch top leagues data
    this.getCricketNews();
  },

  methods: {
    getNewsLink(id) {
      const locale = this.$i18n.locale;
      return `/${locale}/cricket/news/details/${id}`;
    },
    bannerImagePath(image) {
      return this.$getImagePath(this.$root.theme, image);
    },
    // Method to convert Unix timestamp
    formatUnixTimestamp(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
      const hours = ("0" + date.getHours()).slice(-2); // Add leading zero if needed
      const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero if needed
      return `${hours}:${minutes}`;
    },

    // Method to get today's featured matches
    getTodayFeaturedMatch() {
      axios
        .get(`${this.BASE_SERVER_URI}/api/cricket/today/featured/match`)
        .then((response) => {
          this.todayFeaturedMatch = response.data.featuredEvents;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching today's featured matches:", error);
          this.loading = false; // Ensure loading state is updated even in case of error
        });
    },

    // Method to fetch top leagues data
    getTopLeagues() {
      axios
        .get(`${this.BASE_SERVER_URI}/api/cricket/league/list`)
        .then((response) => {
          this.topLeagues = response.data.topLeagues;
        })
        .catch((error) => {
          console.error("Error fetching top leagues:", error);
        });
    },

    // method to get news of page 2
    getCricketNews() {
      axios
        .get(`https://sportyclub.online/api/cricket/news/list?pageNumber=3`)
        .then((response) => {
          this.cricketNewsList = response.data.cricketNewsList.slice(0, 5); // Get up to 10 news items;
        })
        .catch((error) => {
          console.error("Error fetching top leagues:", error);
        });
    },
    redirectToNewPage(match) {
      const locale = this.$i18n.locale; // Assuming you have access to the current locale

      // Construct the URL based on your logic
      const url = `/${locale}/cricket/match/details/${match.homeTeam.name
        .toLowerCase()
        .replace(/\s/g, "-")}-vs-${match.awayTeam.name
        .toLowerCase()
        .replace(/\s/g, "-")}/${match.id}`;

      // Redirect to the constructed URL
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.icon-text-container {
  display: inline-flex;
  align-items: center;
}

.iconfont {
  transform: scale(0.8);
  margin-right: 5px;
  color: #999; /* Initial color */
  transition: color 0.3s; /* Smooth transition for color change */
}

.title {
  margin-left: 7px;
  color: #333; /* Initial color */
  transition: color 0.3s; /* Smooth transition for color change */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.icon-text-container:hover .iconfont,
.icon-text-container:hover .title {
  color: blue; /* Change color to blue on hover */
}
</style>
