<template>
  <div data-v-94567e5a="" class="hotComps pr-12 mb-12 pb-15">
    <div data-v-94567e5a="" class="title font-500">
      {{ $t("football.live-score.popular") }}
    </div>
    <div data-v-94567e5a="" style="width: 100%">
      <a
        v-for="hotLeague in hotLeaguesList"
        :key="hotLeague.id"
        data-v-94567e5a=""
        target="_blank"
        :href="'/' + this.$i18n.locale + '/tennis/league/details/'   +hotLeague.nameEn.toLowerCase().replace(/[\s/]+/g, '-') + '/' + hotLeague.id
        "
        class="comps-item"
        ><span data-v-94567e5a="" class="country-logo"
          ><img data-v-94567e5a="" :src="hotLeague.logo + '!w30'"
        /></span>
        <span
          data-v-94567e5a=""
          title="FIBA Basketball World Cup"
          class="ml-xs w-o-h"
          >{{
            this.$i18n.locale == "cn" ? hotLeague.nameCn : hotLeague.nameEn
          }}</span
        ></a
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      hotLeaguesList: null,
    };
  },
  created() {
    this.gethotLeaguesList();
  },
  methods: {
    // method to get hot leagues list
    gethotLeaguesList() {
      this.$axios
        .get(this.BASE_SERVER_URI + `/api/tennis/league/list`)
        .then((response) => {
          this.hotLeaguesList = response.data.tennisHotLeaguesList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
